import React, { useCallback } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { SettingsHelper } from "../Shared/SettingsHelper";
import { OrganizationSettingsEnum } from "../App";
import { findPermission } from "../Shared/RolesMap";
import { RoutePathEnum } from "./RoutePathEnum";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { StaffRoleEnum, Permissions } from "Shared/Api/globalTypes";
import { Notifications } from "Notifications/Notifications";
import { RouterHelper } from "./RouterHelper";
import { LabelHelper } from "Shared/LabelHelper";

// Notifications

const RedirectToDefault = () => {
  const { threadUserContext } = useThreadContext();
  if (threadUserContext && threadUserContext.role === StaffRoleEnum.ADMIN) {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.PEOPLE_MANAGEMENT,
          state: {
            sideMenu: RoutePathEnum.PEOPLE_MANAGEMENT,
            current: RoutePathEnum.PEOPLE_MANAGEMENT
          }
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const ViewNotifications = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();

  const showNotifications = SettingsHelper.boolean(
    threadUserContext,
    OrganizationSettingsEnum.MOBILE_ALERTS,
    true
  );

  const canViewNotifications = findPermission(
    threadUserContext.role,
    Permissions.VIEW_NOTIFICATIONS
  );

  const handleViewChart = useCallback(
    (studentId: string, programId: string) =>
      RouterHelper.redirectToChart(
        studentId,
        programId,
        history,
        false,
        LabelHelper.routePathLabel(RoutePathEnum.NOTIFICATIONS)
      ),
    [history]
  );

  if (threadUserContext && showNotifications && canViewNotifications) {
    return <Notifications onViewChart={handleViewChart} />;
  } else {
    return <RedirectToDefault />;
  }
};

interface NotificationsRouterProps {}

export const NotificationsRouter = (props: NotificationsRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.NOTIFICATIONS} exact={true}>
        <ViewNotifications />
      </Route>
    </Switch>
  );
};
