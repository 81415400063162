import gql from "graphql-tag";
import { v4 } from "uuid";
import {
  ApolloCache,
  ApolloClient,
  QueryFunctionOptions,
  useMutation,
  useQuery
} from "@apollo/client";
import { FRAGMENT_SESSION_NOTE, FRAGMENT_STUDENT } from "./Fragments";
import { QUERY_ACTIVE_SESSION_NOTES, QUERY_SESSION_NOTES } from "./Queries";
import { UseQueryOptions } from "../types";
import {
  PlaceOfServiceEnum,
  SessionNoteInput,
  SessionNoteStatusEnum,
  SessionNoteStatusFilter
} from "Shared/Api/globalTypes";
import {
  ISessionNotesVariables,
  ISessionNotes,
  ISessionNotes_sessionNotes_edges,
  ISessionNotes_sessionNotes_edges_node
} from "Shared/Api/ISessionNotes";
import {
  IActiveSessionNotesVariables,
  IActiveSessionNotes,
  IActiveSessionNotes_sessionNotesActive
} from "Shared/Api/IActiveSessionNotes";
import {
  sessionNoteCreate,
  sessionNoteCreateVariables,
  sessionNoteCreate_sessionNoteCreate_sessionNote_student
} from "Shared/Api/sessionNoteCreate";
import {
  sessionNoteDelete,
  sessionNoteDeleteVariables
} from "Shared/Api/sessionNoteDelete";
import {
  sessionNoteRevert,
  sessionNoteRevertVariables
} from "Shared/Api/sessionNoteRevert";
import {
  sessionNoteSign,
  sessionNoteSignVariables
} from "Shared/Api/sessionNoteSign";
import {
  sessionNoteSignAll,
  sessionNoteSignAllVariables
} from "Shared/Api/sessionNoteSignAll";
import {
  sessionNoteSubmit,
  sessionNoteSubmitVariables
} from "Shared/Api/sessionNoteSubmit";
import {
  sessionNoteUpdate,
  sessionNoteUpdateVariables
} from "Shared/Api/sessionNoteUpdate";
import {
  sessionNoteUpdateSmartNotes,
  sessionNoteUpdateSmartNotesVariables
} from "Shared/Api/sessionNoteUpdateSmartNotes";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { SessionNoteFragment } from "Shared/Api/SessionNoteFragment";
import _, { cloneDeep } from "lodash";
import { useCallback } from "react";

const MUTATION_CREATE_SESSION_NOTE = gql`
  mutation sessionNoteCreate($id: GUID!, $input: SessionNoteInput!) {
    sessionNoteCreate(id: $id, input: $input) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_UPDATE_SESSION_NOTE = gql`
  mutation sessionNoteUpdate($id: GUID!, $input: SessionNoteInput!) {
    sessionNoteUpdate(id: $id, input: $input) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_SUBMIT_SESSION_NOTE = gql`
  mutation sessionNoteSubmit($id: GUID!, $studentId: GUID!) {
    sessionNoteSubmitForSignature(id: $id, studentId: $studentId) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_SIGN_ALL_SESSION_NOTE = gql`
  mutation sessionNoteSignAll($studentId: GUID!, $signature: String) {
    sessionNoteSignAll(studentId: $studentId, signature: $signature) {
      sessionNotes {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_SIGN_SESSION_NOTE = gql`
  mutation sessionNoteSign($id: GUID!, $studentId: GUID!, $signature: String) {
    sessionNoteSign(id: $id, studentId: $studentId, signature: $signature) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_DELETE_SESSION_NOTE = gql`
  mutation sessionNoteDelete($id: GUID!, $studentId: GUID!) {
    sessionNoteDelete(id: $id, studentId: $studentId) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_REVERT_SESSION_NOTE = gql`
  mutation sessionNoteRevert($id: GUID!, $studentId: GUID!) {
    sessionNoteRevert(id: $id, studentId: $studentId) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

const MUTATION_UPDATE_SMART_NOTES = gql`
  mutation sessionNoteUpdateSmartNotes($input: SessionNoteInput!) {
    sessionNoteUpdateSmartNotes(input: $input) {
      sessionNote {
        ...SessionNoteFragment
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

export const useSessionNoteCreateMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteCreate,
    sessionNoteCreateVariables
  >(MUTATION_CREATE_SESSION_NOTE);

  const handleSessionNoteCreate = useCallback(
    (input: SessionNoteInput) => {
      const id = v4();
      const studentId = input.studentId ?? null;
      const student = readStudent(
        client.cache,
        studentId
      ) as sessionNoteCreate_sessionNoteCreate_sessionNote_student;

      // SessionNoteInput has optional fields while the sessionNote output only has
      // nullable fields, I have not found an automatic way to convert the fields
      const {
        behaviorDetails = null,
        smartBehaviorDetails = null,
        skillDetails = null,
        smartSkillDetails = null,
        start = null,
        end = null,
        signature = null,
        placeOfService,
        serviceType = null
      } = input;

      const optimisticResponse: sessionNoteCreate = {
        sessionNoteCreate: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            __typename: "SessionNoteType",

            id,
            student,

            createdById: threadUserContext.userId,
            createdByName: threadUserContext.userName,

            modifiedAt: new Date().toISOString(),

            behaviorDetails,
            smartBehaviorDetails,
            skillDetails,
            smartSkillDetails,
            start,
            end,
            signature,
            placeOfService: placeOfService ?? PlaceOfServiceEnum.NONE,
            serviceType,
            status: SessionNoteStatusEnum.NEEDS_AUTHORIZED
          }
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { id, input },
        update: (cache, { data }) => {
          if (studentId) {
            updateActiveSessionNoteQuery(
              cache,
              studentId,
              id,
              data?.sessionNoteCreate?.sessionNote
            );
          }
        }
      });
    },
    [mutate, client.cache, threadUserContext.userId, threadUserContext.userName]
  );
  return { sessionNoteCreate: handleSessionNoteCreate, error, data };
};

export const useSessionNoteUpdateMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteUpdate,
    sessionNoteUpdateVariables
  >(MUTATION_UPDATE_SESSION_NOTE);

  const handleSessionNoteUpdate = useCallback(
    (id: string, input: SessionNoteInput) => {
      const existingSessionNote = readSessionNote(client.cache, id);
      if (existingSessionNote === null) {
        throw new Error("Unable to locate session note to update");
      }
      const studentId = input.studentId;

      const optimisticResponse: sessionNoteUpdate = {
        sessionNoteUpdate: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            ...existingSessionNote,

            // it'd be nice to just use spread on input into session note output, but we
            // unfortunately have to get rid of all the undefineds and provide expected
            // defaults for some enums.
            behaviorDetails: input.behaviorDetails || null,
            smartBehaviorDetails: input.smartBehaviorDetails || null,
            skillDetails: input.skillDetails || null,
            smartSkillDetails: input.smartSkillDetails || null,
            start: input.start || null,
            end: input.end || null,
            signature: input.signature || null,
            placeOfService:
              input.placeOfService || existingSessionNote.placeOfService,
            serviceType: input.serviceType || null,
            status: input.status || existingSessionNote.status,

            modifiedAt: new Date().toISOString()
          }
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { id, input },
        update: (cache, { data }) => {
          // make sure notes are in correct query lists
          if (studentId) {
            const sessionNote = data?.sessionNoteUpdate?.sessionNote;

            updateActiveSessionNoteQuery(cache, studentId, id, sessionNote);
            updateSubmittedSessionNoteQuery(cache, studentId, id, sessionNote);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { sessionNoteUpdate: handleSessionNoteUpdate, error, data };
};

export const useSessionNoteSubmitMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteSubmit,
    sessionNoteSubmitVariables
  >(MUTATION_SUBMIT_SESSION_NOTE);

  const handleSessionNoteSubmit = useCallback(
    (id: string, studentId: string) => {
      const existingSessionNote = readSessionNote(client.cache, id);
      if (existingSessionNote === null) {
        throw new Error("Unable to locate session note to submit");
      }

      const optimisticResponse: sessionNoteSubmit = {
        sessionNoteSubmitForSignature: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            ...existingSessionNote,

            status: SessionNoteStatusEnum.NEEDS_SIGNATURE
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId },
        update: (cache, { data }) => {
          updateActiveSessionNoteQuery(
            cache,
            studentId,
            id,
            data?.sessionNoteSubmitForSignature?.sessionNote
          );
          updateSubmittedSessionNoteQuery(
            cache,
            studentId,
            id,
            data?.sessionNoteSubmitForSignature?.sessionNote
          );
        }
      });
    },
    [mutate, client.cache]
  );
  return {
    sessionNoteSubmit: handleSessionNoteSubmit,
    error,
    data
  };
};

export const useSessionNoteSignMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteSign,
    sessionNoteSignVariables
  >(MUTATION_SIGN_SESSION_NOTE);

  const handleSessionNoteSign = useCallback(
    (id: string, studentId: string, signature: string) => {
      const existingSessionNote = readSessionNote(client.cache, id);
      if (existingSessionNote === null) {
        throw new Error("Unable to locate note to sign");
      }
      const optimisticResponse: sessionNoteSign = {
        sessionNoteSign: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            ...existingSessionNote,

            signature,
            status: SessionNoteStatusEnum.SUBMITTED
          }
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { id, studentId, signature },
        update: (cache, { data }) => {
          updateActiveSessionNoteQuery(
            cache,
            studentId,
            id,
            data?.sessionNoteSign?.sessionNote
          );
          updateSubmittedSessionNoteQuery(
            cache,
            studentId,
            id,
            data?.sessionNoteSign?.sessionNote
          );
        }
      });
    },
    [mutate, client.cache]
  );
  return { sessionNoteSign: handleSessionNoteSign, error, data };
};

export const useSessionNoteSignAllMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteSignAll,
    sessionNoteSignAllVariables
  >(MUTATION_SIGN_ALL_SESSION_NOTE);

  const handleSessionNoteSignAll = useCallback(
    (studentId: string, signature: string) => {
      const sessionNotes = cloneDeep(
        readActiveSessionNotes(client.cache, studentId)
      )
        // filter to user's notes
        .filter(
          note =>
            note.createdById === threadUserContext.userId &&
            note.status === SessionNoteStatusEnum.NEEDS_SIGNATURE
        )
        // map into signed versions
        .map(note => {
          return {
            ...note,

            status: SessionNoteStatusEnum.SUBMITTED,
            signature
          };
        });

      const optimisticResponse: sessionNoteSignAll = {
        sessionNoteSignAll: {
          __typename: "SessionNoteSignAllOutput",
          sessionNotes
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { studentId, signature },
        update: (cache, { data }) => {
          data?.sessionNoteSignAll?.sessionNotes.forEach(note => {
            updateActiveSessionNoteQuery(cache, studentId, note!.id, note);
            updateSubmittedSessionNoteQuery(cache, studentId, note!.id, note);
          });
        }
      });
    },
    [mutate, client.cache, threadUserContext.userId]
  );
  return { sessionNoteSignAll: handleSessionNoteSignAll, error, data };
};

export const useSessionNoteDeleteMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteDelete,
    sessionNoteDeleteVariables
  >(MUTATION_DELETE_SESSION_NOTE);

  const handleSessionNoteDelete = useCallback(
    (id: string, studentId: string) => {
      const existingSessionNote = readSessionNote(client.cache, id);
      if (existingSessionNote === null) {
        throw new Error("Unable to locate session note to delete");
      }
      const optimisticResponse: sessionNoteDelete = {
        sessionNoteDelete: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            ...existingSessionNote
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId },
        update: (cache, { data }) => {
          // make sure notes are in correct query lists
          if (studentId) {
            removeSessionNoteFromQueries(cache, studentId, id);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { sessionNoteDelete: handleSessionNoteDelete, error, data };
};

export const useSessionNoteRevertMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    sessionNoteRevert,
    sessionNoteRevertVariables
  >(MUTATION_REVERT_SESSION_NOTE);

  const handleSessionNoteRevert = useCallback(
    (id: string, studentId: string) => {
      const existingSessionNote = readSessionNote(client.cache, id);
      if (existingSessionNote === null) {
        throw new Error("Unable to locate session note to revert");
      }
      const optimisticResponse: sessionNoteRevert = {
        sessionNoteRevert: {
          __typename: "SessionNoteOutput",
          sessionNote: {
            ...existingSessionNote,
            status: SessionNoteStatusEnum.NEEDS_SIGNATURE,
            signature: null
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId },
        update: (cache, { data }) => {
          // make sure notes are in correct query lists
          if (studentId) {
            const sessionNote = data?.sessionNoteRevert?.sessionNote;

            updateActiveSessionNoteQuery(cache, studentId, id, sessionNote);
            updateSubmittedSessionNoteQuery(cache, studentId, id, sessionNote);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { sessionNoteRevert: handleSessionNoteRevert, error, data };
};

export const useSessionNoteUpdateSmartNotesMutation = () => {
  const [mutate, { error, data }] = useMutation<
    sessionNoteUpdateSmartNotes,
    sessionNoteUpdateSmartNotesVariables
  >(MUTATION_UPDATE_SMART_NOTES);

  const handleSessionNoteUpdateSmartNotes = useCallback(
    (input: SessionNoteInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return {
    sessionNoteUpdateSmartNotes: handleSessionNoteUpdateSmartNotes,
    error,
    data
  };
};

export const useSessionNotesQuery = (
  variables: ISessionNotesVariables,
  options?: UseQueryOptions
) => {
  const { loading, error, data, refetch } = useQuery<
    ISessionNotes,
    ISessionNotesVariables
  >(
    QUERY_SESSION_NOTES,
    Object.assign({}, { variables }, { ...options }) as QueryFunctionOptions<
      ISessionNotes,
      ISessionNotesVariables
    >
  );

  return { loading, error, data, refetch };
};

export const refetchSessionNotesQuery = async (
  client: ApolloClient<object>,
  studentId: string,
  statusFilter: SessionNoteStatusFilter,
  first: number
) => {
  const notes = await client.query<ISessionNotes, ISessionNotesVariables>({
    query: QUERY_SESSION_NOTES,
    variables: { studentId, statusFilter, first, days: 30 },
    fetchPolicy: "network-only"
  });
  //Cache limited query results for use offline 
  client.cache.writeQuery<ISessionNotes, ISessionNotesVariables>({
    query: QUERY_SESSION_NOTES,
    variables: { studentId, statusFilter, first },
    data: { sessionNotes: _.cloneDeep(notes.data.sessionNotes) }
  });
  return notes.data;
};

export const useActiveSessionNotesQuery = (
  studentId: string,
  options?: UseQueryOptions
) => {
  const { loading, error, data, refetch } = useQuery<
    IActiveSessionNotes,
    IActiveSessionNotesVariables
  >(
    QUERY_ACTIVE_SESSION_NOTES,
    Object.assign(
      {},
      { variables: { studentId } },
      { ...options }
    ) as QueryFunctionOptions<IActiveSessionNotes, IActiveSessionNotesVariables>
  );

  return { loading, error, data, refetch };
};

export const refetchActiveSessionNotesQuery = async (
  client: ApolloClient<object>,
  studentId: string
) => {
  const notes = await client.query<
    IActiveSessionNotes,
    IActiveSessionNotesVariables
  >({
    query: QUERY_ACTIVE_SESSION_NOTES,
    variables: { studentId },
    fetchPolicy: "network-only"
  });
  return notes.data;
};

const readStudent = (
  cache: ApolloCache<object>,
  studentId: string | null,
  optimistic = true
) => {
  if (studentId) {
    return cache.readFragment(
      {
        fragment: FRAGMENT_STUDENT,
        fragmentName: "StudentFragment",
        id: `StudentType:${studentId}`
      },
      optimistic
    );
  }

  return null;
};

const readSessionNote = (
  cache: ApolloCache<object>,
  id: string,
  optimistic = true
) => {
  const fragment = cache.readFragment<SessionNoteFragment>(
    {
      fragment: FRAGMENT_SESSION_NOTE,
      fragmentName: "SessionNoteFragment",
      id: `SessionNoteType:${id}`
    },
    optimistic
  );
  if (fragment === null) return fragment;
  return cloneDeep(fragment);
};

const readActiveSessionNotes = (
  cache: ApolloCache<object>,
  studentId: string,
  optimistic = true
): IActiveSessionNotes_sessionNotesActive[] => {
  const sessionNotesQuery = cache.readQuery<
    IActiveSessionNotes,
    IActiveSessionNotesVariables
  >(
    {
      query: QUERY_ACTIVE_SESSION_NOTES,
      variables: { studentId }
    },
    optimistic
  );

  return sessionNotesQuery?.sessionNotesActive ?? [];
};

const removeSessionNoteFromQueries = (
  cache: ApolloCache<object>,
  studentId: string,
  id: string,
  optimistic = true
) => {
  const existingNotes = readActiveSessionNotes(cache, studentId, optimistic);
  const sessionNotesActive = cloneDeep(existingNotes).filter(
    note => note.id !== id
  );

  if (sessionNotesActive.length < existingNotes.length) {
    cache.writeQuery<IActiveSessionNotes, IActiveSessionNotesVariables>({
      query: QUERY_ACTIVE_SESSION_NOTES,
      variables: { studentId },
      data: {
        sessionNotesActive
      }
    });
  }

  const variables = {
    studentId,
    first: 10000,
    statusFilter: SessionNoteStatusFilter.SUBMITTED_ONLY
  };

  const sessionNotesQuery = cache.readQuery<
    ISessionNotes,
    ISessionNotesVariables
  >(
    {
      query: QUERY_SESSION_NOTES,
      variables
    },
    optimistic
  );

  const existingSessionNotes: (ISessionNotes_sessionNotes_edges | null)[] =
    sessionNotesQuery?.sessionNotes?.edges ?? [];

  const sessionNotes = cloneDeep(existingSessionNotes).filter(
    edge => edge?.node.id !== id
  );

  if (sessionNotes.length < existingSessionNotes.length) {
    cache.writeQuery<ISessionNotes, ISessionNotesVariables>({
      query: QUERY_SESSION_NOTES,
      variables,
      data: {
        sessionNotes: {
          __typename: "SessionNoteTypeConnection",
          edges: sessionNotes
        }
      }
    });
  }
};

/**
 * update active session notes query with a session note id and optional data.
 *
 * If the data is null or the note is in the wrong status, it makes sure the note does not exist in the query.
 */
const updateActiveSessionNoteQuery = (
  cache: ApolloCache<object>,
  studentId: string,
  id: string,
  data: SessionNoteFragment | null | undefined,
  optimistic = true
) => {
  const existingNotes = readActiveSessionNotes(cache, studentId, optimistic);

  // with an optimistic response we'll be called to update twice
  let sessionNotesActive = cloneDeep(existingNotes);

  // if there's no data, or data is in wrong status, this note id is removed
  if (data && data.status !== SessionNoteStatusEnum.SUBMITTED) {
    const newSessionNote: IActiveSessionNotes_sessionNotesActive = {
      ...data,

      __typename: "SessionNoteType",
      id
    };

    const sessionNoteIndex = sessionNotesActive.findIndex(
      note => note.id === id
    );

    if (sessionNoteIndex >= 0) {
      sessionNotesActive[sessionNoteIndex] = newSessionNote;
    } else {
      sessionNotesActive.push(newSessionNote);
    }

    // API ORDER BY start DESC
    sessionNotesActive.sort((a, b) => {
      const aStart = a.start ?? "";
      const bStart = b.start ?? "";

      // ISO date strings in same offset (utc) can be lexographically sorted
      if (aStart < bStart) {
        return 1;
      } else if (aStart > bStart) {
        return -1;
      }

      return 0;
    });
  } else {
    sessionNotesActive = sessionNotesActive.filter(note => note.id !== id);
  }

  cache.writeQuery<IActiveSessionNotes, IActiveSessionNotesVariables>({
    query: QUERY_ACTIVE_SESSION_NOTES,
    variables: { studentId },
    data: {
      sessionNotesActive
    }
  });
};

/**
 * update submitted session notes query with a session note id and optional data.
 *
 * If the data is null or the note is in the wrong status, it makes sure the note does not exist in the query.
 */
const updateSubmittedSessionNoteQuery = (
  cache: ApolloCache<object>,
  studentId: string,
  id: string,
  data: SessionNoteFragment | null | undefined,
  optimistic = true
) => {
  const variables = {
    studentId,
    first: 10000,
    statusFilter: SessionNoteStatusFilter.SUBMITTED_ONLY
  };
  const sessionNotesQuery = cache.readQuery<
    ISessionNotes,
    ISessionNotesVariables
  >(
    {
      query: QUERY_SESSION_NOTES,
      variables
    },
    optimistic
  );

  const existingSessionNotes: (ISessionNotes_sessionNotes_edges | null)[] =
    sessionNotesQuery?.sessionNotes?.edges ?? [];

  let sessionNotes = cloneDeep(existingSessionNotes);

  if (data && data.status === SessionNoteStatusEnum.SUBMITTED) {
    const newSessionNote: ISessionNotes_sessionNotes_edges_node = {
      ...data,

      __typename: "SessionNoteType",
      id
    };

    const sessionNoteIndex = sessionNotes.findIndex(
      edge => edge?.node.id === id
    );

    if (sessionNoteIndex >= 0) {
      sessionNotes[sessionNoteIndex]!.node = newSessionNote;
    } else {
      sessionNotes.push({
        __typename: "SessionNoteTypeEdge",
        node: newSessionNote
      });
    }

    // API ORDER BY start DESC
    sessionNotes.sort((a, b) => {
      const aStart = a?.node.start ?? "";
      const bStart = b?.node.start ?? "";

      // ISO date strings in same offset (utc) can be lexographically sorted
      if (aStart < bStart) {
        return 1;
      } else if (aStart > bStart) {
        return -1;
      }

      return 0;
    });
  } else {
    // just try to remove id if no data or status is incorrect
    sessionNotes = sessionNotes.filter(edge => edge?.node.id !== id);
  }

  cache.writeQuery<ISessionNotes, ISessionNotesVariables>({
    query: QUERY_SESSION_NOTES,
    variables,
    data: {
      sessionNotes: {
        __typename: "SessionNoteTypeConnection",
        edges: sessionNotes
      }
    }
  });
};
