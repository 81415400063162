import {
  Col,
  Grid,
  Heading,
  IColProps,
  Link,
  Modal,
  Row
} from "@raudabaugh/thread-ui";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { IProgramItem } from "../StudentPrograms/Types";
import DiscreteTrialCard from "./DiscreteTrialCard";
import DurationCard from "./DurationCard";
import FrequencyCard from "./FrequencyCard";
import TaskAnalysisCard from "./TaskAnalysisCard";
import IntervalCard from "./IntervalCard";
import { TaskAnalysisHelper } from "../Shared/TaskAnalysisHelper";
import { DataPointStateEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import { memo, useCallback, useMemo, useState } from "react";
import { PhasePopup } from "Charts/PhasePopup";

interface IProgramDrawerProps {
  pinnedPrograms: IProgramItem[];
  sessionSkills: IProgramItem[];
  currentStudentId: string;
  studentName: string;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onEdit: (programId: string, type: ProgramTypeEnum) => void;
}

const ProgramDrawer = ({ onEdit, ...props }: IProgramDrawerProps) => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState<string>();
  const getFirstName = (name: string) => {
    return name == null || name.length === 0 ? "" : name.split(" ")[0];
  };
  const { data: activeData } = useProgramSessionsQuery(props.currentStudentId, {
    fetchPolicy: "cache-only"
  });

  const programSession = useMemo(() => {
    if (showPopup && activeData) {
      return activeData.programSessions.find(s => s.id === showPopup);
    }

    return undefined;
  }, [activeData, showPopup]);

  const handlePopup = useCallback((programId: string) => {
    setShowPopup(programId);
  }, []);

  const handlePopupClose = useCallback(() => {
    setShowPopup(undefined);
  }, []);

  const handlePopupEdit = useCallback(() => {
    if (programSession) {
      onEdit(programSession.program.id, programSession.program.type);
    }
  }, [onEdit, programSession]);

  const renderDrawer = useCallback(
    (programs: IProgramItem[], pinned: boolean) => {
      const colProps: IColProps = !pinned
        ? { xs: 24 }
        : { xl: 4, md: 6, sm: 8, xs: 12 };
      return (
        <Row type="flex">
          {programs.map((item: IProgramItem, index: number) => {
            switch (item.type) {
              case ProgramTypeEnum.INTERVAL:
                return (
                  <Col key={index} {...colProps}>
                    <IntervalCard
                      runId={item.runId!}
                      programId={item.programId}
                      totalTrials={item.totalCount}
                      trials={item.trials}
                      locked={item.locked}
                      index={index}
                      size={pinned ? "sm" : "lg"}
                      defaultResult={item.defaultResult}
                      attemptedOverride={item.attemptedOverride}
                      correctOverride={item.correctOverride}
                      title={item.name}
                      count={item.applicableCount}
                      sampleTime={item.sampleTime ?? 60}
                      startedAt={item.startedAt}
                      state={item.state}
                      currentStudentId={props.currentStudentId}
                      setLoading={setLoading}
                      onAbc={props.onAbc}
                      onChart={props.onChart}
                      item={item}
                      onPopup={handlePopup}
                    />
                  </Col>
                );
              case ProgramTypeEnum.FREQUENCY:
                return (
                  <Col key={index} {...colProps}>
                    <FrequencyCard
                      pointId={item.runId}
                      phaseId={item.phaseId}
                      programId={item.programId}
                      trials={item.trials}
                      index={index}
                      size={pinned ? "sm" : "lg"}
                      title={item.name}
                      correctOverride={item.correctOverride}
                      currentStudentId={props.currentStudentId}
                      item={item}
                      setLoading={setLoading}
                      onAbc={props.onAbc}
                      onChart={props.onChart}
                      onPopup={handlePopup}
                    />
                  </Col>
                );
              case ProgramTypeEnum.DURATION:
                return (
                  <Col key={index} {...colProps}>
                    <DurationCard
                      phaseId={item.phaseId}
                      programId={item.programId}
                      pointId={item.runId}
                      index={index}
                      size={pinned ? "sm" : "lg"}
                      title={item.name}
                      count={item.applicableCount}
                      duration={item.duration}
                      trials={item.trials}
                      durationOverride={item.durationOverride}
                      isComplete={item.isComplete}
                      currentStudentId={props.currentStudentId}
                      item={item}
                      setLoading={setLoading}
                      onAbc={props.onAbc}
                      onChart={props.onChart}
                      onPopup={handlePopup}
                    />
                  </Col>
                );
              case ProgramTypeEnum.DTT:
                return (
                  <Col key={index} {...colProps}>
                    <DiscreteTrialCard
                      size={pinned ? "sm" : "lg"}
                      programType={item.type}
                      runId={item.runId!}
                      programId={item.programId}
                      index={index}
                      prompt={item.prompt}
                      errorless={item.errorless}
                      targets={item.targets}
                      totalTrials={item.totalCount}
                      totalAttempted={item.applicableCount}
                      totalCorrect={item.correctCount}
                      title={item.name}
                      currentStudentId={props.currentStudentId}
                      setLoading={setLoading}
                      completed={
                        item.state === DataPointStateEnum.COMPLETED ||
                        item.state === DataPointStateEnum.ABANDONED
                      }
                      loading={loading}
                      item={item}
                      onAbc={props.onAbc}
                      onChart={props.onChart}
                      onPopup={handlePopup}
                    />
                  </Col>
                );
              case ProgramTypeEnum.TASK_ANALYSIS:
                return (
                  <Col key={index} {...colProps}>
                    <TaskAnalysisCard
                      size={pinned ? "sm" : "lg"}
                      locked={item.locked}
                      runId={item.runId!}
                      programId={item.programId}
                      programType={item.type}
                      index={index}
                      steps={item.steps}
                      currentStep={TaskAnalysisHelper.getNextStep(
                        item.steps,
                        item.trials
                      )}
                      item={item}
                      totalAttempted={item.attemptedCount}
                      totalApplicable={item.applicableCount}
                      totalCorrect={item.correctCount}
                      title={item.name}
                      currentStudentId={props.currentStudentId}
                      setLoading={setLoading}
                      completed={
                        item.state === DataPointStateEnum.COMPLETED ||
                        item.state === DataPointStateEnum.ABANDONED
                      }
                      onAbc={props.onAbc}
                      onChart={props.onChart}
                      onPopup={handlePopup}
                    />
                  </Col>
                );
              default:
                return null;
            }
          })}
        </Row>
      );
    },
    [handlePopup, loading, props.currentStudentId, props.onAbc, props.onChart]
  );

  return (
    <>
      {showPopup && programSession && (
        <Modal
          title={
            <Row type="flex" justify="space-between" margin="0 16px 0 0">
              <Col>{programSession.program.name || ""}</Col>
              <Heading
                margin="0 40px 0 0"
                id="Edit_Program_Definition"
                level={6}
                color="primary"
              >
                <Link
                  icon="fa-edit fas"
                  iconPosition="right"
                  onClick={handlePopupEdit}
                >
                  Edit
                </Link>
              </Heading>
            </Row>
          }
          onCancel={handlePopupClose}
          visible={true}
          centered={true}
          footer={null}
        >
          <PhasePopup
            phase={programSession.currentPhase!}
            currentPhase={programSession.currentPhase!}
            program={programSession.program!}
            studentId={props.currentStudentId}
          />
        </Modal>
      )}
      <Grid height="100%">
        <LoadingScreen loading={loading} />
        {props.pinnedPrograms.length > 0 ? (
          <Row type="flex" justify="center">
            <Col xs={24} sm={23}>
              <Row margin="16px 0" type="flex" justify="center">
                <Col xs={22} sm={24}>
                  <Heading
                    id="behavior_board"
                    level={4}
                    color="primary"
                    variation={1}
                  >
                    {getFirstName(props.studentName)}'s Pinned Board
                  </Heading>
                </Col>
              </Row>
              {renderDrawer(props.pinnedPrograms, true)}
            </Col>
          </Row>
        ) : null}
        {props.sessionSkills.length > 0 ? (
          <Row type="flex" alignItems="center" justify="center">
            <Col xs={24} sm={23}>
              <Row margin="16px 0" type="flex" justify="center">
                <Col xs={22} sm={24}>
                  <Heading level={4} color="primary" variation={1}>
                    {getFirstName(props.studentName)}'s Session
                  </Heading>
                </Col>
              </Row>
              {renderDrawer(props.sessionSkills, false)}
            </Col>
          </Row>
        ) : null}
      </Grid>
    </>
  );
};

export default memo(ProgramDrawer);
