import React, { useState } from "react";
import {
  Col,
  Heading,
  IColumnProps,
  Icon,
  Input,
  Link,
  Notification,
  Row,
  Select,
  SelectOption,
  Table
} from "@raudabaugh/thread-ui";
import { IntervalHelper } from "../Shared/IntervalHelper";
import { LabelHelper } from "../Shared/LabelHelper";
import { TrialDataFormActions } from "./TrialDataFormActions";
import { TrialDataFormItem } from "./TrialDataFormItem";
import _ from "lodash";
import { useDataPointRecordIntervalMutation } from "../DataAccess/IntervalData";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import {
  TrialInput,
  TrialResultEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";

interface IIntervalDetailProps {
  studentId: string;
  data: DataPointFragment;
  phase: PhaseFragment;
  sampleTime?: number;
  heading: JSX.Element;
  onClose: () => void;
}

export const IntervalDetail = (props: IIntervalDetailProps) => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const { dataPointRecordInterval } = useDataPointRecordIntervalMutation();
  const [editTrial, setEditTrial] = useState<{
    id?: string;
    index: number;
    input: TrialInput;
  }>();
  const [validationFailed, setValidationFailed] = useState(false);
  const dataPoint = props.data as DataPointFragment;
  const trials = _.times(props.phase.numberOfTrials ?? 0).map(resultIndex => {
    const trial: TrialInput = {
      result: IntervalHelper.getIntervalResult(
        resultIndex,
        dataPoint.attemptedOverride,
        dataPoint.correctOverride,
        props.phase.defaultTrialResult ?? TrialResultEnum.NOT_APPLICABLE,
        dataPoint.trials
      )
    };
    return trial;
  });

  const hasEditPermission = findPermission(
    threadUserContext.role,
    Permissions.EDIT_TRIALS
  );

  const showOfflineNotification = (label: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${label} function is not available while in offline mode.`
    });
  };

  const columns: Array<IColumnProps<TrialInput>> = [
    {
      dataIndex: "interval",
      key: "interval",
      render: (text: any, record: TrialInput, index: number) => {
        return (
          <>
            {IntervalHelper.getTimeLabel(
              props.sampleTime ?? 0,
              index * (props.sampleTime ?? 0)
            )}
          </>
        );
      },
      title: "Interval"
    },
    {
      dataIndex: "score",
      key: "score",
      render: (text: any, record: TrialInput, index: number) => {
        return (
          <>{LabelHelper.resultLabel(record.result ?? TrialResultEnum.NONE)}</>
        );
      },
      title: "Score"
    }
  ];

  if (hasEditPermission) {
    columns.push({
      align: "right",
      width: "60px",
      dataIndex: "edit",
      key: "edit",
      render: (text: any, record: TrialInput, index: number) => {
        return (
          <Link
            onClick={() => {
              if (!online) {
                showOfflineNotification("Edit Details");
                return;
              }
              setEditTrial({
                index,
                input: record
              });
            }}
          >
            Edit
          </Link>
        );
      }
    });
  }

  const handleSubmit = () => {
    if (editTrial) {
      const isValid = !validateResult();
      if (!isValid) {
        setValidationFailed(true);
        return;
      }
      if (editTrial.input.result) {
        dataPointRecordInterval(
          dataPoint.id,
          props.studentId,
          editTrial.input.result,
          editTrial.index
        )
          .then(() => setEditTrial(undefined))
          .catch(error =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Update the Interval"
            })
          );
      }
    }
  };

  const validateResult = () => {
    if (
      !editTrial?.input.result ||
      editTrial.input.result === TrialResultEnum.NONE
    ) {
      return "A result must be selected.";
    }
  };

  const handleResultChange = (value: string | number) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      clone.input.result = value as TrialResultEnum;
      setEditTrial(clone);
    }
  };

  if (editTrial) {
    return (
      <>
        <Row type="flex" margin="0 0 8px 0">
          <Col>
            <Link onClick={() => setEditTrial(undefined)}>
              <Icon type="fa-arrow-left fas" />
              {" View trials"}
            </Link>
          </Col>
        </Row>
        <Heading level={3}>
          {editTrial.index ? `Trial ${editTrial.index}` : "Add Trial"}
        </Heading>
        <TrialDataFormItem required={false} label="Interval">
          <Input
            disabled={true}
            value={IntervalHelper.getTimeLabel(
              props.sampleTime ?? 0,
              editTrial.index * (props.sampleTime ?? 0)
            )}
          />
        </TrialDataFormItem>
        <TrialDataFormItem
          required={true}
          label="Score"
          validationMessage={validationFailed ? validateResult() : undefined}
        >
          <Select
            value={editTrial.input.result ?? undefined}
            onChange={handleResultChange}
          >
            {Object.keys(TrialResultEnum)
              .filter(result => result !== TrialResultEnum.NONE)
              .map(result => (
                <SelectOption value={result} key={result}>
                  {LabelHelper.resultLabel(result as TrialResultEnum)}
                </SelectOption>
              ))}
          </Select>
        </TrialDataFormItem>
        <TrialDataFormActions
          onCancel={() => setEditTrial(undefined)}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <Row type="flex" margin="0 0 8px 0">
        <Col>
          <Link onClick={() => props.onClose()}>
            <Icon type="fa-arrow-left fas" /> Datapoint details
          </Link>
        </Col>
      </Row>
      {props.heading}
      <Table
        columns={columns}
        pagination={false}
        dataSource={trials}
        scroll={{ y: "250px" }}
      />
    </>
  );
};
