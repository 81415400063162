import { FetchResult, QueryFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import {
  FRAGMENT_PROGRAM_FOLDER,
  FRAGMENT_PROGRAM_TEMPLATE
} from "./Fragments";
import { DataProxy } from "@apollo/client";
import {
  QUERY_CURRICULUM,
  QUERY_CURRICULUMS,
  QUERY_CURRICULUMS_AVAILABLE,
  QUERY_CURRICULUM_FOLDER,
  QUERY_CURRICULUM_TEMPLATE,
  QUERY_CURRICULUM_TEMPLATE_SEARCH
} from "./Queries";
import {
  ICurriculumTemplateSearch,
  ICurriculumTemplateSearchVariables
} from "../Shared/Api/ICurriculumTemplateSearch";
import {
  ICurriculumFolder,
  ICurriculumFolderVariables
} from "../Shared/Api/ICurriculumFolder";
import {
  curriculumFolderCreate,
  curriculumFolderCreateVariables
} from "../Shared/Api/curriculumFolderCreate";
import {
  curriculumFolderDelete,
  curriculumFolderDeleteVariables
} from "../Shared/Api/curriculumFolderDelete";
import {
  curriculumFolderRename,
  curriculumFolderRenameVariables
} from "../Shared/Api/curriculumFolderRename";
import {
  curriculumTemplateCreate,
  curriculumTemplateCreateVariables
} from "../Shared/Api/curriculumTemplateCreate";
import {
  curriculumTemplateMove,
  curriculumTemplateMoveVariables
} from "../Shared/Api/curriculumTemplateMove";
import {
  curriculumTemplateDelete,
  curriculumTemplateDeleteVariables
} from "../Shared/Api/curriculumTemplateDelete";
import {
  curriculumTemplateUpdate,
  curriculumTemplateUpdateVariables
} from "../Shared/Api/curriculumTemplateUpdate";
import {
  curriculumTemplateRename,
  curriculumTemplateRenameVariables
} from "../Shared/Api/curriculumTemplateRename";
import _ from "lodash";
import {
  curriculumTemplateCopy,
  curriculumTemplateCopyVariables
} from "Shared/Api/curriculumTemplateCopy";
import {
  curriculumFolderMove,
  curriculumFolderMoveVariables
} from "Shared/Api/curriculumFolderMove";
import {
  curriculumFolderCopy,
  curriculumFolderCopyVariables
} from "Shared/Api/curriculumFolderCopy";
import { ICurriculums } from "Shared/Api/ICurriculums";
import { ICurriculum, ICurriculumVariables } from "Shared/Api/ICurriculum";
import { ICurriculumsAvailable } from "../Shared/Api/ICurriculumsAvailable";
import { UseQueryOptions } from "../types";
import {
  ICurriculumTemplate,
  ICurriculumTemplateVariables
} from "../Shared/Api/ICurriculumTemplate";
import { AuthenticationService } from "Security/AuthenticationService";
import { client } from "App";
import { ProgramTemplateInput } from "Shared/Api/globalTypes";
import { useCallback } from "react";

const MUTATION_FOLDER_CREATE = gql`
  mutation curriculumFolderCreate(
    $curriculumId: GUID!
    $parentId: GUID!
    $name: String!
  ) {
    curriculumFolderCreate(
      curriculumId: $curriculumId
      parentId: $parentId
      name: $name
    ) {
      folder {
        ...ProgramFolderFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

const MUTATION_FOLDER_DELETE = gql`
  mutation curriculumFolderDelete($curriculumId: GUID!, $folderId: GUID!) {
    curriculumFolderDelete(curriculumId: $curriculumId, folderId: $folderId) {
      folder {
        ...ProgramFolderFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

const MUTATION_FOLDER_RENAME = gql`
  mutation curriculumFolderRename(
    $curriculumId: GUID!
    $folderId: GUID!
    $newName: String!
  ) {
    curriculumFolderRename(
      curriculumId: $curriculumId
      folderId: $folderId
      newName: $newName
    ) {
      folder {
        ...ProgramFolderFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

const MUTATION_FOLDER_MOVE = gql`
  mutation curriculumFolderMove(
    $curriculumId: GUID!
    $folderId: GUID!
    $targetFolderId: GUID!
  ) {
    curriculumFolderMove(
      curriculumId: $curriculumId
      folderId: $folderId
      targetFolderId: $targetFolderId
    ) {
      folder {
        ...ProgramFolderFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

const MUTATION_FOLDER_COPY = gql`
  mutation curriculumFolderCopy(
    $curriculumId: GUID!
    $folderId: GUID!
    $targetFolderId: GUID!
  ) {
    curriculumFolderCopy(
      curriculumId: $curriculumId
      folderId: $folderId
      targetFolderId: $targetFolderId
    ) {
      folder {
        ...ProgramFolderFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

const MUTATION_TEMPLATE_CREATE = gql`
  mutation curriculumTemplateCreate(
    $curriculumId: GUID!
    $folderId: GUID!
    $input: ProgramTemplateInput!
  ) {
    curriculumTemplateCreate(
      curriculumId: $curriculumId
      folderId: $folderId
      input: $input
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

const MUTATION_TEMPLATE_UPDATE = gql`
  mutation curriculumTemplateUpdate(
    $curriculumId: GUID!
    $templateId: GUID!
    $input: ProgramTemplateInput!
  ) {
    curriculumTemplateUpdate(
      curriculumId: $curriculumId
      templateId: $templateId
      input: $input
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

const MUTATION_TEMPLATE_DELETE = gql`
  mutation curriculumTemplateDelete($curriculumId: GUID!, $templateId: GUID!) {
    curriculumTemplateDelete(
      curriculumId: $curriculumId
      templateId: $templateId
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

const MUTATION_TEMPLATE_MOVE = gql`
  mutation curriculumTemplateMove(
    $curriculumId: GUID!
    $templateId: GUID!
    $sourceFolderId: GUID!
    $targetFolderId: GUID!
    $targetCurriculumId: GUID
  ) {
    curriculumTemplateMove(
      curriculumId: $curriculumId
      templateId: $templateId
      sourceFolderId: $sourceFolderId
      targetFolderId: $targetFolderId
      targetCurriculumId: $targetCurriculumId
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

const MUTATION_TEMPLATE_COPY = gql`
  mutation curriculumTemplateCopy(
    $curriculumId: GUID!
    $templateId: GUID!
    $sourceFolderId: GUID!
    $targetFolderId: GUID!
    $targetCurriculumId: GUID
  ) {
    curriculumTemplateCopy(
      curriculumId: $curriculumId
      templateId: $templateId
      sourceFolderId: $sourceFolderId
      targetFolderId: $targetFolderId
      targetCurriculumId: $targetCurriculumId
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

const MUTATION_TEMPLATE_RENAME = gql`
  mutation curriculumTemplateRename(
    $curriculumId: GUID!
    $templateId: GUID!
    $newName: String!
  ) {
    curriculumTemplateRename(
      curriculumId: $curriculumId
      templateId: $templateId
      newName: $newName
    ) {
      template {
        ...ProgramTemplateFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

export const useCurriculumQuery = (id: GUID, options?: UseQueryOptions) => {
  const { loading, error, data } = useQuery<ICurriculum, ICurriculumVariables>(
    QUERY_CURRICULUM,
    Object.assign(
      {},
      {
        variables: { id }
      },
      { ...options } as QueryFunctionOptions<ICurriculum, ICurriculumVariables>
    )
  );

  return { loading, error, data };
};

export const useCurriculumsAvailableQuery = (options?: UseQueryOptions) => {
  const { loading, error, data } = useQuery<ICurriculumsAvailable>(
    QUERY_CURRICULUMS_AVAILABLE,
    Object.assign({}, { fetchPolicy: "no-cache" }, { ...options })
  );

  return { loading, error, data };
};

export const useCurriculumUploadMutation = () => {
  const handleCurriculumUpload = async (
    curriculumId: string,
    name: string,
    action: string,
    file: File
  ) => {
    const url = process.env.REACT_APP_THREADAPI_URL!.replace(
      "Api/GraphQL",
      "CurriculumApi/UploadCurriculum"
    );
    const options: RequestInit = {
      headers: AuthenticationService.getHeaders(),
      method: "POST"
    };
    const blob = file as Blob;
    options.body = new FormData();
    options.body.append("name", name);
    options.body.append("action", action);
    options.body.append("file", blob);
    var result = await fetch(url, options);
    if (result.status === 200) {
      client.query<ICurriculum, ICurriculumVariables>({
        query: QUERY_CURRICULUM,
        fetchPolicy: "no-cache",
        variables: {
          id: curriculumId
        }
      });
    } else {
      var json = await result.json();
      throw new Error(json["detail"]);
    }
  };

  return { curriculumUpload: handleCurriculumUpload };
};

export const useCurriculumFolderCreateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumFolderCreate,
    curriculumFolderCreateVariables
  >(MUTATION_FOLDER_CREATE);

  const handleCurriculumFolderCreate = useCallback(
    (curriculumId: string, parentId: string, name: string) => {
      return mutate!({
        variables: { curriculumId, parentId, name },
        update: (cache: DataProxy, data: FetchResult<any>) => {
          const result: curriculumFolderCreate = data.data;
          const folder: ICurriculumFolder | null = _.cloneDeep(
            cache.readQuery({
              query: QUERY_CURRICULUM_FOLDER,
              variables: { curriculumId, folderId: parentId }
            })
          );
          if (
            folder?.curriculumFolder?.folders &&
            result.curriculumFolderCreate
          ) {
            // Add the newly created folder to the query
            folder.curriculumFolder!.folders = [
              ...folder.curriculumFolder!.folders,
              result.curriculumFolderCreate.folder
            ];
            cache.writeQuery({
              query: QUERY_CURRICULUM_FOLDER,
              variables: { curriculumId, folderId: parentId },
              data: folder
            });
          }
        }
      });
    },
    [mutate]
  );
  return { curriculumFolderCreate: handleCurriculumFolderCreate, error, data };
};

export const useCurriculumFolderDeleteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumFolderDelete,
    curriculumFolderDeleteVariables
  >(MUTATION_FOLDER_DELETE);

  const handleCurriculumFolderDelete = useCallback(
    (curriculumId: string, folderId: string) => {
      return mutate!({
        variables: { curriculumId, folderId },
        update: (cache: DataProxy, data: FetchResult<any>) => {
          const result: curriculumFolderDelete = data.data;
          if (result.curriculumFolderDelete?.folder.parentId) {
            const folder: ICurriculumFolder | null = _.cloneDeep(
              cache.readQuery({
                query: QUERY_CURRICULUM_FOLDER,
                variables: {
                  curriculumId,
                  folderId: result.curriculumFolderDelete.folder.parentId
                }
              })
            );
            if (folder?.curriculumFolder?.folders) {
              // Remove the folder from the query
              folder.curriculumFolder!.folders =
                folder.curriculumFolder.folders.filter(f => f.id !== folderId);
              cache.writeQuery({
                query: QUERY_CURRICULUM_FOLDER,
                variables: {
                  curriculumId,
                  folderId: result.curriculumFolderDelete.folder.parentId
                },
                data: folder
              });
            }
          }
        }
      });
    },
    [mutate]
  );
  return { curriculumFolderDelete: handleCurriculumFolderDelete, error, data };
};

export const useCurriculumFolderRenameMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumFolderRename,
    curriculumFolderRenameVariables
  >(MUTATION_FOLDER_RENAME);

  const handleCurriculumFolderRename = useCallback(
    (curriculumId: string, folderId: string, newName: string) => {
      return mutate!({
        variables: { curriculumId, folderId, newName }
      });
    },
    [mutate]
  );
  return { curriculumFolderRename: handleCurriculumFolderRename, error, data };
};

export const useCurriculumFolderMoveMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumFolderMove,
    curriculumFolderMoveVariables
  >(MUTATION_FOLDER_MOVE);

  const handleCurriculumFolderMove = useCallback(
    (
      curriculumId: string,
      folderId: string,
      sourceFolderId: string,
      targetFolderId: string
    ) => {
      return mutate!({
        variables: { curriculumId, folderId, targetFolderId },
        refetchQueries: [
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId: sourceFolderId }
          },
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId: targetFolderId }
          }
        ]
      });
    },
    [mutate]
  );
  return { curriculumFolderMove: handleCurriculumFolderMove, error, data };
};

export const useCurriculumFolderCopyMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumFolderCopy,
    curriculumFolderCopyVariables
  >(MUTATION_FOLDER_COPY);

  const handleCurriculumFolderCopy = useCallback(
    (
      curriculumId: string,
      folderId: string,
      sourceFolderId: string,
      targetFolderId: string
    ) => {
      return mutate!({
        variables: { curriculumId, folderId, targetFolderId },
        refetchQueries: [
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId: sourceFolderId }
          },
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId: targetFolderId }
          }
        ]
      });
    },
    [mutate]
  );
  return { curriculumFolderCopy: handleCurriculumFolderCopy, error, data };
};

export const useCurriculumTemplateCreateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateCreate,
    curriculumTemplateCreateVariables
  >(MUTATION_TEMPLATE_CREATE);

  const handleCurriculumTemplateCreate = useCallback(
    (curriculumId: string, folderId: string, input: ProgramTemplateInput) => {
      return mutate!({
        variables: { curriculumId, folderId, input },
        update: (cache: DataProxy, data: FetchResult<any>) => {
          const result: curriculumTemplateCreate = data.data;
          const folder: ICurriculumFolder | null = _.cloneDeep(
            cache.readQuery({
              query: QUERY_CURRICULUM_FOLDER,
              variables: { curriculumId, folderId }
            })
          );
          if (
            folder?.curriculumFolder?.templates &&
            result.curriculumTemplateCreate
          ) {
            // Add the newly created folder to the query
            folder.curriculumFolder!.templates.push(
              result.curriculumTemplateCreate.template
            );
            cache.writeQuery({
              query: QUERY_CURRICULUM_FOLDER,
              variables: { curriculumId, folderId },
              data: folder
            });
          }
        }
      });
    },
    [mutate]
  );
  return {
    curriculumTemplateCreate: handleCurriculumTemplateCreate,
    error,
    data
  };
};

export const useCurriculumTemplateUpdateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateUpdate,
    curriculumTemplateUpdateVariables
  >(MUTATION_TEMPLATE_UPDATE);

  const handleCurriculumTemplateUpdate = useCallback(
    (curriculumId: string, templateId: string, input: ProgramTemplateInput) => {
      return mutate!({
        variables: { curriculumId, templateId, input }
      });
    },
    [mutate]
  );
  return {
    curriculumTemplateUpdate: handleCurriculumTemplateUpdate,
    error,
    data
  };
};

export const useCurriculumTemplateDeleteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateDelete,
    curriculumTemplateDeleteVariables
  >(MUTATION_TEMPLATE_DELETE);

  const handleCurriculumTemplateDelete = useCallback(
    (curriculumId: string, folderId: string, templateId: string) => {
      return mutate!({
        variables: { curriculumId, templateId },
        refetchQueries: [
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId }
          }
        ],
        update: (cache: DataProxy, data: FetchResult<any>) => {
          const result: curriculumTemplateDelete = data.data;
          if (result.curriculumTemplateDelete?.template.parentId) {
            const folderId = result.curriculumTemplateDelete.template.parentId;
            const folder: ICurriculumFolder | null = _.cloneDeep(
              cache.readQuery({
                query: QUERY_CURRICULUM_FOLDER,
                variables: { curriculumId, folderId }
              })
            );
            if (folder?.curriculumFolder?.templates) {
              // Remove the folder from the query
              folder.curriculumFolder!.templates =
                folder.curriculumFolder.templates.filter(
                  f => f.id !== templateId
                );
              cache.writeQuery({
                query: QUERY_CURRICULUM_FOLDER,
                variables: { curriculumId, folderId },
                data: folder
              });
            }
          }
        }
      });
    },
    [mutate]
  );
  return {
    curriculumTemplateDelete: handleCurriculumTemplateDelete,
    error,
    data
  };
};

export const useCurriculumTemplateMoveMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateMove,
    curriculumTemplateMoveVariables
  >(MUTATION_TEMPLATE_MOVE);

  const handleCurriculumTemplateMove = useCallback(
    (
      curriculumId: string,
      sourceFolderId: string,
      templateId: string,
      targetFolderId: string,
      targetCurriculumId: string
    ) => {
      return mutate!({
        variables: {
          curriculumId,
          templateId,
          sourceFolderId,
          targetFolderId,
          targetCurriculumId
        },
        refetchQueries: [
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: { curriculumId, folderId: sourceFolderId }
          },
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: {
              curriculumId: targetCurriculumId,
              folderId: targetFolderId
            }
          }
        ]
      });
    },
    [mutate]
  );
  return { curriculumTemplateMove: handleCurriculumTemplateMove, error, data };
};

export const useCurriculumTemplateCopyMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateCopy,
    curriculumTemplateCopyVariables
  >(MUTATION_TEMPLATE_COPY);

  const handleCurriculumTemplateCopy = useCallback(
    (
      curriculumId: string,
      sourceFolderId: string,
      templateId: string,
      targetFolderId: string,
      targetCurriculumId: string
    ) => {
      return mutate!({
        variables: {
          curriculumId,
          templateId,
          sourceFolderId,
          targetFolderId,
          targetCurriculumId
        },
        refetchQueries: [
          {
            query: QUERY_CURRICULUM_FOLDER,
            variables: {
              curriculumId: targetCurriculumId,
              folderId: targetFolderId
            }
          }
        ]
      });
    },
    [mutate]
  );
  return { curriculumTemplateCopy: handleCurriculumTemplateCopy, error, data };
};

export const useCurriculumTemplateRenameMutation = () => {
  const [mutate, { error, data }] = useMutation<
    curriculumTemplateRename,
    curriculumTemplateRenameVariables
  >(MUTATION_TEMPLATE_RENAME);

  const handleCurriculumTemplateRename = useCallback(
    (curriculumId: string, templateId: string, newName: string) => {
      return mutate!({
        variables: { curriculumId, templateId, newName }
      });
    },
    [mutate]
  );
  return {
    curriculumTemplateRename: handleCurriculumTemplateRename,
    error,
    data
  };
};

// Queries
export const useCurriculumsQuery = () => {
  const { loading, error, data } = useQuery<ICurriculums>(QUERY_CURRICULUMS, {
    fetchPolicy: "no-cache"
  });

  return { loading, error, data };
};

export const useCurriculumTemplateSearchQuery = (
  forString: string,
  curriculumId?: string,
  folderId?: string,
  first?: number,
  after?: Cursor
) => {
  const { loading, error, data } = useQuery<
    ICurriculumTemplateSearch,
    ICurriculumTemplateSearchVariables
  >(QUERY_CURRICULUM_TEMPLATE_SEARCH, {
    variables: { forString, curriculumId, folderId, first, after },
    fetchPolicy: "no-cache"
  });

  return { loading, error, data };
};

export const useCurriculumFolderQuery = (
  curriculumId: string,
  folderId: string
) => {
  const { loading, error, data } = useQuery<
    ICurriculumFolder,
    ICurriculumFolderVariables
  >(QUERY_CURRICULUM_FOLDER, {
    variables: { curriculumId, folderId },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  return { loading, error, data };
};

export const useCurriculumTemplateQuery = (
  curriculumId: string,
  templateId: string,
  options?: UseQueryOptions
) => {
  const { loading, error, data } = useQuery<
    ICurriculumTemplate,
    ICurriculumTemplateVariables
  >(
    QUERY_CURRICULUM_TEMPLATE,
    Object.assign(
      {},
      {
        variables: { curriculumId, templateId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
      },
      { ...options }
    ) as QueryFunctionOptions<ICurriculumTemplate, ICurriculumTemplateVariables>
  );

  return { loading, error, data };
};
