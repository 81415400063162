import { ApolloClient, ApolloCache } from "@apollo/client";
import _ from "lodash";
import {
  FRAGMENT_DATA_POINT,
  FRAGMENT_PROGRAM,
  FRAGMENT_PROGRAM_CHART,
  FRAGMENT_SESSION
} from "./Fragments";
import { DataPointFragment } from "../Shared/Api/DataPointFragment";
import { ProgramChartFragment } from "../Shared/Api/ProgramChartFragment";
import { SessionFragment } from "../Shared/Api/SessionFragment";
import {
  CollectionMethodEnum,
  DataPointStateEnum,
  DataPointTypeEnum,
  ProgramTypeEnum
} from "../Shared/Api/globalTypes";
import { QUERY_PROGRAM_SESSIONS } from "./Queries";
import moment from "moment";
import {
  IProgramSessions,
  IProgramSessionsVariables
} from "Shared/Api/IProgramSessions";
import { v5 as deterministicUuid } from "uuid";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { IProgramSessions_programSessions } from "Shared/Api/IProgramSessions";
import { buildEndIntervalDataPointFragment } from "./IntervalData";
import { buildEndTaskAnalysisDataPointFragment } from "./TaskAnalysisData";
import { buildEndDiscreteTrialDataPointFragment } from "./DiscreteTrialData";
import { buildEndDurationDataPointFragment } from "./DurationData";

export interface IDataPointRecordOutput {
  __typename: "DataPointRecordOutput";
  dataPoint: DataPointFragment;
  autoRecordDataPoints: DataPointFragment[] | null;
  autoRecordSessions: SessionFragment[] | null;
}

export class DataPointDataHelper {
  private static dateSortAsc(a: DataPointFragment, b: DataPointFragment) {
    if (a.completedAt === b.completedAt) {
      return 0;
    }
    if (a.completedAt == null) {
      return 1;
    }
    if (b.completedAt == null) {
      return -1;
    }
    return a.completedAt.localeCompare(b.completedAt);
  }

  public static readDataPoint(
    cache: ApolloCache<object>,
    pointId: string,
    clone: boolean = false,
    optimistic: boolean = true
  ) {
    const point: DataPointFragment | null = cache.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`,
      optimistic
    });
    return clone ? _.cloneDeep(point) : point;
  }

  public static readCreateDataPoint(
    cache: ApolloCache<object>,
    studentId: string,
    programId: string,
    programType: ProgramTypeEnum,
    phaseId: string,
    pointId: string,
    occurredAt: moment.Moment,
    createdById: string,
    createdByName: string,
    state: DataPointStateEnum,
    optimistic: boolean = true
  ) {
    const isBehavior =
      programType === ProgramTypeEnum.DURATION ||
      programType === ProgramTypeEnum.FREQUENCY;
    const timestamp = isBehavior
      ? occurredAt.clone().startOf("day")
      : occurredAt;
    const startedAt = isBehavior ? occurredAt.clone().startOf("day") : null;
    let point: DataPointFragment | null = this.readDataPoint(
      cache,
      isBehavior
        ? deterministicUuid(timestamp.format("YYYY-MM-DD"), phaseId)
        : pointId,
      true,
      optimistic
    );
    if (!point) {
      point = {
        __typename: "DataPointType",
        id: pointId,
        active: true,
        createdAt: timestamp.utc().toISOString(),
        createdById: createdById,
        createdByName: createdByName,
        modifiedAt: timestamp.utc().toISOString(),
        modifiedById: createdById,
        modifiedByName: createdByName,
        startedAt: startedAt ? startedAt.utc().toISOString() : null,
        phaseId: phaseId,
        programId: programId,
        studentId: studentId,
        state: state,
        method: CollectionMethodEnum.DEVICE,
        completedAt: startedAt ? startedAt.utc().toISOString() : null,
        pointType: DataPointTypeEnum.STANDARD,
        trials: [],
        attemptedOverride: null,
        correctOverride: null,
        durationOverride: null,
        softDeleted: false,
        note: null
      };
    }
    return point;
  }

  public static updateSessionDataPoint(
    cache: ApolloCache<object>,
    point: DataPointFragment,
    optimistic: boolean = true
  ) {
    let session: SessionFragment | null = _.cloneDeep(
      cache.readFragment({
        fragment: FRAGMENT_SESSION,
        fragmentName: "SessionFragment",
        id: `SessionType:${point.programId}`,
        optimistic
      })
    );
    if (session != null && session?.dataPoint?.id !== point.id) {
      session.dataPoint = _.cloneDeep(point);
      cache.writeFragment({
        id: `SessionType:${point.programId}`,
        fragment: FRAGMENT_SESSION,
        fragmentName: "SessionFragment",
        data: session
      });
    }
  }

  public static updateLastRun(
    cache: ApolloCache<object>,
    point: DataPointFragment,
    optimistic: boolean = true
  ) {
    let program: ProgramFragment | null = _.cloneDeep(
      cache.readFragment({
        fragment: FRAGMENT_PROGRAM,
        fragmentName: "ProgramFragment",
        id: `ProgramType:${point.programId}`,
        optimistic
      })
    );
    if (program != null) {
      const lastRun = moment(program.lastRun);
      const completedAt = moment(point.completedAt);
      if (completedAt.isAfter(lastRun)) {
        program.lastRun = point.completedAt;
        cache.writeFragment({
          id: `ProgramType:${point.programId}`,
          fragment: FRAGMENT_PROGRAM,
          fragmentName: "ProgramFragment",
          data: program
        });
      }
    }
  }

  public static updateChartDataPoints(
    cache: ApolloCache<object>,
    point: DataPointFragment,
    optimistic: boolean = true,
    originalPointId?: string
  ) {
    let chart: ProgramChartFragment | null = _.cloneDeep(
      cache.readFragment({
        fragment: FRAGMENT_PROGRAM_CHART,
        fragmentName: "ProgramChartFragment",
        id: `ProgramChartType:${point.programId}`,
        optimistic
      })
    );
    if (chart != null) {
      const existingPoint = chart.dataPoints.find(
        dp => dp.id === (originalPointId ?? point.id)
      );
      if (!existingPoint && point.state === DataPointStateEnum.COMPLETED) {
        chart.dataPoints = [...chart.dataPoints, _.cloneDeep(point)];
        chart.dataPoints.sort(DataPointDataHelper.dateSortAsc);
      }
      if (
        existingPoint &&
        ((originalPointId && originalPointId !== point.id) ||
          point.state !== DataPointStateEnum.COMPLETED)
      ) {
        chart.dataPoints = chart.dataPoints.filter(
          d => d.id !== (originalPointId ?? point.id)
        );
      }
      cache.writeFragment({
        fragment: FRAGMENT_PROGRAM_CHART,
        fragmentName: "ProgramChartFragment",
        id: `ProgramChartType:${point.programId}`,
        data: chart
      });
    }
  }

  public static updateChartDataPointsWithAutoRecordOutput(
    cache: ApolloCache<object>,
    output: IDataPointRecordOutput
  ) {
    const sessions = output.autoRecordSessions;
    if (sessions) {
      sessions.forEach(session => {
        const point = session.dataPoint;
        if (point) {
          DataPointDataHelper.updateChartDataPoints(cache, point);
        }
      });
    }
    const points = output.autoRecordDataPoints;
    if (points) {
      points.forEach(point => {
        DataPointDataHelper.updateChartDataPoints(cache, point);
      });
    }
  }

  public static autoRecordOptimisticResponse(
    cache: ApolloCache<object>,
    studentId: string,
    createdById: string,
    createdByName: string,
    optimisticResponse: IDataPointRecordOutput,
    optimistic: boolean = true
  ) {
    const sessions = cache.readQuery<
      IProgramSessions,
      IProgramSessionsVariables
    >({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId },
      optimistic
    });
    const pointList: DataPointFragment[] = [];
    const sessionList: SessionFragment[] = [];
    if (sessions?.programSessions) {
      sessions.programSessions
        .filter(
          s =>
            s.program.type === ProgramTypeEnum.DURATION ||
            s.program.type === ProgramTypeEnum.FREQUENCY
        )
        .forEach(s => {
          if (!s.program.archived && s.currentPhase) {
            const now = moment();
            const today = now.startOf("day");
            const pointId = deterministicUuid(
              today.format("YYYY-MM-DD"),
              s.currentPhase.id
            );
            const point: DataPointFragment = {
              __typename: "DataPointType",
              id: pointId,
              active: true,
              programId: s.program.id,
              studentId: studentId,
              state: DataPointStateEnum.COMPLETED,
              method: CollectionMethodEnum.DEVICE,
              softDeleted: false,
              note: null,
              createdAt: now.toISOString(),
              modifiedAt: now.toISOString(),
              startedAt: now.toISOString(),
              completedAt: today.toISOString(),
              correctOverride: null,
              durationOverride: null,
              attemptedOverride: null,
              pointType: DataPointTypeEnum.STANDARD,
              trials: [],
              createdById,
              createdByName,
              modifiedById: createdById,
              modifiedByName: createdByName,
              phaseId: s.currentPhase.id
            };
            if (s.session && !s.session.dataPoint) {
              const session = _.cloneDeep(s.session);
              session.dataPoint = point;
              sessionList.push(session);
            }
            if (!s.session) {
              pointList.push(point);
            }
          }
        });
    }
    optimisticResponse.autoRecordDataPoints = pointList;
    optimisticResponse.autoRecordSessions = sessionList;
  }

  public static buildEndDataPointOptimisticResponse = (
    client: ApolloClient<object>,
    program: IProgramSessions_programSessions,
    pointId: string,
    occurredAt: string,
    optimistic = true
  ) => {
    const dataPoint: DataPointFragment | null = client.readFragment(
      {
        fragment: FRAGMENT_DATA_POINT,
        fragmentName: "DataPointFragment",
        id: `DataPointType:${pointId}`
      },
      optimistic
    );

    if (!dataPoint) {
      throw Error("Could not find data point");
    }

    if (program.program.type === ProgramTypeEnum.INTERVAL) {
      return buildEndIntervalDataPointFragment(
        client.cache,
        pointId,
        occurredAt
      );
    } else if (program.program.type === ProgramTypeEnum.TASK_ANALYSIS) {
      return buildEndTaskAnalysisDataPointFragment(dataPoint, occurredAt);
    } else if (program.program.type === ProgramTypeEnum.DTT) {
      return buildEndDiscreteTrialDataPointFragment(
        dataPoint,
        program.currentPhase,
        occurredAt
      );
    } else if (program.program.type === ProgramTypeEnum.DURATION) {
      return buildEndDurationDataPointFragment(
        client.cache,
        pointId,
        occurredAt
      );
    }
  };
}
