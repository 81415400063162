import {
  Grid,
  Row,
  Heading,
  Col,
  Input,
  Icon,
  Link
} from "@raudabaugh/thread-ui";
import React, { useCallback, useEffect, useState } from "react";
import { StaffOnboardingStatusEnum } from "Shared/OnboardingApi/globalTypes";
import {
  validatePassword,
  validatePasswordLength,
  validatePasswordSymbol,
  validatePasswordDigit,
  validatePasswordLower,
  validatePasswordUpper
} from "Shared/ValidatePassword";
import { IStaffOnboardingComponent, StaffOnboardingStepEnum } from "./Types";

interface IStaffPasswordProps extends IStaffOnboardingComponent {}

export const StaffPassword = (props: IStaffPasswordProps) => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const isPasswordInvalid = useCallback(() => {
    if (!validatePassword(props.model.password ?? "")) {
      return (
        <Heading color="error" level={6}>
          Password is too weak.
        </Heading>
      );
    }
    return undefined;
  }, [props]);

  const isConfirmPasswordInvalid = useCallback(() => {
    if (props.model.password !== confirmPassword) {
      return (
        <Heading color="error" level={6}>
          Confirm password does not match.
        </Heading>
      );
    }
    return undefined;
  }, [props, confirmPassword]);

  useEffect(() => {
    props.onValidation(
      StaffOnboardingStepEnum.PASSWORD,
      !isPasswordInvalid() && !isConfirmPasswordInvalid()
    );
  }, [props.model, props, isPasswordInvalid, isConfirmPasswordInvalid]);

  interface IPasswordCriteriaProps {
    met: boolean;
    text: string;
  }

  const PasswordCriteria = (props: IPasswordCriteriaProps) => {
    return (
      <Heading level={6} margin="0 16px">
        {props.met ? (
          <Icon color="success" type="fa-check-circle fas" />
        ) : (
          <Icon type="fa-circle fas" />
        )}{" "}
        {props.text}
      </Heading>
    );
  };

  if (props.status === StaffOnboardingStatusEnum.SETUP) {
    return (
      <Grid type="flex" height="100vh" width="100%">
        <Row padding="8px 16px">
          <Heading level={5} margin="0 0 8px 0">
            A password has already been selected for your Thread Learning
            account. You will need your email and this password to log into your
            Thread Learning account. If you do not remember this password, you
            can use the FORGOT PASSWORD function to select a new password by
            completing the account setup process and visiting{" "}
            <Link href="https://thread.centralreach.com">
              thread.centralreach.com
            </Link>
            .
          </Heading>
        </Row>
      </Grid>
    );
  }
  return (
    <Grid type="flex" height="100vh" width="100%">
      <Row padding="8px 16px">
        <Heading level={5} margin="0 0 8px 0">
          You will use your email and a password to log into your Thread
          Learning account. To protect your account, your password must be a
          strong password that includes at least:
        </Heading>
        <PasswordCriteria
          text="6 characters"
          met={validatePasswordLength(props.model.password ?? "")}
        />
        <PasswordCriteria
          text="1 upper case letter"
          met={validatePasswordUpper(props.model.password ?? "")}
        />
        <PasswordCriteria
          text="1 lower case letter"
          met={validatePasswordLower(props.model.password ?? "")}
        />
        <PasswordCriteria
          text="1 digit"
          met={validatePasswordDigit(props.model.password ?? "")}
        />
        <PasswordCriteria
          text="1 non-alphanumeric character"
          met={validatePasswordSymbol(props.model.password ?? "")}
        />
        <Heading level={5} margin="8px 0 0 0">
          PLEASE KEEP YOUR PASSWORD IN A SAFE PLACE
        </Heading>
      </Row>
      <Row padding="8px 16px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              Password
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            size="large"
            type="password"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
              props.onChange({ ...props.model, password: value.target.value });
            }}
            placeholder=""
            value={props.model.password}
          />
          {props.showValidation && isPasswordInvalid()}
        </Col>
      </Row>
      <Row padding="8px 16px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              Confirm Password
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            size="large"
            type="password"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmPassword(value.target.value);
            }}
            placeholder=""
            value={confirmPassword}
          />
          {props.showValidation && isConfirmPasswordInvalid()}
        </Col>
      </Row>
    </Grid>
  );
};
