import {
  Button,
  ButtonGroup,
  ButtonTicker,
  Heading,
  IIntervalButton,
  Modal,
  Row
} from "@raudabaugh/thread-ui";
import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import React, { useEffect, useState } from "react";
import { ActionsMenu } from "./ActionsMenu";
import moment from "moment";
import { TrialFragment } from "Shared/Api/TrialFragment";
import { SmallCardLayout } from "./SmallCardLayout";
import { LargeCardLayout } from "./LargeCardLayout";
import { Icon } from "@raudabaugh/thread-ui";
import { IntervalHelper } from "Shared/IntervalHelper";
import { TrialResultEnum, CollectionMethodEnum } from "Shared/Api/globalTypes";

export interface ITickerTrialCardProps {
  title: string;
  index: number;
  interval: number;
  size: "sm" | "lg";
  start: moment.Moment | null;
  trials: TrialFragment[];
  totalTrials: number;
  locked: boolean;
  defaultResult: TrialResultEnum;
  attemptedOverride: number | null;
  correctOverride: number | null;
  method: CollectionMethodEnum;
  studentId: string;
  programId: string;
  onButtonClick: (button: IIntervalButton) => void;
  onAbcClick: () => void;
  onChartClick: () => void;
  onUndoClick?: () => void;
  onCloseClick: () => void;
  onMoveToTopClick: () => void;
  onEndClick?: () => void;
  onRunAgainClick?: () => void;
  onStart: () => void;
  onShowFocusDrawer?: () => void;
  onPopup: () => void;
  onEnterScoreSave: (
    score: number,
    date: moment.Moment,
    attempts: number | undefined
  ) => void;
}

const spacer: IIntervalButton = { label: "\xa0", seconds: -1 };

export const generateButtonLabel = (status: string) => {
  switch (status) {
    case TrialResultEnum.PLUS:
      return <Icon type="fa-plus fas" />;
    case TrialResultEnum.MINUS:
      return <Icon type="fa-minus fas" />;
    default:
      return "N/A";
  }
};

export const TickerTrialCard = (props: ITickerTrialCardProps) => {
  const [trialsElapsed, setTrialsElapsed] = useState(0);
  const [enterScoreOpen, setEnterScoreOpen] = useState(false);
  const [scoreWarningOpen, setScoreWarningOpen] = useState(false);
  const [stopped, setStopped] = useState(
    !props.start || !!props.correctOverride
  );
  const [buttons, setButtons] = useState<IIntervalButton[]>([
    spacer,
    spacer,
    spacer,
    spacer,
    spacer
  ]);
  const endedEarly = React.useCallback(() => {
    return props.attemptedOverride != null;
  }, [props.attemptedOverride]);
  const createNewButton = (status: TrialResultEnum, seconds: number) => {
    const button: IIntervalButton = {
      status,
      seconds,
      label: generateButtonLabel(status)
    };
    return button;
  };

  const handleScoreWarningContinue = () => {
    setEnterScoreOpen(true);
    setScoreWarningOpen(false);
  };
  const handleScoreWarningCancel = () => {
    setScoreWarningOpen(false);
  };

  const handleEnterScoreClose = () => {
    setEnterScoreOpen(false);
  };
  const handleEnterScoreClick = () => {
    if (
      props.trials?.filter(
        t =>
          t.result !== TrialResultEnum.NONE &&
          t.result !== TrialResultEnum.NOT_APPLICABLE
      )?.length > 0
    ) {
      setScoreWarningOpen(true);
    } else {
      setEnterScoreOpen(true);
    }
  };

  const handleButtonClick = (button: IIntervalButton) => {
    if (!props.correctOverride && props.method !== CollectionMethodEnum.PAPER) {
      button.status = IntervalHelper.toggleResult(
        button.status as TrialResultEnum
      );
      button.label = generateButtonLabel(button.status ?? "");
      props.onButtonClick(button);
    }
    return button.label;
  };

  useEffect(() => {
    if (!props.start && !stopped) {
      setStopped(true);
      return;
    }
    if (props.start) {
      const secondsSinceStart = moment().diff(props.start, "seconds");
      const elapsed = Math.floor(secondsSinceStart / props.interval);
      if (elapsed >= props.totalTrials || endedEarly()) {
        if (!stopped) {
          setStopped(true);
        }
      } else {
        if (stopped) {
          setStopped(false);
        }
      }
    }
  }, [stopped, endedEarly, props.start, props.totalTrials, props.interval]);
  useEffect(() => {
    let newButtons: IIntervalButton[] = [];
    if (props.start || !!props.correctOverride) {
      let secondsSinceStart = moment().diff(props.start, "seconds");
      if (secondsSinceStart < 0) {
        secondsSinceStart = 0;
      }
      let elapsed = Math.floor(secondsSinceStart / props.interval) + 1;
      if (endedEarly()) {
        // Show programs ended early as if they elapsed
        elapsed = props.totalTrials + 1;
      }
      let index = elapsed;
      let maxButtons = 5;
      if (elapsed === props.totalTrials) {
        // Pad end of last trial with a spacer
        newButtons = [spacer];
        index--;
      } else if (elapsed > props.totalTrials) {
        // Show all trails after program has elapsed
        elapsed = props.totalTrials;
        index = elapsed - 1;
        maxButtons = props.totalTrials;
      }
      if (elapsed !== trialsElapsed) {
        setTrialsElapsed(elapsed);
      }
      while (index >= 0 && newButtons.length < maxButtons) {
        const result = IntervalHelper.getIntervalResult(
          index,
          props.attemptedOverride,
          props.correctOverride,
          props.defaultResult,
          props.trials
        );
        const button = {
          status: result,
          seconds: index * props.interval,
          label: generateButtonLabel(result)
        };
        newButtons = [button, ...newButtons];
        index--;
      }
      setButtons(newButtons);
    }
  }, [
    props.start,
    props.attemptedOverride,
    props.correctOverride,
    endedEarly,
    props.interval,
    props.totalTrials,
    props.trials,
    props.defaultResult,
    stopped,
    trialsElapsed
  ]);

  let offset = 0;
  if (props.start) {
    const secondsSinceStart = moment().diff(props.start, "seconds");
    offset = secondsSinceStart % props.interval;
  }

  const actionProps: any = { ...props };
  delete actionProps.onEndClick;
  delete actionProps.onRunAgainClick;

  const actions = (
    <ActionsMenu
      id={`Index${props.index + props.title}DropDown`}
      onRunAgainClick={
        stopped && (props.start || !!props.correctOverride)
          ? props.onRunAgainClick
          : undefined
      }
      onEndClick={!stopped ? props.onEndClick : undefined}
      {...actionProps}
      onEnterScoreClick={!props.locked ? handleEnterScoreClick : undefined}
    />
  );
  const body =
    props.start || !!props.correctOverride ? (
      <ButtonTicker
        interval={props.interval}
        offset={offset}
        buttons={buttons}
        stopped={stopped}
        onButtonPress={handleButtonClick}
        onNewButton={(last: IIntervalButton) => {
          const recalcSeconds = moment().diff(props.start, "seconds");
          const elapsed = Math.floor(recalcSeconds / props.interval) + 1;
          setTrialsElapsed(elapsed);
          const recalcOffset = recalcSeconds % props.interval;
          if (elapsed >= props.totalTrials) {
            if (elapsed > props.totalTrials) {
              setStopped(true);
            }
            return { button: spacer, offset: recalcOffset };
          }
          const newButton: IIntervalButton = createNewButton(
            props.defaultResult,
            last.seconds + props.interval
          );
          return { button: newButton, offset: recalcOffset };
        }}
      />
    ) : props.locked || props.correctOverride ? (
      <ButtonGroup block={true}>
        <Button
          disabled={true}
          type="primary"
          size="x-large"
          onClick={props.onStart}
        >
          <Heading level={5} color="primary" variation={6}>
            {props.locked ? "Pending Decision" : ""}
          </Heading>
        </Button>
      </ButtonGroup>
    ) : (
      <ButtonGroup block={true}>
        <Button type="primary" size="x-large" onClick={props.onStart}>
          <Heading level={5} color="default" variation={1}>
            Start Interval
          </Heading>
        </Button>
      </ButtonGroup>
    );

  const enterScoreDialog = (
    <EnterScoreDialog
      scoreLabel="Correct"
      attemptsLabel="Attempted"
      defaultAttempts={props.totalTrials}
      onClose={handleEnterScoreClose}
      open={enterScoreOpen}
      onSaveScore={props.onEnterScoreSave}
      studentId={props.studentId}
      programId={props.programId}
    />
  );
  const enterScoreWarningModal = (
    <Modal
      title="Are you sure you want to enter a score?"
      afterClose={handleScoreWarningCancel}
      visible={scoreWarningOpen}
      onOk={handleScoreWarningContinue}
      onCancel={handleScoreWarningCancel}
      okText="Enter Score"
    >
      You have unsaved trials that will be overridden immediately. You can't
      undo this action.
    </Modal>
  );

  if (props.size === "sm") {
    return (
      <>
        {enterScoreDialog}
        {enterScoreWarningModal}
        <Modal
          title="Are you sure you want to enter a score?"
          afterClose={handleScoreWarningCancel}
          visible={scoreWarningOpen}
          onOk={handleScoreWarningContinue}
          onCancel={handleScoreWarningCancel}
          okText="Enter Score"
        >
          You have unsaved trials that will be overridden immediately. You can't
          undo this action.
        </Modal>
        <SmallCardLayout
          title={props.title}
          actions={actions}
          onShowFocusDrawer={props.locked ? undefined : props.onShowFocusDrawer}
          onPopup={props.onPopup}
        >
          {body}
        </SmallCardLayout>
      </>
    );
  } else {
    const totalCorrect = IntervalHelper.getTotalCorrect(
      props.method,
      props.attemptedOverride,
      props.correctOverride,
      props.defaultResult,
      props.trials,
      trialsElapsed
    );
    const totalApplicable = IntervalHelper.getTotalAttempted(
      props.method,
      props.attemptedOverride,
      props.defaultResult,
      props.trials,
      trialsElapsed
    );
    const caption = (
      <Row alignItems="center" direction="column" type="flex">
        <Heading color="default" weight="medium" level={4}>
          {totalCorrect}/{totalApplicable}
        </Heading>
        <Heading color="default" level={5}>
          {props.totalTrials ? props.totalTrials : "∞"} trials
        </Heading>
      </Row>
    );
    const minutes = props.interval % 60 === 0;
    const targets = (
      <Heading level={7} color="default">
        <Icon type="fa-stopwatch far" />{" "}
        {minutes
          ? `${props.interval / 60} minutes`
          : `${props.interval} seconds`}
      </Heading>
    );
    return (
      <>
        {enterScoreDialog}
        {enterScoreWarningModal}
        <LargeCardLayout
          title={props.title}
          actions={actions}
          caption={caption}
          targets={targets}
          onShowFocusDrawer={props.locked ? undefined : props.onShowFocusDrawer}
          onPopup={props.onPopup}
        >
          {body}
        </LargeCardLayout>
      </>
    );
  }
};
