import { Grid, Row, Heading } from "@raudabaugh/thread-ui";
import React, { useEffect } from "react";
import { LabelHelper } from "../Shared/LabelHelper";
import { IStaffOnboardingComponent, StaffOnboardingStepEnum } from "./Types";
import { StaffRoleEnum } from "Shared/Api/globalTypes";

interface IStaffInvitationProps extends IStaffOnboardingComponent {}

export const StaffInvitation = (props: IStaffInvitationProps) => {
  useEffect(() => {
    props.onValidation(StaffOnboardingStepEnum.INVITATION, true);
  }, [props]);

  return (
    <Grid type="flex" height="100vh" width="100%">
      <Row>
        <Heading level={4} margin="0 0 8px 0">
          Welcome {props.model.firstName}!
        </Heading>
        <Heading level={5} margin="8px 0">
          We are excited to have you join us as a new{" "}
          {LabelHelper.staffRoleLabel(
            props.model.role ?? StaffRoleEnum.INSTRUCTOR
          )}
          .
        </Heading>
        <Heading level={5} margin="8px 0">
          This set up process will allow you to access Thread Learning by
          CentralReach, software that we use to collaborate among our staff.
        </Heading>
        <Heading level={5} margin="8px 0">
          To start the set up process, accept this invitation by pressing the
          Accept button below.
        </Heading>
      </Row>
    </Grid>
  );
};
