import {
  Col,
  Grid,
  Heading,
  Row,
  Drawer,
  Card,
  Icon,
  Image,
  Divider,
  Tag,
  IIntervalButton,
  ButtonGroup,
  Button
} from "@raudabaugh/thread-ui";
import { IProgramItem } from "../../StudentPrograms/Types";
import { FocusBar } from "./FocusBar";
import { IntervalHelper } from "../../Shared/IntervalHelper";
import { LabelHelper } from "../../Shared/LabelHelper";
import { useState } from "react";
import {
  IntervalFocusButton,
  IntervalFocusButtonType
} from "./IntervalFocusButton";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import {
  CollectionMethodEnum,
  ReinforcementScheduleEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import summaryImage from "Shared/Images/summary.svg";
import sdImage from "Shared/Images/sd.svg";
import promptImage from "Shared/Images/prompt.svg";

interface IIntervalFocusBaseProps {
  item: IProgramItem;
  index: number;
  currentStudentId: string;
  completed: boolean;
  open: boolean;
  onClose: () => void;
  onUndoClick: () => void;
  onButtonClick: (button: IIntervalButton) => void;
  onStart: () => void;
}

const generateButtonLabel = (status: TrialResultEnum) => {
  switch (status) {
    case TrialResultEnum.PLUS:
      return <Icon type="fa-plus fas" />;
    case TrialResultEnum.MINUS:
      return <Icon type="fa-minus fas" />;
    default:
      return "N/A";
  }
};

export const IntervalFocus = (props: IIntervalFocusBaseProps) => {
  const [secondsSinceStart, setSecondsSinceStart] = useState<number>();

  useEffect(() => {
    if (props.open && props.item.startedAt) {
      let seconds = moment().diff(props.item.startedAt, "seconds");
      setSecondsSinceStart(seconds);
    }
  }, [props.open, props.item.startedAt]);

  useEffect(() => {
    if (!props.open) {
      setSecondsSinceStart(undefined);
      return;
    }
    if (secondsSinceStart !== undefined && props.item.sampleTime) {
      const secondsToNext = secondsSinceStart % (props.item.sampleTime ?? 1);
      const elapsed = Math.floor(secondsSinceStart / props.item.sampleTime);
      const endedEarly = props.item.attemptedOverride != null;
      if (elapsed < props.item.totalCount || endedEarly) {
        const period = props.item.sampleTime - secondsToNext;
        const timeout = setTimeout(() => {
          setSecondsSinceStart(moment().diff(props.item.startedAt, "seconds"));
        }, period * 1000);
        return () => clearTimeout(timeout);
      }
    }
  }, [
    props.open,
    props.item.startedAt,
    props.item.sampleTime,
    props.item.totalCount,
    props.item.attemptedOverride,
    secondsSinceStart
  ]);

  let elapsed = Math.floor(
    (secondsSinceStart ?? 0) / (props.item.sampleTime ?? 1)
  );
  if (elapsed > props.item.totalCount) {
    elapsed = props.item.totalCount;
  }

  const correct = IntervalHelper.getTotalCorrect(
    props.item.method,
    props.item.attemptedOverride,
    props.item.correctOverride,
    props.item.defaultResult,
    props.item.trials,
    elapsed
  );

  const attempted = IntervalHelper.getTotalAttempted(
    props.item.method,
    props.item.attemptedOverride,
    props.item.defaultResult,
    props.item.trials,
    elapsed
  );

  return (
    <>
      <Drawer
        key={"Drawer_" + props.index}
        mask={false}
        margin="0"
        padding="0"
        height="100%"
        placement="bottom"
        visible={props.open}
        closable={false}
      >
        <Grid
          type="flex"
          height="100vh"
          width="100%"
          color="primary"
          variation={9}
        >
          <Row type="flex" scroll="vertical">
            <Col grow={1}>
              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  onClick={props.onClose}
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <FocusBar
                    id="closeProgramDrawer"
                    heading={props.item.name}
                    icon="fa-times fas"
                    isOpen={true}
                    size="25px"
                  />
                </Card>
              </Card>

              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <Row type="flex">
                    <Col grow={1}>
                      <Heading color="default" variation={1} level={6}>
                        {props.item.totalCount} trials • {correct}/{attempted}{" "}
                        correct
                      </Heading>
                    </Col>
                    <Col grow={0}>
                      {props.item.applicableCount > 0 &&
                        props.item.method !== CollectionMethodEnum.PAPER && (
                          <Icon
                            onClick={props.onUndoClick}
                            type="fa-undo fas"
                            size="25px"
                            width={"50px"}
                          />
                        )}
                    </Col>
                  </Row>
                </Card>
              </Card>

              {props.item.programDescription && (
                <Row padding="10px 0 0 0" margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={summaryImage} alt="Summary" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.programDescription}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.discriminativeStimulus && (
                <Row margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={sdImage} alt="SD" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.discriminativeStimulus}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.prompt !== "" && (
                <Row margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={promptImage} alt="Prompt" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.prompt}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.reinforcementSchedule !==
                ReinforcementScheduleEnum.NONE && (
                <Row margin="15px 15px" type="flex">
                  <Tag color="primary" maxWidth="10em">
                    {LabelHelper.reinforcementScheduleShortLabel(
                      props.item.reinforcementSchedule
                    )}
                    {props.item.reinforcement}
                  </Tag>
                  {props.item.reinforcementType}
                </Row>
              )}
              <Divider />
              <Row type="flex" margin="0 16px">
                {!props.item.startedAt &&
                props.item.method !== CollectionMethodEnum.PAPER ? (
                  <ButtonGroup>
                    <Button
                      type="primary"
                      size="x-large"
                      onClick={props.onStart}
                    >
                      <Heading level={5} color="default" variation={1}>
                        Start Interval
                      </Heading>
                    </Button>
                  </ButtonGroup>
                ) : (
                  _.times(props.item.totalCount).map(buttonIndex => {
                    const result = IntervalHelper.getIntervalResult(
                      buttonIndex,
                      props.item.attemptedOverride,
                      props.item.correctOverride,
                      props.item.defaultResult,
                      props.item.trials
                    );
                    const endedEarly = props.item.attemptedOverride != null;
                    let type: IntervalFocusButtonType = "future";
                    if (elapsed > buttonIndex || endedEarly) {
                      type = "past";
                    } else if (elapsed === buttonIndex) {
                      type = "current";
                    }
                    const button: IIntervalButton = {
                      status: result,
                      seconds: (props.item.sampleTime ?? 0) * buttonIndex,
                      label: generateButtonLabel(result)
                    };

                    return (
                      <IntervalFocusButton
                        key={buttonIndex}
                        button={button}
                        type={type}
                        interval={props.item.sampleTime ?? 0}
                        method={props.item.method}
                        onButtonClick={props.onButtonClick}
                      />
                    );
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Grid>
      </Drawer>
    </>
  );
};
