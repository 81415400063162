import gql from "graphql-tag";
import {
  FRAGMENT_DATA_POINT,
  FRAGMENT_PHASE,
  FRAGMENT_PROGRAM,
  FRAGMENT_PROGRAM_SESSION,
  FRAGMENT_SESSION,
  FRAGMENT_USER
} from "./Fragments";
import _ from "lodash";
import {
  useMutation,
  useQuery,
  ApolloClient,
  QueryFunctionOptions,
  useSubscription,
  ApolloCache,
  useApolloClient
} from "@apollo/client";
import {
  QUERY_PROGRAMS_FROM_ARCHIVE,
  QUERY_PROGRAM_SESSIONS,
  QUERY_PROGRAM_CHART,
  QUERY_USER,
  QUERY_ACTIVE_MESSAGES
} from "./Queries";
import {
  IProgramsFromArchive,
  IProgramsFromArchiveVariables
} from "../Shared/Api/IProgramsFromArchive";
import { UseQueryOptions, UseSubscriptionOptions } from "../types";
import {
  ArchiveTypeEnum,
  DataPointStateEnum,
  DrawerEnum,
  IntervalTypeEnum,
  LockedTypeEnum,
  ProgramInput,
  ProgramTypeEnum,
  ReinforcementScheduleEnum,
  StepStateEnum,
  TargetStateEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import {
  IProgramChart,
  IProgramChartVariables
} from "Shared/Api/IProgramChart";
import {
  IProgramSessions,
  IProgramSessionsVariables
} from "Shared/Api/IProgramSessions";
import {
  programSetArchived,
  programSetArchivedVariables
} from "Shared/Api/programSetArchived";
import {
  programActivate,
  programActivateVariables
} from "Shared/Api/programActivate";
import {
  programDeactivate,
  programDeactivateVariables
} from "Shared/Api/programDeactivate";
import {
  programDeactivateAll,
  programDeactivateAllVariables
} from "Shared/Api/programDeactivateAll";
import {
  programSetDeactivated,
  programSetDeactivatedVariables
} from "Shared/Api/programSetDeactivated";
import {
  programSessionEndAll_programSessionEndAll_dataPointOutputs_dataPoint,
  programSessionEndAll_programSessionEndAll_dataPointOutputs,
  programSessionEndAll_programSessionEndAll,
  programSessionEndAll,
  programSessionEndAllVariables
} from "Shared/Api/programSessionEndAll";
import {
  programUnlock,
  programUnlockVariables
} from "Shared/Api/programUnlock";
import {
  programCreate,
  programCreateVariables
} from "Shared/Api/programCreate";
import {
  programUpdate,
  programUpdateVariables
} from "Shared/Api/programUpdate";
import {
  programOnUpdate,
  programOnUpdateVariables
} from "Shared/Api/programOnUpdate";
import {
  programSessionOnUpdate,
  programSessionOnUpdateVariables
} from "Shared/Api/programSessionOnUpdate";
import {
  programChartOnUpdate,
  programChartOnUpdateVariables
} from "Shared/Api/programChartOnUpdate";
import {
  phaseOnUpdate,
  phaseOnUpdateVariables
} from "Shared/Api/phaseOnUpdate";
import { programMove, programMoveVariables } from "Shared/Api/programMove";
import { useCallback } from "react";
import { v4 } from "uuid";
import moment from "moment";
import { ProgramSessionFragment } from "Shared/Api/ProgramSessionFragment";
import { IUser, IUserVariables } from "Shared/Api/IUser";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import {
  SUBSCRIPTION_PHASE_ON_UPDATE,
  SUBSCRIPTION_PROGRAM_CHART_ON_UPDATE,
  SUBSCRIPTION_PROGRAM_ON_UPDATE,
  SUBSCRIPTION_PROGRAM_SESSION_ON_UPDATE
} from "./Subscriptions";
import {
  ProgramFragment,
  ProgramFragment_targets
} from "Shared/Api/ProgramFragment";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { IActiveMessages } from "Shared/Api/IActiveMessages";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { IntervalHelper } from "Shared/IntervalHelper";

const MUTATION_SET_ARCHIVE = gql`
  mutation programSetArchived(
    $id: GUID!
    $studentId: GUID!
    $archived: Boolean!
    $archiveType: ArchiveTypeEnum
  ) {
    programSetArchived(
      id: $id
      studentId: $studentId
      archive: $archived
      archiveType: $archiveType
    ) {
      program {
        ...ProgramFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM}
`;

const MUTATION_SET_DEACTIVATED = gql`
  mutation programSetDeactivated(
    $id: GUID!
    $studentId: GUID!
    $deactivated: Boolean!
  ) {
    programSetDeactivated(
      id: $id
      studentId: $studentId
      deactivated: $deactivated
    ) {
      program {
        ...ProgramFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM}
`;

const MUTATION_UNLOCK = gql`
  mutation programUnlock($id: GUID!, $studentId: GUID!) {
    programUnlock(id: $id, studentId: $studentId) {
      program {
        ...ProgramFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM}
`;
const MUTATION_ACTIVATE = gql`
  mutation programActivate($id: GUID!, $studentId: GUID!, $pointId: GUID!) {
    programActivate(id: $id, studentId: $studentId, pointId: $pointId) {
      programSession {
        ...ProgramSessionFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
  ${FRAGMENT_USER}
`;

const MUTATION_DEACTIVATE = gql`
  mutation programDeactivate(
    $id: GUID!
    $studentId: GUID!
    $confirmed: Boolean!
  ) {
    programDeactivate(id: $id, studentId: $studentId, confirmed: $confirmed) {
      programSession {
        ...ProgramSessionFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
  ${FRAGMENT_USER}
`;

const MUTATION_DEACTIVATE_ALL = gql`
  mutation programDeactivateAll(
    $studentId: GUID!
    $confirmed: Boolean!
    $occurredAt: DateTime
  ) {
    programDeactivateAll(
      studentId: $studentId
      confirmed: $confirmed
      occurredAt: $occurredAt
    ) {
      programSessions {
        ...ProgramSessionFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
  ${FRAGMENT_USER}
`;

const MUTATION_MOVE = gql`
  mutation programMove($id: GUID!, $studentId: GUID!, $index: Int!) {
    programMove(id: $id, studentId: $studentId, index: $index) {
      sessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_SESSION}
`;

const MUTATION_UPDATE = gql`
  mutation programUpdate(
    $phaseId: GUID!
    $studentId: GUID!
    $input: ProgramInput!
  ) {
    programUpdate(phaseId: $phaseId, studentId: $studentId, input: $input) {
      programSession {
        ...ProgramSessionFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
`;

const MUTATION_CREATE = gql`
  mutation programCreate(
    $studentId: GUID!
    $programId: GUID!
    $phaseId: GUID!
    $phaseStart: DateTime!
    $input: ProgramInput!
  ) {
    programCreate(
      studentId: $studentId
      programId: $programId
      phaseId: $phaseId
      phaseStart: $phaseStart
      input: $input
    ) {
      programSession {
        ...ProgramSessionFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
`;

const MUTATION_PROGRAM_SESSION_END_ALL = gql`
  mutation programSessionEndAll($studentId: GUID!, $occurredAt: DateTime!) {
    programSessionEndAll(studentId: $studentId, occurredAt: $occurredAt) {
      dataPointOutputs {
        dataPoint {
          ...DataPointFragment
        }
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

export const useProgramUpdateMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programUpdate,
    programUpdateVariables
  >(MUTATION_UPDATE);

  const handleProgramUpdate = useCallback(
    (
      programId: string,
      phaseId: string,
      studentId: string,
      input: ProgramInput
    ) => {
      const existingSession = readProgramSession(client.cache, programId);
      const existingTargets = existingSession.program.targets;

      // we allow historical editing now, this phase might not be the current phase
      const isCurrentPhase =
        existingSession.currentPhase === null ||
        phaseId === existingSession.currentPhase.id;
      const phaseFragment = mapProgramInputToPhaseFragment(phaseId, input);

      const optimisticResponse: programUpdate = {
        programUpdate: {
          __typename: "ProgramEditOutput",
          programSession: {
            __typename: "ProgramSessionType",
            id: programId,
            program: mapProgramInputToProgramFragment(
              programId,
              input,
              existingTargets
            ),
            session: existingSession.session,
            currentPhase: isCurrentPhase
              ? phaseFragment
              : existingSession.currentPhase
          }
        }
      };

      // HACK: if the edited phase isn't the current phase, we won't get a fragment update from the mutation, do it ourselves
      if (!isCurrentPhase) {
        client.cache.writeFragment<PhaseFragment>({
          id: `PhaseType:${phaseId}`,
          fragment: FRAGMENT_PHASE,
          fragmentName: "PhaseFragment",
          data: phaseFragment
        });
      }

      return mutate!({
        optimisticResponse,
        variables: { phaseId, studentId, input },
        update: (cache, mutationResult) => {
          var programSession =
            mutationResult.data?.programUpdate?.programSession;
          if (programSession?.currentPhase) {
            updateProgramChartQuery(
              cache,
              studentId,
              programSession.program,
              programSession.currentPhase
            );
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { programUpdate: handleProgramUpdate, error, data };
};

export const useProgramCreateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    programCreate,
    programCreateVariables
  >(MUTATION_CREATE);

  const handleProgramCreate = useCallback(
    (studentId: string, programId: string | undefined, input: ProgramInput) => {
      if (!programId) {
        programId = v4();
      }
      const phaseId = v4();
      const phaseStart = moment().toISOString();
      const optimisticResponse: programCreate = {
        programCreate: {
          __typename: "ProgramEditOutput",
          programSession: {
            __typename: "ProgramSessionType",
            id: programId,
            program: mapProgramInputToProgramFragment(programId, input),
            session: null,
            currentPhase: mapProgramInputToPhaseFragment(phaseId, input)
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { studentId, programId, phaseId, phaseStart, input },
        update: (cache, mutationResult) => {
          var programSession =
            mutationResult.data?.programCreate?.programSession;
          if (programSession?.currentPhase) {
            updateProgramSessionsQuery(
              cache,
              studentId,
              programSession.id,
              programSession
            );
            updateProgramChartQuery(
              cache,
              studentId,
              programSession.program,
              programSession.currentPhase
            );
          }
        }
      });
    },
    [mutate]
  );
  return { programCreate: handleProgramCreate, error, data };
};

const buildMoveOptimisticResponse = (
  cache: ApolloCache<object>,
  studentId: string,
  programId: string,
  drawerPos: number,
  optimistic: boolean = true
) => {
  // Get all the programs for the student
  const programs = _.cloneDeep(
    cache.readQuery<IProgramSessions, IProgramSessionsVariables>({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId },
      optimistic
    })
  );
  if (programs == null) {
    throw new Error("Unable to find programs affected by move");
  }

  const program = programs.programSessions.find(s => s.id === programId);
  if (!program || !program.session) {
    throw new Error("Unable to find program being moved");
  }

  // Filter for programs that are active in the specific drawer and sort by drawer position
  const programSessions = programs.programSessions
    .filter(
      s =>
        s.session &&
        s.program.drawer === program.program.drawer &&
        s.id !== programId
    )
    .sort(
      (a, b) =>
        (a.session?.drawerPosition ?? 0) - (b.session?.drawerPosition ?? 0)
    )
    .map(s => s.session!);

  // Place program being moved in new location
  programSessions.splice(drawerPos, 0, program.session);

  // Renumber drawer positions
  for (let pos = 0; pos < programSessions.length; pos++) {
    programSessions[pos].drawerPosition = pos;
  }

  return programSessions;
};

const readProgramSession = (
  cache: ApolloCache<object>,
  id: string,
  optimistic: boolean = true
): ProgramSessionFragment => {
  const fragmentId = `ProgramSessionType:${id}`;
  let programSession: ProgramSessionFragment | null = _.cloneDeep(
    cache.readFragment({
      fragment: FRAGMENT_PROGRAM_SESSION,
      fragmentName: "ProgramSessionFragment",
      id: fragmentId,
      optimistic
    })
  );
  if (!programSession) {
    throw new Error("Cannot find program session fragment with given id");
  }
  return programSession;
};

export const useProgramMoveMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programMove,
    programMoveVariables
  >(MUTATION_MOVE);

  const handleProgramMove = useCallback(
    (id: string, studentId: string, index: number) => {
      const optimisticResponse: programMove = {
        programMove: {
          __typename: "ProgramMoveOutput",
          sessions: buildMoveOptimisticResponse(
            client.cache,
            studentId,
            id,
            index
          )
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId, index }
      });
    },
    [client.cache, mutate]
  );
  return {
    programMove: handleProgramMove,
    error,
    data
  };
};

export const useProgramSetArchivedMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programSetArchived,
    programSetArchivedVariables
  >(MUTATION_SET_ARCHIVE);
  const handleProgramSetArchived = useCallback(
    (
      id: string,
      studentId: string,
      archived: boolean,
      archiveType: ArchiveTypeEnum
    ) => {
      const program = readProgram(client.cache, id);
      program.archived = archived;
      program.archiveType = archiveType;
      const optimisticResponse: programSetArchived = {
        programSetArchived: {
          __typename: "ProgramOutput",
          program
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId, archived, archiveType },
        update: (cache, mutationResult) => {
          var program = mutationResult.data?.programSetArchived?.program;
          if (program) {
            updateProgramSessionsQuery(cache, studentId, program.id);
          }
        }
      });
    },
    [client.cache, mutate]
  );

  return { programSetArchived: handleProgramSetArchived, error, data };
};

export const useProgramSetDeactivatedMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programSetDeactivated,
    programSetDeactivatedVariables
  >(MUTATION_SET_DEACTIVATED);

  const handleProgramSetDeactivated = useCallback(
    (id: string, studentId: string, deactivated: boolean) => {
      const program = readProgram(client.cache, id);
      program.locked = false;
      const optimisticResponse: programSetDeactivated = {
        programSetDeactivated: {
          __typename: "ProgramOutput",
          program
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId, deactivated }
      });
    },
    [client.cache, mutate]
  );

  return { programSetDeactivated: handleProgramSetDeactivated, error, data };
};

export const useProgramUnlockMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programUnlock,
    programUnlockVariables
  >(MUTATION_UNLOCK);
  const handleProgramUnlock = useCallback(
    (id: string, studentId: string) => {
      const program = readProgram(client.cache, id);
      program.locked = false;
      const optimisticResponse: programUnlock = {
        programUnlock: {
          __typename: "ProgramOutput",
          program
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId },
        update: (cache, { data }) => {
          if (data?.programUnlock) {
            updateActiveMessagesQuery(cache, data.programUnlock.program);
          }
        }
      });
    },
    [client.cache, mutate]
  );

  return { programUnlock: handleProgramUnlock, error, data };
};

// Queries
export const useProgramsFromArchiveQuery = (
  variables: IProgramsFromArchiveVariables,
  options?: UseQueryOptions
) => {
  return useQuery<IProgramsFromArchive, IProgramsFromArchiveVariables>(
    QUERY_PROGRAMS_FROM_ARCHIVE,
    Object.assign(
      {},
      {
        variables
      },
      { ...options }
    ) as QueryFunctionOptions<
      IProgramsFromArchive,
      IProgramsFromArchiveVariables
    >
  );
};

export const refetchProgramSessionsQuery = async (
  client: ApolloClient<object>,
  studentId: string
) => {
  const sessions = await client.query<
    IProgramSessions,
    IProgramSessionsVariables
  >({
    query: QUERY_PROGRAM_SESSIONS,
    variables: { studentId },
    fetchPolicy: "network-only"
  });
  return sessions.data;
};

export const useProgramSessionsQuery = (
  studentId: string,
  options?: UseQueryOptions
) => {
  return useQuery<IProgramSessions, IProgramSessionsVariables>(
    QUERY_PROGRAM_SESSIONS,
    Object.assign(
      {},
      { variables: { studentId } },
      { ...options }
    ) as QueryFunctionOptions<IProgramSessions, IProgramSessionsVariables>
  );
};

export const refetchProgramChartQuery = async (
  client: ApolloClient<object>,
  studentId: string,
  programId: string
) => {
  const chart = await client.query<IProgramChart, IProgramChartVariables>({
    query: QUERY_PROGRAM_CHART,
    variables: { studentId, programId, mostRecentLimit: true },
    fetchPolicy: "network-only"
  });
  // Cache limited query results for use offline
  client.cache.writeQuery<IProgramChart, IProgramChartVariables>({
    query: QUERY_PROGRAM_CHART,
    variables: { studentId, programId },
    data: { programChart: _.cloneDeep(chart.data.programChart) }
  });
  return chart.data;
};

export const useProgramChartQuery = (
  studentId: string,
  programId: string,
  mostRecentLimit?: number,
  options?: UseQueryOptions
) => {
  return useQuery<IProgramChart, IProgramChartVariables>(
    QUERY_PROGRAM_CHART,
    Object.assign(
      {},
      { variables: { studentId, programId, mostRecentLimit } },
      { ...options }
    ) as QueryFunctionOptions<IProgramChart, IProgramChartVariables>
  );
};

const updateActiveMessagesQuery = (
  cache: ApolloCache<object>,
  program: ProgramFragment,
  optimistic: boolean = true
) => {
  const messages = _.cloneDeep(
    cache.readQuery<IActiveMessages>({
      query: QUERY_ACTIVE_MESSAGES,
      optimistic
    })
  );
  if (messages?.messagesActive) {
    messages.messagesActive = messages.messagesActive.filter(
      m => m.programId !== program.id
    );
    cache.writeQuery({
      query: QUERY_ACTIVE_MESSAGES,
      data: messages
    });
  }
};

const readUserAndUpdateHasActivePrograms = (
  cache: ApolloCache<object>,
  userId: string,
  studentId: string,
  activating: boolean,
  optimistic: boolean = true
) => {
  const programs = _.cloneDeep(
    cache.readQuery<IProgramSessions, IProgramSessionsVariables>({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId },
      optimistic
    })
  );
  const user = _.cloneDeep(
    cache.readQuery<IUser, IUserVariables>({
      query: QUERY_USER,
      variables: { id: null },
      optimistic
    })
  );
  if (!user?.user || !programs) {
    throw new Error("Failed to update active program status");
  }
  var assigned = user.user.assignedStudents.find(
    s => s.studentId === studentId
  );
  if (!assigned) {
    throw new Error("Failed to update active program status");
  }
  if (activating && assigned.hasActivePrograms === false) {
    assigned.hasActivePrograms = true;
  }
  if (
    !activating &&
    programs.programSessions.filter(ps => ps.session).length === 1
  ) {
    assigned.hasActivePrograms = false;
  }
  return user.user;
};

export const useProgramActivateMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    programActivate,
    programActivateVariables
  >(MUTATION_ACTIVATE);
  const handleProgramActivate = useCallback(
    (id: string, studentId: string) => {
      var session = readProgramSession(client.cache, id);
      var dataPoint = createDataPoint(
        client.cache,
        studentId,
        session,
        threadUserContext.userId,
        threadUserContext.userName
      );
      session.session = {
        __typename: "SessionType",
        id: id,
        drawerPosition: 1000,
        dataPoint: dataPoint,
        phase: session.currentPhase
      };
      var user = readUserAndUpdateHasActivePrograms(
        client.cache,
        threadUserContext.userId,
        studentId,
        true
      );
      return mutate({
        optimisticResponse: {
          programActivate: {
            __typename: "ProgramSessionOutput",
            programSession: session,
            user: user
          }
        },
        variables: {
          id,
          studentId,
          pointId: dataPoint?.id ?? v4()
        }
      });
    },
    [client.cache, mutate, threadUserContext.userId, threadUserContext.userName]
  );

  return { programActivate: handleProgramActivate, error, data };
};

export const useProgramDeactivateMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    programDeactivate,
    programDeactivateVariables
  >(MUTATION_DEACTIVATE);
  const handleProgramDeactivate = useCallback(
    (id: string, studentId: string, confirmed: boolean) => {
      const session = readProgramSession(client.cache, id);
      const point = session.session?.dataPoint;
      session.session = null;
      if (point && point.state === DataPointStateEnum.COMPLETED) {
        session.program.lastRun = point.completedAt;
      }
      const user = readUserAndUpdateHasActivePrograms(
        client.cache,
        threadUserContext.userId,
        studentId,
        false
      );
      return mutate({
        optimisticResponse: {
          programDeactivate: {
            __typename: "ProgramSessionOutput",
            programSession: session,
            user: user
          }
        },
        variables: {
          id,
          studentId,
          confirmed
        }
      });
    },
    [client.cache, mutate, threadUserContext.userId]
  );

  return { programDeactivate: handleProgramDeactivate, error, data };
};

export const useProgramDeactivateAllMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    programDeactivateAll,
    programDeactivateAllVariables
  >(MUTATION_DEACTIVATE_ALL);
  const handleProgramDeactivateAll = useCallback(
    (studentId: string) => {
      const programs = _.cloneDeep(
        client.cache.readQuery<IProgramSessions, IProgramSessionsVariables>({
          query: QUERY_PROGRAM_SESSIONS,
          variables: { studentId },
          optimistic: true
        })
      );
      const user = _.cloneDeep(
        client.cache.readQuery<IUser, IUserVariables>({
          query: QUERY_USER,
          variables: { id: null },
          optimistic: true
        })
      );
      if (!user?.user || !programs) {
        throw new Error(
          "Failed to build optimistic response for programDeactivateAll"
        );
      }
      const sessions = programs.programSessions.filter(ps => ps.session);
      sessions.forEach(ps => {
        if (
          !ps.session?.dataPoint ||
          ps.session.dataPoint.trials.length === 0 ||
          ps.session.dataPoint.state === DataPointStateEnum.COMPLETED
        ) {
          if (
            ps.program.type === ProgramTypeEnum.INTERVAL &&
            ps.session?.dataPoint?.startedAt &&
            IntervalHelper.getTrialsElapsed(
              moment(ps.session?.dataPoint?.startedAt),
              ps.currentPhase?.lengthOfEachInterval ?? 1,
              ps.currentPhase?.numberOfTrials ?? 1,
              ps.session?.dataPoint?.attemptedOverride ?? null
            ) < (ps.currentPhase?.numberOfTrials ?? 1)
          ) {
            return;
          }
          const point = ps.session?.dataPoint;
          ps.session = null;
          if (point && point.state === DataPointStateEnum.COMPLETED) {
            ps.program.lastRun = point.completedAt;
          }
        }
      });
      var assigned = user.user.assignedStudents.find(
        s => s.studentId === studentId
      );
      if (!assigned) {
        throw new Error(
          "Failed to build optimistic response for programDeactivateAll"
        );
      }
      assigned.hasActivePrograms = false;
      const occurredAt = moment().utc().toISOString();
      return mutate({
        optimisticResponse: {
          programDeactivateAll: {
            __typename: "ProgramDeactivateAllOutput",
            programSessions: sessions,
            user: user.user
          }
        },
        variables: {
          studentId,
          confirmed: false,
          occurredAt
        }
      });
    },
    [client.cache, mutate]
  );

  return { programDeactivateAll: handleProgramDeactivateAll, error, data };
};

export const useProgramSessionEndAllMutation = (studentId: string) => {
  const [mutate, { client, error, data }] = useMutation<
    programSessionEndAll,
    programSessionEndAllVariables
  >(MUTATION_PROGRAM_SESSION_END_ALL);
  const handleProgramSessionEndAll = useCallback(
    (studentId: string) => {
      const occurredAt = moment().utc().toISOString();
      const programSessionEndAllOptimisticResponse =
        buildEndDataPointsOptimisticResponse(client, studentId, occurredAt);
      return mutate({
        optimisticResponse: {
          programSessionEndAll: programSessionEndAllOptimisticResponse
        },
        variables: {
          studentId,
          occurredAt
        },
        update: (cache, { data }) => {
          const points = data?.programSessionEndAll?.dataPointOutputs;
          points?.forEach(point => {
            if (point) {
              DataPointDataHelper.updateChartDataPoints(cache, point.dataPoint);
              DataPointDataHelper.updateLastRun(cache, point.dataPoint);
            }
          });
        }
      });
    },
    [client, mutate]
  );

  return { programSessionEndAll: handleProgramSessionEndAll, error, data };
};

export const useIncompleteProgramSessionsQuery = (studentId: string) => {
  const client = useApolloClient();

  const getIncompleteProgramSessions = useCallback(() => {
    const programs = client.cache.readQuery<
      IProgramSessions,
      IProgramSessionsVariables
    >({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId },
      optimistic: true
    });
    if (programs == null) {
      return null;
    }
    const incompletePrograms = programs.programSessions.filter(
      ps =>
        ps.session?.dataPoint &&
        ps.session.dataPoint.trials.length !== 0 &&
        ps.session.dataPoint.state !== DataPointStateEnum.COMPLETED &&
        ps.session.dataPoint.state !== DataPointStateEnum.ABANDONED
    );
    return incompletePrograms;
  }, [client, studentId]);

  return { getIncompleteProgramSessions };
};

export const useProgramOnUpdateSubscription = (
  studentIds: string[],
  options?: UseSubscriptionOptions<programOnUpdate, programOnUpdateVariables>
) => {
  return useSubscription<programOnUpdate, programOnUpdateVariables>(
    SUBSCRIPTION_PROGRAM_ON_UPDATE,
    {
      variables: { studentIds },
      ...options
    }
  );
};

export const useProgramPhaseOnUpdateSubscription = (
  studentIds: string[],
  options?: UseSubscriptionOptions<phaseOnUpdate, phaseOnUpdateVariables>
) => {
  return useSubscription<phaseOnUpdate, phaseOnUpdateVariables>(
    SUBSCRIPTION_PHASE_ON_UPDATE,
    {
      variables: { studentIds },
      ...options
    }
  );
};

export const useProgramSessionOnUpdateSubscription = (
  studentIds: string[],
  options?: UseSubscriptionOptions<
    programSessionOnUpdate,
    programSessionOnUpdateVariables
  >
) => {
  return useSubscription<
    programSessionOnUpdate,
    programSessionOnUpdateVariables
  >(SUBSCRIPTION_PROGRAM_SESSION_ON_UPDATE, {
    variables: { studentIds },
    ...options
  });
};

export const useProgramChartOnUpdateSubscription = (
  programId: string,
  options?: UseSubscriptionOptions<
    programChartOnUpdate,
    programChartOnUpdateVariables
  >
) => {
  return useSubscription<programChartOnUpdate, programChartOnUpdateVariables>(
    SUBSCRIPTION_PROGRAM_CHART_ON_UPDATE,
    {
      variables: { programId },
      ...options
    }
  );
};

const mapProgramInputToProgramFragment = (
  programId: string,
  input: ProgramInput,
  existingTargets: ProgramFragment_targets[] = []
) => {
  // input.targets is a filtered list of program targets, needs merging
  const targets = [...existingTargets];
  input.targets?.forEach(inputTarget => {
    const targetsIndex = targets.findIndex(t => t.id === inputTarget.id);
    const mappedInputTarget: ProgramFragment_targets = {
      __typename: "TargetType",
      ...inputTarget,
      targetDescription: inputTarget.targetDescription || "",
      mastered: inputTarget.mastered || null,
      completed: inputTarget.completed || false,
      state: inputTarget.state || TargetStateEnum.CURRENT,
      maintenanceMinusCount: 0,
      maintenancePlusCount: 0,
      lastMaintenanceDateTime: null
    };

    if (targetsIndex >= 0) {
      // replace
      targets[targetsIndex] = mappedInputTarget;
    } else {
      // append
      targets.push(mappedInputTarget);
    }
  });

  const program: ProgramFragment = {
    __typename: "ProgramType",
    id: programId,
    drawer: input.drawer || DrawerEnum.PRIVATE_DRAWER,
    pinned: input.pinned || false,
    type: input.programType || ProgramTypeEnum.DTT,
    archived: false,
    archiveType: ArchiveTypeEnum.NONE,
    locked: false,
    lockedType: LockedTypeEnum.NONE,
    lastRun: null,
    name: input.programName || "",
    programGoal: input.programGoal || "",
    prerequisiteSkillsNeeded: input.prerequisiteSkillsNeeded || "",
    intervalType: input.intervalType || IntervalTypeEnum.NONE,
    targets,
    templateId: input.templateId || null,
    curriculumId: input.curriculumId || null
  };
  return program;
};

const mapProgramInputToPhaseFragment = (
  phaseId: string,
  input: ProgramInput
) => {
  const phase: PhaseFragment = {
    __typename: "PhaseType",
    id: phaseId,
    criterionForMastery: input.criterionForMastery
      ? input.criterionForMastery.map(c => {
          return {
            __typename: "CriterionType",
            id: c.id,
            pointsAnalyzed: c.pointsAnalyzed ?? 0,
            minPercentage: c.minPercentage ?? 0
          };
        })
      : [],
    steps: input.steps
      ? input.steps.map(s => {
          return {
            __typename: "StepType",
            id: s.id,
            description: s.description || null,
            state: s.state || StepStateEnum.ACTIVE,
            softDeleted: s.softDeleted
          };
        })
      : [],
    targetIds:
      input.phaseTargetIds ??
      (input.targets
        ? input.targets
            .filter(t => t.state === TargetStateEnum.CURRENT)
            .map(t => t.id)
        : []),
    prompt: input.prompt || null,
    criterionForMasteryText: null,
    numberOfTrials: input.numberOfTrials || null,
    typeOfReinforcement: input.typeOfReinforcement || null,
    errorless: input.errorless || false,
    unlimitedTrials: input.unlimitedTrials || false,
    defaultTrialResult: input.defaultTrialResult || TrialResultEnum.NONE,
    procedureDetails: input.procedureDetails || null,
    materials: input.materials || null,
    promptingProcedure: input.promptingProcedure || null,
    tips: input.tips || null,
    definitionOfBehavior: input.definitionOfBehavior || null,
    unitGoal: input.unitGoal || null,
    baselineText: input.baselineText || null,
    frequencyOfDataCollectionText: input.frequencyOfDataCollectionText || null,
    phaseSummary: input.phaseSummary || null,
    phaseNameOverride: input.phaseNameOverride || null,
    reinforcementSchedule:
      input.reinforcementSchedule || ReinforcementScheduleEnum.NONE,
    reinforcementRatio: input.reinforcementRatio || null,
    instructionalCue: input.instructionalCue || null,
    lengthOfEachInterval: input.lengthOfEachInterval || null,
    minimumRequiredTrials: input.minimumRequiredTrials || 0
  };
  return phase;
};

const readProgram = (cache: ApolloCache<object>, programId: string) => {
  const program = _.cloneDeep(
    cache.readFragment<ProgramFragment>({
      fragment: FRAGMENT_PROGRAM,
      fragmentName: "ProgramFragment",
      id: `ProgramType:${programId}`
    })
  );
  if (program === null) {
    throw new Error("Unable to locate program.");
  }
  return program;
};

const createDataPoint = (
  cache: ApolloCache<object>,
  studentId: string,
  session: ProgramSessionFragment,
  createdById: string,
  createdByName: string,
  optimistic: boolean = true
) => {
  if (!session.currentPhase) {
    return null;
  }
  const now = moment();
  const pointId = v4();
  const point = DataPointDataHelper.readCreateDataPoint(
    cache,
    studentId,
    session.program.id,
    session.program.type,
    session.currentPhase.id,
    pointId,
    now,
    createdById,
    createdByName,
    DataPointStateEnum.NOT_STARTED,
    optimistic
  );
  return point;
};

const updateProgramSessionsQuery = (
  cache: ApolloCache<object>,
  studentId: string,
  programId: string,
  data: ProgramSessionFragment | undefined = undefined,
  optimistic: boolean = true
) => {
  const sessions = _.cloneDeep(
    cache.readQuery<IProgramSessions, IProgramSessionsVariables>({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId },
      optimistic
    })
  );

  if (!sessions) {
    // Query has not been cached yet
    return;
  }
  if (data && data.program.archived === false) {
    const index = sessions.programSessions.findIndex(s => s.id === programId);

    if (index < 0) {
      sessions.programSessions.push(data);
    } else {
      sessions.programSessions[index] = data;
    }
  } else {
    sessions.programSessions = sessions.programSessions.filter(
      s => s.id !== programId
    );
  }

  cache.writeQuery({
    query: QUERY_PROGRAM_SESSIONS,
    variables: { studentId },
    data: sessions
  });
};

const updateProgramChartQuery = (
  cache: ApolloCache<object>,
  studentId: string,
  program: ProgramFragment,
  phase: PhaseFragment
) => {
  let chart = cache.readQuery<IProgramChart, IProgramChartVariables>({
    query: QUERY_PROGRAM_CHART,
    variables: { studentId, programId: program.id }
  });

  if (chart == null) {
    // create chart for new programs
    chart = {
      programChart: {
        __typename: "ProgramChartType",
        id: program.id,
        mostRecentLimit: null,
        program: _.cloneDeep(program),
        dataPoints: [],
        phases: []
      }
    };
  } else {
    chart = _.cloneDeep(chart);
  }

  if (!chart.programChart.phases.find(p => p.id === phase.id)) {
    // add new phase to chart
    chart.programChart.phases.push(_.cloneDeep(phase));
  }

  cache.writeQuery({
    query: QUERY_PROGRAM_CHART,
    variables: { studentId, programId: program.id },
    data: chart
  });
};

const buildEndDataPointsOptimisticResponse = (
  client: ApolloClient<object>,
  studentId: string,
  occurredAt: string
) => {
  const programs = _.cloneDeep(
    client.cache.readQuery<IProgramSessions, IProgramSessionsVariables>({
      query: QUERY_PROGRAM_SESSIONS,
      variables: { studentId }
    })
  );
  const filteredPrograms = programs?.programSessions?.filter(ps => {
    const active = ps.session !== null && !ps.program.archived;
    const canBeEnded =
      ps.program.type === ProgramTypeEnum.INTERVAL ||
      ps.program.type === ProgramTypeEnum.DURATION ||
      ps.program.drawer === DrawerEnum.PRIVATE_DRAWER;
    return active && canBeEnded;
  });
  const points = filteredPrograms?.map(p => p.session!.dataPoint) ?? [];
  let dataPointOutputs: (programSessionEndAll_programSessionEndAll_dataPointOutputs | null)[] =
    [];
  points.forEach(point => {
    if (point) {
      const fragment = DataPointDataHelper.readDataPoint(
        client.cache,
        point.id
      );
      if (fragment) {
        const programIndex =
          filteredPrograms?.findIndex(
            fp => fp.program.id === point.programId
          ) ?? -1;
        if (filteredPrograms && programIndex > -1) {
          const program = filteredPrograms[programIndex];
          if (
            (program.program.type !== ProgramTypeEnum.DTT &&
              program.program.type !== ProgramTypeEnum.TASK_ANALYSIS) ||
            point.trials.length > 0
          ) {
            const endedDataPoint =
              DataPointDataHelper.buildEndDataPointOptimisticResponse(
                client,
                program,
                point.id,
                occurredAt
              );
            const dataPoint: programSessionEndAll_programSessionEndAll_dataPointOutputs =
              {
                __typename: "DataPointOutput",
                dataPoint:
                  endedDataPoint as programSessionEndAll_programSessionEndAll_dataPointOutputs_dataPoint
              };
            dataPointOutputs.push(dataPoint);
          }
        }
      }
    }
  });
  return {
    __typename: "ProgramSessionEndAllOutput",
    dataPointOutputs: dataPointOutputs
  } as programSessionEndAll_programSessionEndAll;
};
