import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormItem,
  Grid,
  Heading,
  Image,
  Input,
  Modal,
  Row,
  Select,
  SelectOption,
  TimePicker
} from "@raudabaugh/thread-ui";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { useHistory } from "react-router";
import moment from "moment";
import { IABCDataByStudent_aBCDataByStudent } from "Shared/Api/IABCDataByStudent";
import { ABCDataInput } from "Shared/Api/globalTypes";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { IProgramSessions_programSessions_program } from "Shared/Api/IProgramSessions";
import abcImage from "./abc-data-collection.svg";
interface IAbcDataFormProps {
  canEdit: boolean;
  studentId: string;
  programId?: string;
  defaultBehaviorText?: string;
  existingData?: IABCDataByStudent_aBCDataByStudent;
  onSave: (input: ABCDataInput) => void;
}

export const AbcDataForm = (props: IAbcDataFormProps) => {
  const [selectedProgram, setSelectedProgram] = useState<
    IProgramSessions_programSessions_program | undefined
  >();

  const [programs, setPrograms] = useState<
    IProgramSessions_programSessions_program[] | undefined
  >();
  const [values, setValues] = useState<ABCDataInput>({
    date: props.existingData ? props.existingData.date : moment().toISOString(),
    antecedent: props.existingData ? props.existingData.antecedent : "",
    behavior: props.existingData
      ? props.existingData.behavior
      : props.defaultBehaviorText,
    consequence: props.existingData ? props.existingData.consequence : "",
    programId:
      props.existingData && props.existingData.programId
        ? props.existingData.programId
        : props.programId,
    note: props.existingData ? props.existingData.note : "",
    setting: props.existingData ? props.existingData.setting : ""
  });
  const history = useHistory();

  const {
    loading: stdLoading,
    error: stdError,
    data: stdData
  } = useProgramSessionsQuery(props.studentId);

  const changeFieldValue = (field: string, value: any) => {
    const newValues = Object.assign({}, values, {
      [field]: value
    });
    setValues(newValues);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleCancel = () => {
    if (isValid()) {
      Modal.warning({
        okCancel: true,
        title: "Do you want to save your changes before leaving?",
        content:
          "You can save any changes you just made to this ABC data so they’ll be here for later. Otherwise, those changes will be lost.",
        onCancel: () => goBack(),
        onOk: () => handleSave(),
        okText: "Save Changes",
        cancelText: "Don't Save"
      });
    } else {
      Modal.warning({
        okCancel: true,
        title: "Discard all changes and close ABC Data form?",
        content:
          "The changes you just made to this ABC data cannot be saved. If you leave this form those changes will be lost.",
        onOk: () => goBack(),
        okText: "Yes",
        cancelText: "No"
      });
    }
  };

  const handleChangeTime = (date: moment.Moment, dateString: string) => {
    if (date) {
      const newDate = moment(values.date)
        .clone()
        .set({
          hour: date.get("hour"),
          minute: date.get("minute"),
          second: date.get("second")
        });
      changeFieldValue("date", newDate.toISOString());
    }
  };

  const handleChangeDate = (date: moment.Moment, dateString: string) => {
    if (date) {
      const newDate = moment(date)
        .clone()
        .set({
          year: date.get("year"),
          month: date.get("month"),
          date: date.get("date")
        });
      changeFieldValue("date", newDate.toISOString());
    }
  };

  const handleSave = () => {
    props.onSave(values);
  };

  const isValid = () => {
    if (
      values.antecedent === "" ||
      values.behavior === "" ||
      values.consequence === ""
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (stdData) {
      setSelectedProgram(
        stdData.programSessions?.find(ps => ps.program.id === values!.programId)
          ?.program
      );
      setPrograms(stdData.programSessions?.map(ps => ps.program));
    }

    if (stdError) {
      NotificationsHelper.ErrorNotification({
        error: stdError,
        title: "Failed to Load ABC Data",
        refreshPageOnClose: true
      });
    }
  }, [stdData, stdError, values]);

  return !programs ? (
    <></>
  ) : (
    <>
      <LoadingScreen loading={stdLoading} />
      <Grid margin="16px 16px 0 16px">
        <LoadingScreen loading={false} />
        <Row type="flex" justify="space-between" align="middle">
          <Col
            xs={0}
            xxl={2}
            xl={2}
            lg={3}
            md={3}
            sm={4}
            margin="0 16px 16px 0"
          >
            <Row type="flex" justify="center">
              <Image src={abcImage} />
            </Row>
          </Col>
          <Col type="flex" grow={1}>
            <Heading level={3}>ABC Data Collection</Heading>
          </Col>
        </Row>

        <Form>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Service Date
              </Heading>
            </Row>
            <DatePicker
              placeholder=""
              value={moment(values.date)}
              disabled={!props.canEdit}
              onChange={handleChangeDate}
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Service Time
              </Heading>
            </Row>
            <TimePicker
              id="startTime"
              size="large"
              format="h:mm a"
              use12Hours={true}
              disabled={!props.canEdit}
              onChange={handleChangeTime}
              value={moment(values.date)}
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading
                level={5}
                weight="medium"
                margin="6px 0 6px 6px"
                color="error"
              >
                * &nbsp;
              </Heading>
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Antecedent
              </Heading>
            </Row>
            <Input
              value={values!.antecedent!}
              placeholder=""
              disabled={!props.canEdit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeFieldValue("antecedent", event.target.value)
              }
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading
                level={5}
                weight="medium"
                margin="6px 0 6px 6px"
                color="error"
              >
                * &nbsp;
              </Heading>
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Behavior
              </Heading>
            </Row>
            <Input
              value={values!.behavior!}
              placeholder=""
              disabled={!props.canEdit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeFieldValue("behavior", event.target.value)
              }
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading
                level={5}
                weight="medium"
                margin="6px 0 6px 6px"
                color="error"
              >
                * &nbsp;
              </Heading>
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Consequence
              </Heading>
            </Row>
            <Input
              value={values!.consequence!}
              placeholder=""
              disabled={!props.canEdit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeFieldValue("consequence", event.target.value)
              }
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Associated Program
              </Heading>
            </Row>
            <Select
              dropdownMatchSelectWidth
              disabled={!props.canEdit}
              defaultValue={selectedProgram?.name ?? ""}
              value={selectedProgram?.name ?? ""}
              onChange={(value: any) => changeFieldValue("programId", value)}
            >
              {programs.map(p => (
                <SelectOption value={p.id} key={p.id}>
                  {p.name}
                </SelectOption>
              ))}
            </Select>
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Note
              </Heading>
            </Row>
            <Input
              value={values!.note!}
              placeholder=""
              disabled={!props.canEdit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeFieldValue("note", event.target.value)
              }
            />
          </FormItem>
          <FormItem>
            <Row type="flex" margin="0">
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                Setting
              </Heading>
            </Row>
            <Input
              value={values!.setting!}
              placeholder=""
              disabled={!props.canEdit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeFieldValue("setting", event.target.value)
              }
            />
          </FormItem>
          {props.canEdit && (
            <Row padding="0 16px 0 16px" type="flex" justify="end">
              <Col grow={1} padding="0 0 0 8px">
                {
                  // tslint:disable-next-line:jsx-no-lambda
                  <Button size="large" block={true} onClick={handleCancel}>
                    Cancel
                  </Button>
                }
              </Col>
              <Col grow={1} padding="0 8px 0 0">
                <Button
                  size="large"
                  block={true}
                  type="primary"
                  disabled={!isValid()}
                  onClick={handleSave}
                >
                  Save ABC Data
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Grid>
    </>
  );
};
