import gql from "graphql-tag";
import {
  FRAGMENT_DATA_POINT,
  FRAGMENT_MESSAGE,
  FRAGMENT_PHASE,
  FRAGMENT_PROGRAM,
  FRAGMENT_PROGRAM_CHART,
  FRAGMENT_PROGRAM_SESSION
} from "./Fragments";

export const SUBSCRIPTION_DATA_POINT_ON_UPDATE = gql`
  subscription dataPointOnUpdate($studentIds: [GUID!]) {
    dataPointOnUpdate(studentIds: $studentIds) {
      studentId
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

export const SUBSCRIPTION_PHASE_ON_UPDATE = gql`
  subscription phaseOnUpdate($studentIds: [GUID!]) {
    phaseOnUpdate(studentIds: $studentIds) {
      studentId
      phase {
        ...PhaseFragment
      }
    }
  }
  ${FRAGMENT_PHASE}
`;

export const SUBSCRIPTION_PROGRAM_ON_UPDATE = gql`
  subscription programOnUpdate($studentIds: [GUID!]) {
    programOnUpdate(studentIds: $studentIds) {
      studentId
      program {
        ...ProgramFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM}
`;

export const SUBSCRIPTION_PROGRAM_SESSION_ON_UPDATE = gql`
  subscription programSessionOnUpdate($studentIds: [GUID!]) {
    programSessionOnUpdate(studentIds: $studentIds) {
      studentId
      programSession {
        ...ProgramSessionFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
`;

export const SUBSCRIPTION_PROGRAM_CHART_ON_UPDATE = gql`
  subscription programChartOnUpdate($programId: GUID!) {
    programChartOnUpdate(programId: $programId) {
      studentId
      chart {
        ...ProgramChartFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM_CHART}
`;

export const SUBSCRIPTION_MESSAGE_ON_UPDATE = gql`
  subscription messageOnUpdate {
    messageOnUpdate {
      message {
        ...MessageFragment
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;
