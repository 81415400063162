import {
  BaseMutationOptions,
  QueryFunctionOptions,
  useMutation,
  useQuery
} from "@apollo/client";
import { UseMutationOptions, UseQueryOptions } from "../types";
import { QUERY_CLINICAL_FILES_BY_STUDENT } from "./Queries";
import {
  IClinicalFilesByStudent,
  IClinicalFilesByStudentVariables
} from "../Shared/Api/IClinicalFilesByStudent";
import {
  clinicalFileSoftDelete,
  clinicalFileSoftDeleteVariables
} from "../Shared/Api/clinicalFileSoftDelete";
import { FRAGMENT_CLINICAL_FILE } from "./Fragments";
import gql from "graphql-tag";
import { AuthenticationService } from "Security/AuthenticationService";
import { v4 as uuid } from "uuid";
import { ClinicalFileTypeEnum } from "Shared/Api/globalTypes";
import { useCallback } from "react";

export interface ClinicalFileInput {
  name: string;
  fileArea: ClinicalFileTypeEnum;
  fileAreaOther: string | null;
  contentType: string;
  createdById: string;
  createdByName: string;
}

const MUTATION_SOFT_DELETE = gql`
  mutation clinicalFileSoftDelete($fileId: GUID!, $studentId: GUID!) {
    clinicalFileSoftDelete(fileId: $fileId, studentId: $studentId) {
      clinicalFile {
        ...ClinicalFileFragment
      }
    }
  }
  ${FRAGMENT_CLINICAL_FILE}
`;

export const useClinicalFilesByStudentQuery = (
  studentId: GUID,
  options?: UseQueryOptions
) => {
  const { loading, error, data, refetch } = useQuery<
    IClinicalFilesByStudent,
    IClinicalFilesByStudentVariables
  >(
    QUERY_CLINICAL_FILES_BY_STUDENT,
    Object.assign(
      {},
      {
        variables: { studentId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
      },
      { ...options }
    ) as QueryFunctionOptions<
      IClinicalFilesByStudent,
      IClinicalFilesByStudentVariables
    >
  );

  return { loading, error, data, refetch };
};

export const useClinicalFileUploadMutation = () => {
  const handleClinicalFileUpload = async (
    studentId: string,
    input: ClinicalFileInput,
    file: File
  ) => {
    const url = process.env.REACT_APP_THREADAPI_URL!.replace(
      "Api/GraphQL",
      "ClinicalApi/UploadClinicalFile"
    );
    const options: RequestInit = {
      headers: AuthenticationService.getHeaders(),
      method: "POST"
    };
    const blob = file as Blob;
    const fileId = uuid();
    options.body = new FormData();
    options.body.append("studentId", studentId);
    options.body.append("fileId", fileId);
    options.body.append("name", input.name);
    options.body.append("fileArea", input.fileArea);
    options.body.append("fileAreaOther", input.fileAreaOther ?? "");
    options.body.append("file", blob);
    var result = await fetch(url, options);
    if (result.status !== 200) {
      var json = await result.json();
      throw new Error(json["detail"]);
    }
  };

  return { clinicalFileUpload: handleClinicalFileUpload };
};

export const useClinicalFileSoftDeleteMutation = (
  options?: UseMutationOptions
) => {
  const [mutate, { error, data }] = useMutation<
    clinicalFileSoftDelete,
    clinicalFileSoftDeleteVariables
  >(
    MUTATION_SOFT_DELETE,
    options as BaseMutationOptions<
      clinicalFileSoftDelete,
      clinicalFileSoftDeleteVariables
    >
  );

  const handleClinicalFileSoftDelete = useCallback(
    (fileId: string, studentId: string) => {
      return mutate!({
        refetchQueries: [
          {
            query: QUERY_CLINICAL_FILES_BY_STUDENT,
            variables: {
              studentId
            }
          }
        ],
        variables: { fileId, studentId }
      });
    },
    [mutate]
  );

  return { clinicalFileSoftDelete: handleClinicalFileSoftDelete, error, data };
};
