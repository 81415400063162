import { Modal, IModalProps } from "@raudabaugh/thread-ui";
import { memo, ReactNode, useCallback, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

interface InterceptedNavigation {
  // these are just recreations of History Action and Location
  // couldn't get them to import from react-router
  action: "POP" | "PUSH" | "REPLACE";
  location: {
    pathname: string;
    hash: string;
    search: string;
    state: unknown;
  };
}

export interface DiscardChangesModalProps extends IModalProps {
  // implied by Modal component generics
  children?: ReactNode;

  // props not part of Modal
  intercept: boolean;
  setVisible: (value: boolean) => void;
}

export const DiscardChangesModal = ({
  intercept,
  onCancel,
  onOk,
  visible,
  setVisible,

  // defaults for modal
  title = "Discard Changes?",
  children = "Are you sure you want to Cancel? All changes you made will be discarded.",
  okText = "Yes, discard",
  cancelText = "No",

  // prop we want to exclude - will issue dev only warning
  footer,

  // the rest will be passed to Modal as is
  ...props
}: DiscardChangesModalProps) => {
  const history = useHistory();
  const [interceptedNavigation, setInterceptedNavigation] =
    useState<InterceptedNavigation>();

  const handlePromptIntercept = useCallback(
    (location, action) => {
      // this assumes the modal is a true modal, so any intercepted navigation while its visible
      // is considered to be from the modal's logic itself and thus allowed.
      if (intercept && !visible) {
        setVisible(true);
        setInterceptedNavigation({ action, location });

        // cancels the navigation
        return false;
      }

      return true;
    },
    [intercept, visible, setVisible]
  );

  const handleOk = useCallback(() => {
    if (interceptedNavigation) {
      switch (interceptedNavigation.action) {
        case "PUSH":
          history.push(interceptedNavigation.location);
          break;

        case "POP":
          // assumes we're never going back more than 1 history item
          history.goBack();
          break;

        case "REPLACE":
          history.replace(interceptedNavigation.location);
          break;
      }
    } else {
      return onOk?.();
    }
  }, [history, interceptedNavigation, onOk]);

  const handleCancel = useCallback(() => {
    setInterceptedNavigation(undefined);
    return onCancel?.();
  }, [onCancel]);

  // warn developer if they specified a custom footer we're going to filter out
  if (process.env.NODE_ENV === "development" && footer) {
    console.warn(
      "DiscardChangesModal: footer prop will be ignored, the default buttons are essential to function correctly"
    );
  }

  return (
    <>
      <Prompt when={intercept} message={handlePromptIntercept} />
      <Modal
        {...props}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        title={title}
        okText={okText}
        cancelText={cancelText}
      >
        {children}
      </Modal>
    </>
  );
};

export default memo(DiscardChangesModal);
