import { Grid, Row, Illustration, Heading } from "@raudabaugh/thread-ui";
import React from "react";
import warningImage from "./undraw_warning_cyit.svg";

export const OfflinePageUnavailable = () => {
  return (
    <Grid height="100vh">
      <Row padding="0 0 32px 0">
        <Illustration src={warningImage} topOffset={45} />
      </Row>
      <Row padding="0 16px">
        <Heading level={3}>You are currently offline</Heading>
      </Row>
      <Row padding="8px 16px">
        <Heading level={5}>
          This functionality is not available while in offline mode. Please
          connect to the internet as soon as possible to have full application
          functionality.
        </Heading>
      </Row>
    </Grid>
  );
};
