import {
  Card,
  Col,
  Grid,
  Heading,
  Row,
  Button,
  Link
} from "@raudabaugh/thread-ui";
import { EditGuardian } from "./EditGuardian";
import { validateEmail } from "../../Shared/ValidateEmail";
import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import {
  useStudentUpdatePrimaryGuardianMutation,
  useStudentUpdateSecondaryGuardianMutation
} from "DataAccess/StudentData";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IGuardianBaseProps {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  primaryGuardian: boolean;
  canEdit: boolean;
  setLoadingScreen: (value: boolean) => void;
}
export interface IGuardianState {
  error?: ApolloError;
}

export const Guardian = (props: IGuardianBaseProps) => {
  const [editGuardian, setEditGuardian] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | null>(props.firstName);
  const [lastName, setLastName] = useState<string | null>(props.lastName);
  const [email, setEmail] = useState<string | null>(props.email);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    props.phoneNumber
  );

  const {
    updateStudentPrimaryGuardian
  } = useStudentUpdatePrimaryGuardianMutation();
  const {
    updateStudentSecondaryGuardian
  } = useStudentUpdateSecondaryGuardianMutation();

  const submitCheck = () => {
    if (
      (firstName !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        !validateEmail(email!)) ||
      !props.primaryGuardian
    ) {
      return false;
    }
    return true;
  };

  const editGuardianValue = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = event.target.value;
    switch (field) {
      case "firstName":
        setFirstName(val);
        break;
      case "lastName":
        setLastName(val);
        break;
      case "email":
        setEmail(val);
        break;
      case "phoneNumber":
        setPhoneNumber(val);
        break;
    }
  };

  const editInfo = () => {
    setEditGuardian(true);
  };

  const cancelEditGuardian = () => {
    setEditGuardian(false);
    setEmail(props.email);
    setPhoneNumber(props.phoneNumber);
    setFirstName(props.firstName);
    setLastName(props.lastName);
  };

  const saveEditedInfo = () => {
    setEditGuardian(!editGuardian);
    props.setLoadingScreen(true);
    if (props.primaryGuardian) {
      updateStudentPrimaryGuardian(
        props.id,
        email,
        firstName,
        lastName,
        phoneNumber
      )
        .then(() => props.setLoadingScreen(false))
        .catch((error: ApolloError) =>
          NotificationsHelper.ErrorNotification({
            error,
            title: "Action Failed"
          })
        );
    } else {
      updateStudentSecondaryGuardian(
        props.id,
        email,
        firstName,
        lastName,
        phoneNumber
      )
        .then(() => props.setLoadingScreen(false))
        .catch((error: ApolloError) =>
          NotificationsHelper.ErrorNotification({
            error,
            title: "Action Failed"
          })
        );
    }
  };

  return (
    <>
      <Card
        margin={"16px 0px"}
        variation={1}
        width="100%"
        title={
          <Row type="flex" justify="space-between">
            <Col>
              {props.primaryGuardian ? "Primary" : "Secondary"} Guardian Info
            </Col>
            {props.canEdit && (
              <Heading
                id={
                  props.primaryGuardian
                    ? "primaryGuardianEdit"
                    : "secondaryEditGuardian"
                }
                level={6}
                color="primary"
              >
                <Link
                  icon="fa-edit fas"
                  iconPosition="right"
                  onClick={editInfo}
                >
                  Edit
                </Link>
              </Heading>
            )}
          </Row>
        }
      >
        {!editGuardian ? (
          <Grid>
            <Row margin="0px 0px 12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  First Name
                </Heading>
                <Heading level={5}>
                  {props.firstName ? props.firstName : "N/A"}
                </Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  Last Name
                </Heading>
                <Heading level={5}>
                  {props.lastName ? props.lastName : "N/A"}
                </Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  Email
                </Heading>
                <Heading level={5}>{props.email ? props.email : "N/A"}</Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  Phone Number
                </Heading>
                <Heading level={5}>
                  {props.phoneNumber ? props.phoneNumber : "N/A"}
                </Heading>
              </Col>
            </Row>
          </Grid>
        ) : (
          <Row>
            <EditGuardian
              firstName={firstName!}
              lastName={lastName!}
              email={email!}
              phoneNumber={phoneNumber!}
              onChangeText={editGuardianValue}
              secondary={!props.primaryGuardian}
            />
            <Row type="flex">
              <Col margin="0px 12px 0px 0px">
                <Button
                  disabled={submitCheck()}
                  size="large"
                  type="primary"
                  onClick={saveEditedInfo}
                >
                  Save
                </Button>
              </Col>
              <Col>
                <Button size="large" onClick={cancelEditGuardian}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        )}
      </Card>
    </>
  );
};
