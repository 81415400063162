import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { client } from "../App";
import { RoutePathEnum } from "./RoutePathEnum";
import { ApolloProvider } from "@apollo/client";
import { StaffOnboardingComplete } from "Onboarding/StaffOnboardingComplete";
import { StaffInvitationInvalid } from "Onboarding/StaffInvitationInvalid";
import { StaffInvitationDeclined } from "Onboarding/StaffInvitationDeclined";
import { StaffOnboardingAborted } from "Onboarding/StaffOnboardingAborted";
import { validateGuid } from "Shared/ValidateGuid";
import { OnboardingStaff } from "Onboarding/OnboardStaff";

interface IStaffOnboardingParams {
  id: string;
  orgId: string;
  code: string;
}

const StaffOnboarding = () => {
  const { id, orgId, code } = useParams<IStaffOnboardingParams>();
  if (!validateGuid(id) || !validateGuid(orgId)) {
    return <Redirect to={RoutePathEnum.ONBOARDING_STAFF_INVALID} />;
  }
  return <OnboardingStaff staffId={id} orgId={orgId} code={code} />;
};

export const OnboardingRouter = () => {
  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path={RoutePathEnum.ONBOARDING_STAFF} exact={true}>
          <StaffOnboarding />
        </Route>
        <Route path={RoutePathEnum.ONBOARDING_STAFF_COMPLETE} exact={true}>
          <StaffOnboardingComplete />
        </Route>
        <Route path={RoutePathEnum.ONBOARDING_STAFF_INVALID} exact={true}>
          <StaffInvitationInvalid />
        </Route>
        <Route path={RoutePathEnum.ONBOARDING_STAFF_DECLINED} exact={true}>
          <StaffInvitationDeclined />
        </Route>
        <Route path={RoutePathEnum.ONBOARDING_STAFF_CANCELED} exact={true}>
          <StaffOnboardingAborted />
        </Route>
      </Switch>
    </ApolloProvider>
  );
};
