import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormItem,
  Grid,
  Heading,
  Row,
  TimePicker,
  Icon,
  Illustration,
  RadioGroup,
  Radio,
  ListItem,
  List,
  Modal
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useSessionNoteUpdateSmartNotesMutation,
  useSessionNoteDeleteMutation,
  useSessionNoteSubmitMutation,
  useSessionNoteUpdateMutation,
  useSessionNoteCreateMutation,
  useActiveSessionNotesQuery
} from "../DataAccess/SessionNoteData";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  PlaceOfServiceEnum,
  SessionNoteInput,
  SessionNoteStatusEnum,
  StaffRoleEnum
} from "Shared/Api/globalTypes";
import { SessionNoteFragment } from "Shared/Api/SessionNoteFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import notesImage from "./undraw-taking-notes-tjaf.svg";
import { useOnlineStatus } from "Shared/ApolloHelper";
import DiscardChangesModal from "Shared/DiscardChangesModal";
import { SmartNote } from "./SmartNote";

interface ISessionNoteFormModel {
  serviceDate?: moment.Moment;
  start?: moment.Moment;
  end?: moment.Moment;
  serviceType?: string;
  placeOfService?: PlaceOfServiceEnum;
  behaviorDetails?: string;
  skillDetails?: string;
}

interface ISessionNoteFormBaseProps {
  studentId: string;
  onClose: (
    message: string | undefined,
    description: string | undefined
  ) => void;
  onError: (
    error: ApolloError | undefined,
    message: string | undefined,
    description: string | undefined
  ) => void;
  onSign: (sessionId: string) => void;
  sessionId?: string;
}

export const SessionNoteForm = ({
  studentId,
  sessionId,
  onClose,
  onError,
  onSign
}: ISessionNoteFormBaseProps) => {
  const online = useOnlineStatus();
  const { threadUserContext } = useThreadContext();
  const { sessionNoteUpdateSmartNotes } =
    useSessionNoteUpdateSmartNotesMutation();
  const { sessionNoteDelete } = useSessionNoteDeleteMutation();
  const { sessionNoteSubmit } = useSessionNoteSubmitMutation();
  const { sessionNoteUpdate } = useSessionNoteUpdateMutation();
  const { sessionNoteCreate } = useSessionNoteCreateMutation();

  const [therapistName, setTherapistName] = useState<string>();
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState<string | undefined>(
    sessionId
  );
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [model, setModel] = useState<ISessionNoteFormModel>();
  const [form] = Form.useForm<ISessionNoteFormModel>();
  const [status, setStatus] = useState<SessionNoteStatusEnum>(
    SessionNoteStatusEnum.NEEDS_DETAIL
  );

  const renderCardTitle = useCallback((text: string) => {
    return (
      <>
        <Heading level={5} weight="medium">
          {text}
        </Heading>
      </>
    );
  }, []);

  const renderSmartNote = useCallback(
    (
      label: string,
      name: string,
      onInsert: () => void,
      initialValue?: string
    ) => {
      return (
        <Card
          padding="0 16px"
          color="default"
          variation={1}
          title={renderCardTitle(label)}
        >
          <Row align="middle" type="flex" gutter={16} margin="0">
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <Heading level={7}>
                Our “Smart Note” feature automatically writes your documentation
                based on session data for the selected date.
              </Heading>
            </Col>
            <Col xs={24} sm={24} md={8} lg={7} xl={6} xxl={5} margin="16px 0">
              <Button
                id={`${name}Button`}
                size="large"
                block={true}
                onClick={() => onInsert()}
              >
                <Row type="flex">
                  <Col>
                    <Icon type={"fa-magic fas"} margin="0 12px 0 0" />
                  </Col>
                  <Col grow={1} margin="0 26px 0 0">
                    Insert Smart Note
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col xs={0} sm={0} md={15} lg={16} xl={17} xxl={18}>
              <Heading level={7}>
                Our “Smart Note” feature automatically writes your documentation
                based on session data for the selected date.
              </Heading>
            </Col>
          </Row>
          <SmartNote name={name} initialValue={initialValue} />
        </Card>
      );
    },
    [renderCardTitle]
  );

  const setTime = useCallback(
    (date: moment.Moment | undefined, time: moment.Moment | undefined) => {
      if (!time) {
        return undefined;
      }
      const seconds = time.diff(moment(time).startOf("day"), "seconds");
      const dateTime = moment(date ?? moment())
        .startOf("day")
        .add(seconds, "seconds");
      return dateTime;
    },
    []
  );

  /**
   * Data access methods
   */

  const mapModel = useCallback(
    (model: ISessionNoteFormModel) => {
      const note: SessionNoteInput = {
        behaviorDetails: model.behaviorDetails,
        end: setTime(model.serviceDate, model.end)?.toISOString(),
        placeOfService: model.placeOfService,
        serviceType: model.serviceType,
        skillDetails: model.skillDetails,
        start: setTime(
          model.serviceDate,
          model.start ?? moment().startOf("day")
        )?.toISOString(),
        status: status,
        studentId: studentId
      };
      return note;
    },
    [setTime, status, studentId]
  );

  const updateSmartNotes =
    useCallback(async (): Promise<SessionNoteFragment> => {
      const formValues = form.getFieldsValue();
      const note = mapModel(formValues!);
      const result = await sessionNoteUpdateSmartNotes(note);
      const updatedNote =
        result!.data!.sessionNoteUpdateSmartNotes!.sessionNote;
      return updatedNote;
    }, [form, sessionNoteUpdateSmartNotes, mapModel]);

  const insertSkillSmartNote = useCallback(() => {
    if (!online) {
      NotificationsHelper.ErrorNotification({
        title: "Unable to insert Smart Note",
        description: "The smart note function is not available while offline."
      });
      return;
    }
    updateSmartNotes()
      .then((result: SessionNoteFragment) => {
        const clone = {
          serviceDate: form.getFieldValue("serviceDate") ?? undefined,
          start: form.getFieldValue("start") ?? undefined,
          end: form.getFieldValue("end") ?? undefined,
          serviceType: form.getFieldValue("serviceType") ?? undefined,
          placeOfService: form.getFieldValue("placeOfService") ?? undefined,
          behaviorDetails: form.getFieldValue("behaviorDetails") ?? undefined,
          skillDetails: result.smartSkillDetails ?? undefined
        };
        setModel(clone);
        setModified(true);
        form.setFieldsValue(clone);
      })
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Save Note!"
        })
      );
  }, [updateSmartNotes, online, form]);

  const insertSmartBehaviorNote = useCallback(() => {
    if (!online) {
      NotificationsHelper.ErrorNotification({
        title: "Unable to insert Smart Note",
        description: "The smart note function is not available while offline."
      });
      return;
    }
    updateSmartNotes()
      .then((result: SessionNoteFragment) => {
        const clone = {
          serviceDate: form.getFieldValue("serviceDate") ?? undefined,
          start: form.getFieldValue("start") ?? undefined,
          end: form.getFieldValue("end") ?? undefined,
          serviceType: form.getFieldValue("serviceType") ?? undefined,
          placeOfService: form.getFieldValue("placeOfService") ?? undefined,
          skillDetails: form.getFieldValue("skillDetails") ?? undefined,
          behaviorDetails: result.smartBehaviorDetails ?? undefined
        };
        setModel(clone);
        setModified(true);
        form.setFieldsValue(clone);
      })
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Save Note!"
        })
      );
  }, [updateSmartNotes, online, form]);

  const { data: sessionNotesData, error: sessionNotesError } =
    useActiveSessionNotesQuery(studentId);

  useEffect(() => {
    if (!sessionId) {
      setModel({});
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionNotesData && !modified) {
      const dto = sessionNotesData.sessionNotesActive.find(
        note => note.id === sessionId
      );
      if (dto) {
        setStatus(dto.status);
        setModel({
          serviceDate: dto.start ? moment(dto.start) : undefined,
          start: dto.start ? moment(dto.start) : undefined,
          end: dto.end ? moment(dto.end) : undefined,
          serviceType: dto.serviceType ?? undefined,
          placeOfService:
            dto.placeOfService === PlaceOfServiceEnum.NONE
              ? undefined
              : dto.placeOfService,
          skillDetails: dto.skillDetails ?? undefined,
          behaviorDetails: dto.behaviorDetails ?? undefined
        });
        setTherapistName(dto.createdByName ?? undefined);
      }
    }

    if (sessionNotesError) {
      onError(sessionNotesError, "Failed to Load Note!", undefined);
    }
  }, [
    sessionId,
    studentId,
    onError,
    sessionNotesError,
    sessionNotesData,
    modified
  ]);

  const save = useCallback(
    async (values: ISessionNoteFormModel): Promise<string> => {
      setLoading(true);
      try {
        const note: SessionNoteInput = mapModel(values);
        if (!currentSessionId) {
          const result = await sessionNoteCreate(note);
          const updatedNote = result!.data!.sessionNoteCreate!.sessionNote;
          setCurrentSessionId(updatedNote.id);
          return updatedNote.id;
        } else {
          await sessionNoteUpdate(currentSessionId, note);
          return currentSessionId;
        }
      } finally {
        setLoading(false);
      }
    },
    [currentSessionId, sessionNoteCreate, sessionNoteUpdate, mapModel]
  );

  const closeCancelAlert = useCallback(() => {
    setCancelAlertOpen(false);
  }, []);

  const closeCancelAlertAndDialog = useCallback(() => {
    onClose("Note not saved", "Your changes were lost");
  }, [onClose]);

  const closeWithSaveAlert = useCallback(() => {
    onClose("Note Saved", "Changes made to this note have been saved.");
  }, [onClose]);

  const noteSaved = useCallback(
    (finished: boolean, sessionId: string) => {
      setModified(false);
      if (finished) {
        Modal.success({
          okCancel: true,
          title: "Note finished!",
          content: "The next step is to collect a guardian signature.",
          onCancel: () => closeWithSaveAlert(),
          onOk: () => onSign(sessionId!),
          okText: "Sign Now",
          cancelText: "Sign Later"
        });
      } else {
        closeWithSaveAlert();
      }
    },
    [closeWithSaveAlert, onSign]
  );

  const handleSaveDraft = useCallback(() => {
    const values = form.getFieldsValue();
    save(values)
      .then(sessionId => noteSaved(false, sessionId))
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Save Note!"
        })
      );
  }, [noteSaved, save, form]);

  const handleFinishFailed = () => {
    NotificationsHelper.BasicErrorNotification(
      "Missing Required Info",
      "Please review sections marked in red for incomplete information before submitting."
    );
  };

  const handleFinishNote = (values: any) => {
    let newSessionId: string = "";
    save(values as ISessionNoteFormModel)
      .then(sessionId => {
        newSessionId = sessionId;
        sessionNoteSubmit(sessionId, studentId);
      })
      .then(() => noteSaved(true, newSessionId))
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Save Note!"
        })
      );
  };

  const deleteNote = useCallback(() => {
    const wasModified = modified;
    setModified(false);
    sessionNoteDelete(sessionId!, studentId)
      .then(() =>
        onClose("Note Deleted", "This note has been permanently deleted.")
      )
      .catch(error => {
        if (wasModified) {
          setModified(true);
        }
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Delete Note!"
        });
      });
  }, [modified, sessionId, studentId, onClose, sessionNoteDelete]);

  const handleDelete = useCallback(() => {
    Modal.warning({
      okCancel: true,
      title: "Are you sure you want to delete this session note?",
      content: "This action cannot be undone.",
      onOk: () => deleteNote(),
      okText: "Delete",
      cancelText: "Cancel"
    });
  }, [deleteNote]);

  /**
   * Utility methods
   */
  const formItemLayout = useMemo(
    () => ({
      labelCol: {
        lg: { span: 3 },
        md: { span: 3 },
        sm: { span: 4 },
        xs: { span: 24 }
      },
      wrapperCol: {
        lg: { span: 21 },
        md: { span: 21 },
        sm: { span: 20 },
        xs: { span: 24 }
      }
    }),
    []
  );

  const student = useMemo(
    () =>
      threadUserContext.assignedStudents.find(
        student => student!.studentId === studentId
      )?.student,
    [threadUserContext, studentId]
  );
  const studentName = useMemo(
    () => student?.firstName + " " + student?.lastName,
    [student]
  );
  const readyForSignature = useMemo(
    () => status === SessionNoteStatusEnum.NEEDS_SIGNATURE,
    [status]
  );
  if (sessionId && !model && !sessionNotesError) return <LoadingScreen />;
  return (
    <>
      <DiscardChangesModal
        intercept={modified}
        visible={cancelAlertOpen}
        setVisible={setCancelAlertOpen}
        onCancel={closeCancelAlert}
        onOk={closeCancelAlertAndDialog}
      />
      <Grid color="default">
        <LoadingScreen loading={loading} />
        {model && (
          <>
            <Row margin="16px">
              <Col>
                <Heading level={3}>
                  {sessionId
                    ? `Editing ${studentName}'s Session Note`
                    : `Creating Session Note for ${studentName}`}
                </Heading>
                {therapistName && (
                  <Heading level={6}>{"by " + therapistName}</Heading>
                )}
              </Col>
            </Row>
            <Row padding="0 0 32px 0">
              <Illustration src={notesImage} />
            </Row>
            <Row>
              <Form
                initialValues={model}
                onFinish={handleFinishNote}
                onFinishFailed={handleFinishFailed}
                form={form}
                scrollToFirstError={true}
                requiredMark={false}
              >
                <Card
                  color="default"
                  variation={1}
                  padding="16px 16px 0 16px"
                  margin="12px 0 12px 0"
                  title={
                    <Heading level={5} weight="medium">
                      Service Date &amp; Time
                    </Heading>
                  }
                >
                  <FormItem
                    name="serviceDate"
                    label="Date:"
                    {...formItemLayout}
                    rules={[
                      {
                        required: true,
                        message: "Please select a service date"
                      }
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder={moment(model?.start).format("YYYY-MM-DD")}
                    />
                  </FormItem>
                  <FormItem
                    name="start"
                    label="Start Time:"
                    {...formItemLayout}
                    rules={[
                      {
                        required: true,
                        message: "Please select a service start time"
                      }
                    ]}
                  >
                    <TimePicker
                      id="startTime"
                      size="large"
                      format="h:mm a"
                      use12Hours={true}
                    />
                  </FormItem>
                  <FormItem
                    name="end"
                    dependencies={["start"]}
                    label="End Time:"
                    {...formItemLayout}
                    rules={[
                      {
                        required: true,
                        message: "Please select a service end time"
                      },
                      ({ getFieldValue }) => ({
                        validator: async (_rule: any, value: any) => {
                          const serviceDate = getFieldValue("serviceDate");  
                          const end = setTime(serviceDate, value);
                          const start = setTime(serviceDate, getFieldValue("start"));
                          if (end && start) {
                            if(end.isBefore(start)) {
                              throw new Error(
                                "Service end time must be after start time"
                              );
                            }
                          }
                        }
                      }),
                      ({ getFieldValue }) => ({
                        validator: async (_rule: any, value: any) => {
                          const serviceDate = getFieldValue("serviceDate");  
                          const end = setTime(serviceDate, value);
                          const start = setTime(serviceDate, getFieldValue("start"));
                          if (end && start) {
                            const duration = moment.duration(end.diff(start));
                            const hours = duration.asHours();
                            if (hours > 12) {
                              throw new Error(
                                "Session cannot be longer than 12 hours"
                              );
                            }
                          }
                        }
                      })
                    ]}
                  >
                    <TimePicker
                      id="endTime"
                      size="large"
                      format="h:mm a"
                      use12Hours={true}
                    />
                  </FormItem>
                </Card>
                <Card
                  color="default"
                  padding="0 16px"
                  variation={1}
                  title={renderCardTitle("Service Provided")}
                >
                  <List>
                    <FormItem
                      name="serviceType"
                      rules={[
                        {
                          required: true,
                          message: "Please select a provided service"
                        }
                      ]}
                    >
                      <RadioGroup>
                        {threadUserContext!.role !==
                          StaffRoleEnum.INSTRUCTOR && (
                          <>
                            <ListItem key="Assessment">
                              <Radio value="Assessment">Assessment</Radio>
                            </ListItem>
                            <ListItem key="Treatment Planning">
                              <Radio value="Treatment Planning">
                                Treatment Planning
                              </Radio>
                            </ListItem>
                            <ListItem key="Direct Supervision">
                              <Radio value="Direct Supervision">
                                Direct Supervision
                              </Radio>
                            </ListItem>
                            <ListItem key="Parent Training">
                              <Radio value="Parent Training">
                                Parent Training
                              </Radio>
                            </ListItem>
                          </>
                        )}
                        <ListItem key="Direct Care">
                          <Radio value="Direct Care">Direct Care</Radio>
                        </ListItem>
                        <ListItem key="Social Skills Group">
                          <Radio value="Social Skills Group">
                            Social Skills Group
                          </Radio>
                        </ListItem>
                        <ListItem key="Supervised Direct Care">
                          <Radio value="Supervised Direct Care">
                            Supervised Direct Care
                          </Radio>
                        </ListItem>
                        <ListItem key="Consultation">
                          <Radio value="Consultation">Consultation</Radio>
                        </ListItem>
                      </RadioGroup>
                    </FormItem>
                  </List>
                </Card>
                <Card
                  padding="0 16px"
                  color="default"
                  variation={1}
                  title={renderCardTitle("Place of Service")}
                >
                  <List>
                    <FormItem
                      name="placeOfService"
                      rules={[
                        {
                          required: true,
                          message: "Please select a place of service"
                        }
                      ]}
                    >
                      <RadioGroup>
                        <ListItem key={PlaceOfServiceEnum.HOME}>
                          <Radio value={PlaceOfServiceEnum.HOME}>Home</Radio>
                        </ListItem>
                        <ListItem key={PlaceOfServiceEnum.OFFICE}>
                          <Radio value={PlaceOfServiceEnum.OFFICE}>
                            Office
                          </Radio>
                        </ListItem>
                        <ListItem key={PlaceOfServiceEnum.SCHOOL}>
                          <Radio value={PlaceOfServiceEnum.SCHOOL}>
                            School
                          </Radio>
                        </ListItem>
                        <ListItem key={PlaceOfServiceEnum.TELEHEALTH}>
                          <Radio value={PlaceOfServiceEnum.TELEHEALTH}>
                            Telehealth
                          </Radio>
                        </ListItem>
                      </RadioGroup>
                    </FormItem>
                  </List>
                </Card>
                {renderSmartNote(
                  "Skill Acquisition Description",
                  "skillDetails",
                  insertSkillSmartNote,
                  model?.skillDetails
                )}
                {renderSmartNote(
                  "Behavior Description",
                  "behaviorDetails",
                  insertSmartBehaviorNote,
                  model?.behaviorDetails
                )}
                <FormItem>
                  <Row padding="4px 16px 16px 16px">
                    <Button
                      htmlType="submit"
                      size="large"
                      type="primary"
                      block={true}
                    >
                      Finish Note
                    </Button>
                  </Row>
                  <Row padding="0 16px 0 16px" type="flex" justify="end">
                    <Col grow={1} padding="0 8px 0 0">
                      <Button
                        size="large"
                        block={true}
                        onClick={() => handleSaveDraft()}
                      >
                        {readyForSignature
                          ? "Save Modifications"
                          : "Save Draft"}
                      </Button>
                    </Col>
                    <Col grow={1} padding="0 0 0 8px">
                      {
                        // tslint:disable-next-line:jsx-no-lambda
                        <Button
                          size="large"
                          block={true}
                          type="danger"
                          onClick={() => handleDelete()}
                        >
                          Delete Note
                        </Button>
                      }
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </Row>
          </>
        )}
      </Grid>
    </>
  );
};
