import React, { Dispatch } from "react";

export enum CurriculumStateEnum {
  EMPTY,
  COPY,
  CUT
}

export interface ICurriculumContext {
  curriculumId?: string;
  parentId?: string;
  folderId?: string;
  templateId?: string;
  clipstate: CurriculumStateEnum;
}

type Action =
  | {
      type: "COPY-FOLDER";
      curriculumId: string;
      parentId: string;
      folderId: string;
    }
  | {
      type: "CUT-FOLDER";
      curriculumId: string;
      parentId: string;
      folderId: string;
    }
  | {
      type: "COPY-TEMPLATE";
      curriculumId: string;
      parentId: string;
      templateId: string;
    }
  | {
      type: "CUT-TEMPLATE";
      curriculumId: string;
      parentId: string;
      templateId: string;
    };

export const initialCurriculumState: ICurriculumContext = {
  clipstate: CurriculumStateEnum.EMPTY
};

export const curriculumReducer = (
  state: ICurriculumContext,
  action: Action
): ICurriculumContext => {
  switch (action.type) {
    case "COPY-FOLDER":
      return {
        curriculumId: action.curriculumId,
        parentId: action.parentId,
        folderId: action.folderId,
        clipstate: CurriculumStateEnum.COPY
      };
    case "CUT-FOLDER":
      return {
        curriculumId: action.curriculumId,
        parentId: action.parentId,
        folderId: action.folderId,
        clipstate: CurriculumStateEnum.CUT
      };
    case "COPY-TEMPLATE":
      return {
        curriculumId: action.curriculumId,
        parentId: action.parentId,
        templateId: action.templateId,
        clipstate: CurriculumStateEnum.COPY
      };
    case "CUT-TEMPLATE":
      return {
        curriculumId: action.curriculumId,
        parentId: action.parentId,
        templateId: action.templateId,
        clipstate: CurriculumStateEnum.CUT
      };
    default:
      return state;
  }
};
export const CurriculumContext = React.createContext<{
  state: ICurriculumContext;
  dispatch: Dispatch<Action>;
}>({ state: initialCurriculumState, dispatch: () => null });
