import { ProgramTypeEnum } from "./Api/globalTypes";
import dttImage from "Shared/Images/dtt.svg";
import taskAnalysisImage from "Shared/Images/taskAnalysis.svg";
import intervalImage from "Shared/Images/interval.svg";
import durationImage from "Shared/Images/duration.svg";
import frequencyImage from "Shared/Images/frequency.svg";

export class ImageHelper {
  static programTypeImage(programType: ProgramTypeEnum) {
    switch (programType) {
      case ProgramTypeEnum.DTT:
        return dttImage;

      case ProgramTypeEnum.TASK_ANALYSIS:
        return taskAnalysisImage;

      case ProgramTypeEnum.INTERVAL:
        return intervalImage;

      case ProgramTypeEnum.DURATION:
        return durationImage;

      case ProgramTypeEnum.FREQUENCY:
        return frequencyImage;

      default:
        throw new Error("Invalid program type");
    }
  }
  public static async fetchProtectedImage(
    url: string
  ): Promise<string | undefined> {
    try {
      const token = sessionStorage.getItem("token");
      const value = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // always read the response or we'll abort the fetch
      const blob = await value.blob();

      if (!value.ok) {
        return undefined;
      }

      const reader = new FileReader();
      const imageLoad = () =>
        new Promise(resolve => {
          reader.addEventListener("load", resolve);
          reader.readAsDataURL(blob);
        });

      await imageLoad();
      const imageData = reader.result;
      if (typeof imageData === "string") {
        return imageData as string;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }
}
