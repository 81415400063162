import {
  Col,
  Heading,
  Row,
  Icon,
  List,
  ListItem,
  Tag,
  Grid,
  Button,
  Input
} from "@raudabaugh/thread-ui";
import React, { useState } from "react";

export interface IValueListProps {
  id: string;
  values: string[];
  tags?: string[] | JSX.Element[];
  addLabel: string;
  generateTag: (value: string) => string;
  onRemove: (id: string, value: string) => void;
  onAdd: (id: string, value: string) => void;
}

export const ValueList = (props: IValueListProps) => {
  const [isAdding, setIsAdding] = useState(false);
  const [newValue, setNewValue] = useState("");
  return (
    <List size="small">
      {props.values.map((value, index) => (
        <ListItem>
          <Row
            justify="space-between"
            key={index}
            type="flex"
            align="middle"
            grow={1}
            margin="0 16px"
          >
            {props.tags && (
              <Col grow={0}>
                <Grid width="60px" margin="0 0 0 8px">
                  <Tag color="primary">{props.tags[index]}</Tag>
                </Grid>
              </Col>
            )}
            <Col grow={1}>{value}</Col>
            <Col>
              <Heading
                weight="bold"
                margin="0 6px 0 4px"
                level={5}
                color="error"
              >
                <Icon
                  type="fa-times fas"
                  color="error"
                  onClick={() => props.onRemove(props.id, value)}
                />
              </Heading>
            </Col>
          </Row>
        </ListItem>
      ))}

      <ListItem>
        <Row
          justify="space-between"
          type="flex"
          align="middle"
          grow={1}
          margin="0 16px"
        >
          <Col grow={0}>
            <Grid width="60px" margin="0 0 0 8px">
              {newValue.trim().length > 0 && (
                <Tag color="primary">{props.generateTag(newValue)}</Tag>
              )}
            </Grid>
          </Col>
          <Col grow={1} margin="0 16px 0 0">
            {isAdding && (
              <Input
                value={newValue}
                maxLength={50}
                mask="[a-zA-Z0-9\s]"
                size="small"
                onChange={value => setNewValue(value.target.value)}
                onPressEnter={() => {
                  if (newValue.trim().length > 0) {
                    props.onAdd(props.id, newValue);
                  }
                  setIsAdding(false);
                  setNewValue("");
                }}
                placeholder=""
              />
            )}
          </Col>
          <Col>
            {isAdding && (
              <Button
                type="primary"
                onClick={() => {
                  if (newValue.trim().length > 0) {
                    props.onAdd(props.id, newValue);
                  }
                  setIsAdding(false);
                  setNewValue("");
                }}
              >
                Save
              </Button>
            )}
            {!isAdding && (
              <Button type="primary" onClick={() => setIsAdding(true)}>
                {props.addLabel}
              </Button>
            )}
          </Col>
        </Row>
      </ListItem>
    </List>
  );
};
