import React, { useCallback, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { Notification } from "@raudabaugh/thread-ui";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { SessionNoteForm } from "SessionNotes/SessionNoteForm";
import { SessionNotes } from "SessionNotes/SessionNotes";
import { SessionNoteView } from "SessionNotes/SessionNoteView";
import { RouterHelper } from "./RouterHelper";

// Session notes

interface ISessionNoteParams {
  noteId: string;
}

interface ISessionNoteHandlers {
  handleNoteClose: (
    studentId: string,
    message?: string,
    description?: string
  ) => void;
  handleNoteEdit: (studentId: string, noteId: string) => void;
  handleNoteError: (
    studentId: string,
    error?: ApolloError,
    message?: string,
    description?: string
  ) => void;
  handleNoteSign: (studentId: string, sessionId: string) => void;
}

interface ISessionNoteListHandlers {
  handleNoteRevise: (studentId: string, noteId: string) => void;
  handleNoteNew: (studentId: string) => void;
  handleNoteView: (studentId: string, noteId: string) => void;
}

const SessionNoteViewById: React.FC<ISessionNoteHandlers> = ({
  handleNoteClose,
  handleNoteEdit,
  handleNoteError,
  handleNoteSign
}) => {
  const { noteId } = useParams<ISessionNoteParams>();
  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(
    (message: string | undefined, description: string | undefined) =>
      handleNoteClose(studentId || "", message, description),
    [studentId, handleNoteClose]
  );

  const handleError = useCallback(
    (
      error: ApolloError | undefined,
      message: string | undefined,
      description: string | undefined
    ) => handleNoteError(studentId || "", error, message, description),
    [studentId, handleNoteError]
  );

  const handleSign = useCallback(
    (sessionId: string) => handleNoteSign(studentId || "", sessionId),
    [studentId, handleNoteSign]
  );

  const handleEdit = useCallback(
    (noteId: string) => handleNoteEdit(studentId || "", noteId),
    [studentId, handleNoteEdit]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <SessionNoteView
        studentId={studentId}
        sessionId={noteId}
        onClose={handleClose}
        onError={handleError}
        onSign={handleSign}
        onEdit={handleEdit}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.SESSION_NOTES,
          state: {
            sideMenu: RoutePathEnum.SESSION_NOTES,
            current: RoutePathEnum.SESSION_NOTES
          }
        }}
      />
    );
  }
};

const SessionNoteEditById: React.FC<ISessionNoteHandlers> = ({
  handleNoteClose,
  handleNoteError,
  handleNoteSign
}) => {
  const { noteId } = useParams<ISessionNoteParams>();
  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(
    (message, description) =>
      handleNoteClose(studentId || "", message, description),
    [studentId, handleNoteClose]
  );
  const handleError = useCallback(
    (error, message, description) =>
      handleNoteError(studentId || "", error, message, description),
    [studentId, handleNoteError]
  );
  const handleSign = useCallback(
    (sessionId: string) => handleNoteSign(studentId || "", sessionId),
    [studentId, handleNoteSign]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <SessionNoteForm
        studentId={studentId}
        sessionId={noteId}
        onClose={handleClose}
        onError={handleError}
        onSign={handleSign}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.SESSION_NOTES,
          state: {
            sideMenu: RoutePathEnum.SESSION_NOTES,
            current: RoutePathEnum.SESSION_NOTES
          }
        }}
      />
    );
  }
};

const SessionNoteNew: React.FC<ISessionNoteHandlers> = ({
  handleNoteClose,
  handleNoteError,
  handleNoteSign
}) => {
  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(
    (message, description) =>
      handleNoteClose(studentId || "", message, description),
    [studentId, handleNoteClose]
  );
  const handleError = useCallback(
    (error, message, description) =>
      handleNoteError(studentId || "", error, message, description),
    [studentId, handleNoteError]
  );
  const handleSign = useCallback(
    (sessionId: string) => handleNoteSign(studentId || "", sessionId),
    [studentId, handleNoteSign]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <SessionNoteForm
        studentId={studentId}
        onClose={handleClose}
        onError={handleError}
        onSign={handleSign}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.SESSION_NOTES,
          state: {
            sideMenu: RoutePathEnum.SESSION_NOTES,
            current: RoutePathEnum.SESSION_NOTES
          }
        }}
      />
    );
  }
};

const SessionNotesByStudent: React.FC<ISessionNoteListHandlers> = ({
  handleNoteNew,
  handleNoteRevise,
  handleNoteView
}) => {
  const { studentId, setStudentId } = useStudentContext();

  const handleCreateNote = useCallback(
    () => handleNoteNew(studentId || ""),
    [studentId, handleNoteNew]
  );

  const handleEditNote = useCallback(
    (noteId: string) => handleNoteRevise(studentId || "", noteId),
    [studentId, handleNoteRevise]
  );

  const handleViewNote = useCallback(
    (noteId: string) => handleNoteView(studentId || "", noteId),
    [studentId, handleNoteView]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <SessionNotes
        studentId={studentId}
        onCreateNote={handleCreateNote}
        onEditNote={handleEditNote}
        onViewNote={handleViewNote}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.SESSION_NOTES,
          state: {
            sideMenu: RoutePathEnum.SESSION_NOTES,
            current: RoutePathEnum.SESSION_NOTES
          }
        }}
      />
    );
  }
};

interface SessionNotesRouterProps {}

export const SessionNotesRouter = (props: SessionNotesRouterProps) => {
  const history = useHistory();

  const handleNoteClose = useCallback(
    (studentId: string, message?: string, description?: string) => {
      if (message) {
        Notification.success({
          duration: 0,
          message: message,
          description: description
        });
      }
      history.goBack();
    },
    [history]
  );

  const handleNoteError = useCallback(
    (
      studentId: string,
      error?: ApolloError,
      message?: string,
      description?: string
    ) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: message || "Failed to Access Thread!",
        description
      });
    },
    []
  );

  const handleNoteSign = useCallback(
    (studentId: string, sessionId: string) => {
      RouterHelper.redirectToSessionNoteView(
        studentId,
        sessionId,
        history,
        true
      );
    },
    [history]
  );

  const handleNoteView = useCallback(
    (studentId: string, noteId: string) => {
      RouterHelper.redirectToSessionNoteView(studentId, noteId, history);
    },
    [history]
  );

  const handleNoteRevise = useCallback(
    (studentId: string, noteId: string) => {
      RouterHelper.redirectToSessionNoteEdit(studentId, noteId, history);
    },
    [history]
  );

  const handleNoteEdit = useCallback(
    (studentId: string, noteId: string) => {
      RouterHelper.redirectToSessionNoteEdit(studentId, noteId, history, true);
    },
    [history]
  );

  const handleNoteNew = useCallback(
    (studentId: string) => {
      RouterHelper.redirectToSessionNoteCreate(studentId, history, false);
    },
    [history]
  );

  // because these get spread as props we dont' have to memoize
  const handlers: ISessionNoteHandlers = {
    handleNoteClose,
    handleNoteEdit,
    handleNoteError,
    handleNoteSign
  };

  const listHandlers: ISessionNoteListHandlers = {
    handleNoteRevise,
    handleNoteNew,
    handleNoteView
  };

  return (
    <Switch>
      <Route path={RoutePathEnum.SESSION_NOTES_VIEW_NOTE} exact={true}>
        <SessionNoteViewById {...handlers} />
      </Route>
      <Route path={RoutePathEnum.SESSION_NOTES_EDIT_NOTE} exact={true}>
        <SessionNoteEditById {...handlers} />
      </Route>
      <Route path={RoutePathEnum.SESSION_NOTES_NEW_NOTE} exact={true}>
        <SessionNoteNew {...handlers} />
      </Route>
      <Route path={RoutePathEnum.SESSION_NOTES_LIST_NOTES} exact={true}>
        <SessionNotesByStudent {...listHandlers} />
      </Route>
      <Route path={RoutePathEnum.SESSION_NOTES}>
        <DefaultStudent path={RoutePathEnum.SESSION_NOTES_LIST_NOTES} />
      </Route>
    </Switch>
  );
};
