import {
  Card,
  Col,
  Heading,
  IColumnProps,
  Icon,
  Link,
  Notification,
  Row,
  Switch,
  Table,
  Tooltip
} from "@raudabaugh/thread-ui";
import { EditStudentAssignments } from "../EditStudentAssignments";
import { getInitials } from "../../Shared/Initials";
import { ProtectedAvatar } from "../../Shared/ProtectedAvatar";
import { useCallback, useEffect, useState } from "react";
import { useStudentSearchQuery } from "DataAccess/StudentData";
import {
  IStudentSearchVariables,
  IStudentSearch_studentSearch_edges
} from "../../Shared/Api/IStudentSearch";
import { v4 as uuid } from "uuid";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { StudentFragment } from "Shared/Api/StudentFragment";
import { useStaffAssignedStudentOfflineAvailabilityMutation } from "DataAccess/StaffData";
import {
  StaffAssignedStudentInput,
  StudentStatusEnum
} from "Shared/Api/globalTypes";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OrganizationSettingsEnum } from "App";

interface IStudentsAssignedProps {
  staffId: string;
  students: StudentFragment[];
  onStudentSelect?: (studentId: string) => void;
  onSave: (
    studentsToSave: StudentFragment[],
    studentsToRemove: StudentFragment[]
  ) => void;
  showEdit: boolean;
  useCardHeader?: boolean;
  showAvailableOfflineColumn?: boolean;
  heading?: string;
  hideColumnHeaders?: boolean;
}

interface IQueryState extends IStudentSearchVariables {
  editStudents: boolean;
  contextId: string;
}

const DefaultItems = 40;
const DefaultMoreItems = 150;

export const StudentsAssigned = (props: IStudentsAssignedProps) => {
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [studentsToSave, setStudentsToSave] = useState<StudentFragment[]>([]);
  const [studentsToRemove, setStudentsToRemove] = useState<StudentFragment[]>(
    []
  );
  const [availableStudents, setAvailableStudents] = useState<
    IStudentSearch_studentSearch_edges[]
  >([]);
  const [totalAvailableStudents, setTotalAvailableStudents] = useState(0);

  const notEmpty = <T extends {}>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined;
  };
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();

  const maxStudents =
    threadUserContext.settings[
      OrganizationSettingsEnum.MAX_OFFLINE_STUDENTS_PER_USER
    ]?.value ?? 15;
  const offlineStudentsTooltip = `By turning this on you make this student available offline for just your user account. There is a limit of ${maxStudents} students that can be made available offline to your account. Additionally, to continue working offline you must connect Thread to the internet every 48 hours so that your data can upload.`;

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [queryState, setQueryState] = useState<IQueryState>({
    forString: "",
    first: DefaultItems,
    after: null,
    sortFirst: props.students.map(s => s.id),
    contextId: uuid(),
    editStudents: false,
    statusFilter: StudentStatusEnum.ACTIVE
  });

  const handleStudentSelect = (value: string) => {
    props.onStudentSelect?.(value);
  };

  const handleSave = useCallback(() => {
    props.onSave([...studentsToSave], [...studentsToRemove]);
    setStudentsToSave([]);
    setStudentsToRemove([]);
    setAvailableStudents([]);
    setQueryState({
      ...queryState,
      first: DefaultItems,
      after: null,
      sortFirst: props.students.map(s => s.id),
      contextId: uuid(),
      editStudents: false
    });
  }, [props, queryState, studentsToRemove, studentsToSave]);

  const handleEditStudentsClick = useCallback(() => {
    if (!online) {
      NotificationsHelper.ErrorNotification({
        title: "Editing assigned students not available.",
        description: "This function is not available while offline."
      });
      return;
    }
    setAvailableStudents([]);
    setQueryState({
      ...queryState,
      first: DefaultItems,
      after: null,
      sortFirst: props.students.map(s => s.id),
      contextId: uuid(),
      editStudents: true
    });
  }, [props.students, queryState, online]);

  const handleCancelEdit = useCallback(() => {
    setStudentsToSave([]);
    setStudentsToRemove([]);
    setAvailableStudents([]);
    setQueryState({
      ...queryState,
      contextId: uuid(),
      editStudents: false
    });
  }, [queryState]);

  const { error: studentsError, data: studentsData } = useStudentSearchQuery(
    queryState,
    { skip: !online }
  );

  const handleSuccess = (title: string, description: string) => {
    Notification.success({
      duration: 0,
      message: title,
      description
    });
  };

  const handleError = (error: Error) => {
    NotificationsHelper.ErrorNotification({
      error,
      title: error.name,
      description: error.message
    });
  };

  const { toggleStudentOfflineAvailability } =
    useStaffAssignedStudentOfflineAvailabilityMutation();

  useEffect(() => {
    let cancelled = false;

    if (!cancelled && studentsData && studentsData.studentSearch) {
      const totalCount = +studentsData.studentSearch!.totalCount! || 0;
      if (totalCount > 0) {
        const tempData = studentsData.studentSearch.edges!.filter(notEmpty);
        const tempStudents = Array.from(
          new Set(availableStudents.concat(tempData))
        );

        const tempHasMore = totalCount > tempStudents.length;
        setAvailableStudents(tempStudents);
        setTotalAvailableStudents(totalCount);
        setHasMore(tempHasMore);
      } else {
        setAvailableStudents([]);
        setTotalAvailableStudents(0);
        setHasMore(false);
      }
    }

    if (studentsError) {
      NotificationsHelper.ErrorNotification({
        error: studentsError,
        title: "Failed to Load Data"
      });
    }

    return () => {
      cancelled = true;
    };
    // NOTE: added due to availabeleStudents causing 2x calls
    // eslint-disable-next-line
  }, [studentsError, studentsData]);

  const handleStudentSearch = useCallback(
    (search: string) => {
      setQueryState({
        ...queryState,
        forString: search,
        after: null,
        first: DefaultItems
      });
      setAvailableStudents([]);
      setHasMore(true);
    },
    [queryState]
  );

  const handleLoadMore = useCallback(() => {
    hasMore &&
      setQueryState({
        ...queryState,
        after:
          availableStudents && availableStudents.length
            ? availableStudents[availableStudents.length - 1]?.cursor!
            : null,
        first: DefaultMoreItems
      });
  }, [hasMore, queryState, availableStudents]);

  const handleAssignmentChanged = (
    student: StudentFragment,
    checked: boolean
  ) => {
    const newStudentsToSave = studentsToSave;
    const newStudentsToRemove = studentsToRemove;
    if (checked) {
      setStudentsToRemove(
        newStudentsToRemove.filter((s: StudentFragment) => s.id !== student.id)
      );
      setStudentsToSave(newStudentsToSave.concat(student));
    } else {
      setStudentsToRemove(newStudentsToRemove.concat(student));
      setStudentsToSave(
        newStudentsToSave.filter((s: StudentFragment) => s.id !== student.id)
      );
    }
  };

  const handleOfflineAvailabilityToggle = (
    studentId: string,
    availableOffline: boolean
  ) => {
    setShowSpinner(true);
    if (availableOffline) {
      const offlineStudents = threadUserContext.assignedStudents.filter(
        s => s.availableOffline
      );

      if (offlineStudents.length >= maxStudents) {
        setShowSpinner(false);
        NotificationsHelper.ErrorNotification({
          title: "Unable to make student available online",
          description: `You have reached the maximum number of students (${maxStudents}). In order to make this student available offline, you must first untoggle a student.`
        });
        return;
      }
    }
    const input: StaffAssignedStudentInput = { availableOffline };
    toggleStudentOfflineAvailability(props.staffId, studentId, input)
      .then(student => {
        setShowSpinner(false);
        handleSuccess(
          "Student Availability Updated",
          "Student availability successfully updated"
        );
      })
      .catch(error => {
        setShowSpinner(false);
        handleError(error);
      });
  };

  const defineRecord = (record: StudentFragment) => {
    return record!.id!;
  };

  const columns: Array<IColumnProps<StudentFragment>> = [
    {
      dataIndex: "",
      key: "",
      render: (text: any, record: StudentFragment, index: number) => {
        return (
          <Col onClick={() => handleStudentSelect(record.id)}>
            <ProtectedAvatar
              color="primary"
              alt={record.fullName}
              size="default"
              src={record.avatarUrl}
            >
              {getInitials(record.fullName)}
            </ProtectedAvatar>
          </Col>
        );
      },
      width: "76px"
    },
    {
      dataIndex: "",
      key: "",
      render: (text: any, record: StudentFragment, index: number) => {
        return (
          <Col
            margin="10px 0 0 0"
            grow={1}
            onClick={() => handleStudentSelect(record.id)}
          >
            <Heading maxWidth="calc(100vw - 200px)" level={5}>
              {record.fullName}
            </Heading>
          </Col>
        );
      },
      xs: { width: "calc(100vw - 190px)" },
      md: { width: "calc(100vw - 298x)" },
      title: "Student",
      sorter: (a, b) => a.fullName!.localeCompare(b.fullName!)
    }
  ];
  if (
    props.showAvailableOfflineColumn !== undefined &&
    props.showAvailableOfflineColumn === true
  ) {
    columns.push({
      dataIndex: "",
      key: "",
      render: (text: any, record: StudentFragment, index: number) => {
        const checked = threadUserContext.assignedStudents.some(
          s => s.studentId === record.id && s.availableOffline
        );
        return (
          <Col margin="10px 0 0 0">
            <Switch
              checked={checked}
              onChange={value => {
                handleOfflineAvailabilityToggle(record.id, value);
              }}
            ></Switch>
          </Col>
        );
      },
      title: () => (
        <Heading level={5}>
          Available Offline
          <Tooltip title={offlineStudentsTooltip}>
            <Icon
              opacity={0.65}
              color="default"
              variation={10}
              size="1em"
              margin="0px 5px"
              type="fa-info-circle far"
            />
          </Tooltip>
        </Heading>
      )
    });
  }

  return (
    <>
      <LoadingScreen loading={showSpinner} />
      {!props.useCardHeader ? (
        <>
          <Row type="flex" justify="space-between">
            <Col>
              <Heading level={5} weight="medium" margin="4px 0 0 0">
                {props.heading ?? "Assigned Students"}
              </Heading>
            </Col>
            {props.showEdit && (
              <Heading
                id="Edit_Student_Assignment_Button"
                level={6}
                color="primary"
                margin="4px 16px 0 0"
              >
                <Link
                  icon="fa-edit fas"
                  iconPosition="right"
                  onClick={handleEditStudentsClick}
                >
                  Edit
                </Link>
              </Heading>
            )}
          </Row>
          <Table
            dataSource={props.students}
            columns={columns}
            rowKey={defineRecord}
            pagination={false}
            showHeader={!props.hideColumnHeaders}
          />
        </>
      ) : (
        <Card
          variation={1}
          width="100%"
          padding="0"
          title={
            <Row type="flex" justify="space-between">
              <Col>{props.heading ?? "Assigned Students"}</Col>
              {props.showEdit && (
                <Heading
                  id="Edit_Student_Assignment_Button"
                  level={6}
                  color="primary"
                >
                  <Link
                    icon="fa-edit fas"
                    iconPosition="right"
                    onClick={handleEditStudentsClick}
                  >
                    Edit
                  </Link>
                </Heading>
              )}
            </Row>
          }
        >
          <Table
            dataSource={props.students}
            columns={columns}
            rowKey={defineRecord}
            pagination={false}
          />
        </Card>
      )}
      <EditStudentAssignments
        onSave={handleSave}
        onAssignmentChanged={handleAssignmentChanged}
        visible={queryState.editStudents}
        onClose={handleCancelEdit}
        assignments={props.students}
        peopleToSave={studentsToSave}
        peopleToRemove={studentsToRemove}
        totalPeople={totalAvailableStudents}
        shownPeople={availableStudents.map(i => i.node)}
        hasMore={hasMore}
        onSearch={handleStudentSearch}
        onLoadMore={handleLoadMore}
      />
    </>
  );
};
