import React, { useState } from "react";
import {
  Col,
  Heading,
  IColumnProps,
  Icon,
  Link,
  Notification,
  Row,
  Select,
  SelectOption,
  Table
} from "@raudabaugh/thread-ui";
import { LabelHelper } from "../Shared/LabelHelper";
import { TrialDataFormActions } from "./TrialDataFormActions";
import { TrialDataFormItem } from "./TrialDataFormItem";
import { useDataPointUpdateTrialMutation } from "DataAccess/DataPointData";
import _ from "lodash";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import {
  DataPointFragment,
  DataPointFragment_trials
} from "Shared/Api/DataPointFragment";
import {
  ProgramTypeEnum,
  TrialInput,
  TrialResultEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { useOnlineStatus } from "Shared/ApolloHelper";

interface ITaskAnalysisDetailProps {
  studentId: string;
  data: DataPointFragment;
  phases: PhaseFragment[];
  heading: JSX.Element;
  onClose: () => void;
}

export const TaskAnalysisDetail = (props: ITaskAnalysisDetailProps) => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const { dataPointUpdateTrial } = useDataPointUpdateTrialMutation();
  const [editTrial, setEditTrial] = useState<{
    id?: string;
    index: number;
    input: TrialInput;
  }>();
  const [validationFailed, setValidationFailed] = useState(false);
  const dataPoint = props.data as DataPointFragment;
  const hasEditPermission = findPermission(
    threadUserContext.role,
    Permissions.ADD_TRIALS
  );

  const showOfflineNotification = (label: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${label} function is not available while in offline mode.`
    });
  };

  const findStep = (stepId: string | undefined | null) => {
    if (!stepId) {
      return undefined;
    }
    const phase = props.phases.find(p => p.id === props.data.phaseId);
    if (!phase) {
      return undefined;
    }
    const step = phase.steps.find(s => s.id === stepId);
    if (step) {
      return step;
    }
    return undefined;
  };

  const columns: Array<IColumnProps<DataPointFragment_trials>> = [
    {
      dataIndex: "step",
      key: "step",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return (
          <>
            {index + 1}
            {". "}
            {findStep(record.stepId)?.description}
          </>
        );
      },
      title: "Step"
    },
    {
      dataIndex: "score",
      key: "score",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        let result = LabelHelper.resultLabel(record.result);
        if (result === "") {
          result = "Inactive";
        }
        return <>{result}</>;
      },
      title: "Score"
    }
  ];

  if (hasEditPermission) {
    columns.push({
      align: "right",
      width: "60px",
      dataIndex: "edit",
      key: "edit",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return record.result !== TrialResultEnum.NONE ? (
          <Link
            onClick={() => {
              if (!online) {
                showOfflineNotification("Edit Details");
                return;
              }
              setEditTrial({
                id: record.id,
                index,
                input: {
                  result: record.result,
                  stepId: record.stepId,
                  prompt: record.prompt
                }
              });
            }}
          >
            Edit
          </Link>
        ) : (
          <></>
        );
      }
    });
  }

  const handleSubmit = () => {
    if (editTrial) {
      const isValid = !validateResult();
      if (!isValid) {
        setValidationFailed(true);
        return;
      }
      if (editTrial.id) {
        dataPointUpdateTrial(
          dataPoint.id,
          props.studentId,
          editTrial.id,
          editTrial.input,
          ProgramTypeEnum.TASK_ANALYSIS
        )
          .then(() => setEditTrial(undefined))
          .catch(error =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Update the Trial"
            })
          );
      }
    }
  };

  const validateResult = () => {
    if (
      !editTrial?.input.result ||
      editTrial.input.result === TrialResultEnum.NONE
    ) {
      return "A result must be selected.";
    }
  };

  const handleResultChange = (value: string | number) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      clone.input.result = value as TrialResultEnum;
      setEditTrial(clone);
    }
  };

  if (editTrial) {
    const step = findStep(editTrial?.input?.stepId);
    return (
      <>
        <Row type="flex" margin="0 0 8px 0">
          <Col>
            <Link onClick={() => setEditTrial(undefined)}>
              <Icon type="fa-arrow-left fas" />
              {" View trials"}
            </Link>
          </Col>
        </Row>
        <Heading level={3}>{`Step ${editTrial.index + 1}. ${
          step?.description
        }`}</Heading>
        <TrialDataFormItem
          required={true}
          label="Score"
          validationMessage={validationFailed ? validateResult() : undefined}
        >
          <Select
            value={editTrial.input.result ?? undefined}
            onChange={handleResultChange}
          >
            {Object.keys(TrialResultEnum)
              .filter(result => result !== TrialResultEnum.NONE)
              .map(result => (
                <SelectOption value={result} key={result}>
                  {LabelHelper.resultLabel(result as TrialResultEnum)}
                </SelectOption>
              ))}
          </Select>
        </TrialDataFormItem>
        <TrialDataFormActions
          onCancel={() => setEditTrial(undefined)}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <Row type="flex" margin="0 0 8px 0">
        <Col>
          <Link onClick={() => props.onClose()}>
            <Icon type="fa-arrow-left fas" /> Datapoint details
          </Link>
        </Col>
      </Row>
      {props.heading}
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataPoint.trials}
        scroll={{ y: "250px" }}
      />
    </>
  );
};
