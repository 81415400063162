import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { MyStudents } from "../PeopleManagement/MyStudents";

// My Student Data

interface MyStudentRouterProps {}

export const MyStudentRouter = (props: MyStudentRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.MY_STUDENT_DETAIL} exact={true}>
        <MyStudentDetail />
      </Route>
      <Route path={RoutePathEnum.MY_STUDENT_LIST}>
        <MyStudents />
      </Route>
    </Switch>
  );
};

const MyStudentDetail = () => {
  const { studentId, setStudentId } = useStudentContext();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return <>{/* placeholder for CRTH-1563 */}</>;
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.MY_STUDENT_LIST,
          state: {
            sideMenu: RoutePathEnum.MY_STUDENT_LIST,
            current: RoutePathEnum.MY_STUDENT_LIST
          }
        }}
      />
    );
  }
};
