import {
  Col,
  Grid,
  Heading,
  IClickParam,
  IUploadProps,
  Row,
  Popover,
  Menu,
  MenuDivider,
  MenuItem,
  Notification,
  Upload
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import { getInitials } from "../../Shared/Initials";
import { ProtectedAvatar } from "../../Shared/ProtectedAvatar";
import { useEffect, useState } from "react";
import {
  useStudentQuery,
  useStudentUpdateMutation
} from "DataAccess/StudentData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { LabelHelper } from "Shared/LabelHelper";
import moment from "moment";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { IStudent_student } from "Shared/Api/IStudent";
export interface IStudentProfileHeaderBaseProps {
  id: string;
  canEdit: boolean;
  lastActive?: string;
}

export const StudentProfileHeader = (props: IStudentProfileHeaderBaseProps) => {
  const { updateStudentInfo } = useStudentUpdateMutation();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { threadUserContext } = useThreadContext();
  const [student, setStudent] = useState<IStudent_student>();

  const online = useOnlineStatus();
  const { error: studentError, data: studentData } = useStudentQuery(props.id, {
    skip: !online
  });

  useEffect(() => {
    if (online) {
      if (studentData && studentData.student) {
        setStudent(studentData.student);
      }
    } else {
      const student = threadUserContext.assignedStudents.find(
        assignment => assignment.studentId === props.id
      )?.student;
      setStudent(student);
    }
  }, [studentData, online, props.id, threadUserContext.assignedStudents]);

  const handleMenuClick = (param: IClickParam) => {
    setMenuOpen(false);
    if (param.key === "remove") {
      updateStudentInfo(props.id, { avatarData: "" })
        .then(() => {
          setShowSpinner(false);
        })
        .catch(error => {
          setShowSpinner(false);
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Upload Image"
          });
        });
    }
  };

  const handleMenuVisibleChange = () => {
    setMenuOpen(!menuOpen);
  };

  const handleBeforeUpload = (file: File, fileList: File[]) => {
    // Validate file
    const isImageFile = file.type === "image/jpeg" || file.type === "image/png";
    if (!isImageFile) {
      Notification.error({
        duration: 0,
        message: "Invalid Format",
        description: "You can only upload JPG and PNG files!"
      });
      return false;
    }
    const isFileSizeOk = file.size / 1024 / 1024 < 5;
    if (!isFileSizeOk) {
      Notification.error({
        duration: 0,
        message: "Invalid Format",
        description: "Image must be smaller than 5MB!"
      });
      return false;
    }

    // Show spinner
    setShowSpinner(true);

    // Get the image data as a base64 url
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageData = reader.result;
      if (typeof imageData === "string") {
        const parts = imageData.split(",");
        updateStudentInfo(props.id, { avatarData: parts[1] })
          .then(() => {
            setShowSpinner(false);
          })
          .catch(error => {
            setShowSpinner(false);
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Upload Image"
            });
          });
        const img = document.createElement("img");
        img.onload = () => {
          if (img.width < 256 || img.height < 256 || img.width !== img.height) {
            Notification.warning({
              duration: 0,
              message: "Profile Image Tips",
              description:
                "For best results, use square images of at least 256 x 256 pixels."
            });
          }
        };
        img.src = imageData;
      } else {
        const error: ApolloError = {
          message: "Unexpected error uploading image file.",
          graphQLErrors: [],
          networkError: null,
          extraInfo: null,
          name: "Invalid Format",
          clientErrors: []
        };
        setShowSpinner(false);
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Upload Image"
        });
      }
    });
    reader.readAsDataURL(file);
    return false;
  };

  const uploadProps: IUploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text"
    },
    accept: ".png,.jpg,.jpeg,image/jpeg,image/png",
    showUploadList: false,
    beforeUpload: handleBeforeUpload
  };

  useEffect(() => {
    studentError &&
      NotificationsHelper.ErrorNotification({
        error: studentError,
        title: "Connection Error"
      });
  }, [studentError]);

  return (
    <>
      <LoadingScreen loading={showSpinner} />
      <Grid width="100%" margin="0" padding="0 12px">
        <Row type="flex" align="middle" wrap={false}>
          <Col grow={0}>
            <Popover
              trigger="click"
              placement="bottom"
              onVisibleChange={handleMenuVisibleChange}
              visible={props.canEdit && menuOpen}
              content={
                <Menu margin="-12px -16px" onClick={handleMenuClick}>
                  <MenuItem key="upload">
                    <Upload {...uploadProps}>Upload Image</Upload>
                  </MenuItem>
                  {student?.avatarUrl !== null && (
                    <MenuItem key="remove">Remove Image</MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem key="cancel">Cancel</MenuItem>
                </Menu>
              }
            >
              <ProtectedAvatar
                color="primary"
                alt={student?.fullName ?? ""}
                size={40}
                src={student?.avatarUrl}
              >
                {getInitials(student?.fullName ?? null)}
              </ProtectedAvatar>
            </Popover>
          </Col>
          <Col grow={1} padding="0 0 0 12px">
            <Heading level={4} weight="bold" maxWidth="calc(70vw - 60px)">
              {student?.fullName}
            </Heading>
            <Heading level={6}>
              Last Seen:{" "}
              {LabelHelper.relativeDateLabel(moment(student?.lastSeen))}
            </Heading>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
