import { OrganizationSettingsEnum } from "App";
import { IThreadContext } from "./IThreadContext";
import { SettingsHelper } from "./SettingsHelper";

export interface IPrompt {
  name: string;
  label: string;
}

export class PromptHelper {
  static PROMPT_NONE = "";
  static PROMPT_INDEPENDENT = "Independent";

  static generateLabel(name: string) {
    if (name === PromptHelper.PROMPT_INDEPENDENT) {
      return "Prompt";
    }
    const maxLabelSize = 4;
    let label = name.match(/\b\w/g)?.join("").toUpperCase() ?? "";
    if (label.length > maxLabelSize) label = label.substring(0, maxLabelSize);
    return label;
  }

  static standardPrompts(context: IThreadContext) {
    const useHihPromptHierarchy = SettingsHelper.boolean(
      context,
      OrganizationSettingsEnum.HIH_HIERARCHY
    );
    const promptMap: { [key: string]: IPrompt } = {};
    const standardPrompts: IPrompt[] = [];
    if (useHihPromptHierarchy) {
      standardPrompts.push({ name: "Full physical", label: "FP" });
      standardPrompts.push({ name: "Partial physical", label: "PP" });
      standardPrompts.push({ name: "Model", label: "M" });
      standardPrompts.push({ name: "Gestural", label: "G" });
      standardPrompts.push({ name: "Vocal verbal", label: "VV" });
      standardPrompts.push({ name: "Partial vocal verbal", label: "PVV" });
      standardPrompts.push({ name: "Positional", label: "P" });
    } else {
      standardPrompts.push({ name: "Gestural", label: "G" });
      standardPrompts.push({ name: "Demonstration", label: "D" });
      standardPrompts.push({ name: "Full physical", label: "FP" });
      standardPrompts.push({ name: "Partial physical", label: "PP" });
      standardPrompts.push({ name: "Full verbal", label: "FV" });
      standardPrompts.push({ name: "Partial verbal", label: "PV" });
      standardPrompts.push({ name: "Positional", label: "P" });
      standardPrompts.push({ name: "Textual", label: "T" });
      standardPrompts.push({ name: "Auditory", label: "A" });
    }
    standardPrompts.forEach(prompt => {
      promptMap[prompt.name] = prompt;
    });
    return promptMap;
  }

  static prompts(context: IThreadContext) {
    const standardMap: {
      [key: string]: IPrompt;
    } = PromptHelper.standardPrompts(context);
    const promptMap: { [key: string]: IPrompt } = standardMap;
    const addPrompts = Object.keys(context.settings).filter(k =>
      k.startsWith("add_prompt:")
    );
    addPrompts.forEach(prompt => {
      const setting = context.settings[prompt];
      if (setting.value) {
        promptMap[setting.value] = {
          name: setting.value,
          label: PromptHelper.generateLabel(setting.value)
        };
      }
    });
    const hidePrompts = Object.keys(context.settings).filter(k =>
      k.startsWith("hide_prompt:")
    );
    hidePrompts.forEach(prompt => {
      const setting = context.settings[prompt];
      if (setting.value) {
        delete promptMap[setting.value];
      }
    });
    return promptMap;
  }
}
