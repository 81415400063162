import gql from "graphql-tag";
import { QueryFunctionOptions, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import {
  onboardingStaff,
  onboardingStaffVariables
} from "../Shared/OnboardingApi/onboardingStaff";
import {
  onboardingStaffConfirm,
  onboardingStaffConfirmVariables
} from "../Shared/OnboardingApi/onboardingStaffConfirm";
import {
  onboardingStaffProfile,
  onboardingStaffProfileVariables
} from "../Shared/OnboardingApi/onboardingStaffProfile";
import {
  onboardingStaffSetup,
  onboardingStaffSetupVariables
} from "../Shared/OnboardingApi/onboardingStaffSetup";
import { UseQueryOptions } from "../types";
import {
  OnboardingStaffConfirmInput,
  OnboardingStaffProfileInput,
  OnboardingStaffSetupInput
} from "Shared/OnboardingApi/globalTypes";

const MUTATION_STAFF_CONFIRM = gql`
  mutation onboardingStaffConfirm($input: OnboardingStaffConfirmInput!) {
    onboardingStaffConfirm(input: $input) {
      staffRole
      status
    }
  }
`;

const MUTATION_STAFF_PROFILE = gql`
  mutation onboardingStaffProfile($input: OnboardingStaffProfileInput!) {
    onboardingStaffProfile(input: $input) {
      staffRole
      status
    }
  }
`;

const MUTATION_STAFF_SETUP = gql`
  mutation onboardingStaffSetup($input: OnboardingStaffSetupInput!) {
    onboardingStaffSetup(input: $input) {
      staffRole
      status
    }
  }
`;

export const QUERY_ONBOARDING_STAFF = gql`
  query onboardingStaff($staffId: GUID!, $orgId: GUID!, $code: String!) {
    onboardingStaff(staffId: $staffId, orgId: $orgId, code: $code) {
      id
      organizationName
      staffFirstName
      staffLastName
      staffRole
      staffPhoneNumber
      status
    }
  }
`;

export const useOnboardingStaffQuery = (
  staffId: string,
  orgId: string,
  code: string,
  options?: UseQueryOptions
) => {
  return useQuery<onboardingStaff, onboardingStaffVariables>(
    QUERY_ONBOARDING_STAFF,
    Object.assign(
      {},
      {
        variables: { staffId, orgId, code },
        fetchPolicy: "network-only"
      },
      { ...options }
    ) as QueryFunctionOptions<onboardingStaff, onboardingStaffVariables>
  );
};

export const useStaffConfirmMutation = () => {
  const [mutate, { error, data }] = useMutation<
    onboardingStaffConfirm,
    onboardingStaffConfirmVariables
  >(MUTATION_STAFF_CONFIRM);

  const handleConfirm = useCallback(
    (input: OnboardingStaffConfirmInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { confirm: handleConfirm, error, data };
};

export const useStaffProfileMutation = () => {
  const [mutate, { error, data }] = useMutation<
    onboardingStaffProfile,
    onboardingStaffProfileVariables
  >(MUTATION_STAFF_PROFILE);

  const handleProfile = useCallback(
    (input: OnboardingStaffProfileInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { profile: handleProfile, error, data };
};

export const useStaffSetupMutation = () => {
  const [mutate, { error, data }] = useMutation<
    onboardingStaffSetup,
    onboardingStaffSetupVariables
  >(MUTATION_STAFF_SETUP);

  const handleSetup = useCallback(
    (input: OnboardingStaffSetupInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { setup: handleSetup, error, data };
};
