import { Col, Heading, Icon, Row, Tooltip } from "@raudabaugh/thread-ui";
import React from "react";
import { MessageFragment } from "../Shared/Api/MessageFragment";

interface IMessageStatusProps {
  message: MessageFragment;
  onViewChart: (
    studentId: string,
    programId: string,
    messageId: string,
    locked: boolean
  ) => void;
  canUnlock: boolean;
}

export const MessageStatus = (props: IMessageStatusProps) => {
  const handleChartClick = (locked: boolean) => {
    if (props.message?.programId) {
      props.onViewChart(
        props.message.student.id,
        props.message.programId,
        props.message.id,
        locked
      );
    }
  };

  // Locked program
  if (props.message.program?.locked) {
    return (
      <Col type="flex" grow={0} basis="96px">
        <Row type="flex" wrap={true}>
          <Tooltip title="Program locked pending supervisor action">
            <Col type="flex" grow={0} basis="32px">
              <Row type="flex" justify="center" margin="4px 8px">
                <Icon
                  size="28px"
                  color="alert"
                  variation={6}
                  type="fa-lock far"
                />
              </Row>
            </Col>
          </Tooltip>
          <Col
            type="flex"
            grow={0}
            basis="32px"
            onClick={() => handleChartClick(true)}
          >
            <Row type="flex" justify="center" margin="4px 8px">
              <Icon size="32px" color="primary" type="icon-Chart" />
            </Row>
            <Row type="flex" justify="center">
              <Col type="flex" basis="0">
                <Heading level={7} align="center">
                  View Chart
                </Heading>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  // Not locked program
  return (
    <Col
      type="flex"
      grow={0}
      basis="96px"
      onClick={() => handleChartClick(false)}
    >
      <Row type="flex" justify="center" margin="4px 8px">
        <Icon size="32px" color="primary" type="icon-Chart" />
      </Row>
      <Row type="flex" justify="center">
        <Col type="flex" grow={1} basis="0">
          <Heading level={7} align="center">
            View Chart {props.canUnlock && "& Dismiss"}
          </Heading>
        </Col>
      </Row>
    </Col>
  );
};
