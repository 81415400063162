import {
  Heading,
  IColumnProps,
  Table,
  Row,
  Spin,
  Col
} from "@raudabaugh/thread-ui";
import { useHistory } from "react-router";
import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { StudentProfileFragment } from "Shared/Api/StudentProfileFragment";
import moment from "moment";
import { LabelHelper } from "Shared/LabelHelper";
import { RouterHelper } from "Routes/RouterHelper";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import _ from "lodash";

interface IStudentTableProps {
  data: StudentProfileFragment[];
  handleLoadData?: () => void;
  hasMore: boolean;
  handleOnChange?: (pagination: any, filter: any, sorter: any) => void;
  myStudents: boolean;
}

export const StudentTable = (props: IStudentTableProps) => {
  const history = useHistory();
  let columns: Array<IColumnProps<StudentProfileFragment>> = [
    {
      dataIndex: "fullname",
      key: "fullname",
      render: (text: any, record: StudentProfileFragment) => {
        return (
          <Heading
            id={record.fullName!.replace(/\s/g, "")}
            level={6}
            color="primary"
            noTooltip={true}
          >
            {record.fullName}
          </Heading>
        );
      },
      defaultSortOrder: "ascend",
      sorter: props.handleOnChange
        ? true
        : (a: StudentProfileFragment, b: StudentProfileFragment) =>
            (a.fullName ?? "").localeCompare(b.fullName ?? ""),
      title: "Student Name"
    }
  ];
  if (!props.handleOnChange) {
    columns.push({
      dataIndex: "Last Seen",
      key: "lastSeen",
      render: (text: any, record: StudentProfileFragment) => (
        <Heading level={6} noTooltip={true}>
          {record.lastSeen &&
            LabelHelper.relativeDateLabel(moment(record.lastSeen))}
        </Heading>
      ),
      sorter: props.handleOnChange
        ? true
        : (a: StudentProfileFragment, b: StudentProfileFragment) =>
            (b.lastSeen ? moment(b.lastSeen) : moment()).diff(
              a.lastSeen ? moment(a.lastSeen) : moment()
            ),
      title: "Last Seen"
    });
  }
  columns.push({
    dataIndex: "Primary Guardian Email",
    key: "email",
    render: (text: any, record: StudentProfileFragment) => (
      <Heading level={6} maxWidth={"70%"} noTooltip={true}>
        {record.primaryGuardian?.email}
      </Heading>
    ),
    sorter: props.handleOnChange
      ? true
      : (a: StudentProfileFragment, b: StudentProfileFragment) =>
          (a.primaryGuardian?.email ?? "").localeCompare(
            b.primaryGuardian?.email ?? ""
          ),
    title: "Primary Guardian Email"
  });
  columns.push({
    dataIndex: "status",
    xs: {
      visible: false
    },
    filterMultiple: false,
    sm: {
      visible: false
    },
    filters: [
      {
        text: "Inactive",
        value: "INACTIVE"
      },
      {
        text: "Active",
        value: "ACTIVE"
      }
    ],
    key: "status",
    render: (text: any, record: StudentProfileFragment, index: number) => {
      return (
        <Row type="flex" align="middle">
          <Col>{_.capitalize(record.status)}</Col>
        </Row>
      );
    },
    sorter: true,
    title: "Status"
  });
  const defineRecord = (record: StudentProfileFragment) => {
    return `${record.id!}`;
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: props.hasMore,
    onLoadMore: props.handleLoadData ? props.handleLoadData : () => {},
    rootMargin: "0px 0px 400px 0px"
  });

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        dataSource={props.data}
        onRow={(record: StudentProfileFragment) => ({
          onClick: () =>
            RouterHelper.redirectToStudentProfile(
              record.id!,
              history,
              false,
              props.myStudents
                ? LabelHelper.routePathLabel(RoutePathEnum.MY_STUDENT_LIST)
                : LabelHelper.routePathLabel(RoutePathEnum.PEOPLE_MANAGEMENT)
            )
        })}
        rowKey={defineRecord}
        onChange={props.handleOnChange}
        loading={props.hasMore && props.data.length === 0}
      />
      {props.data && props.data.length > 0 && props.hasMore && (
        <Row
          innerRef={sentryRef as unknown as React.RefObject<any>}
          justify="center"
          type="flex"
        >
          <Spin />
        </Row>
      )}
    </>
  );
};
