import {
  Col,
  Grid,
  Heading,
  Icon,
  Media,
  List,
  Modal,
  Text,
  ListItem,
  Row,
  Menu,
  MenuItem,
  Dropdown,
  Avatar,
  Paragraph,
  Illustration,
  Card,
  Button,
  Notification
} from "@raudabaugh/thread-ui";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { getInitials } from "../Shared/Initials";
import { IActiveMessages } from "../Shared/Api/IActiveMessages";
import { MessageStatus } from "./MessageStatus";
import { LabelHelper } from "../Shared/LabelHelper";
import {
  useMessageClearMutation,
  useActiveMessagesQuery
} from "../DataAccess/MessageData";
import { MessageClearInput, Permissions } from "Shared/Api/globalTypes";
import { MessageFragment } from "Shared/Api/MessageFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import notifiyImage from "./undraw-notify-illustration.svg";
import { findPermission } from "Shared/RolesMap";
import { useThreadContext } from "ContextHooks/ThreadContextHook";

export interface INotificationsProps {
  onViewChart: (studentId: string, programId: string) => void;
}

export const Notifications = (props: INotificationsProps) => {
  const { threadUserContext } = useThreadContext();
  const { messageClear } = useMessageClearMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<
    MessageFragment[] | null | undefined
  >(undefined);
  const hasEditNotificationsPermission = findPermission(
    threadUserContext.role,
    Permissions.EDIT_NOTIFICATIONS
  );
  const {
    loading: msgsLoading,
    error: msgsError,
    data: msgsData
  } = useActiveMessagesQuery();

  const updateData = async (data: IActiveMessages) => {
    if (data?.messagesActive) {
      setMessages(data.messagesActive);
    }
  };

  useEffect(() => {
    msgsError &&
      NotificationsHelper.ErrorNotification({
        error: msgsError,
        title: "Failed to Clear Alerts"
      });
    msgsData && updateData(msgsData);
    msgsLoading && setLoading(true);
    !msgsLoading && setLoading(false);
  }, [msgsLoading, msgsError, msgsData]);

  const handleChartClick = async (
    studentId: string,
    programId: string,
    messageId: string,
    locked: boolean
  ) => {
    if (!locked && hasEditNotificationsPermission) {
      // Dismiss the notification
      const input: MessageClearInput = {
        messageIds: [messageId]
      };
      await messageClear(input);
    }
    props.onViewChart(studentId, programId);
  };

  const handleMenuClick = () => {
    Modal.confirm({
      title: `Are you sure you want to clear all of the alerts?`,
      content: (
        <>
          <Paragraph>
            This will clear all of <Text emphasis={true}>your</Text> alerts for
            all of your assigned students and can not be undone. This will not
            affect the programs themselves, so locked programs will continue to
            be locked until a supervisor or admin unlocks them. Other users who
            also received this alert will still have it.
          </Paragraph>
        </>
      ),
      okText: `Clear Alerts`,
      cancelText: "Cancel",
      onOk: removeAlerts,
      okButtonProps: { danger: true }
    });
  };

  const removeAlerts = () => {
    if (messages) {
      const messageIds = messages.map(m => m.id);
      const input: MessageClearInput = {
        messageIds: messageIds
      };

      messageClear(input)
        .then(() => {
          Notification.success({
            duration: 0,
            message: "Alerts Cleared!",
            description: `Successfully cleared ${messageIds.length} alert${
              messageIds.length > 1 ? "s" : ""
            }.`
          });
        })
        .catch(error => {
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Clear Alerts"
          });
        });
    }
  };

  const actions = (
    <Menu onClick={handleMenuClick} selectable={false} mode="vertical">
      <MenuItem>
        <Icon type="fa-trash fas" width="28px" />
        Clear all alerts
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <LoadingScreen loading={loading} />
      {!loading && (
        <Grid>
          <Row padding="16px" type="flex">
            <Col grow={1}>
              <Heading level={3}>Clinical Alerts</Heading>
            </Col>
            {hasEditNotificationsPermission && (
              <Col grow={0}>
                <Dropdown trigger={["click"]} overlay={actions}>
                  <Button ghost={true} borderless={true}>
                    <Icon
                      color="default"
                      variation={10}
                      type="fa-ellipsis-v fas"
                    />
                  </Button>
                </Dropdown>
              </Col>
            )}
          </Row>
          <Row padding="0 0 32px 0">
            <Illustration src={notifiyImage} />
          </Row>
          <Row type="flex" justify="center" margin="0 10% 8px 10%">
            <Paragraph type="secondary">
              Alerts are generated when a chart needs your attention. Once the
              chart no longer needs your attention, the alert will disappear.
              You can also manually clear your alerts if you don't want to see
              them.
            </Paragraph>
          </Row>
          <Row type="flex" justify="center">
            {messages && messages.length > 0 ? (
              <Card variation={1} margin="8px 0" minWidth="90%">
                <List>
                  {messages.map((message, index) => {
                    const student = message.student;
                    const messageDate = message.createdAt
                      ? LabelHelper.relativeDateLabel(moment(message.createdAt))
                      : "";
                    return (
                      <ListItem key={message.id}>
                        <Grid width="100%">
                          <Row type="flex" justify="center" wrap={true}>
                            <Media.Sm orLarger>
                              <Col type="flex" grow={0} padding="0 16px 0 0">
                                <Avatar
                                  color="primary"
                                  alt={`${student.firstName} ${student.lastName}`}
                                  size={56}
                                  src={student.avatarUrl!}
                                >
                                  {getInitials(
                                    `${student.firstName} ${student.lastName}`
                                  )}
                                </Avatar>
                              </Col>
                            </Media.Sm>
                            <Col type="flex" grow={1}>
                              <Heading level={5}>{message.body}</Heading>
                              <Media.Sm orSmaller>
                                <Row>{messageDate}</Row>
                              </Media.Sm>
                            </Col>
                            <Media.Md orLarger>
                              <Col type="flex" grow={0} padding="0 32px 0 0">
                                {messageDate}
                              </Col>
                            </Media.Md>
                            <MessageStatus
                              message={message}
                              canUnlock={hasEditNotificationsPermission}
                              onViewChart={handleChartClick}
                            />
                          </Row>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            ) : (
              <Heading level={4}>No alerts found</Heading>
            )}
          </Row>
        </Grid>
      )}
    </>
  );
};
