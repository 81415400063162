import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect
} from "react";
import {
  Button,
  Col,
  Divider,
  Grid,
  Heading,
  Icon,
  InputTextArea,
  Link,
  Text,
  Notification,
  Paragraph,
  PointShape,
  Row,
  PointTypeEnum,
  Modal
} from "@raudabaugh/thread-ui";
import { findPermission } from "../Shared/RolesMap";
import moment from "moment";
import { DataPointHelper } from "../Shared/DataPointHelper";
import { useDataPointUpdateMutation } from "../DataAccess/DataPointData";
import { DurationDetail } from "./DurationDetail";
import { FrequencyDetail } from "./FrequencyDetail";
import { IntervalDetail } from "./IntervalDetail";
import { DiscreteTrialDetail } from "./DiscreteTrialDetail";
import { TaskAnalysisDetail } from "./TaskAnalysisDetail";
import { PointEditPopup } from "./PointEditPopup";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import {
  CollectionMethodEnum,
  DataPointInput,
  DataPointTypeEnum,
  ProgramTypeEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { LabelHelper } from "Shared/LabelHelper";
import { ChartHelper } from "./ChartHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";

interface IPointPopupProps {
  program: ProgramFragment;
  point: DataPointFragment;
  studentId: string;
  date: moment.Moment;
  phases: PhaseFragment[];
  pointPhase: PhaseFragment;
  onSoftDelete: (id: string, studentId: string, phaseId: string) => void;
  onUpdateChartPoint: (
    pointId: string,
    studentId: string,
    programId: string,
    input: DataPointInput,
    originalPhaseId: string,
    programType: ProgramTypeEnum
  ) => void;
}

interface IPointPopupDisplayFields {
  createdAt?: DateTime;
  createdByUserLabel?: string;
  phaseTitle?: string;
  type?: string;
  modifiedAt?: DateTime;
  modifiedByName: string;
}

export interface CancelEditing {
  cancelEditing: () => void;
}

export const PointPopup = forwardRef<CancelEditing, IPointPopupProps>(
  (props, ref) => {
    const { threadUserContext } = useThreadContext();
    const { dataPointUpdate } = useDataPointUpdateMutation();
    const [editingNote, setEditingNote] = useState(false);
    const [editingDataPoint, setEditingDataPoint] = useState(false);
    const [noteText, setNoteText] = useState<string | null>(props.point.note);
    const [viewTrials, setViewTrials] = useState(false);
    const online = useOnlineStatus();

    const canDelete = findPermission(
      threadUserContext.role,
      Permissions.SOFT_DELETE_DATA_POINT
    );

    useEffect(() => {
      setNoteText(props.point?.note);
    }, [props.point]);

    const showOfflineNotification = (label: string) => {
      Notification.warn({
        duration: 0,
        message: "You are offline.",
        description: `The ${label} function is not available while in offline mode.`
      });
    };
    const handleEditDetailsClick = () => {
      if (!online) {
        showOfflineNotification("Edit Details");
        return;
      }
      setEditingDataPoint(true);
    };
    const handleDeleteDatapointClick = () => {
      if (!online) {
        showOfflineNotification("Delete Datapoint");
        return;
      }

      Modal.warning({
        okCancel: true,
        title: `Are you sure you want to delete this data point?`,
        onOk: () =>
          props.onSoftDelete(
            props.point.id,
            props.studentId,
            props.pointPhase.id
          ),

        okText: "Delete",
        cancelText: "Cancel",
        okButtonProps: { danger: true }
      });
    };
    const handleAddNoteClick = () => {
      if (!online) {
        showOfflineNotification("Add Note");
        return;
      }
      setEditingNote(true);
    };
    const cancelEditing = () => {
      setEditingNote(false);
      setEditingDataPoint(false);
      const text = props.point?.note ?? null;
      setNoteText(text);
    };

    useImperativeHandle(ref, () => ({ cancelEditing }));

    const getCreatedByUser = () => {
      const uniqueUsers = Array.from(
        new Set(props.point.trials.map(t => t.createdByName))
      );
      if (!uniqueUsers || uniqueUsers.length <= 1) {
        return props.point.createdByName ?? "";
      }
      return uniqueUsers.join("; ") ?? "";
    };

    const createdByUser = getCreatedByUser();

    const updateNote = () => {
      dataPointUpdate(props.point.id ?? "", props.studentId, {
        note: noteText
      });
      setEditingNote(false);
      setEditingDataPoint(false);
      const text = props.point?.note ?? null;
      setNoteText(text);
    };

    const phaseTargets = props.pointPhase.targetIds.map(id =>
      props.program.targets.find(t => t.id === id)
    );

    const displayFields: IPointPopupDisplayFields = {
      createdAt: props.point.completedAt ?? undefined,
      createdByUserLabel: createdByUser ? `by ${createdByUser}` : "",
      phaseTitle: ChartHelper.getPhaseName(props.pointPhase, phaseTargets),
      type: props.point.pointType ?? "",
      modifiedAt: props.point.modifiedAt,
      modifiedByName: props.point.modifiedByName ?? ""
    };

    let heading = "ERROR";
    let subheading: string | undefined = undefined;

    const correct = DataPointHelper.correctTrialCount(
      props.program.type,
      props.point,
      props.pointPhase
    );
    let createdFormat = "YYYY-MM-DD [at] h:mm a";
    if (props.program.type === ProgramTypeEnum.FREQUENCY) {
      createdFormat = "YYYY-MM-DD";
      heading = `${correct} Total`;
    } else if (props.program.type === ProgramTypeEnum.DURATION) {
      const duration = DataPointHelper.durationTotal(
        props.program.type,
        props.point,
        props.pointPhase
      );
      createdFormat = "YYYY-MM-DD";
      heading = DataPointHelper.formatDuration(duration) + " Total";
    } else {
      const attempted = DataPointHelper.applicableTrialCount(
        props.program.type,
        props.point,
        props.pointPhase
      );
      const percent = Math.round((correct * 100) / attempted);
      subheading = `${correct}/${attempted} trials correct`;
      heading = `${percent}% Accuracy`;
    }

    const pointHeading = (
      <>
        <Row type="flex">
          <Col>
            <Heading level={4} variation={1}>
              <Text strong={true}>{heading}</Text>
            </Heading>
          </Col>
        </Row>
        {subheading && (
          <Row>
            <Col>
              <Heading level={5} variation={2} color="default" weight="regular">
                {subheading}
              </Heading>
            </Col>
          </Row>
        )}
      </>
    );

    const itemizedResults = DataPointHelper.pointItemizedResults(props.program.type, phaseTargets, props.point.trials);
    const hasPointTrialTargets = props.point.trials.filter(trial => !!trial.targetId).length > 0;
    
    const itemizedTrials = (
      <>
        <Row type='flex' margin="0 0 0 16px">
          <Col>
              {itemizedResults.map((data, index) => {
                return ( index < 5 ? 
                  <Paragraph key={`trial_${index}`} variation={2} color="default" ellipsis margin="0">
                      {data.plus}/{data.total} - {data.targetDescription}
                  </Paragraph>
                  : <Paragraph key={`trial_${index}`} variation={2} color="default" margin="0" level={7}>...{itemizedResults.length - 5} more</Paragraph>
                );
              })}
          </Col>
        </Row>
      </>
    );

    const renderTrialDataTable = () => {
      switch (props.program.type) {
        case ProgramTypeEnum.DTT:
          return (
            <DiscreteTrialDetail
              studentId={props.studentId}
              data={props.point!}
              program={props.program}
              phases={props.phases}
              heading={pointHeading}
              onClose={() => setViewTrials(false)}
            />
          );
        case ProgramTypeEnum.DURATION:
          return (
            <DurationDetail
              studentId={props.studentId}
              data={props.point}
              date={moment(props.point.startedAt ?? props.date)}
              heading={pointHeading}
              onClose={() => setViewTrials(false)}
            />
          );
        case ProgramTypeEnum.FREQUENCY:
          return (
            <FrequencyDetail
              studentId={props.studentId}
              data={props.point}
              date={props.date}
              heading={pointHeading}
              onClose={() => setViewTrials(false)}
            />
          );
        case ProgramTypeEnum.INTERVAL:
          return (
            <IntervalDetail
              studentId={props.studentId}
              data={props.point}
              phase={props.pointPhase}
              sampleTime={props.pointPhase.lengthOfEachInterval ?? undefined}
              heading={pointHeading}
              onClose={() => setViewTrials(false)}
            />
          );
        case ProgramTypeEnum.TASK_ANALYSIS:
          return (
            <TaskAnalysisDetail
              data={props.point}
              phases={props.phases}
              studentId={props.studentId}
              heading={pointHeading}
              onClose={() => setViewTrials(false)}
            />
          );
      }
    };

    if (viewTrials) {
      return <Grid width="100%">{renderTrialDataTable()}</Grid>;
    }

    if (editingDataPoint) {
      return (
        <PointEditPopup
          headingText={heading}
          subHeadingText={subheading ?? ""}
          createdByUserLabel={displayFields.createdByUserLabel ?? ""}
          program={props.program}
          point={props.point}
          studentId={props.studentId}
          phases={props.phases}
          pointPhase={props.pointPhase}
          onUpdateChartPoint={props.onUpdateChartPoint}
          onClose={() => setEditingDataPoint(false)}
        />
      );
    }

    return (
      <Grid type="flex" width="100%">
        {pointHeading}
        {hasPointTrialTargets && itemizedResults && itemizedTrials}
        {props.point.method !== CollectionMethodEnum.PAPER && (
          <Row margin="10px 0px 0px 0px">
            <Link onClick={() => setViewTrials(true)}>
              <Heading level={5} color="primary">
                View trial data
              </Heading>
            </Link>
          </Row>
        )}

        <Row>
          <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
        </Row>

        <Row type="flex" justify="start" gutter={5}>
          <Col>
            <Heading level={6}>
              <Text strong={true}>Date: </Text>
              {`${moment
                .utc(displayFields.createdAt)
                .local()
                .format(createdFormat)}
                ${displayFields.createdByUserLabel}`}
            </Heading>
          </Col>
        </Row>
        <Row type="flex" gutter={5} justify="start">
          <Col>
            <Heading level={6}>
              <Text strong={true}>Phase: </Text>
              {displayFields.phaseTitle}
            </Heading>
          </Col>
        </Row>
        <Row type="flex" alignItems="center" justify="space-between">
          <Col type="flex">
            <Row type="flex" justify="start" alignItems="center">
              <Col type="flex">
                <Heading level={6} variation={5} margin="0px">
                  <Text strong={true}>Data Type: &nbsp;</Text>
                </Heading>
              </Col>
              <Col type="flex">
                <PointShape
                  type={(
                    props.point.pointType ?? PointTypeEnum.STANDARD
                  ).toLocaleLowerCase()}
                ></PointShape>
              </Col>
              <Col type="flex">
                <Text strong={false}>
                  {LabelHelper.dataPointTypeLabel(
                    props.point.pointType ?? DataPointTypeEnum.STANDARD
                  )}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col type="flex">
            <Link onClick={() => handleEditDetailsClick()}>Edit Details</Link>
          </Col>
        </Row>

        <Row>
          <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
        </Row>

        {!noteText && !editingNote ? (
          <Row>
            <Link onClick={() => handleAddNoteClick()}>
              <Heading level={5} color="primary">
                <Icon type="fa-plus fas" /> Add Note
              </Heading>
            </Link>
          </Row>
        ) : !editingNote ? (
          <Row type="flex" alignItems="flex-end" justify="space-between">
            <Col type="flex">
              <Paragraph level={6} variation={5} maxWidth="26em" margin="0">
                {noteText}
              </Paragraph>
            </Col>
            <Col type="flex">
              <Link
                onClick={() => {
                  setEditingNote(true);
                }}
              >
                Edit Note
              </Link>
            </Col>
          </Row>
        ) : (
          <>
            <Row type="flex">
              <Heading level={6}>Note:</Heading>
              <InputTextArea
                rows={5}
                id="note"
                value={noteText ?? ""}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNoteText(event.target.value)
                }
              />
            </Row>
            <Row padding="16px 0" type="flex" justify="center" gutter={16}>
              <Col span={12}>
                {
                  <Button
                    size="large"
                    block={true}
                    type="default"
                    onClick={cancelEditing}
                  >
                    Cancel
                  </Button>
                }
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  block={true}
                  type="primary"
                  onClick={updateNote}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {props.point.method === CollectionMethodEnum.PAPER && (
          <>
            <Row>
              <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
            </Row>
            <Row type="flex" alignItems="center" justify="start" gutter={5}>
              <Col type="flex">
                <Heading level={6} variation={5}>
                  <Icon
                    color="warning"
                    type="fa-exclamation-triangle far"
                    width="20px"
                    margin="0 4px 0 0"
                  ></Icon>
                  <Text strong={true}>Entered manually:&nbsp;</Text>
                  <Text>
                    {`${moment
                      .utc(displayFields.modifiedAt)
                      .local()
                      .format("MMM D, YYYY")} at ${moment
                      .utc(displayFields?.modifiedAt)
                      .local()
                      .format("h:mm a")} by ${displayFields?.modifiedByName}`}
                  </Text>
                </Heading>
              </Col>
            </Row>
          </>
        )}

        {canDelete && (
          <>
            <Row>
              <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
            </Row>
            <Row type="flex">
              <Col type="flex" grow={2}>
                <Link onClick={() => handleDeleteDatapointClick()}>
                  <Heading level={6} color="error">
                    Delete Datapoint
                  </Heading>
                </Link>
              </Col>
              <Col grow={22}></Col>
            </Row>
          </>
        )}
      </Grid>
    );
  }
);
