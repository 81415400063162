import gql from "graphql-tag";
import { useMutation, useSubscription } from "@apollo/client";
import { client } from "../App";
import { FRAGMENT_DATA_POINT } from "./Fragments";
import { v4 as uuid, v5 as deterministicUuid } from "uuid";
import _ from "lodash";
import { PromptHelper } from "Shared/PromptHelper";
import { DataPointFragment } from "../Shared/Api/DataPointFragment";
import { SUBSCRIPTION_DATA_POINT_ON_UPDATE } from "./Subscriptions";
import {
  DataPointInput,
  ProgramTypeEnum,
  TrialInput,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import {
  dataPointUpdateTrial,
  dataPointUpdateTrialVariables
} from "Shared/Api/dataPointUpdateTrial";
import {
  dataPointAddTrial,
  dataPointAddTrialVariables
} from "Shared/Api/dataPointAddTrial";
import {
  dataPointDeleteTrial,
  dataPointDeleteTrialVariables
} from "Shared/Api/dataPointDeleteTrial";
import {
  dataPointScore,
  dataPointScoreVariables
} from "Shared/Api/dataPointScore";
import {
  dataPointUpdate,
  dataPointUpdateVariables
} from "Shared/Api/dataPointUpdate";
import {
  dataPointRestore,
  dataPointRestoreVariables
} from "Shared/Api/dataPointRestore";
import {
  dataPointSoftDelete,
  dataPointSoftDeleteVariables
} from "Shared/Api/dataPointSoftDelete";
import {
  dataPointOnUpdate,
  dataPointOnUpdateVariables
} from "Shared/Api/dataPointOnUpdate";
import { TrialFragment } from "Shared/Api/TrialFragment";
import moment from "moment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { UseSubscriptionOptions } from "types";
import { useCallback } from "react";
import { useThreadContext } from "ContextHooks/ThreadContextHook";

const MUTATION_SCORE = gql`
  mutation dataPointScore(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $correct: Int!
    $attempted: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: $correct
      attempted: $attempted
      duration: 0
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_DELETE_TRIAL = gql`
  mutation dataPointDeleteTrial(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
  ) {
    dataPointDeleteTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_UPDATE_TRIAL = gql`
  mutation dataPointUpdateTrial(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $input: TrialInput!
  ) {
    dataPointUpdateTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
      input: $input
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_ADD_TRIAL = gql`
  mutation dataPointAddTrial(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $input: TrialInput!
  ) {
    dataPointAddTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
      input: $input
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_DATA_POINT_UPDATE = gql`
  mutation dataPointUpdate(
    $studentId: GUID!
    $pointId: GUID!
    $input: DataPointInput!
  ) {
    dataPointUpdate(studentId: $studentId, pointId: $pointId, input: $input) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SOFT_DELETE_UPDATE = gql`
  mutation dataPointSoftDelete($pointId: GUID!, $studentId: GUID!) {
    dataPointSoftDelete(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_RESTORE_UPDATE = gql`
  mutation dataPointRestore($pointId: GUID!, $studentId: GUID!) {
    dataPointRestore(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const readDataPointAndAddTrial = (
  pointId: string,
  trialId: string,
  input: TrialInput,
  createdById: string,
  createdByName: string
) => {
  const point: DataPointFragment | null = _.cloneDeep(
    client.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`
    })
  );
  if (!point) {
    throw Error("Could not find data point");
  }
  const trial: TrialFragment = {
    __typename: "TrialType",
    id: trialId,
    index: point.trials.length,
    result: input.result ?? TrialResultEnum.NONE,
    prompt: input.prompt ?? PromptHelper.PROMPT_INDEPENDENT,
    stepId: input.stepId ?? null,
    targetId: input.targetId ?? null,
    duration: null,
    occurredAt: null,
    createdById,
    createdByName
  };
  point.trials.push(trial);
  return point;
};

export const useDataPointUpdateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointUpdate,
    dataPointUpdateVariables
  >(MUTATION_DATA_POINT_UPDATE);

  const handleDataPointUpdate = useCallback(
    (pointId: string, studentId: string, input: DataPointInput) => {
      return mutate!({
        variables: { pointId, studentId, input },
        update: (cache, { data }) => {
          const point = data?.dataPointUpdate?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(
              cache,
              point,
              true,
              pointId
            );
          }
        }
      });
    },
    [mutate]
  );
  return { dataPointUpdate: handleDataPointUpdate, error, data };
};

export const useDataPointSoftDeleteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointSoftDelete,
    dataPointSoftDeleteVariables
  >(MUTATION_SOFT_DELETE_UPDATE);

  const handleDataPointSoftDelete = useCallback(
    (pointId: string, studentId: string) => {
      return mutate!({
        variables: { pointId, studentId },
        update: (cache, { data }) => {
          const point = data?.dataPointSoftDelete?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate]
  );
  return {
    handleDataPointSoftDelete,
    error,
    data
  };
};

export const useDataPointRestoreMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointRestore,
    dataPointRestoreVariables
  >(MUTATION_RESTORE_UPDATE);

  const handleDataPointRestore = useCallback(
    (pointId: string, studentId: string) => {
      return mutate!({
        variables: { pointId, studentId },
        update: (cache, { data }) => {
          const point = data?.dataPointRestore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate]
  );

  return {
    handleDataPointRestore,
    error,
    data
  };
};

const readDataPointAndUpdateTrial = (
  pointId: string,
  trialId: string,
  input: TrialInput,
  programType: ProgramTypeEnum
) => {
  const point: DataPointFragment | null = _.cloneDeep(
    client.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`
    })
  );
  if (!point) {
    throw Error("Could not find data point");
  }
  const trial = point.trials.find(trial => trial.id === trialId);
  if (trial) {
    if (input.prompt !== undefined) {
      trial.prompt = input.prompt;
    }
    if (input.result) {
      trial.result = input.result;
    }
    if (input.targetId !== undefined) {
      trial.targetId = input.targetId;
    }
    if (input.stepId !== undefined) {
      trial.stepId = input.stepId;
    }
    if (input.duration !== undefined) {
      trial.duration = input.duration;
    }
    if (input.occurredAt !== undefined) {
      trial.occurredAt = input.occurredAt;
    }
  }
  return point;
};

const readDataPointAndDeleteTrial = (
  pointId: string,
  trialId: string,
  programType: ProgramTypeEnum
) => {
  const point: DataPointFragment | null = _.cloneDeep(
    client.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`
    })
  );
  return point;
};

export const useDataPointScoreMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointScore,
    dataPointScoreVariables
  >(MUTATION_SCORE);

  const handleDataPointScore = useCallback(
    (
      pointId: string | undefined,
      phaseId: string,
      programId: string,
      programType: ProgramTypeEnum,
      studentId: string,
      correct: number,
      occurredAt: DateTime,
      attempted: number
    ) => {
      if (!pointId) {
        if (
          programType === ProgramTypeEnum.FREQUENCY ||
          programType === ProgramTypeEnum.DURATION
        ) {
          const day = moment(occurredAt).startOf("day");
          pointId = deterministicUuid(day.format("YYYY-MM-DD"), phaseId);
        } else {
          pointId = uuid();
        }
      }
      return mutate!({
        variables: {
          pointId,
          phaseId,
          studentId,
          correct,
          occurredAt,
          attempted
        },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate]
  );
  return { dataPointScore: handleDataPointScore, error, data };
};

export const useDataPointUpdateTrialMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointUpdateTrial,
    dataPointUpdateTrialVariables
  >(MUTATION_UPDATE_TRIAL);

  const handleDataPointUpdateTrial = useCallback(
    (
      pointId: string,
      studentId: string,
      trialId: string,
      input: TrialInput,
      programType: ProgramTypeEnum
    ) => {
      const optimisticResponse: dataPointUpdateTrial = {
        dataPointUpdateTrial: {
          __typename: "DataPointOutput",
          dataPoint: readDataPointAndUpdateTrial(
            pointId,
            trialId,
            input,
            programType
          ) as DataPointFragment
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, trialId, input }
      });
    },
    [mutate]
  );
  return {
    dataPointUpdateTrial: handleDataPointUpdateTrial,
    error,
    data
  };
};

export const useDataPointAddTrialMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { error, data }] = useMutation<
    dataPointAddTrial,
    dataPointAddTrialVariables
  >(MUTATION_ADD_TRIAL);

  const handleDataPointAddTrial = useCallback(
    (
      pointId: string,
      studentId: string,
      trialId: string,
      input: TrialInput
    ) => {
      const optimisticResponse: dataPointAddTrial = {
        dataPointAddTrial: {
          __typename: "DataPointOutput",
          dataPoint: readDataPointAndAddTrial(
            pointId,
            trialId,
            input,
            threadUserContext.userId,
            threadUserContext.userName
          ) as DataPointFragment
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, trialId, input }
      });
    },
    [mutate, threadUserContext.userId, threadUserContext.userName]
  );
  return {
    dataPointAddTrial: handleDataPointAddTrial,
    error,
    data
  };
};

export const useDataPointDeleteTrialMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointDeleteTrial,
    dataPointDeleteTrialVariables
  >(MUTATION_DELETE_TRIAL);

  const handleDataPointDeleteTrial = useCallback(
    (
      pointId: string,
      studentId: string,
      trialId: string,
      programType: ProgramTypeEnum
    ) => {
      const optimisticResponse: dataPointDeleteTrial = {
        dataPointDeleteTrial: {
          __typename: "DataPointOutput",
          dataPoint: readDataPointAndDeleteTrial(
            pointId,
            trialId,
            programType
          ) as DataPointFragment
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, trialId }
      });
    },
    [mutate]
  );
  return {
    dataPointDeleteTrial: handleDataPointDeleteTrial,
    error,
    data
  };
};

export const useDataPointOnUpdateSubscription = (
  studentIds: string[],
  options?: UseSubscriptionOptions<
    dataPointOnUpdate,
    dataPointOnUpdateVariables
  >
) => {
  return useSubscription<dataPointOnUpdate, dataPointOnUpdateVariables>(
    SUBSCRIPTION_DATA_POINT_ON_UPDATE,
    { variables: { studentIds }, ...options }
  );
};
