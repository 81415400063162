import {
  Button,
  ButtonGroup,
  Col,
  Grid,
  Heading,
  IClickParam,
  Menu,
  MenuItem,
  Modal,
  Row,
  Tag
} from "@raudabaugh/thread-ui";
import React, { memo, useState } from "react";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";
import { IPrompt, PromptHelper } from "Shared/PromptHelper";
import { ActionsMenu } from "./ActionsMenu";
import { LargeCardLayout } from "./LargeCardLayout";
import { SmallCardLayout } from "./SmallCardLayout";

export interface IAdvancedTrialCardProps {
  size: "sm" | "lg";
  locked: boolean;
  skip?: boolean;
  promptLevels?: IPrompt[];
  errorless?: boolean;
  prompt?: string;
  leftLabel: JSX.Element;
  centerLabel?: JSX.Element;
  rightLabel: JSX.Element;
  title: string;
  programType: string;
  caption: JSX.Element;
  index: number;
  targets: JSX.Element;
  disabled: boolean;
  studentId: string;
  onLeftButtonClick: () => void;
  onCenterButtonClick?: (prompt?: string) => void;
  onRightButtonClick: () => void;
  onUndoClick?: () => void;
  onAbcClick: () => void;
  onChartClick: () => void;
  onCloseClick: () => void;
  onEndClick?: () => void;
  onMoveToTopClick: () => void;
  onEnterScoreClick?: () => void;
  onRunAgainClick?: () => void;
  onChangePromptType?: (x: string) => void;
  onShowFocusDrawer?: () => void;
  onPopupClick: () => void;
  onSkipClick?: () => void;
}

const AdvancedTrialCard = (props: IAdvancedTrialCardProps) => {
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [independentCenter, setIndependentCenter] = useState(false);

  const renderCenterButton = () => {
    if (props.centerLabel) {
      return (
        <Button
          id={`${props.index}CenterButton`}
          disabled={props.disabled}
          type="primary"
          size="x-large"
          onClick={centerButtonCheck}
        >
          <Grid margin="0 -20px">
            <Row>{props.centerLabel}</Row>
            {props.programType === ProgramTypeEnum.TASK_ANALYSIS && props.size === "sm" && (
              <Row margin="0 0 -15px 0">{props.caption}</Row>
            )}
          </Grid>
        </Button>
      );
    } else {
      return null;
    }
  };

  const handlePromptSelect = (prompt: IClickParam) => {
    setPromptModalOpen(false);
    props.onChangePromptType?.(prompt.key);
    if (independentCenter) {
      props.onCenterButtonClick?.(prompt.key);
      setIndependentCenter(false);
    }
  };

  const independentCenterClick = () => {
    setPromptModalOpen(true);
    setIndependentCenter(true);
  };

  const centerButtonCheck = () => {
    props.prompt === PromptHelper.PROMPT_INDEPENDENT
      ? independentCenterClick()
      : props.onCenterButtonClick!(props.prompt);
  };

  const actions = (
    <ActionsMenu
      id={`Index${props.index + props.programType}DropDown`}
      {...props}
      onErrorlessClick={
        !!props.errorless ? () => setPromptModalOpen(true) : undefined
      }
    />
  );
  const body = (
    <Row
      type="flex"
      justify="space-between"
      alignItems="center"
      align="middle"
      grow={1}
    >
      {props.errorless && (
        <Modal
          width={350}
          footer={null}
          title="Choose a prompt level."
          visible={promptModalOpen}
          onCancel={() => setPromptModalOpen(false)}
        >
          <Menu onClick={handlePromptSelect}>
            {props.promptLevels!.map((prompt: IPrompt) => (
              <MenuItem key={prompt.name}>
                <Row type="flex">
                  <Col>
                    <Grid width="56px">
                      <Tag color="primary">{prompt.label}</Tag>
                    </Grid>
                  </Col>
                  <Col>{prompt.name}</Col>
                </Row>
              </MenuItem>
            ))}
          </Menu>
        </Modal>
      )}
      {props.locked ? (
        <ButtonGroup block>
          <Button disabled type="primary" size="x-large">
            <Heading weight="bold" level={5} color="primary" variation={6}>
              Pending Decision
            </Heading>
          </Button>
        </ButtonGroup>
      ) : props.skip && props.onSkipClick ? (
        <ButtonGroup block>
          <Button type="primary" size="x-large" onClick={props.onSkipClick}>
            <Heading weight="bold" level={5} color="default" variation={1}>
              Skip
            </Heading>
            {props.programType === ProgramTypeEnum.TASK_ANALYSIS && props.size === "sm" && (
              <Row margin="0 0 -15px 0">{props.caption}</Row>
            )}
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup block={true}>
          <Button
            id={props.programType + "LeftButton"}
            disabled={props.disabled}
            type="primary"
            size="x-large"
            onClick={props.onLeftButtonClick}
          >
            {props.leftLabel}
          </Button>
          {renderCenterButton()}
          <Button
            id={props.programType + "RightButton"}
            disabled={props.disabled}
            type="primary"
            size="x-large"
            onClick={props.onRightButtonClick}
          >
            {props.rightLabel}
            {props.programType === "DTT" &&
              props.size === "sm" &&
              props.caption}
          </Button>
        </ButtonGroup>
      )}
    </Row>
  );

  return props.size === "lg" ? (
    <LargeCardLayout
      title={props.title}
      actions={actions}
      onShowFocusDrawer={props.locked ? undefined : props.onShowFocusDrawer}
      onPopup={props.onPopupClick}
      caption={props.caption}
      targets={props.targets}
    >
      {body}
    </LargeCardLayout>
  ) : (
    <SmallCardLayout
      title={props.title}
      actions={actions}
      onShowFocusDrawer={props.locked ? undefined : props.onShowFocusDrawer}
      onPopup={props.onPopupClick}
    >
      {body}
    </SmallCardLayout>
  );
};

export default memo(AdvancedTrialCard);
