export enum FrequencyStepsEnum {
  HUNDRED = "hundred",
  TEN = "ten",
  FIVE = "five",
  TWO = "two",
  ONE = "one"
}

export enum DurationStepsEnum {
  HOUR = "hour",
  HALF_AN_HOUR = "halfAnHour",
  TEN_MINUTES = "tenMinutes",
  FIVE_MINUTES = "fiveMinutes",
  MINUTE = "minute",
  HALF_A_MINUTE = "halfAnMinute",
  TEN_SECONDS = "tenSeconds",
  FIVE_SECONDS = "fiveSeconds"
}

export type StepBarNameType = FrequencyStepsEnum | DurationStepsEnum;

export enum PointTypeEnum {
  STANDARD = "standard",
  PRETEST = "pretest",
  PROBE = "probe",
  POSTTEST = "posttest"
}
