import {
  ClinicalFileTypeEnum,
  TrialResultEnum,
  PhaseSettingEnum,
  PlaceOfServiceEnum,
  ReinforcementScheduleEnum,
  ArchiveTypeEnum,
  ProgramTypeEnum,
  OrganizationTypeEnum,
  DataPointTypeEnum,
  SessionNoteStatusEnum,
  IntervalTypeEnum,
  StaffRoleEnum,
  LockedTypeEnum
} from "./Api/globalTypes";
import { CountryData } from "@raudabaugh/thread-ui";
import moment from "moment";
import { StudentTabEnum } from "./StudentTabEnum";
import { ProgramTabEnum } from "../StudentPrograms/ProgramTabEnum";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import { IntervalUnitsEnum } from "StudentPrograms/IntervalUnitsEnum";
import { PromptingStrategyEnum } from "StudentPrograms/PromptingStrategyEnum";
import { IThreadContext } from "./IThreadContext";

export class LabelHelper {
  static monthYearLabelFormat(context: IThreadContext): string {
    const format =
      context.settings["default_locale"]?.value === "en-au" ? "D/M" : "M/D";
    return format;
  }

  static dateTimeLabel(date: moment.Moment, context: IThreadContext): string {
    const format =
      context.settings["default_locale"]?.value === "en-au"
        ? "DD/MM/YY hh:mm A"
        : "MM/DD/YY hh:mm A";
    return date.format(format);
  }

  static dateLabel(date: moment.Moment, context: IThreadContext): string {
    const format =
      context.settings["default_locale"]?.value === "en-au"
        ? "DD/MM/YY"
        : "MM/DD/YY";
    return date.format(format);
  }

  static relativeDateLabel(date: moment.Moment): string {
    if (!date.isValid()) {
      return "Never";
    }
    const now = moment();

    // done based on breakpoints in days defined in CRTH-1554
    const difference = moment.duration(now.diff(date));
    const days = Math.round(difference.asDays());

    if (days <= 2) {
      const today = now.startOf("day");

      if (date.isSameOrAfter(today)) {
        return "Today";
      }

      const yesterday = today.subtract(1, "day");

      if (date.isSameOrAfter(yesterday)) {
        return "Yesterday";
      }
    }

    // rest of breakpoints were meant to agree with moment
    return date.fromNow();
  }

  static studentTabLabel(studentTab: StudentTabEnum): string {
    switch (studentTab) {
      case StudentTabEnum.PROFILE:
        return "Profile";
      case StudentTabEnum.CHARTS:
        return "Charts";
      case StudentTabEnum.SESSION_NOTES:
        return "Session Notes";
      case StudentTabEnum.ABC_DATA:
        return "ABC Data";
      case StudentTabEnum.MAINTENANCE:
        return "Maintenance";
      case StudentTabEnum.ARCHIVE:
        return "Archive";
      default:
        return "";
    }
  }

  static programTabLabel(programTab: ProgramTabEnum): string {
    switch (programTab) {
      case ProgramTabEnum.PROGRAM_DETAILS:
        return "Program details";
      case ProgramTabEnum.UNIT_DETAILS:
        return "Unit details";
      case ProgramTabEnum.TARGETS:
        return "Targets";
      case ProgramTabEnum.HISTORICAL_TARGETS:
        return "Historical Targets";
      case ProgramTabEnum.PHASE_DETAILS:
        return "Phase details";
      default:
        return "";
    }
  }

  static reinforcementScheduleLabel(
    reinforcementSchedule: ReinforcementScheduleEnum
  ): string {
    switch (reinforcementSchedule) {
      case ReinforcementScheduleEnum.FIXED_RATIO:
        return "Fixed Ratio (FR)";

      case ReinforcementScheduleEnum.VARIABLE_RATIO:
        return "Variable Ratio (VR)";

      case ReinforcementScheduleEnum.FIXED_INTERVAL:
        return "Fixed Interval (FI)";

      case ReinforcementScheduleEnum.VARIABLE_INTERVAL:
        return "Variable Interval (VI)";

      case ReinforcementScheduleEnum.CONTINUOUS:
        return "Continuous";

      default:
        return "";
    }
  }

  static phaseSettingLabel(setting: PhaseSettingEnum): string {
    switch (setting) {
      case PhaseSettingEnum.NET:
        return "NET";

      case PhaseSettingEnum.DTT:
        return "DTT";

      case PhaseSettingEnum.INDIVIDUAL:
        return "Individual";

      case PhaseSettingEnum.PEER:
        return "With a peer";

      case PhaseSettingEnum.SMALL_GROUP:
        return "Small group (3-5 peers)";

      case PhaseSettingEnum.LARGE_GROUP:
        return "Large group (6+ peers)";

      case PhaseSettingEnum.COMMUNITY_PARK:
        return "Community Park/Playground";

      case PhaseSettingEnum.COMMUNITY_STORE:
        return "Community Store/Restaurant/Activity";

      case PhaseSettingEnum.ST:
        return "ST";

      case PhaseSettingEnum.DT:
        return "DT";

      case PhaseSettingEnum.HOME:
        return "Home";

      default:
        return "";
    }
  }

  static reinforcementScheduleShortLabel(
    reinforcementSchedule: ReinforcementScheduleEnum
  ): string {
    switch (reinforcementSchedule) {
      case ReinforcementScheduleEnum.FIXED_RATIO:
        return "FR";

      case ReinforcementScheduleEnum.VARIABLE_RATIO:
        return "VR";

      case ReinforcementScheduleEnum.FIXED_INTERVAL:
        return "FI";

      case ReinforcementScheduleEnum.VARIABLE_INTERVAL:
        return "VI";

      case ReinforcementScheduleEnum.CONTINUOUS:
        return "C";

      default:
        return "";
    }
  }

  static archiveTypeLabel(type: ArchiveTypeEnum) {
    switch (type) {
      case ArchiveTypeEnum.DISCONTINUED:
        return "Discontinued";

      case ArchiveTypeEnum.MASTERED:
        return "Mastered";

      case ArchiveTypeEnum.ON_HOLD:
        return "On-Hold";

      default:
        return "";
    }
  }

  static lockedTypeLabel(type: LockedTypeEnum) {
    switch (type) {
      case LockedTypeEnum.CRITERION_MET:
        return "Criterion Met";

      case LockedTypeEnum.MINIMAL_PROGRESS:
        return "Minimal Progress";

      default:
        return "";
    }
  }

  static resultLabel(result: TrialResultEnum) {
    switch (result) {
      case TrialResultEnum.MINUS:
        return "-";

      case TrialResultEnum.PLUS:
        return "+";

      case TrialResultEnum.NOT_APPLICABLE:
        return "N/A";

      default:
        return "";
    }
  }

  static programTypeLabel(type: ProgramTypeEnum) {
    switch (type) {
      case ProgramTypeEnum.DTT:
        return "Discrete Trial";

      case ProgramTypeEnum.TASK_ANALYSIS:
        return "Task Analysis";

      case ProgramTypeEnum.DURATION:
        return "Duration";

      case ProgramTypeEnum.FREQUENCY:
        return "Frequency";

      case ProgramTypeEnum.INTERVAL:
        return "Interval";

      default:
        return "";
    }
  }

  static placeOfServiceLabel(place: PlaceOfServiceEnum) {
    switch (place) {
      case PlaceOfServiceEnum.HOME:
        return "Home";

      case PlaceOfServiceEnum.OFFICE:
        return "Office";

      case PlaceOfServiceEnum.SCHOOL:
        return "School";

      case PlaceOfServiceEnum.TELEHEALTH:
        return "Telehealth";

      default:
        return "";
    }
  }

  static clinicalFileAreaLabel(area: ClinicalFileTypeEnum) {
    switch (area) {
      case ClinicalFileTypeEnum.INDIVIDUALIZED_EDUCATION_PLAN:
        return "IEP: Individualized Education Plans";

      case ClinicalFileTypeEnum.BEHAVIOR_INTERVENTION_PLAN:
        return "BIP: Behavior Intervention Plans";

      case ClinicalFileTypeEnum.THREAD_REPORTS:
        return "Thread Reports";

      case ClinicalFileTypeEnum.OTHER:
        return "Other";

      default:
        return "";
    }
  }

  static organizationTypeLabel(type: OrganizationTypeEnum) {
    switch (type) {
      case OrganizationTypeEnum.SCHOOL:
        return "School";

      case OrganizationTypeEnum.CLINIC:
        return "Therapy Clinic";

      case OrganizationTypeEnum.IN_HOME:
        return "In-Home";

      case OrganizationTypeEnum.RESIDENTIAL:
        return "Residential";

      case OrganizationTypeEnum.OTHER:
        return "Other";

      case OrganizationTypeEnum.FREE:
        return "Free";

      case OrganizationTypeEnum.DEMO:
        return "Demo";

      default:
        return "";
    }
  }

  static totalStudentsLabel(totalStudents: number) {
    if (totalStudents === 0) {
      return "";
    }
    if (totalStudents < 10) {
      return "1 to 9 Students";
    }
    if (totalStudents < 30) {
      return "10 to 29 Students";
    }
    if (totalStudents < 100) {
      return "30 to 99 Students";
    }
    if (totalStudents < 300) {
      return "100 to 299 Students";
    }
    return "300+ Students";
  }

  static totalLocationsLabel(totalLocations: number) {
    if (totalLocations === 0) {
      return "";
    }
    if (totalLocations === 1) {
      return "Single";
    }
    return "Multiple";
  }

  static countryLabel(country: string | null) {
    if (!country) {
      return "";
    }
    const pos = country.indexOf("-");
    if (pos !== -1) {
      country = country.substring(0, pos);
    }
    if (CountryData[country]) {
      return CountryData[country].name;
    }
    return "";
  }

  static stateLabel(state: string | null) {
    if (!state) {
      return "";
    }
    const parts = state.split("-");
    if (
      parts.length !== 2 ||
      !CountryData[parts[0]] ||
      !CountryData[parts[0]].divisions[state]
    ) {
      return "";
    }
    return CountryData[parts[0]].divisions[state];
  }

  static dataPointTypeLabel(type: DataPointTypeEnum) {
    switch (type) {
      case DataPointTypeEnum.POSTTEST:
        return "Posttest";
      case DataPointTypeEnum.PRETEST:
        return "Pretest";
      case DataPointTypeEnum.PROBE:
        return "Probe";
      case DataPointTypeEnum.STANDARD:
        return "Standard";
      default:
        return "";
    }
  }

  static sessionNoteStatusLabel(status: SessionNoteStatusEnum) {
    switch (status) {
      case SessionNoteStatusEnum.NEEDS_AUTHORIZED:
        return "Requires Detail";
      case SessionNoteStatusEnum.NEEDS_DETAIL:
        return "Requires Detail";
      case SessionNoteStatusEnum.NEEDS_SIGNATURE:
        return "Needs Signature";
      case SessionNoteStatusEnum.SIGNED:
        return "Signed";
      case SessionNoteStatusEnum.SUBMITTED:
        return "Submitted";
      default:
        return "";
    }
  }

  static phaseAverageLabel(type: ProgramTypeEnum) {
    switch (type) {
      case ProgramTypeEnum.DTT:
      case ProgramTypeEnum.INTERVAL:
      case ProgramTypeEnum.TASK_ANALYSIS:
        return "This phase average score";
      case ProgramTypeEnum.FREQUENCY:
        return "This phase average count";
      case ProgramTypeEnum.DURATION:
        return "This phase average time";
      default:
        return "";
    }
  }

  static routePathLabel(key: RoutePathEnum) {
    switch (key) {
      case RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS:
        return "Plan a Session";
      case RoutePathEnum.MY_STUDENT_LIST:
        return "My Student Data";
      case RoutePathEnum.CURRICULUM:
        return "Curriculum Library";
      case RoutePathEnum.PEOPLE_MANAGEMENT:
        return "Contacts";
      case RoutePathEnum.REPORT:
        return "Reports";
      case RoutePathEnum.REPORT_ABC_DATA:
        return "ABC Data";
      case RoutePathEnum.REPORT_BILLING:
        return "Billing";
      case RoutePathEnum.REPORT_PROGRESS:
        return "Progress Report";
      case RoutePathEnum.REPORT_RAW_DATA:
        return "Raw Data";
      case RoutePathEnum.REPORT_SESSION_NOTE:
        return "Session Note Report";
      case RoutePathEnum.SESSION_NOTES_LIST_NOTES:
        return "Session Notes";
      case RoutePathEnum.NOTIFICATIONS:
        return "Clinical Alerts";
      case RoutePathEnum.REPORT_FINANCE:
        return "Run Finance Report";
      case RoutePathEnum.REPORT_FINANCE_DETAILED:
        return "Run Staff/Students Billed Report";
      case RoutePathEnum.ABC_DATA:
        return "ABC Data";
      case RoutePathEnum.CHARTS:
        return "Charts";
      case RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM:
        return "Edit Program";
      case RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM:
        return "Create Program";
      case RoutePathEnum.SYSOP:
        return "SysOp";
      case RoutePathEnum.ORGANIZATION_CREATE:
        return "New Organization";
    }
  }

  static intervalUnitsLabel(key: IntervalUnitsEnum) {
    switch (key) {
      case IntervalUnitsEnum.MINUTES:
        return "Minutes";
      case IntervalUnitsEnum.SECONDS:
        return "Seconds";
      default:
        return "";
    }
  }

  static intervalTypeLabel(key: IntervalTypeEnum) {
    switch (key) {
      case IntervalTypeEnum.FULL_INTERVAL:
        return "Full Interval";
      case IntervalTypeEnum.PARTIAL_INTERVAL:
        return "Partial Interval";
      case IntervalTypeEnum.MOMENTARY_TIME_SAMPLING:
        return "Momentary Time Sampling";
      default:
        return "";
    }
  }

  static promptingStrategyLabel(key: PromptingStrategyEnum) {
    switch (key) {
      case PromptingStrategyEnum.PHASE:
        return "Set at phase";
      case PromptingStrategyEnum.TRIAL:
        return "Set at each trial";
      default:
        return "";
    }
  }

  static staffRoleLabel(key: StaffRoleEnum) {
    switch (key) {
      case StaffRoleEnum.ADMIN:
        return "Administrator";
      case StaffRoleEnum.INSTRUCTOR:
        return "Instructor";
      case StaffRoleEnum.SUPERVISOR:
        return "Supervisor";
      default:
        return "";
    }
  }
}
