import React from "react";
import { useHistory } from "react-router-dom";
import { AbcDataForm } from "./AbcDataForm";
import { useAbcDataCreate } from "../DataAccess/AbcDataData";
import { findPermission } from "../Shared/RolesMap";
import { useProgramSessionsQuery } from "../DataAccess/ProgramData";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { ApolloError } from "@apollo/client";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ABCDataInput, Permissions } from "Shared/Api/globalTypes";
import { RouterHelper } from "Routes/RouterHelper";
interface IAddAbcDataProps {
  studentId: string;
  dataId?: string;
  programId?: string;
  onError: (error: Error) => void;
  onSuccess: (title: string, description: string) => void;
}

export const AddAbcData = (props: IAddAbcDataProps) => {
  const { threadUserContext } = useThreadContext();

  const canCreateAbcData = findPermission(
    threadUserContext!.role,
    Permissions.ADD_ABC_DATA
  );
  const history = useHistory();
  const { abcDataCreate } = useAbcDataCreate();

  const { error: sessionError, data: sessionData } = useProgramSessionsQuery(
    props.studentId
  );

  if (!canCreateAbcData) {
    RouterHelper.redirectToDefault(history);
  }

  const createAbcData = (input: ABCDataInput) => {
    abcDataCreate(props.studentId, input)
      .then(() => {
        props.onSuccess("ABC Data Added", "ABC Data successfully added.");
      })
      .catch((error: ApolloError) => {
        props.onError(error);
      });
  };

  let defaultBehaviorText = "";
  if (props.programId) {
    if (sessionData) {
      const session = sessionData.programSessions.find(
        s => s.program.id === props.programId
      );
      const program = session?.program;
      defaultBehaviorText = program?.name ? program.name : "";
    } else if (sessionError) {
      props.onError(sessionError);
    } else {
      return <LoadingScreen></LoadingScreen>;
    }
  }

  return (
    <>
      <AbcDataForm
        canEdit={true}
        studentId={props.studentId}
        programId={props.programId}
        defaultBehaviorText={defaultBehaviorText}
        onSave={createAbcData}
      />
    </>
  );
};
