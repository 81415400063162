import {
  Col,
  Grid,
  Heading,
  Input,
  Row,
  InputPhoneNumber,
  InputEmail
} from "@raudabaugh/thread-ui";
import React, { useEffect } from "react";
import { validateEmail } from "../../Shared/ValidateEmail";
import { validatePhoneNumber } from "../../Shared/ValidatePhoneNumber";

interface IEditStaffBasicInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  onChangeText: (
    field: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onValidation?: (valid: boolean) => void;
  isCreate?: boolean;
  phoneRequired?: boolean;
}

export const EditStaffBasicInfo = (props: IEditStaffBasicInfoProps) => {
  useEffect(() => {
    if (props.onValidation) {
      const valid =
        (!validatePhoneNumber(props.phoneNumber) || props.phoneNumber === "") &&
        (!props.isCreate || !validateEmail(props.email));
      props.onValidation(valid);
    }
  }, [props]);
  return (
    <Grid>
      <Row margin="0px 0px 32px 0px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              First Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id="staffFirstNameInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("firstName", value)
            }
            placeholder=""
            value={props.firstName ? props.firstName : ""}
          />
        </Col>
      </Row>
      <Row margin="32px 0px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              Last Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id="staffLastNameInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("lastName", value)
            }
            placeholder=""
            value={props.lastName ? props.lastName : ""}
          />
        </Col>
      </Row>
      {props.isCreate && (
        <Row margin="32px 0px">
          <Col>
            <Row type="flex" margin="0">
              <Heading level={5} margin="0px" color="error">
                * &nbsp;
              </Heading>
              <Heading level={5} margin="0px" color="default" variation={7}>
                Email
              </Heading>
            </Row>
          </Col>
          <Col>
            <InputEmail
              id="staffEmailInput"
              size="large"
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                props.onChangeText("email", value)
              }
              placeholder=""
              value={props.email ? props.email : ""}
            />
            {validateEmail(props.email!) && (
              <Heading color="error" level={6}>
                Please Enter Valid Email
              </Heading>
            )}
          </Col>
        </Row>
      )}
      <Row margin="28px 0px">
        <Col>
          <Row type="flex" margin="0">
            {!!props.phoneRequired && (
              <Heading level={5} margin="0px" color="error">
                * &nbsp;
              </Heading>
            )}
            <Heading level={5} margin="0px" color="default" variation={7}>
              Mobile Number
            </Heading>
          </Row>
          <InputPhoneNumber
            id="staffPhoneNumberInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("phoneNumber", value)
            }
            placeholder=""
            value={props.phoneNumber ? props.phoneNumber : ""}
          />
          {validatePhoneNumber(props.phoneNumber!) && props.phoneNumber !== "" && (
            <Heading color="error" level={6}>
              Please Enter Valid Phone Number
            </Heading>
          )}
        </Col>
      </Row>
    </Grid>
  );
};
