import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Grid,
  Heading,
  Modal,
  Menu,
  MenuItem,
  Tag,
  IClickParam
} from "@raudabaugh/thread-ui";
import { useState } from "react";
import { IPrompt, PromptHelper } from "Shared/PromptHelper";
import { IDiscreteTarget } from "../../StudentPrograms/Types";

interface IDiscreteTargetRowProps {
  target: IDiscreteTarget;
  errorless: boolean;
  index: number;
  onLeftClick: (targetId: string, targetDescription: string) => void;
  onRightClick: (targetId: string, targetDescription: string) => void;
  onCenterClick: (
    targetId: string,
    targetDescription: string,
    prompt?: string
  ) => void;
  onChangePromptType?: (x: string) => void;
  leftLabel: JSX.Element;
  rightLabel: JSX.Element;
  disabled: boolean;
  centerLabel?: JSX.Element;
  promptLevels?: IPrompt[];
  prompt?: string;
}

export const DiscreteTargetRow = (props: IDiscreteTargetRowProps) => {
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [independentTarget, setIndependentTarget] = useState<string>();

  const renderCenterButton = (
    index: number,
    targetId: string,
    targetDescription: string
  ) => {
    if (props.errorless) {
      return (
        <Button
          id={`${index}CenterButton`}
          disabled={props.disabled}
          type="primary"
          size="x-large"
          onClick={() => handleCenterButtonClick(targetId, targetDescription)}
        >
          {props.centerLabel}
        </Button>
      );
    } else {
      return null;
    }
  };

  const handlePromptSelect = (prompt: IClickParam) => {
    setPromptModalOpen(false);
    props.onChangePromptType?.(prompt.key);
    if (independentTarget) {
      props.onCenterClick?.(independentTarget, prompt.key);
      setIndependentTarget(undefined);
    }
  };

  const independentCenterClick = (targetId: string) => {
    setPromptModalOpen(true);
    setIndependentTarget(targetId);
  };

  const handleCenterButtonClick = (
    targetId: string,
    targetDescription: string
  ) => {
    props.prompt === PromptHelper.PROMPT_INDEPENDENT
      ? independentCenterClick(targetId)
      : props.onCenterClick(targetId, targetDescription, props.prompt);
  };

  return (
    <Grid key={props.index}>
      {props.errorless && (
        <Modal
          width={350}
          footer={null}
          title="Choose a prompt level."
          visible={promptModalOpen}
          onCancel={() => setPromptModalOpen(false)}
        >
          <Menu onClick={handlePromptSelect}>
            {props.promptLevels!.map((prompt: IPrompt) => (
              <MenuItem key={prompt.name}>
                <Row type="flex">
                  <Col>
                    <Grid width="56px">
                      <Tag color="primary">{prompt.label}</Tag>
                    </Grid>
                  </Col>
                  <Col>{prompt.name}</Col>
                </Row>
              </MenuItem>
            ))}
          </Menu>
        </Modal>
      )}
      <Row type="flex" margin={"0 10px 20px 10px"}>
        <Col xs={13} sm={16} md={18} lg={20} xl={20} xxl={20}>
          <Heading level={5} color="default">
            {props.target.description}
          </Heading>
          <Heading color="default" variation={1} level={6}>
            {props.target.correctCount}/{props.target.applicableCount}
          </Heading>
        </Col>
        <Col xs={11} sm={8} md={6} lg={4} xl={4} xxl={4}>
          <ButtonGroup block={true}>
            <Button
              id={props.index + "_LeftButton"}
              disabled={props.disabled}
              type="primary"
              size="x-large"
              onClick={() =>
                props.onLeftClick(props.target.id, props.target.description)
              }
            >
              {props.leftLabel}
            </Button>
            {renderCenterButton(
              props.index,
              props.target.id,
              props.target.description
            )}
            <Button
              id={props.index + "_RightButton"}
              disabled={props.disabled}
              type="primary"
              size="x-large"
              onClick={() =>
                props.onRightClick(props.target.id, props.target.description)
              }
            >
              {props.rightLabel}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Grid>
  );
};
