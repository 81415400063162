import {
  Button,
  Col,
  Dropdown,
  Heading,
  Icon,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tag
} from "@raudabaugh/thread-ui";
import _ from "lodash";
import { useMemo } from "react";

export interface IMultiValueListProps {
  settingId: string;
  values: { id: string; numberofPoints: string; percentage: string }[];
  addSettingValue: (settingId: string, value: string) => void;
  editSettingValue: (settingId: string, value: string) => void;
  removeSettingValue: (settingId: string, value: string) => void;
}
export interface IMultiValueListItemProps {
  settingId: string;
  numberOfPoints: string;
  percentage: string;
  addSettingValue: (settingId: string, value: string) => void;
  editSettingValue: (settingId: string, value: string) => void;
  removeSettingValue: (settingId: string, value: string) => void;
}

export const MultiValueList = (props: IMultiValueListProps) => {
  return (
    <>
      <List>
        {props.values
          .sort((a, b) => Number(a.numberofPoints) - Number(b.numberofPoints))
          .map(item => (
            <MultiValueListItem
              key={item.id}
              settingId={props.settingId}
              numberOfPoints={item.numberofPoints}
              percentage={item.percentage}
              addSettingValue={props.addSettingValue}
              editSettingValue={props.editSettingValue}
              removeSettingValue={props.removeSettingValue}
            />
          ))}
        <ListItem>
          <Button
            ghost
            type="primary"
            onClick={e => props.addSettingValue(props.settingId, "0.00-0")}
          >
            + Additional criterion for mastery
          </Button>
        </ListItem>
      </List>
    </>
  );
};

const MultiValueListItem = (props: IMultiValueListItemProps) => {
  const handleEdit = (type: string, oldValue: string, newValue: string) => {
    if (type === "point") {
      const id = `${props.settingId}.${props.percentage}-${oldValue}`;
      props.editSettingValue(id, `${props.percentage}-${newValue}`);
    } else if (type === "percent") {
      const id = `${props.settingId}.${oldValue}-${props.numberOfPoints}`;
      const percentValue = (_.toNumber(newValue) / 100).toFixed(2);
      props.editSettingValue(id, `${percentValue}-${props.numberOfPoints}`);
    }
  };

  const analyzedItems = useMemo(() => {
    const items: number[] = [];
    for (let i = 1; i <= 25; i++) {
      items.push(i);
    }
    return items.map((value: number) => {
      return <MenuItem key={`${value}`}>{`${value}`}</MenuItem>;
    });
  }, []);

  const pointsMenu = (
    <Menu
      onClick={event => handleEdit("point", props.numberOfPoints, event.key)}
    >
      {analyzedItems}
    </Menu>
  );

  const percentageItems = useMemo(() => {
    const items: number[] = [];
    for (let i = 100; i >= 50; i = i - 5) {
      items.push(i);
    }
    return items.map((value: number) => {
      return <MenuItem key={`${value}`}>{`${value}`}</MenuItem>;
    });
  }, []);

  const percentageMenu = (
    <Menu onClick={event => handleEdit("percent", props.percentage, event.key)}>
      {percentageItems}
    </Menu>
  );

  return (
    <ListItem>
      <Col>
        <Dropdown trigger={["click"]} overlay={pointsMenu}>
          <Tag>{props.numberOfPoints}</Tag>
        </Dropdown>
      </Col>
      <Heading level={6} margin="0">
        {Number(props.numberOfPoints) === 1 ? "score of" : "scores of"}
      </Heading>
      <Col padding={"0 0 0 6px"} grow={1}>
        <Dropdown trigger={["click"]} overlay={percentageMenu}>
          <Tag>{(Number(props.percentage) * 100).toFixed(0)}</Tag>
        </Dropdown>
        % or more
      </Col>
      <Heading weight="bold" margin="0 6px 0 0" level={5}>
        <Icon
          type="fa-trash fas"
          onClick={() =>
            props.removeSettingValue(
              props.settingId,
              `${props.percentage}-${props.numberOfPoints}`
            )
          }
        />
      </Heading>
    </ListItem>
  );
};
