import {
  Button,
  Card,
  Col,
  Drawer,
  Grid,
  Heading,
  Icon,
  Row,
  Signature,
  TabPane,
  Tabs,
  Text,
  Switch,
  Notification
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import {
  useSessionNoteSignMutation,
  useSessionNoteSignAllMutation,
  useActiveSessionNotesQuery,
  useSessionNotesQuery
} from "../DataAccess/SessionNoteData";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { ActiveNotes } from "./ActiveNotes";
import { SubmittedNotes } from "./SubmittedNotes";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { SessionNoteFragment } from "Shared/Api/SessionNoteFragment";
import {
  SessionNoteStatusEnum,
  SessionNoteStatusFilter,
  Permissions
} from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { StudentTabEnum } from "Shared/StudentTabEnum";

interface ISessionNotesBaseProps {
  studentId: string;
  onCreateNote: () => void;
  onEditNote: (id: string) => void;
  onViewNote: (id: string) => void;
}

export enum SectionEnum {
  SUBMITTED_NOTES = "submitted notes",
  SESSION_NOTES = "session notes"
}

export const SessionNotes = (props: ISessionNotesBaseProps) => {
  const studentId = props.studentId;
  const {
    threadUserContext: { scrollIntoView, role, userId, assignedStudents }
  } = useThreadContext();
  const { sessionNoteSign } = useSessionNoteSignMutation();
  const { sessionNoteSignAll } = useSessionNoteSignAllMutation();

  const [loading, setLoading] = useState(false);
  const [selectedSessionNote, setSelectedSessionNote] =
    useState<SessionNoteFragment>();
  const [showSignatureDrawer, setShowSignatureDrawer] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [needsSignatureCount, setNeedsSignatureCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);

  const handleNoteSelect = (note: SessionNoteFragment) => {
    const canEdit =
      note.createdById === userId ||
      findPermission(role, Permissions.EDIT_ALL_NOTES);
    if (
      canEdit &&
      (note.status === SessionNoteStatusEnum.NEEDS_DETAIL ||
        note.status === SessionNoteStatusEnum.NEEDS_AUTHORIZED)
    ) {
      props.onEditNote(note.id);
    } else {
      props.onViewNote(note.id);
    }
  };
  const openSignatureDrawer = () => {
    setShowSignatureDrawer(true);
  };
  const closeSignatureDrawer = () => {
    setShowSignatureDrawer(false);
  };
  const handleBulkSign = () => {
    setSelectedSessionNote(undefined);
    openSignatureDrawer();
  };
  const online = useOnlineStatus();
  const availableOffline =
    assignedStudents.filter(
      s => s.studentId === studentId && s.availableOffline
    ).length > 0;

  const handleSignatureSave = useCallback(
    (signature: string, isImage: boolean, isBlank: boolean) => {
      if (isBlank) {
        NotificationsHelper.StandardErrorNotification({
          title: "Signature Required",
          description: "Signature must be filled in"
        });
        return;
      }

      setLoading(true);
      closeSignatureDrawer();
      if (selectedSessionNote && selectedSessionNote.id) {
        sessionNoteSign(selectedSessionNote.id, studentId, signature)
          .then(() =>
            Notification.success({
              duration: 0,
              message: "Note Signed!",
              description:
                "This note has been successfully submitted for billing."
            })
          )
          .catch((error: ApolloError) =>
            NotificationsHelper.ErrorNotification({
              error: error,
              title: "Action Failed"
            })
          )
          .then(() => setLoading(false));
      } else {
        sessionNoteSignAll(studentId, signature)
          .then(() =>
            Notification.success({
              duration: 0,
              message: "Note Signed!",
              description:
                "This note has been successfully submitted for billing."
            })
          )
          .catch((error: ApolloError) =>
            NotificationsHelper.ErrorNotification({
              error: error,
              title: "Action Failed"
            })
          )
          .then(() => setLoading(false));
      }
    },
    [studentId, selectedSessionNote, sessionNoteSign, sessionNoteSignAll]
  );

  const {
    loading: activeLoading,
    error: activeError,
    data: activeData,
    refetch: activeRefetch
  } = useActiveSessionNotesQuery(studentId, {
    skip: !(online || availableOffline)
  });

  const {
    loading: notesLoading,
    error: notesError,
    data: notesData,
    refetch: notesRefetch
  } = useSessionNotesQuery(
    {
      studentId: studentId,
      statusFilter: SessionNoteStatusFilter.SUBMITTED_ONLY,
      first: 10000
    },
    {
      skip: !(online || availableOffline)
    }
  );

  useEffect(() => {
    if (online) {
      activeRefetch();
    }
  }, [activeRefetch, online]);

  useEffect(() => {
    if (online) {
      notesRefetch();
    }
  }, [notesRefetch, online]);

  useEffect(() => {
    scrollIntoView();
  }, [scrollIntoView]);

  useEffect(() => {
    if (activeError || notesError) {
      NotificationsHelper.ErrorNotification({
        error: activeError || notesError,
        title: "Connection Error"
      });
    }
  }, [activeError, notesError]);

  useEffect(() => {
    let isCanceled = false;

    if (!isCanceled) {
      if (activeData) {
        setNeedsSignatureCount(
          activeData.sessionNotesActive.filter(
            sessionNote =>
              sessionNote.status === SessionNoteStatusEnum.NEEDS_SIGNATURE &&
              (showOther || sessionNote.createdById === userId)
          ).length
        );

        setActiveCount(
          activeData.sessionNotesActive.filter(
            sessionNote => showOther || sessionNote.createdById === userId
          ).length
        );
      }
    }
    return () => {
      isCanceled = true;
    };
  }, [activeData, userId, showOther]);

  return (
    <>
      <LoadingScreen loading={loading || activeLoading} />
      <StudentDataTabs
        studentId={props.studentId}
        activeTab={StudentTabEnum.SESSION_NOTES}
      >
        <Grid>
          <Card margin="0" padding="0">
            <Row type="flex" padding="16px">
              <Col margin="0 16px 0 0" grow={1}>
                <Button
                  block={true}
                  type="primary"
                  disabled={showOther || needsSignatureCount < 1}
                  onClick={handleBulkSign}
                >
                  <Row type="flex">
                    <Col>
                      <Icon type="fa-signature fas" />
                    </Col>
                    <Col grow={1}>Bulk Sign</Col>
                  </Row>
                </Button>
              </Col>
              <Col grow={1}>
                <Button block={true} onClick={props.onCreateNote}>
                  <Row type="flex">
                    <Col>
                      <Icon type="fa-plus fas" />
                    </Col>
                    <Col grow={1}>Create Note</Col>
                  </Row>
                </Button>
              </Col>
            </Row>
            <Row type="flex" padding="0 16px">
              <Col>
                <Switch
                  checked={showOther}
                  onChange={() => setShowOther(!showOther)}
                />
              </Col>
              <Col margin="0 8px">Include notes by other staff</Col>
            </Row>
          </Card>
          <Row color="default" variation={1} margin="16px 0 0 0">
            <Tabs>
              <TabPane tab={"Active Notes (" + activeCount + ")"} key="1">
                <ActiveNotes
                  currentStudentId={studentId}
                  showOther={showOther}
                  onSessionNoteSelect={handleNoteSelect}
                  loading={activeLoading}
                  data={activeData}
                />
              </TabPane>
              <TabPane tab="Submitted Notes" key="2">
                <SubmittedNotes
                  currentStudentId={studentId}
                  showOther={showOther}
                  onSessionNoteSelect={handleNoteSelect}
                  loading={notesLoading}
                  data={notesData}
                />
              </TabPane>
            </Tabs>
          </Row>
          <Drawer
            destroyOnClose={true}
            mask={false}
            height="350px"
            placement="bottom"
            visible={showSignatureDrawer}
            closable={false}
          >
            <Row grow={1} type="flex" justify="space-between">
              <Col margin="4px 12px">
                <Heading level={5}>Parent Signature</Heading>
              </Col>
              <Col margin="4px 12px">
                <Icon
                  type="fa-caret-square-down fas"
                  theme="outlined"
                  onClick={closeSignatureDrawer}
                />
              </Col>
            </Row>
            <Row>
              <Col margin="4px 18px">
                <Text>
                  Provide parent signature to submit outstanding session note(s)
                  for billing…
                </Text>
              </Col>
            </Row>
            <Signature onSave={handleSignatureSave} />
          </Drawer>
        </Grid>
      </StudentDataTabs>
    </>
  );
};
