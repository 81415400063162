import { TrialResultEnum, ProgramTypeEnum } from "./Api/globalTypes";
import { IntervalHelper } from "./IntervalHelper";
import moment from "moment";
import { DataPointFragment, DataPointFragment_trials } from "./Api/DataPointFragment";
import { PhaseFragment } from "./Api/PhaseFragment";
import { ProgramFragment_targets } from "./Api/ProgramFragment";

interface ITargetDataItemized {
  targetId: string,
  targetDescription: string | null | undefined,
  plus: number,
  total: number,
  order: number
}
export class DataPointHelper {
  static durationTotal(
    programType: ProgramTypeEnum,
    point: DataPointFragment,
    phase: PhaseFragment
  ) {
    if (programType === ProgramTypeEnum.DURATION) {
      return (
        (point.durationOverride ?? 0) +
        (point.trials
          ? point.trials.reduce(
              (sum, current) => sum + (current.duration ?? 0),
              0
            )
          : 0)
      );
    }
    return 0;
  }

  static correctTrialCount(
    programType: ProgramTypeEnum,
    point: DataPointFragment,
    phase: PhaseFragment
  ) {
    if (
      programType === ProgramTypeEnum.INTERVAL &&
      phase.lengthOfEachInterval
    ) {
      const elapsedTrials = IntervalHelper.getTrialsElapsed(
        moment(point.startedAt),
        phase.lengthOfEachInterval,
        phase.numberOfTrials ?? 1,
        point.attemptedOverride
      );
      return IntervalHelper.getTotalCorrect(
        point.method,
        point.attemptedOverride,
        point.correctOverride,
        phase.defaultTrialResult ?? TrialResultEnum.NOT_APPLICABLE,
        point.trials,
        elapsedTrials
      );
    }
    return (
      point.correctOverride ??
      point.trials.filter(trial => trial.result === TrialResultEnum.PLUS).length
    );
  }

  static applicableTrialCount(
    programType: ProgramTypeEnum,
    point: DataPointFragment,
    phase: PhaseFragment
  ) {
    if (
      programType === ProgramTypeEnum.INTERVAL &&
      phase.lengthOfEachInterval
    ) {
      const elapsedTrials = IntervalHelper.getTrialsElapsed(
        moment(point.startedAt),
        phase.lengthOfEachInterval,
        phase.numberOfTrials ?? 1,
        point.attemptedOverride
      );
      return IntervalHelper.getTotalAttempted(
        point.method,
        point.attemptedOverride,
        phase.defaultTrialResult ?? TrialResultEnum.NOT_APPLICABLE,
        point.trials,
        elapsedTrials
      );
    }
    return (
      point.attemptedOverride ??
      point.trials.filter(
        trial =>
          trial.result !== TrialResultEnum.NOT_APPLICABLE &&
          trial.result !== TrialResultEnum.NONE
      ).length
    );
  }

  static attemptedTrialCount(point: DataPointFragment) {
    return (
      point.attemptedOverride ??
      point.trials.filter(trial => trial.result !== TrialResultEnum.NONE).length
    );
  }

  static pad(value: number, digits: number) {
    const rounded = Math.floor(value).toString();
    if (rounded.length > digits) {
      return rounded;
    }
    const s = "00000000" + rounded;
    return s.substr(s.length - digits);
  }

  static formatDuration(duration: number) {
    const totalHours: string = this.pad(Math.floor(duration / 3600), 2);
    const totalMinutes: string = this.pad(
      Math.floor((duration % 3600) / 60),
      2
    );
    const totalSeconds: string = this.pad(duration % 60, 2);
    return totalHours === "00"
      ? `${totalMinutes}:${totalSeconds}`
      : `${totalHours}:${totalMinutes}:${totalSeconds}`;
  }

  static phaseAverage(
    type: ProgramTypeEnum,
    points: DataPointFragment[] | undefined,
    phase: PhaseFragment
  ): string {
    if (!points || points.length === 0) {
      return "Not yet run this phase";
    }
    switch (type) {
      case ProgramTypeEnum.DTT:
      case ProgramTypeEnum.TASK_ANALYSIS:
      case ProgramTypeEnum.INTERVAL:
        let correctTotal = 0;
        let attemptedTotal = 0;
        points.forEach(point => {
          correctTotal += this.correctTrialCount(type, point, phase);
          attemptedTotal += this.applicableTrialCount(type, point, phase);
        });
        const percentage = Math.round((correctTotal * 100) / attemptedTotal);
        return `${percentage}%`;

      case ProgramTypeEnum.FREQUENCY:
        let frequencyCount = 0;
        points.forEach(point => {
          frequencyCount += this.correctTrialCount(type, point, phase);
        });
        let frequencyAverage = (frequencyCount / points.length).toFixed(1);
        return `${frequencyAverage}`;

      case ProgramTypeEnum.DURATION:
        let durationTotal = 0;
        let occurrenceTotal = 0;
        points.forEach(point => {
          durationTotal += this.durationTotal(type, point, phase);
          occurrenceTotal += !!point.durationOverride ? 1 : point.trials.length;
        });
        let durationAverage = occurrenceTotal
          ? durationTotal / occurrenceTotal
          : 0;
        return this.formatDuration(durationAverage);

      default:
        throw Error("Unexpected program type");
    }
  }

  static phaseItemizedResults(
    type: ProgramTypeEnum,
    currentTargets: (ProgramFragment_targets | undefined)[],
    points: DataPointFragment[] | undefined
  ): Array<ITargetDataItemized> {
      if (!points || points.length === 0) {
        return [];
      }
      
      switch (type) {
        case ProgramTypeEnum.DTT:
        case ProgramTypeEnum.TASK_ANALYSIS:
        case ProgramTypeEnum.INTERVAL:
        case ProgramTypeEnum.FREQUENCY:
          let itemizedResults: ITargetDataItemized[] = [];
  
          currentTargets.forEach(target => {
            if (target?.id){
              let targetData = {
                targetId: target.id,
                targetDescription: target.targetDescription,
                plus: 0,
                total: 0,
                order: 0
              } as ITargetDataItemized;
              points?.forEach(point => {
                let targetTrials = point.trials.filter(trial => trial.targetId === target.id);
                targetData.plus += targetTrials.filter(trial => trial.result === TrialResultEnum.PLUS).length;
                targetData.total += targetTrials.length;
                targetData.order = Math.round((targetData.plus / targetData.total) * 1e4) / 1e4;
              })
              itemizedResults.push(targetData);
            } 
          })
      
          itemizedResults = itemizedResults.sort((a, b) => ( a.order - b.order))
          return itemizedResults;
          
        case ProgramTypeEnum.DURATION:
          return [];
        default:
          throw Error("Unexpected program type");
      }
  }

  static pointItemizedResults(
    type: ProgramTypeEnum,
    currentTargets: (ProgramFragment_targets | undefined)[],
    trials: DataPointFragment_trials[]
  ): Array<ITargetDataItemized>  {
    switch (type) {
      case ProgramTypeEnum.DTT:
      case ProgramTypeEnum.TASK_ANALYSIS:
      case ProgramTypeEnum.INTERVAL:
      case ProgramTypeEnum.FREQUENCY:
        let itemizedResults: ITargetDataItemized[] = [];

        currentTargets.forEach(target => {
          if (target?.id){
            let targetData = {
              targetId: target.id,
              targetDescription: target.targetDescription,
              plus: 0,
              total: 0,
              order: 0
            } as ITargetDataItemized;
            trials.filter(trial => trial.targetId === target.id).forEach(trial => {
              targetData.plus += trial.result === TrialResultEnum.PLUS ? 1 : 0;
              targetData.total += 1;
              targetData.order = Math.round((targetData.plus / targetData.total) * 1e4) / 1e4;
            })
            itemizedResults.push(targetData)
          } 
        })
    
        itemizedResults = itemizedResults.sort((a, b) => ( a.order - b.order))
        return itemizedResults
        
      case ProgramTypeEnum.DURATION:
        return [];
      default:
        throw Error("Unexpected program type");
    }
  }
}
