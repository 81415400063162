import {
  Media,
  Card,
  Col,
  Dropdown,
  Icon,
  Menu,
  MenuItem,
  Row,
  Link,
  IClickParam,
  Heading,
  Badge,
  Modal,
  Button,
  Image,
  Tooltip
} from "@raudabaugh/thread-ui";
import React, { useEffect, useState } from "react";
import { AuthenticationService } from "../Security/AuthenticationService";
import { getInitials } from "./Initials";
import { useHistory } from "react-router";
import { ProtectedAvatar } from "./ProtectedAvatar";
import { findPermission } from "./RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { useIntercom } from "react-use-intercom";
import { XamarinHelper, XamarinInfo } from "./XamarinHelper";
import { version } from "../Shared/Version";
import { SessionVariableEnum } from "App";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { useOfflineDetail } from "Shared/ApolloHelper";
import moment from "moment";
import iconImage from "Shared/Images/thread_icon.png";
import { RouterHelper } from "Routes/RouterHelper";
import { IRouteState } from "Routes/IRouteState";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import { LabelHelper } from "./LabelHelper";

export interface INavigationBarProps {
  onToggleMenu: () => void;
  messageCount: number;
}

export enum UserMenuEnum {
  MY_PROFILE = "MY_PROFILE",
  SIGN_OUT = "SIGN_OUT",
  ORG_PROFILE = "ORG_PROFILE",
  ORG_SETTINGS = "ORG_SETTINGS"
}

enum SupportMenuEnum {
  UPDATE_APP = "UPDATE_APP",
  CHAT = "CHAT",
  ABOUT = "ABOUT"
}

export const NavigationBar = (props: INavigationBarProps) => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { show, boot } = useIntercom();
  const [showAbout, setShowAbout] = useState(false);
  const [xamarinInfo, setXamarinInfo] = useState<XamarinInfo>();
  const [updateAvailable, setUpdateAvailable] = useState(
    sessionStorage[SessionVariableEnum.UPDATE]
  );
  const { online, lastPoll } = useOfflineDetail();
  useEffect(() => {
    if (!XamarinHelper.insideXamarin()) {
      boot({
        hideDefaultLauncher: true,
        name: threadUserContext.userName,
        userId: threadUserContext.userId,
        email: threadUserContext.userEmail,
        company: {
          companyId: threadUserContext.orgId,
          name: threadUserContext.orgName
        },
        customAttributes: {
          org_type: threadUserContext.orgType,
          role: threadUserContext.role
        }
      });
    }

    XamarinHelper.getXamarinInfo().then(info => {
      setXamarinInfo(info);
    });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(registration => {
        global.console.log("NAV: ready fired");
        registration.onupdatefound = () => {
          global.console.log("NAV: updatefound fired");
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          global.console.log("installingWorker.state", installingWorker.state);

          installingWorker.onstatechange = () => {
            global.console.log(
              "NAV: statechange fired",
              installingWorker.state
            );
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                sessionStorage[SessionVariableEnum.UPDATE] = true;
                global.console.log("NAV: update flag set");
                setUpdateAvailable(true);
              }
            }
          };
        };
      });
    }
  }, [
    boot,
    props,
    threadUserContext.orgId,
    threadUserContext.orgName,
    threadUserContext.orgType,
    threadUserContext.role,
    threadUserContext.userEmail,
    threadUserContext.userId,
    threadUserContext.userName
  ]);

  const handleAvatarSelect = (event: IClickParam) => {
    if (event!.key === UserMenuEnum.SIGN_OUT) {
      if (!online && !XamarinHelper.insideXamarin()) {
        RouterHelper.redirectToOfflinePageUnavailable(history);
      } else {
        const authService = AuthenticationService.getInstance();
        authService.logout();
      }
    } else if (event!.key === UserMenuEnum.MY_PROFILE) {
      RouterHelper.redirectToMyProfile(threadUserContext.userId, history);
    } else if (event!.key === UserMenuEnum.ORG_PROFILE) {
      RouterHelper.redirectToOrganizationViewProfile(history);
    } else {
      RouterHelper.redirectToOrganizationViewSettings(history);
    }
  };

  const handleSupportMenuSelect = (event: IClickParam) => {
    if (event.key === SupportMenuEnum.ABOUT) {
      setShowAbout(true);
    } else if (!online) {
      RouterHelper.redirectToOfflinePageUnavailable(history);
    } else if (event.key === SupportMenuEnum.UPDATE_APP) {
      updateServiceWorker();
    } else if (event.key === SupportMenuEnum.CHAT) {
      if (XamarinHelper.insideXamarin()) {
        XamarinHelper.supportChat();
      } else {
        show();
      }
    }
  };

  const handleToggleMenu = () => {
    props.onToggleMenu();
  };

  const updateServiceWorker = () => {
    global.console.log("NAV: update flag cleared");
    setUpdateAvailable(false);
    navigator.serviceWorker.ready.then(registration => {
      const registrationWaiting = registration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });

        registrationWaiting.addEventListener("statechange", e => {
          const target = e.target;
          if (target && target["state"] === "activated") {
            sessionStorage.removeItem(SessionVariableEnum.UPDATE);
            window.location.reload();
          }
        });
      }
    });
  };

  const canViewProfile = findPermission(
    threadUserContext!.role,
    Permissions.VIEW_ORG_PROFILE
  );
  const canViewSettings = findPermission(
    threadUserContext!.role,
    Permissions.VIEW_ORG_SETTINGS
  );

  const avatarMenu = (
    <Menu onClick={handleAvatarSelect}>
      <MenuItem key={UserMenuEnum.SIGN_OUT}>Sign Out</MenuItem>
      <MenuItem key={UserMenuEnum.MY_PROFILE}>My Profile</MenuItem>
      {canViewProfile && (
        <MenuItem key={UserMenuEnum.ORG_PROFILE}>Organization Profile</MenuItem>
      )}
      {canViewSettings && (
        <MenuItem key={UserMenuEnum.ORG_SETTINGS}>
          Organization Settings
        </MenuItem>
      )}
    </Menu>
  );

  const supportMenu = (
    <Menu onClick={handleSupportMenuSelect}>
      {updateAvailable && (
        <MenuItem key={SupportMenuEnum.UPDATE_APP}>
          <Heading level={6}>
            <Badge dot offset={[-8, 0]} />
            Update Thread
          </Heading>
          <Heading level={7}>A new version is available.</Heading>
        </MenuItem>
      )}
      <MenuItem key={SupportMenuEnum.CHAT}>
        <Heading level={6}>Chat with us</Heading>
      </MenuItem>
      <MenuItem key={SupportMenuEnum.ABOUT}>
        <Heading level={6}>About Thread</Heading>
      </MenuItem>
    </Menu>
  );

  const state = history.location.state as IRouteState;
  const parentLabel = state?.backLabel;

  return (
    <>
      <Modal
        title="Thread App needs updated"
        closable={false}
        visible={xamarinInfo?.version.startsWith("1.0")}
        footer={[]}
      >
        This version of the app is out-of-date. Please update to the latest
        version via the{" "}
        {xamarinInfo?.platform === "Android" ? "Play Store" : "App Store"}.
      </Modal>
      <Modal
        closable={false}
        visible={showAbout}
        footer={[
          <Button
            key="submit"
            type="primary"
            size="large"
            onClick={() => setShowAbout(false)}
          >
            OK
          </Button>
        ]}
      >
        <Row type="flex">
          <Col margin="12px">
            <Image src={iconImage} alt="Thread Logo"/>
          </Col>
        </Row>
        <Row padding="16px">
          {XamarinHelper.insideXamarin() ? (
            <>
              <Heading level={5}>Version {xamarinInfo?.version}</Heading>
              <Heading level={5}>Platform {xamarinInfo?.platform}</Heading>
              <Heading level={5}>Build {xamarinInfo?.build}</Heading>
              <Heading level={5}>Package {version}</Heading>
            </>
          ) : (
            <Heading level={5}>Version {version}</Heading>
          )}
        </Row>
      </Modal>
      <Card
        top="0"
        zIndex={10}
        width="100%"
        margin="0"
        bordered={false}
        boxShadow={"0 2px 8px 0 #c7c7c7"}
        variation={1}
        padding="4px 12px 4px 12px"
      >
        <Row gutter={12} type="flex" align="middle" alignItems="center">
          <Media.Md orSmaller={true}>
            <Col padding="12px" onClick={handleToggleMenu}>
              <Badge count={props.messageCount}>
                <Icon
                  opacity={0.65}
                  color="default"
                  variation={10}
                  size="1.6em"
                  type="fa-bars fas"
                />
              </Badge>
            </Col>
          </Media.Md>
          <Row alignItems="center" justify="end" type="flex" grow={1}>
            {state &&
              state.sideMenu !== RoutePathEnum.NONE &&
              state.sideMenu !== state.current && (
                <Media.Sm orLarger={true}>
                  <Col grow={1}>
                    <Row type="flex" padding="4px 0 0 12px">
                      <Col margin="4px 12px 0 0">
                        <Link onClick={() => history.goBack()}>
                          <Icon width="14px" type="fa-arrow-left far" />
                        </Link>
                      </Col>
                      <Col>
                        <Heading level={4}>
                          <Link onClick={() => history.goBack()}>
                            {parentLabel}
                          </Link>
                        </Heading>
                      </Col>
                    </Row>
                  </Col>
                </Media.Sm>
              )}
            {!online && (
              <>
                <Col grow={0} padding="12px 0">
                  <Tooltip title="Offline">
                    <Icon
                      color="default"
                      variation={10}
                      size="1.4em"
                      type="fa-wifi-slash fas"
                    />
                  </Tooltip>
                </Col>
                <Media.Sm orLarger={true}>
                  <Col grow={0} padding="12px">
                    <Heading level={5}>Offline</Heading>
                  </Col>
                  {lastPoll > 0 && (
                    <Col grow={0} padding="12px 12px 12px 0">
                      <Tooltip title="The sync time is based on the last time all offline student data synced to this device.">
                        <Heading level={6}>
                          Last sync{" "}
                          {LabelHelper.dateTimeLabel(
                            moment(lastPoll),
                            threadUserContext
                          )}
                        </Heading>
                      </Tooltip>
                    </Col>
                  )}
                </Media.Sm>
              </>
            )}
            <Col grow={0} padding="12px">
              <Dropdown trigger={["click"]} overlay={supportMenu}>
                <Badge dot={updateAvailable}>
                  <Icon
                    opacity={0.65}
                    color="default"
                    variation={10}
                    size="1.4em"
                    type="fa-question-circle far"
                  />
                </Badge>
              </Dropdown>
            </Col>
            <Col grow={0} className="avatarImage" margin="0px 16px">
              <Dropdown trigger={["click"]} overlay={avatarMenu}>
                <ProtectedAvatar
                  color="primary"
                  alt={threadUserContext.userName}
                  size="small"
                  src={threadUserContext.avatar}
                >
                  {getInitials(threadUserContext.userName)}
                </ProtectedAvatar>
              </Dropdown>
            </Col>
            <Col grow={0}>
              <Dropdown trigger={["click"]} overlay={avatarMenu}>
                <Heading level={5}>{threadUserContext.userName}</Heading>
              </Dropdown>
              <Dropdown trigger={["click"]} overlay={avatarMenu}>
                <Heading level={6}>{threadUserContext.orgName}</Heading>
              </Dropdown>
            </Col>
          </Row>
        </Row>
        {state &&
          state.sideMenu !== RoutePathEnum.NONE &&
          state.sideMenu !== state.current && (
            <Media.Xs>
              <Row type="flex" margin="-4px 0 8px 0">
                <Col grow={1}>
                  <Row type="flex">
                    <Col margin="4px 12px 0 0">
                      <Link onClick={() => history.goBack()}>
                        <Icon width="14px" type="fa-arrow-left far" />
                      </Link>
                    </Col>
                    <Col>
                      <Heading level={4}>
                        <Link onClick={() => history.goBack()}>
                          {parentLabel}
                        </Link>
                      </Heading>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Media.Xs>
          )}
      </Card>
    </>
  );
};
