import React, { useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { RoutePathEnum } from "./RoutePathEnum";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OfflinePageUnavailable } from "./OfflinePageUnavailable";
import { RouterHelper } from "./RouterHelper";
import { ProgramCreate } from "StudentPrograms/ProgramCreate";
import { useCurriculumQuery } from "DataAccess/CurriculumData";
import { LoadingScreen } from "Shared/LoadingScreen";

const CurriculumLibrary = React.lazy(() =>
  import(
    "../Curriculum/CurriculumLibrary" /* webpackChunkName: "curriculum-library" */
  ).then(module => ({
    default: module.CurriculumLibrary
  }))
);

const CurriculumFolder = React.lazy(() =>
  import(
    "../Curriculum/CurriculumFolder" /* webpackChunkName: "curriculum-folder" */
  ).then(module => ({
    default: module.CurriculumFolder
  }))
);

const CurriculumUpload = React.lazy(() =>
  import(
    "../Curriculum/CurriculumUpload" /* webpackChunkName: "curriculum-upload" */
  ).then(module => ({
    default: module.CurriculumUpload
  }))
);

const ProgramTemplate = React.lazy(() =>
  import(
    "../Curriculum/ProgramTemplate" /* webpackChunkName: "program-template" */
  ).then(module => ({
    default: module.ProgramTemplate
  }))
);

const AddProgramTemplate = React.lazy(() =>
  import(
    "../Curriculum/AddProgramTemplate" /* webpackChunkName: "add-program-template" */
  ).then(module => ({
    default: module.AddProgramTemplate
  }))
);

const CurriculumSearch = React.lazy(() =>
  import(
    "../Curriculum/CurriculumSearch" /* webpackChunkName: "curriculum-search" */
  ).then(module => ({
    default: module.CurriculumSearch
  }))
);

interface ICurriculumParams {
  curriculumId: string;
  folderId: string;
  templateId: string;
  studentId: string;
  programId: string;
  type: string;
  text: string;
}

const CurriculumList = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();

  const handleSelect = useCallback(
    (curriculumId: string, rootFolderId: string) => {
      RouterHelper.redirectToCurriculumViewFolder(
        curriculumId,
        rootFolderId,
        history
      );
    },
    [history]
  );

  const handleUploadCurriculum = useCallback(
    () =>
      RouterHelper.redirectToCurriculumUpload(threadUserContext.orgId, history),
    [history, threadUserContext.orgId]
  );

  return (
    <CurriculumLibrary
      onSelect={handleSelect}
      onUploadCurriculum={handleUploadCurriculum}
    />
  );
};

const CurriculumUploadView = () => {
  const { curriculumId } = useParams<ICurriculumParams>();
  const { threadUserContext } = useThreadContext();
  const history = useHistory();

  const handleComplete = useCallback(() => history.goBack(), [history]);
  const handleGoBack = useCallback(() => history.goBack(), [history]);

  if (
    (findPermission(threadUserContext.role, Permissions.UPLOAD_CURRICULUM) &&
      curriculumId === threadUserContext.orgId) ||
    curriculumId === threadUserContext.userId
  ) {
    return (
      <CurriculumUpload
        curriculumId={curriculumId}
        onComplete={handleComplete}
        onGoBack={handleGoBack}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const CurriculumRoot = () => {
  const { threadUserContext } = useThreadContext();
  const { curriculumId } = useParams<ICurriculumParams>();

  const { data } = useCurriculumQuery(curriculumId);
  const history = useHistory();
  if (!threadUserContext) return null;
  if (data?.curriculum) {
    RouterHelper.redirectToCurriculumViewFolder(
      curriculumId,
      data.curriculum.rootFolderId,
      history,
      true,
      undefined
    );
  }
  return <LoadingScreen />;
};

const CurriculumFolderView = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { curriculumId, folderId } = useParams<ICurriculumParams>();

  const handleTemplateNew = useCallback(
    (backLabel: string) => {
      RouterHelper.redirectToCurriculumCreateTemplate(
        curriculumId,
        folderId,
        backLabel,
        history
      );
    },
    [curriculumId, folderId, history]
  );

  const handleFolderSelect = useCallback(
    (id: string, backLabel: string) => {
      RouterHelper.redirectToCurriculumViewFolder(
        curriculumId,
        id,
        history,
        false,
        backLabel
      );
    },
    [curriculumId, history]
  );

  const handleTemplateSelect = useCallback(
    (id: string, backLabel: string) => {
      RouterHelper.redirectToCurriculumViewTemplate(
        curriculumId,
        id,
        backLabel,
        history
      );
    },
    [curriculumId, history]
  );

  const handleTemplateAssign = useCallback(
    (id: string, backLabel: string) => {
      RouterHelper.redirectToCurriculumAssignTemplate(
        curriculumId,
        id,
        backLabel,
        history
      );
    },
    [curriculumId, history]
  );

  const handleGoBack = useCallback(
    (curriculumId: string | null, folderId: string | null) => history.goBack(),
    [history]
  );

  if (!threadUserContext) return null;

  return (
    <CurriculumFolder
      curriculumId={curriculumId}
      folderId={folderId}
      onTemplateNew={handleTemplateNew}
      onFolderSelect={handleFolderSelect}
      onTemplateSelect={handleTemplateSelect}
      onTemplateAssign={handleTemplateAssign}
      onGoBack={handleGoBack}
    />
  );
};

const CurriculumTemplateView = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { curriculumId, templateId } = useParams<ICurriculumParams>();

  const handleGoBack = useCallback(() => history.goBack(), [history]);

  const handleAssign = useCallback(
    (backLabel: string) => {
      RouterHelper.redirectToCurriculumAssignTemplate(
        curriculumId,
        templateId,
        backLabel,
        history
      );
    },
    [curriculumId, history, templateId]
  );

  if (!threadUserContext) return null;

  return (
    <ProgramTemplate
      curriculumId={curriculumId}
      templateId={templateId}
      onGoBack={handleGoBack}
      onAssign={handleAssign}
    />
  );
};

const CurriculumTemplateCreate = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { curriculumId, folderId } = useParams<ICurriculumParams>();

  const handleGoBack = useCallback(() => history.goBack(), [history]);

  if (!threadUserContext) return null;

  if (
    (findPermission(
      threadUserContext.role,
      Permissions.ADD_CURRICULUM_TEMPLATES
    ) &&
      curriculumId === threadUserContext.orgId) ||
    curriculumId === threadUserContext.userId
  ) {
    return (
      <AddProgramTemplate
        curriculumId={curriculumId}
        parentId={folderId}
        onGoBack={handleGoBack}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const CurriculumAddProgram = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { curriculumId, programId } = useParams<ICurriculumParams>();

  const handleGoBack = useCallback(() => history.goBack(), [history]);

  if (!threadUserContext) return null;

  if (curriculumId === threadUserContext.userId) {
    return (
      <AddProgramTemplate
        curriculumId={curriculumId}
        programId={programId}
        onGoBack={handleGoBack}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const ProgramNewByType = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { curriculumId, templateId, studentId } =
    useParams<ICurriculumParams>();

  const handleClose = useCallback(
    (folderId: string | null) => history.goBack(),
    [history]
  );

  if (findPermission(threadUserContext.role, Permissions.EDIT_PROGRAMS)) {
    return (
      <ProgramCreate
        studentId={studentId}
        curriculumId={curriculumId}
        templateId={templateId}
        onClose={handleClose}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const CurriculumLibrarySearch = () => {
  const { text } = useParams<ICurriculumParams>();
  const history = useHistory();

  const handleTemplateAssign = useCallback(
    (id: string, curriculumId: string, backLabel: string) => {
      RouterHelper.redirectToCurriculumAssignTemplate(
        curriculumId,
        id,
        backLabel,
        history
      );
    },
    [history]
  );

  return (
    <CurriculumSearch text={text} onTemplateAssign={handleTemplateAssign} />
  );
};

const CurriculumFolderSearch = () => {
  const { text, curriculumId, folderId } = useParams<ICurriculumParams>();
  const history = useHistory();

  const handleTemplateAssign = useCallback(
    (id: string, curriculumId: string, backLabel: string) => {
      RouterHelper.redirectToCurriculumAssignTemplate(
        curriculumId,
        id,
        backLabel,
        history
      );
    },
    [history]
  );

  return (
    <CurriculumSearch
      text={text}
      curriculumId={curriculumId}
      folderId={folderId}
      onTemplateAssign={handleTemplateAssign}
    />
  );
};

interface CurriculumRouterProps {}

export const CurriculumRouter = (props: CurriculumRouterProps) => {
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }
  return (
    <Switch>
      <Route path={RoutePathEnum.CURRICULUM} exact={true}>
        <CurriculumList />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_UPLOAD} exact={true}>
        <CurriculumUploadView />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_VIEW_FOLDER} exact={true}>
        <CurriculumFolderView />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_VIEW_TEMPLATE} exact={true}>
        <CurriculumTemplateView />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_CREATE_TEMPLATE} exact={true}>
        <CurriculumTemplateCreate />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_ADD_PROGRAM} exact={true}>
        <CurriculumAddProgram />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_ASSIGN_TEMPLATE} exact={true}>
        <ProgramNewByType />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_LIBRARY_SEARCH} exact={true}>
        <CurriculumLibrarySearch />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_FOLDER_SEARCH} exact={true}>
        <CurriculumFolderSearch />
      </Route>
      <Route path={RoutePathEnum.CURRICULUM_DEFAULT} exact={true}>
        <CurriculumRoot />
      </Route>
    </Switch>
  );
};
