import { IIntervalButton, Modal } from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import * as moment from "moment";
import { memo, useCallback, useState } from "react";
import {
  useDataPointScoreIntervalMutation,
  useDataPointRecordIntervalMutation,
  useDataPointRestartIntervalMutation,
  useDataPointUndoIntervalMutation,
  useDataPointEndIntervalMutation
} from "../DataAccess/IntervalData";
import { TickerTrialCard } from "./TickerTrialCard";
import { TrialFragment } from "Shared/Api/TrialFragment";
import { IProgramItem } from "StudentPrograms/Types";
import { IntervalFocus } from "./Focus/IntervalFocus";
import {
  CollectionMethodEnum,
  DataPointStateEnum,
  DrawerEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import {
  useProgramDeactivateMutation,
  useProgramMoveMutation
} from "DataAccess/ProgramData";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IIntervalCardBaseProps {
  currentStudentId: string;
  count: number;
  totalTrials: number;
  trials: TrialFragment[];
  title: string;
  size: "sm" | "lg";
  runId: string;
  programId: string;
  state: DataPointStateEnum;
  sampleTime: number;
  startedAt: moment.Moment | null;
  setLoading: (loading: boolean) => void;
  index: number;
  locked: boolean;
  defaultResult: TrialResultEnum;
  attemptedOverride: number | null;
  correctOverride: number | null;
  item: IProgramItem;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onPopup: (programId: string) => void;
}

const IntervalCard = (props: IIntervalCardBaseProps) => {
  const { programDeactivate } = useProgramDeactivateMutation();
  const { programMove } = useProgramMoveMutation();
  const { dataPointRecordInterval } = useDataPointRecordIntervalMutation();
  const { dataPointRestartInterval } = useDataPointRestartIntervalMutation();
  const { dataPointUndoInterval } = useDataPointUndoIntervalMutation();
  const { dataPointEndInterval } = useDataPointEndIntervalMutation();
  const { dataPointScoreInterval } = useDataPointScoreIntervalMutation();

  const [closeWarningOpen, setCloseWarningOpen] = useState(false);
  const [endWarningOpen, setEndWarningOpen] = useState(false);
  const [focusModeOpen, setFocusModeOpen] = useState(false);

  const handleRunAgainClick = () => {
    dataPointRestartInterval(
      props.runId,
      props.currentStudentId,
      props.programId
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  };
  const handleEndClick = () => {
    props.totalTrials ? setEndWarningOpen(true) : handleEndWarningContinue();
  };
  const handleEndWarningContinue = () => {
    setEndWarningOpen(false);
    dataPointEndInterval(props.runId, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  };
  const handleEndWarningCancel = () => {
    setEndWarningOpen(false);
  };

  const handleMoveToTopClick = () => {
    programMove(props.programId, props.currentStudentId, 0).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  };

  const closeProgram = useCallback(async () => {
    try {
      await programDeactivate(props.programId, props.currentStudentId, false);
    } catch (error) {
      NotificationsHelper.ErrorNotification({
        error: error as Error,
        title: "Action Failed",
      });
    }
  }, [programDeactivate, props.programId, props.currentStudentId]);

  const handleCloseClick = useCallback(() => {
    if (props.item.drawer === DrawerEnum.PRIVATE_DRAWER) {
      const hasNoTrials = props.trials.length === 0;
      const allTrialsNoneResult =
        props.trials.length > 0 &&
        props.trials.filter(
          t => 
            t.result === TrialResultEnum.NONE || 
            t.result === TrialResultEnum.NOT_APPLICABLE
        ).length === props.trials.length;
      const isCompleted = props.state === DataPointStateEnum.COMPLETED;
      
      if(hasNoTrials || allTrialsNoneResult || isCompleted) {
        closeProgram();
      } else {
        setCloseWarningOpen(true);
      }
    } else {
      closeProgram();
    }
  }, [closeProgram, props.item.drawer, props.trials, props.state]);

  const handleCloseWarningContinue = () => {
    setCloseWarningOpen(false);
    closeProgram();
  };

  const handleCloseWarningCancel = () => {
    setCloseWarningOpen(false);
  };

  const handleButtonClick = async (button: IIntervalButton) => {
    const index = button.seconds / props.sampleTime;
    try {
      await dataPointRecordInterval(
        props.runId,
        props.currentStudentId,
        button.status as TrialResultEnum,
        index
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
  };
  const handleAbcClick = () => {
    props.onAbc(props.programId);
  };
  const handleChartClick = () => {
    props.onChart(props.programId, ProgramTypeEnum.DURATION);
  };
  const handleUndoClick = () => {
    dataPointUndoInterval(props.runId, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  };
  const handleStart = () => {
    dataPointRecordInterval(
      props.runId,
      props.currentStudentId,
      props.defaultResult,
      0
    );
  };
  const handleFocusModeClose = () => {
    setFocusModeOpen(false);
  };
  const handleFocusModeOpen = () => {
    setFocusModeOpen(true);
  };

  const handleSaveScore = (
    score: number,
    date: moment.Moment,
    attempts?: number,
    phaseId?: string
  ) => {
    dataPointScoreInterval(
      props.runId,
      phaseId ?? props.item.phaseId,
      props.currentStudentId,
      score,
      attempts!,
      date.utc().toISOString()
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  };

  return (
    <>
      <IntervalFocus
        open={focusModeOpen}
        item={props.item}
        index={props.index}
        currentStudentId={props.currentStudentId}
        completed={props.item.state === DataPointStateEnum.COMPLETED}
        onUndoClick={handleUndoClick}
        onClose={handleFocusModeClose}
        onButtonClick={handleButtonClick}
        onStart={handleStart}
      />
      <TickerTrialCard
        index={props.index}
        locked={props.locked}
        title={props.title}
        start={props.startedAt}
        interval={props.sampleTime}
        trials={props.trials}
        totalTrials={props.totalTrials}
        size={props.size}
        defaultResult={props.defaultResult}
        attemptedOverride={props.attemptedOverride}
        correctOverride={props.correctOverride}
        method={props.item.method}
        studentId={props.currentStudentId}
        programId={props.programId}
        onButtonClick={handleButtonClick}
        onAbcClick={handleAbcClick}
        onChartClick={handleChartClick}
        onUndoClick={
          props.count !== 0 && props.item.method !== CollectionMethodEnum.PAPER
            ? handleUndoClick
            : undefined
        }
        onCloseClick={handleCloseClick}
        onEndClick={handleEndClick}
        onMoveToTopClick={handleMoveToTopClick}
        onRunAgainClick={handleRunAgainClick}
        onStart={handleStart}
        onShowFocusDrawer={handleFocusModeOpen}
        onPopup={() => props.onPopup(props.programId)}
        onEnterScoreSave={handleSaveScore}
      />
      <Modal
        title="Do you want to end this program?"
        afterClose={handleEndWarningCancel}
        visible={endWarningOpen}
        onOk={handleEndWarningContinue}
        onCancel={handleEndWarningCancel}
        cancelText="No"
        okText="Yes"
      ></Modal>
      <Modal
        title="Are you sure you want to close this program?"
        afterClose={handleCloseWarningCancel}
        visible={closeWarningOpen}
        onOk={handleCloseWarningContinue}
        onCancel={handleCloseWarningCancel}
        okText="Close Program"
      >
        Your data will be deleted immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export default memo(IntervalCard);
