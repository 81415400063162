import React, { useCallback } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { RoutePathEnum } from "./RoutePathEnum";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OrgProfile } from "PeopleManagement/Organization/OrgProfile";
import { OrgSettings } from "PeopleManagement/Organization/OrgSettings";
import { OfflinePageUnavailable } from "./OfflinePageUnavailable";
import { RouterHelper } from "./RouterHelper";

// Org profile and settings

const EditOrgProfile = React.lazy(() =>
  import(
    "../PeopleManagement/Organization/EditOrgProfile" /* webpackChunkName: "edit-org-profile" */
  ).then(module => ({
    default: module.EditOrgProfile
  }))
);

const NewOrg = React.lazy(() =>
  import(
    "../PeopleManagement/Organization/NewOrg" /* webpackChunkName: "new-org" */
  ).then(module => ({
    default: module.NewOrg
  }))
);

const EditProfile = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();

  const canEditProfile =
    threadUserContext.isSysop ||
    findPermission(threadUserContext.role, Permissions.EDIT_ORG_PROFILE);

  const handleCancel = useCallback(() => history.goBack(), [history]);

  if (canEditProfile) {
    return <EditOrgProfile onCancel={handleCancel} />;
  } else {
    RouterHelper.redirectToOrganizationViewProfile(history);
    return null;
  }
};

const ViewProfile = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();

  const canViewProfile = findPermission(
    threadUserContext.role,
    Permissions.VIEW_ORG_PROFILE
  );

  const handleEdit = useCallback(
    () => RouterHelper.redirectToOrganizationEditProfile(history),
    [history]
  );

  if (canViewProfile) {
    return <OrgProfile onEdit={handleEdit} />;
  } else {
    RouterHelper.redirectToDefault(history);
    return null;
  }
};

const ViewSettings = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();

  const canViewSettings = findPermission(
    threadUserContext.role,
    Permissions.VIEW_ORG_SETTINGS
  );

  if (canViewSettings) {
    return <OrgSettings />;
  } else {
    RouterHelper.redirectToDefault(history);
    return null;
  }
};

const NewOrganization = () => {
  return <NewOrg />;
};

interface OrganizationRouterProps {}

export const OrganizationRouter = (props: OrganizationRouterProps) => {
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }
  return (
    <Switch>
      <Route path={RoutePathEnum.ORGANIZATION_CREATE} exact={true}>
        <NewOrganization />
      </Route>
      <Route path={RoutePathEnum.ORGANIZATION_EDIT_PROFILE} exact={true}>
        <EditProfile />
      </Route>
      <Route path={RoutePathEnum.ORGANIZATION_VIEW_PROFILE} exact={true}>
        <ViewProfile />
      </Route>
      <Route path={RoutePathEnum.ORGANIZATION_VIEW_SETTINGS} exact={true}>
        <ViewSettings />
      </Route>
    </Switch>
  );
};
