import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { App, SessionVariableEnum } from './App';
import { AuthenticationService } from './Security/AuthenticationService';
import { RenewCallback } from './Security/RenewCallback';
import { SigninCallback } from './Security/SigninCallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Suspense, useMemo, useReducer } from 'react';
import "chartist/dist/chartist.min.css";
import "@raudabaugh/thread-ui/public/ThreadUI.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes,faClock, faWrench, faArchive, faFileWord, faFileExcel, faBullseye, faChartLine, faFileContract, faDollarSign, faDownload, faCheck }
  from '@fortawesome/pro-solid-svg-icons'
import { faAngleLeft, faCaretDown, faUnlock, faEdit, faTrash, faEllipsisV, faUndo, faSearchPlus, faTimesCircle, faArrowUp, faArrowDown, faArrowRight, faWifiSlash }
  from '@fortawesome/pro-solid-svg-icons'
import { faStopCircle, faStar, faPlayCircle, faHandPointer, faMinus, faPlus, faChild, faBook, faPause, faPlay, faChevronRight, faChevronDown, faCheckCircle }
  from '@fortawesome/pro-solid-svg-icons'
import { faMagic, faSignature, faCaretSquareDown, faBars, faAward, faAngleDown, faCloudDownload, faLock as faSolidLock, faFolder, faThumbtack, faCircle, faHatWizard }
  from '@fortawesome/pro-solid-svg-icons'
import { faAddressBook, faInfoCircle, faBell, faLock, faQuestionCircle, faFolderPlus, faTrashAlt, faCopy, faPaste, faCut, faStopwatch, faExclamationTriangle, faPrint, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { Row, Illustration, Grid, Heading, Button, Col } from '@raudabaugh/thread-ui';
import { IntercomProvider } from 'react-use-intercom';
import { CurriculumContext, curriculumReducer, initialCurriculumState } from 'Curriculum/CurriculumContext';
import { ApolloHelper } from 'Shared/ApolloHelper';
import { XamarinHelper } from './Shared/XamarinHelper';
import connectingImage from "Shared/Images/undraw-connecting-teams-3-1-pgn.svg";
import { RouterHelper } from 'Routes/RouterHelper';
import { RoutePathEnum } from 'Routes/RoutePathEnum';
import { OnboardingRouter } from 'Routes/OnboardingRouter';

library.add({ faTimes, faInfoCircle, faClock, faWrench, faArchive, faFileWord, faFileExcel, faBullseye, faChartLine, faFileContract, faAddressBook, faDollarSign, faDownload, faCheck });
library.add({ faAngleLeft, faCaretDown, faUnlock, faEdit, faTrash, faEllipsisV, faUndo, faSearchPlus, faTimesCircle, faArrowUp, faArrowLeft, faArrowDown, faArrowRight, faWifiSlash  });
library.add({ faStopCircle, faStar, faPlayCircle, faHandPointer, faMinus, faPlus, faChild, faBook, faPause, faPlay, faChevronRight, faChevronDown, faCircle, faCheckCircle, faHatWizard });
library.add({ faMagic, faSignature, faCaretSquareDown, faBars, faAward, faAngleDown, faCloudDownload, faSolidLock, faFolder, faThumbtack });
library.add({ faBell, faLock, faQuestionCircle, faFolderPlus, faTrashAlt, faCopy, faPaste, faCut, faStopwatch, faExclamationTriangle, faPrint  });

XamarinHelper.registerInvokeActionFunction();

const HandleSigninCallback = () => {
  const history = useHistory();

  return (
    <SigninCallback
      onSuccess={() => {
        const authService = AuthenticationService.getInstance();
        authService.userManager.getUser().then((user) => {
          if (user?.profile.default_group) {
            // For Stitch IDP only
            sessionStorage.setItem(SessionVariableEnum.ORGANIZATION, user?.profile.default_group);
          } else if (user?.profile.org) {
            // For Central SSO IDP only
            sessionStorage.setItem(SessionVariableEnum.ORGANIZATION, user?.profile.org.organizationId);
          }
          RouterHelper.redirectToDefault(history);
        });
      }}
      onSelect={(org) => {
        sessionStorage.setItem(SessionVariableEnum.ORGANIZATION, org);
        RouterHelper.redirectToDefault(history);
      }}
      onError={() => {
        const authService = AuthenticationService.getInstance();
        authService.forceLogin();
      }}
    />
  );
};

const SorryNoAccess = () => {
  return (
    <Grid height="100vh">
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} />
      </Row>
      <Row padding="0 16px">
        <Heading level={3}>Uh oh, you have not been granted access to Thread</Heading>
      </Row>
      <Row padding="8px 16px">
        <Heading level={5}>Please ask your supervisor or internal administrator to enable your access to Thread.</Heading>
      </Row>
      <Row type='flex' padding="16px">
        <Col grow={1} margin='0 16px 0 0'>
          <Button block={true} onClick={() => document.location.href = '/'} type="primary">Try Again</Button>
        </Col>
        <Col grow={1}>
          <Button block={true} onClick={() => AuthenticationService.getInstance().logout()} type="default">Sign In as a Different User</Button>
        </Col>
      </Row>
    </Grid>
  );
};

const Switchboard = () => {
  const [state, dispatch] = useReducer(curriculumReducer, initialCurriculumState);
  // route changes can cause rerenders of this switchboard, memoize context value to reduce unnecessary leaf renders
  const curriculumContextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
      <Switch>
        <Route exact={true} path="/signin-callback.html" component={HandleSigninCallback} />
        <Route exact={true} path="/silent-renew.html" component={RenewCallback} />
        <Route exact={true} path="/noaccess" component={SorryNoAccess} />
        <Route path={RoutePathEnum.ONBOARDING}>
          <OnboardingRouter />
        </Route>
        <Route path="/">
          <ApolloHelper>
            <CurriculumContext.Provider value={curriculumContextValue}>
              <App />
            </CurriculumContext.Provider>
          </ApolloHelper>
        </Route>
      </Switch>
  );
}

ReactDOM.render(
  <Router>
    <Suspense fallback={<div>loading...</div>}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ''} autoBoot={false}>
          <Switchboard />
        </IntercomProvider>
    </Suspense>
  </Router>,
  document.getElementById('root') as HTMLElement
);

serviceWorkerRegistration.register();
