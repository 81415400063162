import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore
} from "oidc-client";
import _ from "lodash";
import "whatwg-fetch";
import { SessionVariableEnum } from "../App";
import { purgePersistence } from "Shared/ApolloHelper";
import { XamarinHelper } from "Shared/XamarinHelper";
import moment from "moment";

export class AuthenticationService {
  public static getInstance() {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  private static instance: AuthenticationService;

  public userManager: UserManager;

  private constructor() {
    Log.logger = window.console;
    Log.level = Log.DEBUG;
    const settings = this.getSettings(undefined);
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(this.handleUserLoaded);
  }

  public static getHeaders(): Record<string, string> {
    const token = sessionStorage.getItem(SessionVariableEnum.TOKEN);
    const organization =
      sessionStorage.getItem(SessionVariableEnum.ORGANIZATION) ?? "";
    const headers: Record<string, string> = {
      authorization: `Bearer ${token}`,
      organization
    };
    if (typeof Intl === "object") {
      headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return headers;
  }

  public async getUser(): Promise<User | null> {
    if (await this.xamarinLoginSupported()) {
      return XamarinHelper.getXamarinUser();
    } else {
      return this.userManager.getUser();
    }
  }

  public async login(): Promise<void> {
    if (await this.xamarinLoginSupported()) {
      await XamarinHelper.loginXamarinUser();
    } else {
      this.userManager.signinRedirect();
    }
  }

  public async logout() {
    try {
      if (!XamarinHelper.insideXamarin()) {
        await purgePersistence()
      }
    } catch (e) {
      console.error("issues clearing persistent store on logout", e);
    }
    _.forIn(window.localStorage, (value: string, objKey: string) => {
      if (_.startsWith(objKey, "oidc.")) {
        window.localStorage.removeItem(objKey);
      }
    });
    const redirectUrl: string = `${window.location.origin}/signin-callback.html`;
    const clientId: string =
      process.env.REACT_APP_STITCH_CLIENT_ID ?? "ThreadWeb";
    if (sessionStorage.getItem(SessionVariableEnum.TOKEN) !== null && !XamarinHelper.insideXamarin()) {
      sessionStorage.removeItem(SessionVariableEnum.TOKEN);
    }
    if (sessionStorage.getItem(SessionVariableEnum.ORGANIZATION) !== null) {
      sessionStorage.removeItem(SessionVariableEnum.ORGANIZATION);
    }
    if (process.env.REACT_APP_LOGOUT_URL) {
      const logoutUrl: string = `${process.env.REACT_APP_LOGOUT_URL}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;
      window.location.href = logoutUrl;
    } else {
      if (await this.xamarinLoginSupported()) {
        this.userManager.removeUser();
        XamarinHelper.clearSession();
        XamarinHelper.logout();
      } else {
        this.userManager.signoutRedirect().then(() => {
          this.userManager.removeUser();
          XamarinHelper.clearSession();
        });
      }
    }
  }

  public async forceLogin(): Promise<void> {
    if (await this.xamarinLoginSupported()) {
      await XamarinHelper.loginXamarinUser();
    } else {
      const settings = this.getSettings("login");
      const um = new UserManager(settings);
      um.signinRedirect();
    }
  }

  private async xamarinLoginSupported() : Promise<boolean> {
    if (!XamarinHelper.insideXamarin()) {
      return false;
    }
    const xamarinInfo = await XamarinHelper.getXamarinInfo();
    const versionSplit = xamarinInfo.version.split(".");
    if (versionSplit.length !== 3) {
      return false;
    }
    if (parseInt(versionSplit[0], 10) <= 1) {
      return false;
    }
    return true;
  }

  private handleUserLoaded() {
    const authService = AuthenticationService.getInstance();
    authService.getUser().then(user => {
      if (user) {
        sessionStorage.setItem(SessionVariableEnum.TOKEN, user.access_token);
        if (
          user?.profile.orgs &&
          user?.profile.orgs.length > 0 &&
          user?.profile.orgs.split(",").length === 1
        ) {
          sessionStorage.setItem(
            SessionVariableEnum.ORGANIZATION,
            user?.profile.orgs
          );
        }
      }
    });
  }

  private getSettings(prompt: string | undefined): UserManagerSettings {
    const idpUrl: string =
      process.env.REACT_APP_IDP_URL ?? process.env.REACT_APP_STITCH_URL ?? "";
    const clientId: string =
      process.env.REACT_APP_STITCH_CLIENT_ID ?? "ThreadWeb";
    const settings: UserManagerSettings = {
      authority: idpUrl,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: false,
      monitorSession: true,
      query_status_response_type: "code",
      client_id: clientId,
      post_logout_redirect_uri: window.location.origin + "/",
      prompt,
      redirect_uri: window.location.origin + `/signin-callback.html`,
      response_type: "code",
      scope:
        process.env.REACT_APP_IDP_SCOPES ??
        "openid profile ThreadApi SpoolApi StitchApi",
      silent_redirect_uri: window.location.origin + `/silent-renew.html`,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      loadUserInfo: true,
      extraQueryParams: {
        app: process.env.REACT_APP_ID,
        ts: moment.now()
      }
    };
    return settings;
  }
}
