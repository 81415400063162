import { Grid, Row, Heading } from "@raudabaugh/thread-ui";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { EditStaffBasicInfo } from "PeopleManagement/StaffProfile/EditStaffBasicInfo";
import {
  IStaffOnboardingComponent,
  IStaffOnboardingModel,
  StaffOnboardingStepEnum
} from "./Types";

export interface IStaffProfileProps extends IStaffOnboardingComponent {}

export const StaffProfile = (props: IStaffProfileProps) => {
  const [basicValid, setBasicValid] = useState(true);
  const isFormInvalid = useCallback(() => {
    if (
      !props.model.lastName ||
      props.model.lastName.trim() === "" ||
      !props.model.firstName ||
      props.model.firstName.trim() === "" ||
      !props.model.phoneNumber ||
      props.model.phoneNumber.trim() === ""
    ) {
      return (
        <Heading color="error" level={6}>
          Missing one or more required fields.
        </Heading>
      );
    } else {
      return basicValid ? undefined : <></>;
    }
  }, [props.model, basicValid]);

  useEffect(() => {
    props.onValidation(StaffOnboardingStepEnum.PROFILE, !isFormInvalid());
  }, [props.model, props, isFormInvalid]);

  const handleChangeText = (
    field: string,
    value: ChangeEvent<HTMLInputElement>
  ) => {
    const update: IStaffOnboardingModel = { ...props.model };
    update[field] = value.target.value;
    props.onChange(update);
  };

  const handleBasicValidation = (valid: boolean) => {
    setBasicValid(valid);
  };

  return (
    <Grid type="flex" height="100vh" width="100%">
      <Row padding="8px 0">
        <Heading level={5}>
          Please review and update your account profile to ensure that your
          contact information is correct.
        </Heading>
      </Row>
      <Row padding="8px 0">
        <EditStaffBasicInfo
          firstName={props.model.firstName ?? ""}
          lastName={props.model.lastName ?? ""}
          email="N/A"
          phoneNumber={props.model.phoneNumber ?? ""}
          isCreate={false}
          phoneRequired={true}
          onChangeText={handleChangeText}
          onValidation={handleBasicValidation}
        />
      </Row>
      {props.showValidation && <Row padding="0 0">{isFormInvalid()}</Row>}
    </Grid>
  );
};
