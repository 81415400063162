import { Button, Icon, Row } from "@raudabaugh/thread-ui";
import React from "react";
import { ActionsMenu } from "./ActionsMenu";
import { LargeCardLayout } from "./LargeCardLayout";
import { SmallCardLayout } from "./SmallCardLayout";

export interface ISimpleTrialCardProps {
  icon: string;
  title: string;
  caption?: string;
  score?: React.ReactNode;
  index: number;
  size: "sm" | "lg";
  studentId: string;
  onButtonClick: () => void;
  onAbcClick: () => void;
  onChartClick: () => void;
  onUndoClick?: () => void;
  onCloseClick: () => void;
  onMoveToTopClick: () => void;
  onEnterScoreClick: () => void;
  onShowFocusDrawer?: () => void;
  onPopupClick: () => void;
}

export const SimpleTrialCard = (props: ISimpleTrialCardProps) => {
  const actions = (
    <ActionsMenu id={`Index${props.index + props.title}DropDown`} {...props} />
  );
  const body = (
    <Button
      id="durationButton"
      block={true}
      size="x-large"
      type="primary"
      onClick={props.onButtonClick}
    >
      <Row>
        <Icon size="1.1em" color="default" variation={1} type={props.icon} />
      </Row>
      <Row>{props.caption}</Row>
    </Button>
  );
  return props.size === "sm" ? (
    <SmallCardLayout
      title={props.title}
      actions={actions}
      onPopup={props.onPopupClick}
      onShowFocusDrawer={props.onShowFocusDrawer}
    >
      {body}
    </SmallCardLayout>
  ) : (
    <LargeCardLayout
      title={props.title}
      actions={actions}
      onPopup={props.onPopupClick}
      caption={props.score}
      onShowFocusDrawer={props.onShowFocusDrawer}
    >
      {body}
    </LargeCardLayout>
  );
};
