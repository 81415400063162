import React from "react";
import {
  Tabs,
  TabPane,
  Grid,
  Heading,
  Row,
  Illustration
} from "@raudabaugh/thread-ui";
import { StudentTabEnum } from "./StudentTabEnum";
import { LabelHelper } from "./LabelHelper";
import { useHistory } from "react-router";
import { RouterHelper } from "Routes/RouterHelper";
import StudentBar from "StudentPrograms/StudentBar";
import { useOnlineStatus } from "./ApolloHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { StudentProfileHeader } from "PeopleManagement/StudentProfile/StudentProfileHeader";
import { findPermission } from "./RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { OrganizationSettingsEnum } from "App";
import { SettingsHelper } from "./SettingsHelper";
import warningImage from "../Routes/undraw_warning_cyit.svg";

interface IStudentDataTabsProps {
  activeTab: StudentTabEnum;
  children: React.ReactNode;
  studentId: string;
}

export const StudentDataTabs = (props: IStudentDataTabsProps) => {
  const tabs = [
    {
      key: StudentTabEnum.PROFILE,
      redirectToTab: RouterHelper.redirectToStudentProfile
    },
    {
      key: StudentTabEnum.CHARTS,
      redirectToTab: RouterHelper.redirectToCharts
    },
    {
      key: StudentTabEnum.SESSION_NOTES,
      redirectToTab: RouterHelper.redirectToSessionNotes,
      setting: OrganizationSettingsEnum.MOBILE_SESSION_NOTES
    },
    {
      key: StudentTabEnum.ABC_DATA,
      redirectToTab: RouterHelper.redirectToAbcData,
      permission: Permissions.VIEW_ABC_DATA
    },
    {
      key: StudentTabEnum.MAINTENANCE,
      redirectToTab: RouterHelper.redirectToMaintenance,
      permission: Permissions.VIEW_MAINTENANCE
    },
    {
      key: StudentTabEnum.ARCHIVE,
      redirectToTab: RouterHelper.redirectToArchive,
      permission: Permissions.VIEW_ARCHIVE
    }
  ];
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const availableOffline =
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.studentId && s.availableOffline
    ).length > 0;
  const studentIsAssigned =
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.studentId
    ).length > 0;

  const handleTabsChange = (activeKey: string) => {
    const tab = tabs.find(tab => tab.key === activeKey);
    if (tab) {
      tab.redirectToTab(props.studentId, history, true);
    }
  };
  const renderTabs = () => {
    return tabs
      .filter(tab => tab.key === StudentTabEnum.PROFILE || studentIsAssigned)
      .filter(
        tab =>
          !tab.permission ||
          findPermission(threadUserContext.role, tab.permission)
      )
      .filter(
        tab =>
          !tab.setting ||
          SettingsHelper.boolean(threadUserContext, tab.setting, true)
      )
      .map(tab => (
        <TabPane tab={LabelHelper.studentTabLabel(tab.key)} key={tab.key}>
          {props.activeTab === tab.key && props.children}
        </TabPane>
      ));
  };

  if (!(online || availableOffline)) {
    return (
      <>
        <Grid>
          <StudentBar currentStudentId={props.studentId} />
          <Row padding="0 0 32px 0">
            <Illustration src={warningImage} topOffset={45} />
          </Row>
          <Row padding="0 16px">
            <Heading level={3}>You are currently offline</Heading>
          </Row>
          <Row padding="8px 16px">
            <Heading level={5}>
              This student data is not available while in offline mode. Please
              connect to the internet as soon as possible to have full
              application functionality.
            </Heading>
          </Row>
        </Grid>
      </>
    );
  }

  return (
    <Grid padding="8px 0" color="default" variation={1}>
      {props.activeTab === StudentTabEnum.PROFILE ? (
        <StudentProfileHeader id={props.studentId} canEdit={true} />
      ) : (
        <StudentBar currentStudentId={props.studentId} />
      )}
      <Tabs
        size="large"
        onChange={handleTabsChange}
        activeKey={props.activeTab}
      >
        {renderTabs()}
      </Tabs>
    </Grid>
  );
};
