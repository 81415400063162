/// <reference types="@welldone-software/why-did-you-render" />
import { memo, useCallback, useEffect, useState } from "react";
import compose from "@shopify/react-compose";
import { Heading, Modal, Row } from "@raudabaugh/thread-ui";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import {
  IProgramsFromArchiveVariables,
  IProgramsFromArchive_programsFromArchive_edges,
  IProgramsFromArchive_programsFromArchive_edges_node
} from "../../Shared/Api/IProgramsFromArchive";
import { ArchivedProgramsTable } from "./ArchivedProgramsTable";
import {
  useProgramChartQuery,
  useProgramSetArchivedMutation,
  useProgramSetDeactivatedMutation,
  useProgramsFromArchiveQuery
} from "../../DataAccess/ProgramData";
import ReactTimeout, { ReactTimeoutProps } from "react-timeout";
import { ModalConfirmation } from "../../Shared/ModalConfirmation";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import isEqual from "react-fast-compare";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import {
  ArchiveOrderBy,
  ArchiveSortDirection,
  ArchiveTypeEnum,
  ProgramTypeEnum
} from "../../Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { PhasePopup } from "Charts/PhasePopup";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { StudentTabEnum } from "Shared/StudentTabEnum";
import { useOnlineStatus } from "Shared/ApolloHelper";

export interface IArchiveBaseProps {
  currentStudentId: string;
}

interface IArchiveProps extends IArchiveBaseProps, ReactTimeoutProps {}

const DefaultItems = 40;
const DefaultMoreItems = 150;

const ArchiveViewComponent = (props: IArchiveProps) => {
  const {
    threadUserContext: { role }
  } = useThreadContext();
  const online = useOnlineStatus();
  const { currentStudentId } = props;

  // MUTATIONS
  const { programSetDeactivated } = useProgramSetDeactivatedMutation();
  const { programSetArchived } = useProgramSetArchivedMutation();
  const defaultOrderBy = ArchiveOrderBy.LAST_RUN;
  const defaultSortDirection = ArchiveSortDirection.DESCENDING;
  const defaultFilterByArchiveType = ArchiveTypeEnum.NONE;
  const defaultFilterByProgramType = ProgramTypeEnum.NONE;

  const defaultQueryState: IProgramsFromArchiveVariables = {
    studentId: "",
    contextId: "",
    after: null,
    first: DefaultItems,
    orderBy: defaultOrderBy,
    sortDirection: defaultSortDirection,
    filterByArchiveType: defaultFilterByArchiveType,
    filterByProgramType: defaultFilterByProgramType
  };

  const [queryState, setQueryState] = useState<IProgramsFromArchiveVariables>({
    ...defaultQueryState,
    studentId: props.currentStudentId
  });

  const [showPopup, setShowPopup] = useState<string>();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteProgram, setDeleteProgram] =
    useState<IProgramsFromArchive_programsFromArchive_edges_node | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [archivedItems, setArchivedItems] = useState<
    IProgramsFromArchive_programsFromArchive_edges[]
  >([]);

  const { error: archiveError, data: archiveData } =
    useProgramsFromArchiveQuery(queryState, { skip: !online });

  const handleUnarchiveButtonClick = async (
    node: IProgramsFromArchive_programsFromArchive_edges_node
  ) => {
    try {
      await programSetArchived(
        node.id,
        currentStudentId,
        false,
        ArchiveTypeEnum.NONE
      );
      resetResultState();
      setQueryState({
        ...defaultQueryState,
        studentId: props.currentStudentId,
        contextId: uuid()
      });
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Unable to delete unarchive item."
      });
    }
  };

  const handleNameClick = (
    node: IProgramsFromArchive_programsFromArchive_edges_node
  ) => {
    setShowPopup(node?.id);
  };

  const resetResultState = useCallback(() => {
    setArchivedItems([]);
    setHasMore(true);
  }, []);

  const handleChange = (pagination: any, filter: any, sorter: any) => {
    const derivedQueryState = {
      ...defaultQueryState,
      studentId: props.currentStudentId,
      after: queryState.after,
      first: queryState.first,
      contextId: queryState.contextId
    };

    // handle sorter
    if (sorter && sorter.order && sorter.columnKey) {
      if (sorter.order === "ascend") {
        derivedQueryState.sortDirection = ArchiveSortDirection.ASCENDING;
      } else {
        derivedQueryState.sortDirection = ArchiveSortDirection.DESCENDING;
      }

      switch (sorter.columnKey) {
        case "title":
          derivedQueryState.orderBy = ArchiveOrderBy.TITLE;
          break;
        case "archiveType":
          derivedQueryState.orderBy = ArchiveOrderBy.ARCHIVE_TYPE;
          break;
        case "lastRun":
          derivedQueryState.orderBy = ArchiveOrderBy.LAST_RUN;
          break;
        case "type":
          derivedQueryState.orderBy = ArchiveOrderBy.TYPE;
          break;
      }
    }

    if (!_.isEqual(derivedQueryState, queryState)) {
      resetResultState();
      setQueryState({
        ...derivedQueryState,
        after: null,
        first: DefaultItems,
        contextId: uuid()
      });
    }
  };

  const handleLoadData = useCallback(() => {
    hasMore &&
      setQueryState({
        ...queryState,
        first: DefaultMoreItems,
        after:
          archivedItems && archivedItems.length
            ? archivedItems[archivedItems.length - 1]?.cursor!
            : null
      });
  }, [archivedItems, queryState, hasMore]);

  const handleDeleteClick = (
    node: IProgramsFromArchive_programsFromArchive_edges_node
  ) => {
    setDeleteProgram(node);
    setDeleteConfirmationModalOpen(true);
  };

  const handleDeletConfirmationModalOk = async () => {
    setDeleteConfirmationModalOpen(false);
    await deleteArchiveItem();
  };

  const handleConfirmationModalClose = () => {
    setDeleteProgram(null);
    setDeleteConfirmationModalOpen(false);
  };

  const deleteArchiveItem = async () => {
    try {
      await programSetDeactivated(
        deleteProgram?.id as string,
        currentStudentId,
        true
      );
      resetResultState();
      setQueryState({
        ...queryState,
        after: null,
        first: DefaultItems,
        contextId: uuid()
      });
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Unable to delete archive item."
      });
    } finally {
      setDeleteProgram(null);
    }
  };

  useEffect(() => {
    if (archiveError) {
      NotificationsHelper.ErrorNotification({
        error: archiveError,
        title: "Unable to access Archive Programs.",
        refreshPageOnClose: true
      });
      setHasMore(false);
      setArchivedItems([]);
    }
  }, [archiveError]);

  useEffect(() => {
    let cancelled = false;
    if (!cancelled && archiveData && hasMore) {
      const totalCount = +archiveData.programsFromArchive!.totalCount! || 0;
      if (totalCount > 0) {
        const tempArchivedItems = archivedItems.concat(
          archiveData.programsFromArchive!
            .edges! as IProgramsFromArchive_programsFromArchive_edges[]
        );
        const tempHasMore = totalCount > tempArchivedItems.length;
        setArchivedItems(tempArchivedItems);
        setHasMore(tempHasMore);
      } else {
        setArchivedItems([]);
        setHasMore(false);
      }
    }

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveData]);

  const popupProgramName =
    (showPopup &&
      archivedItems?.find(edge => edge.node?.id === showPopup)?.node.name) ??
    "";

  if (!online) {
    return (
      <StudentDataTabs
        studentId={currentStudentId}
        activeTab={StudentTabEnum.ARCHIVE}
      >
        <Row padding="0 16px">
          <Heading level={3}>You are currently offline</Heading>
        </Row>
        <Row padding="8px 16px">
          <Heading level={5}>
            Archived programs are not available while in offline mode. Please
            connect to the internet as soon as possible to have full application
            functionality.
          </Heading>
        </Row>
      </StudentDataTabs>
    );
  }

  return (
    <>
      <ModalConfirmation
        title="Are you sure that you want to delete this data?"
        message="This action cannot be undone."
        type="warning"
        visible={deleteConfirmationModalOpen}
        onCancel={handleConfirmationModalClose}
        onOk={handleDeletConfirmationModalOk}
        okButtonTitle="Delete"
      />
      <StudentDataTabs
        studentId={currentStudentId}
        activeTab={StudentTabEnum.ARCHIVE}
      >
        <ArchivedProgramsTable
          hasMore={hasMore}
          role={role}
          data={archivedItems}
          onChange={handleChange}
          onLoadData={handleLoadData}
          onUnarchiveButtonClick={handleUnarchiveButtonClick}
          onDeleteClick={handleDeleteClick}
          onNameClick={handleNameClick}
        />
      </StudentDataTabs>
      {showPopup && (
        <Modal
          title={popupProgramName}
          onCancel={() => setShowPopup(undefined)}
          visible={true}
          centered={true}
          footer={null}
        >
          <ArchivePopup
            studentId={props.currentStudentId}
            programId={showPopup}
          />
        </Modal>
      )}
    </>
  );
};

ArchiveViewComponent.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "ArchiveViewComponent"
};

export const ArchiveView = memo(
  compose<IArchiveBaseProps>(ReactTimeout)(ArchiveViewComponent),
  isEqual
);

interface IArchivePopupProps {
  programId: string;
  studentId: string;
}

const ArchivePopup: React.FC<IArchivePopupProps> = ({
  programId,
  studentId
}) => {
  const { loading, data, error } = useProgramChartQuery(studentId, programId);

  if (loading) {
    return <LoadingScreen loading={true} />;
  }

  if (error || !data) {
    return <>Error loading program data</>;
  }

  const phase = data.programChart.phases[data.programChart.phases.length - 1];
  const program = data.programChart.program;

  return (
    <PhasePopup
      phase={phase}
      currentPhase={phase}
      program={program}
      studentId={studentId}
    />
  );
};
