import {
  Button,
  Col,
  Grid,
  Heading,
  Row,
  Select,
  SelectOption
} from "@raudabaugh/thread-ui";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { getInitials } from "../Shared/Initials";
import { ProtectedAvatar } from "../Shared/ProtectedAvatar";
import { useUserQuery } from "DataAccess/UserData";
import { RouterHelper } from "Routes/RouterHelper";
import { LabelHelper } from "Shared/LabelHelper";
import { RoutePathEnum } from "Routes/RoutePathEnum";

export interface IStudentBarProps {
  currentStudentId?: string;
  onSelectStudent?: (id: string) => void;
}

const StudentBar = (props: IStudentBarProps) => {
  const history = useHistory();
  const { onSelectStudent, currentStudentId } = props;
  const { data: userData, refetch } = useUserQuery(null, {
    fetchPolicy: "cache-only"
  });

  const handleStudentClick = useCallback(
    (value: string | number) => {
      onSelectStudent?.(String(value));
    },
    [onSelectStudent]
  );

  const handleProfileClick = useCallback(() => {
    if (currentStudentId) {
      RouterHelper.redirectToStudentProfile(
        currentStudentId,
        history,
        false,
        LabelHelper.routePathLabel(RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS)
      );
    }
  }, [currentStudentId, history]);

  const students = useMemo(() => {
    if (userData && userData.user) {
      const appUser = userData.user;
      const students = [...appUser.assignedStudents]
        .sort((a, b) =>
          (a.student.fullName ?? "").localeCompare(b.student.fullName ?? "")
        )
        .map(as => as.student);

      return students;
    }

    return [];
  }, [userData]);

  const currentStudent = useMemo(() => {
    return students.find(s => s.id === currentStudentId);
  }, [currentStudentId, students]);

  const handleVisibleChange = useCallback(
    (visible?: boolean) => {
      visible && refetch();
    },
    [refetch]
  );

  useEffect(() => {
    if (!currentStudent) {
      RouterHelper.redirectToNoStudentFound(history);
    }
  }, [currentStudent, history]);

  if (!currentStudent) {
    return <></>;
  }

  return (
    <Grid width="100%" margin="0" padding="0 12px">
      <Row type="flex" wrap={false}>
        <Col grow={0} margin="5px 0 0 0">
          <ProtectedAvatar
            color="primary"
            size={40}
            src={currentStudent.avatarUrl || null}
          >
            {getInitials(currentStudent.fullName)}
          </ProtectedAvatar>
        </Col>
        <Col grow={1} padding="0 0 0 12px">
          <Row>
            {onSelectStudent ? (
              <Select
                value={currentStudentId}
                onChange={handleStudentClick}
                onDropdownVisibleChange={handleVisibleChange}
                width="100%"
              >
                {students.map((student, index) => (
                  <SelectOption key={"student-" + index} value={student.id}>
                    {student.fullName ?? ""}
                  </SelectOption>
                ))}
              </Select>
            ) : (
              <Heading level={4} weight="bold" noTooltip>
                {currentStudent.fullName}
              </Heading>
            )}
          </Row>
          <Row>
            {onSelectStudent ? (
              <Button
                type="primary"
                borderless
                ghost
                size="small"
                onClick={handleProfileClick}
              >
                See Student Profile & Files
              </Button>
            ) : (
              <Heading level={6}>
                Last Seen:{" "}
                {LabelHelper.relativeDateLabel(moment(currentStudent.lastSeen))}
              </Heading>
            )}
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};

export default memo(StudentBar);
