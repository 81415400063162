import gql from "graphql-tag";
import { FRAGMENT_ORGANIZATION } from "./Fragments";
import { QUERY_CUSTOM_SETTINGS, QUERY_ORGANIZATION } from "./Queries";
import { useMutation, useQuery } from "@apollo/client";
import {
  organizationCreate,
  organizationCreateVariables
} from "../Shared/Api/organizationCreate";
import {
  OrganizationInput,
  OrganizationSettingsInput
} from "../Shared/Api/globalTypes";
import {
  organizationUpdateSettings,
  organizationUpdateSettingsVariables
} from "../Shared/Api/organizationUpdateSettings";
import { ICustomSettings } from "../Shared/Api/ICustomSettings";
import {
  IOrganization,
  IOrganizationVariables
} from "../Shared/Api/IOrganization";
import {
  organizationUpdate,
  organizationUpdateVariables
} from "../Shared/Api/organizationUpdate";
import { useCallback } from "react";

const MUTATION_CREATE = gql`
  mutation organizationCreate($input: OrganizationInput) {
    organizationCreate(input: $input) {
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

const MUTATION_UPDATE = gql`
  mutation organizationUpdate($id: GUID!, $input: OrganizationInput) {
    organizationUpdate(id: $id, input: $input) {
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

const MUTATION_UPDATE_SETTINGS = gql`
  mutation organizationUpdateSettings(
    $id: GUID!
    $input: OrganizationSettingsInput!
  ) {
    organizationUpdateSettings(id: $id, input: $input) {
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

const ORGANIZATION_POLL_INTERVAL = 60 * 60 * 1000; // 1 hour
const CUSTOM_SETTINGS_POLL_INTERVAL = 60 * 60 * 1000; // 1 hour

export const useOrganizationCreateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    organizationCreate,
    organizationCreateVariables
  >(MUTATION_CREATE);

  const handleOrganizationCreate = useCallback(
    (input: OrganizationInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { organizationCreate: handleOrganizationCreate, error, data };
};

export const useOrganizationUpdateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    organizationUpdate,
    organizationUpdateVariables
  >(MUTATION_UPDATE);

  const handleOrganizationUpdate = useCallback(
    (id: string, input: OrganizationInput) => {
      return mutate!({
        variables: { id, input }
      });
    },
    [mutate]
  );
  return { organizationUpdate: handleOrganizationUpdate, error, data };
};

export const useOrganizationUpdateSettingsMutation = () => {
  const [mutate, { error, data }] = useMutation<
    organizationUpdateSettings,
    organizationUpdateSettingsVariables
  >(MUTATION_UPDATE_SETTINGS);

  const handleOrganizationUpdateSettings = useCallback(
    (id: string, input: OrganizationSettingsInput) => {
      return mutate!({
        variables: { id, input }
      });
    },
    [mutate]
  );
  return {
    organizationUpdateSettings: handleOrganizationUpdateSettings,
    error,
    data
  };
};

// Query Hooks
export const useOrganizationQuery = (id: string) => {
  const { loading, error, data } = useQuery<
    IOrganization,
    IOrganizationVariables
  >(QUERY_ORGANIZATION, {
    variables: { id },
    pollInterval: ORGANIZATION_POLL_INTERVAL
  });

  return { loading, error, data };
};

// Query Hooks
export const useOrganizationCustomSettingsQuery = () => {
  const { loading, error, data } = useQuery<ICustomSettings>(
    QUERY_CUSTOM_SETTINGS,
    {
      pollInterval: CUSTOM_SETTINGS_POLL_INTERVAL
    }
  );

  return { loading, error, data };
};
