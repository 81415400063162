import { Col, Heading, Link, Paragraph, Row } from "@raudabaugh/thread-ui";
import { LabelHelper } from "../Shared/LabelHelper";
import { PromptHelper } from "../Shared/PromptHelper";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import {
  ProgramTypeEnum,
  ReinforcementScheduleEnum,
  TargetStateEnum
} from "Shared/Api/globalTypes";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { SettingsHelper } from "Shared/SettingsHelper";
import { OrganizationSettingsEnum, ProgramFieldEnum } from "App";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { DataPointHelper } from "Shared/DataPointHelper";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { useEffect, useState } from "react";
import { useProgramChartQuery } from "DataAccess/ProgramData";
import { PromptingStrategyEnum } from "StudentPrograms/PromptingStrategyEnum";

interface IPhasePopupProps {
  phase: PhaseFragment;
  currentPhase: PhaseFragment;
  program: ProgramFragment;
  studentId: string;
  onEditPhase?: (phaseId: string) => void;
}

export const PhasePopup = (props: IPhasePopupProps) => {
  const { threadUserContext } = useThreadContext();

  const [points, setPoints] = useState<DataPointFragment[]>();
  const { data: chartData } = useProgramChartQuery(
    props.studentId,
    props.program.id
  );
  const isDiscreteTrial = props.program.type === ProgramTypeEnum.DTT;
  const isTaskAnalysis = props.program.type === ProgramTypeEnum.TASK_ANALYSIS;
  const isBehavior =
    props.program.type === ProgramTypeEnum.FREQUENCY ||
    props.program.type === ProgramTypeEnum.DURATION;
  const addMinAttemptedField =
    SettingsHelper.exists(
      threadUserContext,
      OrganizationSettingsEnum.ADD_SECTION,
      ProgramFieldEnum.MIN_ATTEMPTED
    ) && !isBehavior;
  const removePromptField = SettingsHelper.exists(
    threadUserContext,
    OrganizationSettingsEnum.HIDE_SECTION,
    ProgramFieldEnum.PROMPT
  );
  const removeReinforcementRatioField =
    props.phase.reinforcementSchedule ===
      ReinforcementScheduleEnum.CONTINUOUS ||
    props.phase.reinforcementSchedule === ReinforcementScheduleEnum.NONE;
  const isReinforcementInterval =
    props.phase.reinforcementSchedule ===
      ReinforcementScheduleEnum.FIXED_INTERVAL ||
    props.phase.reinforcementSchedule ===
      ReinforcementScheduleEnum.VARIABLE_INTERVAL;
  const phaseTargets = props.phase.targetIds
    .map(id => props.program.targets.find(t => t.id === id))
    .filter(x => x !== undefined);
  const masteredTargets = props.program.targets.filter(
    t => t.state === TargetStateEnum.MASTERED
  );
  const futureTargets = props.program.targets.filter(
    t => t.state === TargetStateEnum.FUTURE
  );
  const currentTargets = props.currentPhase.targetIds
    .map(id => props.program.targets.find(t => t.id === id))
    .filter(x => x !== undefined);
  useEffect(() => {
    if (chartData) {
      const points = chartData.programChart.dataPoints.filter(
        point => point.phaseId === props.phase.id
      );
      setPoints(points);
    }
  }, [chartData, props.phase.id]);
  const averageLabel = LabelHelper.phaseAverageLabel(props.program.type);
  const average = DataPointHelper.phaseAverage(
    props.program.type,
    points,
    props.phase
  );
  const itemizedResults = DataPointHelper.phaseItemizedResults(
    props.program.type,
    phaseTargets,
    points
  );
  const itemizedPhase = `\u00A0 - \u00A0${itemizedResults.map(
    (result, index) =>
      (index > 0 ? " " : "") +
      `${result.targetDescription} (${result.plus}/${result.total})`
  )} `;
  const promptLabel = () => {
    const prompting =
      !!props.phase.errorless && isDiscreteTrial
        ? PromptingStrategyEnum.TRIAL
        : PromptingStrategyEnum.PHASE;
    const label = LabelHelper.promptingStrategyLabel(prompting);
    return `Prompt (${label})`;
  };

  return (
    <>
      {props.onEditPhase && props.phase.id !== props.currentPhase.id && (
        <Row margin="0 0 12px 0">
          <Link onClick={() => props.onEditPhase?.(props.phase.id)}>
            <Heading level={5} color="primary">
              Edit Historical Record
            </Heading>
          </Link>
        </Row>
      )}
      {props.phase.phaseNameOverride && (
        <>
          <Heading level={5}>Phase name</Heading>
          <Paragraph>{props.phase.phaseNameOverride}</Paragraph>
        </>
      )}
      {points && (
        <>
          <Heading level={5}>{averageLabel}</Heading>
          <Paragraph ellipsis>
            {average}
            {itemizedResults.length > 0 && itemizedPhase}
          </Paragraph>
        </>
      )}
      {props.phase.phaseSummary && (
        <>
          <Heading level={5}>Phase details</Heading>
          <Paragraph>{props.phase.phaseSummary}</Paragraph>
        </>
      )}
      {isDiscreteTrial && (
        <>
          <Heading level={5}>Number of trials</Heading>
          <Paragraph>
            {props.phase.numberOfTrials ? props.phase.numberOfTrials : "varies"}
          </Paragraph>
        </>
      )}
      {isTaskAnalysis && props.phase.steps.length > 0 && (
        <>
          <Heading level={5}>Steps</Heading>
          {props.phase.steps.map((c, index) => {
            const active = c.state.toLocaleLowerCase() === "active";
            return (
              <Row type="flex" justify="start" gutter={25}>
                <Col>
                  <Paragraph>{index + 1}.</Paragraph>
                </Col>
                <Col>
                  <Paragraph>{c.description}</Paragraph>
                </Col>
                <Col>
                  <Paragraph color={active ? "success" : "error"}>
                    {active ? "Active" : "Inactive"}
                  </Paragraph>
                </Col>
              </Row>
            );
          })}
        </>
      )}
      {props.program.type === ProgramTypeEnum.INTERVAL &&
        props.phase.lengthOfEachInterval && (
          <>
            <Heading level={5}>Length of each interval</Heading>
            <Paragraph>
              {props.phase.lengthOfEachInterval % 60 === 0
                ? `${props.phase.lengthOfEachInterval / 60} Minutes`
                : `${props.phase.lengthOfEachInterval} Seconds`}
            </Paragraph>
          </>
        )}
      {props.program.type === ProgramTypeEnum.INTERVAL &&
        props.program.intervalType && (
          <>
            <Heading level={5}>Interval type</Heading>
            <Paragraph>
              {LabelHelper.intervalTypeLabel(props.program.intervalType)}
            </Paragraph>
          </>
        )}
      {addMinAttemptedField && props.phase.minimumRequiredTrials !== undefined && (
        <>
          <Heading level={5}>Minimum trials</Heading>
          <Paragraph>{props.phase.minimumRequiredTrials}</Paragraph>
        </>
      )}
      {(props.phase.criterionForMasteryText ||
        props.phase.criterionForMastery.length > 0) && (
        <>
          <Heading level={5}>Criterion for mastery</Heading>
          <Paragraph>
            {props.phase.criterionForMastery.map((c, index) => {
              return (
                <Heading key={index} level={6} margin="0 0 0 24px">
                  &bull;&nbsp;
                  {`${c.pointsAnalyzed} score of ${Math.round(
                    c.minPercentage * 100
                  )}%` +
                    (index !== props.phase.criterionForMastery.length - 1
                      ? " or"
                      : "")}
                </Heading>
              );
            })}
            {props.phase.criterionForMasteryText}
          </Paragraph>
        </>
      )}
      {props.phase.id !== props.currentPhase.id && phaseTargets.length > 0 && (
        <>
          <Heading level={5}>
            Historical targets in this phase ({phaseTargets.length})
          </Heading>
          <Paragraph>
            {phaseTargets.map(
              (t, index) => (index > 0 ? ", " : "") + t?.targetDescription
            )}
          </Paragraph>
        </>
      )}
      {currentTargets.length > 0 && (
        <>
          <Heading level={5}>
            Current targets in program ({currentTargets.length})
          </Heading>
          <Paragraph>
            {currentTargets.map(
              (t, index) => (index > 0 ? ", " : "") + t?.targetDescription
            )}
          </Paragraph>
        </>
      )}
      {masteredTargets.length > 0 && (
        <>
          <Heading level={5}>
            Mastered targets in program ({masteredTargets.length})
          </Heading>
          <Paragraph>
            {masteredTargets.map(
              (t, index) => (index > 0 ? ", " : "") + t.targetDescription
            )}
          </Paragraph>
        </>
      )}
      {futureTargets.length > 0 && (
        <>
          <Heading level={5}>
            Future targets in program ({futureTargets.length})
          </Heading>
          <Paragraph>
            {futureTargets.map(
              (t, index) => (index > 0 ? ", " : "") + t.targetDescription
            )}
          </Paragraph>
        </>
      )}
      {props.phase.procedureDetails && (
        <>
          <Heading level={5}>Procedure</Heading>
          <Paragraph>{props.phase.procedureDetails}</Paragraph>
        </>
      )}
      {props.program.prerequisiteSkillsNeeded && (
        <>
          <Heading level={5}>Prerequisite skills needed</Heading>
          <Paragraph>{props.program.prerequisiteSkillsNeeded}</Paragraph>
        </>
      )}
      {props.phase.promptingProcedure && (
        <>
          <Heading level={5}>Prompting procedure / error correction</Heading>
          <Paragraph>{props.phase.promptingProcedure}</Paragraph>
        </>
      )}
      {props.phase.materials && (
        <>
          <Heading level={5}>Required materials</Heading>
          <Paragraph>{props.phase.materials}</Paragraph>
        </>
      )}
      {props.phase.tips && (
        <>
          <Heading level={5}>Tips</Heading>
          <Paragraph>{props.phase.tips}</Paragraph>
        </>
      )}
      {props.phase.definitionOfBehavior && (
        <>
          <Heading level={5}>Definition of behavior</Heading>
          <Paragraph>{props.phase.definitionOfBehavior}</Paragraph>
        </>
      )}
      {props.phase.frequencyOfDataCollectionText && (
        <>
          <Heading level={5}>Frequency of data collection</Heading>
          <Paragraph>{props.phase.frequencyOfDataCollectionText}</Paragraph>
        </>
      )}
      {!removePromptField && props.phase.prompt !== PromptHelper.PROMPT_NONE && (
        <>
          <Heading level={5}>{promptLabel()}</Heading>
          <Paragraph>{props.phase.prompt}</Paragraph>
        </>
      )}
      {props.phase.baselineText && (
        <>
          <Heading level={5}>Baseline</Heading>
          <Paragraph>{props.phase.baselineText}</Paragraph>
        </>
      )}
      {props.phase.instructionalCue && (
        <>
          <Heading level={5}>Instructional cue</Heading>
          <Paragraph>{props.phase.instructionalCue}</Paragraph>
        </>
      )}
      {props.phase.reinforcementSchedule !== ReinforcementScheduleEnum.NONE && (
        <>
          <Heading level={5}>Reinforcement schedule</Heading>
          <Paragraph>
            {LabelHelper.reinforcementScheduleLabel(
              props.phase.reinforcementSchedule
            )}
          </Paragraph>
        </>
      )}
      {!removeReinforcementRatioField &&
        props.phase.reinforcementRatio &&
        !isReinforcementInterval && (
          <>
            <Heading level={5}>Reinforcement ratio</Heading>
            <Paragraph>{props.phase.reinforcementRatio}</Paragraph>
          </>
        )}
      {!removeReinforcementRatioField &&
        props.phase.reinforcementRatio &&
        isReinforcementInterval && (
          <>
            <Heading level={5}>Reinforcement interval</Heading>
            <Paragraph>
              {props.phase.reinforcementRatio % 60 === 0
                ? `${props.phase.reinforcementRatio / 60} Minutes`
                : `${props.phase.reinforcementRatio} Seconds`}
            </Paragraph>
          </>
        )}
      {props.phase.typeOfReinforcement && (
        <>
          <Heading level={5}>Reinforcement type</Heading>
          <Paragraph>{props.phase.typeOfReinforcement}</Paragraph>
        </>
      )}
      {props.phase.unitGoal && (
        <>
          <Heading level={5}>Unit goal / Short term objective</Heading>
          <Paragraph>{props.phase.unitGoal}</Paragraph>
        </>
      )}
      {props.program.programGoal && (
        <>
          <Heading level={5}>Program goal</Heading>
          <Paragraph>{props.program.programGoal}</Paragraph>
        </>
      )}
    </>
  );
};
