import {
  LazyQueryHookOptions,
  QueryFunctionOptions,
  useLazyQuery,
  useQuery
} from "@apollo/client";
import { QUERY_USER, QUERY_USER_BY_EMAIL } from "./Queries";
import { UseQueryOptions } from "../types";
import {
  IUserByEmail,
  IUserByEmailVariables
} from "../Shared/Api/IUserByEmail";
import { IUser, IUserVariables } from "../Shared/Api/IUser";

// Queries
export const useUserQuery = (id: GUID | null, options?: UseQueryOptions) => {
  return useQuery<IUser, IUserVariables>(
    QUERY_USER,
    Object.assign({}, { variables: { id } }, { ...options })
  );
};

export const useLazyUserQuery = (
  options?: LazyQueryHookOptions<IUser, IUserVariables>
) => {
  return useLazyQuery<IUser, IUserVariables>(QUERY_USER, options);
};

export const useUserByEmailQuery = (
  variables: IUserByEmailVariables,
  options?: UseQueryOptions
) => {
  return useQuery<IUserByEmail, IUserByEmailVariables>(
    QUERY_USER_BY_EMAIL,
    Object.assign(
      {},
      { variables, fetchPolicy: "no-cache" },
      { ...options }
    ) as QueryFunctionOptions<IUserByEmail, IUserByEmailVariables>
  );
};
