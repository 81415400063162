import moment from "moment";
import { CollectionMethodEnum, TrialResultEnum } from "./Api/globalTypes";
import { TrialFragment } from "./Api/TrialFragment";

export class IntervalHelper {
  static getTimeLabel(interval: number, seconds: number) {
    const minutes = interval % 60 === 0;
    const secondsPerHour = 60 * 60;
    const format = minutes
      ? "H:mm"
      : seconds >= secondsPerHour
      ? "H:mm:ss"
      : "mm:ss";
    return moment("2015-01-01").startOf("day").seconds(seconds).format(format);
  }
  static getTrialsElapsed(
    startedAt: moment.Moment,
    sampleTime: number,
    totalTrials: number,
    attemptedOverride: number | null
  ) {
    if (!startedAt.isValid()) {
      return 0;
    }
    const secondsSinceStart = moment().diff(startedAt, "seconds");
    let elapsed = Math.floor(secondsSinceStart / sampleTime) + 1;
    if (elapsed > totalTrials || attemptedOverride != null) {
      return totalTrials;
    }
    return elapsed;
  }
  static getTotalCorrect(
    method: CollectionMethodEnum,
    attemptedOverride: number | null,
    correctOverride: number | null,
    defaultResult: TrialResultEnum,
    trials: TrialFragment[],
    trialsElapsed: number
  ) {
    if (
      method === CollectionMethodEnum.PAPER &&
      correctOverride != null
    ) {
      return correctOverride;
    }
    const unrecorded = trialsElapsed - trials.length;
    let totalCorrect = trials.filter(t => t.result === TrialResultEnum.PLUS)
      .length;
    let totalApplicable = trials.filter(
      t =>
        t.result !== TrialResultEnum.NOT_APPLICABLE &&
        t.result !== TrialResultEnum.NONE
    ).length;
    if (defaultResult === TrialResultEnum.PLUS) {
      totalCorrect +=
        attemptedOverride != null
          ? attemptedOverride - totalApplicable
          : unrecorded;
    }
    return totalCorrect;
  }
  static getTotalAttempted(
    method: CollectionMethodEnum,
    attemptedOverride: number | null,
    defaultResult: TrialResultEnum,
    trials: TrialFragment[],
    trialsElapsed: number
  ) {
    if (
      method === CollectionMethodEnum.PAPER &&
      attemptedOverride != null
    ) {
      return attemptedOverride;
    }
    const unrecorded = trialsElapsed - trials.length;
    let totalApplicable = trials.filter(
      t =>
        t.result !== TrialResultEnum.NOT_APPLICABLE &&
        t.result !== TrialResultEnum.NONE
    ).length;
    if (defaultResult !== TrialResultEnum.NOT_APPLICABLE) {
      totalApplicable =
        attemptedOverride != null
          ? attemptedOverride
          : totalApplicable + unrecorded;
    }
    return totalApplicable;
  }
  static getIntervalResult(
    resultIndex: number,
    attemptedOverride: number | null,
    correctOverride: number | null,
    defaultResult: TrialResultEnum,
    trials: TrialFragment[]
  ) {
    // Handle results entered via Enter Score
    if (!!correctOverride && !!attemptedOverride) {
      if (resultIndex < correctOverride) {
        return TrialResultEnum.PLUS;
      }
      if (resultIndex < attemptedOverride) {
        return TrialResultEnum.MINUS;
      }
      return TrialResultEnum.NOT_APPLICABLE;
    }
    const trial = trials.find(it => it.index === resultIndex);
    if (trial != null) {
      // return recorded result
      return trial.result;
    }
    if (
      attemptedOverride == null ||
      defaultResult === TrialResultEnum.NOT_APPLICABLE
    ) {
      // not ended early or default is N/A, return unrecorded default
      return defaultResult;
    }

    // Handle end program unrecorded values for +/- defaults
    const allRecorded = trials.filter(
      t =>
        t.result !== TrialResultEnum.NONE &&
        t.result !== TrialResultEnum.NOT_APPLICABLE
    );
    const priorRecorded = allRecorded.filter(t => (t.index ?? 0) < resultIndex);
    const totalUnrecorded = attemptedOverride - allRecorded.length;
    const priorUnrecorded = resultIndex - priorRecorded.length;
    if (priorUnrecorded < totalUnrecorded) {
      // Before program ended return default
      return defaultResult;
    } else {
      // After program ended return N/A
      return TrialResultEnum.NOT_APPLICABLE;
    }
  }
  static toggleResult(result?: TrialResultEnum) {
    switch (result) {
      case TrialResultEnum.PLUS:
        return TrialResultEnum.MINUS;
      case TrialResultEnum.MINUS:
        return TrialResultEnum.NOT_APPLICABLE;
      default:
        return TrialResultEnum.PLUS;
    }
  }
}
