import {
  Grid,
  Row,
  Illustration,
  Heading,
  Col,
  Link,
  TextList,
  TextListItem
} from "@raudabaugh/thread-ui";
import React from "react";
import welcomeImage from "./undraw_welcome_cats_thqn.svg";

export const StaffOnboardingComplete = () => {
  return (
    <Grid type="flex" height="100vh" width="100%" color="default" variation={1}>
      <Row padding="0 0 32px 0">
        <Illustration src={welcomeImage} topOffset={45} />
      </Row>
      <Row type="flex">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={24} md={20} lg={16} xl={12}>
          <Row padding="16px">
            <Heading level={3}>You're all set!</Heading>
          </Row>
          <Row padding="0 16px">
            <Heading level={5} margin="8px 0">
              Thank you for setting up your Thread Learning account.
            </Heading>
            <Heading level={5} margin="8px 0">
              To access Thread Learning from your browser, visit{" "}
              <Link href="https://thread.centralreach.com">
                thread.centralreach.com
              </Link>
              {". "}
              For mobile apps, please visit the{" "}
              <Link href="https://play.google.com/store/apps/details?id=com.centralreach.thread.xamarin">
                Google Play Store (Android)
              </Link>{" "}
              and the{" "}
              <Link href="https://apps.apple.com/us/app/thread-learning/id1137677349">
                Apple App Store (iPhone &amp; iPad)
              </Link>
              .
            </Heading>
            <Heading level={5} margin="8px 0">
              To learn how to use Thread Learning, check out the following
              resources or visit our{" "}
              <Link href="https://help.threadlearning.com/">help site</Link> for
              helpful tutorials.
            </Heading>
            <TextList>
              <TextListItem>
                <Link href="https://institute.centralreach.com/learn/course/thread-administrator-training/administrator-training/administrator-training?client=centralreach-employee-portal">
                  Administrator Training
                </Link>
              </TextListItem>
              <TextListItem>
                <Link href="https://institute.centralreach.com/learn/course/thread-web-training/supervisor-training/supervisor-training?client=centralreach-employee-portal">
                  Supervisor Training
                </Link>
              </TextListItem>
              <TextListItem>
                <Link href="https://institute.centralreach.com/learn/course/thread-instructor-training/instructor-training/thread-instructor-training?client=centralreach-employee-portal">
                  Instructor Training
                </Link>
              </TextListItem>
            </TextList>
          </Row>
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
      </Row>
    </Grid>
  );
};
