import React, { useContext } from "react";
import { IThreadContext } from "../Shared/IThreadContext";

export const ThreadContext = React.createContext<{
  state?: IThreadContext;
}>({});

export const useThreadContext = () => {
  const { state } = useContext(ThreadContext);
  return { threadUserContext: state as IThreadContext };
};
