import { ApolloCache } from "@apollo/client";
import { FRAGMENT_PROGRAM_SESSION } from "./Fragments";
import { ProgramSessionFragment } from "../Shared/Api/ProgramSessionFragment";

export class ProgramDataHelper {
  public static readProgramSession(
    cache: ApolloCache<object>,
    sessionId: string,
    optimistic: boolean = true
  ) {
    let session = cache.readFragment<ProgramSessionFragment>({
      fragment: FRAGMENT_PROGRAM_SESSION,
      fragmentName: "ProgramSessionFragment",
      id: `ProgramSessionType:${sessionId}`,
      optimistic
    });
    return session;
  }
}
