import React from "react";
import { FormItem, Heading, Media, Row } from "@raudabaugh/thread-ui";

interface ITrialDataFormItemProps {
  required: boolean;
  label: string;
  validationMessage?: string;
  children: React.ReactNode;
}

export const TrialDataFormItem = (props: ITrialDataFormItemProps) => {
  const formItemLayout = {
    labelCol: {
      sm: { span: 5 },
      xs: { span: 24 }
    },
    wrapperCol: {
      sm: { span: 19 },
      xs: { span: 24 }
    }
  };

  return (
    <FormItem required={props.required} label={props.label} {...formItemLayout}>
      <Media.Sm orLarger>
        <Row margin="4px 0 0 0">{props.children}</Row>
      </Media.Sm>
      <Media.Xs>{props.children}</Media.Xs>
      {props.validationMessage && (
        <Heading color="error" level={5}>
          {props.validationMessage}
        </Heading>
      )}
    </FormItem>
  );
};
