export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/g;
  return regex.test(password);
};

export const validatePasswordLength = (password: string) => {
  const regex = /^[A-Za-z\d@$!%*?&]{6,}$/g;
  return regex.test(password);
};

export const validatePasswordSymbol = (password: string) => {
  const regex = /^.*[@$!%*?&]{1,}.*$/g;
  return regex.test(password);
};

export const validatePasswordDigit = (password: string) => {
  const regex = /^.*[\d]{1,}.*$/g;
  return regex.test(password);
};

export const validatePasswordUpper = (password: string) => {
  const regex = /^.*[A-Z]{1,}.*$/g;
  return regex.test(password);
};

export const validatePasswordLower = (password: string) => {
  const regex = /^.*[a-z]{1,}.*$/g;
  return regex.test(password);
};
