import React from "react";
import { Col, Button, Modal, Paragraph, Link } from "@raudabaugh/thread-ui";
import { AuthenticationService } from "./AuthenticationService";
import { useIntercom } from "react-use-intercom";
import { IThreadContext } from "Shared/IThreadContext";
import { XamarinHelper } from "Shared/XamarinHelper";

interface ILicenseExpiredModalProps {
  context?: IThreadContext;
}

export const LicenseExpiredModal = (props: ILicenseExpiredModalProps) => {
  const { show, boot } = useIntercom();
  if (!XamarinHelper.insideXamarin()) {
    boot({
      hideDefaultLauncher: true,
      name: props.context?.userName,
      userId: props.context?.userId,
      email: props.context?.userEmail,
      company: {
        companyId: props.context?.orgId ?? "",
        name: props.context?.orgName
      },
      customAttributes: { org_type: props.context?.orgType }
    });
  }
  const handleSupportClick = () => {
    if (XamarinHelper.insideXamarin()) {
      XamarinHelper.supportChat();
    } else {
      show();
    }
  };
  return (
    <Modal
      title={"Sorry, this subscription has expired"}
      okText="Sign Out"
      closable={false}
      visible
      footer={
        <Col padding={"4px"}>
          <Button
            type={"primary"}
            onClick={() => {
              const authService = AuthenticationService.getInstance();
              authService.logout();
            }}
          >
            Sign Out
          </Button>
        </Col>
      }
    >
      <Paragraph>
        We hope you've enjoyed using Thread! To upgrade to a paid account,
        please{" "}
        <Link
          onClick={() =>
            window.open(
              "https://go.centralreach.com/mktg/thread/freetrial",
              "_blank"
            )
          }
        >
          contact sales
        </Link>
        .
      </Paragraph>
      <Paragraph>
        If you have received this message in error, please{" "}
        <Link onClick={handleSupportClick}>contact support</Link>.
      </Paragraph>
    </Modal>
  );
};
