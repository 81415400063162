import { Row, Heading, Col, Card, Icon } from "@raudabaugh/thread-ui";
import React from "react";

export interface ILargeCardLayoutProps {
  title: string;
  actions: React.ReactNode;
  caption?: React.ReactNode;
  targets?: React.ReactNode;
  children: React.ReactNode;
  onShowFocusDrawer?: () => void;
  onPopup: () => void;
}

export const LargeCardLayout = (props: ILargeCardLayoutProps) => {
  return (
    <Card color="primary" variation={8} padding="0" margin="0">
      <Row
        padding="16px"
        type="flex"
        alignItems="center"
        justify="space-between"
        grow={1}
      >
        <Col
          margin="0 0 12px 0"
          xs={24}
          sm={10}
          md={12}
          lg={14}
          xl={16}
          xxl={18}
        >
          <Row
            padding="0"
            justify="space-between"
            type="flex"
            alignItems="center"
          >
            <Col
              xs={20}
              sm={24}
              onClick={
                props.onShowFocusDrawer !== undefined
                  ? props.onShowFocusDrawer
                  : () => {}
              }
            >
              <Heading
                level={5}
                maxWidth="70vw"
                color="default"
                variation={1}
                noTooltip={props.onShowFocusDrawer !== undefined}
              >
                {props.title}{" "}
                {props.onShowFocusDrawer !== undefined && (
                  <Icon type="fa-search-plus fas" />
                )}
              </Heading>
            </Col>
            <Col xs={2} sm={0}>
              <Icon
                onClick={() => props.onPopup()}
                variation={10}
                type="fa-info-circle far"
              />
            </Col>
            <Col xs={2} sm={0}>
              {props.actions}
            </Col>
          </Row>
          <Col>{props.targets}</Col>
        </Col>

        <Col
          margin="0 0 0 -16px"
          xs={24}
          sm={14}
          md={12}
          lg={10}
          xl={8}
          xxl={6}
        >
          <Row
            type="flex"
            justify="space-between"
            alignItems="center"
            align="middle"
            grow={1}
          >
            <Col margin="auto" span={5}>
              {props.caption}
            </Col>
            <Col xs={19} sm={11} md={11} lg={11} xl={11} xxl={11}>
              {props.children}
            </Col>
            <Col padding="0 0 0 48px" xs={0} sm={4}>
              <Icon
                onClick={() => props.onPopup()}
                variation={10}
                type="fa-info-circle far"
              />
            </Col>
            <Col padding="16px 24px" xs={0} sm={4}>
              {props.actions}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
