import { memo, useCallback, useMemo } from "react";
import {
  Col,
  Heading,
  Icon,
  IColumnProps,
  ITableRowSelection,
  Row,
  Table,
  Tooltip
} from "@raudabaugh/thread-ui";
import { IProgramItem } from "./Types";
import ProgramTableMenu from "./ProgramTableMenu";
import { ProgramTypeEnum } from "../Shared/Api/globalTypes";
import { LabelHelper } from "../Shared/LabelHelper";

interface IProgramTableProps {
  items: IProgramItem[];
  onAbc: (id: string) => void;
  onChart: (id: string, type: ProgramTypeEnum) => void;
  onArchive: (id: string) => void;
  onCurriculumAdd: (id: string) => void;
  onInfo: (id: string) => void;
  onEdit?: (id: string, type: ProgramTypeEnum) => void;
  onEnterScore: (id: string) => void;
  onToggleProgram: (id: string, active: boolean) => void;
}

const ProgramTable = ({
  items,
  onAbc,
  onArchive,
  onCurriculumAdd,
  onChart,
  onInfo,
  onEdit,
  onEnterScore,
  onToggleProgram
}: IProgramTableProps) => {
  const rowKey = useCallback((record: IProgramItem) => record.programId, []);
  const columns: Array<IColumnProps<IProgramItem>> = useMemo(() => {
    // without this ant Table re-renders cell contents way too often
    const shouldCellUpdate = (record: IProgramItem, prevRecord: IProgramItem) =>
      record !== prevRecord;

    return [
      {
        dataIndex: "name",
        title: "Program title",
        render: (text, record) => {
          return (
            <>
              <Heading
                color="primary"
                level={5}
                weight="medium"
                maxWidth="40vw"
                noTooltip
              >
                {record.pinned && (
                  <Icon
                    color="default"
                    variation={6}
                    type="fa-thumbtack fas"
                    margin="0 8px 0 0"
                  />
                )}
                {record.name}
              </Heading>
              {record.targets.length > 0 && (
                <Heading
                  color="default"
                  level={7}
                  maxWidth="40vw"
                  variation={4}
                  noTooltip
                >
                  {record.targets.join(", ")}
                </Heading>
              )}
            </>
          );
        },
        shouldCellUpdate,
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        dataIndex: "lastRun",
        title: "Last run",
        render: (text, record) => {
          return (
            <Row align="middle" height="100%">
              {record.locked ? (
                <Tooltip title="Program locked pending supervisor action">
                  <Col>
                    <Icon color="alert" variation={6} type="fa-lock fas" />{" "}
                    Locked
                  </Col>
                </Tooltip>
              ) : (
                <Col>
                  {record.lastRun
                    ? LabelHelper.relativeDateLabel(record.lastRun)
                    : "Never"}
                </Col>
              )}
            </Row>
          );
        },
        shouldCellUpdate,
        sorter: (a, b) => {
          // sort locked first ascending
          if (a.locked !== b.locked) {
            return a.locked ? -1 : 1;
          }

          // sort nulls last ascending
          if (a.lastRun) {
            if (b.lastRun) {
              return b.lastRun.diff(a.lastRun);
            }
            return -1;
          }
          return 1;
        }
      },
      {
        key: "buttons",
        title: "",
        align: "right",
        render: (_, record) => {
          return (
            <ProgramTableMenu
              item={record}
              onAbc={onAbc}
              onArchive={onArchive}
              onCurriculumAdd={
                record.copyProtected ? undefined : onCurriculumAdd
              }
              onChart={onChart}
              onEnterScore={onEnterScore}
              onEdit={onEdit}
              onInfo={onInfo}
            />
          );
        },
        shouldCellUpdate
      }
    ];
  }, [
    onAbc,
    onArchive,
    onChart,
    onEnterScore,
    onEdit,
    onInfo,
    onCurriculumAdd
  ]);

  const onRow = useCallback(
    (record: IProgramItem, index: number) => {
      return {
        // to support selection based on whole row, install a click handler
        onClick: (e: Event) => {
          e.preventDefault();
          onToggleProgram(record.programId, record.checked);
        }
      };
    },
    [onToggleProgram]
  );

  const rowSelection: ITableRowSelection<IProgramItem> = useMemo(() => {
    return {
      hideSelectAll: true,
      onSelect: (record, selected, selectedRows, event) => {
        onToggleProgram(record.programId, record.checked);
      },
      selectedRowKeys: items.filter(p => p.checked).map(p => p.programId)
    };
  }, [items, onToggleProgram]);

  return (
    <Table
      columns={columns}
      dataSource={items}
      onRow={onRow}
      pagination={false}
      rowKey={rowKey}
      rowSelection={rowSelection}
    />
  );
};

export default memo(ProgramTable);
