import React, { useCallback, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { RouterHelper } from "./RouterHelper";
import { NonProgramTypeEnum } from "../StudentPrograms/StudentPrograms";
import { RoutePathEnum } from "./RoutePathEnum";
import { DefaultStudent } from "./DefaultStudent";
import { useStudentContext } from "./StudentContextHook";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { DataCollection } from "StudentPrograms/DataCollection";
import { ProgramEdit } from "StudentPrograms/ProgramEdit";
import { ProgramCreate } from "StudentPrograms/ProgramCreate";
import { ProgramEditHistorical } from "StudentPrograms/ProgramEditHistorical";
import { LabelHelper } from "Shared/LabelHelper";

// Data Collection

interface DataCollectionRouterProps {}

interface IDataCollectionParams {
  curriculumId: string;
  folderId: string;
  templateId: string;
  type: string;
  programId: string;
  phaseId: string;
}

export const DataCollectionRouter = (props: DataCollectionRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM} exact={true}>
        <ProgramEditById />
      </Route>
      <Route
        path={RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM_PHASE}
        exact={true}
      >
        <PhaseEditById />
      </Route>
      <Route path={RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM} exact={true}>
        <ProgramNewByType />
      </Route>
      <Route path={RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS} exact={true}>
        <DataStudent inSession={false} />
      </Route>
      <Route path={RoutePathEnum.DATA_COLLECTION_SESSION} exact={true}>
        <DataStudent inSession={true} />
      </Route>
      <Route path={RoutePathEnum.DATA_COLLECTION}>
        <DefaultStudent path={RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS} />
      </Route>
    </Switch>
  );
};

const DataStudent = (props: { inSession: boolean }) => {
  const { threadUserContext } = useThreadContext();
  const canEditPrograms = findPermission(
    threadUserContext.role,
    Permissions.EDIT_PROGRAMS
  );
  const history = useHistory();
  const { studentId, setStudentId } = useStudentContext();

  const handleProgramMenuItems = useCallback(
    (studentId: string, type: NonProgramTypeEnum) => {
      switch (type) {
        case NonProgramTypeEnum.CREATE:
          RouterHelper.redirectToProgramCreate(studentId, history);
          break;
        case NonProgramTypeEnum.ARCHIVE:
          RouterHelper.redirectToArchive(
            studentId,
            history,
            false,
            LabelHelper.routePathLabel(
              RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS
            )
          );
          break;
        case NonProgramTypeEnum.CURRICULUM:
          RouterHelper.redirectToCurriculumLibrary(history);
          break;
        default:
          RouterHelper.redirectToProgramCreate(studentId, history);
          break;
      }
    },
    [history]
  );

  const handleStudentChange = useCallback(
    (studentId: string, inSession: boolean) =>
      inSession
        ? RouterHelper.handleStudentChange(
            RoutePathEnum.DATA_COLLECTION_SESSION,
            studentId,
            history,
            true
          )
        : RouterHelper.handleStudentChange(
            RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS,
            studentId,
            history,
            true
          ),
    [history]
  );
  const handleProgramEdit = useCallback(
    (programId, type) =>
      canEditPrograms
        ? RouterHelper.redirectToProgramEdit(
            studentId || "",
            programId,
            type,
            history
          )
        : undefined,
    [canEditPrograms, history, studentId]
  );

  const handleProgramMenuItemClick = useCallback(
    type =>
      canEditPrograms
        ? handleProgramMenuItems(studentId || "", type)
        : undefined,
    [canEditPrograms, handleProgramMenuItems, studentId]
  );

  const handleAbc = useCallback(
    programId =>
      RouterHelper.redirectToAbcDataViewWithProgram(
        studentId || "",
        programId,
        history
      ),
    [history, studentId]
  );

  const handleChart = useCallback(
    programId =>
      RouterHelper.redirectToChart(
        studentId || "",
        programId,
        history,
        false,
        LabelHelper.routePathLabel(RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS)
      ),
    [history, studentId]
  );

  const handleCurriculumAdd = useCallback(
    programId =>
      RouterHelper.redirectToCurriculumAddProgram(
        threadUserContext.userId,
        programId,
        LabelHelper.routePathLabel(
          RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS
        ) ?? "",
        history
      ),
    [history, threadUserContext.userId]
  );

  const handleOpenSession = useCallback(
    () => RouterHelper.redirectToSession(studentId || "", history),
    [history, studentId]
  );

  const handleCloseSession = useCallback(() => history.goBack(), [history]);

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <DataCollection
        inSession={props.inSession}
        studentId={studentId}
        onStudentChange={handleStudentChange}
        onProgramEdit={handleProgramEdit}
        onProgramMenuItemClick={handleProgramMenuItemClick}
        onAbc={handleAbc}
        onChart={handleChart}
        onCurriculumAdd={handleCurriculumAdd}
        onOpenSession={handleOpenSession}
        onCloseSession={handleCloseSession}
      />
    );
  } else {
    return <Redirect to={{ pathname: RoutePathEnum.DEFAULT }} />;
  }
};

const ProgramEditById = () => {
  const { threadUserContext } = useThreadContext();
  const { programId } = useParams<IDataCollectionParams>();
  const history = useHistory();
  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(() => history.goBack(), [history]);

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (
    studentId &&
    findPermission(threadUserContext.role, Permissions.EDIT_PROGRAMS)
  ) {
    return (
      <ProgramEdit
        programId={programId}
        studentId={studentId}
        onClose={handleClose}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const PhaseEditById = () => {
  const { threadUserContext } = useThreadContext();
  const { phaseId, programId } = useParams<IDataCollectionParams>();
  const history = useHistory();
  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(() => history.goBack(), [history]);

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (
    studentId &&
    findPermission(threadUserContext.role, Permissions.EDIT_PROGRAMS)
  ) {
    return (
      <ProgramEditHistorical
        phaseId={phaseId}
        programId={programId}
        studentId={studentId}
        onClose={handleClose}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const ProgramNewByType = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { templateId, curriculumId } = useParams<IDataCollectionParams>();

  const { studentId, setStudentId } = useStudentContext();

  const handleClose = useCallback(
    (folderId: string | null) => history.goBack(),
    [history]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (
    studentId &&
    findPermission(threadUserContext.role, Permissions.EDIT_PROGRAMS)
  ) {
    return (
      <ProgramCreate
        studentId={studentId}
        curriculumId={curriculumId}
        templateId={templateId}
        onClose={handleClose}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};
