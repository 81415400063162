import {
  Button,
  Grid,
  Heading,
  Illustration,
  Row
} from "@raudabaugh/thread-ui";
import React, { useCallback } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { StaffRoleEnum } from "Shared/Api/globalTypes";
import { OfflinePageUnavailable } from "./OfflinePageUnavailable";
import { useStudentContext } from "./StudentContextHook";
import connectingImage from "Shared/Images/undraw-connecting-teams-3-1-pgn.svg";
import { RouterHelper } from "./RouterHelper";

const StudentNotFound = () => {
  const { studentId, clearStudentId } = useStudentContext();
  if (studentId) {
    clearStudentId();
  }
  return (
    <Grid height="100vh">
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} />
      </Row>
      <Row padding="0 16px">
        <Heading level={3}>
          Uh oh, the student you are trying to access is no longer available.
        </Heading>
      </Row>
      <Row padding="8px 16px">
        <Heading level={5}>
          Please check your student assignments in your Thread profile.
        </Heading>
      </Row>
    </Grid>
  );
};

const SorryNoStudents = () => {
  const history = useHistory();

  const handleTryAgainClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      RouterHelper.redirectToDataCollection(history);
    },
    [history]
  );

  return (
    <Grid height="100vh">
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} />
      </Row>
      <Row padding="0 16px">
        <Heading level={3}>Uh oh, you have no students set up!</Heading>
      </Row>
      <Row padding="8px 16px">
        <Heading level={5}>
          Please ask your supervisor or internal administrator to assign
          students to your Thread profile.
        </Heading>
      </Row>
      <Row padding="16px">
        <Button onClick={handleTryAgainClick} type="primary">
          Try Again
        </Button>
      </Row>
    </Grid>
  );
};

const RedirectToDefault = () => {
  const { threadUserContext } = useThreadContext();

  if (threadUserContext && threadUserContext.role === StaffRoleEnum.ADMIN) {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.PEOPLE_MANAGEMENT,
          state: {
            sideMenu: RoutePathEnum.PEOPLE_MANAGEMENT,
            current: RoutePathEnum.PEOPLE_MANAGEMENT
          }
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

interface DefaultRouterProps {}

export const DefaultRouter = (props: DefaultRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.ERROR_NO_STUDENTS} exact={true}>
        <SorryNoStudents />
      </Route>
      <Route path={RoutePathEnum.ERROR_STUDENT_NOT_FOUND} exact={true}>
        <StudentNotFound />
      </Route>
      <Route path={RoutePathEnum.ERROR_OFFLINE_PAGE_UNAVAILABLE} exact={true}>
        <OfflinePageUnavailable />
      </Route>
      <Route path={RoutePathEnum.DEFAULT}>
        <RedirectToDefault />
      </Route>
    </Switch>
  );
};
