import React from "react";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { AbcDataForm } from "./AbcDataForm";
import {
  useAbcDataByStudentQuery,
  useAbcDataUpdateMutation
} from "../DataAccess/AbcDataData";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ABCDataInput, Permissions } from "Shared/Api/globalTypes";
interface IEditAbcDataProps {
  studentId: string;
  dataId: string;
  programId?: string;
  onError: (error: Error) => void;
  onSuccess: (title: string, description: string) => void;
}

export const EditAbcData = (props: IEditAbcDataProps) => {
  const { threadUserContext } = useThreadContext();
  const canReadPermission = findPermission(
    threadUserContext!.role,
    Permissions.VIEW_ABC_DATA
  );

  const hasEditAbcDataPermission = findPermission(
    threadUserContext!.role,
    Permissions.EDIT_ABC_DATA
  );

  const {
    loading: abcDataLoading,
    error: abcDataError,
    data: abcData
  } = useAbcDataByStudentQuery(props.studentId, {
    skip: !canReadPermission
  });

  const { abcDataUpdate } = useAbcDataUpdateMutation();
  const updateAbcData = (input: ABCDataInput) => {
    abcDataUpdate(props.dataId, props.studentId, input)
      .then(() => {
        props.onSuccess("ABC Data saved", "ABC Data successfully saved.");
      })
      .catch((error: Error) => {
        props.onError(error);
      });
  };

  if (abcDataError) {
    props.onError(abcDataError);
  }

  if (abcDataLoading) {
    return <LoadingScreen loading={true} />;
  }

  const data = abcData?.aBCDataByStudent?.find(a => a.id === props.dataId);
  if (!data) {
    props.onError(new Error("ABC Data not found"));
    return <></>;
  }

  const canEdit =
    data.createdById === threadUserContext.userId || hasEditAbcDataPermission;

  return (
    <>
      <AbcDataForm
        canEdit={canEdit}
        onSave={updateAbcData}
        existingData={data}
        studentId={props.studentId}
        programId={props.programId}
      />
    </>
  );
};
