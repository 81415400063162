import {
  Button,
  Card,
  Col,
  Grid,
  Heading,
  Row,
  Steps,
  Step,
  Progress,
  Modal,
  Paragraph
} from "@raudabaugh/thread-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useProgramCreateMutation } from "../DataAccess/ProgramData";
import { OrganizationSettingsEnum } from "../App";
import DiscardChangesModal from "Shared/DiscardChangesModal";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { ApolloError } from "@apollo/client";
import { SettingsHelper } from "../Shared/SettingsHelper";
import { LabelHelper } from "../Shared/LabelHelper";
import {
  useCurriculumQuery,
  useCurriculumTemplateQuery
} from "../DataAccess/CurriculumData";
import {
  CriterionInput,
  CurriculumTypeEnum,
  DrawerEnum,
  ProgramInput,
  ProgramTypeEnum,
  StepInput,
  TargetInput,
  TargetStateEnum,
  TrialResultEnum
} from "../Shared/Api/globalTypes";
import { NotificationsHelper } from "../Shared/NotificationsHelper";
import { StudentFragment } from "../Shared/Api/StudentFragment";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { PromptHelper } from "../Shared/PromptHelper";
import { ProgramTabEnum } from "./ProgramTabEnum";
import { ProgramDetails } from "./ProgramDetails";
import { IProgramFormStep } from "./Types";
import _ from "lodash";
import { UnitDetails } from "./UnitDetails";
import { PhaseDetails } from "./PhaseDetails";
import { Targets } from "./Targets";
import { RouterHelper } from "Routes/RouterHelper";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import { useHistory } from "react-router-dom";

export interface IProgramCreateProps {
  onClose: (folderId: string | null) => void;
  studentId?: string;
  templateId?: string;
  curriculumId?: string;
}

export const ProgramCreate = (props: IProgramCreateProps) => {
  const { programCreate } = useProgramCreateMutation();
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [program, setProgram] = useState<ProgramInput>({});
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const student = threadUserContext.assignedStudents?.find(
    s => s.studentId === props.studentId
  )?.student;
  const [students, setStudents] = useState<StudentFragment[]>(
    student ? [student] : []
  );
  const studentName = student?.firstName ?? "Student";
  const [parentId, setParentId] = useState<string | null>();
  const [currentStep, setCurrentStep] = useState(0);
  const [valid, setValid] = useState({
    PHASE_DETAILS: false,
    PROGRAM_DETAILS: false,
    TARGETS: false,
    UNIT_DETAILS: false
  });
  const [showValidation, setShowValidation] = useState(false);

  const { data: templateData } = useCurriculumTemplateQuery(
    props.curriculumId || "",
    props.templateId || "",
    {
      skip: !(props.curriculumId && props.templateId)
    }
  );

  const { data: curriculumData } = useCurriculumQuery(
    program.curriculumId || "",
    {
      skip: !program.curriculumId
    }
  );

  useEffect(() => {
    if (templateData) {
      const template = templateData!.curriculumTemplate!;
      const phase: ProgramInput = {
        programType: template.programType,
        drawer: template.drawer,
        pinned: template.pinned,
        baselineText: template.baselineText,
        definitionOfBehavior: template.definitionOfBehavior,
        instructionalCue: template.instructionalCue,
        programGoal: template.programGoal,
        materials: template.materials,
        promptingProcedure: template.promptingProcedure,
        tips: template.tips,
        unitGoal: template.unitGoal,
        programName: template.programName,
        prerequisiteSkillsNeeded: template.prerequisiteSkillsNeeded,
        procedureDetails: template.procedureDetails,
        numberOfTrials: template.numberOfTrials
          ? template.numberOfTrials
          : SettingsHelper.maxNumberOfTrials(threadUserContext),
        minimumRequiredTrials: template.minimumRequiredTrials
          ? template.minimumRequiredTrials
          : SettingsHelper.minNumberOfTrials(threadUserContext),
        frequencyOfDataCollectionText: template.frequencyOfDataCollectionText,
        lengthOfEachInterval: template.lengthOfEachInterval,
        intervalType: template.intervalType,
        defaultTrialResult: template.defaultTrialResult,
        errorless: template.errorless,
        templateId: template.id,
        curriculumId: props.curriculumId,
        reinforcementRatio: template.reinforcementRatio
          ? template.reinforcementRatio
          : SettingsHelper.reinforcementRatio(threadUserContext),
        reinforcementSchedule: template.reinforcementSchedule
          ? template.reinforcementSchedule
          : SettingsHelper.reinforcementStrategy(threadUserContext),
        unlimitedTrials: template.unlimitedTrials
          ? template.unlimitedTrials
          : SettingsHelper.unlimitedTrials(threadUserContext)
      };
      const stepList: StepInput[] = [];
      const targetList: TargetInput[] = [];
      template.futureTargets.forEach(target => {
        const targetInput: TargetInput = {
          id: target.id,
          targetDescription: target.targetDescription,
          mastered: null,
          state: TargetStateEnum.FUTURE,
          completed: false
        };
        targetList.push(targetInput);
      });
      const criterionList: CriterionInput[] = [];
      template.criterionForMastery.forEach(criterion => {
        const criterionInput: CriterionInput = {
          id: criterion.id,
          minPercentage: criterion.minPercentage,
          pointsAnalyzed: criterion.pointsAnalyzed
        };
        criterionList.push(criterionInput);
      });

      phase.criterionForMastery =
        criterionList.length > 0
          ? criterionList
          : SettingsHelper.criterion(threadUserContext);

      phase.steps = stepList;
      phase.targets = targetList;
      setProgram(phase);
      setLoading(false);
      setParentId(template.parentId);
    }
  }, [templateData, props.curriculumId, threadUserContext]);

  useEffect(() => {
    const buildPhaseDefaults = (): ProgramInput => {
      return {
        programType: null,
        drawer: DrawerEnum.PRIVATE_DRAWER,
        pinned: false,
        phaseSummary: "",
        baselineText: "",
        frequencyOfDataCollectionText: "",
        criterionForMasteryText: "",
        criterionForMastery: SettingsHelper.criterion(threadUserContext),
        targets: [],
        defaultTrialResult: TrialResultEnum.NOT_APPLICABLE,
        definitionOfBehavior: "",
        instructionalCue: "",
        errorless: SettingsHelper.boolean(
          threadUserContext,
          OrganizationSettingsEnum.DEFAULT_ERRORLESS
        ),
        programGoal: "",
        procedureDetails: "",
        materials: "",
        promptingProcedure: "",
        tips: "",
        prompt: SettingsHelper.boolean(
          threadUserContext,
          OrganizationSettingsEnum.DEFAULT_ERRORLESS
        )
          ? undefined
          : PromptHelper.PROMPT_INDEPENDENT,
        reinforcementRatio:
          SettingsHelper.reinforcementRatio(threadUserContext),
        reinforcementSchedule:
          SettingsHelper.reinforcementStrategy(threadUserContext),
        typeOfReinforcement: "",
        lengthOfEachInterval: null,
        unitGoal: "",
        steps: [],
        programName: "",
        numberOfTrials: SettingsHelper.maxNumberOfTrials(threadUserContext),
        unlimitedTrials: SettingsHelper.unlimitedTrials(threadUserContext),
        minimumRequiredTrials:
          SettingsHelper.minNumberOfTrials(threadUserContext)
      };
    };
    if (!props.templateId) {
      setProgram(buildPhaseDefaults());
      setLoading(false);
    }
  }, [props.templateId, studentName, threadUserContext]);

  const handleCancel = () => {
    if (saveNeeded) {
      setCancelAlertOpen(true);
    } else {
      props.onClose(parentId ?? null);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleContinue = () => {
    const key = steps[currentStep].key;
    if (!valid[key]) {
      NotificationsHelper.BasicErrorNotification(
        "Missing Required Info",
        "Please review sections marked in red for incomplete information before submitting."
      );
      setShowValidation(true);
      return;
    }
    if (currentStep + 1 < steps.length) {
      setShowValidation(false);
      setCurrentStep(currentStep + 1);
      return;
    }
    save(true, undefined);
  };

  const closeCancelAlert = () => {
    setCancelAlertOpen(false);
  };

  const closeCancelAlertAndDialog = () => {
    props.onClose(parentId ?? null);
  };

  const handleProgramChange = (program: ProgramInput) => {
    setProgram(program);
    setSaveNeeded(true);
  };

  const handleStudentChange = (students: StudentFragment[]) => {
    setStudents(students);
  };

  const handleValidation = (tab: ProgramTabEnum, value: boolean) => {
    const clone = _.cloneDeep(valid);
    if (clone[tab] !== value) {
      clone[tab] = value;
      setValid(clone);
    }
  };

  const handleStepClick = (step: IProgramFormStep) => {
    const clickedStep = steps.indexOf(step);
    if (clickedStep < currentStep) {
      setCurrentStep(clickedStep);
    }
  };

  const handleTemplateClick = useCallback(() => {
    if (templateData) {
      if (
        curriculumData?.curriculum?.type === CurriculumTypeEnum.USER &&
        curriculumData?.curriculum.id !== threadUserContext.userId
      ) {
        Modal.info({
          okCancel: true,
          title: `${curriculumData?.curriculum?.name} - ${templateData.curriculumTemplate?.programName}`,
          content: "This is a private curriculum. Please contact the user.",
          okText: "OK"
        });
      } else {
        RouterHelper.redirectToCurriculumViewTemplate(
          program.curriculumId!,
          program.templateId!,
          LabelHelper.routePathLabel(
            RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM
          )!,
          history,
          false
        );
      }
    }
  }, [templateData, program, history, curriculumData, threadUserContext]);

  const filterTypeSpecificFields = () => {
    const clone = _.cloneDeep(program);
    switch (program.programType) {
      case ProgramTypeEnum.DURATION:
        clone.unlimitedTrials = null;
        clone.numberOfTrials = null;
        clone.minimumRequiredTrials = null;
        clone.criterionForMastery = [];
        clone.criterionForMasteryText = null;
        clone.steps = [];
        clone.errorless = null;
        clone.lengthOfEachInterval = null;
        clone.defaultTrialResult = TrialResultEnum.NONE;
        break;
      case ProgramTypeEnum.FREQUENCY:
        clone.unlimitedTrials = null;
        clone.numberOfTrials = null;
        clone.minimumRequiredTrials = null;
        clone.criterionForMastery = [];
        clone.criterionForMasteryText = null;
        clone.steps = [];
        clone.errorless = null;
        clone.lengthOfEachInterval = null;
        clone.defaultTrialResult = TrialResultEnum.NONE;
        break;
      case ProgramTypeEnum.INTERVAL:
        clone.unlimitedTrials = null;
        clone.steps = [];
        clone.errorless = null;
        break;
      case ProgramTypeEnum.DTT:
        clone.steps = [];
        clone.lengthOfEachInterval = null;
        clone.defaultTrialResult = TrialResultEnum.NONE;
        break;
      case ProgramTypeEnum.TASK_ANALYSIS:
        clone.unlimitedTrials = null;
        clone.numberOfTrials = null;
        clone.minimumRequiredTrials = null;
        clone.errorless = null;
        clone.lengthOfEachInterval = null;
        clone.defaultTrialResult = TrialResultEnum.NONE;
        break;
    }

    // Filter deleted steps and targets before creating the program
    if (clone.steps) {
      for (const step of clone.steps) {
        if (step.softDeleted) {
          clone.steps = clone.steps.filter(s => s.id !== step.id);
        }
      }
    }
    if (clone.targets) {
      for (const target of clone.targets) {
        if (target.softDeleted) {
          clone.targets = clone.targets.filter(t => t.id !== target.id);
        }
        delete target["isNew"];
      }
    }

    return clone;
  };

  const save = async (
    shouldCreateNewPhase: boolean,
    event?: React.MouseEvent<Element, MouseEvent>
  ) => {
    const input: ProgramInput = filterTypeSpecificFields();
    setSaving(true);

    try {
      for (const student of students) {
        await programCreate(student.id, undefined, input);
      }
      setSaveNeeded(false);
      props.onClose(parentId ?? null);
    } catch (error) {
      NotificationsHelper.ErrorNotification({
        error: error as ApolloError,
        title: "Action Failed"
      });
    }
    setSaving(false);
  };

  if (loading || saving) {
    return (
      <LoadingScreen
        loading={true}
        tip={saving ? "Saving changes ..." : "Loading ..."}
      />
    );
  }

  const steps: IProgramFormStep[] = [
    { key: ProgramTabEnum.PROGRAM_DETAILS, component: ProgramDetails },
    { key: ProgramTabEnum.UNIT_DETAILS, component: UnitDetails },
    { key: ProgramTabEnum.TARGETS, component: Targets },
    { key: ProgramTabEnum.PHASE_DETAILS, component: PhaseDetails }
  ];

  const renderCurrentStep = () => {
    var step = steps[currentStep];
    if (!step.component) {
      return (
        <Heading level={5} color="error">
          Step is not yet implemented.
        </Heading>
      );
    }
    var Component = step.component; // as any;
    return (
      <Component
        editing={false}
        program={program}
        students={students}
        showValidation={showValidation}
        onProgramChange={handleProgramChange}
        onStudentChange={handleStudentChange}
        onValidation={handleValidation}
      />
    );
  };

  return (
    <Grid>
      <Row type="flex" direction="column" justify="space-between" height="100%">
        <Grid margin="16px 16px 40px 16px">
          <Row>
            <Heading weight="bold" level={4}>
              New Program
            </Heading>
          </Row>
          <Row type="flex" margin="16px 0">
            <Col xs={0} md={24}>
              <Steps current={currentStep}>
                {steps.map(step => (
                  <Step
                    key={step.key}
                    onClick={() => handleStepClick(step)}
                    title={LabelHelper.programTabLabel(step.key)}
                  />
                ))}
              </Steps>
            </Col>
            <Col xs={8} md={0}>
              <Progress
                type="circle"
                width={56}
                percent={((currentStep + 1) * 100) / steps.length}
                format={() => `${currentStep + 1} of ${steps.length}`}
              />
            </Col>
            <Col xs={16} md={0}>
              <Row type="flex" justify="end">
                <Heading level={3}>
                  {LabelHelper.programTabLabel(steps[currentStep].key)}
                </Heading>
              </Row>
              {currentStep < steps.length - 1 && (
                <Row type="flex" justify="end">
                  <Heading level={5}>
                    Next:{" "}
                    {LabelHelper.programTabLabel(steps[currentStep + 1].key)}
                  </Heading>
                </Row>
              )}
            </Col>
          </Row>
          <Row>{renderCurrentStep()}</Row>
          <Card
            left="0"
            bottom="0"
            position="fixed"
            width="100%"
            margin="0"
            bordered={true}
            color="default"
            variation={4}
            padding="1px 0 0 0"
            zIndex={1}
          >
            <Row type="flex" margin="0 -5px 0 0">
              <Col
                color="navbar"
                margin="-16px 11px -12px -16px"
                grow={1}
                variation={9}
                xs={0}
                sm={0}
                md={0}
                lg={5}
                xl={4}
                xxl={4}
              />
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={19}
                xl={20}
                color="default"
                variation={1}
              >
                <Row type="flex" margin="16px" align="middle" wrap={true}>
                  {curriculumData && templateData && (
                    <>
                      <Paragraph level={6} margin="0px">
                        Modified&nbsp;from:&nbsp;
                      </Paragraph>
                      <Paragraph
                        level={6}
                        strong={true}
                        margin="0px"
                        color="primary"
                        ellipsis={true}
                        onClick={handleTemplateClick}
                      >
                        {curriculumData?.curriculum?.name} -{" "}
                        {templateData?.curriculumTemplate?.programName}
                      </Paragraph>
                    </>
                  )}
                  <Col grow={1} />
                  <Col margin="0px 12px 0px 0px">
                    <Button size="default" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                  {currentStep > 0 && (
                    <Col margin="0px 12px 0px 0px">
                      <Button onClick={handleBack} size="default">
                        Back
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      onClick={handleContinue}
                      size="default"
                      type="primary"
                    >
                      {currentStep === steps.length - 1 ? "Save" : "Continue"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Grid>
        <DiscardChangesModal
          intercept={saveNeeded}
          visible={cancelAlertOpen}
          setVisible={setCancelAlertOpen}
          onCancel={closeCancelAlert}
          onOk={closeCancelAlertAndDialog}
        />
      </Row>
    </Grid>
  );
};
