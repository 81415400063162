import React, { useEffect, useRef, useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import { Notification, Row } from "@raudabaugh/thread-ui";
import { RoutePathEnum } from "./RoutePathEnum";
import { AbcData } from "../AbcData/AbcData";
import { AddAbcData } from "../AbcData/AddAbcData";
import { EditAbcData } from "../AbcData/EditAbcData";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IAbcDataParams {
  studentId: string;
  programId?: string;
}

const ViewAbcData = () => {
  const { programId } = useParams<IAbcDataParams>();
  const containerRef = useRef<Row>(null);
  const { studentId, setStudentId } = useStudentContext();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <AbcData
        currentStudentId={studentId}
        containerRef={containerRef}
        programId={programId}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.ABC_DATA,
          state: {
            sideMenu: RoutePathEnum.ABC_DATA,
            current: RoutePathEnum.ABC_DATA
          }
        }}
      />
    );
  }
};

interface IAddAbcDataParams {
  studentId: string;
  programId?: string;
}

const CreateAbcData = () => {
  const history = useHistory();
  const { studentId, programId } = useParams<IAddAbcDataParams>();

  const handleError = useCallback((error: Error) => {
    NotificationsHelper.ErrorNotification({
      error,
      title: error.name,
      description: error.message
    });
  }, []);

  const handleSuccess = useCallback(
    (title: string, description: string) => {
      Notification.success({
        duration: 0,
        message: title,
        description: description
      });

      history.goBack();
    },
    [history]
  );

  return (
    <AddAbcData
      studentId={studentId}
      programId={programId}
      onError={handleError}
      onSuccess={handleSuccess}
    />
  );
};

interface IEditAbcDataParams {
  studentId: string;
  programId?: string;
  dataId: string;
}

const UpdateAbcData = () => {
  const history = useHistory();
  const { studentId, programId, dataId } = useParams<IEditAbcDataParams>();

  const handleError = useCallback((error: Error) => {
    NotificationsHelper.ErrorNotification({
      error,
      title: error.name,
      description: error.message
    });
  }, []);

  const handleSuccess = useCallback(
    (title: string, description: string) => {
      Notification.success({
        duration: 0,
        message: title,
        description: description
      });
      history.goBack();
    },
    [history]
  );

  return (
    <EditAbcData
      studentId={studentId}
      programId={programId}
      dataId={dataId}
      onError={handleError}
      onSuccess={handleSuccess}
    />
  );
};

interface AbcRouterProps {}

export const AbcRouter = (props: AbcRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.ABC_DATA_CREATE} exact={true}>
        <CreateAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA_EDIT} exact={true}>
        <UpdateAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA_CREATE_WITH_PROGRAM} exact={true}>
        <CreateAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA_EDIT_WITH_PROGRAM} exact={true}>
        <UpdateAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA_WITH_PROGRAM} exact={true}>
        <ViewAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA_WITH_STUDENT} exact={true}>
        <ViewAbcData />
      </Route>
      <Route path={RoutePathEnum.ABC_DATA}>
        <DefaultStudent path={RoutePathEnum.ABC_DATA_WITH_STUDENT} />
      </Route>
    </Switch>
  );
};
