import { Row, Heading, Col, Card, Icon } from "@raudabaugh/thread-ui";
import React from "react";

export interface ISmallCardLayoutProps {
  title: string;
  actions: React.ReactNode;
  children: React.ReactNode;
  onShowFocusDrawer?: () => void;
  onPopup: () => void;
}

export const SmallCardLayout = (props: ISmallCardLayoutProps) => {
  return (
    <Card color="primary" variation={8} padding="10px" margin="0">
      <Row type="flex">
        <Col
          span={20}
          padding="4px 0 0 0"
          onClick={
            props.onShowFocusDrawer !== undefined
              ? props.onShowFocusDrawer
              : () => {}
          }
        >
          <Heading
            level={5}
            maxWidth="40vw"
            color="default"
            variation={1}
            noTooltip={props.onShowFocusDrawer !== undefined}
          >
            {props.title}{" "}
            {props.onShowFocusDrawer !== undefined && (
              <Icon type="fa-search-plus fas" />
            )}
          </Heading>
        </Col>
        <Col padding="5px 0px 0 0" span={2}>
          <Icon
            onClick={() => props.onPopup()}
            variation={10}
            type="fa-info-circle far"
          />
        </Col>
        <Col span={2}>{props.actions}</Col>
      </Row>
      <Row type="flex">
        <Col type="flex" grow={1} span={24}>
          {props.children}
        </Col>
      </Row>
    </Card>
  );
};
