import {
  Button,
  Grid,
  Row,
  Heading,
  ButtonGroup,
  Col,
  IIntervalButton
} from "@raudabaugh/thread-ui";
import { generateButtonLabel } from "ProgramDrawer/TickerTrialCard";
import React, { useEffect, useState } from "react";
import { CollectionMethodEnum, TrialResultEnum } from "Shared/Api/globalTypes";
import { IntervalHelper } from "Shared/IntervalHelper";

export type IntervalFocusButtonType = "future" | "current" | "past";
interface IIntervalFocusButton {
  type: IntervalFocusButtonType;
  button: IIntervalButton;
  interval: number;
  method: CollectionMethodEnum;
  onButtonClick: (button: IIntervalButton) => void;
}

export const IntervalFocusButton = (props: IIntervalFocusButton) => {
  const [label, setLabel] = useState<React.ReactNode>(props.button.label);
  const disabled = props.type === "future";
  const color = disabled ? "primary" : "default";
  const variation = disabled ? 6 : 1;
  const time = IntervalHelper.getTimeLabel(
    props.interval,
    props.button.seconds
  );
  const timeLevel = time.length > 5 ? 8 : 7;
  const buttonType = props.type === "current" ? "ghost" : "primary";

  useEffect(() => {
    setLabel(props.button.label);
  }, [props.button.label]);

  const button = (
    <Button
      disabled={disabled}
      type={buttonType}
      size="x-large"
      onClick={() => {
        if (
          !disabled &&
          props.button.seconds !== -1 &&
          props.method !== CollectionMethodEnum.PAPER
        ) {
          props.button.status = IntervalHelper.toggleResult(
            props.button.status as TrialResultEnum
          );
          setLabel(generateButtonLabel(props.button.status ?? ""));
          props.onButtonClick(props.button);
        }
      }}
    >
      <Grid width="30px">
        <Row margin="0 auto">
          <Heading level={timeLevel} color={color} variation={variation}>
            {time}
          </Heading>
        </Row>
        <Row margin="0 auto">
          <Heading level={5} color={color} variation={variation}>
            {label}
          </Heading>
        </Row>
      </Grid>
    </Button>
  );

  if (disabled) {
    return (
      <Col type="flex" grow={0} margin="4px">
        <ButtonGroup>{button}</ButtonGroup>
      </Col>
    );
  } else {
    return (
      <Col type="flex" grow={0} margin="5px">
        {button}
      </Col>
    );
  }
};
