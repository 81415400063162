import { OrganizationSettingsEnum, ProgramFieldEnum } from "../App";
import { IThreadContext } from "./IThreadContext";
import moment from "moment";
import { OrganizationFragment_settings } from "./Api/OrganizationFragment";
import { CriterionInput, ReinforcementScheduleEnum } from "./Api/globalTypes";
import { v4 } from "uuid";

export class SettingsHelper {
  static boolean(
    context: IThreadContext | undefined,
    settingId: OrganizationSettingsEnum,
    defaultValue?: boolean
  ) {
    const value = context?.settings?.[settingId]?.value;
    if (!value) {
      return defaultValue ?? false;
    }
    return value === "true";
  }

  static date(
    context: IThreadContext | undefined,
    settingId: OrganizationSettingsEnum,
    defaultValue?: moment.Moment
  ): moment.Moment {
    const value = context?.settings?.[settingId]?.value;
    if (!value) {
      return defaultValue ?? moment();
    }
    return moment(value);
  }

  static exists(
    context: IThreadContext | undefined,
    settingId: OrganizationSettingsEnum,
    settingValue: ProgramFieldEnum
  ) {
    const key = settingId + ":" + settingValue;
    return context?.settings?.[key] !== undefined;
  }

  static number(
    context: IThreadContext | undefined,
    settingId: OrganizationSettingsEnum,
    defaultValue?: number
  ) {
    const value = context?.settings?.[settingId]?.value;
    if (!value) {
      return defaultValue ?? 0;
    }
    return parseInt(value);
  }

  static string(
    context: IThreadContext | undefined,
    settingId: OrganizationSettingsEnum,
    defaultValue?: string
  ) {
    const value = context?.settings?.[settingId]?.value;
    if (!value) {
      return defaultValue ?? "";
    }
    return value;
  }

  static unlimitedTrials(context: IThreadContext) {
    const numOfTrials = SettingsHelper.number(
      context,
      OrganizationSettingsEnum.DEFAULT_MAX_TRIALS
    );
    return numOfTrials === 0;
  }

  static maxNumberOfTrials(context: IThreadContext) {
    const value =
      context?.settings?.[OrganizationSettingsEnum.DEFAULT_MAX_TRIALS]?.value;

    if (!value) {
      return null;
    }
    const maxTrial = parseInt(value);

    return maxTrial > 0 ? maxTrial : null;
  }

  static minNumberOfTrials(context: IThreadContext) {
    var numOfTrials = SettingsHelper.number(
      context,
      OrganizationSettingsEnum.DEFAULT_MIN_TRIALS
    );

    if (!!numOfTrials && numOfTrials > 0) {
      return numOfTrials;
    }

    return null;
  }

  static reinforcementStrategy(context: IThreadContext) {
    const stratValue =
      context?.settings?.[
        OrganizationSettingsEnum.DEFAULT_REINFORCEMENT_STRATEGY
      ]?.value;
    if (!!stratValue) {
      const enumVal =
        ReinforcementScheduleEnum[
          stratValue.toLocaleUpperCase().replace(" ", "_")
        ];
      return ReinforcementScheduleEnum[enumVal];
    }

    return ReinforcementScheduleEnum.NONE;
  }

  static reinforcementRatio(context: IThreadContext) {
    const ratioValue =
      context?.settings?.[OrganizationSettingsEnum.DEFAULT_REINFORCEMENT_RATIO]
        ?.value;
    return !!ratioValue ? Number(ratioValue) : null;
  }

  static criterion(context: IThreadContext) {
    const keys = Object.keys(context.settings).filter(key =>
      key.startsWith(OrganizationSettingsEnum.CRITERION_DEFAULTS)
    );
    let criteria = keys.map(i => {
      const setting = context.settings[i];
      return setting.value!;
    });

    const defaultCriteriaList: CriterionInput[] = [];
    criteria.forEach(criterion => {
      const criteriaValues = criterion?.split("-");
      const points =
        criteriaValues != null && criteriaValues.length === 2
          ? parseInt(criteriaValues[1])
          : 1;
      const percentage =
        criteriaValues != null && criteriaValues.length === 2
          ? parseFloat(criteriaValues[0])
          : 1;
      const newCriterion: CriterionInput = {
        id: v4(),
        pointsAnalyzed: points,
        minPercentage: percentage
      };
      defaultCriteriaList.push(newCriterion);
    });

    return defaultCriteriaList;
  }

  static buildSettingsMap(array: OrganizationFragment_settings[]): {
    [key: string]: OrganizationFragment_settings;
  } {
    return array.reduce((map, setting) => {
      let key = setting.setting.id;
      if (
        key === OrganizationSettingsEnum.ADD_SECTION ||
        key === OrganizationSettingsEnum.HIDE_SECTION ||
        key === OrganizationSettingsEnum.ADD_PROMPT ||
        key === OrganizationSettingsEnum.HIDE_PROMPT ||
        key === OrganizationSettingsEnum.CRITERION_DEFAULTS
      ) {
        key = key + ":" + setting.value;
      }
      map[key] = setting;
      return map;
    }, {});
  }
}
