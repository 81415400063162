import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import {
  useDataPointScoreDurationMutation,
  useDataPointStartDurationMutation,
  useDataPointStopDurationMutation,
  useDataPointUndoDurationMutation
} from "../DataAccess/DurationData";
import { SimpleTrialCard } from "./SimpleTrialCard";
import { DrawerEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import {
  useProgramDeactivateMutation,
  useProgramMoveMutation
} from "DataAccess/ProgramData";
import { TrialFragment } from "Shared/Api/TrialFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { DataPointHelper } from "Shared/DataPointHelper";
import { Heading, Modal, Row } from "@raudabaugh/thread-ui";
import { IProgramItem } from "StudentPrograms/Types";

interface IDurationCardProps {
  currentStudentId: string;
  count: number;
  duration: number | null;
  title: string;
  pointId?: string;
  phaseId: string;
  programId: string;
  trials: TrialFragment[];
  durationOverride: number | null;
  isComplete: boolean;
  setLoading: (loading: boolean) => void;
  index: number;
  size: "sm" | "lg";
  item: IProgramItem;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onPopup: (programId: string) => void;
  onShowFocusDrawer?: () => void;
}

const DurationCard = (props: IDurationCardProps) => {
  const [enterScoreOpen, setEnterScoreOpen] = useState(false);
  const [running, setRunning] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timeoutMilliseconds, setTimeoutMilliseconds] = useState(0);
  const { setLoading } = props;
  const [closeWarningOpen, setCloseWarningOpen] = useState(false);

  const { programMove } = useProgramMoveMutation();
  const { programDeactivate } = useProgramDeactivateMutation();
  const { dataPointStartDuration } = useDataPointStartDurationMutation();
  const { dataPointStopDuration } = useDataPointStopDurationMutation();
  const { dataPointScoreDuration } = useDataPointScoreDurationMutation();
  const { dataPointUndoDuration } = useDataPointUndoDurationMutation();
  const { threadUserContext } = useThreadContext();

  useEffect(() => {
    if (props.trials) {
      // Handle startup of timer when returning to a running duration
      const runningDuration = props.trials.find(t => t.duration == null);
      if (runningDuration) {
        setStartTime(moment(runningDuration?.occurredAt).toDate().valueOf());
        setTimeoutMilliseconds(1000);
      }
    }
  }, [props.trials]);

  useEffect(() => {
    // Handle setting next timeout each time timeoutMilliseconds is set
    if (
      !props.isComplete &&
      startTime !== undefined &&
      startTime !== 0 &&
      timeoutMilliseconds !== 0
    ) {
      const timeout = setTimeout(() => {
        const now = Date.now();
        const timeDiffSeconds = Math.max(
          Math.floor((now - startTime) / 1000),
          0
        );
        setRunning(timeDiffSeconds);
        let newTimeout = (now - startTime) % 1000;
        if (newTimeout === timeoutMilliseconds) {
          // Bump value by 1 ms to ensure we get a state change
          newTimeout++;
        }
        setTimeoutMilliseconds(newTimeout);
      }, 1000 - timeoutMilliseconds);
      return () => clearTimeout(timeout);
    }
  }, [startTime, timeoutMilliseconds, props.isComplete]);

  const onClose = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleMoveToTopClick = () => {
    programMove(props.programId, props.currentStudentId, 0).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };

  const closeProgram = useCallback(async () => {
    try {
      await programDeactivate(props.programId, props.currentStudentId, false);
    } catch (error) {
      NotificationsHelper.ErrorNotification({
        error: error as Error,
        title: "Action Failed",
        onClose
      });
    }
  }, [programDeactivate, onClose, props.programId, props.currentStudentId]);

  const handleCloseClick = useCallback (() => {
    if (props.item.drawer === DrawerEnum.PRIVATE_DRAWER) {
      const hasNoTrials = props.trials.length === 0;
      const isCompleted = props.isComplete;
      
      if(hasNoTrials || isCompleted) {
        closeProgram();
      } else {
        setCloseWarningOpen(true);
      }
    } else {
      closeProgram();
    }
  }, [closeProgram, props.item.drawer, props.trials, props.isComplete]);

  const handleCloseWarningContinue = () => {
    setCloseWarningOpen(false);
    closeProgram();
  };

  const handleCloseWarningCancel = () => {
    setCloseWarningOpen(false);
  };

  const handleEnterScoreClick = useCallback(() => {
    setEnterScoreOpen(true);
  }, []);

  const handleSaveScore = useCallback(
    (
      score: number,
      date: moment.Moment,
      attempts?: number,
      phaseId?: string
    ) => {
      dataPointScoreDuration(
        props.programId,
        phaseId ?? props.phaseId,
        props.currentStudentId,
        Math.floor(score * 60),
        date.toISOString()
      )
        .then(() => {
          setEnterScoreOpen(false);
        })
        .catch((error: ApolloError) => {
          setEnterScoreOpen(false);
          NotificationsHelper.ErrorNotification({
            error,
            title: "Action Failed",
            onClose
          });
        });
    },
    [
      dataPointScoreDuration,
      onClose,
      props.currentStudentId,
      props.programId,
      props.phaseId
    ]
  );

  const handleButtonClick = async () => {
    try {
      if (props.duration != null && !props.isComplete) {
        const duration = Math.floor((Date.now() - startTime) / 1000);
        setRunning(0);
        setTimeoutMilliseconds(0);
        await dataPointStopDuration(
          props.pointId!,
          props.currentStudentId,
          duration
        );
      } else {
        setRunning(0);
        setStartTime(Date.now());
        setTimeoutMilliseconds(1000);
        await dataPointStartDuration(
          props.programId,
          props.phaseId,
          props.currentStudentId,
          threadUserContext.userId,
          threadUserContext.userName
        );
      }
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed",
        onClose
      });
    }
  };
  const handleAbcClick = () => {
    props.onAbc(props.programId);
  };
  const handleChartClick = () => {
    props.onChart(props.programId, ProgramTypeEnum.DURATION);
  };
  const handleUndoClick = () => {
    dataPointUndoDuration(
      props.pointId!,
      props.currentStudentId,
      threadUserContext.userId
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed",
        onClose
      });
    });
  };

  const handleEnterScoreClose = () => {
    setEnterScoreOpen(false);
  };

  const totalDuration =
    (props.durationOverride ?? 0) +
    (props.trials
      ? props.trials.reduce((sum, current) => sum + (current.duration ?? 0), 0)
      : 0);
  const icon: string = !props.isComplete ? "fa-pause fas" : "fa-play fas";
  let caption: string | undefined =
    DataPointHelper.formatDuration(totalDuration) +
    (props.isComplete
      ? " / " + props.count + " times"
      : " + " + DataPointHelper.formatDuration(running));
  if (props.size === "lg" && props.isComplete) {
    caption = undefined;
  }
  const score = (
    <Row alignItems="center" direction="column" type="flex">
      <Heading color="default" weight="medium" level={4}>
        {DataPointHelper.formatDuration(totalDuration)}
      </Heading>
      <Heading color="default" level={5}>
        {props.count + " times"}
      </Heading>
    </Row>
  );

  return (
    <>
      <SimpleTrialCard
        icon={icon}
        index={props.index}
        size={props.size}
        title={props.title}
        caption={caption}
        score={score}
        studentId={props.currentStudentId}
        onButtonClick={handleButtonClick}
        onAbcClick={handleAbcClick}
        onChartClick={handleChartClick}
        onUndoClick={props.count !== 0 ? handleUndoClick : undefined}
        onCloseClick={handleCloseClick}
        onMoveToTopClick={handleMoveToTopClick}
        onEnterScoreClick={handleEnterScoreClick}
        onPopupClick={() => props.onPopup(props.programId)}
      />
      <EnterScoreDialog
        scoreLabel="Duration of Occurrence (minutes)"
        dateLabel="Date"
        onClose={handleEnterScoreClose}
        open={enterScoreOpen}
        onSaveScore={handleSaveScore}
        programId={props.programId}
        studentId={props.currentStudentId}
      />
      <Modal
        title="Are you sure you want to close this program?"
        afterClose={handleCloseWarningCancel}
        visible={closeWarningOpen}
        onOk={handleCloseWarningContinue}
        onCancel={handleCloseWarningCancel}
        okText="Close Program"
      >
        Your data will be deleted immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export default memo(DurationCard);
