import React from "react";
import {
  IChartPhase,
  IChartData,
  IPhaseDetail,
  IPrintBlock,
  Icon,
  PrintColumnLayout,
  Paragraph,
  PrintNote,
  Heading,
  PrintBlock,
  Col,
  Row,
  DetailTypeEnum
} from "@raudabaugh/thread-ui";
import _ from "lodash";
import { ChartHelper } from "./ChartHelper";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";

interface IPhaseListProps {
  chartType: ProgramTypeEnum;
  chartData: IChartData;
  phases: IChartPhase[];
  header: React.ReactNode;
}

interface IDetailProps {
  label: string;
  description: string;
}

const TaskDetail = (props: IDetailProps) => {
  return (
    <Row type="flex" margin="7px 0 -5px 0">
      <Col grow={0} margin="0 8px 0 0">
        <Heading level={5} weight="bold">
          {props.label}
        </Heading>
      </Col>
      <Col grow={1}>
        <Paragraph>{props.description}</Paragraph>
      </Col>
    </Row>
  );
};

const TargetDetail = (props: IDetailProps) => {
  return (
    <Row type="flex" margin="7px 0 -5px 0">
      <Col grow={0} margin="0 5px 0 3px">
        <Icon type="fa-bullseye fas" />
      </Col>
      <Col grow={1}>
        <Paragraph>{props.description}</Paragraph>
      </Col>
    </Row>
  );
};

const NoteDetail = (props: IDetailProps) => {
  return (
    <Row type="flex" margin="7px 0 -5px 0" wrap={true}>
      <Col grow={0} margin="0 10px 0 0">
        <Heading level={5} weight="bold" margin="-5px 0 0 0">
          {props.label}
        </Heading>
      </Col>
      <Col grow={1}>
        <PrintNote>{props.description}</PrintNote>
      </Col>
    </Row>
  );
};

const OtherDetail = (props: IDetailProps) => {
  return (
    <Row type="flex" margin="7px 0 -5px 0">
      <Col grow={0} margin="0 5px 0 3px">
        <Heading level={5} weight="bold" margin="-5px 0 0 0">
          {props.label}
        </Heading>
      </Col>
      <Col grow={1}>
        <Paragraph>{props.description}</Paragraph>
      </Col>
    </Row>
  );
};

export const PhaseList = (props: IPhaseListProps) => {
  const breakPoints: Array<IBreakPoint> = getBreakPoints(props.phases);
  const wrapped: Array<IWrappedPhase> = wrapPhases(props.phases, breakPoints);
  const blocks: Array<IPrintBlock> = mapPhasesToPrintBlocks(wrapped);
  return (
    <PrintColumnLayout columnCount={3} header={props.header}>
      <>
        {blocks.map((block: IPrintBlock, index) => {
          return <PrintBlock {...block} key={index} />;
        })}
      </>
    </PrintColumnLayout>
  );
};

const printColumnHeight = 671;
const printColumnWidth = 270;
const printLineHeight = 21;
const printLineMargin = 14;
const printFontSize = 14;
const minimumCaptionHeight = 40;
const maximumDetailLength = 990;

interface IBreakPoint {
  phaseIndex: number;
  detailIndex: number;
}

const getBreakPoints = (phases: Array<IChartPhase>) => {
  const columnSize = printColumnHeight;
  const headerSize = 72;
  const footerSize = 50;
  const breakPoints: Array<IBreakPoint> = [];
  let total = 0;
  for (let phaseIndex = 0; phaseIndex < phases.length; phaseIndex++) {
    const phase: IChartPhase = phases[phaseIndex];
    const captionRows = phase.labelOverride
      ? ChartHelper.getRowCount(
          phase.labelOverride,
          printColumnWidth,
          printFontSize
        )
      : 0;
    const captionSize =
      captionRows > 1 ? printLineHeight * captionRows : minimumCaptionHeight;
    let blockSize = headerSize + captionSize + footerSize;
    if (phase?.details) {
      let detailTotal = 0;
      for (
        let detailIndex = 0;
        detailIndex < phase.details.length;
        detailIndex++
      ) {
        const detail: IPhaseDetail = phase.details[detailIndex];
        let detailRows = ChartHelper.getRowCount(
          detail.description,
          printColumnWidth,
          printFontSize
        );
        let detailSize = printLineHeight * detailRows + printLineMargin;
        if (total + blockSize + detailTotal + detailSize > columnSize) {
          // Column overflow has occurred
          const breakPoint: IBreakPoint = {
            phaseIndex: phaseIndex,
            detailIndex: detailIndex
          };
          breakPoints.push(breakPoint);
          detailTotal = 0;
          total = 0;
          detailSize = printLineHeight * detailRows + printLineMargin;
        }
        detailTotal += detailSize;
      }
      total += blockSize + detailTotal;
    } else {
      if (total + blockSize > columnSize) {
        const breakPoint: IBreakPoint = {
          phaseIndex: phaseIndex,
          detailIndex: 0
        };
        breakPoints.push(breakPoint);
        total = 0;
      }
      total += blockSize;
    }
  }
  return breakPoints;
};

interface IWrappedPhase {
  phase: IChartPhase;
  breakAfter: boolean;
}
const wrapPhases = (
  phases: Array<IChartPhase>,
  breakPoints: Array<IBreakPoint>
): Array<IWrappedPhase> => {
  const wrappedPhases: Array<IWrappedPhase> = phases.map(phase => {
    const wrapped: IWrappedPhase = { phase, breakAfter: false };
    return wrapped;
  });
  for (let index = breakPoints.length - 1; index >= 0; index--) {
    const breakPoint = breakPoints[index];
    const phase = wrappedPhases[breakPoint.phaseIndex].phase;
    if (phase.details && breakPoint.detailIndex !== 0) {
      // Phase is split across the break
      const newPhase: IWrappedPhase = {
        phase: { ...phase },
        breakAfter: false
      };
      phase.titleLetter = phase.titleLetter?.replace("...", "");
      newPhase.phase.titleLetter = "..." + phase.titleLetter;
      phase.titleLetter = phase.titleLetter + "...";
      const beforeBreak = phase.details.splice(0, breakPoint.detailIndex);
      newPhase.phase.details = phase.details;
      if (
        newPhase.phase.details.length === 1 &&
        newPhase.phase.details[0].description.length > maximumDetailLength
      ) {
        newPhase.phase.details[0].description =
          newPhase.phase.details[0].description.substring(
            0,
            maximumDetailLength
          ) + " ...";
      }
      phase.details = beforeBreak;
      wrappedPhases[breakPoint.phaseIndex].breakAfter = true;
      wrappedPhases.splice(breakPoint.phaseIndex + 1, 0, newPhase);
    } else {
      wrappedPhases[breakPoint.phaseIndex - 1].breakAfter = true;
    }
  }
  return wrappedPhases;
};

const mapPhasesToPrintBlocks = (phases: Array<IWrappedPhase>) => {
  const blocks: Array<IPrintBlock> = [];
  phases.forEach((wrapped: IWrappedPhase) => {
    const phase = wrapped.phase;
    const firstXLabel = ChartHelper.getPrintLabel(_.first(phase.points)?.x);
    const lastXLabel = ChartHelper.getPrintLabel(_.last(phase.points)?.x);
    const range =
      firstXLabel !== lastXLabel ? `${firstXLabel}-${lastXLabel}` : firstXLabel;
    const details: Array<React.ReactNode> = phase.details
      ? phase.details.map((detail, index) => {
          // Details are provided by the server in the following order: targets/tasks, additionalInfo, notes.
          switch (detail.type) {
            case DetailTypeEnum.NOTE:
              return (
                <NoteDetail
                  label={detail.label}
                  description={detail.description}
                  key={"d" + index}
                />
              );
            case DetailTypeEnum.TARGET:
              return (
                <TargetDetail
                  label={detail.label}
                  description={detail.description}
                  key={"d" + index}
                />
              );
            case DetailTypeEnum.TASK:
              return (
                <TaskDetail
                  label={detail.label}
                  description={detail.description}
                  key={"d" + index}
                />
              );
            case DetailTypeEnum.ADDITIONAL_INFO:
              return (
                <OtherDetail
                  label={detail.label}
                  description={detail.description}
                  key={"d" + index}
                />
              );
            default:
              // Catch-all in case server wants to include something else we don't recognize.
              return (
                <OtherDetail
                  label={detail.label}
                  description={detail.description}
                  key={"d" + index}
                />
              );
          }
        })
      : [];

    const block: IPrintBlock = {
      titleLeft: phase.titleLetter ?? "",
      titleRight: phase.pointsNumber + " Data Points \xa0\xa0\xa0 " + range,
      caption: phase.labelOverride,
      breakAfter: wrapped.breakAfter,
      children: details
    };
    blocks.push(block);
  });
  return blocks;
};
