import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { MaintenanceView } from "StudentPrograms/Maintenance/MaintenanceView";

const ViewMaintenance = () => {
  const { studentId, setStudentId } = useStudentContext();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return <MaintenanceView currentStudentId={studentId} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.MAINTENANCE,
          state: {
            sideMenu: RoutePathEnum.MAINTENANCE,
            current: RoutePathEnum.MAINTENANCE
          }
        }}
      />
    );
  }
};

interface MaintenanceRouterProps {}

export const MaintenanceRouter = (props: MaintenanceRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.MAINTENANCE_LIST} exact={true}>
        <ViewMaintenance />
      </Route>
      <Route path={RoutePathEnum.MAINTENANCE}>
        <DefaultStudent path={RoutePathEnum.MAINTENANCE_LIST} />
      </Route>
    </Switch>
  );
};
