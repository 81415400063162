import React, { useEffect, useState } from "react";
import { IAvatarProps, Avatar, Spin } from "@raudabaugh/thread-ui";
import { ImageHelper } from "./ImageHelper";
import { gql, useApolloClient } from "@apollo/client";

export interface IProtectedAvatarProps extends IAvatarProps {}

export interface IProtectedAvatarState {
  dataUrl?: string;
  loading: boolean;
}

const FRAGMENT_AVATAR = gql`
  fragment AvatarFragment on AvatarType {
    data
  }
`;

interface AvatarFragment {
  __typename: "AvatarType";
  data: string;
}

/**
 * An avatar graphic pulled from an end point requiring token authentication
 */
export const ProtectedAvatar = (props: IProtectedAvatarProps) => {
  const client = useApolloClient();
  const initialState: IProtectedAvatarState = { loading: false };
  const [state, setState] = useState<IProtectedAvatarState>(initialState);
  useEffect(() => {
    if (props.src == null || props.src.length === 0) {
      setState({ loading: false, dataUrl: undefined });
    }
    if (props.src) {
      const avatar = client.readFragment<AvatarFragment>({
        id: `AvatarType:${props.src}`,
        fragment: FRAGMENT_AVATAR,
        fragmentName: "AvatarFragment"
      });
      let changedOrUnmounted = false;
      if (avatar) {
        setState({ dataUrl: avatar.data, loading: false });
      } else {
        setState({ loading: true });
        ImageHelper.fetchProtectedImage(props.src).then(result => {
          if (result) {
            client.writeFragment<AvatarFragment>({
              id: `AvatarType:${props.src}`,
              fragment: FRAGMENT_AVATAR,
              fragmentName: "AvatarFragment",
              data: {
                __typename: "AvatarType",
                data: result
              }
            });
          }
          if (!changedOrUnmounted) {
            // promise may not resolve before avatar changes or we unmount
            setState({ dataUrl: result, loading: false });
          }
        });
      }

      return () => {
        changedOrUnmounted = true;
      };
    }
  }, [props.src, client]);
  const newProps: IAvatarProps = { ...props };
  newProps.src = state.dataUrl;
  if (state.loading) {
    return <Spin />;
  } else {
    return <Avatar {...newProps} />;
  }
};
