import React, { useState } from "react";
import {
  Col,
  Heading,
  IColumnProps,
  Icon,
  Link,
  Notification,
  Row,
  Table,
  TimePicker
} from "@raudabaugh/thread-ui";
import moment from "moment";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { TrialDataFormActions } from "./TrialDataFormActions";
import { TrialDataFormItem } from "./TrialDataFormItem";
import {
  useDataPointAddTrialMutation,
  useDataPointDeleteTrialMutation,
  useDataPointUpdateTrialMutation
} from "DataAccess/DataPointData";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  DataPointFragment,
  DataPointFragment_trials
} from "Shared/Api/DataPointFragment";
import { ProgramTypeEnum, TrialInput } from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";

interface IFrequencyDetailProps {
  studentId: string;
  data: DataPointFragment;
  date: moment.Moment;
  heading: JSX.Element;
  onClose: () => void;
}

export const FrequencyDetail = (props: IFrequencyDetailProps) => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const { dataPointDeleteTrial } = useDataPointDeleteTrialMutation();
  const { dataPointUpdateTrial } = useDataPointUpdateTrialMutation();
  const { dataPointAddTrial } = useDataPointAddTrialMutation();
  const [editTrial, setEditTrial] = useState<{
    id?: string;
    index?: number;
    input: TrialInput;
  }>();
  const [validationFailed, setValidationFailed] = useState(false);
  const dataPoint = props.data as DataPointFragment;

  const hasAddPermission = findPermission(
    threadUserContext.role,
    Permissions.ADD_TRIALS
  );

  const hasEditPermission = findPermission(
    threadUserContext.role,
    Permissions.EDIT_TRIALS
  );

  const showOfflineNotification = (label: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${label} function is not available while in offline mode.`
    });
  };

  const showDisabledUntilTomorrow = () => {
    Notification.warn({
      duration: 0,
      message: "Editing Disabled",
      description: `Editing of today's data point is disabled until tomorrow.`
    });
  };

  const handleAddTrialClick = () => {
    const today = moment().startOf("day");
    const pointDay = props.date.startOf("day");
    if (pointDay.isSame(today)) {
      showDisabledUntilTomorrow();
      return;
    }
    if (!online) {
      showOfflineNotification("Add Trial");
      return;
    }
    setEditTrial({
      input: { occurredAt: props.date.toISOString() }
    });
  };

  const columns: Array<IColumnProps<DataPointFragment_trials>> = [
    {
      dataIndex: "trial",
      key: "trial",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return <>{index + 1}</>;
      },
      title: "Trial"
    },
    {
      dataIndex: "occurredAt",
      key: "occurredAt",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return <>{moment.utc(record.occurredAt).local().format("h:mm a")}</>;
      },
      title: "Occurred At"
    }
  ];

  if (hasEditPermission) {
    columns.push({
      align: "right",
      width: "60px",
      dataIndex: "edit",
      key: "edit",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return (
          <Link
            onClick={() => {
              const today = moment().startOf("day");
              const pointDay = props.date.startOf("day");
              if (pointDay.isSame(today)) {
                showDisabledUntilTomorrow();
                return;
              }
              if (!online) {
                showOfflineNotification("Edit Details");
                return;
              }
              setEditTrial({
                id: record.id,
                index: index + 1,
                input: {
                  duration: Math.round(record.duration ?? 0),
                  occurredAt: record.occurredAt
                }
              });
            }}
          >
            Edit
          </Link>
        );
      }
    });
  }

  const handleSubmit = () => {
    if (editTrial) {
      const isValid = !validateOccurredAt();
      if (!isValid) {
        setValidationFailed(true);
        return;
      }
      if (editTrial.id) {
        dataPointUpdateTrial(
          dataPoint.id,
          props.studentId,
          editTrial.id,
          editTrial.input,
          ProgramTypeEnum.FREQUENCY
        )
          .then(() => setEditTrial(undefined))
          .catch(error =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Update the Trial"
            })
          );
      } else {
        dataPointAddTrial(
          dataPoint.id,
          props.studentId,
          uuid(),
          editTrial.input
        )
          .then(() => setEditTrial(undefined))
          .catch(error =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Add the Trial"
            })
          );
      }
    }
  };

  const handleDelete = () => {
    if (editTrial?.id) {
      dataPointDeleteTrial(
        dataPoint.id,
        props.studentId,
        editTrial.id,
        ProgramTypeEnum.FREQUENCY
      )
        .then(() => setEditTrial(undefined))
        .catch(error =>
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Delete the Trial"
          })
        );
    }
  };

  const validateOccurredAt = () => undefined;

  const handleOccurredAtChange = (time: moment.Moment) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      const occurredAt = moment(clone.input.occurredAt).local();
      occurredAt.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second")
      });
      clone.input.occurredAt = occurredAt.utc().toISOString();
      setEditTrial(clone);
    }
  };

  if (editTrial) {
    return (
      <>
        <Row type="flex" margin="0 0 8px 0">
          <Col margin="0 0 0 8px">
            <Link onClick={() => setEditTrial(undefined)}>
              <Icon type="fa-arrow-left fas" />
              {" View trials"}
            </Link>
          </Col>
        </Row>
        <Heading level={3}>
          {editTrial.index ? `Trial ${editTrial.index}` : "Add Trial"}
        </Heading>
        <TrialDataFormItem
          required={true}
          label="Occurred at"
          validationMessage={
            validationFailed ? validateOccurredAt() : undefined
          }
        >
          <TimePicker
            format="h:mm a"
            value={moment(editTrial.input.occurredAt).local()}
            onChange={handleOccurredAtChange}
          />
        </TrialDataFormItem>
        <Row height="100px"></Row>
        <TrialDataFormActions
          onCancel={() => setEditTrial(undefined)}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <Row type="flex" margin="0 0 8px 0">
        <Col>
          <Link onClick={() => props.onClose()}>
            <Icon type="fa-arrow-left fas" /> Datapoint details
          </Link>
        </Col>
      </Row>
      {props.heading}
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataPoint?.trials}
        scroll={{ y: "250px" }}
      />
      {hasAddPermission && (
        <Row type="flex" margin="16px">
          <Col grow={1} />
          <Col grow={0}>
            <Link onClick={() => handleAddTrialClick()}>
              <Icon type="fa-plus fas" /> Add Trial
            </Link>
          </Col>
        </Row>
      )}
    </>
  );
};
