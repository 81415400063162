import { memo, useCallback, useMemo } from "react";
import { Col, Heading, Row, Link, Modal } from "@raudabaugh/thread-ui";
import { PhasePopup } from "Charts/PhasePopup";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";

export interface IProgramInfoModalProps {
  studentId: string;
  programId: string;
  onEdit?: (programId: string, type: ProgramTypeEnum) => void;
  onClose: () => void;
}

const ProgramInfoModal = ({
  studentId,
  programId,
  onEdit,
  onClose
}: IProgramInfoModalProps) => {
  const { data } = useProgramSessionsQuery(studentId);

  const programSession = useMemo(() => {
    return data?.programSessions.find(s => s.id === programId);
  }, [data, programId]);

  const handleEdit = useCallback(() => {
    if (programSession && onEdit) {
      onEdit(programSession.program.id, programSession.program.type);
    }
  }, [onEdit, programSession]);

  return (
    <Modal
      title={
        <Row type="flex" justify="space-between" margin="0 16px 0 0">
          <Col>{programSession?.program.name || ""}</Col>
          {onEdit && (
            <Heading
              margin="0 40px 0 0"
              id="Edit_Program_Definition"
              level={6}
              color="primary"
            >
              <Link
                icon="fa-edit fas"
                iconPosition="right"
                onClick={handleEdit}
              >
                Edit
              </Link>
            </Heading>
          )}
        </Row>
      }
      onCancel={onClose}
      visible={true}
      centered={true}
      footer={null}
    >
      <PhasePopup
        phase={programSession?.currentPhase!}
        currentPhase={programSession?.currentPhase!}
        program={programSession?.program!}
        studentId={studentId}
      />
    </Modal>
  );
};

export default memo(ProgramInfoModal);
