import {
  Button,
  Col,
  Grid,
  Heading,
  Icon,
  Row,
  Divider,
  Media,
  Modal
} from "@raudabaugh/thread-ui";
import React, { memo, useCallback } from "react";
import { StudentFragment } from "Shared/Api/StudentFragment";

export interface IDrawerBarProps {
  leftHeading: string;
  leftStatus: string;
  rightHeading: string;
  rightStatus: string;
  icon?: string;
  isOpen: boolean;
  id: string;
  size?: string;
  student?: StudentFragment;
  onClearAll?: () => void;
  onSessionEnd?: () => void;
}

const DrawerBar = (props: IDrawerBarProps) => {
  const { student, onClearAll, onSessionEnd } = props;

  const handleClearAllClick = useCallback(() => {
    Modal.warning({
      okCancel: true,
      title: `Are you sure you want to clear all of ${student?.firstName}'s active programs?`,
      content:
        "Clearing all active programs will close any programs that are not currently in progress.",
      onOk: onClearAll,
      okText: "Clear All",
      cancelText: "Cancel",
      okButtonProps: { danger: true }
    });
  }, [onClearAll, student]);

  const handleEndSessionClick = useCallback(() => {
    Modal.warning({
      okCancel: true,
      title: `Are you sure you want to end all of ${student?.firstName}'s active programs?`,
      content: `Ending ${student?.firstName}'s session will end and graph all programs in this student's drawer. If there are program runs you want to throw out, you must do that prior to ending the session. This can not be undone.`,
      onOk: onSessionEnd,
      okText: "End Session",
      cancelText: "Cancel",
      okButtonProps: { danger: true }
    });
  }, [onSessionEnd, student]);

  return (
    <Media.Sm orLarger>
      {(smOrLarger: boolean) => (
        <Grid>
          <Row type="flex" align="middle" alignItems="center">
            <Col grow={1}>
              <Row type="flex">
                <Col grow={0}>
                  <Row type="flex">
                    {smOrLarger && (
                      <Col margin="8px 16px 8px 8px">
                        <Icon size="24px" type="fa-child fas" />
                      </Col>
                    )}
                    <Col>
                      <Heading
                        id={props.id}
                        color="default"
                        variation={1}
                        level={7}
                      >
                        {props.leftHeading}
                      </Heading>
                      <Heading color="default" variation={1} level={5}>
                        {props.leftStatus}
                      </Heading>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Divider height="40px" margin="0px 16px" type="vertical" />
                </Col>
                <Col>
                  <Row type="flex">
                    {smOrLarger && (
                      <Col margin="8px 16px 8px 0px">
                        <Icon size="24px" type="fa-book fas" />
                      </Col>
                    )}
                    <Col>
                      <Heading color="default" variation={1} level={7}>
                        {props.rightHeading}
                      </Heading>
                      <Heading color="default" variation={1} level={5}>
                        {props.rightStatus}
                      </Heading>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {props.isOpen ? (
              props.icon ? (
                <>
                  {smOrLarger && (
                    <Col
                      onClick={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                    >
                      <Button
                        type="default"
                        ghost
                        borderless
                        onClick={handleClearAllClick}
                      >
                        Clear All
                      </Button>
                      <Button type="primary" onClick={handleEndSessionClick}>
                        End Session
                      </Button>
                    </Col>
                  )}
                  <Col margin="0 0 0 auto">
                    <Row type="flex" alignItems="flex-end">
                      <Icon
                        type={props.icon}
                        width={"50px"}
                        size={props.size}
                      />
                    </Row>
                  </Col>
                  {!smOrLarger && (
                    <Col span={24}>
                      <Row type="flex" justify="end">
                        <Col
                          onClick={(event: React.MouseEvent) =>
                            event.stopPropagation()
                          }
                        >
                          <Button
                            type="default"
                            ghost
                            borderless
                            onClick={handleClearAllClick}
                          >
                            Clear All
                          </Button>
                          <Button
                            type="primary"
                            onClick={handleEndSessionClick}
                          >
                            End Session
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </>
              ) : (
                ""
              )
            ) : (
              <>
                <Col grow={1} />
                <Col xs={24} md={0} margin="12px 0 4px 0">
                  <Button block={true} type="primary">
                    View Current Session
                  </Button>
                </Col>
                <Col xs={0} md={8} margin="0">
                  <Button block={true} type="primary">
                    View Current Session
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Grid>
      )}
    </Media.Sm>
  );
};

export default memo(DrawerBar);
