import {
  Col,
  Heading,
  Row,
  Grid,
  Input,
  FormItem,
  InputTextArea,
  Select,
  SelectOption,
  InputNumber,
  Tag
} from "@raudabaugh/thread-ui";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  ProgramTypeEnum,
  ReinforcementScheduleEnum
} from "Shared/Api/globalTypes";
import { LabelHelper } from "Shared/LabelHelper";
import { IProgramComponentProps } from "./Types";
import { IntervalUnitsEnum } from "./IntervalUnitsEnum";
import { IPrompt, PromptHelper } from "Shared/PromptHelper";
import { PromptingStrategyEnum } from "./PromptingStrategyEnum";
import { ProgramTabEnum } from "./ProgramTabEnum";

export interface IPhaseDetailsProps extends IProgramComponentProps {}

export const PhaseDetails = (props: IPhaseDetailsProps) => {
  const inputRef = useRef<HTMLElement>();
  const { threadUserContext } = useThreadContext();
  const [intervalUnits, setIntervalUnits] = useState<IntervalUnitsEnum>(
    IntervalUnitsEnum.MINUTES
  );
  const { program, onValidation } = props;
  const isTemplate = !props.students;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setIntervalUnits(
      program.reinforcementRatio && program.reinforcementRatio % 60 === 0
        ? IntervalUnitsEnum.MINUTES
        : IntervalUnitsEnum.SECONDS
    );
  }, [program, setIntervalUnits]);

  const handleIntervalUnitsChange = (value: IntervalUnitsEnum) => {
    if (program.reinforcementRatio) {
      const interval =
        value === IntervalUnitsEnum.MINUTES
          ? program.reinforcementRatio * 60
          : program.reinforcementRatio / 60;
      const clone = _.cloneDeep(program);
      clone.reinforcementRatio = interval;
      props.onProgramChange(clone);
    }
    setIntervalUnits(value);
  };

  const promptLabel = () => {
    const prompting =
      !!program.errorless && program.programType === ProgramTypeEnum.DTT
        ? PromptingStrategyEnum.TRIAL
        : PromptingStrategyEnum.PHASE;
    const label = LabelHelper.promptingStrategyLabel(prompting);
    return `Prompt (${label})`;
  };

  const promptMap = _.cloneDeep(PromptHelper.prompts(threadUserContext));
  if (!program.errorless) {
    const prompt: IPrompt = {
      name: PromptHelper.PROMPT_INDEPENDENT,
      label: "I"
    };
    promptMap[prompt.name] = prompt;
  }

  useEffect(() => {
    onValidation(ProgramTabEnum.PHASE_DETAILS, true);
  }, [onValidation]);

  const ratio =
    program.reinforcementSchedule === ReinforcementScheduleEnum.FIXED_RATIO ||
    program.reinforcementSchedule === ReinforcementScheduleEnum.VARIABLE_RATIO;

  return (
    <Grid type="flex" margin="0 16px 0 0" variation={1} padding="0">
      {!isTemplate && (
        <Row type="flex" gutter={20}>
          <Col xs={24} md={12}>
            <FormItem>
              <Row type="flex">
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Phase name
                  </Heading>
                </Col>
              </Row>
              <Input
                value={program.phaseNameOverride ?? ""}
                innerRef={inputRef}
                placeholder=""
                onChange={(event: any) => {
                  const clone = _.cloneDeep(program);
                  clone.phaseNameOverride = event.target.value;
                  props.onProgramChange(clone);
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
      {!isTemplate && (
        <Row type="flex" gutter={20}>
          <Col xs={24}>
            <FormItem>
              <Heading level={6} margin="4px 0 0 0">
                Phase details
              </Heading>
              <InputTextArea
                value={program.phaseSummary ?? ""}
                autoSize={true}
                onChange={event => {
                  const clone = _.cloneDeep(program);
                  clone.phaseSummary = event.target.value;
                  props.onProgramChange(clone);
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
      <Row type="flex" gutter={20}>
        {!isTemplate && (
          <Col xs={24} md={12}>
            <FormItem>
              <Row type="flex" margin="0">
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    {promptLabel()}
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1}>
                  <Select
                    dropdownMatchSelectWidth
                    value={program.prompt!}
                    onChange={value => {
                      const clone = _.cloneDeep(program);
                      clone.prompt = value as string;
                      props.onProgramChange(clone);
                    }}
                  >
                    {Object.keys(promptMap).map(key => (
                      <SelectOption value={key} key={key}>
                        <Row type="flex">
                          <Col>
                            <Grid width="56px">
                              <Tag color="primary">{promptMap[key].label}</Tag>
                            </Grid>
                          </Col>
                          <Col grow={1}>{key}</Col>
                        </Row>
                      </SelectOption>
                    ))}
                  </Select>
                </Col>
              </Row>
            </FormItem>
          </Col>
        )}
        <Col xs={24} md={12}>
          <FormItem>
            <Row type="flex" margin="0">
              <Col>
                <Heading level={6} margin="4px 0 0 0">
                  Instructional Cue
                </Heading>
              </Col>
            </Row>
            <Row type="flex" gutter={20}>
              <Col grow={1}>
                <Input
                  value={program.instructionalCue ?? ""}
                  placeholder=""
                  onChange={(event: any) => {
                    const clone = _.cloneDeep(program);
                    clone.instructionalCue = event.target.value;
                    props.onProgramChange(clone);
                  }}
                />
              </Col>
            </Row>
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" gutter={20}>
        <Col xs={12} md={6}>
          <FormItem>
            <Row type="flex" margin="0">
              <Col>
                <Heading level={6} margin="4px 0 0 0">
                  Reinforcement schedule
                </Heading>
              </Col>
            </Row>
            <Row type="flex" gutter={20}>
              <Col grow={1}>
                <Select
                  dropdownMatchSelectWidth
                  value={
                    program.reinforcementSchedule ===
                    ReinforcementScheduleEnum.NONE
                      ? undefined
                      : program.reinforcementSchedule?.toString()
                  }
                  onChange={value => {
                    const clone = _.cloneDeep(program);
                    clone.reinforcementSchedule =
                      value as ReinforcementScheduleEnum;
                    props.onProgramChange(clone);
                  }}
                  placeholder="Please select"
                >
                  {Object.keys(ReinforcementScheduleEnum)
                    .filter(
                      schedule => schedule !== ReinforcementScheduleEnum.NONE
                    )
                    .map(schedule => (
                      <SelectOption value={schedule} key={schedule}>
                        {LabelHelper.reinforcementScheduleLabel(
                          schedule as ReinforcementScheduleEnum
                        )}
                      </SelectOption>
                    ))}
                  <SelectOption
                    value={ReinforcementScheduleEnum.NONE}
                    key={ReinforcementScheduleEnum.NONE}
                  >
                    &nbsp;
                  </SelectOption>
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Col>
        <Col xs={12} md={6}>
          {program.reinforcementSchedule !==
            ReinforcementScheduleEnum.CONTINUOUS && (
            <FormItem>
              <Row type="flex" margin="0">
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    {ratio ? "Reinforcement ratio" : "Reinforcement interval"}
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={10}>
                <Col xs={ratio ? undefined : 8} grow={ratio ? 1 : undefined}>
                  <InputNumber
                    block={true}
                    placeholder=""
                    value={
                      !ratio &&
                      program.reinforcementRatio &&
                      intervalUnits === IntervalUnitsEnum.MINUTES
                        ? Math.floor(program.reinforcementRatio / 60)
                        : program.reinforcementRatio ?? undefined
                    }
                    onChange={(value: any) => {
                      const clone = _.cloneDeep(program);
                      clone.reinforcementRatio = value as number;
                      props.onProgramChange(clone);
                    }}
                  />
                </Col>
                {!ratio && (
                  <Col grow={1}>
                    <Select
                      dropdownMatchSelectWidth={true}
                      value={intervalUnits}
                      onSelect={(value, option) =>
                        handleIntervalUnitsChange(value as IntervalUnitsEnum)
                      }
                    >
                      {Object.keys(IntervalUnitsEnum).map(unit => (
                        <SelectOption value={unit} key={unit}>
                          {LabelHelper.intervalUnitsLabel(
                            unit as IntervalUnitsEnum
                          )}
                        </SelectOption>
                      ))}
                    </Select>
                  </Col>
                )}
              </Row>
            </FormItem>
          )}
        </Col>
        {!isTemplate && (
          <Col xs={24} md={12}>
            <FormItem>
              <Heading level={6} margin="4px 0 0 0">
                Type of reinforcement
              </Heading>
              <Input
                value={program.typeOfReinforcement ?? ""}
                placeholder=""
                onChange={(event: any) => {
                  const clone = _.cloneDeep(program);
                  clone.typeOfReinforcement = event.target.value;
                  props.onProgramChange(clone);
                }}
              />
            </FormItem>
          </Col>
        )}
      </Row>
      {!isTemplate && (
        <Row type="flex" gutter={20} margin="0 0 64px 0">
          <Col xs={24} md={12}>
            <FormItem>
              <Heading level={6} margin="4px 0 0 0">
                Baseline
              </Heading>
              <InputTextArea
                autoSize={true}
                value={program.baselineText ?? ""}
                onChange={event => {
                  const clone = _.cloneDeep(program);
                  clone.baselineText = event.target.value;
                  props.onProgramChange(clone);
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </Grid>
  );
};
