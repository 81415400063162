import { Button, Col, FormItem, Modal, Row } from "@raudabaugh/thread-ui";

interface ITrialDataFormActionsProps {
  onCancel: () => void;
  onDelete?: () => void;
  onSubmit: () => void;
}

export const TrialDataFormActions = (props: ITrialDataFormActionsProps) => {
  const confirmDelete = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this trial?`,
      content: "This action cannot be undone.",
      okText: "Delete Trial",
      cancelText: "Cancel",
      onOk: () => props.onDelete?.(),
      okButtonProps: { danger: true }
    });
  };

  return (
    <FormItem>
      <Row type="flex">
        <Col grow={1}>
          {props.onDelete && (
            <Button type="danger" onClick={confirmDelete}>
              Delete
            </Button>
          )}
        </Col>
        <Col margin="0 8px">
          <Button type="default" onClick={props.onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={props.onSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </FormItem>
  );
};
