import {
  Button,
  Grid,
  Row,
  Modal,
  Notification,
  Heading,
  Col
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import { StudentBasicInfo } from "./StudentBasicInfo";
import { StudentFiles } from "./StudentFiles";
import { InstructorsAssigned } from "./InstructorsAssigned";
import { Guardian } from "./Guardian";
import { findPermission } from "../../Shared/RolesMap";
import { useHistory } from "react-router";
import { StudentTabEnum } from "Shared/StudentTabEnum";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { useEffect, useState } from "react";
import { useClinicalFilesByStudentQuery } from "../../DataAccess/ClinicalFileData";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import { IStudent } from "Shared/Api/IStudent";
import {
  useStudentQuery,
  useStudentAssignStaffsMutation,
  useStudentRemoveStaffsMutation,
  useStudentRemoveMutation,
  useStudentDeactivateMutation,
  useStudentReactivateMutation
} from "DataAccess/StudentData";
import { StaffFragment } from "Shared/Api/StaffFragment";
import { useStaffsAssignedQuery } from "DataAccess/StaffData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { RouterHelper } from "Routes/RouterHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { StudentStatusEnum, Permissions } from "Shared/Api/globalTypes";
import { SettingsHelper } from "Shared/SettingsHelper";
import { OrganizationSettingsEnum } from "App";
import { usePeopleCountsQuery } from "DataAccess/PeopleData";

interface IStudentProfileProps {
  id: string;
  onBackPressed: () => void;
}

export interface IStudentProfileState {
  error?: ApolloError;
  showSpinner: boolean;
  currentUserMatch: boolean;
  loading: boolean;
}

export const StudentProfile = (props: IStudentProfileProps) => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const SKINNER_STUDENT_ID = "4d8f59ea-1b67-40da-ba49-d13dfc1f476c";
  const { assignStaffToStudent } = useStudentAssignStaffsMutation();
  const { removeStaffFromStudent } = useStudentRemoveStaffsMutation();
  const { removeStudent } = useStudentRemoveMutation();
  const { deactivateStudent } = useStudentDeactivateMutation();
  const { reactivateStudent } = useStudentReactivateMutation();
  const online = useOnlineStatus();
  const { data: countsData } = usePeopleCountsQuery();

  const maxStudents = SettingsHelper.number(
    threadUserContext,
    OrganizationSettingsEnum.MAX_STUDENTS
  );

  const removeFromOrgDialog = (data: IStudent) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${
        data!.student!.firstName
      } from your organization?`,
      content: `This will restrict your organization's access to anything involving this student on Thread, and will remove any associations between ${
        data!.student!.firstName
      } and instructors in your organization.`,
      okText: `Remove ${data!.student!.firstName}`,
      cancelText: "Cancel",
      onOk: () => handleRemove(data!),
      okButtonProps: { danger: true }
    });
  };

  const handleRemove = (data: IStudent) => {
    removeStudent(props.id)
      .then(() => {
        Notification.open({
          duration: 0,
          message: "Student Removed From Organization",
          description: `Student ${data.student?.fullName} has been removed from organization.`
        });
        props.onBackPressed();
      })
      .catch(error =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        })
      );
  };

  const handleDeactivate = (data: IStudent) => {
    deactivateStudent(props.id)
      .then(() => {
        Notification.open({
          duration: 0,
          message: "Student Deactivated",
          description: `Student ${data.student?.fullName} has been deactivated.`
        });
        props.onBackPressed();
      })
      .catch(error =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        })
      );
  };

  const activateDialog = (data: IStudent) => {
    if (
      maxStudents > 0 &&
      countsData?.peopleCounts &&
      countsData.peopleCounts.studentCount >= maxStudents
    ) {
      Modal.error({
        title: `Unable to activate ${data!.student!.firstName}`,
        content: (
          <>
            <Heading level={5}>
              You have reached the maximum number of {maxStudents} active
              students allowed by your subscription of Thread. To upgrade your
              subscription with additional active students, please contact
              sales.
            </Heading>
          </>
        ),
        okCancel: true,
        cancelText: "Contact Sales",
        onCancel: () =>
          (window.location.href =
            "https://go.centralreach.com/mktg/thread/freetrial")
      });
      return;
    }
    Modal.confirm({
      title: `Are you sure you want to activate ${data!.student!.firstName}?`,
      content:
        "Activating this student will make them billable for the current month.",
      okText: `Activate ${data!.student!.firstName}`,
      cancelText: "Cancel",
      onOk: () => handleActivate(data!),
      okButtonProps: { danger: true }
    });
  };

  const handleActivate = (data: IStudent) => {
    reactivateStudent(props.id)
      .then(() => {
        Notification.open({
          duration: 0,
          message: "Student Activated",
          description: `Student ${data.student?.fullName} has been reactivated.`
        });
        props.onBackPressed();
      })
      .catch(error =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        })
      );
  };

  const setLoadingScreen = (value: boolean) => {
    setShowSpinner(value);
  };

  const handleInstructorChanges = async (
    instructorsToSave: StaffFragment[],
    instructorsToRemove: StaffFragment[]
  ) => {
    setShowSpinner(true);

    try {
      await removeStaffFromStudent(
        props.id,
        instructorsToRemove.map(i => i.id)
      );
      await assignStaffToStudent(
        props.id,
        instructorsToSave.map(i => i.id)
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    } finally {
      setShowSpinner(false);
    }
  };

  // data access
  const {
    loading: studentLoading,
    error: studentError,
    data: studentData
  } = useStudentQuery(props.id, { skip: !online });

  const {
    loading: filesLoading,
    error: filesError,
    data: filesData,
    refetch: filesRefetch
  } = useClinicalFilesByStudentQuery(props.id, { skip: !online });

  const {
    loading: relationshipsLoading,
    error: relationshipsError,
    data: relationshipsData
  } = useStaffsAssignedQuery(props.id, { skip: !online });

  const instructors = relationshipsData?.staffsAssigned;
  const student = online
    ? studentData?.student!
    : threadUserContext.assignedStudents.find(
        assigned => assigned.studentId === props.id
      )?.student;
  const currentInstructor = instructors?.find(
    i => i.id === threadUserContext.userId
  );

  useEffect(() => {
    (studentError || relationshipsError || filesError) &&
      NotificationsHelper.ErrorNotification({
        error: studentError ?? relationshipsError ?? filesError,
        title: "Connection Error"
      });
  }, [filesError, relationshipsError, studentError]);

  return (
    <>
      <LoadingScreen
        loading={
          studentLoading || relationshipsLoading || filesLoading || showSpinner
        }
      />
      <Grid color="default">
        {student && (
          <StudentDataTabs
            studentId={student.id}
            activeTab={StudentTabEnum.PROFILE}
          >
            {!online && (
              <>
                <Row padding="0 16px">
                  <Heading level={3}>You are currently offline</Heading>
                </Row>
                <Row padding="8px 16px">
                  <Heading level={5}>
                    Some profile functionality is not available while in offline
                    mode. Please connect to the internet for complete access to
                    the student's profile.
                  </Heading>
                </Row>
              </>
            )}
            <StudentBasicInfo
              firstName={student.firstName || ""}
              lastName={student.lastName || ""}
              birthday={student.birthday || ""}
              eINumber={student.eINumber}
              currentStudents={
                online
                  ? currentInstructor?.assignedStudents
                  : threadUserContext.assignedStudents
              }
              canEdit={
                findPermission(
                  threadUserContext.role,
                  Permissions.EDIT_STUDENT_BASIC_INFO
                ) &&
                (student.id !== SKINNER_STUDENT_ID || threadUserContext.isSysop)
              }
              setLoadingScreen={setLoadingScreen}
              id={props.id}
            />
            {online && (
              <StudentFiles
                setLoadingScreen={setLoadingScreen}
                files={filesData?.clinicalFilesByStudent ?? []}
                id={props.id}
                onFileUploaded={() => filesRefetch()}
              />
            )}
            {online && (
              <InstructorsAssigned
                instructors={instructors ?? []}
                onSave={handleInstructorChanges}
                onSelect={(staffId: string) => {
                  findPermission(
                    threadUserContext.role,
                    Permissions.VIEW_STAFF_PROFILE
                  ) && RouterHelper.redirectToStaffProfile(staffId, history);
                }}
                canEdit={
                  student.status !== StudentStatusEnum.INACTIVE &&
                  findPermission(
                    threadUserContext.role,
                    Permissions.EDIT_ASSIGNED_INSTRUCTORS
                  )
                }
              />
            )}
            <Guardian
              firstName={
                student.primaryGuardian
                  ? student.primaryGuardian.firstName ?? null
                  : null
              }
              lastName={
                student.primaryGuardian
                  ? student.primaryGuardian?.lastName ?? null
                  : null
              }
              email={
                student.primaryGuardian
                  ? student.primaryGuardian?.email ?? null
                  : null
              }
              phoneNumber={
                student.primaryGuardian
                  ? student.primaryGuardian.phoneNumber ?? null
                  : null
              }
              primaryGuardian={true}
              canEdit={
                findPermission(
                  threadUserContext.role,
                  Permissions.EDIT_STUDENT_BASIC_INFO
                ) &&
                online &&
                (student.id !== SKINNER_STUDENT_ID || threadUserContext.isSysop)
              }
              setLoadingScreen={setLoadingScreen}
              id={props.id}
            />
            <Guardian
              firstName={
                student.secondaryGuardian
                  ? student.secondaryGuardian.firstName ?? null
                  : null
              }
              lastName={
                student.secondaryGuardian
                  ? student.secondaryGuardian.lastName ?? null
                  : null
              }
              email={
                student.secondaryGuardian
                  ? student.secondaryGuardian.email ?? null
                  : null
              }
              phoneNumber={
                student.secondaryGuardian
                  ? student.secondaryGuardian.phoneNumber ?? null
                  : null
              }
              primaryGuardian={false}
              canEdit={
                findPermission(
                  threadUserContext.role,
                  Permissions.EDIT_STUDENT_BASIC_INFO
                ) &&
                online &&
                (student.id !== SKINNER_STUDENT_ID || threadUserContext.isSysop)
              }
              setLoadingScreen={setLoadingScreen}
              id={props.id}
            />
            {findPermission(
              threadUserContext.role,
              Permissions.REMOVE_FROM_ORGANIZATION
            ) &&
              (student.id !== SKINNER_STUDENT_ID ||
                threadUserContext.isSysop) &&
              online && (
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={16}
                  padding="0 0 12px 0"
                >
                  {student.status === StudentStatusEnum.INACTIVE && (
                    <>
                      <Col>
                        <Button
                          size="large"
                          onClick={() => activateDialog(studentData!)}
                        >
                          Activate
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          size="large"
                          type="danger"
                          onClick={() => removeFromOrgDialog(studentData!)}
                        >{`Remove ${student.firstName} From Organization`}</Button>
                      </Col>
                    </>
                  )}
                  {student.status === StudentStatusEnum.ACTIVE && (
                    <Button
                      size="large"
                      type="danger"
                      onClick={() => handleDeactivate(studentData!)}
                    >
                      Make Inactive
                    </Button>
                  )}
                </Row>
              )}
          </StudentDataTabs>
        )}
      </Grid>
    </>
  );
};
