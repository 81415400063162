import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal, Notification, Row } from "@raudabaugh/thread-ui";
import moment from "moment";
import { XamarinHelper } from "Shared/XamarinHelper";
import { LoadingScreen } from "../Shared/LoadingScreen";
import {
  useAbcDataByStudentQuery,
  useAbcDataSoftDeleteMutation
} from "../DataAccess/AbcDataData";
import { AuthenticationService } from "../Security/AuthenticationService";
import contentDisposition from "content-disposition";
import FileSaver from "file-saver";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { AbcDataTable } from "./AbcDataTable";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { IABCDataByStudent_aBCDataByStudent } from "Shared/Api/IABCDataByStudent";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { StudentTabEnum } from "Shared/StudentTabEnum";
import { RouterHelper } from "Routes/RouterHelper";

interface AbcDataProps {
  currentStudentId: string;
  containerRef: React.RefObject<any>;
  programId?: string;
}

export const AbcData = (props: AbcDataProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { abcDataSoftDelete } = useAbcDataSoftDeleteMutation();
  const online = useOnlineStatus();

  const availableOffline =
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.currentStudentId && s.availableOffline
    ).length > 0;

  const canViewAbcData = findPermission(
    threadUserContext!.role,
    Permissions.VIEW_ABC_DATA
  );
  const canCreateAbcData = findPermission(
    threadUserContext!.role,
    Permissions.ADD_ABC_DATA
  );
  const hasSoftDeleteAbcDataPermission = findPermission(
    threadUserContext!.role,
    Permissions.SOFT_DELETE_ABC_DATA
  );

  const hasEditAbcDataPermission = findPermission(
    threadUserContext!.role,
    Permissions.EDIT_ABC_DATA
  );

  const {
    loading: abcDataLoading,
    error: abcDataError,
    data: abcData,
    refetch: abcDataRefetch
  } = useAbcDataByStudentQuery(props.currentStudentId, {
    skip: !canViewAbcData || !(online || availableOffline)
  });

  useEffect(() => {
    if (online) {
      abcDataRefetch();
    }
  }, [abcDataRefetch, online]);

  if (!canViewAbcData) {
    RouterHelper.redirectToDefault(history);
  }

  const handleError = (error: Error) => {
    NotificationsHelper.ErrorNotification({
      error,
      title: error.name,
      description: error.message
    });
  };

  const handleSuccess = (title: string, description: string) => {
    Notification.success({
      duration: 0,
      message: title,
      description
    });
  };

  const softDeleteAbcData = (id: string) => {
    abcDataSoftDelete(id, props.currentStudentId)
      .then(() => {
        handleSuccess("ABC Data Deleted", "ABC Data successfully deleted");
      })
      .catch((error: Error) => {
        handleError(error);
      });
  };

  const handleAddAbcDataClick = () => {
    if (!canCreateAbcData) {
      return;
    }
    if (!props.programId) {
      RouterHelper.redirectToAbcDataCreate(props.currentStudentId, history);
    } else {
      RouterHelper.redirectToAbcDataCreateWithProgram(
        props.currentStudentId,
        props.programId,
        history
      );
    }
  };

  const handleDeleteRecord = (record: IABCDataByStudent_aBCDataByStudent) => {
    if (!hasSoftDeleteAbcDataPermission) {
      if (record.createdById !== threadUserContext.userId) {
        RouterHelper.redirectToDefault(history);
        return;
      }
    }

    Modal.warning({
      okCancel: true,
      title: `Are you sure you want to delete this ABC Data?`,
      onOk: () => softDeleteAbcData(record.id),

      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { danger: true }
    });
  };

  const handleEditRecord = (record: IABCDataByStudent_aBCDataByStudent) => {
    const canEditAbcData =
      hasEditAbcDataPermission ||
      threadUserContext.userId === record.createdById;
    if (!canEditAbcData) {
      RouterHelper.redirectToDefault(history);
    }

    if (props.programId) {
      RouterHelper.redirectToAbcDataEditWithProgram(
        props.currentStudentId,
        record.id,
        props.programId,
        history
      );
    } else {
      RouterHelper.redirectToAbcDataEdit(
        props.currentStudentId,
        record.id,
        history
      );
    }
  };

  const handleExport = async () => {
    if (!online) {
      NotificationsHelper.ErrorNotification({
        title: "ABC Data export is unavailable",
        description: "This function is unavailable while offline."
      });
      return;
    }
    try {
      const timeZone =
        typeof Intl === "object"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : undefined;
      let url = `${process.env.REACT_APP_THREADAPI_URL!.replace(
        "Api/GraphQL",
        "ClinicalApi/AbcDataReport"
      )}?studentId=${props.currentStudentId}&timezone=${
        timeZone ?? "America/New_York"
      }`;
      const startDate = moment().subtract(6, "months");
      const endDate = moment();
      url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
      const headers = AuthenticationService.getHeaders();
      const response = await fetch(url, { headers });
      const blob = await response.blob();
      if (!response.headers.get("Content-Disposition"))
        throw new Error("Content-Disposition header missing");
      const filename = contentDisposition.parse(
        response.headers.get("Content-Disposition") ||
          'attachment; filename="download"'
      ).parameters.filename;
      FileSaver.saveAs(blob, filename || "download");
      setLoading(false);
    } catch (e) {
      const error = e as Error;
      setLoading(false);
      NotificationsHelper.ErrorNotification({
        error,
        title: error.name,
        description: error.message
      });
    }
  };

  if (loading || abcDataLoading || !abcData) {
    return <LoadingScreen loading={true} />;
  }

  if (abcDataError) {
    handleError(abcDataError);
  }

  let abcFiltered = [];

  if (props.programId) {
    abcFiltered = abcData.aBCDataByStudent.filter(a =>
      a.programId ? a.programId === props.programId : false
    );
  } else {
    abcFiltered = abcData.aBCDataByStudent;
  }

  return (
    <>
      <StudentDataTabs
        studentId={props.currentStudentId}
        activeTab={StudentTabEnum.ABC_DATA}
      >
        <Row padding="16px" type="flex" alignItems="flex-end" justify="end">
          <Col>
            {canCreateAbcData && (
              <Button type="primary" onClick={handleAddAbcDataClick}>
                Add ABC Data
              </Button>
            )}
          </Col>
          {!XamarinHelper.insideXamarin() && (
            <Col margin="0 0 0 16px">
              <Button block={true} type="primary" onClick={handleExport}>
                Export
              </Button>
            </Col>
          )}
        </Row>
        {abcData && (
          <AbcDataTable
            hasEditAbcDataPermission={hasEditAbcDataPermission}
            hasSoftDeleteAbcDataPermission={hasSoftDeleteAbcDataPermission}
            abcData={abcFiltered}
            onEditRecord={handleEditRecord}
            onDeleteRecord={handleDeleteRecord}
          />
        )}
      </StudentDataTabs>
    </>
  );
};
