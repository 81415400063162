import { Col, Grid, Heading, Icon, Row } from "@raudabaugh/thread-ui";
import * as React from "react";

export interface IFocusBarProps {
  heading: string;
  icon: string;
  isOpen: boolean;
  id: string;
  size?: string;
}

export const FocusBar = (props: IFocusBarProps) => {
  return (
    <Grid>
      <Row type="flex" align="middle" alignItems="center">
        <Col grow={1}>
          <Heading id={props.id} color="default" variation={1} level={5}>
            {props.heading}
          </Heading>
        </Col>
        <Col grow={0}>
          <Icon type={props.icon} width={"50px"} size={props.size} />
        </Col>
      </Row>
    </Grid>
  );
};
