import {
  Col,
  Heading,
  Row,
  Grid,
  Input,
  FormItem,
  InputTextArea,
  Icon,
  Select,
  SelectOption,
  Checkbox,
  InputNumber
} from "@raudabaugh/thread-ui";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import _ from "lodash";
import { StudentsAssigned } from "PeopleManagement/StaffProfile/StudentsAssigned";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DrawerEnum,
  IntervalTypeEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { StudentFragment } from "Shared/Api/StudentFragment";
import { LabelHelper } from "Shared/LabelHelper";
import { IProgramComponentProps } from "./Types";
import { IntervalUnitsEnum } from "./IntervalUnitsEnum";
import { SettingsHelper } from "Shared/SettingsHelper";
import { OrganizationSettingsEnum, ProgramFieldEnum } from "App";
import { PromptingStrategyEnum } from "./PromptingStrategyEnum";
import { Tooltip } from "@raudabaugh/thread-ui";
import { ProgramTabEnum } from "./ProgramTabEnum";
import moment from "moment";

export interface IProgramDetailsProps extends IProgramComponentProps {}

export const ProgramDetails = (props: IProgramDetailsProps) => {
  const inputRef = useRef<HTMLElement>();
  const { threadUserContext } = useThreadContext();
  const [intervalUnits, setIntervalUnits] = useState<IntervalUnitsEnum>(
    (props.program.lengthOfEachInterval ?? 0) % 60 === 0
      ? IntervalUnitsEnum.MINUTES
      : IntervalUnitsEnum.SECONDS
  );
  const secondsPerHour = 60 * 60;
  const { program, students, onValidation, onProgramChange } = props;
  const isTemplate = !students;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (
      program.programType !== ProgramTypeEnum.NONE &&
      program.programType !== null
    ) {
      const clone = _.cloneDeep(program);
      switch (program.programType) {
        case ProgramTypeEnum.DURATION:
        case ProgramTypeEnum.FREQUENCY:
          clone.drawer = DrawerEnum.PUBLIC_DRAWER;
          if (clone.pinned === undefined) {
            clone.pinned = true;
          }
          break;

        case ProgramTypeEnum.DTT:
        case ProgramTypeEnum.TASK_ANALYSIS:
          clone.drawer = DrawerEnum.PRIVATE_DRAWER;
          if (clone.pinned === undefined) {
            clone.pinned = false;
          }
          break;

        case ProgramTypeEnum.INTERVAL:
          if (clone.pinned === undefined) {
            clone.pinned = false;
          }
          clone.drawer =
            clone.pinned ?? false
              ? DrawerEnum.PUBLIC_DRAWER
              : DrawerEnum.PRIVATE_DRAWER;
          break;
      }
      if (clone.drawer !== program.drawer || clone.pinned !== program.pinned) {
        onProgramChange(clone);
      }
    }
  }, [program, program.programType, onProgramChange]);

  const saveStudentChanges = (
    studentsToSave: StudentFragment[],
    studentsToRemove: StudentFragment[]
  ) => {
    if (students) {
      let currentStudents = students.filter(
        s => !studentsToRemove.some(i => i.id === s.id)
      );
      currentStudents = currentStudents.concat(
        studentsToSave.filter(i => !currentStudents.some(s => s.id === i.id))
      );
      props.onStudentChange?.(currentStudents);
    }
  };

  const handleIntervalUnitsChange = (value: IntervalUnitsEnum) => {
    if (program.lengthOfEachInterval) {
      const sampleTime =
        value === IntervalUnitsEnum.MINUTES
          ? program.lengthOfEachInterval * 60
          : program.lengthOfEachInterval / 60;
      const clone = _.cloneDeep(program);
      clone.lengthOfEachInterval = sampleTime;
      props.onProgramChange(clone);
    }
    setIntervalUnits(value);
  };

  const handleIntervalLengthChange = (value?: number | string) => {
    if (value && intervalUnits === IntervalUnitsEnum.MINUTES) {
      value = parseInt(value.toString()) * 60;
    }
    const clone = _.cloneDeep(program);
    clone.lengthOfEachInterval = (value as number) ?? 0;
    props.onProgramChange(clone);
  };

  const discreteTrial = program.programType === ProgramTypeEnum.DTT;
  const interval = program.programType === ProgramTypeEnum.INTERVAL;

  const invalidInterval = useCallback(() => {
    if (!interval || !program.numberOfTrials || !program.lengthOfEachInterval) {
      return undefined;
    }
    let maxHours = 10;
    let totalSeconds = program.numberOfTrials * program.lengthOfEachInterval;
    if (totalSeconds >= maxHours * secondsPerHour) {
      return "Total length of program must be less than 10 hours.";
    }
    return undefined;
  }, [
    interval,
    program.numberOfTrials,
    program.lengthOfEachInterval,
    secondsPerHour
  ]);

  const invalidStudents = useCallback(() => {
    if (students && students.length === 0) {
      return "Must have at least one student assigned";
    }
    return undefined;
  }, [students]);

  const invalidProgramName = useCallback(() => {
    if (!program.programName || program.programName === "") {
      // Name is required
      return "Program name is required";
    }
    return undefined;
  }, [program.programName]);

  const invalidProgramType = useCallback(() => {
    if (
      !isTemplate &&
      (!program.programType || program.programType === ProgramTypeEnum.NONE)
    ) {
      return "Data type is required";
    }
  }, [isTemplate, program.programType]);

  const invalidNumberOfTrials = useCallback(() => {
    if (
      !isTemplate &&
      ((discreteTrial && !program.unlimitedTrials) || interval) &&
      !program.numberOfTrials
    ) {
      // Number of trials is required
      return "Number of trials is required";
    }
    return undefined;
  }, [
    program.unlimitedTrials,
    program.numberOfTrials,
    discreteTrial,
    interval,
    isTemplate
  ]);

  const invalidLengthOfInterval = useCallback(() => {
    if (!isTemplate && interval && !program.lengthOfEachInterval) {
      // Length of each interval is required
      return "Length of each interval is required";
    }
    return undefined;
  }, [program.lengthOfEachInterval, interval, isTemplate]);

  useEffect(() => {
    const isValid: boolean =
      !invalidInterval() &&
      !invalidStudents() &&
      !invalidProgramName() &&
      !invalidProgramType() &&
      !invalidLengthOfInterval() &&
      !invalidNumberOfTrials();
    onValidation(ProgramTabEnum.PROGRAM_DETAILS, isValid);
  }, [
    onValidation,
    program,
    invalidInterval,
    invalidStudents,
    invalidProgramName,
    invalidProgramType,
    invalidLengthOfInterval,
    invalidNumberOfTrials
  ]);

  const programTypes: ProgramTypeEnum[] = [
    ProgramTypeEnum.DURATION,
    ProgramTypeEnum.FREQUENCY,
    ProgramTypeEnum.INTERVAL,
    ProgramTypeEnum.DTT,
    ProgramTypeEnum.TASK_ANALYSIS
  ];

  const addMinAttemptedField = SettingsHelper.exists(
    threadUserContext,
    OrganizationSettingsEnum.ADD_SECTION,
    ProgramFieldEnum.MIN_ATTEMPTED
  );

  const secondsPerDay = secondsPerHour * 24;
  let lengthOfProgram = "-";
  if (program.numberOfTrials && program.lengthOfEachInterval) {
    let totalSeconds = program.numberOfTrials * program.lengthOfEachInterval;
    const format =
      intervalUnits === IntervalUnitsEnum.MINUTES
        ? "H [hours], m [minutes]"
        : totalSeconds >= secondsPerHour
        ? "H [hours], m [minutes], s [seconds]"
        : "m [minutes], s [seconds]";
    lengthOfProgram = moment("2015-01-01")
      .startOf("day")
      .seconds(totalSeconds)
      .format(format);
    if (totalSeconds > secondsPerDay) {
      lengthOfProgram = `${Math.floor(
        totalSeconds / secondsPerDay
      )} days, ${lengthOfProgram}`;
    }
  }

  return (
    <Grid type="flex" margin="0 16px 0 0" variation={1} padding="0">
      <Row type="flex" gutter={20}>
        <Col xs={24} md={12}>
          <FormItem>
            <Row type="flex">
              <Col margin="4px 4px 0 0">
                <Heading level={5} weight="medium" color="error">
                  *
                </Heading>
              </Col>
              <Col>
                <Heading level={6} margin="4px 0 0 0">
                  Program name
                </Heading>
              </Col>
            </Row>
            <Input
              value={program.programName ?? ""}
              innerRef={inputRef}
              placeholder=""
              onChange={(event: any) => {
                const clone = _.cloneDeep(program);
                clone.programName = event.target.value;
                props.onProgramChange(clone);
              }}
            />
            {props.showValidation && invalidProgramName() && (
              <Heading color="error" level={6}>
                {invalidProgramName()}
              </Heading>
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem>
            <Row type="flex">
              <Col margin="4px 4px 0 0">
                <Heading level={5} weight="medium" color="error">
                  *
                </Heading>
              </Col>
              <Col>
                <Heading level={6} margin="4px 0 0 0">
                  Data type
                </Heading>
              </Col>
              {/* Not need for v4.5
              <Col margin="4px 0 0 4px">
                <Icon type="fa-info-circle far" />
              </Col>
              */}
            </Row>
            <Row type="flex" gutter={20}>
              <Col grow={1} xl={12}>
                <Select
                  disabled={props.editing}
                  dropdownMatchSelectWidth={true}
                  value={
                    isTemplate &&
                    (program.programType === null ||
                      program.programType === ProgramTypeEnum.NONE)
                      ? "USER_CHOICE"
                      : program.programType === ProgramTypeEnum.NONE
                      ? ""
                      : program.programType ?? ""
                  }
                  onSelect={(value, option) => {
                    const clone = _.cloneDeep(program);
                    clone.programType =
                      value === "USER_CHOICE"
                        ? ProgramTypeEnum.NONE
                        : (value as ProgramTypeEnum);
                    props.onProgramChange(clone);
                  }}
                >
                  {isTemplate && (
                    <SelectOption value="USER_CHOICE" key="USER_CHOICE">
                      User Choice
                    </SelectOption>
                  )}
                  {programTypes.map(type => (
                    <SelectOption value={type} key={type}>
                      {LabelHelper.programTypeLabel(type)}
                    </SelectOption>
                  ))}
                </Select>
                {props.showValidation && invalidProgramType() && (
                  <Heading color="error" level={6}>
                    {invalidProgramType()}
                  </Heading>
                )}
              </Col>
              <Col grow={1} xl={12}>
                <Row type="flex">
                  <Col margin="4px 0 0 0">
                    <Checkbox
                      checked={program.pinned ?? false}
                      onChange={event => {
                        const clone = _.cloneDeep(program);
                        clone.pinned = !(clone.pinned ?? false);
                        if (interval && !props.editing) {
                          clone.drawer = clone.pinned
                            ? DrawerEnum.PUBLIC_DRAWER
                            : DrawerEnum.PRIVATE_DRAWER;
                        }
                        props.onProgramChange(clone);
                      }}
                    >
                      Pin this program
                    </Checkbox>
                  </Col>
                  <Col margin="4px 0 0 0">
                    <Tooltip title="Pinned programs show up at the top of the blue session drawer. This is used for behaviors that must be accessed at all times.">
                      <Icon type="fa-info-circle far" />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormItem>
        </Col>
      </Row>
      {discreteTrial && (
        <Row type="flex" gutter={20}>
          <Col xs={24} md={12}>
            <FormItem>
              <Row type="flex" margin="0">
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Prompting strategy
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1}>
                  <Select
                    dropdownMatchSelectWidth={true}
                    value={
                      !!program.errorless
                        ? PromptingStrategyEnum.TRIAL
                        : PromptingStrategyEnum.PHASE
                    }
                    onSelect={(value, option) => {
                      const clone = _.cloneDeep(program);
                      clone.errorless =
                        (value as PromptingStrategyEnum) ===
                        PromptingStrategyEnum.TRIAL;
                      props.onProgramChange(clone);
                    }}
                  >
                    {Object.keys(PromptingStrategyEnum).map(result => (
                      <SelectOption value={result} key={result}>
                        {LabelHelper.promptingStrategyLabel(
                          result as PromptingStrategyEnum
                        )}
                      </SelectOption>
                    ))}
                  </Select>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>
      )}
      {(discreteTrial || interval) && (
        <Row type="flex" gutter={20}>
          <Col xs={12} md={6}>
            <FormItem>
              <Row type="flex" margin="0">
                {!isTemplate && (
                  <Col margin="4px 4px 0 0">
                    <Heading level={5} weight="medium" color="error">
                      *
                    </Heading>
                  </Col>
                )}
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Number of trials
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1}>
                  <InputNumber
                    disabled={discreteTrial && !!program.unlimitedTrials}
                    min={1}
                    block={true}
                    placeholder=""
                    value={program.numberOfTrials ?? undefined}
                    onChange={value => {
                      const clone = _.cloneDeep(program);
                      clone.numberOfTrials = value as number;
                      props.onProgramChange(clone);
                    }}
                  />
                  {props.showValidation && invalidNumberOfTrials() && (
                    <Heading color="error" level={6}>
                      {invalidNumberOfTrials()}
                    </Heading>
                  )}
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col xs={12} md={6}>
            {interval && (
              <>
                <Row type="flex" margin="0">
                  <Heading level={6}>Total Length of Program</Heading>
                </Row>
                <Row type="flex">
                  <Heading level={5} margin="10px 0 0 0">
                    {lengthOfProgram}
                  </Heading>
                </Row>
                {props.showValidation && invalidInterval() && (
                  <Heading color="error" level={6}>
                    {invalidInterval()}
                  </Heading>
                )}
              </>
            )}
            <FormItem>
              <Row type="flex"></Row>
              <Row>
                <Col grow={1} xl={12} margin="4px 0 0 0">
                  {discreteTrial && (
                    <Checkbox
                      checked={program.unlimitedTrials ?? false}
                      onChange={event => {
                        const clone = _.cloneDeep(program);
                        clone.unlimitedTrials = !clone.unlimitedTrials;
                        props.onProgramChange(clone);
                      }}
                    >
                      Unlimited trials
                    </Checkbox>
                  )}
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            {addMinAttemptedField && (
              <FormItem>
                <Row type="flex" margin="0">
                  <Heading level={6} margin="4px 0 0 0">
                    Minimum required trials
                  </Heading>
                </Row>
                <Row type="flex" gutter={20}>
                  <Col grow={1} xl={12}>
                    <InputNumber
                      disabled={program.unlimitedTrials ?? false}
                      min={1}
                      block={true}
                      placeholder=""
                      value={program.minimumRequiredTrials ?? undefined}
                      onChange={value => {
                        const clone = _.cloneDeep(program);
                        clone.minimumRequiredTrials = value as number;
                        props.onProgramChange(clone);
                      }}
                    />
                  </Col>
                  <Col grow={1} xl={12} margin="4px 0 0 0">
                    {/* Not need for v4.5
                  <Checkbox
                    checked={program.decreaseBehavior ?? false}
                    onChange={event => {
                      const clone = _.cloneDeep(program);
                      clone.decreaseBehavior = !program.decreaseBehavior;
                      props.onProgramChange(clone);
                    }}
                  >
                    Decrease this behavior
                  </Checkbox>
                  */}
                  </Col>
                </Row>
              </FormItem>
            )}
          </Col>
        </Row>
      )}
      {interval && (
        <Row type="flex" gutter={20}>
          <Col xs={24} md={12}>
            <FormItem>
              <Row type="flex" margin="0">
                {!isTemplate && (
                  <Col margin="4px 4px 0 0">
                    <Heading level={5} weight="medium" color="error">
                      *
                    </Heading>
                  </Col>
                )}
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Length of each interval
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1} xs={12}>
                  <InputNumber
                    min={1}
                    placeholder={""}
                    block={true}
                    value={
                      program.lengthOfEachInterval &&
                      intervalUnits === IntervalUnitsEnum.MINUTES
                        ? Math.floor(program.lengthOfEachInterval / 60)
                        : program.lengthOfEachInterval ?? undefined
                    }
                    onChange={handleIntervalLengthChange}
                  />
                  {props.showValidation && invalidLengthOfInterval() && (
                    <Heading color="error" level={6}>
                      {invalidLengthOfInterval()}
                    </Heading>
                  )}
                </Col>
                <Col grow={1} xs={12}>
                  <Select
                    dropdownMatchSelectWidth={true}
                    value={intervalUnits}
                    onSelect={(value, option) =>
                      handleIntervalUnitsChange(value as IntervalUnitsEnum)
                    }
                  >
                    {Object.keys(IntervalUnitsEnum).map(unit => (
                      <SelectOption value={unit} key={unit}>
                        {LabelHelper.intervalUnitsLabel(
                          unit as IntervalUnitsEnum
                        )}
                      </SelectOption>
                    ))}
                  </Select>
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col xs={12} md={6}>
            <FormItem>
              <Row type="flex" margin="0">
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Interval type
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1}>
                  <Select
                    dropdownMatchSelectWidth={true}
                    value={program.intervalType ?? IntervalTypeEnum.NONE}
                    onSelect={(value, option) => {
                      const clone = _.cloneDeep(program);
                      clone.intervalType = value as IntervalTypeEnum;
                      props.onProgramChange(clone);
                    }}
                  >
                    {Object.keys(IntervalTypeEnum)
                      .filter(type => type !== IntervalTypeEnum.NONE)
                      .map(type => (
                        <SelectOption value={type} key={type}>
                          {LabelHelper.intervalTypeLabel(
                            type as IntervalTypeEnum
                          )}
                        </SelectOption>
                      ))}
                    <SelectOption
                      value={IntervalTypeEnum.NONE}
                      key={IntervalTypeEnum.NONE}
                    >
                      &nbsp;
                    </SelectOption>
                  </Select>
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col xs={12} md={6}>
            <FormItem>
              <Row type="flex" margin="0">
                {!isTemplate && (
                  <Col margin="4px 4px 0 0">
                    <Heading level={5} weight="medium" color="error">
                      *
                    </Heading>
                  </Col>
                )}
                <Col>
                  <Heading level={6} margin="4px 0 0 0">
                    Default trial result
                  </Heading>
                </Col>
              </Row>
              <Row type="flex" gutter={20}>
                <Col grow={1}>
                  <Select
                    dropdownMatchSelectWidth={true}
                    value={program.defaultTrialResult ?? TrialResultEnum.NONE}
                    onSelect={(value, option) => {
                      const clone = _.cloneDeep(program);
                      clone.defaultTrialResult = value as TrialResultEnum;
                      props.onProgramChange(clone);
                    }}
                  >
                    {Object.keys(TrialResultEnum)
                      .filter(result => result !== TrialResultEnum.NONE)
                      .map(result => (
                        <SelectOption value={result} key={result}>
                          {LabelHelper.resultLabel(result as TrialResultEnum)}
                        </SelectOption>
                      ))}
                    <SelectOption
                      value={IntervalTypeEnum.NONE}
                      key={IntervalTypeEnum.NONE}
                    >
                      &nbsp;
                    </SelectOption>
                  </Select>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>
      )}
      <Row type="flex" gutter={20}>
        <Col xs={24} md={12}>
          <FormItem>
            <Heading level={6} margin="4px 0 0 0">
              Program goal
            </Heading>
            <InputTextArea
              value={program.programGoal ?? ""}
              onChange={event => {
                const clone = _.cloneDeep(program);
                clone.programGoal = event.target.value;
                props.onProgramChange(clone);
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem>
            <Heading level={6} margin="4px 0 0 0">
              Prerequisite skills needed
            </Heading>
            <InputTextArea
              value={program.prerequisiteSkillsNeeded ?? ""}
              onChange={event => {
                const clone = _.cloneDeep(program);
                clone.prerequisiteSkillsNeeded = event.target.value;
                props.onProgramChange(clone);
              }}
            />
          </FormItem>
        </Col>
      </Row>
      {students && (
        <Row type="flex" margin="0 0 64px 0">
          <Col xs={24} md={12}>
            <StudentsAssigned
              staffId={threadUserContext.userId}
              students={students}
              onSave={saveStudentChanges}
              showEdit={!props.editing}
              useCardHeader={false}
              showAvailableOfflineColumn={false}
              heading="Student(s)"
              hideColumnHeaders={true}
            />
            {props.showValidation && invalidStudents() && (
              <Heading color="error" level={6}>
                {invalidStudents()}
              </Heading>
            )}
          </Col>
          <Col xs={24} md={12} />
        </Row>
      )}
    </Grid>
  );
};
