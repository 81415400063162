import React, { useCallback, useMemo } from "react";
import moment from "moment";
import {
  Table,
  IColumnProps,
  Heading,
  Link,
  Row,
  Media
} from "@raudabaugh/thread-ui";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { DataPointStateEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { DataPointHelper } from "Shared/DataPointHelper";
import { PhaseFragment } from "Shared/Api/PhaseFragment";

interface IProps {
  chartType: ProgramTypeEnum;
  data: DataPointFragment[];
  phases: PhaseFragment[];
  onRecover?: (id: string) => void;
}

export function RecoverDataModal({
  chartType,
  data,
  phases,
  onRecover
}: IProps) {
  const recoverDataPoint = useCallback(
    id => {
      if (onRecover) {
        onRecover(id);
      }
    },
    [onRecover]
  );

  const calculateScore = useCallback(
    (point: DataPointFragment) => {
      const phase = phases.find(p => p.id === point.phaseId);
      if (!phase) return 0;
      const applicableTrialCount = DataPointHelper.applicableTrialCount(
        chartType,
        point,
        phase
      );

      const correctTrialCount = DataPointHelper.correctTrialCount(
        chartType,
        point,
        phase
      );

      switch (chartType) {
        case ProgramTypeEnum.FREQUENCY:
          return `${correctTrialCount} Occurrences`;
        case ProgramTypeEnum.DURATION:
          const duration = DataPointHelper.durationTotal(
            chartType,
            point,
            phase
          );
          return duration > 300
            ? `${Math.round(duration / 60)} Minutes`
            : `${duration} Seconds`;
        default:
          const percent = correctTrialCount / applicableTrialCount;
          return `${Math.round(
            percent * 100
          )}% over ${applicableTrialCount} trial${
            applicableTrialCount > 1 ? "s" : ""
          }`;
      }
    },
    [chartType, phases]
  );

  const smallCalculateScore = useCallback(
    (point: DataPointFragment) => {
      const phase = phases.find(p => p.id === point.phaseId);
      if (!phase) return 0;
      const applicableTrialCount = DataPointHelper.applicableTrialCount(
        chartType,
        point,
        phase
      );

      const correctTrialCount = DataPointHelper.correctTrialCount(
        chartType,
        point,
        phase
      );

      switch (chartType) {
        case ProgramTypeEnum.FREQUENCY:
          return `${correctTrialCount} Occurrences`;
        case ProgramTypeEnum.DURATION:
          const duration = DataPointHelper.durationTotal(
            chartType,
            point,
            phase
          );
          return duration > 300
            ? `${Math.round(duration / 60)} Minutes`
            : `${duration} Seconds`;
        default:
          const percent = correctTrialCount / applicableTrialCount;
          return `${Math.round(percent * 100)}% of ${applicableTrialCount}`;
      }
    },
    [chartType, phases]
  );

  const completedAtColumn: IColumnProps<DataPointFragment> = useMemo(
    () => ({
      dataIndex: "completedAt",
      render: (value, { createdByName }) => {
        switch (chartType) {
          case ProgramTypeEnum.FREQUENCY:
          case ProgramTypeEnum.DURATION:
            return `${moment.utc(value).local().format("MMM D, YYYY")}`;
          default:
            return `${moment
              .utc(value)
              .local()
              .format("MMM D, YYYY [at] h:mm a")} by ${createdByName}`;
        }
      },
      title: "Date"
    }),
    [chartType]
  );

  const recoverColumn: IColumnProps<DataPointFragment> = useMemo(
    () => ({
      key: "recover",
      render: (_, { id }) => (
        <Link onClick={() => recoverDataPoint(id)}>Recover</Link>
      ),
      width: "5em"
    }),
    [recoverDataPoint]
  );

  const columns: IColumnProps<DataPointFragment>[] = useMemo(
    () => [
      {
        dataIndex: "state",
        render: (_, point) =>
          point.state === DataPointStateEnum.ABANDONED
            ? "Never Charted"
            : "Deleted from Chart",
        title: "Type"
      },
      {
        dataIndex: "y",
        render: (_, point) => calculateScore(point),
        title: "Score",
        width: "10em"
      },
      completedAtColumn,
      recoverColumn
    ],
    [calculateScore, completedAtColumn, recoverColumn]
  );

  const smallColumns: IColumnProps<DataPointFragment>[] = useMemo(
    () => [
      {
        dataIndex: "state",
        render: (_, point) => {
          return (
            <>
              <Row>
                {point.state === DataPointStateEnum.ABANDONED
                  ? "Abandoned"
                  : "Deleted"}
              </Row>
              <Row>{smallCalculateScore(point)}</Row>
            </>
          );
        },
        title: "Type / Score"
      },
      completedAtColumn,
      recoverColumn
    ],
    [smallCalculateScore, completedAtColumn, recoverColumn]
  );

  return (
    <>
      <Heading level={3} margin="0 0 16px 0">
        Deleted Datapoints
      </Heading>
      <Media.Sm orSmaller>
        <Table
          size="small"
          columns={smallColumns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </Media.Sm>
      <Media.Md orLarger>
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </Media.Md>
    </>
  );
}
