import { ProgramFragment } from "./Api/ProgramFragment";
import { IThreadContext } from "./IThreadContext";

export class CurriculumHelper {
  static defaultCurriculumId = "c5131ecd-f66b-07c8-c8c9-0bf6dd85899e";

  static isCopyProtected(
    program: ProgramFragment,
    context: IThreadContext
  ): boolean {
    return (
      !!program.curriculumId &&
      this.isCurriculumCopyProtected(program.curriculumId, context)
    );
  }

  static isCurriculumCopyProtected(
    curriculumId: string,
    context: IThreadContext
  ): boolean {
    return (
      curriculumId !== context.orgId &&
      curriculumId !== context.userId &&
      curriculumId !== CurriculumHelper.defaultCurriculumId
    );
  }
}
