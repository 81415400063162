export const validatePhoneNumber = (number: string) => {
  if (
    /^[+]?\d{0,4}[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
      number
    ) ||
    number === ""
  ) {
    return false;
  }
  return true;
};
