import { XamarinHelper } from "./XamarinHelper";

// most of the persistence related libraries expect an AsyncStorage style, which is
// just a mirror of LocalStorage with promises - though not formally specified.
export interface IAsyncStorage {
  clear(): Promise<void>;
  getItem(key: string): Promise<string | null>;
  removeItem(key: string): Promise<void>;
  setItem(key: string, value: string): Promise<void>;
}

export class AsyncLocalStorage implements IAsyncStorage {
  clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve(localStorage.clear());
    });
  }

  getItem(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      resolve(localStorage.getItem(key));
    });
  }

  removeItem(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve(localStorage.removeItem(key));
    });
  }

  setItem(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve(localStorage.setItem(key, value));
    });
  }
}

export class XamarinStorage implements IAsyncStorage {
  async clear(): Promise<void> {
    await XamarinHelper.clearKeyValueCache();
  }

  async getItem(key: string): Promise<string | null> {
    const response = await XamarinHelper.getFromKeyValueCache(key);
    return response.Value ?? null;
  }

  async removeItem(key: string): Promise<void> {
    await XamarinHelper.removeFromKeyValueCache(key);
  }

  async setItem(key: string, value: string): Promise<void> {
    await XamarinHelper.putIntoKeyValueCache(key, value);
  }
}
