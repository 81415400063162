import { Link, Row, Tooltip, Notification } from "@raudabaugh/thread-ui";
import React from "react";
import {
  ApolloError,
  isApolloError,
  ServerError,
  ServerParseError
} from "@apollo/client";
import { ErrorCodeEnum } from "./ErrorCodeEnum";
import { AuthenticationService } from "../Security/AuthenticationService";

const refreshPage = () => {
  window.location.reload();
};

export interface IError {
  title: string;
  error?: ApolloError | Error;
  description?: string;
  refreshPageOnClose?: boolean;
  onClose?: () => void;
}

export class NotificationsHelper {
  static ErrorNotification = (notificationError: IError) => {
    if (notificationError.error && isApolloError(notificationError.error)) {
      NotificationsHelper.ApolloErrorNotification(notificationError);
    } else {
      NotificationsHelper.StandardErrorNotification(notificationError);
    }
  };

  static StandardErrorNotification = (notificationError: IError) => {
    const description = notificationError.error ? (
      <Row padding="0 10px 0 0">
        <Tooltip title={notificationError.error?.stack ?? ""}>
          {!notificationError.description &&
          notificationError.refreshPageOnClose ? (
            <>
              {"Check your network connection and "}
              <Link onClick={refreshPage}>try again</Link>
              {"."}
            </>
          ) : notificationError.description ? (
            notificationError.description
          ) : (
            "Check your network connection and try again."
          )}
        </Tooltip>
      </Row>
    ) : (
      notificationError.description
    );

    Notification.error({
      message: notificationError.title,
      description,
      onClose: notificationError.onClose,
      duration: 0
    });
  };

  static ApolloErrorNotification = (notificationError: IError) => {
    const error = notificationError.error as ApolloError;
    const errorCode = error?.graphQLErrors[0]?.extensions?.code;
    const data = error?.graphQLErrors[0]?.extensions?.data as any;
    const errorMessage = data?.message as string;
    const statusCode = (error?.networkError as ServerError | ServerParseError)
      ?.statusCode;

    // Handle authentication errors (e.g. expired access token)
    if (statusCode === 401 || errorCode === ErrorCodeEnum.NOT_AUTHENTICATED) {
      const authService = AuthenticationService.getInstance();
      authService.forceLogin();
      return null;
    }

    // Handle authorization errors (e.g. valid user credentials, but insufficient role)
    if (statusCode === 403 || errorCode === ErrorCodeEnum.NOT_AUTHORIZED) {
      document.location.href = "/noaccess";
      return null;
    }

    // Handle student access error
    if (errorCode === ErrorCodeEnum.STUDENT_NOT_FOUND) {
      document.location.href = "/studentnotfound";
      return null;
    }

    let description: string | React.ReactNode = "";

    if (!errorMessage) {
      description = notificationError.error ? (
        <Row padding="0 10px 0 0">
          <Tooltip title={error?.message ?? ""}>
            {notificationError.refreshPageOnClose}
            {errorCode === ErrorCodeEnum.VALIDATION ? (
              error?.graphQLErrors[0].message
            ) : !notificationError.description &&
              notificationError.refreshPageOnClose ? (
              <>
                {"Check your network connection and "}
                <Link onClick={refreshPage}>try again</Link>
                {"."}
              </>
            ) : notificationError.description ? (
              notificationError.description
            ) : (
              "Check your network connection and try again."
            )}
          </Tooltip>
        </Row>
      ) : (
        notificationError.description
      );
    } else {
      description = <Row padding="0 10px 0 0">{errorMessage}</Row>;
    }

    Notification.error({
      message: notificationError.title,
      description,
      onClose: notificationError.onClose,
      duration: 0
    });
  };

  static BasicErrorNotification = (
    title: React.ReactNode,
    description: React.ReactNode,
    onClose?: () => void
  ) => {
    Notification.error({
      message: title,
      description,
      onClose,
      duration: 0
    });
  };
}
