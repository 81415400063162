/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArchiveOrderBy {
  ARCHIVE_TYPE = "ARCHIVE_TYPE",
  LAST_RUN = "LAST_RUN",
  TITLE = "TITLE",
  TYPE = "TYPE",
}

export enum ArchiveSortDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum ArchiveTypeEnum {
  DISCONTINUED = "DISCONTINUED",
  MASTERED = "MASTERED",
  NONE = "NONE",
  ON_HOLD = "ON_HOLD",
}

export enum ClinicalFileTypeEnum {
  BEHAVIOR_INTERVENTION_PLAN = "BEHAVIOR_INTERVENTION_PLAN",
  INDIVIDUALIZED_EDUCATION_PLAN = "INDIVIDUALIZED_EDUCATION_PLAN",
  NONE = "NONE",
  OTHER = "OTHER",
  THREAD_REPORTS = "THREAD_REPORTS",
}

export enum CollectionMethodEnum {
  DEVICE = "DEVICE",
  NONE = "NONE",
  PAPER = "PAPER",
}

export enum CurriculumTypeEnum {
  CUSTOM = "CUSTOM",
  STANDARD = "STANDARD",
  USER = "USER",
}

export enum CustomSettingControlEnum {
  CHECKBOX = "CHECKBOX",
  CHECKBOX_LIST = "CHECKBOX_LIST",
  CUSTOM_LIST = "CUSTOM_LIST",
  DATE_PICKER = "DATE_PICKER",
  MULTI_VALUE_LIST = "MULTI_VALUE_LIST",
  NONE = "NONE",
  NUMERIC = "NUMERIC",
  OPTIONAL_DROPDOWN = "OPTIONAL_DROPDOWN",
  OPTIONAL_NUMERIC = "OPTIONAL_NUMERIC",
  RADIO_LIST = "RADIO_LIST",
}

export enum CustomSettingStatusEnum {
  DEPRECATED = "DEPRECATED",
  INTERNAL_ONLY = "INTERNAL_ONLY",
  NONE = "NONE",
  PUBLISHED = "PUBLISHED",
}

export enum DataPointStateEnum {
  ABANDONED = "ABANDONED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NONE = "NONE",
  NOT_STARTED = "NOT_STARTED",
}

export enum DataPointTypeEnum {
  NONE = "NONE",
  POSTTEST = "POSTTEST",
  PRETEST = "PRETEST",
  PROBE = "PROBE",
  STANDARD = "STANDARD",
}

export enum DrawerEnum {
  NONE = "NONE",
  PRIVATE_DRAWER = "PRIVATE_DRAWER",
  PUBLIC_DRAWER = "PUBLIC_DRAWER",
}

export enum GuardianOptOutEnum {
  NONE = "NONE",
  NO_CONTACT = "NO_CONTACT",
  NO_EMAIL = "NO_EMAIL",
  OTHER = "OTHER",
}

export enum IntervalTypeEnum {
  FULL_INTERVAL = "FULL_INTERVAL",
  MOMENTARY_TIME_SAMPLING = "MOMENTARY_TIME_SAMPLING",
  NONE = "NONE",
  PARTIAL_INTERVAL = "PARTIAL_INTERVAL",
}

export enum LockedTypeEnum {
  CRITERION_MET = "CRITERION_MET",
  MINIMAL_PROGRESS = "MINIMAL_PROGRESS",
  NONE = "NONE",
}

export enum ManagementAppEnum {
  NONE = "NONE",
  PEOPLE_MANAGEMENT = "PEOPLE_MANAGEMENT",
  PMTABA = "PMTABA",
  PRACTICE_MANAGEMENT = "PRACTICE_MANAGEMENT",
}

export enum OrganizationTypeEnum {
  CLINIC = "CLINIC",
  DEMO = "DEMO",
  FREE = "FREE",
  IN_HOME = "IN_HOME",
  NONE = "NONE",
  OTHER = "OTHER",
  RESIDENTIAL = "RESIDENTIAL",
  SCHOOL = "SCHOOL",
}

export enum Permissions {
  ADD_ABC_DATA = "ADD_ABC_DATA",
  ADD_CLINICAL_FILES = "ADD_CLINICAL_FILES",
  ADD_CURRICULUM_TEMPLATES = "ADD_CURRICULUM_TEMPLATES",
  ADD_NEW_ORGANIZATION = "ADD_NEW_ORGANIZATION",
  ADD_NEW_PEOPLE = "ADD_NEW_PEOPLE",
  ADD_TO_ARCHIVE = "ADD_TO_ARCHIVE",
  ADD_TRIALS = "ADD_TRIALS",
  COMPLETE_PROGRAM_FROM_MAINTENANCE = "COMPLETE_PROGRAM_FROM_MAINTENANCE",
  DELETE_TRIALS = "DELETE_TRIALS",
  DOWNLOAD_CURRICULUM = "DOWNLOAD_CURRICULUM",
  DOWNLOAD_ORG_REPORTS = "DOWNLOAD_ORG_REPORTS",
  DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS",
  EDIT_ABC_DATA = "EDIT_ABC_DATA",
  EDIT_ALL_NOTES = "EDIT_ALL_NOTES",
  EDIT_ASSIGNED_INSTRUCTORS = "EDIT_ASSIGNED_INSTRUCTORS",
  EDIT_ASSIGNED_STUDENTS = "EDIT_ASSIGNED_STUDENTS",
  EDIT_CLINICAL_FILES = "EDIT_CLINICAL_FILES",
  EDIT_CURRICULUM_FOLDERS = "EDIT_CURRICULUM_FOLDERS",
  EDIT_CURRICULUM_TEMPLATES = "EDIT_CURRICULUM_TEMPLATES",
  EDIT_DATA_POINTS_DATE_AND_PHASE = "EDIT_DATA_POINTS_DATE_AND_PHASE",
  EDIT_LICENSED_CURRICULUM = "EDIT_LICENSED_CURRICULUM",
  EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS",
  EDIT_ORG_PROFILE = "EDIT_ORG_PROFILE",
  EDIT_ORG_SETTINGS = "EDIT_ORG_SETTINGS",
  EDIT_PROGRAMS = "EDIT_PROGRAMS",
  EDIT_ROLES = "EDIT_ROLES",
  EDIT_STAFF_BASIC_INFO = "EDIT_STAFF_BASIC_INFO",
  EDIT_STUDENT_BASIC_INFO = "EDIT_STUDENT_BASIC_INFO",
  EDIT_TRIALS = "EDIT_TRIALS",
  NONE = "NONE",
  RECEIVE_CRITERION_MET_NOTIFICATIONS = "RECEIVE_CRITERION_MET_NOTIFICATIONS",
  RECEIVE_MINIMAL_PROGRESS_NOTIFICATIONS = "RECEIVE_MINIMAL_PROGRESS_NOTIFICATIONS",
  RECEIVE_STALE_PROGRAM_NOTIFICATIONS = "RECEIVE_STALE_PROGRAM_NOTIFICATIONS",
  REMOVE_FROM_ORGANIZATION = "REMOVE_FROM_ORGANIZATION",
  REMOVE_ORGANIZATION = "REMOVE_ORGANIZATION",
  RESTORE_DATA_POINT = "RESTORE_DATA_POINT",
  RESTORE_FROM_ARCHIVE = "RESTORE_FROM_ARCHIVE",
  RUN_BACKGROUND_JOB = "RUN_BACKGROUND_JOB",
  SOFT_DELETE_ABC_DATA = "SOFT_DELETE_ABC_DATA",
  SOFT_DELETE_CLINICAL_FILES = "SOFT_DELETE_CLINICAL_FILES",
  SOFT_DELETE_DATA_POINT = "SOFT_DELETE_DATA_POINT",
  SOFT_DELETE_FROM_ARCHIVE = "SOFT_DELETE_FROM_ARCHIVE",
  UNLOCK_PROGRAMS = "UNLOCK_PROGRAMS",
  UPLOAD_CURRICULUM = "UPLOAD_CURRICULUM",
  VIEW_ABC_DATA = "VIEW_ABC_DATA",
  VIEW_ALL_NOTES = "VIEW_ALL_NOTES",
  VIEW_ALL_ORGANIZATIONS = "VIEW_ALL_ORGANIZATIONS",
  VIEW_ALL_STAFF = "VIEW_ALL_STAFF",
  VIEW_ALL_STUDENTS = "VIEW_ALL_STUDENTS",
  VIEW_ARCHIVE = "VIEW_ARCHIVE",
  VIEW_ASSIGNED_STUDENTS = "VIEW_ASSIGNED_STUDENTS",
  VIEW_CLINICAL_FILES = "VIEW_CLINICAL_FILES",
  VIEW_MAINTENANCE = "VIEW_MAINTENANCE",
  VIEW_NOTIFICATIONS = "VIEW_NOTIFICATIONS",
  VIEW_ORG_PROFILE = "VIEW_ORG_PROFILE",
  VIEW_ORG_SETTINGS = "VIEW_ORG_SETTINGS",
  VIEW_PHONE_NUMBER = "VIEW_PHONE_NUMBER",
  VIEW_STAFF_PROFILE = "VIEW_STAFF_PROFILE",
  VIEW_STUDENT_PROFILE = "VIEW_STUDENT_PROFILE",
  VIEW_TRIALS = "VIEW_TRIALS",
}

export enum PhaseSettingEnum {
  COMMUNITY_PARK = "COMMUNITY_PARK",
  COMMUNITY_STORE = "COMMUNITY_STORE",
  DT = "DT",
  DTT = "DTT",
  HOME = "HOME",
  INDIVIDUAL = "INDIVIDUAL",
  LARGE_GROUP = "LARGE_GROUP",
  NET = "NET",
  NONE = "NONE",
  PEER = "PEER",
  SMALL_GROUP = "SMALL_GROUP",
  ST = "ST",
}

export enum PlaceOfServiceEnum {
  HOME = "HOME",
  NONE = "NONE",
  OFFICE = "OFFICE",
  SCHOOL = "SCHOOL",
  TELEHEALTH = "TELEHEALTH",
}

export enum ProgramTypeEnum {
  DTT = "DTT",
  DURATION = "DURATION",
  FREQUENCY = "FREQUENCY",
  INTERVAL = "INTERVAL",
  NONE = "NONE",
  TASK_ANALYSIS = "TASK_ANALYSIS",
}

export enum ReinforcementScheduleEnum {
  CONTINUOUS = "CONTINUOUS",
  FIXED_INTERVAL = "FIXED_INTERVAL",
  FIXED_RATIO = "FIXED_RATIO",
  NONE = "NONE",
  VARIABLE_INTERVAL = "VARIABLE_INTERVAL",
  VARIABLE_RATIO = "VARIABLE_RATIO",
}

export enum SessionNoteStatusEnum {
  NEEDS_AUTHORIZED = "NEEDS_AUTHORIZED",
  NEEDS_DETAIL = "NEEDS_DETAIL",
  NEEDS_SIGNATURE = "NEEDS_SIGNATURE",
  NONE = "NONE",
  SIGNED = "SIGNED",
  SUBMITTED = "SUBMITTED",
}

export enum SessionNoteStatusFilter {
  DRAFT_ONLY = "DRAFT_ONLY",
  NONE = "NONE",
  SUBMITTED_ONLY = "SUBMITTED_ONLY",
}

export enum StaffRoleEnum {
  ADMIN = "ADMIN",
  INSTRUCTOR = "INSTRUCTOR",
  NONE = "NONE",
  SUPERVISOR = "SUPERVISOR",
}

export enum StaffStatusEnum {
  ACTIVE = "ACTIVE",
  DECLINED = "DECLINED",
  INACTIVE = "INACTIVE",
  NONE = "NONE",
  PENDING = "PENDING",
}

export enum StepStateEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NONE = "NONE",
}

export enum StudentStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NONE = "NONE",
}

export enum TargetAction {
  COMPLETE = "COMPLETE",
  NONE = "NONE",
  RESET_COUNTERS = "RESET_COUNTERS",
  UPDATE_MINUS_COUNTER = "UPDATE_MINUS_COUNTER",
  UPDATE_PLUS_COUNTER = "UPDATE_PLUS_COUNTER",
}

export enum TargetStateEnum {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
  MASTERED = "MASTERED",
  NONE = "NONE",
}

export enum TrialResultEnum {
  MINUS = "MINUS",
  NONE = "NONE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PLUS = "PLUS",
}

/**
 * An ABC Data
 */
export interface ABCDataInput {
  antecedent?: string | null;
  behavior?: string | null;
  consequence?: string | null;
  date?: DateTime | null;
  id?: string | null;
  note?: string | null;
  programId?: GUID | null;
  setting?: string | null;
}

/**
 * A criterion rule
 */
export interface CriterionInput {
  id: GUID;
  minPercentage?: number | null;
  pointsAnalyzed?: number | null;
  softDeleted?: boolean | null;
}

/**
 * A criterion rule
 */
export interface CriterionTemplateInput {
  id: GUID;
  minPercentage?: number | null;
  pointsAnalyzed?: number | null;
  softDeleted?: boolean | null;
}

/**
 * Chart point input
 */
export interface DataPointInput {
  completedAt?: DateTime | null;
  note?: string | null;
  phaseId?: GUID | null;
  pointType?: DataPointTypeEnum | null;
}

/**
 * List of ids of messages to clear
 */
export interface MessageClearInput {
  messageIds: GUID[];
}

/**
 * An organization
 */
export interface OrganizationInput {
  address1?: string | null;
  address2?: string | null;
  alias?: string | null;
  city?: string | null;
  managementApp?: ManagementAppEnum | null;
  name?: string | null;
  postalZip?: string | null;
  state?: string | null;
  totalLocations?: number | null;
  totalStudents?: number | null;
  type?: OrganizationTypeEnum | null;
  websiteUrl?: string | null;
}

/**
 * An organization setting
 */
export interface OrganizationSettingInput {
  id: string;
  softDeleted?: boolean | null;
  value: string;
}

/**
 * Organization settings
 */
export interface OrganizationSettingsInput {
  settings: OrganizationSettingInput[];
}

/**
 * An input payload for program create and update mutations
 */
export interface ProgramInput {
  baselineText?: string | null;
  criterionForMastery?: CriterionInput[] | null;
  criterionForMasteryText?: string | null;
  curriculumId?: GUID | null;
  defaultMethod?: CollectionMethodEnum | null;
  defaultTrialResult?: TrialResultEnum | null;
  definitionOfBehavior?: string | null;
  drawer?: DrawerEnum | null;
  errorless?: boolean | null;
  frequencyOfDataCollectionText?: string | null;
  instructionalCue?: string | null;
  intervalType?: IntervalTypeEnum | null;
  lengthOfEachInterval?: number | null;
  materials?: string | null;
  minimumRequiredTrials?: number | null;
  numberOfTrials?: number | null;
  phaseNameOverride?: string | null;
  phaseSummary?: string | null;
  phaseTargetIds?: GUID[] | null;
  pinned?: boolean | null;
  prerequisiteSkillsNeeded?: string | null;
  procedureDetails?: string | null;
  programGoal?: string | null;
  programName?: string | null;
  programType?: ProgramTypeEnum | null;
  prompt?: string | null;
  promptingProcedure?: string | null;
  reinforcementRatio?: number | null;
  reinforcementSchedule?: ReinforcementScheduleEnum | null;
  setting?: PhaseSettingEnum | null;
  steps?: StepInput[] | null;
  targets?: TargetInput[] | null;
  templateId?: GUID | null;
  tips?: string | null;
  typeOfReinforcement?: string | null;
  unitGoal?: string | null;
  unlimitedTrials?: boolean | null;
}

/**
 * A curriculum program template
 */
export interface ProgramTemplateInput {
  abllsMastery?: string | null;
  baselineText?: string | null;
  criterionForMastery?: CriterionTemplateInput[] | null;
  criterionForMasteryText?: string | null;
  dataRecording?: string | null;
  defaultMethod?: CollectionMethodEnum | null;
  defaultTrialResult?: TrialResultEnum | null;
  definitionOfBehavior?: string | null;
  drawer?: DrawerEnum | null;
  errorCorrection?: string | null;
  errorless?: boolean | null;
  frequencyOfDataCollectionText?: string | null;
  id?: string | null;
  instructionalCue?: string | null;
  intervalType?: IntervalTypeEnum | null;
  lengthOfEachInterval?: number | null;
  materials?: string | null;
  minimumRequiredTrials?: number | null;
  numberOfTrials?: number | null;
  phaseNameOverride?: string | null;
  phaseSummary?: string | null;
  pinned?: boolean | null;
  prerequisiteSkillsNeeded?: string | null;
  procedureDetails?: string | null;
  programGoal?: string | null;
  programName?: string | null;
  programType?: ProgramTypeEnum | null;
  prompt?: string | null;
  promptingProcedure?: string | null;
  reinforcementRatio?: number | null;
  reinforcementSchedule?: ReinforcementScheduleEnum | null;
  steps?: StepTemplateInput[] | null;
  targets?: TargetTemplateInput[] | null;
  teachingProcedure?: string | null;
  tips?: string | null;
  typeOfReinforcement?: string | null;
  unitGoal?: string | null;
  unlimitedTrials?: boolean | null;
  vbmappTags?: string | null;
}

/**
 * A session note
 */
export interface SessionNoteInput {
  behaviorDetails?: string | null;
  end?: DateTime | null;
  placeOfService?: PlaceOfServiceEnum | null;
  serviceType?: string | null;
  signature?: string | null;
  skillDetails?: string | null;
  smartBehaviorDetails?: string | null;
  smartSkillDetails?: string | null;
  start?: DateTime | null;
  status?: SessionNoteStatusEnum | null;
  studentId?: GUID | null;
}

/**
 * Staff Assigned Student
 */
export interface StaffAssignedStudentInput {
  availableOffline?: boolean | null;
}

/**
 * User input payload for a staff member.
 */
export interface StaffInput {
  avatarData?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  role?: StaffRoleEnum | null;
  userName?: string | null;
}

/**
 * A task analysis step
 */
export interface StepInput {
  description?: string | null;
  id: GUID;
  softDeleted?: boolean | null;
  state?: StepStateEnum | null;
}

/**
 * A task analysis step
 */
export interface StepTemplateInput {
  description?: string | null;
  id: GUID;
  softDeleted?: boolean | null;
}

/**
 * User input payload for a student
 */
export interface StudentInput {
  alerts?: string | null;
  avatarData?: string | null;
  birthday?: DateTime | null;
  eINumber?: string | null;
  firstName?: string | null;
  guardianOptOutComment?: string | null;
  guardianOptOutReason?: GuardianOptOutEnum | null;
  lastName?: string | null;
  primaryEmail?: string | null;
  primaryFirstName?: string | null;
  primaryLastName?: string | null;
  primaryPhoneNumber?: string | null;
  secondaryEmail?: string | null;
  secondaryFirstName?: string | null;
  secondaryLastName?: string | null;
  secondaryPhoneNumber?: string | null;
}

/**
 * A phase target
 */
export interface TargetInput {
  completed?: boolean | null;
  id: GUID;
  mastered?: DateTimeOffset | null;
  softDeleted?: boolean | null;
  state?: TargetStateEnum | null;
  targetDescription?: string | null;
}

/**
 * A phase target
 */
export interface TargetTemplateInput {
  id: GUID;
  softDeleted?: boolean | null;
  targetDescription?: string | null;
}

/**
 * A trial
 */
export interface TrialInput {
  duration?: number | null;
  id?: GUID | null;
  occurredAt?: DateTime | null;
  prompt?: string | null;
  result?: TrialResultEnum | null;
  stepId?: GUID | null;
  targetId?: GUID | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
