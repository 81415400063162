import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { RouterHelper } from "./RouterHelper";
import moment from "moment";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OfflinePageUnavailable } from "./OfflinePageUnavailable";
import { LabelHelper } from "Shared/LabelHelper";

// Reports

const StudentReport = React.lazy(() =>
  import(
    "../Reports/StudentReport" /* webpackChunkName: "student-report" */
  ).then(module => ({
    default: module.StudentReport
  }))
);

const OrganizationReport = React.lazy(() =>
  import(
    "../Reports/OrganizationReport" /* webpackChunkName: "organization-report" */
  ).then(module => ({
    default: module.OrganizationReport
  }))
);

const SystemReport = React.lazy(() =>
  import(
    "../Reports/SystemReport" /* webpackChunkName: "system-report" */
  ).then(module => ({
    default: module.SystemReport
  }))
);

const BillingReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (
    !findPermission(threadUserContext.role, Permissions.DOWNLOAD_ORG_REPORTS)
  ) {
    RouterHelper.redirectToDefault(history);
  }

  const defaultStartDate = moment().subtract(1, "months");
  const defaultEndDate = moment();

  return (
    <OrganizationReport
      title="Download Billing Report"
      endpoint="BillingReport"
      dateRange="DAY"
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
    />
  );
};

const FinanceReportDetailed = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!threadUserContext.isSysop) {
    RouterHelper.redirectToDefault(history);
  }

  const defaultStartDate = moment().subtract(1, "months");
  const defaultEndDate = moment();
  const featureDeployedOn = moment(new Date(2022, 4, 1));
  const notice = `Staff and Student counts prior to ${LabelHelper.dateLabel(
    featureDeployedOn,
    threadUserContext
  )} may not be accurate.`;

  return (
    <OrganizationReport
      title="Download Staff/Students Billed Report"
      endpoint="FinanceDetailedReport"
      dateRange="MONTH"
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      notice={notice}
    />
  );
};

const AbcDataDumpReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
    RouterHelper.redirectToDefault(history);
  }

  const { studentId, setStudentId } = useStudentContext();

  const handleSelectStudent = (studentId: string) =>
    RouterHelper.handleStudentChange(
      RoutePathEnum.REPORT_ABC_DATA,
      studentId,
      history
    );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  const defaultStartDate = moment().subtract(6, "months");
  const defaultEndDate = moment();

  if (studentId) {
    return (
      <StudentReport
        currentStudentId={studentId}
        onSelectStudent={handleSelectStudent}
        title="Download ABC Data Report"
        endpoint="AbcDataReport"
        dateRange={true}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.REPORT_ABC_DATA_DEFAULT,
          state: {
            sideMenu: RoutePathEnum.REPORT_ABC_DATA_DEFAULT,
            current: RoutePathEnum.REPORT_ABC_DATA_DEFAULT
          }
        }}
      />
    );
  }
};

const FinanceReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!threadUserContext.isSysop) RouterHelper.redirectToDefault(history);
  return (
    <SystemReport
      title="Run Finance Report"
      notice="Running this report will generate an email to all report recipients"
    />
  );
};

const ProgressReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
    RouterHelper.redirectToDefault(history);
  }
  const { studentId, setStudentId } = useStudentContext();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  const defaultStartDate = moment().subtract(6, "months");
  const defaultEndDate = moment();

  if (studentId) {
    return (
      <StudentReport
        currentStudentId={studentId}
        onSelectStudent={studentId =>
          RouterHelper.handleStudentChange(
            RoutePathEnum.REPORT_PROGRESS,
            studentId,
            history,
            true
          )
        }
        title="Download Progress Report"
        endpoint="ProgressReport"
        dateRange={true}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.REPORT_PROGRESS_DEFAULT,
          state: {
            sideMenu: RoutePathEnum.REPORT_PROGRESS_DEFAULT,
            current: RoutePathEnum.REPORT_PROGRESS_DEFAULT
          }
        }}
      />
    );
  }
};

const SessionNoteReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
    RouterHelper.redirectToDefault(history);
  }

  const { studentId, setStudentId } = useStudentContext();

  const handleSelectStudent = (studentId: string) =>
    RouterHelper.handleStudentChange(
      RoutePathEnum.REPORT_SESSION_NOTE,
      studentId,
      history,
      true
    );

  const defaultStartDate = moment().subtract(1, "months");
  const defaultEndDate = moment();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <StudentReport
        currentStudentId={studentId}
        onSelectStudent={handleSelectStudent}
        title="Download Session Note Report"
        notice="This report contains all session notes that are signed or ready to sign. It does not contain session note drafts."
        endpoint="SessionNoteReport"
        dateRange={true}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.REPORT_SESSION_NOTE_DEFAULT,
          state: {
            sideMenu: RoutePathEnum.REPORT_SESSION_NOTE_DEFAULT,
            current: RoutePathEnum.REPORT_SESSION_NOTE_DEFAULT
          }
        }}
      />
    );
  }
};

const RawDataReport = () => {
  const history = useHistory();
  const { threadUserContext } = useThreadContext();
  if (!findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
    RouterHelper.redirectToDefault(history);
  }

  const { studentId, setStudentId } = useStudentContext();

  const handleSelectStudent = (studentId: string) =>
    RouterHelper.handleStudentChange(
      RoutePathEnum.REPORT_RAW_DATA,
      studentId,
      history,
      true
    );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <StudentReport
        currentStudentId={studentId!}
        onSelectStudent={handleSelectStudent}
        title="Download Raw Data"
        endpoint="RawDataReport"
        dateRange={false}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.REPORT_RAW_DATA_DEFAULT,
          state: {
            sideMenu: RoutePathEnum.REPORT_RAW_DATA_DEFAULT,
            current: RoutePathEnum.REPORT_RAW_DATA_DEFAULT
          }
        }}
      />
    );
  }
};

interface ReportsRouterProps {}

export const ReportsRouter = (props: ReportsRouterProps) => {
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }

  return (
    <Switch>
      <Route path={RoutePathEnum.REPORT_PROGRESS} exact={true}>
        <ProgressReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_RAW_DATA} exact={true}>
        <RawDataReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_SESSION_NOTE} exact={true}>
        <SessionNoteReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_BILLING} exact={true}>
        <BillingReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_ABC_DATA} exact={true}>
        <AbcDataDumpReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_FINANCE} exact={true}>
        <FinanceReport />
      </Route>
      <Route path={RoutePathEnum.REPORT_FINANCE_DETAILED} exact={true}>
        <FinanceReportDetailed />
      </Route>
      <Route path={RoutePathEnum.REPORT_PROGRESS_DEFAULT}>
        <DefaultStudent path={RoutePathEnum.REPORT_PROGRESS} />
      </Route>
      <Route path={RoutePathEnum.REPORT_RAW_DATA_DEFAULT}>
        <DefaultStudent path={RoutePathEnum.REPORT_RAW_DATA} />
      </Route>
      <Route path={RoutePathEnum.REPORT_SESSION_NOTE_DEFAULT}>
        <DefaultStudent path={RoutePathEnum.SESSION_NOTES} />
      </Route>
      <Route path={RoutePathEnum.REPORT_ABC_DATA_DEFAULT}>
        <DefaultStudent path={RoutePathEnum.REPORT_ABC_DATA} />
      </Route>
    </Switch>
  );
};
