import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { client } from "Shared/ApolloHelper";
import { useQuery } from "@apollo/client";

export interface IStudentContext {
  __typename: "StudentContext";
  studentId?: string;
}

const studentContextQuery = gql`
  query studentContext {
    studentId
  }
`;

export const getStudentContext = (): IStudentContext => {
  const context = client.readQuery<IStudentContext>({
    query: studentContextQuery
  });
  return context ?? { __typename: "StudentContext" };
};

const updateStudentContext = (update: Partial<IStudentContext>) => {
  const current = getStudentContext();
  client.writeQuery<IStudentContext>({
    query: studentContextQuery,
    data: {
      ...current,
      ...update,

      __typename: "StudentContext"
    }
  });
};

const clearStudentContext = () => {
  client.writeQuery<IStudentContext>({
    query: studentContextQuery,
    data: {
      __typename: "StudentContext"
    }
  });
};

interface IStudentParams {
  studentId?: string;
}

export function useStudentContext() {
  const { data: state } = useQuery<IStudentContext>(studentContextQuery, {
    client,
    fetchPolicy: "cache-only",
    returnPartialData: true
  });
  let { studentId } = useParams<IStudentParams>();
  studentId = studentId ?? state?.studentId;
  const setStudentId = () => {
    if (studentId && studentId !== state?.studentId) {
      updateStudentContext({ studentId });
    }
  };
  const clearStudentId = () => {
    clearStudentContext();
  };
  return { studentId, setStudentId, clearStudentId };
}
