import {
  Button,
  Card,
  Col,
  Grid,
  Heading,
  Row,
  Modal,
  Tabs,
  TabPane,
  Link,
  Icon
} from "@raudabaugh/thread-ui";
import React, { useEffect, useState } from "react";
import {
  useProgramChartQuery,
  useProgramSessionsQuery,
  useProgramUpdateMutation
} from "../DataAccess/ProgramData";
import DiscardChangesModal from "Shared/DiscardChangesModal";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { ApolloError } from "@apollo/client";
import { LabelHelper } from "../Shared/LabelHelper";
import {
  CriterionInput,
  DataPointStateEnum,
  ProgramInput,
  ProgramTypeEnum,
  StepInput,
  TargetInput,
  TargetStateEnum,
  TrialResultEnum
} from "../Shared/Api/globalTypes";
import { NotificationsHelper } from "../Shared/NotificationsHelper";
import { StudentFragment } from "../Shared/Api/StudentFragment";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ProgramTabEnum } from "./ProgramTabEnum";
import { IProgramFormStep } from "./Types";
import { PhaseDetails } from "./PhaseDetails";
import moment from "moment";
import ProgramInfoModal from "./ProgramInfoModal";
import _ from "lodash";
import { HistoricalTargets } from "./Targets";

export interface IProgramEditHistoricalProps {
  phaseId: string;
  programId: string;
  studentId: string;
  onClose: () => void;
}

export const ProgramEditHistorical = (props: IProgramEditHistoricalProps) => {
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const { programUpdate } = useProgramUpdateMutation();
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [pointsInPhase, setPointsInPhase] = useState(0);
  const [datesOfPhase, setDatesOfPhase] = useState("");
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showProgramInfo, setShowProgramInfo] = useState(false);
  const [program, setProgram] = useState<ProgramInput>({});
  const { threadUserContext } = useThreadContext();
  const student = threadUserContext.assignedStudents?.find(
    s => s.studentId === props.studentId
  )?.student as StudentFragment;
  const students = [student];
  const studentName = student?.firstName ?? "Student";
  const [currentTab, setCurrentTab] = useState(ProgramTabEnum.PHASE_DETAILS);
  const [valid, setValid] = useState({
    PHASE_DETAILS: true,
    HISTORICAL_TARGETS: true
  });

  const { data: sessionData } = useProgramSessionsQuery(props.studentId, {
    skip: !props.studentId
  });

  const { data: chartData } = useProgramChartQuery(
    props.studentId,
    props.programId
  );

  useEffect(() => {
    if (sessionData && chartData) {
      const session = sessionData.programSessions.find(
        s => s.program.id === props.programId
      );
      const program = session?.program;
      let dto = session?.currentPhase;
      if (program && dto) {
        if (props.phaseId && chartData) {
          const historical = chartData.programChart.phases.find(
            p => p.id === props.phaseId
          );
          if (historical) {
            dto = historical;
          }
          const points = chartData.programChart.dataPoints.filter(
            point =>
              point.phaseId === props.phaseId &&
              point.softDeleted === false &&
              point.state === DataPointStateEnum.COMPLETED
          );
          setPointsInPhase(points.length);
          const startDate = points[0].completedAt;
          const endDate = points[points.length - 1].completedAt;
          const dateRange = `${LabelHelper.dateLabel(
            moment(startDate),
            threadUserContext
          )} - ${LabelHelper.dateLabel(moment(endDate), threadUserContext)}`;
          setDatesOfPhase(dateRange);
        }
        const phase: ProgramInput = {
          drawer: program.drawer,
          phaseSummary: dto.phaseSummary,
          phaseNameOverride: dto.phaseNameOverride,
          baselineText: dto.baselineText,
          frequencyOfDataCollectionText: dto.frequencyOfDataCollectionText,
          criterionForMasteryText: dto.criterionForMasteryText,
          defaultTrialResult: dto.defaultTrialResult,
          definitionOfBehavior: dto.definitionOfBehavior,
          instructionalCue: dto.instructionalCue,
          errorless: dto.errorless,
          programGoal: program.programGoal,
          minimumRequiredTrials: dto.minimumRequiredTrials,
          procedureDetails: dto.procedureDetails,
          prompt: dto.prompt,
          reinforcementRatio: dto.reinforcementRatio,
          reinforcementSchedule: dto.reinforcementSchedule,
          typeOfReinforcement: dto.typeOfReinforcement,
          lengthOfEachInterval: dto.lengthOfEachInterval,
          unitGoal: dto.unitGoal,
          programName: program.name,
          programType: program.type,
          numberOfTrials: dto.numberOfTrials,
          unlimitedTrials:
            program.type !== ProgramTypeEnum.INTERVAL && dto.unlimitedTrials,
          phaseTargetIds: dto.targetIds
        };
        if (
          program.type === ProgramTypeEnum.INTERVAL &&
          phase.defaultTrialResult === TrialResultEnum.NONE
        ) {
          phase.defaultTrialResult = TrialResultEnum.NOT_APPLICABLE;
        }
        const stepList: StepInput[] = [];
        dto.steps.forEach(step => {
          const stepInput: StepInput = {
            id: step.id,
            state: step.state,
            description: step.description
          };
          stepList.push(stepInput);
        });
        let targetList: TargetInput[] = [];
        program.targets.forEach(target => {
          const targetInput: TargetInput = {
            id: target.id,
            targetDescription: target.targetDescription,
            mastered: target.mastered,
            state: target.state,
            completed: target.completed
          };
          if (
            !props.phaseId &&
            target.state === TargetStateEnum.CURRENT &&
            dto &&
            !dto.targetIds.find(id => id === target.id)
          ) {
            return;
          }
          targetList.push(targetInput);
        });
        if (props.phaseId) {
          dto.targetIds.forEach(id => {
            const target = targetList.find(t => t.id === id);
            if (target) {
              targetList = targetList.filter(t => t.id !== id);
              targetList.unshift(target);
            }
          });
        }
        const criterionList: CriterionInput[] = [];
        dto.criterionForMastery.forEach(criterion => {
          const criterionInput: CriterionInput = {
            id: criterion.id,
            minPercentage: criterion.minPercentage,
            pointsAnalyzed: criterion.pointsAnalyzed
          };
          criterionList.push(criterionInput);
        });
        phase.steps = stepList;
        phase.targets = targetList;
        phase.criterionForMastery = criterionList;
        setProgram(phase);
        setLoading(false);
      }
    }
  }, [
    props.programId,
    props.phaseId,
    props.studentId,
    studentName,
    sessionData,
    chartData,
    threadUserContext
  ]);

  const closeSaveAlert = () => {
    setSaveAlertOpen(false);
  };

  const handleCancel = () => {
    if (saveNeeded) {
      setCancelAlertOpen(true);
    } else {
      props.onClose();
    }
  };

  const closeCancelAlert = () => {
    setCancelAlertOpen(false);
  };

  const closeCancelAlertAndDialog = () => {
    props.onClose();
  };

  const handleProgramChange = (program: ProgramInput) => {
    setProgram(program);
    setSaveNeeded(true);
  };

  const handleStudentChange = (students: StudentFragment[]) => {};

  const handleValidation = (tab: ProgramTabEnum, value: boolean) => {
    const clone = _.cloneDeep(valid);
    if (clone[tab] !== value) {
      clone[tab] = value;
      setValid(clone);
    }
  };

  const handleSave = () => {
    for (const key in valid) {
      if (!valid[key]) {
        NotificationsHelper.BasicErrorNotification(
          "Missing Required Info",
          "Please review sections marked in red for incomplete information before submitting."
        );
        return;
      }
    }
    setSaveAlertOpen(true);
  };

  const save = () => {
    var input: ProgramInput = _.cloneDeep(program);
    if (input.targets) {
      input.targets.forEach(target => delete target["isNew"]);
    }
    setSaving(true);
    programUpdate(props.programId, props.phaseId, props.studentId, input)
      .then(() => {
        setSaveNeeded(false);
        setSaving(false);
        props.onClose();
      })
      .catch((error: ApolloError) => {
        setSaving(false);
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
  };

  if (loading || saving) {
    return (
      <LoadingScreen
        loading={true}
        tip={saving ? "Saving changes ..." : "Loading ..."}
      />
    );
  }

  const steps: IProgramFormStep[] = [
    { key: ProgramTabEnum.PHASE_DETAILS, component: PhaseDetails },
    { key: ProgramTabEnum.HISTORICAL_TARGETS, component: HistoricalTargets }
  ];

  const renderStep = (step: IProgramFormStep) => {
    if (!step?.component) {
      return (
        <Heading level={5} color="error">
          Step is not yet implemented.
        </Heading>
      );
    }
    var Component = step.component; // as any;
    return (
      <Component
        editing={true}
        program={program}
        students={students}
        showValidation={true}
        onProgramChange={handleProgramChange}
        onStudentChange={handleStudentChange}
        onValidation={handleValidation}
      />
    );
  };

  return (
    <Grid>
      <Row type="flex" direction="column" justify="space-between" height="100%">
        <Grid margin="16px 16px 8px 16px">
          <Row>
            <Heading weight="bold" level={4}>
              Edit historical phase details
            </Heading>
          </Row>
          <Row type="flex">
            <Col xs={12} md={6} margin="16px 0 0 0">
              <Row>
                <Heading level={6}>Program</Heading>
              </Row>
              <Row>
                <Heading level={5}>{program.programName}</Heading>
              </Row>
            </Col>
            <Col xs={12} md={6} margin="16px 0 0 0">
              <Row>
                <Heading level={6}>Dates of phase</Heading>
              </Row>
              <Row>
                <Heading level={5}>{datesOfPhase}</Heading>
              </Row>
            </Col>
            <Col xs={12} md={6} margin="16px 0 0 0">
              <Row>
                <Heading level={6}>Data points in phase</Heading>
              </Row>
              <Row>
                <Heading level={5}>{pointsInPhase}</Heading>
              </Row>
            </Col>
            <Col xs={12} md={6} margin="26px 0 0 0">
              <Row type="flex">
                <Link onClick={() => setShowProgramInfo(true)}>
                  Program Info
                </Link>
              </Row>
            </Col>
          </Row>
        </Grid>
        <Grid color="default">
          <Row height="12px"></Row>
        </Grid>
        <Grid margin="0 16px">
          <Row type="flex" margin="0 0 0 -16px">
            <Col xs={24}>
              <Tabs
                activeKey={currentTab}
                size="large"
                onChange={activeKey =>
                  setCurrentTab(activeKey as ProgramTabEnum)
                }
              >
                {steps.map(step => {
                  let tab = <>{LabelHelper.programTabLabel(step.key)}</>;
                  if (!valid[step.key]) {
                    tab = (
                      <>
                        <Icon color="error" type="fa-info-circle far" /> {tab}
                      </>
                    );
                  }
                  return (
                    <TabPane key={step.key} tab={tab}>
                      <Row margin="0 16px">{renderStep(step)}</Row>
                    </TabPane>
                  );
                })}
              </Tabs>
            </Col>
          </Row>
          <Card
            left="0"
            bottom="0"
            position="fixed"
            width="100%"
            margin="0"
            bordered={true}
            color="default"
            variation={4}
            padding="1px 0 0 0"
            zIndex={1}
          >
            <Row type="flex" margin="0 -5px 0 0">
              <Col
                color="navbar"
                margin="-16px 11px -12px -16px"
                grow={1}
                variation={9}
                xs={0}
                sm={0}
                md={0}
                lg={5}
                xl={4}
                xxl={4}
              />
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={19}
                xl={20}
                color="default"
                variation={1}
              >
                <Row type="flex" margin="16px">
                  <Col grow={1} />
                  <Col margin="0px 12px 0px 0px">
                    <Button size="default" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handleSave} size="default" type="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Grid>
        <Modal
          visible={saveAlertOpen}
          onCancel={closeSaveAlert}
          title="Are you sure you want to edit the historical record of this phase? This cannot be undone."
          footer={
            <>
              <Button type="primary" onClick={event => save()}>
                Yes
              </Button>
              <Button type="primary" onClick={event => closeSaveAlert()}>
                No
              </Button>
              {!props.phaseId && (
                <Button type="ghost" onClick={closeSaveAlert}>
                  Cancel
                </Button>
              )}
            </>
          }
        ></Modal>
        <DiscardChangesModal
          intercept={saveNeeded}
          visible={cancelAlertOpen}
          setVisible={setCancelAlertOpen}
          onCancel={closeCancelAlert}
          onOk={closeCancelAlertAndDialog}
        />
        {showProgramInfo && (
          <ProgramInfoModal
            studentId={props.studentId}
            programId={props.programId}
            onClose={() => setShowProgramInfo(false)}
          />
        )}
      </Row>
    </Grid>
  );
};
