import {
  Col,
  Divider,
  Form,
  FormItem,
  Grid,
  Heading,
  InputTextArea,
  Row
} from "@raudabaugh/thread-ui";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { ProgramTabEnum } from "./ProgramTabEnum";
import { IProgramComponentProps } from "./Types";

export interface IUnitDetailsProps extends IProgramComponentProps {}

export const UnitDetails = (props: IUnitDetailsProps) => {
  const inputRef = useRef<HTMLElement>();
  const { onValidation } = props;

  useEffect(() => {
    //No required fields, mark program as valid
    onValidation(ProgramTabEnum.UNIT_DETAILS, true);
  }, [onValidation, props.program]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus({
        cursor: "end"
      } as FocusOptions);
    }
  }, []);

  return (
    <>
      <Form>
        <Grid margin="0 16px">
          <FormItem>
            <Heading level={6} margin="4px 0 0 0">
              Unit goal / short term objective
            </Heading>
            <InputTextArea
              value={props.program.unitGoal!}
              innerRef={inputRef}
              onChange={event => {
                const program = _.cloneDeep(props.program);
                program.unitGoal = event.target.value;
                props.onProgramChange(program);
              }}
            />
          </FormItem>

          <Row type="flex" gutter={32}>
            <Col sm={12} xs={24}>
              <FormItem>
                <Heading level={6} margin="4px 0 0 0">
                  Definition of behavior
                </Heading>
                <InputTextArea
                  value={props.program.definitionOfBehavior!}
                  onChange={event => {
                    const program = _.cloneDeep(props.program);
                    program.definitionOfBehavior = event.target.value;
                    props.onProgramChange(program);
                  }}
                />
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem>
                <Heading level={6} margin="4px 0 0 0">
                  Frequency of data collection
                </Heading>
                <InputTextArea
                  value={props.program.frequencyOfDataCollectionText!}
                  onChange={event => {
                    const program = _.cloneDeep(props.program);
                    program.frequencyOfDataCollectionText = event.target.value;
                    props.onProgramChange(program);
                  }}
                />
              </FormItem>
            </Col>
          </Row>

          <Heading level={5} weight="bold" margin="4px 0 0 0">
            Procedure
          </Heading>

          <Divider
            color="#eee"
            type="horizontal"
            height="1px"
            margin="0px 0px 16px 0px"
          />

          <FormItem>
            <Heading level={6} margin="4px 0 0 0">
              Required materials
            </Heading>
            <InputTextArea
              value={props.program.materials!}
              onChange={event => {
                const program = _.cloneDeep(props.program);
                program.materials = event.target.value;
                props.onProgramChange(program);
              }}
            />
          </FormItem>

          <FormItem>
            <Heading level={6} margin="4px 0 0 0">
              Procedure details
            </Heading>
            <InputTextArea
              value={props.program.procedureDetails!}
              onChange={event => {
                const program = _.cloneDeep(props.program);
                program.procedureDetails = event.target.value;
                props.onProgramChange(program);
              }}
            />
          </FormItem>

          <Row type="flex" gutter={32} margin="0 0 64px 0">
            <Col sm={12} xs={24}>
              <FormItem>
                <Heading level={6} margin="4px 0 0 0">
                  Prompting procedure / Error correction
                </Heading>
                <InputTextArea
                  value={props.program.promptingProcedure!}
                  onChange={event => {
                    const program = _.cloneDeep(props.program);
                    program.promptingProcedure = event.target.value;
                    props.onProgramChange(program);
                  }}
                />
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem>
                <Heading level={6} margin="4px 0 0 0">
                  Tips
                </Heading>
                <InputTextArea
                  value={props.program.tips!}
                  onChange={event => {
                    const program = _.cloneDeep(props.program);
                    program.tips = event.target.value;
                    props.onProgramChange(program);
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Grid>
      </Form>
    </>
  );
};
