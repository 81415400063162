import { gql } from "@apollo/client";

export const FRAGMENT_GUARDIAN = gql`
  fragment GuardianFragment on GuardianType {
    id
    firstName
    lastName
    email
    phoneNumber
  }
`;

export const FRAGMENT_STUDENT = gql`
  fragment StudentFragment on StudentType {
    id
    firstName
    lastName
    fullName
    avatarUrl
    modifiedAt
  }
`;

export const FRAGMENT_STUDENT_PROFILE = gql`
  fragment StudentProfileFragment on StudentType {
    id
    avatarUrl
    firstName
    lastName
    fullName
    modifiedAt
    birthday
    eINumber
    status
    guardianOptOutReason
    primaryGuardian {
      ...GuardianFragment
    }
    secondaryGuardian {
      ...GuardianFragment
    }
    lastSeen
  }
  ${FRAGMENT_GUARDIAN}
`;

export const FRAGMENT_STAFF = gql`
  fragment StaffFragment on StaffType {
    id
    fullName
    status
    avatarUrl
    email
    firstName
    lastName
    phoneNumber
    role
    isSysOp
    lastActive
    assignedStudents {
      studentId
      availableOffline
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

export const FRAGMENT_SESSION_NOTE = gql`
  fragment SessionNoteFragment on SessionNoteType {
    id
    skillDetails
    behaviorDetails
    start
    end
    signature
    smartBehaviorDetails
    smartSkillDetails
    placeOfService
    serviceType
    status
    student {
      ...StudentFragment
    }
    modifiedAt
    createdById
    createdByName
  }
  ${FRAGMENT_STUDENT}
`;

export const FRAGMENT_PHASE = gql`
  fragment PhaseFragment on PhaseType {
    id
    prompt
    targetIds
    steps {
      id
      description
      state
    }
    criterionForMastery {
      id
      pointsAnalyzed
      minPercentage
    }
    criterionForMasteryText
    numberOfTrials
    typeOfReinforcement
    errorless
    unlimitedTrials
    defaultTrialResult
    procedureDetails
    materials
    promptingProcedure
    tips
    definitionOfBehavior
    unitGoal
    baselineText
    frequencyOfDataCollectionText
    phaseSummary
    phaseNameOverride
    reinforcementSchedule
    reinforcementRatio
    instructionalCue
    lengthOfEachInterval
    minimumRequiredTrials
  }
`;

export const FRAGMENT_PROGRAM = gql`
  fragment ProgramFragment on ProgramType {
    id
    drawer
    pinned
    type
    archived
    archiveType
    locked
    lockedType
    lastRun
    name
    programGoal
    prerequisiteSkillsNeeded
    intervalType
    templateId
    curriculumId
    targets {
      id
      targetDescription
      mastered
      completed
      state
      maintenanceMinusCount
      maintenancePlusCount
      lastMaintenanceDateTime
    }
  }
`;

export const FRAGMENT_TRIAL = gql`
  fragment TrialFragment on TrialType {
    id
    index
    result
    prompt
    duration
    occurredAt
    targetId
    stepId
    createdById
    createdByName
  }
`;

export const FRAGMENT_DATA_POINT = gql`
  fragment DataPointFragment on DataPointType {
    id
    active
    softDeleted
    state
    note
    createdAt
    modifiedAt
    startedAt
    completedAt
    attemptedOverride
    correctOverride
    durationOverride
    state
    method
    pointType
    phaseId
    programId
    studentId
    trials {
      ...TrialFragment
    }
    createdById
    createdByName
    modifiedById
    modifiedByName
  }
  ${FRAGMENT_TRIAL}
`;

export const FRAGMENT_PROGRAM_CHART = gql`
  fragment ProgramChartFragment on ProgramChartType {
    id
    mostRecentLimit
    program {
      ...ProgramFragment
    }
    dataPoints {
      ...DataPointFragment
    }
    phases {
      ...PhaseFragment
    }
  }
  ${FRAGMENT_PROGRAM}
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_PHASE}
`;

export const FRAGMENT_SESSION = gql`
  fragment SessionFragment on SessionType {
    id
    drawerPosition
    dataPoint {
      ...DataPointFragment
    }
    phase {
      ...PhaseFragment
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_PHASE}
`;

export const FRAGMENT_PROGRAM_SESSION = gql`
  fragment ProgramSessionFragment on ProgramSessionType {
    id
    program {
      ...ProgramFragment
    }
    session {
      ...SessionFragment
    }
    currentPhase {
      ...PhaseFragment
    }
  }
  ${FRAGMENT_PROGRAM}
  ${FRAGMENT_SESSION}
  ${FRAGMENT_PHASE}
`;

export const FRAGMENT_MESSAGE = gql`
  fragment MessageFragment on MessageType {
    id
    createdAt
    body
    active
    programId
    student {
      id
      fullName
      firstName
      lastName
      avatarUrl
    }
    program {
      locked
    }
  }
`;

export const FRAGMENT_CURRICULUM = gql`
  fragment CurriculumFragment on CurriculumType {
    id
    name
    rootFolderId
    description
    templateCount
    type
  }
`;

export const FRAGMENT_PROGRAM_FOLDER = gql`
  fragment ProgramFolderFragment on ProgramFolderType {
    id
    level
    name
    parentId
    parentName
    curriculum {
      id
      name
      type
    }
  }
`;

export const FRAGMENT_PROGRAM_TEMPLATE = gql`
  fragment ProgramTemplateFragment on ProgramTemplateType {
    id
    parentId
    parentName
    drawer
    pinned
    programType
    programName
    programGoal
    prerequisiteSkillsNeeded
    intervalType
    prompt
    criterionForMasteryText
    numberOfTrials
    typeOfReinforcement
    errorless
    unlimitedTrials
    defaultTrialResult
    procedureDetails
    materials
    promptingProcedure
    tips
    definitionOfBehavior
    unitGoal
    baselineText
    frequencyOfDataCollectionText
    phaseSummary
    phaseNameOverride
    reinforcementSchedule
    reinforcementRatio
    instructionalCue
    lengthOfEachInterval
    minimumRequiredTrials
    criterionForMastery {
      id
      pointsAnalyzed
      minPercentage
    }
    futureTargets {
      id
      targetDescription
    }
    steps {
      id
      description
    }
  }
`;

export const FRAGMENT_CLINICAL_FILE = gql`
  fragment ClinicalFileFragment on ClinicalFileType {
    id
    name
    fileType
    fileTypeOther
    contentType
    fileSize
    createdById
    createdByName
    createdAt
  }
`;

export const FRAGMENT_ABC_DATA = gql`
  fragment AbcDataFragment on ABCDataType {
    id
    antecedent
    behavior
    consequence
    date
    note
    setting
    programId
    createdById
    createdByName
  }
`;

export const FRAGMENT_ORGANIZATION = gql`
  fragment OrganizationFragment on OrganizationType {
    id
    name
    type
    alias
    address1
    address2
    city
    state
    postalZip
    websiteUrl
    totalStudents
    totalLocations
    settings {
      sequence
      value
      setting {
        id
        title
      }
    }
    managementApp
  }
`;

export const FRAGMENT_CUSTOM_SETTING = gql`
  fragment CustomSettingFragment on CustomSettingType {
    id
    title
    tooltip
    values
    labels
    dataSource
    control
    controlLabel
    defaultValue
    defaultValueWhenActivated
    status
    readOnly
    minValue
    maxValue
    editPermission
    parentSettingId
    childSettingIds
    parentSettingValues
    autoActivateChildSettings
    negateSettingIds
  }
`;

export const FRAGMENT_CUSTOM_SETTING_CATEGORY = gql`
  fragment CustomSettingCategoryFragment on CustomSettingCategoryType {
    title
    tooltip
    settings {
      ...CustomSettingFragment
    }
  }
  ${FRAGMENT_CUSTOM_SETTING}
`;

export const FRAGMENT_ROLE = gql`
  fragment RoleFragment on RoleType {
    id
    title
    description
  }
`;

export const FRAGMENT_USER = gql`
  fragment UserFragment on UserType {
    id
    fullName
    phoneNumber
    email
    firstName
    lastName
    avatarUrl
    currentRole
    currentOrganization {
      ...OrganizationFragment
    }
    isSysOp
    assignedStudents {
      studentId
      availableOffline
      hasActivePrograms
      student {
        ...StudentProfileFragment
      }
    }
  }
  ${FRAGMENT_ORGANIZATION}
  ${FRAGMENT_STUDENT_PROFILE}
`;
