import { LoadingScreen } from "../../Shared/LoadingScreen";
import {
  Grid,
  Row,
  Heading,
  Image,
  Col,
  FormItem,
  Button,
  Link
} from "@raudabaugh/thread-ui";
import React, { useEffect } from "react";
import { LabelHelper } from "../../Shared/LabelHelper";
import { findPermission } from "../../Shared/RolesMap";
import { Permissions } from "../../Shared/Api/globalTypes";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import { useOrganizationQuery } from "DataAccess/OrganizationData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import teamImage from "./undraw-team-spirit-hrr-4.svg";

export interface IOrgProfileBaseProps {
  onEdit: () => void;
}

export const OrgProfile = (props: IOrgProfileBaseProps) => {
  const { threadUserContext } = useThreadContext();
  const formItemLayout = {
    labelCol: {
      md: { span: 7 },
      sm: { span: 24 },
      xs: { span: 24 }
    },
    wrapperCol: {
      md: { span: 17 },
      sm: { span: 24 },
      xs: { span: 24 }
    }
  };

  const canEditProfile =
    threadUserContext!.isSysop ||
    findPermission(threadUserContext!.role, Permissions.EDIT_ORG_PROFILE);

  const {
    loading: orgLoading,
    error: orgError,
    data: orgData
  } = useOrganizationQuery(threadUserContext?.orgId);

  useEffect(() => {
    if (orgError) {
      NotificationsHelper.ErrorNotification({
        error: orgError,
        title: "Failed to Load Organization Profile",
        refreshPageOnClose: true
      });
    }
  }, [orgError]);

  return (
    <Grid>
      {orgLoading && <LoadingScreen loading={true} tip={"Loading..."} />}
      {orgData && orgData.organization && (
        <>
          <Row padding="24px 0" type="flex" justify="center">
            <Image src={teamImage} />
          </Row>
          <Row padding="0 0 8px 0" type="flex" justify="center">
            <Heading level={3}>Your Org Profile</Heading>
          </Row>
          <Row padding="16px" type="flex" justify="center">
            <Col span={16}>
              <FormItem {...formItemLayout} label="Organization Name">
                {orgData.organization.name}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Primary Address"
                lineHeight="20px"
              >
                <Row margin="10px 0 0 0">
                  {orgData.organization.address1}
                  {orgData.organization.address2 ? ", " : ""}
                  {orgData.organization.address2}
                </Row>
                <Row>
                  {orgData.organization.city},{" "}
                  {LabelHelper.stateLabel(orgData.organization.state)}{" "}
                  {orgData.organization.postalZip}
                </Row>
                <Row>
                  {LabelHelper.countryLabel(orgData.organization.state)}
                </Row>
              </FormItem>
              {orgData.organization.websiteUrl && (
                <FormItem {...formItemLayout} label="Website Address">
                  <Link href={orgData.organization.websiteUrl}>
                    {orgData.organization.websiteUrl}
                  </Link>
                </FormItem>
              )}
              <FormItem {...formItemLayout} label="Organization Type">
                {LabelHelper.organizationTypeLabel(orgData.organization.type)}
              </FormItem>
              <FormItem {...formItemLayout} label="Total Students">
                {LabelHelper.totalStudentsLabel(
                  orgData.organization.totalStudents
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Total Locations">
                {LabelHelper.totalLocationsLabel(
                  orgData.organization.totalLocations
                )}
              </FormItem>
              {canEditProfile && (
                <Row>
                  <Col xs={24} md={7} />
                  <Col xs={24} md={17}>
                    <Row type="flex">
                      <Col>
                        <Button type="primary" onClick={() => props.onEdit()}>
                          Edit Profile
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
