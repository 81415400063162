import React from "react";
import { Grid, Row, Illustration, Heading, Col } from "@raudabaugh/thread-ui";
import connectingImage from "Shared/Images/undraw-connecting-teams-3-1-pgn.svg";

export const StaffInvitationDeclined = () => {
  return (
    <Grid type="flex" height="100vh" width="100%" color="default" variation={1}>
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} />
      </Row>
      <Row type="flex">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={24} md={20} lg={16} xl={12}>
          <Row padding="0 16px">
            <Heading level={3}>
              Your request to declined the invitation has been processed.
            </Heading>
          </Row>
          <Row padding="8px 16px">
            <Heading level={5}>
              If the request to decline was in error, please ask your supervisor
              or internal administrator to resend your invitation.
            </Heading>
          </Row>
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
      </Row>
    </Grid>
  );
};
