import { StaffRoleEnum } from "Shared/Api/globalTypes";
import { StaffOnboardingStatusEnum } from "Shared/OnboardingApi/globalTypes";

export enum StaffOnboardingStepEnum {
  INVITATION = "INVITATION",
  PROFILE = "PROFILE",
  PASSWORD = "PASSWORD",
  WELCOME = "WELCOME"
}

export interface IStaffOnboardingModel {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  password?: string;
  role?: StaffRoleEnum;
}

export interface IStaffOnboardingComponent {
  staffId: string;
  orgId: string;
  code: string;
  model: IStaffOnboardingModel;
  status: StaffOnboardingStatusEnum;
  showValidation: boolean;
  onValidation: (step: StaffOnboardingStepEnum, valid: boolean) => void;
  onChange: (model: IStaffOnboardingModel) => void;
}

export interface IStaffOnboardingStep {
  key: StaffOnboardingStepEnum;
  component?: React.FC<IStaffOnboardingComponent>;
  label: string;
}
