import {
  Col,
  Grid,
  Heading,
  Input,
  Row,
  InputPhoneNumber,
  InputEmail
} from "@raudabaugh/thread-ui";
import React, { useEffect } from "react";
import { validateEmail } from "../../Shared/ValidateEmail";
import { validatePhoneNumber } from "../../Shared/ValidatePhoneNumber";

interface IEditGuardianProps {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  onChangeText: (
    field: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => void;
  secondary?: boolean;
  optOut?: boolean;
  onValidateCheck?: (isValid: boolean) => void;
  emailConflict?: boolean;
}

export const EditGuardian = (props: IEditGuardianProps) => {
  useEffect(() => {
    if (!props.secondary) {
      if (
        !validateEmail(props.email!) &&
        props.firstName !== "" &&
        props.lastName !== "" &&
        props.onValidateCheck
      ) {
        props.onValidateCheck(true);
      }
    }
  }, [props, props.firstName, props.lastName, props.email, props.secondary]);

  return (
    <Grid>
      <Row margin="0px 0px 32px 0px">
        <Col>
          <Row type="flex" margin="0">
            {!props.secondary && !props.optOut && (
              <Heading level={5} margin="0px" color="error">
                * &nbsp;
              </Heading>
            )}
            <Heading level={5} margin="0px" color="default" variation={7}>
              First Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id={
              !props.secondary
                ? "primaryGuardianFirstNameInput"
                : "secondaryGuardianFirstNameInput"
            }
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("firstName", value)
            }
            placeholder=""
            value={props.firstName ? props.firstName : ""}
          />
        </Col>
      </Row>
      <Row margin="32px 0px">
        <Col>
          <Row type="flex" margin="0">
            {!props.secondary && !props.optOut && (
              <Heading level={5} margin="0px" color="error">
                * &nbsp;
              </Heading>
            )}
            <Heading level={5} margin="0px" color="default" variation={7}>
              Last Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id={
              !props.secondary
                ? "primaryGuardianLastNameInput"
                : "secondaryGuardianLastNameInput"
            }
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("lastName", value)
            }
            placeholder=""
            value={props.lastName ? props.lastName : ""}
          />
        </Col>
      </Row>
      <Row margin="32px 0px">
        <Col>
          <Row type="flex" margin="0">
            {!props.secondary && !props.optOut && (
              <Heading level={5} margin="0px" color="error">
                * &nbsp;
              </Heading>
            )}
            <Heading level={5} margin="0px" color="default" variation={7}>
              Email
            </Heading>
          </Row>
        </Col>
        <Col>
          <InputEmail
            id={
              !props.secondary
                ? "primaryGuardianEmailInput"
                : "secondaryGuardianEmailInput"
            }
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("email", value)
            }
            placeholder=""
            value={props.email ? props.email : ""}
          />
          {validateEmail(props.email!) && (
            <Heading color="error" level={6}>
              Please Enter Valid Email
            </Heading>
          )}
          {props.emailConflict === true && (
            <Heading level={5} color="error">
              The same email cannot be used for primary and secondary guardians.
            </Heading>
          )}
        </Col>
      </Row>
      <Row margin="28px 0px">
        <Col>
          <Heading level={5} margin="0px" color="default" variation={7}>
            Mobile Number
          </Heading>
          <InputPhoneNumber
            id={
              !props.secondary
                ? "primaryGuardianPhoneNumberInput"
                : "secondaryGuardianPhoneNumberInput"
            }
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("phoneNumber", value)
            }
            placeholder=""
            value={props.phoneNumber ? props.phoneNumber : ""}
          />
          {validatePhoneNumber(props.phoneNumber!) &&
            props.phoneNumber !== "" &&
            props.phoneNumber !== null && (
              <Heading color="error" level={6}>
                Please Enter Valid Phone Number
              </Heading>
            )}
        </Col>
      </Row>
    </Grid>
  );
};
