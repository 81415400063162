import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import { memo, useCallback, useState } from "react";
import {
  useDataPointMarkFrequencyMutation,
  useDataPointScoreFrequencyMutation,
  useDataPointUndoFrequencyMutation
} from "../DataAccess/FrequencyData";

import { SimpleTrialCard } from "./SimpleTrialCard";
import {} from "../DataAccess/FrequencyData";
import { DrawerEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import {
  useProgramDeactivateMutation,
  useProgramMoveMutation
} from "DataAccess/ProgramData";
import { TrialFragment } from "Shared/Api/TrialFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { Row, Heading, Modal } from "@raudabaugh/thread-ui";
import { IProgramItem } from "StudentPrograms/Types";

interface IFrequencyCardProps {
  index: number;
  currentStudentId: string;
  title: string;
  size: "sm" | "lg";
  pointId?: string;
  phaseId: string;
  programId: string;
  trials: TrialFragment[];
  correctOverride: number | null;
  item: IProgramItem;
  setLoading: (loading: boolean) => void;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onPopup: (programId: string) => void;
  onShowFocusDrawer?: () => void;
}

const FrequencyCard = (props: IFrequencyCardProps) => {
  const { programDeactivate } = useProgramDeactivateMutation();
  const { dataPointMarkFrequency } = useDataPointMarkFrequencyMutation();
  const { programMove } = useProgramMoveMutation();
  const { dataPointScoreFrequency } = useDataPointScoreFrequencyMutation();
  const { dataPointUndoFrequency } = useDataPointUndoFrequencyMutation();
  const { threadUserContext } = useThreadContext();
  const [enterScoreOpen, setEnterScoreOpen] = useState(false);
  const { setLoading } = props;
  const [closeWarningOpen, setCloseWarningOpen] = useState(false);

  const onClose = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleMoveToTopClick = () => {
    programMove(props.programId, props.currentStudentId, 0).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };

  const closeProgram = useCallback(async () => {
    try {
      await programDeactivate(props.programId, props.currentStudentId, false);
    } catch (error) {
      NotificationsHelper.ErrorNotification({
        error: error as Error,
        title: "Action Failed",
        onClose
      });
    }
  }, [programDeactivate, onClose, props.programId, props.currentStudentId]);

  const handleCloseClick = useCallback(() => {
    if(props.item.drawer === DrawerEnum.PRIVATE_DRAWER) {
      const hasNoTrials = props.trials.length === 0;
        
      if(hasNoTrials) {
        closeProgram();
      } else {
        setCloseWarningOpen(true);
      }
    } else {
      closeProgram();
    }
  }, [closeProgram, props.item.drawer, props.trials]);

  const handleCloseWarningContinue = () => {
    setCloseWarningOpen(false);
    closeProgram();
  };

  const handleCloseWarningCancel = () => {
    setCloseWarningOpen(false);
  };

  const handleEnterScoreClick = useCallback(() => {
    setEnterScoreOpen(true);
  }, []);

  const handleSaveScore = (
    score: number,
    date: moment.Moment,
    attempts?: number,
    phaseId?: string
  ) => {
    dataPointScoreFrequency(
      props.programId,
      phaseId ?? props.phaseId,
      props.currentStudentId,
      score,
      date.toISOString()
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed",
        onClose
      });
    });
  };
  const handleButtonClick = async () => {
    try {
      await dataPointMarkFrequency(
        props.programId,
        props.phaseId,
        props.currentStudentId,
        threadUserContext.userId,
        threadUserContext.userName
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed",
        onClose
      });
    }
  };
  const handleAbcClick = () => {
    props.onAbc(props.programId);
  };
  const handleChartClick = () => {
    props.onChart(props.programId, ProgramTypeEnum.FREQUENCY);
  };
  const handleUndoClick = () => {
    dataPointUndoFrequency(props.pointId!, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };

  const handleEnterScoreClose = () => {
    setEnterScoreOpen(false);
  };

  const count =
    (props.correctOverride ?? 0) + (props.trials ? props.trials.length : 0);
  let caption: string | undefined = count + " total";
  const score: React.ReactNode = (
    <Row alignItems="center" direction="column" type="flex">
      <Heading color="default" weight="medium" level={5}>
        {caption}
      </Heading>
    </Row>
  );
  if (props.size === "lg") {
    caption = undefined;
  }
  return (
    <>
      <SimpleTrialCard
        icon="fa-plus"
        title={props.title}
        caption={caption}
        score={score}
        index={props.index}
        size={props.size}
        studentId={props.currentStudentId}
        onAbcClick={handleAbcClick}
        onChartClick={handleChartClick}
        onUndoClick={count !== 0 ? handleUndoClick : undefined}
        onButtonClick={handleButtonClick}
        onCloseClick={handleCloseClick}
        onMoveToTopClick={handleMoveToTopClick}
        onEnterScoreClick={handleEnterScoreClick}
        onPopupClick={() => props.onPopup(props.programId)}
      />
      <EnterScoreDialog
        scoreLabel="Number of Occurrences"
        dateLabel="Date"
        onClose={handleEnterScoreClose}
        open={enterScoreOpen}
        onSaveScore={handleSaveScore}
        studentId={props.currentStudentId}
        programId={props.programId}
      />
      <Modal
        title="Are you sure you want to close this program?"
        afterClose={handleCloseWarningCancel}
        visible={closeWarningOpen}
        onOk={handleCloseWarningContinue}
        onCancel={handleCloseWarningCancel}
        okText="Close Program"
      >
        Your data will be deleted immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export default memo(FrequencyCard);
