/// <reference types="@welldone-software/why-did-you-render" />
// import "./wdyr";
import { Row } from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IThreadContext } from "./Shared/IThreadContext";
import ReactDOM from "react-dom";
import moment from "moment";
import { client, useOnlineStatus } from "./Shared/ApolloHelper";
import { LoadingScreen } from "./Shared/LoadingScreen";
import { SettingsHelper } from "./Shared/SettingsHelper";
import { LicenseExpiredModal } from "./Security/LicenseExpiredModal";
import { XamarinHelper } from "./Shared/XamarinHelper";
import {
  PullToRefresh,
  ReleaseContent,
  RefreshContent,
  PullDownContent
} from "react-js-pull-to-refresh";
import { PrintRouter } from "Routes/PrintRouter";
import {
  addToActiveMessagesQuery,
  removeFromActiveMessagesQuery,
  useActiveMessagesQuery,
  useMessageOnUpdateSubscription
} from "./DataAccess/MessageData";
import { ThreadContext } from "./ContextHooks/ThreadContextHook";
import { ManagementAppEnum, StaffRoleEnum } from "Shared/Api/globalTypes";
import { useUserQuery } from "DataAccess/UserData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import AppLayout from "Shared/AppLayout";
import { RouterHelper } from "Routes/RouterHelper";
// re-export client - though really, most / all imports of this should use useApolloClient hook instead
export { client };

export enum SessionVariableEnum {
  TOKEN = "token",
  ORGANIZATION = "organization",
  UPDATE = "update"
}

export enum OrganizationSettingsEnum {
  MOBILE_DATA_COLLECTION = "mobile_data_collection",
  MOBILE_SESSION_NOTES = "mobile_session_notes",
  MOBILE_ALERTS = "mobile_alerts",
  MAX_ADMINS = "max_admins",
  MAX_SUPERVISORS = "max_supervisors",
  MAX_INSTRUCTORS = "max_instructors",
  MAX_STUDENTS = "max_students",
  MAX_STAFF = "max_staff",
  MAX_OFFLINE_STUDENTS_PER_USER = "max_offline_students_per_user",
  MAX_OFFLINE_POINTS_PER_CHART = "max_offline_points_per_chart",
  ADD_SECTION = "add_section",
  HIDE_SECTION = "hide_section",
  ADD_PROMPT = "add_prompt",
  HIDE_PROMPT = "hide_prompt",
  DEFAULT_ERRORLESS = "default_errorless",
  DEFAULT_MAX_TRIALS = "default_max_trials",
  DEFAULT_MIN_TRIALS = "default_min_trials",
  CRITERION_DEFAULTS = "criterion_defaults",
  DEFAULT_REINFORCEMENT_STRATEGY = "default_reinforcement_strategy",
  DEFAULT_REINFORCEMENT_RATIO = "default_reinforcement_ratio",
  HIH_HIERARCHY = "hih_hierarchy",
  LICENSE_EXPIRES = "license_expires",
  BEHAVIOR_AUTORECORD_ZEROS = "behavior_autorecord_zeros",
  BILLED_BY = "billed_by",
  BILLING_PRICE = "billing_price",
  NONSTANDARD_PRICING = "nonstandard_pricing"
}

export enum ProgramFieldEnum {
  MIN_ATTEMPTED = "minAttempted",
  SETTING = "setting",
  OBJECTIVES = "objectives",
  REINFORCEMENT_TYPE = "reinforcement_type",
  PROMPT = "prompt",
  REINFORCEMENT_SCHEDULE = "reinforcement_schedule"
}

export const App = () => {
  const containerRef = useRef<Row>(null);
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [context, setContext] = useState<IThreadContext>();
  const [error, setError] = useState<ApolloError>();
  const history = useHistory();
  const location = useLocation();
  const online = useOnlineStatus();

  const threadContextValue = useMemo(
    () => ({
      state: context
    }),
    [context]
  );

  const { data: activeMessageData, error: activeMessageError } =
    useActiveMessagesQuery({
      skip: !online
    });
  const { data: messageOnUpdateData } = useMessageOnUpdateSubscription({
    skip: !online
  });

  const { error: userError, data: userData } = useUserQuery(null, {
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (messageOnUpdateData) {
      const message = messageOnUpdateData?.messageOnUpdate?.message;
      if (message) {
        if (message.active) {
          addToActiveMessagesQuery(message, client);
        } else {
          removeFromActiveMessagesQuery(message, client);
        }
      }
    }
  }, [messageOnUpdateData]);
  useEffect(() => {
    if (activeMessageData) {
      const messageLength = activeMessageData.messagesActive.length;
      if (messageCount !== messageLength) {
        setMessageCount(messageLength);
      }
    }
    activeMessageError && setMessageCount(0);
  }, [activeMessageData, activeMessageError, messageCount]);

  const scrollIntoView = useCallback(() => {
    const tmp = ReactDOM.findDOMNode(containerRef.current) as HTMLElement;
    tmp.scrollIntoView({ behavior: "smooth" });
  }, []);

  const getAlertTarget = useCallback(() => {
    const tmp = ReactDOM.findDOMNode(containerRef.current) as HTMLElement;
    return tmp;
  }, []);

  useEffect(() => {
    if (userData && userData.user) {
      const appUser = userData.user;

      if (appUser.currentRole === StaffRoleEnum.NONE) {
        RouterHelper.redirectToNoAccess(history);
      }

      const currentUser: IThreadContext = {
        avatar: appUser.avatarUrl ?? "",
        userName: appUser.firstName + " " + appUser.lastName,
        orgId: appUser.currentOrganization?.id ?? "",
        orgName: appUser.currentOrganization?.name ?? "",
        userId: appUser.id ?? "",
        userEmail: appUser.email ?? "",
        managementApp: ManagementAppEnum.NONE,
        role: appUser.currentRole,
        assignedStudents: [...appUser.assignedStudents].sort((a, b) =>
          (a.student.fullName ?? "").localeCompare(b.student.fullName ?? "")
        ),
        isSysop: appUser.isSysOp,
        settings: {},
        scrollIntoView,
        getAlertTarget
      };

      if (appUser.currentOrganization) {
        const org = appUser.currentOrganization;
        currentUser.managementApp =
          org.managementApp ?? ManagementAppEnum.PEOPLE_MANAGEMENT;
        currentUser.orgType = org.type;
        currentUser.settings = SettingsHelper.buildSettingsMap(org.settings);
      }

      const now = moment();
      setContext(currentUser);

      const licenseExpires =
        SettingsHelper.date(
          currentUser,
          OrganizationSettingsEnum.LICENSE_EXPIRES
        ) ?? now;

      if (!currentUser.isSysop && licenseExpires.isBefore(now)) {
        setLicenseExpired(true);
      }

      XamarinHelper.sendPwaInfo();

      XamarinHelper.invokeAction("user-info", {
        userName: currentUser.userName,
        userId: currentUser.userId,
        email: currentUser.userEmail,
        orgId: currentUser.orgId,
        orgName: currentUser.orgName,
        orgType: currentUser.orgType,
        role: currentUser.role
      });
    }
    userError && setError(userError);
  }, [getAlertTarget, history, userData, userError, scrollIntoView]);

  useEffect(() => {
    const unregister = history.listen(location => {
      XamarinHelper.invokeAction("navigate", { url: location.pathname });
    });

    return () => {
      unregister();
    };
  }, [history]);

  const handlePullRefresh = useCallback(() => {
    return new Promise(resolve => {
      window.location.reload();
    });
  }, []);

  if (licenseExpired) {
    return <LicenseExpiredModal context={context} />;
  }

  if (context === undefined) {
    if (error) {
      NotificationsHelper.ErrorNotification({
        error: error,
        title: "Failed to Access Thread!",
        refreshPageOnClose: true
      });
      return <></>;
    } else {
      return <LoadingScreen loading={true} />;
    }
  }

const allowRefresh = (false || XamarinHelper.insideXamarin()) && online;

  return (
    <ThreadContext.Provider value={threadContextValue}>
      {location.pathname.startsWith("/print/") ? (
        <PrintRouter />
      ) : (
        <PullToRefresh
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={handlePullRefresh}
          triggerHeight={allowRefresh ? 100 : 0}
          backgroundColor="white"
        >
          <AppLayout
            containerRef={containerRef}
            messageCount={messageCount}
            online={online}
          />
        </PullToRefresh>
      )}
    </ThreadContext.Provider>
  );
};
