/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum StaffOnboardingStatusEnum {
  CONFIRMED = "CONFIRMED",
  INVITED = "INVITED",
  NONE = "NONE",
  SETUP = "SETUP",
}

export enum StaffRoleEnum {
  ADMIN = "ADMIN",
  INSTRUCTOR = "INSTRUCTOR",
  NONE = "NONE",
  SUPERVISOR = "SUPERVISOR",
}

/**
 * An input payload for confirming a new staff
 */
export interface OnboardingStaffConfirmInput {
  accept: boolean;
  code: string;
  orgId: GUID;
  staffId: GUID;
}

/**
 * An input payload for confirming a new staff
 */
export interface OnboardingStaffProfileInput {
  code: string;
  firstName: string;
  lastName: string;
  orgId: GUID;
  phoneNumber: string;
  staffId: GUID;
}

/**
 * An input payload for confirming a new staff
 */
export interface OnboardingStaffSetupInput {
  code: string;
  orgId: GUID;
  password: string;
  staffId: GUID;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
