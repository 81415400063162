import {
  Card,
  Col,
  Drawer,
  Grid,
  Heading,
  Notification,
  Row,
  TabPane,
  Tabs
} from "@raudabaugh/thread-ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DrawerBar from "../ProgramDrawer/DrawerBar";
import ProgramDrawer from "../ProgramDrawer/ProgramDrawer";
import { LoadingScreen } from "../Shared/LoadingScreen";
import StudentBar from "../StudentPrograms/StudentBar";
import { IProgramItem, IDiscreteTarget } from "./Types";
import StudentPrograms from "./StudentPrograms";
import moment from "moment";
import { DataPointHelper } from "Shared/DataPointHelper";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  DataPointStateEnum,
  DrawerEnum,
  ProgramTypeEnum,
  StepStateEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { useUserQuery } from "DataAccess/UserData";
import {
  useProgramSessionEndAllMutation,
  useIncompleteProgramSessionsQuery,
  useProgramDeactivateAllMutation,
  useProgramPhaseOnUpdateSubscription,
  useProgramSessionOnUpdateSubscription
} from "DataAccess/ProgramData";
import { ProtectedAvatar } from "Shared/ProtectedAvatar";
import { getInitials } from "Shared/Initials";
import { UserFragment_assignedStudents } from "Shared/Api/UserFragment";
import { ApolloError } from "@apollo/client";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import {
  IProgramSessions,
  IProgramSessions_programSessions
} from "Shared/Api/IProgramSessions";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useDataPointOnUpdateSubscription } from "DataAccess/DataPointData";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { IThreadContext } from "Shared/IThreadContext";
import { CurriculumHelper } from "Shared/CurriculumHelper";

interface IDataCollectionProps {
  studentId: string;
  inSession: boolean;
  onStudentChange: (studentId: string, inSession: boolean) => void;
  onProgramEdit: (programId: string, type: ProgramTypeEnum) => void;
  onProgramMenuItemClick?: (type: ProgramTypeEnum, drawer: DrawerEnum) => void;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onCurriculumAdd: (programId: string) => void;
  onOpenSession: () => void;
  onCloseSession: () => void;
}

interface ICountMap {
  applicableTrialCount: number;
  correctTrialCount: number;
}

export const DataCollection = (props: IDataCollectionProps) => {
  const {
    onStudentChange,
    onOpenSession: handleOpenSession,
    onCloseSession: handleCloseSession
  } = props;
  const memoCache = useRef<IMemoCache>(new Map());
  const { threadUserContext } = useThreadContext();
  const [flashBar, setFlashBar] = useState(false);
  const [clearing, setClearing] = useState(false);
  const online = useOnlineStatus();
  const { data: userData } = useUserQuery(null);
  const [lastStudentIdCached, setLastStudentIdCached] = useState<string>();
  const availableOffline =
    props.studentId === lastStudentIdCached ||
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.studentId && s.availableOffline
    ).length > 0;
  const {
    loading: activeLoading,
    error: activeError,
    data: activeData,
    refetch: activeRefetch
  } = useProgramSessionsQuery(props.studentId, {
    skip: !online && !availableOffline
  });
  const { programDeactivateAll } = useProgramDeactivateAllMutation();
  useProgramPhaseOnUpdateSubscription([props.studentId], { skip: !online });
  useDataPointOnUpdateSubscription([props.studentId], { skip: !online });
  useProgramSessionOnUpdateSubscription([props.studentId], { skip: !online });
  const { getIncompleteProgramSessions } = useIncompleteProgramSessionsQuery(
    props.studentId
  );

  const { programSessionEndAll } = useProgramSessionEndAllMutation(
    props.studentId
  );

  useEffect(() => {
    if (online) {
      activeRefetch();
    }
  }, [activeRefetch, online]);

  useEffect(() => {
    online &&
      activeError &&
      NotificationsHelper.ErrorNotification({
        error: activeError,
        title: "Connection Error"
      });
  }, [activeError, online]);

  useEffect(() => {
    if (online && activeData) {
      setLastStudentIdCached(props.studentId);
    }
  }, [online, activeData, props.studentId]);

  const handleFlashTimeout = useCallback(() => {
    setFlashBar(false);
  }, [setFlashBar]);

  const handleProgramToggle = useCallback(() => {
    setFlashBar(true);
    window.setTimeout(handleFlashTimeout, 200);
  }, [handleFlashTimeout, setFlashBar]);

  const handleClearDrawer = useCallback(() => {
    setClearing(true);
    const incomplete = getIncompleteProgramSessions();
    programDeactivateAll(props.studentId)
      .then(() => {
        if (incomplete && incomplete.length > 0) {
          Notification.warning({
            duration: 0,
            message: "Unable to clear all programs",
            description:
              "Active programs that are in progress were not cleared, select End Session to clear them."
          });
        } else {
          Notification.success({
            duration: 4,
            message: "All programs cleared",
            description:
              "All active programs have been cleared from the session."
          });
        }
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to clear active programs"
        });
      })
      .finally(() => setClearing(false));
  }, [getIncompleteProgramSessions, programDeactivateAll, props.studentId]);

  const handleSessionEnd = useCallback(() => {
    programSessionEndAll(props.studentId).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  }, [programSessionEndAll, props.studentId]);

  const handleSelectStudent = useCallback(
    studentId => {
      onStudentChange(studentId, false);
    },
    [onStudentChange]
  );

  const handleSelectStudentInTab = useCallback(
    studentId => {
      onStudentChange(studentId, true);
    },
    [onStudentChange]
  );

  // abuse useMemo to reset memoCache for student specific cache object, useEffect callback is run too late
  useMemo(() => {
    if (process.env.NODE_ENV === "development") {
      console.log(
        "DataCollection: reseting memo cache for programs of student %s",
        props.studentId
      );
    }
    memoCache.current = new Map();
  }, [props.studentId]);

  const { allPrograms, pinnedDrawer, sessionDrawer } = useMemo(() => {
    const allPrograms: IProgramItem[] = prepareTable(
      activeData,
      memoCache.current,
      threadUserContext
    );
    const pinnedDrawer: IProgramItem[] = prepareDrawer(
      activeData,
      true,
      memoCache.current,
      threadUserContext
    );
    const sessionDrawer: IProgramItem[] = prepareDrawer(
      activeData,
      false,
      memoCache.current,
      threadUserContext
    );

    return {
      allPrograms,
      pinnedDrawer,
      sessionDrawer
    };
  }, [activeData, threadUserContext]);

  if (activeLoading)
    return <LoadingScreen loading={true} tip="Loading student..." />;

  if (clearing)
    return <LoadingScreen loading={true} tip="Clearing session..." />;

  if (!online && (activeError || !availableOffline)) {
    return (
      <>
        <Grid>
          <Row>
            <Col padding="16px">
              <Heading level={3}>Plan a Session</Heading>
            </Col>
          </Row>
          <Row>
            <StudentBar
              currentStudentId={props.studentId}
              onSelectStudent={handleSelectStudent}
            />
          </Row>
          <Row type="flex" justify="center">
            <Heading level={5}>{`Student data unavailable offline`}</Heading>
          </Row>
        </Grid>
      </>
    );
  }

  const student = threadUserContext.assignedStudents?.find(
    s => s?.studentId === props.studentId
  )?.student;

  const pinnedStatus: string = preparePinnedStatus(pinnedDrawer);
  const sessionStatus: string = prepareSessionStatus(sessionDrawer);
  const activeStudents: UserFragment_assignedStudents[] = userData?.user
    ? userData.user.assignedStudents.filter(as => as.hasActivePrograms)
    : [];

  return (
    <Grid>
      <Row type="flex" direction="column" justify="space-between" height="100%">
        <Col grow={1} span={24} margin="0 0 130px 0">
          <StudentPrograms
            items={allPrograms}
            studentId={props.studentId}
            onAbc={props.onAbc}
            onChart={props.onChart}
            onCurriculumAdd={props.onCurriculumAdd}
            onProgramEdit={props.onProgramEdit}
            onProgramMenuItemClick={props.onProgramMenuItemClick}
            onProgramToggle={handleProgramToggle}
            onSelectStudent={handleSelectStudent}
          />
        </Col>
        <Card
          left="0"
          bottom="0"
          position="fixed"
          width="100%"
          margin="0"
          padding="16px 16px 12px 16px"
          color="primary"
          variation={flashBar ? 7 : 9}
          onClick={handleOpenSession}
          bordered={false}
        >
          <Row type="flex">
            <Col
              color="navbar"
              margin="-16px 11px -12px -16px"
              grow={1}
              variation={9}
              xs={0}
              sm={0}
              md={0}
              lg={5}
              xl={4}
              xxl={4}
            />
            <Col xs={24} sm={24} md={24} lg={19} xl={20}>
              <DrawerBar
                id="openProgramDrawer"
                leftHeading="Pinned Board"
                rightHeading="Session"
                leftStatus={pinnedStatus}
                rightStatus={sessionStatus}
                icon="fa-angle-down fas"
                isOpen={false}
              />
            </Col>
          </Row>
        </Card>
      </Row>
      <Drawer
        mask={false}
        margin="0"
        padding="0"
        height="100%"
        placement="bottom"
        visible={props.inSession}
        closable={false}
      >
        <Grid
          type="flex"
          height="100vh"
          width="100%"
          color="primary"
          variation={9}
        >
          <Card margin="0" bordered={false} padding="0" boxShadow="0 2px 8px 0">
            <Card
              width="100%"
              margin="0"
              padding="16px 16px 12px 16px"
              color="primary"
              variation={9}
              onClick={handleCloseSession}
              bordered={false}
            >
              <DrawerBar
                id="closeProgramDrawer"
                leftHeading="Pinned Board"
                rightHeading="Session"
                leftStatus={pinnedStatus}
                rightStatus={sessionStatus}
                icon="fa-times fas"
                isOpen={true}
                student={student}
                onClearAll={handleClearDrawer}
                onSessionEnd={handleSessionEnd}
                size="25px"
              />
            </Card>
          </Card>
          {(activeStudents.length !== 1 ||
            activeStudents[0].studentId !== props.studentId) && (
            <Row>
              <Tabs
                type="drawer"
                onChange={handleSelectStudentInTab}
                activeKey={props.studentId}
              >
                {activeStudents.map(assigned => (
                  <TabPane
                    tab={
                      <>
                        <Row>
                          <Col grow={1}>
                            <Row type="flex" justify="center">
                              <ProtectedAvatar
                                color="primary"
                                size={58}
                                src={assigned.student.avatarUrl || null}
                              >
                                {getInitials(assigned.student.fullName)}
                              </ProtectedAvatar>{" "}
                            </Row>
                          </Col>
                        </Row>
                        <Row>{assigned.student.fullName}</Row>
                      </>
                    }
                    key={assigned.student.id}
                  />
                ))}
              </Tabs>
            </Row>
          )}
          <Row scroll="vertical" type="flex" grow={1}>
            <Col grow={1}>
              <ProgramDrawer
                pinnedPrograms={pinnedDrawer}
                sessionSkills={sessionDrawer}
                currentStudentId={props.studentId}
                studentName={student?.fullName ?? ""}
                onAbc={props.onAbc}
                onChart={props.onChart}
                onEdit={props.onProgramEdit}
              />
            </Col>
          </Row>
        </Grid>
      </Drawer>
    </Grid>
  );
};

type IMemoCache = Map<string, IMemoCacheEntry>;

interface IMemoCacheEntry {
  data: IProgramSessions_programSessions;
  list: IProgramItem;
  drawer?: IProgramItem;
}

const preparePinnedStatus = (programs: IProgramItem[]) => {
  const count = programs.length;
  return count + " program" + (count > 1 ? "s" : "");
};

const prepareSessionStatus = (programs: IProgramItem[]) => {
  const count = programs.length;
  let complete = 0;
  programs.forEach(program => {
    if (program.state === DataPointStateEnum.COMPLETED) {
      complete++;
    }
  });
  return complete + " of " + count + " complete";
};

const prepareTable = (
  activeData: IProgramSessions | undefined,
  cache: IMemoCache,
  context: IThreadContext
) => {
  const items: IProgramItem[] =
    activeData?.programSessions?.map(
      ps => getOrComputeProgramItem(ps, cache, context).list
    ) ?? [];

  return items;
};

const prepareDrawer = (
  data: IProgramSessions | undefined,
  pinned: boolean,
  cache: IMemoCache,
  context: IThreadContext
): IProgramItem[] => {
  const items: IProgramItem[] = [];

  // short-circuit
  if (!data || !data.programSessions) {
    return items;
  }

  data.programSessions.forEach(ps => {
    const programPinned =
      ps.program.pinned ?? ps.program.drawer === DrawerEnum.PUBLIC_DRAWER;
    if (ps.session !== null && programPinned === pinned) {
      const item = getOrComputeProgramItem(ps, cache, context).drawer;

      if (item) {
        items.push(item);
      }
    }
  });

  return items.sort((a, b) => a.drawerPosition - b.drawerPosition);
};

function getOrComputeProgramItem(
  ps: IProgramSessions_programSessions,
  cache: IMemoCache,
  context: IThreadContext
) {
  const cacheKey = ps.program.id;
  let cacheEntry = cache.get(cacheKey);

  if (!cacheEntry || cacheEntry.data !== ps) {
    cacheEntry = {
      data: ps,
      list: prepareListProgramItem(ps, context),
      drawer: prepareDrawerProgramItem(ps, context)
    };

    cache.set(cacheKey, cacheEntry);
  }

  return cacheEntry;
}

function prepareListProgramItem(
  ps: IProgramSessions_programSessions,
  context: IThreadContext
): IProgramItem {
  const program = ps.program;
  const point = ps.session?.dataPoint;

  const item = {} as IProgramItem;
  item.copyProtected = CurriculumHelper.isCopyProtected(program, context);
  item.programId = program.id;
  item.drawer = program.drawer;
  item.pinned = program.pinned ?? program.drawer === DrawerEnum.PUBLIC_DRAWER;
  item.checked = ps.session !== null; // program.active;
  item.targets = [];
  item.type = program.type;
  item.name = program.name!;
  item.lastRun = !!program.lastRun ? moment.utc(program.lastRun) : null;
  if (point) {
    item.state = point.state ?? DataPointStateEnum.NONE;
    item.startedAt = point.startedAt ? moment(point.startedAt) : null;
    item.attemptedOverride = point.attemptedOverride;
    item.correctOverride = point.correctOverride;
    item.trials = point.trials;
  }
  if (ps.currentPhase != null) {
    item.totalCount = ps.currentPhase.numberOfTrials!;
    item.sampleTime = ps.currentPhase.lengthOfEachInterval ?? null;
    ps.currentPhase.targetIds
      .map(id => program.targets.find(target => target.id === id))
      .forEach(target => {
        if (target) {
          item.targets.push(target.targetDescription!);
        }
      });
    ps.currentPhase.steps.forEach(step => {
      if (step.state === StepStateEnum.ACTIVE) {
        item.targets.push(step.description!);
      }
    });
    item.phaseId = ps.currentPhase.id;
  }
  item.locked = program.locked;

  return item;
}

function prepareDrawerProgramItem(
  ps: IProgramSessions_programSessions,
  context: IThreadContext
): IProgramItem | undefined {
  const program = ps.program;
  if (ps.session !== null) {
    const point = ps.session.dataPoint;
    const phase = ps.session.phase ?? ps.currentPhase;
    const item = {} as IProgramItem;
    item.copyProtected = CurriculumHelper.isCopyProtected(program, context);
    item.drawer = program.drawer;
    item.pinned = program.pinned ?? program.drawer === DrawerEnum.PUBLIC_DRAWER;
    item.drawerPosition = ps.session.drawerPosition;
    item.name = program.name ?? "";
    item.programId = program.id;
    item.phaseId = phase!.id;
    item.type = program.type;
    item.targets = [];
    item.discreteTargets = [] as IDiscreteTarget[];
    if (point && phase) {
      item.method = point.method;
      if (program.type === ProgramTypeEnum.DTT) {
        const countMap: { [key: string]: ICountMap } = {};
        point.trials.forEach(trial => {
          if (trial.targetId) {
            const appChange =
              trial.result !== TrialResultEnum.NOT_APPLICABLE &&
              trial.result !== TrialResultEnum.NONE
                ? 1
                : 0;
            const correctChange = trial.result === TrialResultEnum.PLUS ? 1 : 0;
            if (!countMap[trial.targetId]) {
              countMap[trial.targetId] = {
                applicableTrialCount: appChange,
                correctTrialCount: correctChange
              };
            } else {
              countMap[trial.targetId].applicableTrialCount += appChange;
              countMap[trial.targetId].correctTrialCount += correctChange;
            }
          }
        });
        phase.targetIds
          .map(id => program.targets.find(target => target.id === id))
          .forEach(target => {
            if (target) {
              item.targets.push(target.targetDescription!);
              item.discreteTargets.push({
                id: target.id,
                description: target.targetDescription
                  ? target.targetDescription
                  : "",
                applicableCount: countMap[target.id]
                  ? countMap[target.id].applicableTrialCount
                  : 0,
                correctCount: countMap[target.id]
                  ? countMap[target.id].correctTrialCount
                  : 0
              });
            }
          });
      } else if (program.type === ProgramTypeEnum.TASK_ANALYSIS) {
        item.steps = phase.steps;
        item.attemptedCount = DataPointHelper.attemptedTrialCount(point);
      } else if (program.type === ProgramTypeEnum.DURATION) {
        const runningDuration = point.trials.find(t => t.duration == null);
        if (runningDuration) {
          item.duration = moment().diff(
            moment(runningDuration.occurredAt),
            "seconds"
          );
        } else {
          item.isComplete = true;
        }
      }
      item.runId = point.id;
      item.applicableCount = DataPointHelper.applicableTrialCount(
        program.type,
        point,
        phase
      );
      item.correctCount = DataPointHelper.correctTrialCount(
        program.type,
        point,
        phase
      );
    } else {
      item.applicableCount = 0;
      item.correctCount = 0;
      if (program.type === ProgramTypeEnum.DURATION) {
        item.isComplete = true;
      }
    }
    if (point) {
      item.state = point.state;
      item.currentCount = point.trials.length;
      item.startedAt = point.startedAt ? moment(point.startedAt) : null;
      item.attemptedOverride = point.attemptedOverride;
      item.correctOverride = point.correctOverride;
      item.durationOverride = point.durationOverride;
      item.trials = point.trials;
    }
    if (phase) {
      item.totalCount = phase.numberOfTrials ?? 0;
      item.errorless = phase.errorless;
      item.prompt = phase.prompt ?? "";
      item.programDescription = phase.procedureDetails;
      item.discriminativeStimulus = phase.instructionalCue;
      item.reinforcementSchedule = phase.reinforcementSchedule;
      item.reinforcement = phase.reinforcementRatio;
      item.reinforcementType = phase.typeOfReinforcement;
      item.locked = program.locked;
      item.sampleTime = phase.lengthOfEachInterval;
      item.defaultResult =
        phase.defaultTrialResult === TrialResultEnum.NONE
          ? TrialResultEnum.NOT_APPLICABLE
          : phase.defaultTrialResult;
    } else {
      item.totalCount = 0;
    }

    return item;
  }

  return undefined;
}
