import { Alert, Button, Col, Link, Modal } from "@raudabaugh/thread-ui";
import React from "react";

export interface IModalConfirmationProps {
  message: string;
  title: string;
  type?: "success" | "info" | "warning";
  okButtonTitle?: string;
  visible?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const ModalConfirmation = (props: IModalConfirmationProps) => {
  return (
    <Modal
      title={props.title}
      onCancel={props.onCancel}
      visible={props.visible || false}
      footer={
        <Col padding={"4px"}>
          <Button type={"primary"}>
            <Link onClick={props.onCancel}>Cancel</Link>
          </Button>
          <Button type={"ghost"}>
            <Link onClick={props.onOk}>{props.okButtonTitle || "ok"}</Link>
          </Button>
        </Col>
      }
    >
      <Alert
        showIcon={true}
        closable={false}
        type={props.type || "info"}
        key="net"
        message={props.message}
      />
    </Modal>
  );
};
