import {
  Col,
  Grid,
  Heading,
  Icon,
  List,
  ListItem,
  Row,
  Tag
} from "@raudabaugh/thread-ui";
import moment from "moment";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { SessionNoteStatusEnum } from "Shared/Api/globalTypes";
import { SessionNoteFragment } from "Shared/Api/SessionNoteFragment";
import { LabelHelper } from "Shared/LabelHelper";
import { IActiveSessionNotes } from "Shared/Api/IActiveSessionNotes";

export interface IActiveNotesProps {
  currentStudentId: string;
  showOther: boolean;
  onSessionNoteSelect: (note: SessionNoteFragment) => void;
  loading: boolean;
  data: IActiveSessionNotes | undefined;
}

export const ActiveNotes = (props: IActiveNotesProps) => {
  const { threadUserContext } = useThreadContext();

  const handleNoteSelect = (note: SessionNoteFragment) => {
    props.onSessionNoteSelect(note);
  };

  return (
    <>
      <LoadingScreen loading={props.loading} />
      <Grid margin="-16px 16px 0 16px">
        <List>
          {props.data?.sessionNotesActive &&
            props.data.sessionNotesActive.map(sessionNote => {
              if (sessionNote === undefined) {
                return null;
              }
              if (
                sessionNote.status !== SessionNoteStatusEnum.SUBMITTED &&
                (props.showOther ||
                  sessionNote.createdById === threadUserContext.userId)
              ) {
                return (
                  <ListItem key={sessionNote.id}>
                    <Grid
                      onClick={() => handleNoteSelect(sessionNote)}
                      width="100%"
                    >
                      <Row type="flex" justify="space-between">
                        <Col>
                          <Heading level={5} color="primary" weight="medium">
                            {moment(sessionNote.start).format(
                              "ddd, MMM. D, YYYY"
                            )}
                          </Heading>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between">
                        <Col>
                          {moment(sessionNote.start).format("h:mm a")}
                          {sessionNote.end !== null
                            ? " - " + moment(sessionNote.end).format("h:mm a")
                            : ""}
                          {sessionNote.createdById !== threadUserContext.userId
                            ? ` by ${sessionNote.createdByName}`
                            : ""}
                        </Col>
                        <Col margin="0 0 0 auto">
                          <Icon type="fa-chevron-right fas" />
                        </Col>
                      </Row>
                      <Row>
                        <Col margin="4px 0">
                          <Tag
                            color={
                              sessionNote.status ===
                              SessionNoteStatusEnum.NEEDS_SIGNATURE
                                ? "warning"
                                : "error"
                            }
                          >
                            {LabelHelper.sessionNoteStatusLabel(
                              sessionNote.status
                            )}
                          </Tag>
                        </Col>
                      </Row>
                    </Grid>
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
        </List>
      </Grid>
    </>
  );
};
