import React, { useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { findPermission } from "../Shared/RolesMap";
import { OrganizationSettingsEnum } from "../App";
import { SettingsHelper } from "../Shared/SettingsHelper";
import { RoutePathEnum } from "./RoutePathEnum";
import { DefaultStudent } from "./DefaultStudent";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { StaffRoleEnum, Permissions } from "Shared/Api/globalTypes";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OfflinePageUnavailable } from "./OfflinePageUnavailable";
import { RouterHelper } from "./RouterHelper";
import { StudentProfile } from "PeopleManagement/StudentProfile/StudentProfile";

const AddPeople = React.lazy(() =>
  import(
    "../PeopleManagement/AddPeople/AddPeople" /* webpackChunkName: "add-people" */
  ).then(module => ({
    default: module.AddPeople
  }))
);

const StaffProfile = React.lazy(() =>
  import(
    "../PeopleManagement/StaffProfile/StaffProfile" /* webpackChunkName: "staff-profile" */
  ).then(module => ({
    default: module.StaffProfile
  }))
);

const PeopleManagement = React.lazy(() =>
  import(
    "../PeopleManagement/PeopleManagement" /* webpackChunkName: "people-management" */
  ).then(module => ({
    default: module.PeopleManagement
  }))
);

interface IPeopleManagementParams {
  staffId: string;
  studentId: string;
}

const PeopleToken = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const match = useRouteMatch<IPeopleManagementParams>();
  const location = useLocation();
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }
  const showDataCollection = SettingsHelper.boolean(
    threadUserContext,
    OrganizationSettingsEnum.MOBILE_DATA_COLLECTION,
    true
  );
  if (
    findPermission(threadUserContext.role, Permissions.VIEW_ALL_STUDENTS) ||
    findPermission(threadUserContext.role, Permissions.VIEW_ASSIGNED_STUDENTS)
  ) {
    return (
      <PeopleManagement history={history} match={match} location={location} />
    );
  } else if (showDataCollection !== false) {
    return (
      <DefaultStudent path={RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS} />
    );
  } else {
    RouterHelper.redirectToStaffProfile(threadUserContext.userId, history);
    return null;
  }
};

const StaffProfileById = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { staffId } = useParams<IPeopleManagementParams>();
  const handleBackPress = useCallback(() => {
    history.goBack();
  }, [history]);
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }

  const canViewAll = findPermission(
    threadUserContext.role,
    Permissions.VIEW_ALL_STAFF
  );
  if (staffId && (canViewAll || staffId === threadUserContext.userId)) {
    return <StaffProfile id={staffId} onBackPressed={handleBackPress} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const StudentProfileById = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { studentId } = useParams<IPeopleManagementParams>();

  const handleBackPress = useCallback(() => {
    RouterHelper.redirectToPeopleManagement(history);
  }, [history]);

  const isStudentIdValid = threadUserContext.assignedStudents.find(
    student => student.studentId === studentId
  )?.student;
  if (
    studentId &&
    (isStudentIdValid ||
      threadUserContext.role === StaffRoleEnum.ADMIN ||
      threadUserContext.role === StaffRoleEnum.SUPERVISOR)
  ) {
    return <StudentProfile id={studentId} onBackPressed={handleBackPress} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.DATA_COLLECTION,
          state: {
            sideMenu: RoutePathEnum.DATA_COLLECTION,
            current: RoutePathEnum.DATA_COLLECTION
          }
        }}
      />
    );
  }
};

const AddStaffAndStudents = () => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const handleBackPress = useCallback(() => {
    history.goBack();
  }, [history]);
  const online = useOnlineStatus();

  if (!online) {
    return <OfflinePageUnavailable />;
  }

  return (
    <AddPeople
      onBackPressed={handleBackPress}
      orgId={threadUserContext.orgId}
    />
  );
};

interface PeopleManagementRouterProps {}

export const PeopleManagementRouter = (props: PeopleManagementRouterProps) => {
  return (
    <Switch>
      <Route
        path={RoutePathEnum.PEOPLE_MANAGEMENT_STUDENT_PROFILE}
        exact={true}
      >
        <StudentProfileById />
      </Route>
      <Route path={RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE} exact={true}>
        <StaffProfileById />
      </Route>
      <Route path={RoutePathEnum.PEOPLE_MANAGEMENT_CREATE} exact={true}>
        <AddStaffAndStudents />
      </Route>
      <Route path={RoutePathEnum.PEOPLE_MANAGEMENT} exact={true}>
        <PeopleToken />
      </Route>
    </Switch>
  );
};
