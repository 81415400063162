import gql from "graphql-tag";
import {
  FRAGMENT_PROGRAM,
  FRAGMENT_SESSION_NOTE,
  FRAGMENT_MESSAGE,
  FRAGMENT_CURRICULUM,
  FRAGMENT_PROGRAM_FOLDER,
  FRAGMENT_PROGRAM_TEMPLATE,
  FRAGMENT_CLINICAL_FILE,
  FRAGMENT_ABC_DATA,
  FRAGMENT_PROGRAM_SESSION,
  FRAGMENT_PROGRAM_CHART,
  FRAGMENT_ROLE,
  FRAGMENT_STAFF,
  FRAGMENT_ORGANIZATION,
  FRAGMENT_USER,
  FRAGMENT_STUDENT_PROFILE,
  FRAGMENT_CUSTOM_SETTING_CATEGORY
} from "./Fragments";

export const QUERY_PROGRAM_SESSIONS = gql`
  query IProgramSessions($studentId: GUID!) {
    programSessions(studentId: $studentId) {
      ...ProgramSessionFragment
    }
  }
  ${FRAGMENT_PROGRAM_SESSION}
`;

export const QUERY_PROGRAM_CHART = gql`
  query IProgramChart(
    $studentId: GUID!
    $programId: GUID!
    $mostRecentLimit: Boolean
  ) {
    programChart(
      studentId: $studentId
      programId: $programId
      mostRecentLimit: $mostRecentLimit
    ) {
      ...ProgramChartFragment
    }
  }
  ${FRAGMENT_PROGRAM_CHART}
`;

export const QUERY_PROGRAMS_FROM_ARCHIVE = gql`
  query IProgramsFromArchive(
    $studentId: GUID!
    $orderBy: ArchiveOrderBy!
    $sortDirection: ArchiveSortDirection!
    $filterByArchiveType: ArchiveTypeEnum!
    $filterByProgramType: ProgramTypeEnum!
    $after: Cursor
    $first: Int
    $contextId: String
  ) {
    programsFromArchive(
      studentId: $studentId
      orderBy: $orderBy
      sortDirection: $sortDirection
      filterByArchiveType: $filterByArchiveType
      filterByProgramType: $filterByProgramType
      after: $after
      first: $first
      contextId: $contextId
    ) {
      edges {
        cursor
        node {
          ...ProgramFragment
        }
      }
      totalCount
    }
  }
  ${FRAGMENT_PROGRAM}
`;

export const QUERY_SESSION_NOTES = gql`
  query ISessionNotes(
    $statusFilter: SessionNoteStatusFilter!
    $first: Int!
    $studentId: GUID!
    $days: Int
  ) {
    sessionNotes(
      statusFilter: $statusFilter
      first: $first
      studentId: $studentId
      days: $days
    ) {
      edges {
        node {
          ...SessionNoteFragment
        }
      }
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

export const QUERY_ACTIVE_SESSION_NOTES = gql`
  query IActiveSessionNotes($studentId: GUID!) {
    sessionNotesActive(studentId: $studentId) {
      ...SessionNoteFragment
    }
  }
  ${FRAGMENT_SESSION_NOTE}
`;

export const QUERY_CLINICAL_FILES_BY_STUDENT = gql`
  query IClinicalFilesByStudent($studentId: GUID!) {
    clinicalFilesByStudent(studentId: $studentId) {
      ...ClinicalFileFragment
    }
  }
  ${FRAGMENT_CLINICAL_FILE}
`;

export const QUERY_ACTIVE_MESSAGES = gql`
  query IActiveMessages {
    messagesActive {
      ...MessageFragment
    }
  }
  ${FRAGMENT_MESSAGE}
`;

export const QUERY_CURRICULUM = gql`
  query ICurriculum($id: GUID!) {
    curriculum(curriculumId: $id) {
      ...CurriculumFragment
    }
  }
  ${FRAGMENT_CURRICULUM}
`;

export const QUERY_CURRICULUMS = gql`
  query ICurriculums {
    curriculums {
      ...CurriculumFragment
    }
  }
  ${FRAGMENT_CURRICULUM}
`;

export const QUERY_CURRICULUMS_AVAILABLE = gql`
  query ICurriculumsAvailable {
    curriculumsAvailable {
      ...CurriculumFragment
    }
  }
  ${FRAGMENT_CURRICULUM}
`;

export const QUERY_CURRICULUM_FOLDER = gql`
  query ICurriculumFolder($curriculumId: GUID!, $folderId: GUID!) {
    curriculumFolder(curriculumId: $curriculumId, folderId: $folderId) {
      ...ProgramFolderFragment
      folders {
        ...ProgramFolderFragment
      }
      templates {
        id
        programName
      }
    }
  }
  ${FRAGMENT_PROGRAM_FOLDER}
`;

export const QUERY_CURRICULUM_TEMPLATE = gql`
  query ICurriculumTemplate($curriculumId: GUID!, $templateId: GUID!) {
    curriculumTemplate(curriculumId: $curriculumId, templateId: $templateId) {
      ...ProgramTemplateFragment
    }
  }
  ${FRAGMENT_PROGRAM_TEMPLATE}
`;

export const QUERY_CURRICULUM_TEMPLATE_SEARCH = gql`
  query ICurriculumTemplateSearch(
    $forString: String
    $curriculumId: GUID
    $folderId: GUID
    $first: Int
    $after: Cursor
  ) {
    curriculumTemplateSearch(
      forString: $forString
      curriculumId: $curriculumId
      folderId: $folderId
      first: $first
      after: $after
    ) {
      totalCount
      edges {
        node {
          id
          programName
          curriculumId
          curriculumName
          score
          folderPath {
            id
            name
          }
        }
        cursor
      }
    }
  }
`;

export const QUERY_ABC_DATA_BY_STUDENT = gql`
  query IABCDataByStudent($studentId: GUID!) {
    aBCDataByStudent(studentId: $studentId) {
      ...AbcDataFragment
    }
  }
  ${FRAGMENT_ABC_DATA}
`;

export const QUERY_STAFF_SEARCH = gql`
  query IStaffSearch(
    $forString: String
    $first: Int
    $after: Cursor
    $sortBy: String
    $sortDirection: String
    $sortFirst: [GUID!]
    $roleFilters: [StaffRoleEnum!]
    $statusFilter: StaffStatusEnum
    $activeOnly: Boolean
  ) {
    staffSearch(
      forString: $forString
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      sortFirst: $sortFirst
      roleFilters: $roleFilters
      statusFilter: $statusFilter
      activeOnly: $activeOnly
    ) {
      totalCount
      edges {
        node {
          id
          fullName
          status
          avatarUrl
          email
          firstName
          lastName
          phoneNumber
          role
          isSysOp
        }
        cursor
      }
    }
  }
`;

export const QUERY_STUDENT_SEARCH = gql`
  query IStudentSearch(
    $forString: String
    $first: Int
    $after: Cursor
    $sortBy: String
    $sortDirection: String
    $sortFirst: [GUID!]
    $statusFilter: StudentStatusEnum
  ) {
    studentSearch(
      forString: $forString
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      sortFirst: $sortFirst
      statusFilter: $statusFilter
    ) {
      totalCount
      edges {
        node {
          ...StudentProfileFragment
        }
        cursor
      }
    }
  }
  ${FRAGMENT_STUDENT_PROFILE}
`;

export const QUERY_ORGANIZATION = gql`
  query IOrganization($id: GUID!) {
    organization(id: $id) {
      ...OrganizationFragment
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

export const QUERY_CUSTOM_SETTINGS = gql`
  query ICustomSettings {
    customSettings {
      ...CustomSettingCategoryFragment
      subcategories {
        ...CustomSettingCategoryFragment
        subcategories {
          ...CustomSettingCategoryFragment
        }
      }
    }
  }
  ${FRAGMENT_CUSTOM_SETTING_CATEGORY}
`;

export const QUERY_STAFF = gql`
  query IStaff($id: GUID!) {
    staff(id: $id) {
      ...StaffFragment
    }
  }
  ${FRAGMENT_STAFF}
`;

export const QUERY_STAFFS_ASSIGNED = gql`
  query IStaffsAssigned($studentId: GUID!) {
    staffsAssigned(studentId: $studentId) {
      ...StaffFragment
    }
  }
  ${FRAGMENT_STAFF}
`;

export const QUERY_STUDENT = gql`
  query IStudent($id: GUID!) {
    student(studentId: $id) {
      ...StudentProfileFragment
    }
  }
  ${FRAGMENT_STUDENT_PROFILE}
`;

export const QUERY_ROLES = gql`
  query IRoles {
    roles {
      ...RoleFragment
    }
  }
  ${FRAGMENT_ROLE}
`;

export const QUERY_USER = gql`
  query IUser($id: GUID) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_USER_BY_EMAIL = gql`
  query IUserByEmail($email: String!) {
    userByEmail(email: $email) {
      ...UserFragment
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_PEOPLE_COUNTS = gql`
  query IPeopleCounts {
    peopleCounts {
      studentCount
      inactiveStudentCount
      staffCount
      inactiveStaffCount
      adminCount
      supervisorCount
      instructorCount
    }
  }
`;
