import { Grid, Row, List } from "@raudabaugh/thread-ui";
import { useUpdateTargetMutation } from "DataAccess/TargetsData";
import { SimpleMaintenanceCard } from "./SimpleMaintenanceCard";
import React, { memo, useEffect, useState } from "react";
import { ProgramTypeEnum, TargetAction } from "../../Shared/Api/globalTypes";
import isEqual from "react-fast-compare";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { TargetFragment } from "Shared/Api/TargetFragment";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import moment from "moment";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { StudentTabEnum } from "Shared/StudentTabEnum";

export interface IMaintenanceBaseProps {
  currentStudentId: string;
}

interface MaintenanceTarget extends TargetFragment {
  maintenanceMinusCount: number;
  maintenancePlusCount: number;
  lastMaintenanceDateTime: string | null;
  programId: string;
  programName: string;
}

const MaintenanceViewComponent = (props: IMaintenanceBaseProps) => {
  const [sortTargets, setSortTargets] = useState(false);
  const [sortedTargetIds, setSortedTargetIds] = useState<string[]>([]);
  const {
    threadUserContext: { assignedStudents }
  } = useThreadContext();

  const online = useOnlineStatus();
  const { targetUpdateCounters } = useUpdateTargetMutation();
  const [sortedTargets, setSortedTargets] = useState<MaintenanceTarget[]>([]);

  const availableOffline =
    assignedStudents.filter(
      s => s.studentId === props.currentStudentId && s.availableOffline
    ).length > 0;

  const {
    data: sessionData,
    loading: sessionLoading,
    error: sessionError,
    refetch: sessionRefetch
  } = useProgramSessionsQuery(props.currentStudentId, {
    skip: !online && !availableOffline
  });

  useEffect(() => {
    if (online) {
      sessionRefetch();
    }
  }, [sessionRefetch, online]);

  const handleCompleted = async (targetId: string, programId: string) => {
    try {
      await targetUpdateCounters(
        props.currentStudentId,
        programId,
        targetId,
        TargetAction.COMPLETE
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Unable to access complete target"
      });
    }
  };

  const handleReset = async (targetId: string, programId: string) => {
    try {
      await targetUpdateCounters(
        props.currentStudentId,
        programId,
        targetId,
        TargetAction.RESET_COUNTERS
      );
      return true;
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Unable to access reset counters"
      });
      return false;
    }
  };

  const handlePlusClick = async (targetId: string, programId: string) => {
    try {
      await targetUpdateCounters(
        props.currentStudentId,
        programId,
        targetId,
        TargetAction.UPDATE_PLUS_COUNTER
      );
      return true;
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error: error,
        title: "Unable to access plus counter"
      });
      return false;
    }
  };

  const handleMinusClick = async (targetId: string, programId: string) => {
    try {
      await targetUpdateCounters(
        props.currentStudentId,
        programId,
        targetId,
        TargetAction.UPDATE_MINUS_COUNTER
      );
      return true;
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Unable to access minus counters"
      });
      return false;
    }
  };

  useEffect(() => {
    setSortTargets(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    !cancelled &&
      sessionError &&
      NotificationsHelper.ErrorNotification({
        error: sessionError,
        title: "Unable to access Maintenance Data",
        refreshPageOnClose: true
      });

    return () => {
      cancelled = true;
    };
  }, [sessionError]);

  useEffect(() => {
    const recentWindow = 24 * 60;
    let cancelled = false;

    if (!cancelled && sessionData) {
      console.log("Processing Session Data");
      const targets = sessionData.programSessions
        .filter(ps => ps.program.type === ProgramTypeEnum.DTT)
        .flatMap(ps =>
          ps.program.targets.map(t => {
            const maintTarget: MaintenanceTarget = {
              ...t,
              programId: ps.program.id,
              programName: ps.program.name ?? ""
            };
            return maintTarget;
          })
        )
        .filter(t => t.mastered && !t.completed)
        .sort((a, b) =>
          moment(a.lastMaintenanceDateTime ?? a.mastered).diff(
            moment(b.lastMaintenanceDateTime ?? b.mastered)
          )
        );
      let sortedIds = sortedTargetIds;
      if (sortTargets) {
        console.log("Sorting Targets");
        const recentlyAccessedTargets = targets.filter(
          t =>
            (t.lastMaintenanceDateTime &&
              moment()
                .utc()
                .diff(moment(t.lastMaintenanceDateTime), "minutes") <
                recentWindow) ||
            (t.mastered &&
              moment().utc().diff(moment(t.mastered), "minutes") < recentWindow)
        );
        const oldTargets = targets.filter(
          t => !recentlyAccessedTargets.includes(t)
        );
        const orderedTargets = recentlyAccessedTargets.concat(oldTargets);
        sortedIds = orderedTargets.map(t => t.id);
        setSortTargets(false);
        setSortedTargetIds(sortedIds);
      }
      console.log("Updating SortedTargets");
      setSortedTargets(
        targets.sort(
          (a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id)
        )
      );
    }

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortTargets, sessionData]);

  return (
    <StudentDataTabs
      studentId={props.currentStudentId}
      activeTab={StudentTabEnum.MAINTENANCE}
    >
      <Grid type="flex" width="100%">
        <Row>
          <List
            dataSource={sortedTargets}
            loading={sessionLoading}
            renderItem={(item: MaintenanceTarget, index: number) => {
              return (
                <SimpleMaintenanceCard
                  index={index}
                  targetId={item.id!}
                  targetTitle={item.targetDescription!}
                  programId={item.programId}
                  programTitle={item.programName}
                  minusCount={item.maintenanceMinusCount}
                  plusCount={item.maintenancePlusCount}
                  masteryDate={item.mastered!}
                  lastMaintenanceDateTime={item.lastMaintenanceDateTime!}
                  onPlusClick={handlePlusClick}
                  onMinusClick={handleMinusClick}
                  onReset={handleReset}
                  onCompleted={handleCompleted}
                />
              );
            }}
          />
        </Row>
      </Grid>
    </StudentDataTabs>
  );
};

MaintenanceViewComponent.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "MaintenanceViewComponent"
};

export const MaintenanceView = memo(MaintenanceViewComponent, isEqual);
