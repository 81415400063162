import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import {
  DatePicker,
  Form,
  FormItem,
  Heading,
  InputNumber,
  Modal,
  Select,
  SelectOption,
  TimePicker,
  Text
} from "@raudabaugh/thread-ui";
import { LoadingScreen } from "Shared/LoadingScreen";
import {
  useProgramChartQuery,
  useProgramSessionsQuery
} from "DataAccess/ProgramData";
import { DataPointStateEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { ChartHelper } from "Charts/ChartHelper";
import _ from "lodash";
import { IProgramSessions_programSessions_program } from "Shared/Api/IProgramSessions";
import { IProgramChart_programChart_phases } from "Shared/Api/IProgramChart";

interface IEnterScoreDialogProps {
  /**
   * Label for score input box
   */
  scoreLabel: string;
  /**
   * Label for date input box
   */
  dateLabel?: string;
  /**
   * Label for # of attempts input box
   */
  attemptsLabel?: string;
  /**
   * Default # of attempts.
   */
  defaultAttempts?: number;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Callback when the dialog is closed
   */
  onClose: () => void;
  /**
   * Callback when user clicks Save button
   */
  onSaveScore: (
    score: number,
    date: moment.Moment,
    attempts?: number,
    phaseId?: string
  ) => void;
  /**
   * Whether the modal is open or not
   */
  open: boolean;
  /**
   * Student Id of the data point
   */
  studentId: string;
  /**
   * Program Id of the data point
   */
  programId: string;
  /**
   * Only allow past Dates
   */
  onlyAllowPastDates?: boolean;
}

interface IEnterScoreDialogState {
  attempts?: number;
  date?: moment.Moment;
  score?: number;
  validationFailed: boolean;
}

interface IPhaseMap {
  phase: IProgramChart_programChart_phases;
  earliest: string;
  latest: string;
}

export const EnterScoreDialog = (props: IEnterScoreDialogProps) => {
  const [scoreWarningOpen, setScoreWarningOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState<string>();
  const [suggestedPhaseId, setSuggestedPhaseId] = useState<string>();
  const [program, setProgram] =
    useState<IProgramSessions_programSessions_program>();

  const [state, setState] = useState<IEnterScoreDialogState>({
    attempts: props.defaultAttempts,
    date: moment(),
    validationFailed: false
  });
  const { data: sessionData } = useProgramSessionsQuery(props.studentId ?? "", {
    skip: !props.studentId
  });

  const { data: chartData, loading } = useProgramChartQuery(
    props.studentId,
    props.programId,
    undefined,
    {
      skip: !props.open
    }
  );

  useEffect(() => {
    setProgram(
      _.first(
        sessionData!.programSessions.filter(
          i => i.program.id === props.programId
        )
      )?.program
    );
  }, [sessionData, props.programId]);

  useEffect(() => {
    if (!props.open) {
      setState({
        attempts: undefined,
        date: undefined,
        score: undefined,
        validationFailed: false
      });
    } else {
      setState({
        attempts: props.defaultAttempts,
        date: props.onlyAllowPastDates ? moment().subtract(1, 'days') : moment(),
        validationFailed: false
      });
    }
  }, [props.open, props.defaultAttempts, props.onlyAllowPastDates]);

  const [phaseMap, setPhaseMap] = useState<IPhaseMap[]>();

  useEffect(() => {
    if (chartData) {
      const map: IPhaseMap[] = chartData.programChart.phases.map(phase => {
        const points = chartData.programChart.dataPoints.filter(
          point => point.phaseId === phase.id && !point.softDeleted
        );
        const sorted = _.sortBy(points, i => i.completedAt);
        const earliest = _.first(sorted)?.completedAt;
        const latest = _.last(sorted)?.completedAt;
        return { phase, earliest, latest } as IPhaseMap;
      });
      const sortedMap = _.sortBy(map, i => i.latest);
      setPhaseMap(sortedMap);

      setSelectedPhaseId(_.last(sortedMap)?.phase.id);
      setSuggestedPhaseId(_.last(sortedMap)?.phase.id);
    }
  }, [chartData]);

  if (loading) {
    return <LoadingScreen loading={true} />;
  }

  const formItemLayout = {
    labelCol: {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    },
    wrapperCol: {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    }
  };

  const hasValidScore = () => {
    if (!state.score && state.score !== 0) {
      return false;
    }

    if (props.attemptsLabel) {
      return state.score <= (state.attempts ?? 0);
    }
    return true;
  };

  const hasValidAttempts = () => {
    if (!props.attemptsLabel) {
      return true;
    }

    if (!state.attempts) {
      return false;
    }

    const attempts = state.attempts ?? 0;
    const score = state.score ?? 0;

    return attempts > 0 && attempts >= score;
  };

  const changeFieldValue = (field: string, value: any) => {
    const newState = Object.assign({}, state, {
      [field]: value
    });
    setState(newState);
  };

  //Frequency and Duration types are compared without time, they can only have 1 data point per day
  const relevantDateTime = (date: Moment) => {
    if (
      chartData?.programChart.program.type === ProgramTypeEnum.FREQUENCY ||
      chartData?.programChart.program.type === ProgramTypeEnum.DURATION
    ) {
      return new Date(date.toDate().toDateString()).valueOf();
    } else {
      return date.valueOf();
    }
  };

  const updateSuggestedPhase = (newDate: Moment) => {
    if ((phaseMap?.length ?? 0) > 0) {
      let chosenPhaseMap = _.first(phaseMap);

      phaseMap?.every((i: IPhaseMap) => {
        if (
          relevantDateTime(newDate!) >= relevantDateTime(moment(i.earliest)) &&
          relevantDateTime(newDate!) <= relevantDateTime(moment(i.latest))
        ) {
          chosenPhaseMap = i;
          return false;
        } else if (
          relevantDateTime(newDate!) >= relevantDateTime(moment(i.latest))
        ) {
          chosenPhaseMap = i;
        }
        return true;
      });

      if (
        relevantDateTime(moment(chosenPhaseMap?.latest)) <=
        relevantDateTime(newDate!)
      ) {
        const emptyPhase = _.first(phaseMap?.filter(i => !i.latest));
        if (emptyPhase) chosenPhaseMap = emptyPhase;
      }
      setSuggestedPhaseId(chosenPhaseMap?.phase.id);
    }
  };

  const handleAttemptsChange = (value?: string | number) => {
    value && value >= 0
      ? changeFieldValue("attempts", value)
      : changeFieldValue("attempts", 0);
  };

  const handleScoreChange = (value?: string | number) => {
    value && value >= 0
      ? changeFieldValue("score", value)
      : changeFieldValue("score", 0);
  };

  const handleDateChange = (date: moment.Moment, dateString: string) => {
    if (date != null) {
      const newDate = state.date?.clone().set({
        year: date.get("year"),
        month: date.get("month"),
        date: date.get("date")
      });
      changeFieldValue("date", newDate);
      updateSuggestedPhase(newDate!);
    }
  };
  const handleTimeChange = (time: moment.Moment, dateString: string) => {
    if (time != null) {
      const newDate = state.date?.clone().set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second")
      });
      changeFieldValue("date", newDate);
      updateSuggestedPhase(newDate!);
    }
  };

  const handleSaveScore = () => {
    const valid = hasValidScore() && hasValidAttempts();
    changeFieldValue("validationFailed", !valid);

    if (!valid) {
      return;
    }

    if (!props.dateLabel) {
      handleSaveScoreContinue();
      return;
    }

    const dataPointOverwriteCheck = chartData?.programChart.dataPoints.filter(
      d =>
        d.completedAt &&
        state.date?.isSame(d.completedAt, "D") &&
        d.state === DataPointStateEnum.COMPLETED
    );

    if (dataPointOverwriteCheck && dataPointOverwriteCheck.length > 0) {
      setScoreWarningOpen(true);
    } else {
      handleSaveScoreContinue();
    }
  };

  const handleSaveScoreContinue = () => {
    setScoreWarningOpen(false);
    const score: number = state.score!;
    const date: moment.Moment = state.date!;
    const attempts: number | undefined = state.attempts;
    const phaseId: string | undefined = selectedPhaseId;
    props.onSaveScore(score, date, attempts, phaseId);
    changeFieldValue("attempts", undefined);
    changeFieldValue("date", undefined);

    props.onClose();
  };

  const handleScoreWarningCancel = () => {
    setScoreWarningOpen(false);
  };

  const handlePhaseChange = (value?: string) => {
    setSelectedPhaseId(value);
  };

  const renderDateInput = () => {
    if (props.dateLabel) {
      return (
        <FormItem label={props.dateLabel} {...formItemLayout}>
          <DatePicker
            size="large"
            placeholder=""
            onChange={handleDateChange}
            value={state.date}
            disabledDate={(current) => {
                if(!props.onlyAllowPastDates)
                  return false;
                return current && current.isAfter(moment().subtract(1, 'days'));
              }
            }
          />
          <TimePicker
            size="large"
            placeholder=""
            onChange={handleTimeChange}
            value={state.date}
          />
        </FormItem>
      );
    }
  };

  const renderPhaseInput = () => {
    if ((chartData?.programChart.phases.length ?? 0) > 1) {
      return (
        <FormItem label="Phase" {...formItemLayout}>
          <Select
            dropdownMatchSelectWidth={false}
            value={selectedPhaseId}
            onChange={(value: any) => {
              handlePhaseChange(value);
            }}
          >
            {phaseMap?.map((p, index) => {
              const phaseTargets = p.phase.targetIds.map(id =>
                program?.targets.find(t => t.id === id)
              );
              return (
                <SelectOption value={p.phase.id} key={p.phase.id}>
                  <Text>
                    {ChartHelper.getPhaseName(p.phase, phaseTargets)} (
                    {index + 1 === chartData?.programChart.phases.length
                      ? "Current: "
                      : ""}
                    {index + 1} of {chartData?.programChart.phases.length})
                  </Text>
                </SelectOption>
              );
            })}
          </Select>
          {suggestedPhaseId !== selectedPhaseId && (
            <Text type="warning">
              The datapoint is being put into an unexpected phase. Please double
              check it within the chart after saving
            </Text>
          )}
        </FormItem>
      );
    }
  };

  const renderAttemptsInput = (hasValidAttempts: boolean) => {
    if (props.attemptsLabel) {
      return (
        <FormItem label={props.attemptsLabel} {...formItemLayout}>
          <InputNumber
            forceKeypad={true}
            placeholder=""
            value={state.attempts}
            onChange={handleAttemptsChange}
          />
          {state.validationFailed && !hasValidAttempts && (
            <Heading color="error" level={6}>
              Please enter a valid value for {props.attemptsLabel}{" "}
            </Heading>
          )}
        </FormItem>
      );
    } else {
      return null;
    }
  };

  const validScore = hasValidScore();
  const validAttempts = hasValidAttempts();
  return (
    <>
      <Modal
        title="Are you sure you want to enter a score?"
        afterClose={handleScoreWarningCancel}
        visible={scoreWarningOpen}
        onOk={handleSaveScoreContinue}
        onCancel={handleScoreWarningCancel}
        okText="Yes"
      >
        Data that was previously entered will be overwritten. Would you like to
        proceed?
      </Modal>
      <Modal
        visible={props.open}
        title="Enter Score"
        okText="Save"
        onOk={handleSaveScore}
        cancelText="Cancel"
        onCancel={props.onClose}
        afterClose={props.onClose}
      >
        <Form>
          <FormItem label={props.scoreLabel} {...formItemLayout}>
            <InputNumber
              forceKeypad={true}
              placeholder=""
              value={state.score}
              onChange={handleScoreChange}
            />
            {state.validationFailed && !validScore && (
              <Heading color="error" level={6}>
                Please enter a valid value for {props.scoreLabel}{" "}
              </Heading>
            )}
          </FormItem>
          {renderAttemptsInput(validAttempts)}
          {renderDateInput()}
          {renderPhaseInput()}
        </Form>
      </Modal>
    </>
  );
};
