import { IChartData, IChartInfo, PointTypeEnum } from "@raudabaugh/thread-ui";
import {
  StepBarNameType,
  FrequencyStepsEnum,
  DurationStepsEnum
} from "./ChartTypes";
import moment from "moment";
import {
  DataPointTypeEnum,
  ProgramTypeEnum,
  StepStateEnum
} from "Shared/Api/globalTypes";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { getInitials } from "Shared/Initials";
import { flatten, isEmpty } from "lodash";
import { DataPointHelper } from "Shared/DataPointHelper";
import { IProgramChart_programChart_phases } from "Shared/Api/IProgramChart";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { ProgramFragment_targets } from "Shared/Api/ProgramFragment";

export interface IPhaseWithDataPoints
  extends IProgramChart_programChart_phases {
  points: IPhaseDataPoint[];
}

export interface IPhaseDataPoint extends DataPointFragment {
  x: string | null;
  y: number | null;
}
interface IChartSteps {
  divisor?: number;
  stepBarName?: StepBarNameType;
  maxValue?: number;
}

const CANVAS = document.createElement("canvas");

export class ChartHelper {
  static getYAxisLabels(
    stepBarName?: StepBarNameType,
    maxValue?: number
  ): string[] | undefined {
    switch (stepBarName) {
      case DurationStepsEnum.HOUR:
        let durSteps: string[] = [];
        const durStepMax = (maxValue! / 3600)+1;
        const durIncrementValue = durStepMax / 10;
        for (let i = 0; i <= 10; i++) {
          durSteps.push(`${Math.round((durIncrementValue * i)*10)/10}h`);
        }
        return durSteps;
      case DurationStepsEnum.HALF_AN_HOUR:
        return [
          "0h",
          "0.5h",
          "1h",
          "1.5h",
          "2h",
          "2.5h",
          "3h",
          "3.5h",
          "4h",
          "4.5h",
          "5h"
        ];
      case DurationStepsEnum.TEN_MINUTES:
        return [
          "0m",
          "10m",
          "20m",
          "30m",
          "40m",
          "50m",
          "60m",
          "70m",
          "80m",
          "90m",
          "100m"
        ];
      case DurationStepsEnum.FIVE_MINUTES:
        return [
          "0m",
          "5m",
          "10m",
          "15m",
          "20m",
          "25m",
          "30m",
          "35m",
          "40m",
          "45m",
          "50m"
        ];
      case DurationStepsEnum.MINUTE:
        return [
          "0m",
          "1m",
          "2m",
          "3m",
          "4m",
          "5m",
          "6m",
          "7m",
          "8m",
          "9m",
          "10m"
        ];
      case DurationStepsEnum.HALF_A_MINUTE:
        return [
          "0m",
          "0.5m",
          "1m",
          "1.5m",
          "2m",
          "2.5m",
          "3m",
          "3.5m",
          "4m",
          "4.5m",
          "5m"
        ];
      case DurationStepsEnum.TEN_SECONDS:
        return [
          "0s",
          "10s",
          "20s",
          "30s",
          "40s",
          "50s",
          "60s",
          "70s",
          "80s",
          "90s",
          "100s"
        ];
      case DurationStepsEnum.FIVE_SECONDS:
        return [
          "0s",
          "5s",
          "10s",
          "15s",
          "20s",
          "25s",
          "30s",
          "35s",
          "40s",
          "45s",
          "50s"
        ];

      case FrequencyStepsEnum.HUNDRED:
        let freqSteps: string[] = [];
        const freqStepMax = Math.ceil(Number(maxValue) / 100) * 100;
        const freqIncrementValue = freqStepMax / 10;
        for (let i = 0; i <= 10; i++) {
          freqSteps.push((freqIncrementValue * i).toString());
        }
        return freqSteps;

      case FrequencyStepsEnum.TEN:
        return [
          "0",
          "10",
          "20",
          "30",
          "40",
          "50",
          "60",
          "70",
          "80",
          "90",
          "100"
        ];
      case FrequencyStepsEnum.FIVE:
        return ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50"];
      case FrequencyStepsEnum.TWO:
        return ["0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20"];
      case FrequencyStepsEnum.ONE:
        return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

      default:
        return [
          "0%",
          "10%",
          "20%",
          "30%",
          "40%",
          "50%",
          "60%",
          "70%",
          "80%",
          "90%",
          "100%"
        ];
    }
  }

  static getYAxisTitle(
    chartType: ProgramTypeEnum,
    stepBarName?: StepBarNameType
  ): string {
    if (chartType === ProgramTypeEnum.FREQUENCY) {
      return "Occurrences";
    } else if (chartType === ProgramTypeEnum.DURATION) {
      if (
        stepBarName === DurationStepsEnum.HOUR ||
        stepBarName === DurationStepsEnum.HALF_AN_HOUR
      ) {
        return "Hours";
      } else if (
        stepBarName === DurationStepsEnum.TEN_SECONDS ||
        stepBarName === DurationStepsEnum.FIVE_SECONDS
      ) {
        return "Seconds";
      } else {
        return "Minutes";
      }
    }

    return "Accuracy";
  }

  static mapChartViewData(
    chartType: ProgramTypeEnum,
    phases: IPhaseWithDataPoints[],
    xLabelFormat: string
  ): IChartData {
    let labels: (string | null)[] = [],
      series: (number | null)[] = [],
      info: (IChartInfo | null)[] = [],
      steps: IChartSteps = {};

    const isBehaviorChart =
      chartType === ProgramTypeEnum.FREQUENCY ||
      chartType === ProgramTypeEnum.DURATION;

    const getPointType = (pointType: DataPointTypeEnum): PointTypeEnum => {
      switch (pointType) {
        case DataPointTypeEnum.POSTTEST:
          return PointTypeEnum.POSTTEST;

        case DataPointTypeEnum.PRETEST:
          return PointTypeEnum.PRETEST;

        case DataPointTypeEnum.PROBE:
          return PointTypeEnum.PROBE;

        default:
          return PointTypeEnum.STANDARD;
      }
    };

    // update the "y" value for
    phases.forEach(phase => {
      phase.points.forEach((point, index) => {
        const applicableTrialCount = DataPointHelper.applicableTrialCount(
          chartType,
          point,
          phase
        );

        const correctTrialCount = DataPointHelper.correctTrialCount(
          chartType,
          point,
          phase
        );

        if (chartType === ProgramTypeEnum.DURATION) {
          point.y = DataPointHelper.durationTotal(chartType, point, phase);
        } else {
          point.y = isBehaviorChart
            ? correctTrialCount
            : correctTrialCount / applicableTrialCount;
        }
      });
    });

    if (isBehaviorChart) {
      steps = this.getBehaviorSteps(phases, chartType);
    }

    phases.forEach(phase => {
      phase.points.forEach((point, index) => {
        this.updateProgressChartLists(
          labels,
          series,
          point.completedAt,
          point.y,
          chartType,
          steps.divisor!,
          index,
          phase.points,
          xLabelFormat
        );

        info.push({
          id: point.id,
          note: !!point.note,
          type: getPointType(point.pointType),
          creatorInitials: getInitials(point.createdByName)
        });
        info.push(null);
      });
    });
    labels.pop();
    series.pop();
    info.pop();

    const chartData: IChartData = {
      labels: labels,
      series: [series],
      info: info
    };
    if (isBehaviorChart) {
      chartData.stepBarName = steps.stepBarName;
      chartData.maxValue = steps.maxValue;
    }

    return chartData;
  }

  public static getPhaseName(
    phase: PhaseFragment,
    phaseTargets: (ProgramFragment_targets | undefined)[]
  ) {
    let phaseName = phase?.phaseNameOverride;
    if (!phaseName && phase.steps.length > 0) {
      phaseName =
        "Active steps " +
        phase.steps
          .map((s, index) => (s.state === StepStateEnum.ACTIVE ? index + 1 : 0))
          .filter(n => n !== 0)
          .join(", ");
    }
    if (!phaseName && phaseTargets.length > 0) {
      phaseName = phaseTargets.map(t => t?.targetDescription).join(", ");
    }
    if (!phaseName) {
      phaseName = phase?.definitionOfBehavior;
    }
    return phaseName ?? "";
  }

  public static getPrintLabel(value: any | null | undefined) {
    if (!value) return "";
    const date = new Date(value);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
  }

  public static getRowCount(
    string: string,
    blockWidth: number,
    fontSize: number
  ) {
    const font = `400 ${fontSize || 16}px "Roboto", sans-serif`;

    const context = CANVAS.getContext("2d");
    if (context == null) return 1;

    context.font = font;

    const wordsArray = ChartHelper.split(string);
    let rows = 0;

    while (!isEmpty(wordsArray)) {
      let counter = 0;
      let test = wordsArray[counter].substring(1);
      let testWidth = context.measureText(test).width;

      do {
        counter++;

        if (counter < wordsArray.length) {
          test += wordsArray[counter];
          testWidth = context.measureText(test).width;
        }
      } while (testWidth < blockWidth && counter < wordsArray.length);

      rows++;
      wordsArray.splice(0, counter);
    }

    return rows;
  }

  private static split(string: string) {
    var wordsArray = string.split(" ").filter(str => !isEmpty(str));
    let result: string[] = [];

    wordsArray.forEach(word => {
      let parts: string[] = [];

      if (word.indexOf("-") === -1) {
        result.push(` ${word}`);
      } else {
        parts = [];

        while (word !== "") {
          if (
            word[0] !== "-" ||
            (word[0] === "-" && word.length > 1 && word[1] !== "-")
          ) {
            const index = word.indexOf("-", 1);
            if (index === -1) {
              parts.push(word);
              word = "";
            } else {
              parts.push(word.substring(0, index + 1));
              word = word.substring(index + 1);
            }
          } else {
            parts.push("-");
            word = word.substring(1);
          }
        }

        parts[0] = " " + parts[0];
        result.push(...parts);
      }
    });

    return flatten(result);
  }

  private static getChartDataList(
    chartData: IChartData,
    totalMaxPointsNumber: number
  ): IChartData[] {
    let chartDataList: IChartData[] = [],
      remainingChartPointsNumber: number = chartData.labels.length,
      chartDataIndex: number = 0,
      filledChartData: IChartData,
      emptyLabels: (string | null)[],
      emptySeries: (number | null)[],
      emptyInfo: (IChartInfo | null)[];

    while (remainingChartPointsNumber > 0) {
      if (remainingChartPointsNumber >= totalMaxPointsNumber) {
        chartDataList.push({
          labels: chartData.labels.slice(
            chartDataIndex,
            chartDataIndex + totalMaxPointsNumber
          ),
          series: [
            chartData.series[0].slice(
              chartDataIndex,
              chartDataIndex + totalMaxPointsNumber
            )
          ],
          info: chartData.info.slice(
            chartDataIndex,
            chartDataIndex + totalMaxPointsNumber
          )
        });

        chartDataIndex += totalMaxPointsNumber - 1;
        remainingChartPointsNumber -= totalMaxPointsNumber - 1;
      } else {
        filledChartData = {
          labels: chartData.labels.slice(
            chartDataIndex,
            chartDataIndex + remainingChartPointsNumber
          ),
          series: [
            chartData.series[0].slice(
              chartDataIndex,
              chartDataIndex + remainingChartPointsNumber
            )
          ],
          info: chartData.info.slice(
            chartDataIndex,
            chartDataIndex + remainingChartPointsNumber
          )
        };

        remainingChartPointsNumber =
          totalMaxPointsNumber - remainingChartPointsNumber;

        emptyInfo = new Array(remainingChartPointsNumber).fill(null);
        emptySeries = new Array(remainingChartPointsNumber).fill(null);
        emptyLabels = new Array(remainingChartPointsNumber)
          .fill(null)
          .map((item, index) => (index % 2 === 0 ? null : ""));

        chartDataList.push({
          labels: filledChartData.labels.concat(emptyLabels),
          series: [filledChartData.series[0].concat(emptySeries)],
          info: filledChartData.info.concat(emptyInfo)
        });

        remainingChartPointsNumber = 0;
      }
    }

    return chartDataList;
  }

  private static getBehaviorSteps(
    phases: IPhaseWithDataPoints[],
    chartType: ProgramTypeEnum
  ): IChartSteps {
    const maxValue = phases.reduce((maxPhaseValue, phase) => {
      return phase.points.reduce((maxPointsValue, point) => {
        return maxPointsValue > point.y! ? maxPointsValue : point.y!;
      }, maxPhaseValue);
    }, 0);

    return chartType === ProgramTypeEnum.FREQUENCY
      ? this.getFrequencySteps(maxValue)
      : this.getDurationSteps(maxValue);
  }

  private static getFrequencySteps(value: number): IChartSteps {
    if (value > 100) {
      return {
        divisor: Math.ceil(Number(value) / 100),
        stepBarName: FrequencyStepsEnum.HUNDRED,
        maxValue: value
      };
    }
    if (value > 50) {
      return {
        divisor: 1,
        stepBarName: FrequencyStepsEnum.TEN
      };
    }
    if (value > 20) {
      return {
        divisor: 0.5,
        stepBarName: FrequencyStepsEnum.FIVE
      };
    }
    if (value > 10) {
      return {
        divisor: 0.2,
        stepBarName: FrequencyStepsEnum.TWO
      };
    }
    return {
      divisor: 0.1,
      stepBarName: FrequencyStepsEnum.ONE
    };
  }

  private static getDurationSteps(value: number): IChartSteps {
    if (value > 18000) {
      return {
        divisor: (3600+value)/100,
        stepBarName: DurationStepsEnum.HOUR,
        maxValue: value
      };
    }
    if (value > 6000) {
      return {
        divisor: 180,
        stepBarName: DurationStepsEnum.HALF_AN_HOUR
      };
    }
    if (value > 3000) {
      return {
        divisor: 60,
        stepBarName: DurationStepsEnum.TEN_MINUTES
      };
    }
    if (value > 600) {
      return {
        divisor: 30,
        stepBarName: DurationStepsEnum.FIVE_MINUTES
      };
    }
    if (value > 300) {
      return {
        divisor: 6,
        stepBarName: DurationStepsEnum.MINUTE
      };
    }
    if (value > 100) {
      return {
        divisor: 3,
        stepBarName: DurationStepsEnum.HALF_A_MINUTE
      };
    }
    if (value > 50) {
      return {
        divisor: 1,
        stepBarName: DurationStepsEnum.TEN_SECONDS
      };
    }
    return {
      divisor: 0.5,
      stepBarName: DurationStepsEnum.FIVE_SECONDS
    };
  }

  private static adjustY(
    y: number,
    chartType: ProgramTypeEnum,
    divisor: number
  ): number {
    if (
      chartType === ProgramTypeEnum.DURATION ||
      chartType === ProgramTypeEnum.FREQUENCY
    ) {
      return y / divisor;
    }

    return y * 100;
  }

  private static getPrintChartLabel(
    date: moment.Moment,
    format: string
  ): string {
    return date.local().format(format);
  }

  private static updateProgressChartLists(
    labelsList: (string | Date | null)[],
    seriesList: (number | null)[],
    x: string | null,
    y: number | null,
    chartType: ProgramTypeEnum,
    stepsDivisor: number,
    index: number,
    pointsList: IPhaseDataPoint[],
    xLabelFormat: string
  ): void {
    if (!x) {
      labelsList.push(null);
    } else {
      const date = moment.utc(x);
      labelsList.push(this.getPrintChartLabel(date, xLabelFormat));
    }
    if (y || y === 0) {
      seriesList.push(this.adjustY(y, chartType, stepsDivisor));
    } else {
      seriesList.push(null);
    }

    if (index < pointsList.length - 1) {
      labelsList.push(null);
      seriesList.push(
        (this.adjustY(pointsList[index + 1].y!, chartType, stepsDivisor) +
          this.adjustY(y!, chartType, stepsDivisor)) /
          2
      );
    } else {
      labelsList.push("");
      seriesList.push(null);
    }
  }
}
