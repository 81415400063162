import {
  Card,
  Col,
  Grid,
  Heading,
  Image,
  Row,
  Illustration,
  Signature,
  Button,
  Modal
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  useSessionNoteSignMutation,
  useSessionNoteRevertMutation,
  useSessionNotesQuery,
  useActiveSessionNotesQuery
} from "../DataAccess/SessionNoteData";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { LabelHelper } from "../Shared/LabelHelper";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { SessionNoteFragment } from "Shared/Api/SessionNoteFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { SessionNoteStatusFilter, Permissions } from "Shared/Api/globalTypes";
import filesImage from "./undraw-files-illustration.svg";
import signImage from "./undraw-sign-illustration.svg";

interface ISessionNoteViewBaseProps {
  studentId: string;
  onClose: (
    message: string | undefined,
    description: string | undefined
  ) => void;
  onError: (
    error: ApolloError | undefined,
    message: string | undefined,
    description: string | undefined
  ) => void;
  onSign: (sessionId: string) => void;
  onEdit: (noteId: string) => void;
  sessionId: string;
}

export const SessionNoteView = (props: ISessionNoteViewBaseProps) => {
  const {
    threadUserContext: { scrollIntoView, role, userId, assignedStudents }
  } = useThreadContext();
  const { sessionNoteSign } = useSessionNoteSignMutation();
  const { sessionNoteRevert } = useSessionNoteRevertMutation();

  const [note, setNote] = useState<SessionNoteFragment>();
  const [loading, setLoading] = useState(false);

  const renderNote = (text: string | null) => {
    if (text == null) return "N/A";
    const lines = text.split("\n");
    return lines.map(line => {
      return <Heading level={5}>{line}</Heading>;
    });
  };

  /**
   * Data access methods
   */

  const {
    loading: activeLoading,
    error: activeError,
    data: activeData
  } = useActiveSessionNotesQuery(props.studentId);

  const {
    loading: notesLoading,
    error: notesError,
    data: notesData
  } = useSessionNotesQuery({
    studentId: props.studentId,
    statusFilter: SessionNoteStatusFilter.SUBMITTED_ONLY,
    first: 10000
  });

  useEffect(() => {
    scrollIntoView();
  }, [scrollIntoView]);

  useEffect(() => {
    (notesLoading || activeLoading) && setLoading(true);
    !notesLoading && !activeLoading && setLoading(false);
  }, [notesLoading, activeLoading]);

  useEffect(() => {
    if (notesError || activeError) {
      props.onError(
        notesError ?? activeError,
        "Failed to Load Note!",
        undefined
      );
    }
  }, [notesError, activeError, props, props.onError]);

  useEffect(() => {
    if (notesData) {
      const dto = notesData.sessionNotes?.edges?.find(
        e => e?.node.id === props.sessionId
      )?.node;
      if (dto) {
        setNote(dto);
      }
    }
  }, [notesData, props.sessionId]);

  useEffect(() => {
    if (activeData) {
      const dto = activeData.sessionNotesActive.find(
        note => note.id === props.sessionId
      );
      if (dto) {
        setNote(dto);
      }
    }
  }, [activeData, props.sessionId]);

  const handleSignatureSave = (
    signature: string,
    isImage: boolean,
    isBlank: boolean
  ) => {
    if (isBlank) {
      NotificationsHelper.StandardErrorNotification({
        title: "Signature Required",
        description: "Signature must be filled in"
      });
      return;
    }

    setLoading(true);
    sessionNoteSign(props.sessionId, props.studentId, signature)
      .then(() =>
        props.onClose(
          "Note Signed!",
          "This note has been successfully submitted for billing."
        )
      )
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Save Note!"
        });
      })
      .then(() => setLoading(false));
  };

  const handleEdit = () => {
    if (note?.signature) {
      Modal.warning({
        okCancel: true,
        title: "Are you sure you want to retract and edit this session note?",
        content:
          "Any signature on this note will be deleted.  This cannot be undone.",
        onOk: () => handleRevert(),
        okText: "Retract Note",
        cancelText: "Don't Retract"
      });
    } else {
      props.onEdit(props.sessionId);
    }
  };

  const handleRevert = () => {
    setLoading(true);
    sessionNoteRevert(props.sessionId, props.studentId)
      .then(() => props.onEdit(props.sessionId))
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to Retract Note!"
        })
      )
      .then(() => setLoading(false));
  };

  const canEdit =
    note?.createdById === userId ||
    findPermission(role, Permissions.EDIT_ALL_NOTES);
  const therapistName =
    note?.createdById !== userId ? note?.createdByName : undefined;
  const student = assignedStudents.find(
    student => student!.studentId === props.studentId
  )?.student;
  const studentName = student?.firstName + " " + student?.lastName;

  return (
    <>
      <Grid color="default">
        <LoadingScreen loading={loading} />
        {note && (
          <>
            <Row margin="4px 16px 16px 16px">
              <Col>
                <Heading level={3}>
                  {note?.signature
                    ? `Viewing ${studentName}'s Session Note`
                    : "Collect Guardian Signature"}
                </Heading>
                <Heading level={6}>
                  {moment(note.start).format("ddd, MMM. D")},
                  {moment(note.start).format(" h:mm A")} -{" "}
                  {note.end ? moment(note.end).format("h:mm A") : "?"}
                  {therapistName ? " by " + therapistName : ""}
                </Heading>
              </Col>
            </Row>
            <Row padding="0 0 32px 0">
              <Illustration src={note?.signature ? filesImage : signImage} />
            </Row>
            <Row>
              <Card
                selectable={false}
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Guardian Signature
                  </Heading>
                }
              >
                {note.signature ? (
                  <>
                    <Image
                      src={`data:image/png;base64,${note.signature}`}
                      height="90"
                    />
                    <Heading level={6}>
                      Signed{" "}
                      {moment(note.modifiedAt).format(
                        "ddd, MMM. D, YYYY \\at h:mm A"
                      )}
                    </Heading>
                  </>
                ) : canEdit ? (
                  <Signature onSave={handleSignatureSave} />
                ) : (
                  "N/A"
                )}
              </Card>
              {canEdit && (
                <Button type="danger" onClick={handleEdit} block={true}>
                  {note.signature
                    ? "Retract and Edit Session Note"
                    : "Edit Session Note"}
                </Button>
              )}
              <Card
                padding="0 16px 16px 16px"
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Service Date &amp; Time
                  </Heading>
                }
              >
                <Row>
                  <Col sm={11} xs={24} padding="16px 0 0 0">
                    <Heading level={6}>Date</Heading>
                    <Heading level={5}>
                      {moment(note.start).format("ddd, MMM. D, YYYY")}
                    </Heading>
                  </Col>
                  <Col sm={7} xs={24} padding="16px 0 0 0">
                    <Heading level={6}>Start Time</Heading>
                    <Heading level={5}>
                      {moment(note.start).format("h:mm A")}
                    </Heading>
                  </Col>
                  <Col sm={6} xs={24} padding="16px 0 0 0">
                    <Heading level={6}>End Time</Heading>
                    <Heading level={5}>
                      {note.end ? moment(note.end).format("h:mm A") : ""}
                    </Heading>
                  </Col>
                </Row>
              </Card>
              <Card
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Service Provided
                  </Heading>
                }
              >
                {note.serviceType}
              </Card>
              <Card
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Place of Service
                  </Heading>
                }
              >
                {LabelHelper.placeOfServiceLabel(note.placeOfService)}
              </Card>
              <Card
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Skill Acquisition Description
                  </Heading>
                }
              >
                {renderNote(note.skillDetails)}
              </Card>
              <Card
                color="default"
                variation={1}
                title={
                  <Heading level={5} weight="medium">
                    Behavior Description
                  </Heading>
                }
              >
                {renderNote(note.behaviorDetails)}
              </Card>
            </Row>
          </>
        )}
      </Grid>
    </>
  );
};
