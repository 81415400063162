import { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Grid,
  Heading,
  PointShape,
  Select,
  SelectOption,
  Text,
  TimePicker,
  Row
} from "@raudabaugh/thread-ui";
import moment from "moment";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import {
  DataPointInput,
  DataPointTypeEnum,
  ProgramTypeEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { LabelHelper } from "Shared/LabelHelper";
import { ChartHelper } from "./ChartHelper";

interface IPointEditPopupProps {
  headingText: string;
  subHeadingText: string;
  createdByUserLabel: string;
  program: ProgramFragment;
  point: DataPointFragment;
  studentId: string;
  pointPhase: PhaseFragment;
  phases: PhaseFragment[];
  onUpdateChartPoint: (
    pointId: string,
    studentId: string,
    programId: string,
    input: DataPointInput,
    originalPhaseId: string,
    programType: ProgramTypeEnum
  ) => void;
  onClose: () => void;
}

export const PointEditPopup = (props: IPointEditPopupProps) => {
  const { threadUserContext } = useThreadContext();

  const hasFullEditPermission = findPermission(
    threadUserContext.role,
    Permissions.EDIT_DATA_POINTS_DATE_AND_PHASE
  );

  const [values, setValues] = useState<DataPointInput>({
    completedAt: props.point.completedAt,
    pointType: props.point.pointType || DataPointTypeEnum.STANDARD,
    phaseId: props.pointPhase.id ?? ""
  });

  const initialPhaseId = props.pointPhase.id;

  const handleSave = () => {
    props.onUpdateChartPoint(
      props.point.id,
      props.studentId,
      props.program.id,
      values,
      initialPhaseId,
      props.program.type
    );
    props.onClose();
  };

  const pointTypes = [
    DataPointTypeEnum.STANDARD,
    DataPointTypeEnum.PRETEST,
    DataPointTypeEnum.PROBE,
    DataPointTypeEnum.POSTTEST
  ];

  const changeFieldValue = (field: string, value: any) => {
    const newValues = Object.assign({}, values, {
      [field]: value
    });
    setValues(newValues);
  };

  const handleChangeTime = (date: moment.Moment, dateString: string) => {
    if (date) {
      const newDate = moment(values.completedAt)
        .clone()
        .set({
          hour: date.get("hour"),
          minute: date.get("minute"),
          second: date.get("second")
        });
      changeFieldValue("completedAt", newDate.toISOString());
    }
  };

  const handleChangeDate = (date: moment.Moment, dateString: string) => {
    if (date) {
      const newDate = moment(values.completedAt)
        .clone()
        .set({
          year: date.get("year"),
          month: date.get("month"),
          date: date.get("date")
        });
      changeFieldValue("completedAt", newDate.toISOString());
    }
  };

  return (
    <Grid type="flex" width="100%">
      <Row type="flex">
        <Col>
          <Heading level={4} variation={1}>
            <Text strong={true}>{props.headingText}</Text>
          </Heading>
        </Col>
      </Row>
      {props.subHeadingText && (
        <Row>
          <Col>
            <Heading level={5} variation={2} color="default" weight="regular">
              {props.subHeadingText}
            </Heading>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
        </Col>
      </Row>

      {props.createdByUserLabel.length > 0 && (
        <Row>
          <Col>
            <Heading level={5} variation={2} color="default" weight="regular">
              Data taken {props.createdByUserLabel}
            </Heading>
          </Col>
        </Row>
      )}

      <Row
        type="flex"
        justify="start"
        alignItems="flex-end"
        gutter={10}
        margin="16px 0px 0px 0px"
      >
        <Col type="flex">
          <Heading level={5}>Date:</Heading>
        </Col>
        <Col type="flex">
          <DatePicker
            placeholder=""
            disabled={!hasFullEditPermission}
            value={moment(values.completedAt)}
            onChange={handleChangeDate}
          />
        </Col>
      </Row>

      <Row
        type="flex"
        justify="start"
        alignItems="flex-end"
        gutter={10}
        margin="16px 0px 0px 0px"
      >
        <Col type="flex">
          <Heading level={5}>Time:</Heading>
        </Col>
        <Col type="flex">
          <TimePicker
            placeholder=""
            disabled={!hasFullEditPermission}
            use12Hours={true}
            value={moment(values.completedAt)}
            onChange={handleChangeTime}
          />
        </Col>
      </Row>

      <Row
        type="flex"
        justify="start"
        alignItems="flex-end"
        gutter={10}
        margin="16px 0px 0px 0px"
      >
        <Col type="flex">
          <Heading level={5}>Data type:</Heading>
        </Col>
        <Col type="flex">
          <Select
            dropdownMatchSelectWidth={false}
            defaultValue={values.pointType ?? ""}
            value={values.pointType ?? ""}
            onChange={(value: any) => {
              changeFieldValue("pointType", value);
            }}
            size="large"
          >
            {pointTypes.map(p => (
              <SelectOption value={p} key={p}>
                <Row type="flex" alignItems="center" gutter={5} justify="start">
                  <Col type="flex">
                    <PointShape type={p.toLocaleLowerCase()} />
                  </Col>
                  <Col type="flex">
                    <Text>{LabelHelper.dataPointTypeLabel(p)}</Text>
                  </Col>
                </Row>
              </SelectOption>
            ))}
          </Select>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="start"
        alignItems="flex-end"
        gutter={10}
        margin="16px 0px 0px 0px"
      >
        <Col type="flex">
          <Heading level={5}>Phase:</Heading>
        </Col>
        <Col type="flex">
          <Select
            dropdownMatchSelectWidth={false}
            disabled={!hasFullEditPermission}
            defaultValue={values.phaseId ?? ""}
            value={values.phaseId ?? ""}
            onChange={(value: any) => {
              changeFieldValue("phaseId", value);
            }}
          >
            {props.phases.map(p => {
              const phaseTargets = p.targetIds.map(id =>
                props.program.targets.find(t => t.id === id)
              );
              return (
                <SelectOption value={p.id} key={p.id}>
                  <Text>{ChartHelper.getPhaseName(p, phaseTargets)}</Text>
                </SelectOption>
              );
            })}
          </Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <Divider color="#F0F0F0" margin="16px 0px 16px 0px" />
        </Col>
      </Row>

      <Row padding="16px 0" type="flex" justify="end" gutter={16}>
        <Col span={8}>
          {
            <Button
              size="large"
              block={true}
              type="default"
              onClick={props.onClose}
            >
              Cancel
            </Button>
          }
        </Col>
        <Col span={8}>
          <Button size="large" block={true} type="primary" onClick={handleSave}>
            Save
          </Button>
        </Col>
      </Row>
    </Grid>
  );
};
