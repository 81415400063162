import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { ArchiveView } from "StudentPrograms/Archive/ArchiveView";

const ViewArchive = () => {
  const { studentId, setStudentId } = useStudentContext();

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return <ArchiveView currentStudentId={studentId} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.ARCHIVE,
          state: {
            sideMenu: RoutePathEnum.ARCHIVE,
            current: RoutePathEnum.ARCHIVE
          }
        }}
      />
    );
  }
};

interface ArchiveRouterProps {}

export const ArchiveRouter = (props: ArchiveRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.ARCHIVE_LIST} exact={true}>
        <ViewArchive />
      </Route>
      <Route path={RoutePathEnum.ARCHIVE}>
        <DefaultStudent path={RoutePathEnum.ARCHIVE_LIST} />
      </Route>
    </Switch>
  );
};
