import { StaffRoleEnum, Permissions } from "./Api/globalTypes";

export const rolePermissionsMap = new Map([
  [
    StaffRoleEnum.ADMIN,
    new Set([
      Permissions.VIEW_PHONE_NUMBER,
      Permissions.VIEW_STUDENT_PROFILE,
      Permissions.VIEW_STAFF_PROFILE,
      Permissions.VIEW_ALL_STUDENTS,
      Permissions.VIEW_ALL_STAFF,
      Permissions.EDIT_ROLES,
      Permissions.EDIT_ASSIGNED_STUDENTS,
      Permissions.EDIT_ASSIGNED_INSTRUCTORS,
      Permissions.EDIT_STUDENT_BASIC_INFO,
      Permissions.EDIT_STAFF_BASIC_INFO,
      Permissions.REMOVE_FROM_ORGANIZATION,
      Permissions.ADD_NEW_PEOPLE,
      Permissions.EDIT_PROGRAMS,
      Permissions.DOWNLOAD_REPORTS,
      Permissions.DOWNLOAD_ORG_REPORTS,
      Permissions.EDIT_ALL_NOTES,
      Permissions.VIEW_NOTIFICATIONS,
      Permissions.EDIT_NOTIFICATIONS,
      Permissions.UNLOCK_PROGRAMS,
      Permissions.EDIT_ORG_PROFILE,
      Permissions.VIEW_ORG_PROFILE,
      Permissions.VIEW_ORG_SETTINGS,
      Permissions.EDIT_CURRICULUM_TEMPLATES,
      Permissions.ADD_CURRICULUM_TEMPLATES,
      Permissions.EDIT_CURRICULUM_FOLDERS,
      Permissions.UPLOAD_CURRICULUM,
      Permissions.DOWNLOAD_CURRICULUM,
      Permissions.VIEW_ARCHIVE,
      Permissions.ADD_TO_ARCHIVE,
      Permissions.RESTORE_FROM_ARCHIVE,
      Permissions.SOFT_DELETE_FROM_ARCHIVE,
      Permissions.VIEW_CLINICAL_FILES,
      Permissions.ADD_CLINICAL_FILES,
      Permissions.EDIT_CLINICAL_FILES,
      Permissions.SOFT_DELETE_CLINICAL_FILES,
      Permissions.VIEW_ABC_DATA,
      Permissions.ADD_ABC_DATA,
      Permissions.EDIT_ABC_DATA,
      Permissions.SOFT_DELETE_ABC_DATA,
      Permissions.EDIT_DATA_POINTS_DATE_AND_PHASE,
      Permissions.SOFT_DELETE_DATA_POINT,
      Permissions.RESTORE_DATA_POINT,
      Permissions.VIEW_MAINTENANCE,
      Permissions.COMPLETE_PROGRAM_FROM_MAINTENANCE,
      Permissions.VIEW_TRIALS,
      Permissions.ADD_TRIALS,
      Permissions.EDIT_TRIALS,
      Permissions.DELETE_TRIALS
    ])
  ],
  [
    StaffRoleEnum.SUPERVISOR,
    new Set([
      Permissions.VIEW_STUDENT_PROFILE,
      Permissions.VIEW_ASSIGNED_STUDENTS,
      Permissions.VIEW_STAFF_PROFILE,
      Permissions.VIEW_ALL_STAFF,
      Permissions.EDIT_ASSIGNED_INSTRUCTORS,
      Permissions.EDIT_PROGRAMS,
      Permissions.DOWNLOAD_REPORTS,
      Permissions.EDIT_ALL_NOTES,
      Permissions.VIEW_NOTIFICATIONS,
      Permissions.EDIT_NOTIFICATIONS,
      Permissions.UNLOCK_PROGRAMS,
      Permissions.VIEW_ARCHIVE,
      Permissions.ADD_TO_ARCHIVE,
      Permissions.RESTORE_FROM_ARCHIVE,
      Permissions.SOFT_DELETE_FROM_ARCHIVE,
      Permissions.VIEW_CLINICAL_FILES,
      Permissions.ADD_CLINICAL_FILES,
      Permissions.EDIT_CLINICAL_FILES,
      Permissions.SOFT_DELETE_CLINICAL_FILES,
      Permissions.VIEW_ABC_DATA,
      Permissions.ADD_ABC_DATA,
      Permissions.EDIT_ABC_DATA,
      Permissions.SOFT_DELETE_ABC_DATA,
      Permissions.SOFT_DELETE_DATA_POINT,
      Permissions.EDIT_DATA_POINTS_DATE_AND_PHASE,
      Permissions.RESTORE_DATA_POINT,
      Permissions.VIEW_MAINTENANCE,
      Permissions.COMPLETE_PROGRAM_FROM_MAINTENANCE,
      Permissions.VIEW_TRIALS,
      Permissions.ADD_TRIALS,
      Permissions.EDIT_TRIALS,
      Permissions.DELETE_TRIALS
    ])
  ],
  [
    StaffRoleEnum.INSTRUCTOR,
    new Set([
      Permissions.EDIT_PROGRAMS,
      Permissions.VIEW_NOTIFICATIONS,
      Permissions.VIEW_ARCHIVE,
      Permissions.ADD_TO_ARCHIVE,
      Permissions.RESTORE_FROM_ARCHIVE,
      Permissions.VIEW_CLINICAL_FILES,
      Permissions.VIEW_ABC_DATA,
      Permissions.ADD_ABC_DATA,
      Permissions.VIEW_MAINTENANCE,
      Permissions.COMPLETE_PROGRAM_FROM_MAINTENANCE,
      Permissions.VIEW_TRIALS,
      Permissions.DELETE_TRIALS
    ])
  ]
]);

export function findPermission(role: StaffRoleEnum, permission: Permissions) {
  return rolePermissionsMap.get(role)?.has(permission) ?? false;
}
