import { Col, Overlay, Row, Spin } from "@raudabaugh/thread-ui";
import React from "react";

export interface ILoadingScreenProps {
  delay?: number;
  indicator?: any;
  size?: string;
  spinning?: boolean;
  tip?: string;
  loading?: boolean;
}

export const LoadingScreen = (props: ILoadingScreenProps) => {
  return props.loading || props.loading === undefined ? (
    <Overlay
      zIndex={100}
      color={"default"}
      variation={3}
      opacity={0.9}
      height={"100%"}
      width={"100%"}
    >
      <Row align={"middle"} justify={"center"} type={"flex"}>
        <Col padding={"100px 0 0 0"}>
          <Spin tip={props.tip ? props.tip : "Loading..."} size={"large"} />
        </Col>
      </Row>
    </Overlay>
  ) : null;
};
