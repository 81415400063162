import {
  Heading,
  Icon,
  Modal,
  Tag,
  Row
} from "@raudabaugh/thread-ui";
import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  useDataPointRecordDiscreteTrialMutation,
  useDataPointRestartDiscreteTrialMutation,
  useDataPointScoreDiscreteTrialMutation,
  useDataPointSetPromptLevelMutation,
  useDataPointUndoDiscreteTrialMutation,
  useDataPointEndDiscreteTrialMutation
} from "../DataAccess/DiscreteTrialData";
import AdvancedTrialCard from "./AdvancedTrialCard";
import { DiscreteTrialFocus } from "./Focus/DiscreteTrialFocus";
import { IProgramItem } from "../StudentPrograms/Types";
import { IPrompt, PromptHelper } from "../Shared/PromptHelper";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  ProgramTypeEnum,
  DataPointStateEnum,
  TrialResultEnum,
  DrawerEnum
} from "Shared/Api/globalTypes";
import {
  useProgramDeactivateMutation,
  useProgramMoveMutation
} from "DataAccess/ProgramData";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IDiscreteTrialCardBaseProps {
  size: "sm" | "lg";
  currentStudentId: string;
  title: string;
  runId: string;
  programId: string;
  index: number;
  errorless: boolean;
  prompt: string;
  programType: string;
  totalTrials?: number;
  totalAttempted: number;
  totalCorrect: number;
  targets: string[];
  completed: boolean;
  setLoading: (loading: boolean) => void;
  showFocusDrawer?: () => void;
  loading: boolean;
  item: IProgramItem;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onPopup: (programId: string) => void;
}

const DiscreteTrialCard = ({
  onAbc,
  onChart,
  onPopup,
  ...props
}: IDiscreteTrialCardBaseProps) => {
  const { threadUserContext } = useThreadContext();
  const { dataPointSetPromptLevel } = useDataPointSetPromptLevelMutation();
  const { programDeactivate } = useProgramDeactivateMutation();
  const { programMove } = useProgramMoveMutation();
  const { dataPointRecordDiscreteTrial } =
    useDataPointRecordDiscreteTrialMutation();
  const { dataPointRestartDiscreteTrial } =
    useDataPointRestartDiscreteTrialMutation();
  const { dataPointScoreDiscreteTrial } =
    useDataPointScoreDiscreteTrialMutation();
  const { dataPointUndoDiscreteTrial } =
    useDataPointUndoDiscreteTrialMutation();
  const { dataPointEndDiscreteTrial } = useDataPointEndDiscreteTrialMutation();

  const [enterScoreOpen, setEnterScoreOpen] = useState(false);
  const [scoreWarningOpen, setScoreWarningOpen] = useState(false);
  const [closeWarningOpen, setCloseWarningOpen] = useState(false);
  const [endWarningOpen, setEndWarningOpen] = useState(false);
  const [focusModeOpen, setFocusModeOpen] = useState(false);

  const promptMap = useMemo(
    () => PromptHelper.prompts(threadUserContext),
    [threadUserContext]
  );
  const prompts = useMemo(
    () => Object.keys(promptMap).map(k => promptMap[k]),
    [promptMap]
  );
  const targets = useMemo(() => {
    return (
      <>
        {props.targets.map(target => (
          <Tag key={target} color="primary" maxWidth="10em">
            {target}
          </Tag>
        ))}
      </>
    );
  }, [props.targets]);
  const variation = props.completed ? 6 : 1;
  const color = props.completed ? "primary" : "default";
  const leftLabel = useMemo(
    () => (
      <Icon
        size="1.1em"
        color={color}
        variation={variation}
        type="fa-minus fas"
      />
    ),
    [color, variation]
  );
  const centerLabel = useMemo(
    () =>
      props.errorless ? (
        <Heading level={7} color={color} variation={variation}>
          {PromptHelper.generateLabel(props.prompt ?? "")}
        </Heading>
      ) : undefined,
    [props.errorless, color, variation, props.prompt]
  );
  const rightLabel = useMemo(
    () => (
      <Icon
        size="1.1em"
        color={color}
        variation={variation}
        type="fa-plus fas"
      />
    ),
    [color, variation]
  );
  const caption = useMemo(
    () => (
      <>
        {props.size === "lg" ? (
          <Row alignItems="center" direction="column" type="flex">
            <Heading color="default" weight="medium" level={4}>
              {props.totalCorrect}/{props.totalAttempted}
            </Heading>
            <Heading color="default" level={5}>
              {props.totalTrials ? props.totalTrials : "∞"} trials
            </Heading>
          </Row>
        ) : (
          <Heading
            margin="0 -15px -18px -15px"
            color="default"
            weight="medium"
            level={7}
          >
            {props.totalCorrect}/{props.totalAttempted} of{" "}
            {props.totalTrials ? props.totalTrials : "∞"}
          </Heading>
        )}
      </>
    ),
    [props.totalCorrect, props.totalAttempted, props.totalTrials, props.size]
  );

  const renderTrialCard = (prompts: IPrompt[]) => {
    return (
      <AdvancedTrialCard
        size={props.size}
        locked={props.item.locked}
        errorless={props.errorless}
        onChangePromptType={changePromptType}
        promptLevels={prompts}
        prompt={props.prompt ?? PromptHelper.PROMPT_NONE}
        onShowFocusDrawer={props.item.locked ? undefined : handleFocusModeOpen}
        programType={props.programType}
        index={props.index}
        targets={targets}
        leftLabel={leftLabel}
        centerLabel={centerLabel}
        rightLabel={rightLabel}
        title={props.title}
        disabled={props.completed}
        studentId={props.currentStudentId}
        onLeftButtonClick={handleLeftButtonClick}
        onCenterButtonClick={handleCenterButtonClick}
        onRightButtonClick={handleRightButtonClick}
        onAbcClick={handleAbcClick}
        onChartClick={handleChartClick}
        onUndoClick={props.totalAttempted !== 0 ? handleUndoClick : undefined}
        onCloseClick={handleCloseClick}
        onEndClick={
          !props.completed && props.totalAttempted !== 0
            ? handleEndClick
            : undefined
        }
        onEnterScoreClick={!props.completed ? handleEnterScoreClick : undefined}
        onMoveToTopClick={handleMoveToTopClick}
        onRunAgainClick={props.completed ? handleRunAgainClick : undefined}
        onPopupClick={handlePopup}
        caption={caption}
      />
    );
  };

  const handlePopup = useCallback(() => {
    onPopup(props.programId);
  }, [onPopup, props.programId]);
  const handleScoreWarningContinue = useCallback(() => {
    setEnterScoreOpen(true);
    setScoreWarningOpen(false);
  }, []);
  const handleScoreWarningCancel = useCallback(() => {
    setScoreWarningOpen(false);
  }, []);
  const handleEndWarningContinue = useCallback(() => {
    setEndWarningOpen(false);
    setFocusModeOpen(false);
    dataPointEndDiscreteTrial(props.runId, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  }, [dataPointEndDiscreteTrial, props.runId, props.currentStudentId]);
  const handleEndWarningCancel = useCallback(() => {
    setEndWarningOpen(false);
    setFocusModeOpen(false);
  }, []);
  const closeProgram = useCallback(
    async (confirmed: boolean) => {
      await programDeactivate(
        props.programId,
        props.currentStudentId,
        confirmed
      ).catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    },
    [programDeactivate, props.programId, props.currentStudentId]
  );
  const handleCloseWarningContinue = useCallback(() => {
    setCloseWarningOpen(false);
    closeProgram(true);
  }, [closeProgram]);
  const handleCloseWarningCancel = useCallback(() => {
    setCloseWarningOpen(false);
  }, []);
  const handleLeftButtonClick = useCallback(async () => {
    try {
      // handle negative result
      await dataPointRecordDiscreteTrial(
        props.runId,
        props.currentStudentId,
        TrialResultEnum.MINUS,
        PromptHelper.PROMPT_INDEPENDENT
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
  }, [dataPointRecordDiscreteTrial, props.runId, props.currentStudentId]);

  const handleCenterButtonClick = useCallback(
    async (prompt?: string) => {
      // handle full prompt result
      try {
        await dataPointRecordDiscreteTrial(
          props.runId,
          props.currentStudentId,
          TrialResultEnum.MINUS,
          prompt ?? PromptHelper.PROMPT_INDEPENDENT,
          threadUserContext.userId
        );
      } catch (e) {
        const error = e as Error;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    },
    [
      dataPointRecordDiscreteTrial,
      props.runId,
      props.currentStudentId,
      threadUserContext.userId
    ]
  );
  const changePromptType = useCallback(
    (prompt: string) => {
      dataPointSetPromptLevel(
        props.runId,
        props.currentStudentId,
        prompt
      ).catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    },
    [dataPointSetPromptLevel, props.runId, props.currentStudentId]
  );
  const handleRightButtonClick = useCallback(async () => {
    // handle positive result
    if (props.errorless) {
      changePromptType(PromptHelper.PROMPT_INDEPENDENT);
    }
    try {
      await dataPointRecordDiscreteTrial(
        props.runId,
        props.currentStudentId,
        TrialResultEnum.PLUS,
        PromptHelper.PROMPT_INDEPENDENT,
        threadUserContext.userId
      );
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
  }, [
    dataPointRecordDiscreteTrial,
    changePromptType,
    props.errorless,
    props.runId,
    props.currentStudentId,
    threadUserContext.userId
  ]);
  const handleEnterScoreClose = useCallback(() => {
    setEnterScoreOpen(false);
  }, []);
  const handleEnterScoreClick = useCallback(() => {
    if (props.totalAttempted === 0) {
      setEnterScoreOpen(true);
    } else {
      setScoreWarningOpen(true);
    }
  }, [props.totalAttempted]);
  const handleRunAgainClick = useCallback(() => {
    dataPointRestartDiscreteTrial(
      props.runId,
      props.currentStudentId,
      props.programId
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  }, [
    dataPointRestartDiscreteTrial,
    props.runId,
    props.currentStudentId,
    props.programId
  ]);
  const handleAbcClick = useCallback(() => {
    onAbc(props.programId);
  }, [onAbc, props.programId]);
  const handleChartClick = useCallback(() => {
    onChart(props.programId, ProgramTypeEnum.DTT);
  }, [onChart, props.programId]);
  const handleUndoClick = useCallback(() => {
    dataPointUndoDiscreteTrial(props.runId, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  }, [dataPointUndoDiscreteTrial, props.runId, props.currentStudentId]);
  const handleMoveToTopClick = useCallback(() => {
    programMove(props.programId, props.currentStudentId, 0).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      }
    );
  }, [programMove, props.programId, props.currentStudentId]);

  const handleCloseClick = useCallback(() => {
    if(props.item.drawer === DrawerEnum.PRIVATE_DRAWER){
      const hasInfiniteTrials = !props.totalTrials;
      const hasNoTrials = props.totalAttempted === 0 || props.totalAttempted === props.totalTrials;
      const isCompleted = props.completed;

      if(hasInfiniteTrials || hasNoTrials || isCompleted) {
        closeProgram(false);
      } else {
        setCloseWarningOpen(true);
      }
    } else {
      closeProgram(false);
    }
  }, [closeProgram, props.item.drawer, props.totalTrials, props.totalAttempted, props.completed]);

  const handleEndClick = useCallback(() => {
    props.totalTrials ? setEndWarningOpen(true) : handleEndWarningContinue();
  }, [props.totalTrials, handleEndWarningContinue]);
  const handleSaveScore = useCallback(
    (
      score: number,
      date: moment.Moment,
      attempts?: number,
      phaseId?: string
    ) => {
      dataPointScoreDiscreteTrial(
        props.runId,
        phaseId ?? props.item.phaseId,
        props.currentStudentId,
        score,
        attempts!,
        date.utc().toISOString()
      ).catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    },
    [
      dataPointScoreDiscreteTrial,
      props.runId,
      props.item.phaseId,
      props.currentStudentId
    ]
  );
  const handleFocusModeClose = useCallback(
    (dataCollected: boolean) => {
      const isInfinite = props.item.totalCount ? false : true;
      if (
        isInfinite &&
        focusModeOpen &&
        dataCollected &&
        props.item.applicableCount > 0 &&
        !(props.item.state === DataPointStateEnum.COMPLETED)
      ) {
        setEndWarningOpen(true);
      } else {
        setFocusModeOpen(false);
      }
    },
    [
      focusModeOpen,
      props.item.totalCount,
      props.item.applicableCount,
      props.item.state
    ]
  );

  const handleFocusModeOpen = useCallback(() => {
    setFocusModeOpen(true);
  }, []);

  return (
    <>
      {renderTrialCard(prompts)}
      <DiscreteTrialFocus
        promptLevels={prompts}
        open={focusModeOpen}
        loading={props.loading}
        item={props.item}
        index={props.index}
        setLoading={props.setLoading}
        currentStudentId={props.currentStudentId}
        completed={props.item.state === DataPointStateEnum.COMPLETED}
        onClose={handleFocusModeClose}
      />
      <EnterScoreDialog
        scoreLabel="Correct"
        attemptsLabel="Attempted"
        defaultAttempts={props.totalTrials}
        onClose={handleEnterScoreClose}
        open={enterScoreOpen}
        onSaveScore={handleSaveScore}
        studentId={props.currentStudentId}
        programId={props.programId}
      />
      <Modal
        title={`${
          focusModeOpen ? "Do" : "Are you sure"
        } you want to end this program?`}
        afterClose={handleEndWarningCancel}
        visible={endWarningOpen}
        onOk={handleEndWarningContinue}
        onCancel={handleEndWarningCancel}
        cancelText={`${focusModeOpen ? "No" : "Cancel"}`}
        okText={`${focusModeOpen ? "Yes" : "End"}`}
      >
        {focusModeOpen ? (
          <>You've recorded data for {props.totalAttempted} trials.</>
        ) : (
          <>
            You've only recorded data for {props.totalAttempted} trials out of{" "}
            {props.totalTrials} trials.
          </>
        )}
      </Modal>
      <Modal
        title="Are you sure you want to enter a score?"
        afterClose={handleScoreWarningCancel}
        visible={scoreWarningOpen}
        onOk={handleScoreWarningContinue}
        onCancel={handleScoreWarningCancel}
        okText="Enter Score"
      >
        You have unsaved trials that will be overridden immediately. You can't
        undo this action.
      </Modal>
      <Modal
        title="Are you sure you want to close this program?"
        afterClose={handleCloseWarningCancel}
        visible={closeWarningOpen}
        onOk={handleCloseWarningContinue}
        onCancel={handleCloseWarningCancel}
        okText="Close Program"
      >
        Your data will be deleted immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export default memo(DiscreteTrialCard);
