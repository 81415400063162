import React from "react";
import {
  Modal,
  Row,
  Grid,
  List,
  ListItem,
  InputSearch,
  Heading,
  Divider,
  Checkbox,
  Spin
} from "@raudabaugh/thread-ui";
import { StaffFragment } from "../Shared/Api/StaffFragment";
import { IStaffSearch_staffSearch_edges_node } from "Shared/Api/IStaffSearch";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface IEditStaffAssignmentsProps {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  onSearch: (value: string) => void;
  shownPeople: IStaffSearch_staffSearch_edges_node[];
  totalPeople: number;
  assignments: IStaffSearch_staffSearch_edges_node[];
  peopleToSave: IStaffSearch_staffSearch_edges_node[];
  peopleToRemove: IStaffSearch_staffSearch_edges_node[];
  hasMore: boolean;
  onLoadMore: () => void;
  onAssignmentChanged: (assignment: StaffFragment, checked: boolean) => void;
}

export const EditStaffAssignments = (props: IEditStaffAssignmentsProps) => {
  const findAssignments = (item: StaffFragment) => {
    return (
      (props.assignments.some(u => u.id === item.id) ||
        props.peopleToSave.some(u => u.id === item.id)) &&
      !props.peopleToRemove.some(u => u.id === item.id)
    );
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: props.hasMore,
    onLoadMore: props.onLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px"
  });

  return (
    <Modal
      title={`Select Instructors to Assign`}
      visible={props.visible}
      okText="Save Assignment"
      onCancel={props.onClose}
      onOk={props.onSave}
      centered
    >
      <Grid type="flex">
        <Row
          type="flex"
          justify="center"
          color="default"
          variation={2}
          padding="16px 16px 0 16px"
          margin="-16px -16px 0px -16px"
        >
          <InputSearch
            onPressEnter={event => props.onSearch(event.target.value)}
            onSearch={searchToken => props.onSearch(searchToken)}
            placeholder="Search"
          />
          <Heading
            color="default"
            variation={8}
            level={6}
            margin="12px 0 16px 0"
          >
            Showing {(props.shownPeople || []).length} of {props.totalPeople}{" "}
            instructors,{" "}
            {props.assignments
              ? props.assignments!.length
              : 0 + props.peopleToSave.length}{" "}
            selected
          </Heading>
        </Row>
        <Row type="flex" justify="center" margin="0 -16px 0 -16px">
          <Divider margin="0"></Divider>
        </Row>
        <Row>
          <List
            itemLayout="horizontal"
            dataSource={props.shownPeople}
            hasMore={props.hasMore}
            renderItem={(item: StaffFragment) => {
              return (
                <>
                  <ListItem>
                    <Row
                      grow={100}
                      type="flex"
                      onClick={event =>
                        props.onAssignmentChanged(item, !findAssignments(item))
                      }
                    >
                      <Checkbox
                        id={`${item.firstName}-${item.lastName}Edit`}
                        checked={findAssignments(item)}
                      />
                      <Heading
                        id={`${item.firstName}-${item.lastName}`}
                        level={5}
                        margin="0px 0px 0px 16px"
                      >
                        {item.fullName}
                      </Heading>
                    </Row>
                  </ListItem>
                </>
              );
            }}
          />
          {props.shownPeople.length > 0 && props.hasMore && (
            <Row
              innerRef={(sentryRef as unknown) as React.RefObject<any>}
              justify="center"
              type="flex"
            >
              <Spin />
            </Row>
          )}
        </Row>
      </Grid>
    </Modal>
  );
};
