import React, { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  Col,
  Drawer,
  Grid,
  Row,
  Overlay,
  Media
} from "@raudabaugh/thread-ui";
import { XamarinHelper } from "./XamarinHelper";
import { ThreadRouter } from "../Routes/ThreadRouter";
import { NavigationBar } from "./NavigationBar";
import { SideMenu } from "./SideMenu";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import { IRouteState } from "Routes/IRouteState";
import { useStudentContext } from "Routes/StudentContextHook";

interface AppLayoutProps {
  containerRef: React.Ref<Row>;
  messageCount: number;
  online: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  containerRef,
  messageCount,
  online
}) => {
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { studentId } = useStudentContext();

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(menuOpen => !menuOpen);
  }, [setMenuOpen]);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const handleMenuSelect = useCallback(
    (option: RoutePathEnum | string) => {
      if (option.startsWith("http:") || option.startsWith("https:")) {
        window.location.href = option;
      } else {
        const route = option as RoutePathEnum;
        const state: IRouteState = {
          sideMenu: route,
          current: route,
          backLabel: ""
        };
        history.push(option.replace(":studentId", studentId ?? ""), state);
      }
    },
    [history, studentId]
  );

  const offlineEnabled =
    process.env.REACT_APP_OFFLINE_ENABLE === "true" ||
    XamarinHelper.insideXamarin();

  return (
    <Grid type="flex" width="100%">
      <Row margin="0" type="flex" grow={1}>
        <Media.Md orSmaller={true}>
          <Drawer
            lg={true}
            xl={true}
            xxl={true}
            mask={false}
            margin="0"
            padding="0"
            height="auto"
            placement="left"
            visible={menuOpen}
            closable={false}
            destroyOnClose
          >
            <SideMenu
              path={location.pathname}
              onCloseMenu={handleCloseMenu}
              onSelect={handleMenuSelect}
              messageCount={messageCount}
            />
            {menuOpen && (
              <Col onClick={handleCloseMenu}>
                <Overlay
                  zIndex={-1}
                  color="default"
                  variation={7}
                  opacity={0.5}
                  width="200vw"
                />
              </Col>
            )}
          </Drawer>
        </Media.Md>
        <Media.Lg orLarger={true}>
          <Col xs={0} sm={0} md={0} lg={5} xl={4} xxl={4} zIndex={99}>
            <Grid
              type="flex"
              height="100vh"
              overflowY="auto"
              color="navbar"
              variation={9}
            >
              <SideMenu
                path={location.pathname}
                onCloseMenu={handleCloseMenu}
                onSelect={handleMenuSelect}
                messageCount={messageCount}
              />
            </Grid>
          </Col>
        </Media.Lg>
        <Col xs={24} sm={24} md={24} lg={19} xl={20} xxl={20}>
          <Grid type="flex" height="100vh">
            <Row justify="end" type="flex">
              <Col xs={24}>
                <NavigationBar
                  onToggleMenu={handleToggleMenu}
                  messageCount={messageCount}
                />
              </Col>
            </Row>
            <Row
              type="flex"
              grow={1}
              scroll="vertical"
              ref={containerRef}
              alignItems="stretch"
            >
              <Col xs={24}>
                {!online && !offlineEnabled && (
                  <>
                    <Overlay
                      zIndex={10101}
                      color={"default"}
                      variation={3}
                      opacity={1}
                      height={"130px"}
                      width={"100%"}
                    >
                      <Alert
                        showIcon={true}
                        closable={false}
                        type={"warning"}
                        message="Your device is offline"
                        description="This version of Thread does not support offline mode.  Thread has been paused until your device comes back online."
                      />
                    </Overlay>
                    <Overlay
                      zIndex={10100}
                      color={"default"}
                      opacity={0.7}
                      height={"100%"}
                      width={"100%"}
                    />
                  </>
                )}
                <ThreadRouter />
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    </Grid>
  );
};

export default React.memo(AppLayout);
