import gql from "graphql-tag";
import {
  ApolloCache,
  ApolloClient,
  useMutation,
  useQuery,
  useSubscription
} from "@apollo/client";
import { FRAGMENT_MESSAGE } from "./Fragments";
import {
  messageClear,
  messageClearVariables
} from "../Shared/Api/messageClear";
import { messageOnUpdate } from "../Shared/Api/messageOnUpdate";
import { IActiveMessages } from "Shared/Api/IActiveMessages";
import { QUERY_ACTIVE_MESSAGES } from "./Queries";
import { UseQueryOptions, UseSubscriptionOptions } from "../types";
import { MessageClearInput } from "Shared/Api/globalTypes";
import { MessageFragment } from "Shared/Api/MessageFragment";
import { messageClear_messageClear_messages } from "../Shared/Api/messageClear";
import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { SUBSCRIPTION_MESSAGE_ON_UPDATE } from "./Subscriptions";

const MUTATION_MESSAGE_CLEAR = gql`
  mutation messageClear($input: MessageClearInput!) {
    messageClear(input: $input) {
      messages {
        ...MessageFragment
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;

const readActiveMessages = (cache: ApolloCache<object>) => {
  const activeMessages = cache.readQuery<IActiveMessages>({
    query: QUERY_ACTIVE_MESSAGES
  });

  return activeMessages?.messagesActive ?? [];
};

const readMessage = (
  cache: ApolloCache<object>,
  id: GUID,
  optimistic = true
) => {
  const fragment = cache.readFragment<MessageFragment>(
    {
      fragment: FRAGMENT_MESSAGE,
      fragmentName: "MessageFragment",
      id: `MessageType:${id}`
    },
    optimistic
  );
  if (fragment === null) return fragment;
  return cloneDeep(fragment);
};

const buildMessageClearResponse = (
  cache: ApolloCache<object>,
  ids: GUID[]
): messageClear => {
  const messages: messageClear_messageClear_messages[] = [];
  ids.forEach(id => {
    const message = cloneDeep(readMessage(cache, id));
    if (message !== null) {
      messages.push(message);
    }
  });

  return { messageClear: { messages, __typename: "MessageClearOutput" } };
};

const updateActiveMessagesQuery = (cache: ApolloCache<object>, ids: GUID[]) => {
  const origMessagesActive = readActiveMessages(cache);
  const messagesActive = cloneDeep(origMessagesActive).filter(
    i => !ids.includes(i.id)
  );
  if (messagesActive.length < origMessagesActive.length) {
    cache.writeQuery<IActiveMessages>({
      query: QUERY_ACTIVE_MESSAGES,
      data: {
        messagesActive
      }
    });
  }
};

export const useMessageOnUpdateSubscription = (
  options?: UseSubscriptionOptions<messageOnUpdate, undefined>
) => {
  return useSubscription<messageOnUpdate, undefined>(
    SUBSCRIPTION_MESSAGE_ON_UPDATE,
    {
      ...options
    }
  );
};

export const useMessageClearMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    messageClear,
    messageClearVariables
  >(MUTATION_MESSAGE_CLEAR);

  const handleMessageClear = useCallback(
    (input: MessageClearInput) => {
      // generate an optimistic response
      const optimisticResponse = buildMessageClearResponse(
        client.cache,
        input.messageIds
      );

      return mutate!({
        optimisticResponse,
        variables: { input },
        refetchQueries: [
          {
            query: QUERY_ACTIVE_MESSAGES
          }
        ],
        update: (cache, { data }) => {
          const ids = data?.messageClear.messages.map(msg => msg.id) as GUID[];
          if (ids && ids.length) {
            updateActiveMessagesQuery(cache, ids);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { messageClear: handleMessageClear, error, data };
};

export const useActiveMessagesQuery = (options?: UseQueryOptions) => {
  return useQuery<IActiveMessages>(
    QUERY_ACTIVE_MESSAGES,
    Object.assign({}, {}, { ...options })
  );
};

export const addToActiveMessagesQuery = (
  message: MessageFragment,
  client: ApolloClient<object>
) => {
  const messagesActive = readActiveMessages(client.cache).filter(
    m => m.id !== message.id
  );
  messagesActive.push(message);
  client.cache.writeQuery<IActiveMessages>({
    query: QUERY_ACTIVE_MESSAGES,
    data: {
      messagesActive
    }
  });
};

export const removeFromActiveMessagesQuery = (
  message: MessageFragment,
  client: ApolloClient<object>
) => {
  const messagesActive = readActiveMessages(client.cache).filter(
    m => m.id !== message.id
  );
  client.cache.writeQuery<IActiveMessages>({
    query: QUERY_ACTIVE_MESSAGES,
    data: {
      messagesActive
    }
  });
};
