import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { LoadingScreen } from "../Shared/LoadingScreen";
import ReactDOM from "react-dom";
import {
  PrintFrame,
  Button,
  Row,
  Heading,
  Dropdown,
  Col,
  Icon,
  MenuItem,
  Menu,
  Text,
  Notification,
  IClickParam,
  IChartPhase,
  IChartData,
  Illustration,
  Modal,
  IChartInfo,
  Select,
  SelectOption,
  Alert,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  DatePicker
} from "@raudabaugh/thread-ui";
import { ChartView } from "./ChartView";
import {
  ChartHelper,
  IPhaseDataPoint,
  IPhaseWithDataPoints
} from "./ChartHelper";
import { cloneDeep, first, last } from "lodash";
import { PointPopup, CancelEditing } from "./PointPopup";
import moment from "moment";
import { PhasePopup } from "./PhasePopup";
import { RecoverDataModal } from "./RecoverDataModal";
import compose from "@shopify/react-compose";
import {
  useProgramChartQuery,
  useProgramSessionsQuery,
  useProgramUnlockMutation
} from "../DataAccess/ProgramData";
import { findPermission } from "../Shared/RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { XamarinHelper } from "Shared/XamarinHelper";
import ReactTimeout, { ReactTimeoutProps } from "react-timeout";
import { ApolloError } from "@apollo/client";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  IProgramSessions_programSessions,
  IProgramSessions_programSessions_program
} from "Shared/Api/IProgramSessions";
import { IProgramChart_programChart_dataPoints } from "Shared/Api/IProgramChart";
import {
  DataPointInput,
  DataPointStateEnum,
  LockedTypeEnum,
  ProgramTypeEnum
} from "Shared/Api/globalTypes";
import {
  useDataPointSoftDeleteMutation,
  useDataPointRestoreMutation,
  useDataPointUpdateMutation
} from "DataAccess/DataPointData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useOnlineStatus } from "Shared/ApolloHelper";
import dataImage from "./undraw_Data_re_80ws.svg";
import _ from "lodash";
import { StudentDataTabs } from "Shared/StudentDataTabs";
import { StudentTabEnum } from "Shared/StudentTabEnum";
import { RouterHelper } from "Routes/RouterHelper";
import { useHistory } from "react-router-dom";
import { LabelHelper } from "Shared/LabelHelper";
import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import { useDataPointScoreMutation } from "../DataAccess/DataPointData";
import { useDataPointScoreDurationMutation } from "../DataAccess/DurationData";
import { useDataPointScoreFrequencyMutation } from "../DataAccess/FrequencyData";
import FileSaver from "file-saver";
import { AuthenticationService } from "Security/AuthenticationService";
import contentDisposition from "content-disposition";

interface IChartsBaseProps {
  currentStudentId: string;
  currentProgramId: string;
  onSelectProgram: (id: string) => void;
  onEditPhase: (phaseId: string, type: ProgramTypeEnum) => void;
}

const getPrintChartLabel = (value: any | null) => {
  if (!value) return "";
  return moment(value).format("L");
};

export const ChartsComponent = (
  props: IChartsBaseProps & ReactTimeoutProps
) => {
  const { currentStudentId, currentProgramId, onSelectProgram } = props;

  const { threadUserContext } = useThreadContext();

  const defaultLocal = LabelHelper.monthYearLabelFormat(threadUserContext);

  const { programUnlock } = useProgramUnlockMutation();
  const { handleDataPointRestore } = useDataPointRestoreMutation();
  const { dataPointUpdate } = useDataPointUpdateMutation();
  const { handleDataPointSoftDelete: dataPointSoftDelete } =
    useDataPointSoftDeleteMutation();
  const [printFrameLoaded, setPrintFrameLoaded] = useState(false);
  const [deletedDataPoints, setDeletedDataPoints] = useState<
    IProgramChart_programChart_dataPoints[]
  >([]);
  const [showRecoverData, setShowRecoverData] = useState(false);
  const popOverRef = React.createRef<CancelEditing>();

  const [currentProgram, setCurrentProgram] = useState<
    IProgramSessions_programSessions | null | undefined
  >();
  const [menuItemCount, setMenuItemCount] = useState(0);
  const [isLimited, setIsLimited] = useState(false);
  const [chartPhases, setChartPhase] = useState<IChartPhase[]>([]);
  const [chartData, setChartData] = useState<IChartData | null>(null);
  const printFrameRef = useRef<HTMLIFrameElement>(null);
  const online = useOnlineStatus();
  const history = useHistory();

  const { dataPointScore } = useDataPointScoreMutation();
  const { dataPointScoreDuration } = useDataPointScoreDurationMutation();
  const { dataPointScoreFrequency } = useDataPointScoreFrequencyMutation();

  const [showEnterScore, setShowEnterScore] = useState(false);
  const [defaultAttempts, setDefaultAttempts] = useState<number>();

  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [showPrintProgressReport, setShowPrintProgressReport] = useState<boolean>(false);
  const [action, setAction] = useState("ALLDATES");
  const [reportStartDate, setReportStartDate] = useState<moment.Moment | undefined>(
    moment().subtract(6, "months")
  );
  const [reportEndDate, setReportEndDate] = useState<moment.Moment | undefined>(
    moment()
  );
  
  const availableOffline =
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.currentStudentId && s.availableOffline
    ).length > 0;

  // handlers
  const cancelEditing = useCallback(() => {
    popOverRef?.current?.cancelEditing();
  }, [popOverRef]);

  const handleUnlockClick = useCallback(
    async (program: IProgramSessions_programSessions_program) => {
      await programUnlock(program.id, currentStudentId)
        .then(() => {
          Notification.success({
            duration: 0,
            message: "Program Unlocked",
            description: `${
              currentProgram?.program.name ?? "This program"
            } has been successfully unlocked.`
          });
        })
        .catch((error: ApolloError) => {
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Unlock Program"
          });
        });
    },
    [programUnlock, currentStudentId, currentProgram?.program.name]
  );

  //#region Handlers
  const canUnlockPrograms = findPermission(
    threadUserContext.role,
    Permissions.EDIT_NOTIFICATIONS
  );

  const canRestoreDataPoints = findPermission(
    threadUserContext.role,
    Permissions.RESTORE_DATA_POINT
  );

  const canEditPrograms =
    currentProgramId &&
    currentProgramId !== "nocharts" &&
    findPermission(threadUserContext.role, Permissions.EDIT_PROGRAMS);

  const showOfflineNotification = (label: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${label} function is not available while in offline mode.`
    });
  };

  const handleRecoverDataClick = useCallback(() => {
    if (!online) {
      showOfflineNotification("Recover Data");
      return;
    }
    setShowRecoverData(true);
  }, [setShowRecoverData, online]);

  const handleDismissRecoverData = useCallback(() => {
    setShowRecoverData(false);
  }, [setShowRecoverData]);

  const recoverDataPoint = useCallback(
    (id: string) => {
      handleDataPointRestore(id, currentStudentId)
        ?.then(() => {
          Notification.success({
            duration: 0,
            message: "Data Point Restored",
            description: "Data point successfuly restored"
          });
        })
        .catch((error: Error) => {
          NotificationsHelper.ErrorNotification({
            error,
            title: error.name,
            description: error.message
          });
        });
    },
    [handleDataPointRestore, currentStudentId]
  );

  const handleDataPointSoftDelete = (id: string, studentId: string) => {
    dataPointSoftDelete(id, studentId)
      ?.then(() => {
        Notification.success({
          duration: 0,
          message: "Data Point Deleted",
          description: "Data point successfully deleted"
        });
      })
      .catch((error: Error) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: error.name,
          description: error.message
        });
      });
  };

  const handleUpdateChartPoint = (
    pointId: string,
    studentId: string,
    programId: string,
    input: DataPointInput,
    originalPhaseId: string,
    programType: ProgramTypeEnum
  ) => {
    dataPointUpdate(pointId, studentId, input)
      .then(() => {
        Notification.success({
          duration: 0,
          message: "Data Point Updated",
          description: "Data point successfully updated"
        });
      })
      .catch((error: Error) => {
        NotificationsHelper.ErrorNotification({
          error: error,
          title: error.name,
          description: error.message
        });
      });
  };

  const handlePrintFrameLoad = () => {
    const frame = ReactDOM.findDOMNode(
      printFrameRef.current
    ) as HTMLIFrameElement;

    props.setTimeout &&
      props.setTimeout(() => {
        try {
          frame?.contentWindow?.focus();
          frame?.contentWindow?.print();
        } finally {
          setPrintFrameLoaded(false);
        }
      }, 15 * 1000);
  };
  //#endregion

  //#region ProgramSessionQuery
  const {
    loading: programSessionsLoading,
    error: programSessionsError,
    data: programSessionsData
  } = useProgramSessionsQuery(currentStudentId);

  const sortedProgramSessions: IProgramSessions_programSessions[] | undefined =
    useMemo(() => {
      if (programSessionsData) {
        const { programSessions } = programSessionsData;

        return cloneDeep(programSessions).sort((ps1, ps2) =>
          (ps1.program.name || "").localeCompare(ps2!.program.name || "")
        );
      }
    }, [programSessionsData]);

  useEffect(() => {
    if (programSessionsError) {
      NotificationsHelper.ErrorNotification({
        title: "Unable to get Chart Listing",
        error: programSessionsError
      });
    }
  }, [programSessionsError]);

  useEffect(() => {
    if (sortedProgramSessions) {
      if (currentProgramId) {
        setCurrentProgram(
          sortedProgramSessions.find(programSession => {
            return programSession.program.id === currentProgramId;
          })
        );
      } else if (sortedProgramSessions.length > 0) {
        // redirect to the first program if there is one
        onSelectProgram(sortedProgramSessions[0].id);
      }
    }
  }, [currentProgramId, onSelectProgram, sortedProgramSessions]);

  useEffect(() => {
    if (sortedProgramSessions) {
      setMenuItemCount(sortedProgramSessions.length);
    }
  }, [sortedProgramSessions]);
  //#endregion

  //#region ProgramChartQuery
  const {
    loading: programChartLoading,
    error: programChartError,
    data: programChartData,
    refetch: programChartRefetch
  } = useProgramChartQuery(currentStudentId, currentProgramId, undefined, {
    skip:
      !currentProgramId ||
      currentProgramId === "nocharts" ||
      !(online || availableOffline)
  });

  useEffect(() => {
    if (online && isLimited) {
      programChartRefetch();
    }
  }, [programChartRefetch, online, isLimited]);

  useEffect(() => {
    let isCancelled = false;
    const dateSortDesc = (
      b: IProgramChart_programChart_dataPoints,
      a: IProgramChart_programChart_dataPoints
    ) => {
      if (a.completedAt === b.completedAt) {
        return 0;
      }
      if (a.completedAt == null) {
        return 1;
      }
      if (b.completedAt == null) {
        return -1;
      }
      return a.completedAt.localeCompare(b.completedAt);
    };

    !isCancelled &&
      programChartError &&
      NotificationsHelper.ErrorNotification({
        title: "Unable to get Chart Data",
        error: programChartError
      });

    if (!isCancelled && programChartData && currentProgram) {
      setIsLimited(!!programChartData.programChart.mostRecentLimit);
      const allPhases = programChartData.programChart.phases;
      const filteredPhases: IPhaseWithDataPoints[] = [];
      const tempChartPhases: IChartPhase[] = [];
      const currentPhase = _.last(allPhases);

      // Order by data points
      const allPoints = programChartData.programChart.dataPoints.filter(
        p => !p.softDeleted && p.state === DataPointStateEnum.COMPLETED
      ) as IPhaseDataPoint[];
      for (const point of allPoints) {
        const phase = allPhases.find(p => p.id === point.phaseId);
        if (phase) {
          if (
            filteredPhases.length === 0 ||
            _.last(filteredPhases)?.id !== phase.id
          ) {
            const copy: IPhaseWithDataPoints = {
              ...phase,
              points: []
            };
            filteredPhases.push(copy);
          }
          _.last(filteredPhases)?.points.push(cloneDeep(point));
        }
      }

      if (!filteredPhases.find(p => p.id === currentProgram.currentPhase?.id)) {
        // include current phase even if it has no points
        const phase = allPhases.find(
          p => p.id === currentProgram.currentPhase?.id
        );
        if (phase) {
          const emptyCurrentPhase: IPhaseWithDataPoints = {
            ...phase,
            points: []
          };
          filteredPhases.push(emptyCurrentPhase);
        }
      }

      if (canRestoreDataPoints) {
        const tempDeletedDataPoints = programChartData?.programChart.dataPoints
          .filter(dp => dp.softDeleted)
          .reverse();

        // ensure we have most recent first, empty / null last - x is ISO8601 dates, so should be lexographically sortable
        tempDeletedDataPoints.sort(dateSortDesc);
        setDeletedDataPoints(tempDeletedDataPoints);
      }

      if (currentProgram && filteredPhases && filteredPhases.length) {
        filteredPhases.forEach(phase => {
          const firstXLabel = getPrintChartLabel(
            first(phase.points)?.completedAt
          );
          const lastXLabel = getPrintChartLabel(
            last(phase.points)?.completedAt
          );
          const range =
            firstXLabel !== lastXLabel
              ? `${firstXLabel}-${lastXLabel}`
              : firstXLabel;
          const phaseTargets = phase.targetIds.map(id =>
            currentProgram!.program.targets.find(t => t.id === id)
          );
          const chartPhase: IChartPhase = {
            id: phase.id,
            pointsNumber: phase.points.length,
            range: range,
            labelOverride: phase?.phaseNameOverride,
            additionalInfo: phase?.phaseSummary,
            target: ChartHelper.getPhaseName(phase, phaseTargets),
            points: phase.points.map(
              point =>
                ({
                  id: point.id,
                  note: !!point.note,
                  type: point.pointType,
                  x: point.completedAt
                    ? new Date(point.completedAt)
                    : undefined,
                  popover: (
                    <PointPopup
                      ref={popOverRef}
                      key={point.id}
                      studentId={currentStudentId}
                      program={currentProgram!.program}
                      point={point}
                      date={moment(point.completedAt)}
                      phases={programChartData.programChart.phases}
                      pointPhase={phase}
                      onSoftDelete={handleDataPointSoftDelete}
                      onUpdateChartPoint={handleUpdateChartPoint}
                    />
                  ),
                  onPopoverClose: cancelEditing
                } as unknown as IChartInfo)
            ),
            popover: phase && (
              <PhasePopup
                program={currentProgram!.program}
                phase={phase}
                currentPhase={currentPhase!}
                studentId={props.currentStudentId}
                onEditPhase={(phaseId: string) =>
                  props.onEditPhase(phaseId, currentProgram!.program.type)
                }
              />
            )
          };
          tempChartPhases.push(chartPhase);
        });
        setChartPhase(tempChartPhases);
        setChartData(
          ChartHelper.mapChartViewData(
            currentProgram!.program.type,
            filteredPhases,
            defaultLocal
          )
        );
      }
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    programChartData,
    programChartError,
    currentProgram,
    canRestoreDataPoints,
    currentStudentId
  ]);
  //#endregion

  const scoreBehavior = useMemo(
    () =>
      currentProgram?.program.type === ProgramTypeEnum.DURATION ||
      currentProgram?.program.type === ProgramTypeEnum.FREQUENCY,
    [currentProgram]
  );
  const scoreLabel = useMemo(() => {
    switch (currentProgram?.program.type) {
      case ProgramTypeEnum.FREQUENCY:
        return "Number of Occurrences";
      case ProgramTypeEnum.DURATION:
        return "Duration of Occurrence (minutes)";
      default:
        return "Correct";
    }
  }, [currentProgram]);

  const handleSaveScore = useCallback(
    async (
      score: number,
      date: moment.Moment,
      attempts?: number,
      phaseId?: string
    ) => {
      if (currentProgram) {
        try {
          const currentPhaseId = phaseId ?? currentProgram.currentPhase?.id;
          if (!currentPhaseId) {
            NotificationsHelper.ErrorNotification({
              title: "Unable to save score",
              description: "Missing phase"
            });
            return;
          }
          if (currentProgram.program.type === ProgramTypeEnum.DURATION) {
            await dataPointScoreDuration(
              currentProgram.program.id,
              currentPhaseId,
              props.currentStudentId,
              score * 60,
              date.toISOString()
            );
          } else if (currentProgram.program.type === ProgramTypeEnum.FREQUENCY) {
            await dataPointScoreFrequency(
              currentProgram.program.id,
              currentPhaseId,
              props.currentStudentId,
              score,
              date.toISOString()
            );
          } else {
            await dataPointScore(
              undefined,
              currentPhaseId,
              currentProgram.program.id,
              currentProgram?.program.type,
              props.currentStudentId,
              score,
              date.toISOString(),
              attempts!
            );
          }
        } catch (e) {
          const error = e as Error;
          NotificationsHelper.ErrorNotification({
            error,
            title: "Unable to save score"
          });
        }
      }
    },
    [
       props.currentStudentId,
       currentProgram,
       dataPointScoreDuration,
       dataPointScoreFrequency,
       dataPointScore
    ]
  );
  const handleEnterScoreClose = useCallback(() => {
    setShowEnterScore(false);
  }, []);

  const handleMenuClick = (p: IClickParam) => {
    p.domEvent.stopPropagation();
    switch (p.key) {
      case "editProgram":
        if (currentProgram) {
          RouterHelper.redirectToProgramEdit(
            props.currentStudentId,
            props.currentProgramId,
            currentProgram.program.type,
            history
          );
        }
        break;
      case "printProgressReport":
        if(findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
          setShowPrintProgressReport(true);
        }
        break;
      case "printChart":
        if (!online) {
          showOfflineNotification("Print Chart");
          return;
        }
        setPrintFrameLoaded(true);
        break;
      case "downloadDatapoints":
        if(findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS)) {
          downloadDatapointsReport();
        }
        break;
      case "backdateDatapoint":
        if (!online) {
          showOfflineNotification("Backdate Datapoint");
          return;
        }
        if (currentProgram?.program) {
          const behavior =
            currentProgram.program.type === ProgramTypeEnum.DURATION ||
            currentProgram.program.type === ProgramTypeEnum.FREQUENCY;
          let attempts = 0;
      
          if (!behavior) {
            attempts =
              currentProgram.program.type === ProgramTypeEnum.TASK_ANALYSIS
                ? (currentProgram.currentPhase?.steps.length ?? 0)
                : 0;
          }
          setDefaultAttempts(attempts);
          setShowEnterScore(true);
         }
        break;
      default:
        console.log('Unhandled menu item');
        break;
    }
  };

  const downloadProgressReport = useCallback(async () => {
    setReportLoading(true);
    try {
      const endpoint = 'ProgressReport';
      const title = 'Download Progress Report';
      const programId = currentProgramId;
      const timeZone =
        typeof Intl === "object"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : undefined;
      let url = `${process.env.REACT_APP_THREADAPI_URL!.replace(
        "Api/GraphQL",
        `ClinicalApi/${endpoint}`
      )}?studentId=${props.currentStudentId}&timezone=${
        timeZone ?? "America/New_York"
      }&programId=${programId}`;
      const startDate = reportStartDate!
        .clone()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .utc(false);
      const endDate = reportEndDate!
        .clone()
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .utc(false);
      url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
      if (action === "ALLDATES")
      {
        url += `&allDates=true`;
      }

      const headers = AuthenticationService.getHeaders();
      if (XamarinHelper.insideXamarin()) {
        const result = await XamarinHelper.viewDocument(
          url,
          title,
          headers.authorization,
          headers.organization
        );
        if (!result) {
          throw new Error("Unable to download clinical file");
        }
      } else {
        const response = await fetch(url, { headers });
        const blob = await response.blob();
        if (!response.headers.get("Content-Disposition"))
          throw new Error("Content-Disposition header missing");
        const filename = contentDisposition.parse(
          response.headers.get("Content-Disposition") ||
            'attachment; filename="download"'
        ).parameters.filename;
        FileSaver.saveAs(blob, filename || "download");
      }
      setReportLoading(false);
    } catch (e) {
      const error = e as Error;
      setReportLoading(false);
      NotificationsHelper.ErrorNotification({
        error,
        title: "Failed to Download Report"
      });
    }
  }, [action, currentProgramId, props.currentStudentId, reportEndDate, reportStartDate]);

  const downloadDatapointsReport = useCallback(async () => {
    setReportLoading(true);
    try {
      const endpoint = 'RawDataReport';
      const title = 'Download Datapoints';
      const programId = currentProgramId;
      const timeZone =
        typeof Intl === "object"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : undefined;
      let url = `${process.env.REACT_APP_THREADAPI_URL!.replace(
        "Api/GraphQL",
        `ClinicalApi/${endpoint}`
      )}?studentId=${props.currentStudentId}&timezone=${
        timeZone ?? "America/New_York"
      }&programId=${programId}`;

      const headers = AuthenticationService.getHeaders();
      if (XamarinHelper.insideXamarin()) {
        const result = await XamarinHelper.viewDocument(
          url,
          title,
          headers.authorization,
          headers.organization
        );
        if (!result) {
          throw new Error("Unable to download clinical file");
        }
      } else {
        const response = await fetch(url, { headers });
        const blob = await response.blob();
        if (!response.headers.get("Content-Disposition"))
          throw new Error("Content-Disposition header missing");
        const filename = contentDisposition.parse(
          response.headers.get("Content-Disposition") ||
            'attachment; filename="download"'
        ).parameters.filename;
        FileSaver.saveAs(blob, filename || "download");
      }
      setReportLoading(false);
    } catch (e) {
      const error = e as Error;
      setReportLoading(false);
      NotificationsHelper.ErrorNotification({
        error,
        title: "Failed to Download Report"
      });
    }
  },[currentProgramId, props.currentStudentId]);

  const actions = (
    <Menu onClick={handleMenuClick} selectable={false} mode="vertical">
      {canEditPrograms && (
        <MenuItem key="editProgram">
          <Icon type="fa-edit fas" width="28px" />
          Edit Program
        </MenuItem>
      )}
      {findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS) && (
        <MenuItem key="printProgressReport">
          <Icon type="fa-file-word fas" width="28px" />
          Print Progress Report
        </MenuItem>
      )}
      {currentProgram && !XamarinHelper.insideXamarin() && (
        <MenuItem key="printChart">
          <Icon type="fa-print far" width="28px" />
          Print Chart
        </MenuItem>
      )}
      {findPermission(threadUserContext.role, Permissions.DOWNLOAD_REPORTS) && (
        <MenuItem key="downloadDatapoints">
          <Icon type="fa-file-excel fas" width="28px" />
          Download Datapoints
        </MenuItem>
      )}
      {!currentProgram?.program.locked && (
        <MenuItem key="backdateDatapoint">
          <Icon type="fa-star fas" width="28px" />
          Backdate Datapoint
      </MenuItem>
      )}
    </Menu>
  );

    const formItemLayout = {
    labelCol: {
      lg: { span: 5 },
      md: { span: 5 },
      sm: { span: 6 },
      xs: { span: 24 }
    },
    wrapperCol: {
      lg: { span: 21 },
      md: { span: 21 },
      sm: { span: 20 },
      xs: { span: 24 }
    }
  };

  const handleReportStartDateChange = (date: moment.Moment) => {
    if(date)
      setReportStartDate(date.clone());
  };

  const handleReportEndDateChange = (date: moment.Moment) => {
    if(date)
      setReportEndDate(date.clone());
  };

  const handlePrintProgressReport = useCallback(() => {
    setShowPrintProgressReport(false);
    downloadProgressReport();
  }, [downloadProgressReport]);

  const handleDismissProgressReport = useCallback(() => {
    setShowPrintProgressReport(false);
  }, [setShowPrintProgressReport]);

  const renderProgressReportModal = () => {
    return (
      <Modal
        visible={showPrintProgressReport}
        title="Print One-Chart Progress Report"
        okText="Download"
        onOk={handlePrintProgressReport}
        cancelText="Cancel"
        onCancel={handleDismissProgressReport}
        afterClose={handleDismissProgressReport}
      >
        <Form>
          <FormItem
          required={true}
          >
            <RadioGroup 
              value={action}
            >
              <Row>
                <Radio
                value="ALLDATES"
                onChange={event => event.target.checked && setAction("ALLDATES")}
                >
                  For all dates
                </Radio>
              </Row>
              <Row>
                <Radio
                  value="DATERANGE"
                  onChange={event => event.target.checked && setAction("DATERANGE")}
                >
                  For the following dates:
                </Radio>
              </Row>
            </RadioGroup>
          </FormItem>
          <FormItem label={"Start date"} {...formItemLayout}>
            <DatePicker
              size="large"
              placeholder=""
              value={reportStartDate}
              onChange={handleReportStartDateChange}
              disabled={action==="ALLDATES"}
            />
          </FormItem>
          <FormItem label={"End date"} {...formItemLayout}>
            <DatePicker
              size="large"
              placeholder=""
              value={reportEndDate}
              onChange={handleReportEndDateChange}
              disabled={action==="ALLDATES"}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <LoadingScreen
        loading={programSessionsLoading || programChartLoading || reportLoading}
        tip={reportLoading ? "Downloading... large reports may take a few minutes." : "Loading..."}
      />
      <Modal
        centered
        footer={false}
        visible={showRecoverData}
        onCancel={handleDismissRecoverData}
      >
        <RecoverDataModal
          chartType={programChartData?.programChart.program.type!}
          data={deletedDataPoints}
          phases={programChartData?.programChart.phases!}
          onRecover={recoverDataPoint}
        />
      </Modal>
      {showPrintProgressReport && renderProgressReportModal()}
      {showEnterScore && (
        <EnterScoreDialog
          scoreLabel={scoreLabel}
          dateLabel={!scoreBehavior ? "Date" : "Date of Behavior"}
          attemptsLabel={!scoreBehavior ? "Attempted" : undefined}
          defaultAttempts={defaultAttempts}
          onClose={handleEnterScoreClose}
          open={showEnterScore}
          onSaveScore={handleSaveScore}
          studentId={props.currentStudentId}
          programId={currentProgramId}
          onlyAllowPastDates={true}
        />
      )}
      <StudentDataTabs
        studentId={props.currentStudentId}
        activeTab={StudentTabEnum.CHARTS}
      >
        {menuItemCount === 0 && (
          <>
            <Row margin="0 0 30px 0">
              <Illustration src={dataImage} />
            </Row>
            <Row type="flex" justify="center">
              <Heading level={2}>{`No charts found.`}</Heading>
            </Row>
          </>
        )}
        {menuItemCount > 0 && (
          <Row type="flex" margin="4px" alignItems="center" justify="end">
            <Col grow={1}>
              <Select
                value={currentProgram?.program.id || "Select a Chart..."}
                width="100%"
                onSelect={(value) => {
                  onSelectProgram(value as string);
                }}
                >
                  {sortedProgramSessions?.map(
                    programSession =>
                      programSession.program.name && (
                        <SelectOption 
                          title={programSession.program.name} 
                          value={programSession.program!.id} 
                          >
                          {programSession.program.name}
                        </SelectOption>
                      )
                  )}
              </Select>
            </Col>
            {canRestoreDataPoints && (
              <Col grow={0}>
                <Button
                  ghost={!(deletedDataPoints && deletedDataPoints.length > 0)}
                  borderless={true}
                  disabled={!(deletedDataPoints && deletedDataPoints.length > 0)}
                  onClick={handleRecoverDataClick}
                  >
                    <Icon margin="0 8px 0 0" type="fa-undo fas"/>
                    Recover data points
                </Button>
              </Col>
            )}
            <Col grow={0}>
              <Dropdown trigger={["click"]} overlay={actions}>
                <Button ghost={true} borderless={true}>
                  <Icon
                  color="default"
                  variation={10}
                  type="fa-ellipsis-v fas"
                  />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        )}
        {currentProgram &&
          canUnlockPrograms &&
          currentProgram.program.locked && (
          <Row type="flex" margin="4px" alignItems="center" >
            <Col grow={1}>
              <Alert
                showIcon={false}
                closable={false}
                type={"error"}
                message={"Program is locked" + 
                  (currentProgram.program.lockedType !== LockedTypeEnum.NONE ? 
                    " - " + LabelHelper.lockedTypeLabel(currentProgram.program.lockedType) : "")}
                action={
                  <Button 
                    type="alert" 
                    borderless={true}
                    onClick={() =>
                      currentProgram && handleUnlockClick(currentProgram?.program)
                    }
                    >
                    Unlock 
                  </Button>
                }
              />
            </Col>
          </Row>
          )}
        {currentProgram && chartData && (
          <>
            <Row
              type="flex"
              align="middle"
              justify="center"
              color="default"
              variation={1}
            >
              <Col padding="4px" xs={24}>
                <ChartView
                  phases={chartPhases}
                  data={chartData}
                  programType={currentProgram!.program.type}
                  studentId={props.currentStudentId}
                />
                {isLimited && (
                  <Heading level={5} margin="24px 0 0 0">
                    <Icon
                      color="warning"
                      type="fa-exclamation-triangle far"
                      width="20px"
                      margin="0 4px 0 0"
                    />
                    <Text>
                      Not all data points for this chart are available while
                      offline. Go online to see complete chart.
                    </Text>
                  </Heading>
                )}
              </Col>
            </Row>
            {printFrameLoaded && (
              <>
                <LoadingScreen loading={true} tip={"Calculating Chart..."} />
                <PrintFrame
                  pageSize="letter"
                  orientation="landscape"
                  ref={printFrameRef}
                  src={
                    "/print/charts/" +
                    currentStudentId +
                    "/" +
                    currentProgram.id
                  }
                  onLoad={handlePrintFrameLoad}
                />
              </>
            )}
          </>
        )}
      </StudentDataTabs>
    </>
  );
};

export const Charts = compose<IChartsBaseProps & ReactTimeoutProps>(
  ReactTimeout
)(ChartsComponent);
