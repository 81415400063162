export enum RoutePathEnum {
  // ABC Data paths
  ABC_DATA = "/abc",
  ABC_DATA_WITH_STUDENT = "/abc/:studentId",
  ABC_DATA_WITH_PROGRAM = "/abc/:studentId/:programId",
  ABC_DATA_CREATE = "/abc/:studentId/add",
  ABC_DATA_CREATE_WITH_PROGRAM = "/abc/:studentId/:programId/add",
  ABC_DATA_EDIT = "/abc/edit/:studentId/:dataId",
  ABC_DATA_EDIT_WITH_PROGRAM = "/abc/edit/:studentId/:dataId/:programId",

  // Archive paths
  ARCHIVE = "/archive",
  ARCHIVE_LIST = "/archive/:studentId",

  // Maintenance paths
  MAINTENANCE = "/maintenance",
  MAINTENANCE_LIST = "/maintenance/:studentId",

  // Chart paths
  CHARTS = "/charts",
  CHARTS_VIEW_WITH_STUDENT = "/charts/:studentId",
  CHARTS_VIEW_WITH_PROGRAM = "/charts/:studentId/:programId",

  // Curriculum paths
  CURRICULUM = "/curriculum",
  CURRICULUM_DEFAULT = "/curriculum/:curriculumId",
  CURRICULUM_UPLOAD = "/curriculum/:curriculumId/upload",
  CURRICULUM_VIEW_FOLDER = "/curriculum/:curriculumId/folder/:folderId",
  CURRICULUM_VIEW_TEMPLATE = "/curriculum/:curriculumId/template/:templateId",
  CURRICULUM_CREATE_TEMPLATE = "/curriculum/:curriculumId/newtemplate/:folderId",
  CURRICULUM_ASSIGN_TEMPLATE = "/curriculum/:curriculumId/assign/:templateId",
  CURRICULUM_ADD_PROGRAM = "/curriculum/:curriculumId/addprogram/:programId",
  CURRICULUM_LIBRARY_SEARCH = "/curriculum/search/:text",
  CURRICULUM_FOLDER_SEARCH = "/curriculum/:curriculumId/:folderId/search/:text",

  // Data Collection paths
  DATA_COLLECTION = "/data",
  DATA_COLLECTION_LIST_PROGRAMS = "/data/:studentId",
  DATA_COLLECTION_SESSION = "/data/:studentId/session",
  DATA_COLLECTION_EDIT_PROGRAM = "/data/:studentId/edit/:type/:programId",
  DATA_COLLECTION_EDIT_PROGRAM_PHASE = "/data/:studentId/edit/:type/:programId/:phaseId",
  DATA_COLLECTION_CREATE_PROGRAM = "/data/:studentId/new",

  // My Student Data paths
  MY_STUDENT_LIST = "/mystudents",
  MY_STUDENT_DETAIL = "/mystudents/:studentId",

  // SysOp paths
  SYSOP = "/sysop",
  BENCHMARKING = "/benchmarking",

  // Default path
  DEFAULT = "/",

  // Error paths
  ERROR_NO_STUDENTS = "/nostudents",
  ERROR_STUDENT_NOT_FOUND = "/studentnotfound",
  ERROR_OFFLINE_PAGE_UNAVAILABLE = "/offlinepageunavailable",
  ERROR_NO_ACCESS = "/noaccess",

  // None (blank) path
  NONE = "",

  // Notifications path
  NOTIFICATIONS = "/notifications",

  // Organization paths
  ORGANIZATION = "/organization",
  ORGANIZATION_EDIT_PROFILE = "/organization/profile/edit",
  ORGANIZATION_VIEW_PROFILE = "/organization/profile",
  ORGANIZATION_VIEW_SETTINGS = "/organization/settings",
  ORGANIZATION_CREATE = "/organization/new",

  // People Management paths
  PEOPLE_MANAGEMENT = "/people",
  PEOPLE_MANAGEMENT_STUDENT_PROFILE_DEFAULT = "/people/student/",
  PEOPLE_MANAGEMENT_STUDENT_PROFILE = "/people/student/:studentId",
  PEOPLE_MANAGEMENT_STAFF_PROFILE = "/people/staff/:staffId",
  PEOPLE_MANAGEMENT_CREATE = "/people/add",

  // Print paths
  PRINT = "/print",
  PRINT_CHART = "/print/charts/:studentId/:programId",

  // Report paths
  REPORT = "/reports",
  REPORT_ABC_DATA = "/reports/abc/:studentId",
  REPORT_PROGRESS = "/reports/progress/:studentId",
  REPORT_RAW_DATA = "/reports/rawdata/:studentId",
  REPORT_SESSION_NOTE = "/reports/sessionnotes/:studentId",
  REPORT_ABC_DATA_DEFAULT = "/reports/abc/",
  REPORT_PROGRESS_DEFAULT = "/reports/progress/",
  REPORT_RAW_DATA_DEFAULT = "/reports/rawdata/",
  REPORT_SESSION_NOTE_DEFAULT = "/reports/sessionnotes/",
  REPORT_BILLING = "/reports/billing",
  REPORT_FINANCE = "/reports/finance",
  REPORT_FINANCE_DETAILED = "/reports/financedetailed",

  // Session Note paths
  SESSION_NOTES = "/notes",
  SESSION_NOTES_LIST_NOTES = "/notes/:studentId",
  SESSION_NOTES_VIEW_NOTE = "/notes/:studentId/view/:noteId",
  SESSION_NOTES_EDIT_NOTE = "/notes/:studentId/edit/:noteId",
  SESSION_NOTES_NEW_NOTE = "/notes/:studentId/new",

  // Onboarding paths
  ONBOARDING = "/onboarding",
  ONBOARDING_STAFF = "/onboarding/staff/:id/:orgId/:code",
  ONBOARDING_STAFF_COMPLETE = "/onboarding/staffcomplete",
  ONBOARDING_STAFF_INVALID = "/onboarding/staffinvalid",
  ONBOARDING_STAFF_DECLINED = "/onboarding/staffdeclined",
  ONBOARDING_STAFF_CANCELED = "/onboarding/staffcanceled"
}
