import { useEffect } from "react";
import { AuthenticationService } from "./AuthenticationService";

export interface ISigninCallbackProps {
  onSuccess: () => void;
  onError: (err: any) => void;
  onSelect: (org: string) => void;
}

export const SigninCallback = (props: ISigninCallbackProps) => {
  useEffect(() => {
    const getSelectedOrg = () => {
      const hash = window.location.hash.substr(1);
      const parameters = hash.split("&");
      for (const parameter of parameters) {
        const pair = parameter.split("=");
        if (pair.length === 2 && pair[0] === "org") {
          return pair[1];
        }
      }
      return null;
    };
    const org = getSelectedOrg();
    if (org) {
      props.onSelect(org);
      return;
    }
    const authService = AuthenticationService.getInstance();
    authService.userManager
      .signinRedirectCallback()
      .then(() => props.onSuccess())
      .catch(err => props.onError(err));
  }, [props]);

  return null;
};
