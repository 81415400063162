import {
  Button,
  Col,
  DragTable,
  Dropdown,
  Form,
  FormItem,
  Grid,
  Heading,
  IColumnProps,
  Icon,
  Input,
  List,
  ListItem,
  Media,
  Menu,
  MenuItem,
  Modal,
  RadioButton,
  RadioGroup,
  Row,
  Table,
  Tag
} from "@raudabaugh/thread-ui";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  CriterionInput,
  ProgramInput,
  ProgramTypeEnum,
  StepInput,
  StepStateEnum,
  TargetInput,
  TargetStateEnum
} from "Shared/Api/globalTypes";
import { ProgramTabEnum } from "./ProgramTabEnum";
import { IProgramComponentProps } from "./Types";
import { v4 as uuid } from "uuid";
import moment from "moment";

export interface ITargetsProps extends IProgramComponentProps {
  historical?: boolean;
}

export const Targets = ({
  historical = false,
  onProgramChange,
  onValidation,
  program,
  students
}: ITargetsProps) => {
  const isTemplate = !students;

  const isBehavior =
    program.programType === ProgramTypeEnum.FREQUENCY ||
    program.programType === ProgramTypeEnum.DURATION;

  const showSteps = program.programType === ProgramTypeEnum.TASK_ANALYSIS;
  const showTargets = program.programType !== ProgramTypeEnum.TASK_ANALYSIS;

  const requireSteps =
    !isTemplate && program.programType === ProgramTypeEnum.TASK_ANALYSIS;
  const requireTargets =
    !isTemplate && program.programType === ProgramTypeEnum.DTT;
  const requireCriteria =
    !isTemplate &&
    !isBehavior &&
    program.programType !== ProgramTypeEnum.INTERVAL;

  // validation adapted from previous ProgramForm
  const targetsValidation = useMemo(() => {
    const programInput = program as ProgramInput;
    if (program.programType === ProgramTypeEnum.DTT) {
      if (programInput) {
        const targets = programInput.targets ?? [];
        const visibleTargets = targets.filter(c => !c.softDeleted);
        if (requireTargets) {
          if (historical) {
            if (programInput.phaseTargetIds?.length === 0) {
              return "Must have at least one active target";
            }
            return undefined;
          }
          if (visibleTargets.length < 1) {
            return "Must have at least one target";
          }
          if (
            visibleTargets.filter(c => c.state === TargetStateEnum.CURRENT)
              .length < 1
          ) {
            return "Must have at least one current target";
          }
        }
        for (const target of visibleTargets) {
          if (!target.targetDescription || target.targetDescription === "") {
            return "Each target must have a name";
          }
        }
      } else {
        const targets = program.targets ?? [];
        const visibleTargets = targets.filter(c => !c.softDeleted);
        for (const target of visibleTargets) {
          if (!target.targetDescription || target.targetDescription === "") {
            return "Each target must have a name";
          }
        }
      }
    }
    return undefined;
  }, [historical, program, requireTargets]);

  // validation adapted from previous ProgramForm
  const stepsValidation = useMemo(() => {
    if (program.programType === ProgramTypeEnum.TASK_ANALYSIS) {
      const steps = program.steps ?? [];
      const visibleSteps = steps.filter(s => !s.softDeleted);

      if (requireSteps && visibleSteps.length < 1) {
        return "Task must have at least one step";
      }
      if (!isTemplate) {
        const activeSteps = visibleSteps.filter(
          s => (s as StepInput).state === StepStateEnum.ACTIVE
        );
        if (requireSteps && activeSteps.length < 1) {
          return "Must have at least one active step";
        }
      }

      for (const step of visibleSteps) {
        if (!step.description || step.description === "") {
          return "Each step must have a name";
        }
      }
    }

    return undefined;
  }, [program, requireSteps, isTemplate]);

  // validation adapted from previous ProgramForm
  const criterionValidation = useMemo(() => {
    const criteria = program.criterionForMastery ?? [];

    if (criteria.filter(c => !c.softDeleted).length < 1 && requireCriteria) {
      return "Must have at least one criterion";
    }

    return undefined;
  }, [program, requireCriteria]);

  useEffect(() => {
    const valid =
      targetsValidation === undefined &&
      stepsValidation === undefined &&
      criterionValidation === undefined;
    onValidation(
      historical ? ProgramTabEnum.HISTORICAL_TARGETS : ProgramTabEnum.TARGETS,
      valid
    );
  }, [
    onValidation,
    historical,
    program,
    criterionValidation,
    stepsValidation,
    targetsValidation
  ]);

  const addTarget = useCallback(() => {
    const existingTargets = program.targets ?? [];

    const newTarget: TargetInput = {
      id: uuid(),
      state: isTemplate ? undefined : TargetStateEnum.FUTURE
    };
    newTarget["isNew"] = true;

    const newProgram = {
      ...program,
      targets: [...existingTargets, newTarget]
    };

    onProgramChange(newProgram);
  }, [onProgramChange, program, isTemplate]);

  const processRemoveTarget = useCallback(
    (id: string) => {
      if (program.targets) {
        const existingTargetIndex = program.targets.findIndex(
          target => target.id === id
        );

        if (existingTargetIndex >= 0) {
          const targets = [...program.targets];
          const existingTarget = targets[existingTargetIndex];

          targets[existingTargetIndex] = {
            ...existingTarget,
            softDeleted: true
          };

          onProgramChange({
            ...program,
            targets
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const removeTarget = useCallback(
    (id: string) => {
      Modal.warning({
        okCancel: true,
        title: "Are you sure you want to delete the target?",
        content: "You are deleting a target and this action can not be undone.",
        onOk: () => processRemoveTarget(id),
        okText: "Delete"
      });
    },
    [processRemoveTarget]
  );

  const editTarget = useCallback(
    (target: TargetInput) => {
      if (program.targets) {
        const targetIndex = program.targets.findIndex(t => t.id === target.id);

        if (targetIndex >= 0) {
          const existingTarget = program.targets[targetIndex];

          const targets = [...program.targets];
          targets[targetIndex] = {
            ...existingTarget,
            ...target
          };

          onProgramChange({
            ...program,
            targets
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const editHistoricalTarget = useCallback(
    (target: TargetInput, active: boolean) => {
      const programInput = program as ProgramInput;
      if (programInput?.phaseTargetIds) {
        let phaseTargetIds = [...programInput.phaseTargetIds];
        if (active) {
          phaseTargetIds.push(target.id);
        } else {
          phaseTargetIds = phaseTargetIds.filter(id => id !== target.id);
        }

        onProgramChange({
          ...program,
          phaseTargetIds
        });
      }
    },
    [onProgramChange, program]
  );

  const addStep = useCallback(() => {
    const existingSteps = program.steps ?? [];

    const newStep: StepInput = {
      id: uuid(),
      state: isTemplate ? undefined : StepStateEnum.ACTIVE
    };

    const newProgram = {
      ...program,
      steps: [...existingSteps, newStep]
    };

    onProgramChange(newProgram);
  }, [onProgramChange, program, isTemplate]);

  const removeStep = useCallback(
    (id: string) => {
      if (program.steps) {
        const existingStepIndex = program.steps.findIndex(
          step => step.id === id
        );

        if (existingStepIndex >= 0) {
          const steps = [...program.steps];
          const existingStep = steps[existingStepIndex];

          steps[existingStepIndex] = {
            ...existingStep,
            softDeleted: true
          };

          onProgramChange({
            ...program,
            steps
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const editStep = useCallback(
    (step: StepInput) => {
      if (program.steps) {
        const stepIndex = program.steps.findIndex(s => s.id === step.id);

        if (stepIndex >= 0) {
          const existingStep = program.steps[stepIndex];

          const steps = [...program.steps];
          steps[stepIndex] = {
            ...existingStep,
            ...step
          };

          onProgramChange({
            ...program,
            steps
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const moveStep = useCallback(
    (draggingIndex: number, hoverIndex: number) => {
      console.log("moving step %d -> %d", draggingIndex, hoverIndex);

      // create a shallow copy
      const steps = [...program.steps!];

      // remove the element at dragging index
      const step = steps.splice(draggingIndex, 1)[0];

      // add it back at hovering index
      steps.splice(hoverIndex, 0, step);

      onProgramChange({
        ...program,
        steps
      });
    },
    [onProgramChange, program]
  );

  const addCriterion = useCallback(() => {
    const existingCriteria = program.criterionForMastery ?? [];

    const newCriterion: CriterionInput = {
      id: uuid(),
      minPercentage: 1,
      pointsAnalyzed: 1
    };

    const newProgram = {
      ...program,
      criterionForMastery: [...existingCriteria, newCriterion]
    };

    onProgramChange(newProgram);
  }, [onProgramChange, program]);

  const removeCriterion = useCallback(
    (id: string) => {
      if (program.criterionForMastery) {
        const existingCriterionIndex = program.criterionForMastery.findIndex(
          criterion => criterion.id === id
        );

        if (existingCriterionIndex >= 0) {
          const existingCriterion =
            program.criterionForMastery[existingCriterionIndex];

          const criterion: CriterionInput = {
            ...existingCriterion,
            softDeleted: true
          };

          const criterionForMastery = [...program.criterionForMastery];
          criterionForMastery[existingCriterionIndex] = criterion;

          onProgramChange({
            ...program,
            criterionForMastery
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const editCriterion = useCallback(
    (criterion: CriterionInput) => {
      if (program.criterionForMastery) {
        const criterionIndex = program.criterionForMastery.findIndex(
          c => c.id === criterion.id
        );

        if (criterionIndex >= 0) {
          const existingCriterion = program.criterionForMastery[criterionIndex];

          const criterionForMastery = [...program.criterionForMastery];
          criterionForMastery[criterionIndex] = {
            ...existingCriterion,
            ...criterion
          };

          onProgramChange({
            ...program,
            criterionForMastery
          });
        }
      }
    },
    [onProgramChange, program]
  );

  const editCriterionText = useCallback(
    (criterionForMasteryText: string) => {
      onProgramChange({
        ...program,
        criterionForMasteryText
      });
    },
    [onProgramChange, program]
  );

  const inputRef = useRef<HTMLElement>();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const programInput = program as ProgramInput;

  return (
    <Form>
      <Grid margin="0 16px 80px 16px">
        {showTargets && (
          <FormItem>
            <SectionHeading required={requireTargets}>
              {isTemplate && "Future "}Targets
            </SectionHeading>
            <SectionValidation text={targetsValidation} />
            <TargetList
              targets={program.targets ?? []}
              hideCompleted={program.programType !== ProgramTypeEnum.DTT}
              noState={isTemplate}
              onAdd={addTarget}
              onRemove={removeTarget}
              onEdit={editTarget}
              onEditHistorical={historical ? editHistoricalTarget : undefined}
              historicalEdit={historical}
              historicalTargetIds={
                historical && programInput.phaseTargetIds
                  ? programInput.phaseTargetIds
                  : undefined
              }
            />
          </FormItem>
        )}
        {showSteps && (
          <FormItem>
            <SectionHeading required={requireSteps}>Steps</SectionHeading>
            <SectionValidation text={stepsValidation} />
            <StepList
              steps={program.steps ?? []}
              noState={isTemplate}
              onAdd={addStep}
              onRemove={removeStep}
              onEdit={editStep}
              onMove={moveStep}
            />
          </FormItem>
        )}
        <FormItem>
          <SectionHeading required={requireCriteria}>
            Criterion for Mastery
          </SectionHeading>
          <SectionValidation text={criterionValidation} />
          {isBehavior ? (
            <CriterionText
              inputRef={inputRef}
              text={program.criterionForMasteryText ?? ""}
              onEdit={editCriterionText}
            />
          ) : (
            <CriterionList
              criteria={program.criterionForMastery ?? []}
              onAdd={addCriterion}
              onRemove={removeCriterion}
              onEdit={editCriterion}
            />
          )}
        </FormItem>
      </Grid>
    </Form>
  );
};

export const HistoricalTargets = (props: IProgramComponentProps) => {
  return <Targets historical {...props} />;
};

const SectionHeading = ({
  children,
  required
}: {
  required: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Row type="flex">
      {required && (
        <Heading level={6} weight="medium" color="error">
          * &nbsp;
        </Heading>
      )}
      <Heading level={6} weight="medium">
        {children}
      </Heading>
    </Row>
  );
};

const SectionValidation = ({ text }: { text?: string }) => {
  if (text) {
    return (
      <Row>
        <Heading color="error" level={6}>
          {text}
        </Heading>
      </Row>
    );
  }

  return null;
};

interface ITargetListProps {
  targets: TargetInput[];
  hideCompleted: boolean;
  noState: boolean;
  onAdd: () => void;
  onRemove: (id: string) => void;
  onEdit: (target: TargetInput) => void;
  onEditHistorical?: (target: TargetInput, active: boolean) => void;
  historicalEdit: boolean;
  historicalTargetIds?: string[];
}

const TargetList = ({
  targets,
  hideCompleted,
  noState,
  onAdd,
  onEdit,
  onRemove,
  onEditHistorical,
  historicalEdit,
  historicalTargetIds
}: ITargetListProps) => {
  const visibleTargets = useMemo(() => {
    return targets.filter(target => !target.softDeleted);
  }, [targets]);

  const inputRef = useRef<HTMLElement>();
  const isFirstPass = useRef(true);

  useEffect(() => {
    if (isFirstPass.current) {
      isFirstPass.current = false;
      return;
    }
    inputRef?.current?.focus();
  }, [visibleTargets.length]);

  const changeState = useCallback(
    (record: TargetInput, e: any) => {
      const target = { ...record };
      switch (e.target.value) {
        case "COMPLETED":
          target.state = TargetStateEnum.MASTERED;
          if (!target.mastered) {
            target.mastered = moment().utc().toISOString();
          }
          target.completed = true;
          break;
        case TargetStateEnum.FUTURE:
          target.state = TargetStateEnum.FUTURE;
          target.mastered = null;
          target.completed = false;
          break;
        case TargetStateEnum.CURRENT:
          target.state = TargetStateEnum.CURRENT;
          target.mastered = null;
          target.completed = false;
          break;
        case TargetStateEnum.MASTERED:
          target.state = TargetStateEnum.MASTERED;
          if (!target.mastered) {
            target.mastered = moment().utc().toISOString();
          }
          target.completed = false;
          break;
        default:
          console.error("Unknown Target state", e.target.value);
          break;
      }
      onEdit(target);
    },
    [onEdit]
  );

  const changeHistoricalState = useCallback(
    (record: TargetInput, e: any) => {
      const target = { ...record };
      onEditHistorical?.(target, e.target.value === "true");
    },
    [onEditHistorical]
  );

  const columns = useMemo(() => {
    const cols: IColumnProps<TargetInput>[] = [
      {
        dataIndex: "targetDescription",
        title: "Name",
        render: (_value: string, record: TargetInput) => (
          <Input
            innerRef={inputRef}
            value={record.targetDescription ?? undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onEdit({ ...record, targetDescription: e.target.value })
            }
          />
        )
      }
    ];
    if (!noState) {
      cols.push({
        dataIndex: "state",
        title: "Status",
        render: (_value: TargetStateEnum, record: TargetInput) => {
          const active = historicalTargetIds
            ? historicalTargetIds.findIndex(id => id === record.id)
            : -1;
          return !historicalEdit ? (
            <RadioGroup
              defaultValue={TargetStateEnum.NONE}
              value={record.completed ? "COMPLETED" : record.state}
              buttonStyle="outline"
              onChange={(e: any) => changeState(record, e)}
            >
              <RadioButton value={TargetStateEnum.FUTURE}>Future</RadioButton>
              <RadioButton value={TargetStateEnum.CURRENT}>Current</RadioButton>
              <RadioButton value={TargetStateEnum.MASTERED}>
                Mastered
              </RadioButton>
              {!hideCompleted && (
                <RadioButton value={"COMPLETED"}>Completed</RadioButton>
              )}
            </RadioGroup>
          ) : (
            <RadioGroup
              defaultValue={TargetStateEnum.NONE}
              value={active !== -1}
              buttonStyle="outline"
              onChange={(e: any) => changeHistoricalState(record, e)}
            >
              <RadioButton value={true}>Active</RadioButton>
              <RadioButton value={false}>Inactive</RadioButton>
            </RadioGroup>
          );
        },
        width: "375px"
      });
    }
    if (!historicalEdit) {
      cols.push({
        key: "delete",
        dataIndex: "id",
        render: (_value: string, record: TargetInput) => {
          const canDelete =
            !!record["isNew"] ||
            record.state === TargetStateEnum.FUTURE ||
            noState;
          return canDelete ? (
            <Col padding="10px 0 0 0">
              <Icon type="fa-trash fas" onClick={() => onRemove(record.id)} />
            </Col>
          ) : (
            <></>
          );
        },
        width: "40px"
      });
    }
    return cols;
  }, [
    changeState,
    changeHistoricalState,
    hideCompleted,
    noState,
    historicalTargetIds,
    historicalEdit,
    onEdit,
    onRemove
  ]);

  const stackedColumns = useMemo(() => {
    return [
      {
        key: "stacked",
        dataIndex: "id",
        render: (_value: string, record: TargetInput) => {
          const active = historicalTargetIds
            ? historicalTargetIds.findIndex(id => id === record.id)
            : -1;
          const canDelete =
            !!record["isNew"] ||
            record.state === TargetStateEnum.FUTURE ||
            noState;
          return (
            <Grid>
              <Row type="flex">
                <Col grow={1}>
                  <Input
                    innerRef={inputRef}
                    value={record.targetDescription ?? undefined}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onEdit({ ...record, targetDescription: e.target.value })
                    }
                  />
                </Col>
                {canDelete && (
                  <Col grow={0} margin="6px 0 0 16px">
                    <Icon
                      type="fa-trash fas"
                      onClick={() => onRemove(record.id)}
                    />
                  </Col>
                )}
              </Row>
              {!historicalEdit ? (
                <>
                  <RadioGroup
                    defaultValue={TargetStateEnum.NONE}
                    value={record.state}
                    buttonStyle="outline"
                    onChange={(e: any) => changeState(record, e)}
                  >
                    <RadioButton value={TargetStateEnum.FUTURE}>
                      Future
                    </RadioButton>
                    <RadioButton value={TargetStateEnum.CURRENT}>
                      Current
                    </RadioButton>
                    <RadioButton value={TargetStateEnum.MASTERED}>
                      Mastered
                    </RadioButton>
                    <RadioButton value={"COMPLETED"} disabled>
                      Completed
                    </RadioButton>
                  </RadioGroup>
                </>
              ) : (
                <RadioGroup
                  defaultValue={TargetStateEnum.NONE}
                  value={active !== -1}
                  buttonStyle="outline"
                  onChange={(e: any) => changeHistoricalState(record, e)}
                >
                  <RadioButton value={true}>Active</RadioButton>
                  <RadioButton value={false}>Inactive</RadioButton>
                </RadioGroup>
              )}
            </Grid>
          );
        }
      }
    ];
  }, [
    changeState,
    changeHistoricalState,
    historicalTargetIds,
    historicalEdit,
    noState,
    onEdit,
    onRemove
  ]);

  return (
    <Media.Md orLarger>
      {(isSizeOrLarger: boolean) => (
        <>
          <Table
            columns={isSizeOrLarger ? columns : stackedColumns}
            dataSource={visibleTargets}
            pagination={false}
            rowKey="id"
            showHeader={isSizeOrLarger}
          />
          <Row>
            <Button ghost type="primary" onClick={onAdd}>
              + Add Target
            </Button>
          </Row>
        </>
      )}
    </Media.Md>
  );
};

interface IStepListProps {
  steps: StepInput[];
  noState: boolean;

  onAdd: () => void;
  onRemove: (id: string) => void;
  onEdit: (step: StepInput) => void;
  onMove: (draggingIndex: number, hoverIndex: number) => void;
}

const StepList = ({
  steps,
  noState,
  onAdd,
  onEdit,
  onMove,
  onRemove
}: IStepListProps) => {
  const inputRef = useRef<HTMLElement>();
  const isFirstPass = useRef(true);
  const [visibleIndexes, visibleSteps] = useMemo(() => {
    // we use this in translateMove
    const visibleIndexes = [];
    const visibleSteps = [];

    for (let index = 0; index < steps.length; index++) {
      const step = steps[index];

      if (!step.softDeleted) {
        visibleIndexes.push(index);
        visibleSteps.push(step);
      }
    }

    return [visibleIndexes, visibleSteps];
  }, [steps]);

  useEffect(() => {
    if (isFirstPass.current) {
      isFirstPass.current = false;
      return;
    }
    inputRef?.current?.focus();
  }, [visibleSteps.length]);

  const changeState = useCallback(
    (record: StepInput, e: any) => {
      const step = { ...record };
      switch (e.target.value) {
        case StepStateEnum.ACTIVE:
          step.state = StepStateEnum.ACTIVE;
          break;
        case StepStateEnum.INACTIVE:
          step.state = StepStateEnum.INACTIVE;
          break;
        default:
          console.error("Unknown Step state", e.target.value);
          break;
      }
      onEdit(step);
    },
    [onEdit]
  );

  // since we do the deletion filtering, we have to translate to real indexes
  const translateMove = useCallback(
    (draggingIndex: number, hoveringIndex: number) => {
      const translatedDraggingIndex = visibleIndexes[draggingIndex];
      const translatedHoveringIndex = visibleIndexes[hoveringIndex];

      if (translatedDraggingIndex >= 0 && translatedHoveringIndex >= 0) {
        onMove(translatedDraggingIndex, translatedHoveringIndex);
      }
    },
    [onMove, visibleIndexes]
  );

  const columns = useMemo(() => {
    return [
      {
        key: "dragHandle",
        dataIndex: "id",
        render: () => <Icon type="fa-bars" />,
        width: "40px"
      },
      {
        dataIndex: "id",
        title: "#",
        render: (_value: string, record: StepInput, index: number) => (
          <>{index + 1}</>
        ),
        width: "40px"
      },
      {
        dataIndex: "description",
        title: "Name",
        render: (_value: string, record: StepInput, index: number) => (
          <Input
            innerRef={inputRef}
            value={record.description ?? undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onEdit({ ...record, description: e.target.value })
            }
          />
        )
      },
      {
        dataIndex: "state",
        title: "Status",
        visible: !noState,
        render: (_value: StepStateEnum, record: StepInput) => (
          <RadioGroup
            defaultValue={StepStateEnum.NONE}
            value={record.state}
            buttonStyle="outline"
            onChange={(e: any) => changeState(record, e)}
          >
            <RadioButton value={StepStateEnum.ACTIVE}>Active</RadioButton>
            <RadioButton value={StepStateEnum.INACTIVE}>Inactive</RadioButton>
            <RadioButton value={"MASTERED"} disabled>
              Mastered
            </RadioButton>
          </RadioGroup>
        ),
        width: "375px"
      },
      {
        key: "delete",
        dataIndex: "id",
        render: (_value: string, record: StepInput) => (
          <Icon type="fa-trash fas" onClick={() => onRemove(record.id)} />
        ),
        width: "40px"
      }
    ];
  }, [changeState, onEdit, onRemove, noState]);

  const stackedColumns = useMemo(() => {
    return [
      {
        key: "stacked",
        dataIndex: "id",
        render: (_value: string, record: StepInput, index: number) => (
          <Grid>
            <Row type="flex" align="middle">
              <Col>
                <Icon type="fa-bars fas" />
              </Col>
              <Col padding="0 12px">{index + 1}</Col>
              <Col grow={1}>
                <Input
                  innerRef={inputRef}
                  value={record.description ?? undefined}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onEdit({ ...record, description: e.target.value })
                  }
                />
              </Col>
            </Row>
            <RadioGroup
              defaultValue={StepStateEnum.NONE}
              value={record.state}
              buttonStyle="outline"
              onChange={(e: any) => changeState(record, e)}
            >
              <RadioButton value={StepStateEnum.ACTIVE}>Active</RadioButton>
              <RadioButton value={StepStateEnum.INACTIVE}>Inactive</RadioButton>
              <RadioButton value={"MASTERED"} disabled>
                Mastered
              </RadioButton>
            </RadioGroup>
            <Row justify="end" type="flex">
              <Icon type="fa-trash fas" onClick={() => onRemove(record.id)} />
            </Row>
          </Grid>
        )
      }
    ];
  }, [changeState, onEdit, onRemove]);

  return (
    <Media.Md orLarger>
      {(isSizeOrLarger: boolean) => (
        <>
          <DragTable
            columns={isSizeOrLarger ? columns : stackedColumns}
            dataSource={visibleSteps}
            onMove={translateMove}
            pagination={false}
            rowKey="id"
            showHeader={isSizeOrLarger}
          />
          <Row>
            <Button ghost type="primary" onClick={onAdd}>
              + Add Step
            </Button>
          </Row>
        </>
      )}
    </Media.Md>
  );
};

interface ICriterionListProps {
  criteria: CriterionInput[];

  onAdd: () => void;
  onRemove: (id: string) => void;
  onEdit: (criterion: CriterionInput) => void;
}
const CriterionList = ({
  criteria,
  onAdd,
  onEdit,
  onRemove
}: ICriterionListProps) => {
  const visibleCriteria = useMemo(() => {
    return criteria.filter(criterion => !criterion.softDeleted);
  }, [criteria]);

  // we do the button as a list item to get the last split line like designed
  return (
    <List>
      {visibleCriteria.map(criterion => (
        <CriterionListItem
          key={criterion.id}
          criterion={criterion}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ))}
      <ListItem>
        <Button ghost type="primary" onClick={onAdd}>
          + Additional criterion for mastery
        </Button>
      </ListItem>
    </List>
  );
};

interface ICriterionListItemProps {
  criterion: CriterionInput;

  onRemove: (id: string) => void;
  onEdit: (criterion: CriterionInput) => void;
}

const CriterionListItem = ({
  criterion,
  onEdit,
  onRemove
}: ICriterionListItemProps) => {
  const handleChange = useCallback(
    (field, id, event) => {
      const newCriterion = { ...criterion };
      switch (field) {
        case "minPercentage":
          newCriterion.minPercentage = parseInt(event.key) / 100;
          break;
        case "pointsAnalyzed":
          newCriterion.pointsAnalyzed = parseInt(event.key);
          break;
      }
      onEdit(newCriterion);
    },
    [criterion, onEdit]
  );

  const percentageItems = useMemo(() => {
    const items: number[] = [];
    for (let i = 100; i >= 5; i = i - 5) {
      items.push(i);
    }
    return items.map((value: number) => {
      return <MenuItem key={`${value}`}>{`${value}`}</MenuItem>;
    });
  }, []);

  const analyzedItems = useMemo(() => {
    const items: number[] = [];
    for (let i = 1; i <= 25; i++) {
      items.push(i);
    }
    return items.map((value: number) => {
      return <MenuItem key={`${value}`}>{`${value}`}</MenuItem>;
    });
  }, []);

  const percentageMenu = (
    <Menu
      onClick={event => handleChange("minPercentage", criterion.id, event)}
      selectedKeys={[criterion.minPercentage!.toString()]}
    >
      {percentageItems}
    </Menu>
  );

  const pointsMenu = (
    <Menu
      onClick={event => handleChange("pointsAnalyzed", criterion.id, event)}
      selectedKeys={[criterion.pointsAnalyzed!.toString()]}
    >
      {analyzedItems}
    </Menu>
  );

  return (
    <ListItem>
      <Col>
        <Dropdown trigger={["click"]} overlay={pointsMenu}>
          <Tag>{criterion.pointsAnalyzed!}</Tag>
        </Dropdown>
      </Col>
      <Heading level={6} margin="0">
        {criterion.pointsAnalyzed! === 1 ? "score of" : "scores of"}
      </Heading>
      <Col padding={"0 0 0 6px"} grow={1}>
        <Dropdown trigger={["click"]} overlay={percentageMenu}>
          <Tag>{Math.round(criterion.minPercentage! * 100)}</Tag>
        </Dropdown>
        % or more
      </Col>
      <Heading weight="bold" margin="0 6px 0 0" level={5}>
        <Icon type="fa-trash fas" onClick={() => onRemove(criterion.id)} />
      </Heading>
    </ListItem>
  );
};

interface ICriterionTextProps {
  text: string;
  onEdit: (value: string) => void;
  inputRef: React.RefObject<any>;
}

const CriterionText = ({ text, onEdit, inputRef }: ICriterionTextProps) => {
  return (
    <Input
      innerRef={inputRef}
      value={text}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onEdit(e.target.value)
      }
    />
  );
};
