interface IFileType {
  extension: string;
  contentType: string;
}
export class FileHelper {
  static fileMap: IFileType[] = [
    // Document files
    { extension: ".doc", contentType: "application/msword" },
    {
      extension: ".docx",
      contentType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    },
    { extension: ".pdf", contentType: "application/pdf" },
    { extension: ".txt", contentType: "text/plain" },
    { extension: ".rtf", contentType: "application/rtf" },
    { extension: ".pages", contentType: "application/vnd.apple.pages" },

    // Image files
    { extension: ".gif", contentType: "image/gif" },
    { extension: ".jpg", contentType: "image/jpeg" },
    { extension: ".jpeg", contentType: "image/jpeg" },
    { extension: ".png", contentType: "image/png" },

    // Spreadsheet files
    { extension: ".xls", contentType: "application/vnd.ms-excel" },
    {
      extension: ".xlsx",
      contentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    { extension: ".csv", contentType: "text/csv" },

    // Presentation files
    { extension: ".ppt", contentType: "application/vnd.ms-powerpoint" },
    {
      extension: ".pptx",
      contentType:
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    }
  ];

  static allExtensions = FileHelper.fileMap.map(f => f.extension);

  static allContentTypes = FileHelper.fileMap.map(f => f.contentType);

  static imageContentTypes = ["image/gif", "image/jpeg", "image/png"];

  static mobileContentTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/rtf",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ];

  static isImage(contentType: string) {
    return (
      FileHelper.imageContentTypes.find(c => c === contentType) !== undefined
    );
  }

  static isMobileSupported(contentType: string | null) {
    if (!contentType) return false;
    return (
      FileHelper.mobileContentTypes.find(c => c === contentType) !== undefined
    );
  }

  static deriveContentType(name: string) {
    const extension = FileHelper.getFileExtension(name) ?? "";
    return FileHelper.fileMap.find(f => f.extension === extension)?.contentType;
  }

  static getFileExtension(name: string) {
    const pos = name.lastIndexOf(".");
    const extension =
      pos !== -1 ? name.substring(pos).toLowerCase() : undefined;
    return extension;
  }
}
