import { History } from "history";
import { RoutePathEnum } from "./RoutePathEnum";
import { ProgramTypeEnum } from "../Shared/Api/globalTypes";
import { matchPath } from "react-router-dom";
import { IRouteState } from "./IRouteState";
import { LabelHelper } from "Shared/LabelHelper";

export class RouterHelper {
  static updateState = (
    history: History,
    current: RoutePathEnum,
    backLabel?: string
  ) => {
    let state = history.location.state as IRouteState;
    if (!backLabel && !state.backLabel) {
      backLabel = LabelHelper.routePathLabel(
        RouterHelper.defaultParentOfRoute(current)
      );
    }
    state = {
      ...state,
      current
    };
    if (backLabel) {
      state = {
        ...state,
        backLabel
      };
    }
    return state;
  };

  static handleStudentChange = (
    path: RoutePathEnum,
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const newPath = path
      .replace(":studentId", studentId)
      .replace(":programId", "");
    if (replace) {
      history.replace(newPath, history.location.state);
    } else {
      history.push(newPath, history.location.state);
    }
  };

  static redirectToSessionNotes = (
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.SESSION_NOTES_LIST_NOTES.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.SESSION_NOTES_LIST_NOTES
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToSessionNoteView = (
    studentId: string,
    noteId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.SESSION_NOTES_VIEW_NOTE.replace(
      ":studentId",
      studentId
    ).replace(":noteId", noteId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.SESSION_NOTES_VIEW_NOTE,
      LabelHelper.routePathLabel(RoutePathEnum.SESSION_NOTES_LIST_NOTES)
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToSessionNoteEdit = (
    studentId: string,
    noteId: string,
    history: History,
    replace?: boolean
  ) => {
    const path = RoutePathEnum.SESSION_NOTES_EDIT_NOTE.replace(
      ":studentId",
      studentId
    ).replace(":noteId", noteId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.SESSION_NOTES_EDIT_NOTE,
      LabelHelper.routePathLabel(RoutePathEnum.SESSION_NOTES_LIST_NOTES)
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToSessionNoteCreate = (
    studentId: string,
    history: History,
    replace?: boolean
  ) => {
    const path = RoutePathEnum.SESSION_NOTES_NEW_NOTE.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.SESSION_NOTES_NEW_NOTE,
      LabelHelper.routePathLabel(RoutePathEnum.SESSION_NOTES_LIST_NOTES)
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToSession = (studentId: string, history: History) => {
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.DATA_COLLECTION_SESSION
    );
    history.push(`/data/${studentId}/session`, state);
  };

  static redirectToProgramCreate = (studentId: string, history: History) => {
    const path = RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM
    );
    history.push(path, state);
  };

  static redirectToProgramEdit = (
    studentId: string,
    programId: string,
    type: ProgramTypeEnum,
    history: History
  ) => {
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM
    );
    history.push(`/data/${studentId}/edit/${type}/${programId}`, state);
  };

  static redirectToPhaseEdit = (
    studentId: string,
    phaseId: string,
    programId: string,
    type: ProgramTypeEnum,
    history: History,
    backLabel?: string
  ) => {
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM_PHASE,
      backLabel
    );
    history.push(
      `/data/${studentId}/edit/${type}/${programId}/${phaseId}`,
      state
    );
  };

  static redirectToAbcData = (
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.ABC_DATA_WITH_STUDENT.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_WITH_STUDENT
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToAbcDataViewWithProgram = (
    studentId: string,
    programId: string,
    history: History
  ) => {
    const path = RoutePathEnum.ABC_DATA_WITH_PROGRAM.replace(
      ":studentId",
      studentId
    ).replace(":programId", programId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_WITH_PROGRAM,
      LabelHelper.routePathLabel(RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS)
    );
    history.push(path, state);
  };

  static redirectToAbcDataView = (studentId: string, history: History) => {
    const path = RoutePathEnum.ABC_DATA_WITH_STUDENT.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_WITH_STUDENT,
      LabelHelper.routePathLabel(RoutePathEnum.ABC_DATA)
    );
    history.push(path, state);
  };

  static redirectToAbcDataCreate = (
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.ABC_DATA_CREATE.replace(":studentId", studentId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_CREATE,
      LabelHelper.routePathLabel(RoutePathEnum.ABC_DATA)
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToAbcDataCreateWithProgram = (
    studentId: string,
    programId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.ABC_DATA_CREATE_WITH_PROGRAM.replace(
      ":studentId",
      studentId
    ).replace(":programId", programId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_CREATE_WITH_PROGRAM
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToAbcDataEdit = (
    studentId: string,
    dataId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.ABC_DATA_EDIT.replace(
      ":studentId",
      studentId
    ).replace(":dataId", dataId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_EDIT,
      LabelHelper.routePathLabel(RoutePathEnum.ABC_DATA)
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToAbcDataEditWithProgram = (
    studentId: string,
    dataId: string,
    programId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.ABC_DATA_EDIT_WITH_PROGRAM.replace(
      ":studentId",
      studentId
    )
      .replace(":dataId", dataId)
      .replace(":programId", programId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ABC_DATA_EDIT_WITH_PROGRAM
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToCharts = (
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.CHARTS_VIEW_WITH_STUDENT.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CHARTS_VIEW_WITH_STUDENT
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToChart = (
    studentId: string,
    programId: string,
    history: History,
    replace: boolean = false,
    backLabel?: string
  ) => {
    const path = `/charts/${studentId}/${programId}`;
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CHARTS_VIEW_WITH_PROGRAM,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
    } else {
      history.push(path, state);
    }
  };

  static redirectToDataCollection(history: History) {
    history.push(RoutePathEnum.DATA_COLLECTION, RoutePathEnum.DATA_COLLECTION);
  }

  static redirectToStudentPrograms(studentId: string, history: History) {
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS
    );
    history.push(
      RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS.replace(
        ":studentId",
        studentId
      ),
      state
    );
  }

  static redirectToMaintenance = (
    studentId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.MAINTENANCE_LIST.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.MAINTENANCE_LIST
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToStaffProfile = (
    staffId: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE.replace(
      ":staffId",
      staffId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToStudentProfile = (
    studentId: string,
    history: History,
    replace: boolean = false,
    backLabel?: string
  ) => {
    const path = RoutePathEnum.PEOPLE_MANAGEMENT_STUDENT_PROFILE.replace(
      ":studentId",
      studentId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.PEOPLE_MANAGEMENT_STUDENT_PROFILE,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToArchive = (
    studentId: string,
    history: History,
    replace: boolean = false,
    backLabel?: string
  ) => {
    const path = RoutePathEnum.ARCHIVE_LIST.replace(":studentId", studentId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ARCHIVE_LIST,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
      return;
    }
    history.push(path, state);
  };

  static redirectToDefault = (history: History) => {
    history.push(RoutePathEnum.DEFAULT);
  };

  static redirectToNoAccess = (history: History) => {
    history.push(RoutePathEnum.ERROR_NO_ACCESS);
  };

  static redirectToNoStudentFound = (history: History) => {
    history.push(RoutePathEnum.ERROR_STUDENT_NOT_FOUND);
  };

  static redirectToOfflinePageUnavailable = (history: History) => {
    history.push(RoutePathEnum.ERROR_OFFLINE_PAGE_UNAVAILABLE);
  };

  static redirectToCurriculumLibrary = (history: History) => {
    const state = RouterHelper.updateState(history, RoutePathEnum.CURRICULUM);
    state.sideMenu = RoutePathEnum.CURRICULUM;
    history.push(RoutePathEnum.CURRICULUM, state);
  };

  static redirectToCurriculum = (
    curriculumId: string,
    history: History,
    backLabel?: string
  ) => {
    const path = RoutePathEnum.CURRICULUM_DEFAULT.replace(
      ":curriculumId",
      curriculumId
    );
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_DEFAULT,
      backLabel
    );
    history.push(path, state);
  };

  static redirectToCurriculumViewFolder = (
    curriculumId: string,
    folderId: string,
    history: History,
    replace: boolean = false,
    backLabel?: string
  ) => {
    const path = RoutePathEnum.CURRICULUM_VIEW_FOLDER.replace(
      ":curriculumId",
      curriculumId
    ).replace(":folderId", folderId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_VIEW_FOLDER,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
    } else {
      history.push(path, state);
    }
  };

  static redirectToCurriculumViewTemplate = (
    curriculumId: string,
    templateId: string,
    backLabel: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.CURRICULUM_VIEW_TEMPLATE.replace(
      ":curriculumId",
      curriculumId
    ).replace(":templateId", templateId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_VIEW_TEMPLATE,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
    } else {
      history.push(path, state);
    }
  };

  static redirectToCurriculumCreateTemplate = (
    curriculumId: string,
    folderId: string,
    backLabel: string,
    history: History
  ) => {
    const path = RoutePathEnum.CURRICULUM_CREATE_TEMPLATE.replace(
      ":curriculumId",
      curriculumId
    ).replace(":folderId", folderId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_CREATE_TEMPLATE,
      backLabel
    );
    history.push(path, state);
  };

  static redirectToCurriculumAddProgram = (
    curriculumId: string,
    programId: string,
    backLabel: string,
    history: History
  ) => {
    const path = RoutePathEnum.CURRICULUM_ADD_PROGRAM.replace(
      ":curriculumId",
      curriculumId
    ).replace(":programId", programId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_ADD_PROGRAM,
      backLabel
    );
    history.push(path, state);
  };

  static redirectToCurriculumAssignTemplate = (
    curriculumId: string,
    templateId: string,
    backLabel: string,
    history: History
  ) => {
    const path = RoutePathEnum.CURRICULUM_ASSIGN_TEMPLATE.replace(
      ":curriculumId",
      curriculumId
    ).replace(":templateId", templateId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_ASSIGN_TEMPLATE,
      backLabel
    );
    history.push(path, state);
  };

  static redirectToCurriculumUpload = (
    curriculumId: string,
    history: History
  ) => {
    const path = RoutePathEnum.CURRICULUM_UPLOAD.replace(
      ":curriculumId",
      curriculumId
    );
    history.push(path, history.location.state);
  };

  static redirectToCurriculumLibrarySearch = (
    text: string,
    history: History,
    replace: boolean = false
  ) => {
    const path = RoutePathEnum.CURRICULUM_LIBRARY_SEARCH.replace(":text", text);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_LIBRARY_SEARCH,
      "Curriculum Library"
    );
    if (replace) {
      history.replace(path, state);
    } else {
      history.push(path, state);
    }
  };

  static redirectToCurriculumFolderSearch = (
    text: string,
    curriculumId: string,
    folderId: string,
    history: History,
    replace: boolean = false,
    backLabel?: string
  ) => {
    const path = RoutePathEnum.CURRICULUM_FOLDER_SEARCH.replace(":text", text)
      .replace(":curriculumId", curriculumId)
      .replace(":folderId", folderId);
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.CURRICULUM_FOLDER_SEARCH,
      backLabel
    );
    if (replace) {
      history.replace(path, state);
    } else {
      history.push(path, state);
    }
  };

  static redirectToPeopleManagement = (history: History) => {
    history.push(RoutePathEnum.PEOPLE_MANAGEMENT, {
      sideMenu: RoutePathEnum.PEOPLE_MANAGEMENT,
      current: RoutePathEnum.PEOPLE_MANAGEMENT
    });
  };

  static redirectToAddPeople = (history: History) => {
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.PEOPLE_MANAGEMENT_CREATE
    );
    history.push(RoutePathEnum.PEOPLE_MANAGEMENT_CREATE, state);
  };

  static redirectToMyProfile = (staffId: string, history: History) => {
    const path = RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE.replace(
      ":staffId",
      staffId
    );
    let state = RouterHelper.updateState(
      history,
      RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE
    );
    state.sideMenu = RoutePathEnum.NONE;
    history.push(path, state);
  };

  static redirectToOrganizationViewProfile = (history: History) => {
    const path = RoutePathEnum.ORGANIZATION_VIEW_PROFILE;
    let state = RouterHelper.updateState(
      history,
      RoutePathEnum.ORGANIZATION_VIEW_PROFILE
    );
    state.sideMenu = RoutePathEnum.NONE;
    history.push(path, state);
  };

  static redirectToOrganizationViewSettings = (history: History) => {
    const path = RoutePathEnum.ORGANIZATION_VIEW_SETTINGS;
    let state = RouterHelper.updateState(
      history,
      RoutePathEnum.ORGANIZATION_VIEW_SETTINGS
    );
    state.sideMenu = RoutePathEnum.NONE;
    history.push(path, state);
  };

  static redirectToOrganizationEditProfile = (history: History) => {
    const path = RoutePathEnum.ORGANIZATION_EDIT_PROFILE;
    const state = RouterHelper.updateState(
      history,
      RoutePathEnum.ORGANIZATION_EDIT_PROFILE
    );
    history.push(path, state);
  };

  static mapPathToRoute = (path: string) => {
    const match = Object.keys(RoutePathEnum).find(key => {
      return (
        matchPath(path, {
          path: RoutePathEnum[key],
          exact: true
        }) != null
      );
    });
    if (match) {
      return RoutePathEnum[match];
    } else {
      return RoutePathEnum.NONE;
    }
  };

  static defaultParentOfRoute = (route: RoutePathEnum) => {
    switch (route) {
      // Plan a Session
      case RoutePathEnum.DATA_COLLECTION_CREATE_PROGRAM:
      case RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM:
      case RoutePathEnum.DATA_COLLECTION_SESSION:
      case RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM_PHASE:
        return RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS;

      // My Student Data
      case RoutePathEnum.PEOPLE_MANAGEMENT_STUDENT_PROFILE:
      case RoutePathEnum.CHARTS_VIEW_WITH_PROGRAM:
      case RoutePathEnum.CHARTS_VIEW_WITH_STUDENT:
      case RoutePathEnum.SESSION_NOTES_LIST_NOTES:
      case RoutePathEnum.ABC_DATA_WITH_STUDENT:
      case RoutePathEnum.MAINTENANCE_LIST:
      case RoutePathEnum.ARCHIVE_LIST:
        return RoutePathEnum.MY_STUDENT_LIST;

      // Contacts
      case RoutePathEnum.PEOPLE_MANAGEMENT_STAFF_PROFILE:
      case RoutePathEnum.PEOPLE_MANAGEMENT_CREATE:
        return RoutePathEnum.PEOPLE_MANAGEMENT;

      // Curriculum
      //case RoutePathEnum.CURRICULUM_VIEW_TEMPLATE:
      //case RoutePathEnum.CURRICULUM_CREATE_TEMPLATE:
      case RoutePathEnum.CURRICULUM_VIEW_FOLDER:
      case RoutePathEnum.CURRICULUM_UPLOAD:
        return RoutePathEnum.CURRICULUM;

      default:
        return RoutePathEnum.NONE;
    }
  };
}
