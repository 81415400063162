import { useEffect } from "react";
import { AuthenticationService } from "./AuthenticationService";

export interface IRenewCallbackProps {
  onSuccess?: () => void;
  onError?: (err: any) => void;
}
export const RenewCallback = (props: IRenewCallbackProps) => {
  useEffect(() => {
    const { onSuccess, onError } = props;
    const authService = AuthenticationService.getInstance();

    authService.userManager
      .signinSilentCallback()
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(err => {
        if (onError) {
          onError(err);
        }
      });
  }, [props]);

  return null;
};
