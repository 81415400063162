import React, { useState } from "react";
import {
  Form,
  FormItem,
  Heading,
  Notification,
  Input,
  Modal,
  Radio,
  RadioGroup
} from "@raudabaugh/thread-ui";
import { LabelHelper } from "../../Shared/LabelHelper";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import { ClinicalFileFragment } from "Shared/Api/ClinicalFileFragment";
import { FileHelper } from "Shared/FileHelper";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import {
  ClinicalFileInput,
  useClinicalFileUploadMutation
} from "DataAccess/ClinicalFileData";
import { ClinicalFileTypeEnum } from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IStudentFileUploadProps {
  studentId: string;
  files: ClinicalFileFragment[];
  onComplete: () => void;
}

export const StudentFileUpload = (props: IStudentFileUploadProps) => {
  const { threadUserContext } = useThreadContext();
  const [name, setName] = useState<string>("");
  const [originalName, setOriginalName] = useState<string>("");
  const [area, setArea] = useState<ClinicalFileTypeEnum>(
    ClinicalFileTypeEnum.NONE
  );
  const [otherText, setOtherText] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [fileKey, setFileKey] = useState(Date.now());
  const [validationFailed, setValidationFailed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const formItemLayout = {
    labelCol: {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    },
    wrapperCol: {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    }
  };

  const { clinicalFileUpload } = useClinicalFileUploadMutation();

  const handleSubmit = async () => {
    if (!file) {
      return;
    }
    const failed = validateForm() !== undefined;
    setValidationFailed(failed);
    if (failed) {
      return;
    }
    let contentType: string | undefined;
    if (FileHelper.getFileExtension(name)) {
      contentType = FileHelper.deriveContentType(name);
    } else {
      contentType = FileHelper.deriveContentType(originalName);
    }
    const input: ClinicalFileInput = {
      name,
      fileArea: area,
      fileAreaOther: otherText,
      contentType: contentType ?? "",
      createdByName: threadUserContext.userName,
      createdById: threadUserContext.userId
    };
    setUploading(true);
    try {
      await clinicalFileUpload(props.studentId, input, file);
      Notification.success({
        duration: 0,
        message: "File successfully uploaded.",
        description: "Your clinical file has been successfully uploaded."
      });
      setName("");
      setArea(ClinicalFileTypeEnum.NONE);
      setFile(undefined);
      setFileKey(Date.now());
      props.onComplete();
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "File Upload Failed",
        description: error.message
      });
    } finally {
      setUploading(false);
    }
  };

  const validateForm = () => {
    return validateName() || validateArea() || validateFile();
  };

  const validateName = () => {
    if (name.trim().length === 0) {
      return "Name is required";
    }
    let extension = FileHelper.getFileExtension(name);
    if (extension && name.trim() !== originalName.trim()) {
      if (!FileHelper.allExtensions.find(e => e === extension)) {
        return "We don't currently support this file type for security purposes";
      }
    }

    return undefined;
  };

  const validateArea = () => {
    if (area.length === 0 || area === ClinicalFileTypeEnum.NONE) {
      return "Selection is required";
    }
    const count = props.files.filter(f => f.fileType === area).length;
    if (count >= 5) {
      return `We are currently limiting users to 5 '${LabelHelper.clinicalFileAreaLabel(
        area
      )}' files. Please delete old files so that you can upload new ones.  Only Administrators and Supervisors can delete files.`;
    }
    return undefined;
  };

  const validateFile = () => {
    if (!file) {
      return "Upload file is required";
    }
    if (file.type && !FileHelper.allContentTypes.find(t => t === file.type)) {
      return "We don't currently support this file type for security purposes.";
    }
    if (name.trim() === originalName.trim()) {
      const extension = FileHelper.getFileExtension(name);
      if (!FileHelper.allExtensions.find(e => e === extension)) {
        return "We don't currently support this file type for security purposes";
      }
    }
    if (file.size > 30000000) {
      return "The file size limit is 30 MB.  Please try compressing your file and uploading again.";
    }

    return undefined;
  };

  const accept =
    FileHelper.allExtensions.join() + "," + FileHelper.allContentTypes.join();

  const areas = [
    ClinicalFileTypeEnum.BEHAVIOR_INTERVENTION_PLAN,
    ClinicalFileTypeEnum.INDIVIDUALIZED_EDUCATION_PLAN,
    ClinicalFileTypeEnum.THREAD_REPORTS,
    ClinicalFileTypeEnum.OTHER
  ];
  return (
    <Modal
      title="Upload a new Clinical File"
      visible={true}
      okText="Upload"
      onOk={() => handleSubmit()}
      onCancel={() => props.onComplete()}
    >
      <LoadingScreen tip="Uploading..." loading={uploading} />

      <Form onFinish={handleSubmit}>
        <FormItem required={true} {...formItemLayout} label="File to Upload">
          <Input
            key={fileKey}
            size="large"
            type="file"
            accept={accept}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target?.files && e.target.files.length > 0) {
                setFile(e.target.files[0]);
                setName(e.target.files[0].name);
                setOriginalName(e.target.files[0].name);
              }
            }}
          />
          {validationFailed && validateFile() && (
            <Heading color="error" level={5}>
              {validateFile()}
            </Heading>
          )}
        </FormItem>
        <FormItem required={true} {...formItemLayout} label="File Category">
          <RadioGroup value={area}>
            {areas.map(a => (
              <Radio
                value={a}
                block={true}
                onChange={event => event.target.checked && setArea(a)}
              >
                {LabelHelper.clinicalFileAreaLabel(a)}
              </Radio>
            ))}
          </RadioGroup>
          {validationFailed && validateArea() && (
            <Heading color="error" level={5}>
              {validateArea()}
            </Heading>
          )}
        </FormItem>
        {area === ClinicalFileTypeEnum.OTHER && (
          <FormItem
            {...formItemLayout}
            label="Tell us what you are using this file for and we will consider adding a new category to a future version of Thread"
          >
            <Input
              size="large"
              maxLength={50}
              value={otherText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOtherText(e.target.value)
              }
            />
          </FormItem>
        )}
        <FormItem required={true} label="File Name" {...formItemLayout}>
          <Input
            size="large"
            maxLength={80}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
          {validationFailed && validateName() && (
            <Heading color="error" level={5}>
              {validateName()}
            </Heading>
          )}
        </FormItem>
      </Form>
    </Modal>
  );
};
