import React, { useCallback, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import { RouterHelper } from "./RouterHelper";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { DefaultStudent } from "./DefaultStudent";
import { Charts } from "Charts/Charts";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";
import { LabelHelper } from "Shared/LabelHelper";
import { useProgramSessionsQuery } from "DataAccess/ProgramData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { LoadingScreen } from "Shared/LoadingScreen";
import _ from "lodash";

// Charting

interface IChartsParams {
  programId: string;
}

const LocateChart = () => {
  const { studentId } = useStudentContext();
  const history = useHistory();
  const { error: programSessionsError, data: programSessionsData } =
    useProgramSessionsQuery(studentId!);
  if (programSessionsError) {
    NotificationsHelper.ApolloErrorNotification({
      error: programSessionsError,
      title: "Failed to access student charts"
    });
  }
  const sessions = programSessionsData?.programSessions;
  if (sessions) {
    const programs = _.cloneDeep(sessions).sort((ps1, ps2) =>
      (ps1.program.name || "").localeCompare(ps2!.program.name || "")
    );
    const programId = programs.length > 0 ? programs[0].id : "nocharts";
    RouterHelper.redirectToChart(studentId!, programId, history, true);
  }
  return <LoadingScreen />;
};

const ViewCharts = () => {
  const history = useHistory();
  const { programId } = useParams<IChartsParams>();
  const { studentId, setStudentId } = useStudentContext();

  const handleSelectProgram = useCallback(
    (programId: string) =>
      RouterHelper.redirectToChart(studentId || "", programId, history, true),
    [history, studentId]
  );

  useEffect(() => {
    setStudentId();
  }, [setStudentId]);

  if (studentId) {
    return (
      <Charts
        currentStudentId={studentId}
        currentProgramId={programId}
        onSelectProgram={handleSelectProgram}
        onEditPhase={(phaseId: string, type: ProgramTypeEnum) =>
          RouterHelper.redirectToPhaseEdit(
            studentId,
            phaseId,
            programId,
            type,
            history,
            LabelHelper.routePathLabel(RoutePathEnum.CHARTS)
          )
        }
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutePathEnum.CHARTS,
          state: {
            sideMenu: RoutePathEnum.CHARTS,
            current: RoutePathEnum.CHARTS
          }
        }}
      />
    );
  }
};

interface ChartsRouterProps {}

export const ChartsRouter = (props: ChartsRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.CHARTS_VIEW_WITH_PROGRAM} exact={true}>
        <ViewCharts />
      </Route>
      <Route path={RoutePathEnum.CHARTS_VIEW_WITH_STUDENT} exact={true}>
        <LocateChart />
      </Route>
      <Route path={RoutePathEnum.CHARTS}>
        <DefaultStudent path={RoutePathEnum.CHARTS_VIEW_WITH_STUDENT} />
      </Route>
    </Switch>
  );
};
