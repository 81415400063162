import { ApolloCache, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { v4 as uuid, v5 as deterministicUuid } from "uuid";
import _ from "lodash";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import {
  dataPointScoreDuration,
  dataPointScoreDurationVariables
} from "Shared/Api/dataPointScoreDuration";
import {
  dataPointStartDuration,
  dataPointStartDurationVariables
} from "Shared/Api/dataPointStartDuration";
import {
  dataPointStopDuration,
  dataPointStopDurationVariables
} from "Shared/Api/dataPointStopDuration";
import {
  dataPointUndoDuration,
  dataPointUndoDurationVariables
} from "Shared/Api/dataPointUndoDuration";
import {
  DataPointStateEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { PromptHelper } from "Shared/PromptHelper";
import { FRAGMENT_DATA_POINT, FRAGMENT_SESSION } from "./Fragments";
import moment from "moment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { TrialFragment } from "Shared/Api/TrialFragment";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { refetchProgramSessionsQuery } from "./ProgramData";
import { useCallback } from "react";

const MUTATION_UNDO_DURATION = gql`
  mutation dataPointUndoDuration($pointId: GUID!, $studentId: GUID!) {
    dataPointUndoTrial(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_START_DURATION = gql`
  mutation dataPointStartDuration(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $occurredAt: DateTime!
  ) {
    dataPointStart(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      trialId: $trialId
      result: PLUS
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
      autoRecordDataPoints {
        ...DataPointFragment
      }
      autoRecordSessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_SESSION}
`;

const MUTATION_STOP_DURATION = gql`
  mutation dataPointStopDuration(
    $pointId: GUID!
    $studentId: GUID!
    $duration: Int!
  ) {
    dataPointStop(
      pointId: $pointId
      studentId: $studentId
      duration: $duration
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SCORE_DURATION = gql`
  mutation dataPointScoreDuration(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $score: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: 1
      attempted: 0
      duration: $score
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

export const useDataPointUndoDurationMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointUndoDuration,
    dataPointUndoDurationVariables
  >(MUTATION_UNDO_DURATION);

  const handleDataPointUndoDuration = useCallback(
    (pointId: string, studentId: string, createdById: string) => {
      const optimisticResponse = buildUndoDurationOptimisticResponse(
        client.cache,
        pointId
      );
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId }
      });
    },
    [mutate, client.cache]
  );
  return {
    dataPointUndoDuration: handleDataPointUndoDuration,
    error,
    data
  };
};

const buildUndoDurationOptimisticResponse = (
  cache: ApolloCache<object>,
  pointId: string
) => {
  const point = DataPointDataHelper.readDataPoint(cache, pointId, true);
  if (point == null) {
    throw new Error("Unable to locate data point to undo");
  }
  point.trials.pop();
  const optimisticResponse: dataPointUndoDuration = {
    dataPointUndoTrial: {
      __typename: "DataPointOutput",
      dataPoint: point
    }
  };
  return optimisticResponse;
};

export const useDataPointStartDurationMutation = () => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const [mutate, { client, error, data }] = useMutation<
    dataPointStartDuration,
    dataPointStartDurationVariables
  >(MUTATION_START_DURATION);

  const handleDataPointStartDuration = useCallback(
    (
      programId: string,
      phaseId: string,
      studentId: string,
      createdById: string,
      createdByName: string
    ) => {
      const trialId = uuid();
      const now = moment();
      const today = now.clone().startOf("day");
      const pointId = deterministicUuid(today.format("YYYY-MM-DD"), phaseId);
      const point = readDataPointAndAddTrial(
        client.cache,
        studentId,
        pointId,
        phaseId,
        programId,
        trialId,
        now,
        createdById,
        createdByName
      );
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointStartDuration | undefined = undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          id: `DataPointType:${pointId}`,
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointStart: {
            __typename: "DataPointRecordOutput",
            dataPoint: point,
            autoRecordDataPoints: null,
            autoRecordSessions: null
          }
        };
        DataPointDataHelper.autoRecordOptimisticResponse(
          client.cache,
          studentId,
          threadUserContext.userId,
          threadUserContext.userName,
          optimisticResponse.dataPointStart!
        );
      }
      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          phaseId,
          studentId,
          trialId,
          occurredAt: now.utc().toISOString()
        },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointStart?.dataPoint;
          if (point) {
            DataPointDataHelper.updateSessionDataPoint(cache, point);
          }
          const output = data?.dataPointStart;
          if (output) {
            DataPointDataHelper.updateChartDataPointsWithAutoRecordOutput(
              cache,
              output
            );
          }
        }
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [
      mutate,
      client,
      online,
      threadUserContext.userId,
      threadUserContext.userName
    ]
  );
  return {
    dataPointStartDuration: handleDataPointStartDuration,
    error,
    data
  };
};

export const useDataPointStopDurationMutation = () => {
  const online = useOnlineStatus();
  const [mutate, { client, error, data }] = useMutation<
    dataPointStopDuration,
    dataPointStopDurationVariables
  >(MUTATION_STOP_DURATION);

  const handleDataPointStopDuration = useCallback(
    (pointId: string, studentId: string, duration: number) => {
      const point = readDataPointAndUpdateTrial(
        client.cache,
        pointId,
        duration
      );
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointStopDuration | undefined = undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          id: `DataPointType:${pointId}`,
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointStop: {
            __typename: "DataPointOutput",
            dataPoint: point
          }
        };
      }
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, duration },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointStop?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [mutate, client, online]
  );
  return {
    dataPointStopDuration: handleDataPointStopDuration,
    error,
    data
  };
};

const readDataPointAndAddTrial = (
  cache: ApolloCache<object>,
  studentId: string,
  pointId: string,
  phaseId: string,
  programId: string,
  trialId: string,
  occurredAt: moment.Moment,
  createdById: string,
  createdByName: string
) => {
  const point: DataPointFragment = DataPointDataHelper.readCreateDataPoint(
    cache,
    studentId,
    programId,
    ProgramTypeEnum.DURATION,
    phaseId,
    pointId,
    occurredAt,
    createdById,
    createdByName,
    DataPointStateEnum.COMPLETED
  );

  const trial: TrialFragment = {
    __typename: "TrialType",
    id: trialId,
    index: point.trials.length,
    result: TrialResultEnum.PLUS,
    prompt: PromptHelper.PROMPT_INDEPENDENT,
    stepId: null,
    targetId: null,
    occurredAt: occurredAt.toISOString(),
    duration: null,
    createdById,
    createdByName
  };
  point.trials = [...point.trials, trial];
  return point;
};

const readDataPointAndUpdateTrial = (
  cache: ApolloCache<object>,
  pointId: string,
  duration: number,
  optimistic: boolean = true
) => {
  const point: DataPointFragment | null = _.cloneDeep(
    cache.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`,
      optimistic
    })
  );
  if (!point) {
    throw Error("Could not find data point");
  }
  const trial = point.trials[point.trials.length - 1];
  trial.duration = duration;
  return point;
};

export const useDataPointScoreDurationMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointScoreDuration,
    dataPointScoreDurationVariables
  >(MUTATION_SCORE_DURATION);

  const handleDataPointScoreDuration = useCallback(
    (
      programId: string,
      phaseId: string,
      studentId: string,
      score: number,
      occurredAt: DateTime
    ) => {
      const day = moment(occurredAt).startOf("day");
      const pointId = deterministicUuid(day.format("YYYY-MM-DD"), phaseId);
      return mutate!({
        variables: { pointId, phaseId, studentId, score, occurredAt },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate]
  );
  return {
    dataPointScoreDuration: handleDataPointScoreDuration,
    error,
    data
  };
};

export const buildEndDurationDataPointFragment = (
  cache: ApolloCache<object>,
  pointId: string,
  occurredAt: string
) => {
  const point = DataPointDataHelper.readDataPoint(cache, pointId, true);
  if (!point) {
    throw new Error("Unable to locate data point to end");
  }
  const activeTrial = point.trials.find(i => i.duration === null);
  if (activeTrial) {
    const trialStart = moment(activeTrial.occurredAt).toDate().valueOf();
    const trialEnd = moment(occurredAt).toDate().valueOf();
    activeTrial.duration = Math.floor((trialEnd - trialStart) / 1000);
  }
  point.completedAt = occurredAt;
  point.state = DataPointStateEnum.COMPLETED;
  return point;
};
