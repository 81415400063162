import { ApolloCache, gql, useMutation } from "@apollo/client";
import {
  targetUpdateCounters,
  targetUpdateCountersVariables
} from "../Shared/Api/targetUpdateCounters";
import { TargetAction } from "../Shared/Api/globalTypes";
import _ from "lodash";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { refetchProgramSessionsQuery } from "./ProgramData";
import { useCallback } from "react";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { FRAGMENT_PROGRAM } from "./Fragments";

/* MUTATIONS */
const MUTATION_UPDATE_TARGET_COUNTERS = gql`
  mutation targetUpdateCounters(
    $studentId: GUID!
    $programId: GUID!
    $targetId: GUID!
    $action: TargetAction!
  ) {
    targetUpdateCounters(
      studentId: $studentId
      programId: $programId
      targetId: $targetId
      action: $action
    ) {
      program {
        ...ProgramFragment
      }
    }
  }
  ${FRAGMENT_PROGRAM}
`;

export const useUpdateTargetMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    targetUpdateCounters,
    targetUpdateCountersVariables
  >(MUTATION_UPDATE_TARGET_COUNTERS);
  const online = useOnlineStatus();

  const handleTargetUpdateCounters = useCallback(
    (
      studentId: string,
      programId: string,
      targetId: string,
      action: TargetAction
    ) => {
      const program = readAndUpdateTarget(
        client.cache,
        programId,
        targetId,
        action
      );
      if (program == null) {
        throw new Error("Unable to locate target");
      }
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: targetUpdateCounters | undefined = undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_PROGRAM,
          fragmentName: "ProgramFragment",
          id: `ProgramType:${programId}`,
          data: program
        });
      } else {
        optimisticResponse = {
          targetUpdateCounters: {
            __typename: "TargetOutput",
            program
          }
        };
      }

      return mutate!({
        optimisticResponse,
        variables: { studentId, programId, targetId, action },
        fetchPolicy
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [mutate, client, online]
  );

  return { targetUpdateCounters: handleTargetUpdateCounters, error, data };
};

const readAndUpdateTarget = (
  cache: ApolloCache<object>,
  programId: string,
  targetId: string,
  action: TargetAction,
  optimistic: boolean = true
) => {
  const program = _.cloneDeep(
    cache.readFragment<ProgramFragment>({
      fragment: FRAGMENT_PROGRAM,
      fragmentName: "ProgramFragment",
      id: `ProgramType:${programId}`,
      optimistic
    })
  );
  if (program == null) {
    return null;
  }
  const target = program.targets.find(t => t.id === targetId);
  if (target == null) {
    return null;
  }
  switch (action) {
    case TargetAction.UPDATE_MINUS_COUNTER:
      target.maintenanceMinusCount++;
      break;
    case TargetAction.UPDATE_PLUS_COUNTER:
      target.maintenancePlusCount++;
      break;
    case TargetAction.RESET_COUNTERS:
      target.maintenanceMinusCount = 0;
      target.maintenancePlusCount = 0;
      break;
    case TargetAction.COMPLETE:
      target.completed = true;
  }
  return program;
};
