import {
  IProgramsFromArchive_programsFromArchive_edges_node,
  IProgramsFromArchive_programsFromArchive_edges
} from "../../Shared/Api/IProgramsFromArchive";
import compose from "@shopify/react-compose";
import moment from "moment";
import React from "react";
import {
  Button,
  Dropdown,
  Heading,
  IClickParam,
  IColumnProps,
  Icon,
  Link,
  Menu,
  MenuItem,
  Row,
  Spin,
  Table
} from "@raudabaugh/thread-ui";
import { LabelHelper } from "../../Shared/LabelHelper";
import { findPermission } from "../../Shared/RolesMap";
import {
  ArchiveTypeEnum,
  ProgramTypeEnum,
  StaffRoleEnum,
  Permissions
} from "../../Shared/Api/globalTypes";
import { XamarinHelper } from "Shared/XamarinHelper";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface IArchivedProgramsTableBaseProps {
  onLoadData: () => void;
  onChange: (pagination: any, filter: any, sorter: any) => void;
  onDeleteClick: (
    record: IProgramsFromArchive_programsFromArchive_edges_node
  ) => void;
  onNameClick: (
    record: IProgramsFromArchive_programsFromArchive_edges_node
  ) => void;
  onUnarchiveButtonClick: (
    record: IProgramsFromArchive_programsFromArchive_edges_node
  ) => void;
  data: IProgramsFromArchive_programsFromArchive_edges[];
  hasMore: boolean;
  role: StaffRoleEnum;
}

type IArchivedProgramsTableProps = IArchivedProgramsTableBaseProps;

const statusColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
  {
    dataIndex: "archiveType",
    key: "archiveType",
    title: "Status",
    sorter: true,
    onFilter: (value, record) => {
      return record.node.archiveType === value;
    },
    filterMultiple: false,
    filters: [
      {
        text: LabelHelper.archiveTypeLabel(ArchiveTypeEnum.MASTERED),
        value: ArchiveTypeEnum.MASTERED
      },
      {
        text: LabelHelper.archiveTypeLabel(ArchiveTypeEnum.ON_HOLD),
        value: ArchiveTypeEnum.ON_HOLD
      },
      {
        text: LabelHelper.archiveTypeLabel(ArchiveTypeEnum.DISCONTINUED),
        value: ArchiveTypeEnum.DISCONTINUED
      }
    ],
    render: (
      _text: any,
      record: IProgramsFromArchive_programsFromArchive_edges,
      _: number
    ) => {
      return <>{LabelHelper.archiveTypeLabel(record!.node!.archiveType)}</>;
    }
  };

const lastDataPointColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
  {
    dataIndex: "lastRun",
    key: "lastRun",
    title: "Last Data Point",
    sorter: true,
    render: (
      _text: any,
      record: IProgramsFromArchive_programsFromArchive_edges,
      _: number
    ) => {
      if (record!.node!.lastRun) {
        return (
          <>
            {moment
              .utc(record!.node!.lastRun)
              .local()
              .format("ddd, MMM. D, YYYY \\at h:mm A")}
          </>
        );
      }
      return "Never Used";
    }
  };

const typeColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
  {
    dataIndex: "type",
    key: "type",
    title: "Type",
    sorter: true,
    onFilter: (value, record) => {
      return record.node.type === value;
    },
    filterMultiple: false,
    filters: [
      {
        text: LabelHelper.programTypeLabel(ProgramTypeEnum.DTT),
        value: ProgramTypeEnum.DTT
      },
      {
        text: LabelHelper.programTypeLabel(ProgramTypeEnum.TASK_ANALYSIS),
        value: ProgramTypeEnum.TASK_ANALYSIS
      },
      {
        text: LabelHelper.programTypeLabel(ProgramTypeEnum.DURATION),
        value: ProgramTypeEnum.DURATION
      },
      {
        text: LabelHelper.programTypeLabel(ProgramTypeEnum.FREQUENCY),
        value: ProgramTypeEnum.FREQUENCY
      },
      {
        text: LabelHelper.programTypeLabel(ProgramTypeEnum.INTERVAL),
        value: ProgramTypeEnum.INTERVAL
      }
    ],
    render: (
      _text: any,
      record: IProgramsFromArchive_programsFromArchive_edges,
      _: number
    ) => {
      return <>{LabelHelper.programTypeLabel(record!.node!.type)}</>;
    }
  };

const ArchivedProgramsTableComponent = (props: IArchivedProgramsTableProps) => {
  const defineRecord = (
    record: IProgramsFromArchive_programsFromArchive_edges
  ) => {
    return record!.node!.id!;
  };

  const stopEventPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleMenuClick = (
    p: IClickParam,
    record: IProgramsFromArchive_programsFromArchive_edges
  ) => {
    p.domEvent.stopPropagation();
    switch (p.key) {
      case "delete":
        props.onDeleteClick && props.onDeleteClick(record.node);
        break;
    }
  };

  const unarchiveColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
    {
      dataIndex: "unarchive",
      key: "unarchive",
      title: "",
      sorter: false,
      render: (
        _text: any,
        record: IProgramsFromArchive_programsFromArchive_edges,
        _: number
      ) => {
        return (
          <Button
            type="primary"
            ghost
            size="small"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              props.onUnarchiveButtonClick(record.node);
              event.stopPropagation();
            }}
          >
            Unarchive
          </Button>
        );
      }
    };

  const programNameColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
    {
      dataIndex: "title",
      key: "title",
      title: "Program Name",
      sorter: true,
      render: (
        _text: any,
        record: IProgramsFromArchive_programsFromArchive_edges,
        _: number
      ) => {
        const name = record!.node!.name! || "";
        return XamarinHelper.insideXamarin() ? (
          <Heading id={name.replace(/\s/g, "")} level={6} color="primary">
            {name}
          </Heading>
        ) : (
          <Link onClick={() => props.onNameClick(record.node)}>
            <Heading id={name.replace(/\s/g, "")} level={6} color="primary">
              {name}
            </Heading>
          </Link>
        );
      }
    };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: props.hasMore,
    onLoadMore: props.onLoadData,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px"
  });

  const adminMenuColumn: IColumnProps<IProgramsFromArchive_programsFromArchive_edges> =
    {
      dataIndex: "admin",
      key: "admin",
      title: "",
      sorter: false,
      render: (
        _text: any,
        record: IProgramsFromArchive_programsFromArchive_edges,
        _: number
      ) => {
        const actions = (
          <Menu
            onClick={e => {
              handleMenuClick(e, record);
            }}
            selectable={false}
            mode="vertical"
          >
            <MenuItem key="delete">
              <Icon type="fa-trash fas" width="28px"></Icon>Delete
            </MenuItem>
          </Menu>
        );
        return (
          <Dropdown trigger={["click"]} overlay={actions}>
            <Button
              onClick={stopEventPropagation}
              ghost={true}
              borderless={true}
            >
              <Icon color="default" variation={10} type="fa-ellipsis-v fas" />
            </Button>
          </Dropdown>
        );
      }
    };

  const columns: Array<
    IColumnProps<IProgramsFromArchive_programsFromArchive_edges>
  > = [
    programNameColumn,
    statusColumn,
    lastDataPointColumn,
    typeColumn,
    unarchiveColumn
  ];

  const deleteFromArchive = findPermission(
    props.role,
    Permissions.SOFT_DELETE_FROM_ARCHIVE
  );

  if (deleteFromArchive) {
    columns.push(adminMenuColumn);
  }

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        dataSource={props.data}
        rowKey={defineRecord}
        onChange={props.onChange}
        loading={props.hasMore && props.data.length === 0}
      />
      {props.data && props.data.length > 0 && props.hasMore && (
        <Row
          innerRef={sentryRef as unknown as React.RefObject<any>}
          justify="center"
          type="flex"
        >
          <Spin />
        </Row>
      )}
    </>
  );
};

export const ArchivedProgramsTable = compose<IArchivedProgramsTableProps>()(
  ArchivedProgramsTableComponent
);
