import { User } from "oidc-client";
import { v4 } from "uuid";
import { version } from "../Shared/Version";

export interface XamarinInfo {
  build: string;
  version: string;
  platform: string;
}

export class XamarinHelper {
  // injected version helps us workaround no top level await in our modules
  static injectedVersion: string | null = null;
  static injectedMajorVersion: number = 0;
  static injectedMinorVersion: number = 0;

  static init() {
    if (XamarinHelper.insideAndroid()) {
      // android had no way to guarantee order of javascript evaluation on page finished, so we put it in jsBridge
      XamarinHelper.injectedVersion =
        window["jsBridge"].threadXamarinVersion?.();
    }

    if (XamarinHelper.insideIOS()) {
      // ios messagehandlers can't synchronously return results before ios 14 but we do have guaranteed user script loading at document start
      XamarinHelper.injectedVersion = window["threadXamarinVersion"] ?? null;
    }

    if (XamarinHelper.injectedVersion) {
      try {
        const [majorStr, minorStr] = XamarinHelper.injectedVersion.split(".");

        XamarinHelper.injectedMajorVersion = parseInt(majorStr, 10) ?? 0;
        XamarinHelper.injectedMinorVersion = parseInt(minorStr, 10) ?? 0;
      } catch (e) {
        console.error(
          "errors parsing injected version",
          XamarinHelper.injectedVersion,
          e
        );
      }
    }
  }

  public static insideXamarin(): boolean {
    return XamarinHelper.insideAndroid() || XamarinHelper.insideIOS();
  }

  public static insideAndroid(): boolean {
    return window["jsBridge"];
  }

  public static insideIOS(): boolean {
    return window["webkit"] && window["webkit"].messageHandlers;
  }

  // injected versions added in 2.1.0, will never return true before that
  public static checkMinimumInjectedVersion(
    minMajor: number,
    minMinor: number
  ): boolean {
    const major = XamarinHelper.injectedMajorVersion;
    const minor = XamarinHelper.injectedMinorVersion;

    return major > minMajor || (major === minMajor && minor >= minMinor);
  }

  public static kvcacheSupported(): boolean {
    return XamarinHelper.checkMinimumInjectedVersion(2, 1);
  }

  public static registerInvokeActionFunction() {
    if (XamarinHelper.insideAndroid()) {
      window["invokeXamarinAction"] = (data: object) => {
        window["jsBridge"].invokeAction(data);
      };
    }
    if (XamarinHelper.insideIOS()) {
      window["invokeXamarinAction"] = (data: object) => {
        window["webkit"].messageHandlers.invokeAction.postMessage(data);
      };
    }
  }

  public static invokeAction(action: string, data: object) {
    if (XamarinHelper.insideXamarin()) {
      const message = { action, ...data };
      window["invokeXamarinAction"](JSON.stringify(message));
    }
  }

  public static addEventListener(
    eventType: string,
    listener: { (evt: CustomEvent): void },
    once: boolean
  ) {
    const options: AddEventListenerOptions = { once };
    window.addEventListener(eventType, listener as EventListener, options);
  }

  public static sendPwaInfo(): void {
    XamarinHelper.invokeAction("pwa-info", { version: version });
  }

  public static getXamarinInfo(): Promise<XamarinInfo> {
    const promise = new Promise<any>(resolve => {
      XamarinHelper.addEventListener(
        "get-xamarin-info",
        event => {
          resolve(JSON.parse(event.detail));
        },
        true
      );
    });
    XamarinHelper.invokeAction("get-xamarin-info", {});
    return promise;
  }

  public static getXamarinUser(): Promise<User | null> {
    const promise = new Promise<any>(resolve => {
      XamarinHelper.addEventListener(
        "get-xamarin-user",
        event => {
          const user = JSON.parse(event.detail);
          window.sessionStorage.setItem("token", user.access_token);
          this.addXamarinRefreshTokenListener();
          resolve(user);
        },
        true
      );
    });
    XamarinHelper.invokeAction("get-xamarin-user", {});
    return promise;
  }

  public static loginXamarinUser(): Promise<User | null> {
    const promise = new Promise<any>(resolve => {
      XamarinHelper.addEventListener(
        "login-xamarin-user",
        event => {
          const user = JSON.parse(event.detail);
          window.sessionStorage.setItem("token", user.access_token);
          resolve(user);
        },
        true
      );
    });
    XamarinHelper.invokeAction("login-xamarin-user", {});
    return promise;
  }

  public static addXamarinRefreshTokenListener(): void {
    XamarinHelper.addEventListener(
      "refresh-xamarin-auth-token",
      event => {
        const message = JSON.parse(event.detail);
        window.sessionStorage.setItem("token", message.access_token);
      },
      false
    );
  }

  public static viewDocument(
    url: string,
    description: string,
    authorization: string,
    organization: string
  ): Promise<boolean> {
    const promise = new Promise<any>(resolve => {
      XamarinHelper.addEventListener(
        "view-document",
        event => {
          resolve(JSON.parse(event.detail).result);
        },
        true
      );
    });
    XamarinHelper.invokeAction("view-document", {
      url,
      description,
      authorization,
      organization
    });
    return promise;
  }

  public static logout() {
    XamarinHelper.invokeAction("logout", {});
  }

  public static clearSession() {
    XamarinHelper.invokeAction("clear-session", {});
  }

  public static supportChat() {
    XamarinHelper.invokeAction("support-chat", {});
  }

  public static clearKeyValueCache() {
    const messageId = v4();
    const promise = XamarinHelper.slotKvCacheMessage(messageId);

    XamarinHelper.invokeAction("kvcache-clear", {
      messageId
    });

    return promise;
  }

  public static getFromKeyValueCache(key: string) {
    const messageId = v4();
    const promise = XamarinHelper.slotKvCacheMessage(messageId);

    XamarinHelper.invokeAction("kvcache-get", {
      messageId,
      key
    });

    return promise;
  }

  public static putIntoKeyValueCache(key: string, value: string) {
    const messageId = v4();
    const promise = XamarinHelper.slotKvCacheMessage(messageId);

    XamarinHelper.invokeAction("kvcache-put", {
      messageId,
      key,
      value
    });

    return promise;
  }

  public static removeFromKeyValueCache(key: string): Promise<any> {
    const messageId = v4();
    const promise = XamarinHelper.slotKvCacheMessage(messageId);

    XamarinHelper.invokeAction("kvcache-remove", {
      messageId,
      key
    });

    return promise;
  }

  private static _kvcacheResponseListenerAdded = false;
  private static _kvcacheResponseResolvers = new Map<
    string,
    (response: KvCacheResponse) => void
  >();
  private static _kvcacheChunkedResponses = new Map<
    string,
    KvCacheResponse[]
  >();

  private static slotKvCacheMessage(
    messageId: string
  ): Promise<KvCacheResponse> {
    if (!XamarinHelper._kvcacheResponseListenerAdded) {
      XamarinHelper._kvcacheResponseListenerAdded = true;

      const resolve = (messageId: string, response: KvCacheResponse) => {
        const resolver = XamarinHelper._kvcacheResponseResolvers.get(messageId);

        XamarinHelper._kvcacheResponseResolvers.delete(messageId);
        XamarinHelper._kvcacheChunkedResponses.delete(messageId);

        if (!resolver) {
          console.warn("kvcache-response no resolver for MessageId", messageId);
          return;
        }

        resolver(response);
      };

      XamarinHelper.addEventListener(
        "kvcache-response",
        event => {
          try {
            const response = JSON.parse(event.detail) as KvCacheResponse;

            const chunks = response?.Chunks ?? 1;
            const chunked = chunks > 1;
            const messageId = response?.MessageId;

            if (!messageId) {
              console.warn(
                "kvcache-response event without a MessageId",
                response
              );
              return;
            }

            if (!XamarinHelper._kvcacheResponseResolvers.has(messageId)) {
              console.warn("kvcache-response event with no resolver", response);
              return;
            }

            if (!chunked) {
              // simple case, we're not expecting more than one chunk, just resolve with this value
              return resolve(messageId, response);
            }

            // if we're chunked then collect the response
            let chunkedReponses =
              XamarinHelper._kvcacheChunkedResponses.get(messageId);

            if (!chunkedReponses) {
              chunkedReponses = [];
              XamarinHelper._kvcacheChunkedResponses.set(
                messageId,
                chunkedReponses
              );
            }

            chunkedReponses.push(response);

            // check if this is the last chunk needed to resolve - assumes all chunks have same
            // chunks values and we'll never receive the same chunk event more than once
            if (chunkedReponses.length === response.Chunks) {
              // sort them just in case we get them out of order
              chunkedReponses.sort((a, b) => (a.Chunk ?? 0) - (b.Chunk ?? 0));

              const combinedResponse: KvCacheResponse = {
                MessageId: messageId,
                Value: chunkedReponses.map(r => r.Value ?? "").join("")
              };

              return resolve(messageId, combinedResponse);
            }
          } catch (e) {
            console.error(
              "unexpected error in kvcache-response",
              e,
              event.detail
            );
          }
        },
        false
      );
    }

    return new Promise<KvCacheResponse>((resolve, reject) => {
      // TODO?: set a timeout and reject if we never get a response and resolve
      XamarinHelper._kvcacheResponseResolvers.set(messageId, resolve);
    });
  }
}

XamarinHelper.init();

interface KvCacheResponse {
  MessageId: string;
  Chunk?: number;
  Chunks?: number;
  Value?: string | null;
}
