import gql from "graphql-tag";
import { FRAGMENT_STAFF } from "./Fragments";
import { QueryFunctionOptions, useMutation, useQuery } from "@apollo/client";
import { staffUpdate, staffUpdateVariables } from "../Shared/Api/staffUpdate";
import {
  StaffAssignedStudentInput,
  StaffInput,
  StaffRoleEnum
} from "../Shared/Api/globalTypes";
import {
  staffResendInvite,
  staffResendInviteVariables
} from "../Shared/Api/staffResendInvite";
import {
  IStaffSearch,
  IStaffSearchVariables
} from "../Shared/Api/IStaffSearch";
import {
  staffDeactivate,
  staffDeactivateVariables
} from "../Shared/Api/staffDeactivate";
import {
  staffReactivate,
  staffReactivateVariables
} from "../Shared/Api/staffReactivate";
import {
  staffLeaveOrganization,
  staffLeaveOrganizationVariables
} from "../Shared/Api/staffLeaveOrganization";
import { staffInvite, staffInviteVariables } from "../Shared/Api/staffInvite";
import {
  staffToggleStudentOfflineAvailability,
  staffToggleStudentOfflineAvailabilityVariables
} from "../Shared/Api/StaffToggleStudentOfflineAvailability";
import { UseQueryOptions } from "../types";
import {
  QUERY_ROLES,
  QUERY_STAFF,
  QUERY_STAFFS_ASSIGNED,
  QUERY_STAFF_SEARCH,
  QUERY_USER
} from "./Queries";
import { IRoles } from "../Shared/Api/IRoles";
import {
  staffAssignStudents,
  staffAssignStudentsVariables
} from "../Shared/Api/staffAssignStudents";
import {
  staffRemoveStudents,
  staffRemoveStudentsVariables
} from "../Shared/Api/staffRemoveStudents";
import {
  IStaffsAssigned,
  IStaffsAssignedVariables
} from "../Shared/Api/IStaffsAssigned";
import { IStaff, IStaffVariables } from "../Shared/Api/IStaff";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useCallback } from "react";

const MUTATION_DEACTIVATE = gql`
  mutation staffDeactivate($id: GUID!) {
    staffDeactivate(id: $id) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_REACTIVATE = gql`
  mutation staffReactivate($id: GUID!) {
    staffReactivate(id: $id) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_LEAVE_ORGANIZATION = gql`
  mutation staffLeaveOrganization($id: GUID!) {
    staffLeaveOrganization(id: $id) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_INVITE_STAFF = gql`
  mutation staffInvite($input: StaffInput!) {
    staffInvite(input: $input) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_RESEND_INVITE_TO_STAFF = gql`
  mutation staffResendInvite($id: GUID!) {
    staffResendInvite(id: $id) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_UPDATE_STAFF = gql`
  mutation staffUpdate($id: GUID!, $input: StaffInput!) {
    staffUpdate(id: $id, input: $input) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_ASSIGN_STUDENTS = gql`
  mutation staffAssignStudents($staffId: GUID!, $studentIds: [GUID!]) {
    staffAssignStudents(staffId: $staffId, studentIds: $studentIds) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_REMOVE_STUDENTS = gql`
  mutation staffRemoveStudents($staffId: GUID!, $studentIds: [GUID!]) {
    staffRemoveStudents(staffId: $staffId, studentIds: $studentIds) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

const MUTATION_TOGGLE_STUDENT_OFFLINE_AVAILABILITY = gql`
  mutation staffToggleStudentOfflineAvailability(
    $id: GUID!
    $studentId: GUID!
    $input: StaffAssignedStudentInput!
  ) {
    staffToggleStudentOfflineAvailability(
      id: $id
      studentId: $studentId
      input: $input
    ) {
      staff {
        ...StaffFragment
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

export const useStaffQuery = (id: GUID, options?: UseQueryOptions) => {
  return useQuery<IStaff, IStaffVariables>(
    QUERY_STAFF,
    Object.assign(
      {},
      {
        variables: { id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
      },
      { ...options }
    ) as QueryFunctionOptions<IStaff, IStaffVariables>
  );
};

export const useStaffSearchQuery = (
  variables: IStaffSearchVariables,
  options?: UseQueryOptions
) => {
  return useQuery<IStaffSearch, IStaffSearchVariables>(
    QUERY_STAFF_SEARCH,
    Object.assign({}, { variables, fetchPolicy: "no-cache" }, { ...options })
  );
};

export const useStaffsAssignedQuery = (
  studentId: string,
  options?: UseQueryOptions
) => {
  return useQuery<IStaffsAssigned, IStaffsAssignedVariables>(
    QUERY_STAFFS_ASSIGNED,
    Object.assign(
      {},
      {
        variables: { studentId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
      },
      { ...options }
    ) as QueryFunctionOptions<IStaffsAssigned, IStaffsAssignedVariables>
  );
};

export const useRolesQuery = (options?: UseQueryOptions) => {
  return useQuery<IRoles>(
    QUERY_ROLES,
    Object.assign({}, { fetchPolicy: "no-cache" }, { ...options })
  );
};

export const useStaffDeactivateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffDeactivate,
    staffDeactivateVariables
  >(MUTATION_DEACTIVATE);

  const handleStaffDeactivate = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { deactivate: handleStaffDeactivate, error, data };
};

export const useStaffReactivateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffReactivate,
    staffReactivateVariables
  >(MUTATION_REACTIVATE);

  const handleStaffReactivate = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { reactivate: handleStaffReactivate, error, data };
};

export const useStaffLeaveMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffLeaveOrganization,
    staffLeaveOrganizationVariables
  >(MUTATION_LEAVE_ORGANIZATION);

  const handleStaffLeave = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { leaveOrganization: handleStaffLeave, error, data };
};

export const useStaffInviteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffInvite,
    staffInviteVariables
  >(MUTATION_INVITE_STAFF);

  const handleInviteStaff = useCallback(
    (input: StaffInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { inviteStaff: handleInviteStaff, error, data };
};

export const useStaffResendInviteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffResendInvite,
    staffResendInviteVariables
  >(MUTATION_RESEND_INVITE_TO_STAFF);

  const handleResendInviteToStaff = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { resendInviteToStaff: handleResendInviteToStaff, error, data };
};

export const useStaffUpdateMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { error, data }] = useMutation<
    staffUpdate,
    staffUpdateVariables
  >(MUTATION_UPDATE_STAFF);

  const handleUpdateStaffInfo = useCallback(
    (id: string, input: StaffInput) => {
      const refetch =
        id === threadUserContext.userId
          ? [{ query: QUERY_USER, variables: { id: null } }]
          : undefined;
      return mutate!({
        variables: { id, input },
        refetchQueries: refetch
      });
    },
    [mutate, threadUserContext.userId]
  );
  return { updateStaffInfo: handleUpdateStaffInfo, error, data };
};

export const useStaffUpdateRoleMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffUpdate,
    staffUpdateVariables
  >(MUTATION_UPDATE_STAFF);

  const handleUpdateStaffRole = useCallback(
    (id: string, role: StaffRoleEnum) => {
      return mutate!({
        variables: { id, input: { role } }
      });
    },
    [mutate]
  );
  return { updateStaffRole: handleUpdateStaffRole, error, data };
};

export const useStaffAssignStudentsMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffAssignStudents,
    staffAssignStudentsVariables
  >(MUTATION_ASSIGN_STUDENTS);

  const handleAssignStudentToStaff = useCallback(
    (staffId: string, studentIds: string[]) => {
      return mutate!({
        variables: { staffId, studentIds }
      });
    },
    [mutate]
  );
  return {
    assignStudentToStaff: handleAssignStudentToStaff,
    error,
    data
  };
};

export const useStaffRemoveStudentsMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffRemoveStudents,
    staffRemoveStudentsVariables
  >(MUTATION_REMOVE_STUDENTS);

  const handleRemoveStudents = useCallback(
    (staffId: string, studentIds: string[]) => {
      return mutate!({
        variables: { staffId, studentIds }
      });
    },
    [mutate]
  );
  return { removeStudentsFromStaff: handleRemoveStudents, error, data };
};

export const useStaffAssignedStudentOfflineAvailabilityMutation = () => {
  const [mutate, { error, data }] = useMutation<
    staffToggleStudentOfflineAvailability,
    staffToggleStudentOfflineAvailabilityVariables
  >(MUTATION_TOGGLE_STUDENT_OFFLINE_AVAILABILITY);

  const handleToggleStudentOfflineAvailability = useCallback(
    (id: string, studentId: string, input: StaffAssignedStudentInput) => {
      return mutate!({
        variables: { id, studentId, input }
      });
    },
    [mutate]
  );
  return {
    toggleStudentOfflineAvailability: handleToggleStudentOfflineAvailability,
    error,
    data
  };
};
