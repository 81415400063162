import gql from "graphql-tag";
import { v4 } from "uuid";
import moment from "moment";
import { ApolloClient, useApolloClient, useMutation } from "@apollo/client";
import { PromptHelper } from "Shared/PromptHelper";
import {
  FRAGMENT_DATA_POINT,
  FRAGMENT_PHASE,
  FRAGMENT_SESSION
} from "./Fragments";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  dataPointEndTaskAnalysis,
  dataPointEndTaskAnalysisVariables
} from "Shared/Api/dataPointEndTaskAnalysis";
import {
  dataPointRecordTaskAnalysis,
  dataPointRecordTaskAnalysisVariables
} from "Shared/Api/dataPointRecordTaskAnalysis";
import {
  dataPointRestartTaskAnalysis,
  dataPointRestartTaskAnalysisVariables
} from "Shared/Api/dataPointRestartTaskAnalysis";
import {
  dataPointScoreTaskAnalysis,
  dataPointScoreTaskAnalysisVariables
} from "Shared/Api/dataPointScoreTaskAnalysis";
import {
  dataPointUndoTaskAnalysis,
  dataPointUndoTaskAnalysisVariables
} from "Shared/Api/dataPointUndoTaskAnalysis";
import {
  CollectionMethodEnum,
  TrialResultEnum,
  DataPointStateEnum,
  StepStateEnum,
  ProgramTypeEnum
} from "Shared/Api/globalTypes";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { ProgramDataHelper } from "./ProgramDataHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { refetchProgramSessionsQuery } from "./ProgramData";
import { useCallback } from "react";

const MUTATION_RECORD_TASK_ANALYSIS = gql`
  mutation dataPointRecordTaskAnalysis(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $result: TrialResultEnum!
    $occurredAt: DateTime!
    $stepId: GUID
    $autoSkip: Boolean
  ) {
    dataPointRecordTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
      result: $result
      occurredAt: $occurredAt
      stepId: $stepId
      autoSkip: $autoSkip
    ) {
      dataPoint {
        ...DataPointFragment
      }
      autoRecordDataPoints {
        ...DataPointFragment
      }
      autoRecordSessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_SESSION}
`;

const MUTATION_UNDO_TASK_ANALYSIS = gql`
  mutation dataPointUndoTaskAnalysis(
    $pointId: GUID!
    $studentId: GUID!
    $autoSkip: Boolean
  ) {
    dataPointUndoTrial(
      pointId: $pointId
      studentId: $studentId
      autoSkip: $autoSkip
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_END_TASK_ANALYSIS = gql`
  mutation dataPointEndTaskAnalysis(
    $pointId: GUID!
    $studentId: GUID!
    $occurredAt: DateTime!
  ) {
    dataPointComplete(
      pointId: $pointId
      studentId: $studentId
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SCORE_TASK_ANALYSIS = gql`
  mutation dataPointScoreTaskAnalysis(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $correct: Int!
    $attempted: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: $correct
      attempted: $attempted
      duration: 0
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_RESTART_TASK_ANALYSIS = gql`
  mutation dataPointRestartTaskAnalysis(
    $oldPointId: GUID!
    $newPointId: GUID!
    $studentId: GUID!
  ) {
    dataPointRestart(
      oldPointId: $oldPointId
      newPointId: $newPointId
      studentId: $studentId
    ) {
      session {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_SESSION}
`;

const readDataPointAndPhase = (
  client: ApolloClient<object>,
  dataPointId: string,
  optimistic = true
) => {
  const dataPoint: DataPointFragment | null = client.readFragment(
    {
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${dataPointId}`
    },
    optimistic
  );

  if (!dataPoint) {
    throw Error("Could not find data point");
  }

  const phase: PhaseFragment | null = client.readFragment(
    {
      fragment: FRAGMENT_PHASE,
      fragmentName: "PhaseFragment",
      id: `PhaseType:${dataPoint.phaseId}`
    },
    optimistic
  );

  return { dataPoint, phase };
};

const addTaskAnalysisTrial = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null,
  trialId: string,
  result: TrialResultEnum,
  occurredAt: string,
  stepId: string,
  createdById: string,
  createdByName: string,
  autoSkip: boolean = false
) => {
  const point = _.cloneDeep(dataPoint);

  if (phase) {
    // Add missing trials for steps prior to the one being recorded to ensure order
    const stepIndex = phase.steps.findIndex(s => s.id === stepId);
    while (point.trials.length < stepIndex) {
      const skippedStep = phase.steps[point.trials.length];
      point.trials.push({
        __typename: "TrialType",
        id: uuid(),
        index: point.trials.length,
        result: TrialResultEnum.NONE,
        prompt: "",
        stepId: skippedStep.id,
        targetId: null,
        duration: null,
        occurredAt: null,
        createdById,
        createdByName
      });
    }

    // Record trial for specified step
    const trial = point.trials.find(t => t.stepId === stepId);
    if (trial != null) {
      trial.result = result;
      trial.occurredAt = occurredAt;
    } else {
      // Add new trial
      point.trials.push({
        __typename: "TrialType",
        id: trialId,
        index: point.trials.length,
        result,
        prompt: PromptHelper.PROMPT_INDEPENDENT,
        stepId: stepId ?? null,
        targetId: null,
        duration: null,
        occurredAt: occurredAt,
        createdById,
        createdByName
      });
      if (autoSkip) {
        // Add trials for any inactive steps that immediately follow
        while (
          point.trials.length < phase.steps.length &&
          phase.steps[point.trials.length].state === StepStateEnum.INACTIVE
        ) {
          point.trials.push({
            __typename: "TrialType",
            id: uuid(),
            index: point.trials.length,
            result: TrialResultEnum.NONE,
            prompt: "",
            stepId: phase.steps[point.trials.length].id,
            targetId: null,
            duration: null,
            occurredAt: null,
            createdById,
            createdByName
          });
        }
      }
    }
    if (point.state === DataPointStateEnum.NOT_STARTED) {
      point.state = DataPointStateEnum.IN_PROGRESS;
    }
    const completedTrials = point.trials.filter(t => t.occurredAt);
    if (phase.steps.length === completedTrials.length) {
      point.state = DataPointStateEnum.COMPLETED;
      point.completedAt = occurredAt;
    }
  }

  return point;
};

const undoTaskAnalysisTrial = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null,
  autoSkip: boolean
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);
  const sortedTrials = _.cloneDeep(modifiedDataPoint.trials).filter(
    t => t.occurredAt !== null
  );
  sortedTrials.sort((a, b) => {
    if (a.occurredAt === b.occurredAt) {
      const aIndex = modifiedDataPoint.trials.findIndex(t => t.id === a.id);
      const bIndex = modifiedDataPoint.trials.findIndex(t => t.id === b.id);
      return bIndex - aIndex;
    }
    return moment(b.occurredAt).diff(moment(a.occurredAt));
  });
  const mostRecentTrial = autoSkip
    ? sortedTrials.find(trial => trial.result !== TrialResultEnum.NONE)
    : _.first(sortedTrials);

  if (mostRecentTrial) {
    const index = modifiedDataPoint.trials.findIndex(
      t => t.id === mostRecentTrial.id
    );
    if (index === modifiedDataPoint.trials.length - 1) {
      modifiedDataPoint.trials.splice(index, 1);
    } else {
      modifiedDataPoint.trials[index].result = TrialResultEnum.NONE;
      modifiedDataPoint.trials[index].occurredAt = null;
    }
  }

  if (
    modifiedDataPoint.state === DataPointStateEnum.COMPLETED ||
    modifiedDataPoint.state === DataPointStateEnum.ABANDONED
  ) {
    modifiedDataPoint.state = DataPointStateEnum.IN_PROGRESS;
  }

  return modifiedDataPoint;
};

const scoreTaskAnalysis = (
  dataPoint: DataPointFragment,
  correct: number,
  attempted: number,
  date: DateTime
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  modifiedDataPoint.trials = [];
  modifiedDataPoint.correctOverride = correct;
  modifiedDataPoint.attemptedOverride = attempted;
  modifiedDataPoint.durationOverride = 0;
  modifiedDataPoint.active = true;
  modifiedDataPoint.method = CollectionMethodEnum.PAPER;

  modifiedDataPoint.completedAt = date;
  modifiedDataPoint.state = DataPointStateEnum.COMPLETED;

  return modifiedDataPoint;
};

const completeTaskAnalysis = (
  dataPoint: DataPointFragment,
  occurredAt: DateTime
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  const trials = modifiedDataPoint.trials.filter(
    trial =>
      trial.result !== TrialResultEnum.NOT_APPLICABLE &&
      trial.result !== TrialResultEnum.NONE
  );

  if (
    trials.length === 0 &&
    typeof modifiedDataPoint.attemptedOverride !== "number"
  ) {
    modifiedDataPoint.state = DataPointStateEnum.ABANDONED;
  } else {
    modifiedDataPoint.state = DataPointStateEnum.COMPLETED;
    modifiedDataPoint.completedAt = occurredAt;
  }

  return modifiedDataPoint;
};

export const buildEndTaskAnalysisDataPointFragment = (
  dataPoint: DataPointFragment,
  occurredAt: DateTime
) => {
  return completeTaskAnalysis(dataPoint, occurredAt);
};

export const useDataPointUndoTaskAnalysisMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointUndoTaskAnalysis,
    dataPointUndoTaskAnalysisVariables
  >(MUTATION_UNDO_TASK_ANALYSIS);

  const handleDataPointUndoTaskAnalysis = useCallback(
    (pointId: string, studentId: string, autoSkip: boolean) => {
      const { dataPoint, phase } = readDataPointAndPhase(client, pointId);
      const optimisticResponse: dataPointUndoTaskAnalysis = {
        dataPointUndoTrial: {
          __typename: "DataPointOutput",
          dataPoint: undoTaskAnalysisTrial(dataPoint, phase, autoSkip)
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, autoSkip },
        update: (cache, { data }) => {
          const point = data?.dataPointUndoTrial?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return {
    dataPointUndoTaskAnalysis: handleDataPointUndoTaskAnalysis,
    error,
    data
  };
};

export const useDataPointRecordTaskAnalysisMutation = () => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const client = useApolloClient();
  const [mutate, { error, data }] = useMutation<
    dataPointRecordTaskAnalysis,
    dataPointRecordTaskAnalysisVariables
  >(MUTATION_RECORD_TASK_ANALYSIS);

  const handleDataPointRecordTaskAnalysis = useCallback(
    (
      pointId: string,
      studentId: string,
      stepId: string,
      result: TrialResultEnum,
      autoSkip: boolean = false
    ) => {
      const occurredAt = moment().utc().toISOString();
      const trialId = uuid();
      const { dataPoint, phase } = readDataPointAndPhase(client, pointId);
      const point = addTaskAnalysisTrial(
        dataPoint,
        phase,
        trialId,
        result,
        occurredAt,
        stepId,
        threadUserContext.userId,
        threadUserContext.userName,
        autoSkip
      );
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointRecordTaskAnalysis | undefined =
        undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          id: `DataPointType:${pointId}`,
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointRecordTrial: {
            __typename: "DataPointRecordOutput",
            dataPoint: point,
            autoRecordDataPoints: null,
            autoRecordSessions: null
          }
        };
        DataPointDataHelper.autoRecordOptimisticResponse(
          client.cache,
          studentId,
          threadUserContext.userId,
          threadUserContext.userName,
          optimisticResponse.dataPointRecordTrial!
        );
      }
      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          studentId,
          trialId,
          result,
          occurredAt,
          stepId,
          autoSkip
        },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointRecordTrial?.dataPoint;
          if (point && point.state === DataPointStateEnum.COMPLETED) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
          const output = data?.dataPointRecordTrial;
          if (output) {
            DataPointDataHelper.updateChartDataPointsWithAutoRecordOutput(
              cache,
              output
            );
          }
        }
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [
      mutate,
      client,
      online,
      threadUserContext.userId,
      threadUserContext.userName
    ]
  );
  return {
    dataPointRecordTaskAnalysis: handleDataPointRecordTaskAnalysis,
    error,
    data
  };
};

export const useDataPointScoreTaskAnalysisMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointScoreTaskAnalysis,
    dataPointScoreTaskAnalysisVariables
  >(MUTATION_SCORE_TASK_ANALYSIS);

  const handleDataPointScoreTaskAnalysis = useCallback(
    (
      pointId: string,
      phaseId: string,
      studentId: string,
      correct: number,
      attempted: number,
      occurredAt: DateTime
    ) => {
      const { dataPoint } = readDataPointAndPhase(client, pointId);
      const optimisticResponse: dataPointScoreTaskAnalysis = {
        dataPointSaveScore: {
          __typename: "DataPointOutput",
          dataPoint: scoreTaskAnalysis(
            dataPoint,
            correct,
            attempted,
            occurredAt
          )
        }
      };

      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          phaseId,
          studentId,
          correct,
          attempted,
          occurredAt
        },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return {
    dataPointScoreTaskAnalysis: handleDataPointScoreTaskAnalysis,
    error,
    data
  };
};

export const useDataPointRestartTaskAnalysisMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    dataPointRestartTaskAnalysis,
    dataPointRestartTaskAnalysisVariables
  >(MUTATION_RESTART_TASK_ANALYSIS);

  const handleDataPointRestartTaskAnalysis = useCallback(
    (pointId: string, studentId: string, programId: string) => {
      const session = ProgramDataHelper.readProgramSession(
        client.cache,
        programId
      );
      if (!session?.session || !session?.currentPhase) {
        throw new Error("Unable to locate program session to restart");
      }
      const newPointId = v4();
      const now = moment();
      const point = DataPointDataHelper.readCreateDataPoint(
        client.cache,
        studentId,
        programId,
        ProgramTypeEnum.TASK_ANALYSIS,
        session.currentPhase.id,
        newPointId,
        now,
        threadUserContext.userId,
        threadUserContext.userName,
        DataPointStateEnum.NOT_STARTED
      );
      const optimisticResponse: dataPointRestartTaskAnalysis = {
        dataPointRestart: {
          __typename: "DataPointRestartOutput",
          session: {
            __typename: "SessionType",
            id: programId,
            drawerPosition: session.session?.drawerPosition,
            dataPoint: point,
            phase: session.currentPhase
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { newPointId, oldPointId: pointId, studentId }
      });
    },
    [mutate, client.cache, threadUserContext.userId, threadUserContext.userName]
  );
  return {
    dataPointRestartTaskAnalysis: handleDataPointRestartTaskAnalysis,
    error,
    data
  };
};

export const useDataPointEndTaskAnalysisMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointEndTaskAnalysis,
    dataPointEndTaskAnalysisVariables
  >(MUTATION_END_TASK_ANALYSIS);

  const handleDataPointEndTaskAnalysis = useCallback(
    (pointId: string, studentId: string) => {
      const { dataPoint } = readDataPointAndPhase(client, pointId);
      const occurredAt = moment().utc().toISOString();
      const optimisticResponse: dataPointEndTaskAnalysis = {
        dataPointComplete: {
          __typename: "DataPointOutput",
          dataPoint: buildEndTaskAnalysisDataPointFragment(
            dataPoint,
            occurredAt
          )
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, occurredAt },
        update: (cache, { data }) => {
          const point = data?.dataPointComplete?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return {
    dataPointEndTaskAnalysis: handleDataPointEndTaskAnalysis,
    error,
    data
  };
};
