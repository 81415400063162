import React, { useEffect, useState } from "react";
import {
  Chart,
  IChartData,
  IChartPhase,
  IPhaseDetail,
  DetailTypeEnum,
  Heading,
  Print,
  PrintPage,
  Row,
  IChartInfo
} from "@raudabaugh/thread-ui";
import {
  ChartHelper,
  IPhaseDataPoint,
  IPhaseWithDataPoints
} from "./ChartHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import {
  useProgramChartQuery,
  useProgramSessionsQuery
} from "DataAccess/ProgramData";
import { cloneDeep, first, last } from "lodash";
import { IProgramChart_programChart_dataPoints } from "Shared/Api/IProgramChart";
import { IProgramSessions_programSessions } from "Shared/Api/IProgramSessions";
import { LoadingScreen } from "Shared/LoadingScreen";
import { DataPointStateEnum, ProgramTypeEnum } from "Shared/Api/globalTypes";
import { ChartHeader } from "./ChartHeader";
import { PhaseList } from "./PhaseList";
import { TargetListPage } from "./Print/TargetListPage";
import _ from "lodash";
import { LabelHelper } from "Shared/LabelHelper";
interface IChartPrintProps {
  studentId: string;
  programId: string;
}

export const ChartPrint = (props: IChartPrintProps) => {
  const { threadUserContext } = useThreadContext();
  const defaultLocal = LabelHelper.monthYearLabelFormat(threadUserContext);

  const { studentId: currentStudentId, programId: currentProgramId } = props;

  const [currentProgram, setCurrentProgram] = useState<
    IProgramSessions_programSessions | null | undefined
  >();
  const [chartPhases, setChartPhases] = useState<IChartPhase[]>([]);
  const [chartData, setChartData] = useState<IChartData | null>(null);
  const student = threadUserContext.assignedStudents.find(
    s => s.studentId === currentStudentId
  )?.student;
  const filteredPhases: IPhaseWithDataPoints[] = [];

  //#region ProgramSessionQuery
  const {
    loading: programSessionsLoading,
    error: programSessionsError,
    data: programSessionsData
  } = useProgramSessionsQuery(currentStudentId);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled && programSessionsData) {
      const { programSessions } = programSessionsData;
      const clonedPrograms = cloneDeep(programSessions);

      if (programSessions.length) {
        setCurrentProgram(
          clonedPrograms.find(programSession => {
            return programSession.program.id === currentProgramId;
          })
        );
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [programSessionsData, programSessionsError, currentProgramId]);
  //#endregion

  //#region ProgramChartQuery
  const {
    loading: programChartLoading,
    error: programChartError,
    data: programChartData
  } = useProgramChartQuery(currentStudentId, currentProgramId, undefined, {
    skip: !currentProgramId // && !currentProgram
  });

  useEffect(() => {
    let isCancelled = false;
    const dateSortDesc = (
      a: IProgramChart_programChart_dataPoints,
      b: IProgramChart_programChart_dataPoints
    ) => {
      if (a.completedAt === b.completedAt) {
        return 0;
      }
      if (a.completedAt == null) {
        return 1;
      }
      if (b.completedAt == null) {
        return -1;
      }
      return a.completedAt.localeCompare(b.completedAt);
    };

    if (!isCancelled && programChartData && currentProgram) {
      const tempPhases = programChartData.programChart.phases;
      const tempChartPhases: IChartPhase[] = [];

      tempPhases.forEach(phase => {
        const points = cloneDeep(
          programChartData.programChart.dataPoints
        ).filter(dp => {
          return (
            dp.phaseId === phase.id &&
            dp.state === DataPointStateEnum.COMPLETED &&
            !dp.softDeleted
          );
        }) as IPhaseDataPoint[];
        points.sort(dateSortDesc);
        const copy: IPhaseWithDataPoints = {
          ...phase,
          points
        };
        filteredPhases.push(copy);
      });

      if (currentProgram && filteredPhases && filteredPhases.length) {
        filteredPhases.forEach(phase => {
          const phaseTargets = phase.targetIds
            .map(id => currentProgram.program.targets.find(t => t.id === id))
            .filter(x => x !== undefined);
          const details = phaseTargets
            .map(t => {
              if (t) {
                return {
                  type: DetailTypeEnum.TARGET,
                  description: t.targetDescription,
                  label: ""
                };
              }
              return undefined;
            })
            .filter(x => x !== undefined);
          const chartPhase: IChartPhase = {
            id: phase.id,
            range: "N/A",
            pointsNumber: phase.points.length,
            labelOverride: phase?.phaseNameOverride,
            additionalInfo: phase?.phaseSummary,
            target: first(phaseTargets)?.targetDescription || null,
            points: phase.points.map(
              point =>
                ({
                  id: point.id,
                  note: !!point.note,
                  type: point.pointType,
                  x: point.createdAt ? new Date(point.createdAt) : undefined,
                  popover: null
                } as unknown as IChartInfo)
            ),
            popover: null,
            details: details as IPhaseDetail[]
          };
          if (chartPhase.details?.length === 0) {
            const noDetails: IPhaseDetail = {
              type: DetailTypeEnum.NONE,
              label: "",
              description:
                currentProgram!.program.type === ProgramTypeEnum.TASK_ANALYSIS
                  ? "No tasks set"
                  : "No targets set"
            };
            chartPhase.details.push(noDetails);
          }
          tempChartPhases.push(chartPhase);
        });
        setChartPhases(tempChartPhases);
        setChartData(
          ChartHelper.mapChartViewData(
            currentProgram!.program.type,
            filteredPhases,
            defaultLocal
          )
        );
      }
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programChartData, programChartError, currentProgram, currentStudentId]);
  //#endregion

  if (programSessionsLoading || programChartLoading)
    return (
      <LoadingScreen
        loading={programSessionsLoading || programChartLoading}
        tip={"Loading..."}
      />
    );

  if (programChartError) {
    return <Heading level={2}>Error: Failed to Print!</Heading>;
  }

  const studentName = student?.fullName;

  if (chartData && chartPhases) {
    const pageData = getPageData(chartData, chartPhases);
    const schoolYear: string = getSchoolYear(chartPhases);
    const title = currentProgram?.program.name ?? "";
    const header = (
      <ChartHeader
        studentName={studentName || ""}
        title={title}
        schoolYear={schoolYear}
      />
    );

    return (
      <Print pageSize="letter" orientation="landscape">
        {pageData.chartDataList.map((chartPage, index) => {
          return (
            <PrintPage key={index}>
              <Row type="flex">{header}</Row>
              <Row type="flex" grow={1}>
                <Chart
                  mode="print"
                  type="Line"
                  data={chartPage}
                  phases={pageData.phases[index]}
                  yAxisTitle={ChartHelper.getYAxisTitle(
                    currentProgram?.program?.type as ProgramTypeEnum,
                    chartData.stepBarName
                  )}
                  yAxisLabels={ChartHelper.getYAxisLabels(
                    chartData.stepBarName,
                    chartData.maxValue
                  )}
                  height="100%"
                  width="100%"
                  showLegend={
                    (currentProgram?.program?.type as ProgramTypeEnum) !==
                      ProgramTypeEnum.DURATION &&
                    (currentProgram?.program?.type as ProgramTypeEnum) !==
                      ProgramTypeEnum.FREQUENCY
                  }
                />
              </Row>
            </PrintPage>
          );
        })}
        <PhaseList
          chartType={currentProgram?.program?.type as ProgramTypeEnum}
          chartData={chartData}
          phases={chartPhases}
          header={header}
        />
        <TargetListPage
          program={currentProgram!.program}
          studentName={studentName || ""}
          title={title ?? ""}
          schoolYear={schoolYear}
          currentPhase={filteredPhases.slice(-1)[0]}
        />
      </Print>
    );
  } else {
    return <Heading level={2}>Error: Failed to Print Chart!</Heading>;
  }
};

const getPageData = (chartData: IChartData, chartPhases: IChartPhase[]) => {
  const totalMaxPointsNumber = 90;
  const maxPointsNumber = totalMaxPointsNumber / 2;
  const pageData = {
    chartDataList: getChartDataList(chartData, totalMaxPointsNumber),
    phases: getPagePhases(chartPhases, maxPointsNumber)
  };

  return pageData;
};

const getSchoolYear = (phases: IChartPhase[]) => {
  const lastChartPoint = last(last(phases)!.points);
  if (lastChartPoint && lastChartPoint.x) {
    const lastDate = new Date(lastChartPoint.x);
    const completedAtMonth = lastDate.getMonth();
    const completedAtYear = lastDate.getFullYear();
    return completedAtMonth < 6
      ? `${completedAtYear - 1}-${completedAtYear}`
      : `${completedAtYear}-${completedAtYear + 1}`;
  } else {
    return "";
  }
};

const getChartDataList = (
  chartData: IChartData,
  totalMaxPointsNumber: number
) => {
  let chartDataList = [],
    remainingChartPointsNumber = chartData.labels.length,
    chartDataIndex = 0,
    filledChartData,
    emptyLabels,
    emptySeries,
    emptyInfo;

  while (remainingChartPointsNumber > 0) {
    if (remainingChartPointsNumber >= totalMaxPointsNumber) {
      chartDataList.push({
        labels: chartData.labels.slice(
          chartDataIndex,
          chartDataIndex + totalMaxPointsNumber
        ),
        series: [
          chartData.series[0].slice(
            chartDataIndex,
            chartDataIndex + totalMaxPointsNumber
          )
        ],
        info: chartData.info.slice(
          chartDataIndex,
          chartDataIndex + totalMaxPointsNumber
        )
      } as IChartData);

      chartDataIndex += totalMaxPointsNumber;
      remainingChartPointsNumber -= totalMaxPointsNumber;
    } else {
      filledChartData = {
        labels: chartData.labels.slice(
          chartDataIndex,
          chartDataIndex + remainingChartPointsNumber
        ),
        series: [
          chartData.series[0].slice(
            chartDataIndex,
            chartDataIndex + remainingChartPointsNumber
          )
        ],
        info: chartData.info.slice(
          chartDataIndex,
          chartDataIndex + remainingChartPointsNumber
        )
      };

      remainingChartPointsNumber =
        totalMaxPointsNumber - remainingChartPointsNumber;

      emptyInfo = new Array(remainingChartPointsNumber).fill(null);
      emptySeries = new Array(remainingChartPointsNumber).fill(null);
      emptyLabels = new Array(remainingChartPointsNumber)
        .fill(null)
        .map((item, index) =>
          index === 0 ? "" : index % 2 === 0 ? null : " "
        );

      chartDataList.push({
        labels: filledChartData.labels.concat(emptyLabels),
        series: [filledChartData.series[0].concat(emptySeries)],
        info: filledChartData.info.concat(emptyInfo)
      } as IChartData);

      remainingChartPointsNumber = 0;
    }
  }

  return chartDataList;
};

const getPagePhases = (phases: IChartPhase[], maxPointsNumber: number) => {
  let pagePhases: [IChartPhase[]] = [[]],
    totalPointsNumber = 0,
    remainingPhasePointsNumber;

  phases.forEach((phase, index) => {
    phase.titleLetter = numberToLetter(index);
    let newPhase: IChartPhase = { ...phase };
    if (totalPointsNumber + phase.pointsNumber < maxPointsNumber) {
      totalPointsNumber += phase.pointsNumber;
      _.last(pagePhases)?.push(
        Object.assign({}, newPhase, {
          titleLetter: numberToLetter(index)
        })
      );
    } else {
      _.last(pagePhases)?.push(
        Object.assign({}, newPhase, {
          pointsNumber: maxPointsNumber - totalPointsNumber,
          totalPointsNumber: phase.pointsNumber,
          titleLetter: `${numberToLetter(index)}...`
        })
      );
      pagePhases.push([]);

      remainingPhasePointsNumber =
        phase.pointsNumber - (maxPointsNumber - totalPointsNumber);
      while (remainingPhasePointsNumber > 0) {
        if (remainingPhasePointsNumber < maxPointsNumber) {
          _.last(pagePhases)?.push(
            Object.assign({}, newPhase, {
              pointsNumber: remainingPhasePointsNumber,
              totalPointsNumber: phase.pointsNumber,
              titleLetter: `...${numberToLetter(index)}`
            })
          );
          totalPointsNumber = remainingPhasePointsNumber;
          remainingPhasePointsNumber = 0;
        } else {
          _.last(pagePhases)?.push(
            Object.assign({}, newPhase, {
              pointsNumber: maxPointsNumber,
              totalPointsNumber: phase.pointsNumber,
              titleLetter: `...${numberToLetter(index)}...`
            })
          );
          pagePhases.push([]);
          remainingPhasePointsNumber -= maxPointsNumber;
        }
      }
    }
  });

  return pagePhases;
};

const numberToLetter = (number: number): string => {
  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];

  const quotient = Math.floor(number / 26);
  const remainder = number % 26;
  return quotient > 0
    ? numberToLetter(quotient - 1) + alphabet[remainder]
    : alphabet[remainder];
};
