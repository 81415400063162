import { StepStateEnum } from "./Api/globalTypes";
import { PhaseFragment_steps } from "./Api/PhaseFragment";
import { TrialFragment } from "./Api/TrialFragment";

export class TaskAnalysisHelper {
  // Get the index of the next active step that hasn't been completed. Returns -1 if task analysis is complete
  static getNextStep(
    steps: PhaseFragment_steps[],
    trials: TrialFragment[],
    autoSkip: boolean = false
  ) {
    for (let index = 0; index < steps.length; index++) {
      const step = steps[index];
      const trial = trials.find(trial => trial.stepId === step.id);
      if (
        (!autoSkip || step.state === StepStateEnum.ACTIVE) &&
        (!trial || !trial?.occurredAt)
      ) {
        return index;
      }
    }
    return -1;
  }
}
