import {
  Grid,
  Col,
  List,
  ListItem,
  Row,
  Tag,
  Heading,
  Icon
} from "@raudabaugh/thread-ui";
import moment from "moment";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { SessionNoteFragment } from "../Shared/Api/SessionNoteFragment";
import { SessionNoteStatusEnum } from "../Shared/Api/globalTypes";
import { ISessionNotes } from "../Shared/Api/ISessionNotes";
import { LoadingScreen } from "../Shared/LoadingScreen";

export interface ISubmittedNotesProps {
  currentStudentId: string;
  showOther: boolean;
  onSessionNoteSelect: (note: SessionNoteFragment) => void;
  loading: boolean;
  data: ISessionNotes | undefined;
}

export const SubmittedNotes = (props: ISubmittedNotesProps) => {
  const { threadUserContext } = useThreadContext();
  const handleNoteSelect = (note: SessionNoteFragment) => {
    props.onSessionNoteSelect(note);
  };

  return (
    <>
      <LoadingScreen loading={props.loading} />
      {props.data && (
        <Grid margin="-16px 16px 0 16px">
          <List>
            {props.data?.sessionNotes?.edges &&
              props.data.sessionNotes.edges.map(edge => {
                const sessionNote = edge?.node;
                if (sessionNote === undefined) {
                  return null;
                }
                if (
                  sessionNote.status === SessionNoteStatusEnum.SUBMITTED &&
                  (props.showOther ||
                    sessionNote.createdById === threadUserContext.userId)
                ) {
                  return (
                    <ListItem key={sessionNote.id}>
                      <Grid
                        onClick={() => handleNoteSelect(sessionNote)}
                        width="100%"
                      >
                        <Row type="flex" justify="space-between">
                          <Col>
                            <Heading level={5} color="primary" weight="medium">
                              {moment(sessionNote.start).format(
                                "ddd, MMM. D, YYYY"
                              )}
                            </Heading>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between">
                          <Col>
                            {moment(sessionNote.start!).format("h:mm A")}
                            {sessionNote.end !== null
                              ? " - " +
                                moment(sessionNote.end!).format("h:mm A")
                              : ""}
                            {sessionNote.createdById !==
                            threadUserContext.userId
                              ? ` by ${sessionNote.createdByName}`
                              : ""}
                          </Col>
                          <Col margin="0 0 0 auto">
                            <Icon type="fa-chevron-right fas" />
                          </Col>
                        </Row>
                        <Row>
                          <Col margin="8px 0 0 0">
                            <Tag color="success">
                              Submitted{" "}
                              {moment(sessionNote.modifiedAt!).format(
                                "MMM. D, YYYY, h:mm A"
                              )}
                            </Tag>
                          </Col>
                        </Row>
                      </Grid>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
          </List>
        </Grid>
      )}
    </>
  );
};
