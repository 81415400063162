import {
  Button,
  Card,
  Col,
  Dropdown,
  Heading,
  IColumnProps,
  Icon,
  Link,
  Media,
  Menu,
  MenuItem,
  Modal,
  Row,
  Notification,
  Select,
  SelectOption,
  Table
} from "@raudabaugh/thread-ui";
import moment from "moment";
import React, { useState } from "react";
import { LabelHelper } from "Shared/LabelHelper";
import { findPermission } from "Shared/RolesMap";
import { ClinicalFileFragment } from "../../Shared/Api/ClinicalFileFragment";
import { useClinicalFileSoftDeleteMutation } from "../../DataAccess/ClinicalFileData";
import { client } from "../../App";
import { StudentFileUpload } from "./StudentFileUpload";
import { AuthenticationService } from "Security/AuthenticationService";
import FileSaver from "file-saver";
import { LoadingScreen } from "Shared/LoadingScreen";
import { XamarinHelper } from "Shared/XamarinHelper";
import { FileHelper } from "Shared/FileHelper";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import { ClinicalFileTypeEnum, Permissions } from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IStudentFilesProps {
  id: string;
  files: ClinicalFileFragment[];
  setLoadingScreen: (value: boolean) => void;
  onFileUploaded: () => void;
}

export const StudentFiles = (props: IStudentFilesProps) => {
  const { threadUserContext } = useThreadContext();
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileFilter, setFileFilter] = useState(
    ClinicalFileTypeEnum.BEHAVIOR_INTERVENTION_PLAN
  );
  const { clinicalFileSoftDelete } = useClinicalFileSoftDeleteMutation({
    client
  });

  const canUpload = findPermission(
    threadUserContext.role,
    Permissions.ADD_CLINICAL_FILES
  );
  const canDelete = findPermission(
    threadUserContext.role,
    Permissions.SOFT_DELETE_CLINICAL_FILES
  );
  const formatFileSize = (bytes: number, dp = 1) => {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };
  const confirmDeletion = (file: ClinicalFileFragment) => {
    Modal.confirm({
      title: `Are you sure you want to delete the '${file.name}' clinical file?`,
      content: "This action cannot be undone.",
      okText: "Delete File",
      cancelText: "Cancel",
      onOk: () => deleteFile(file),
      okButtonProps: { danger: true }
    });
  };

  const deleteFile = (file: ClinicalFileFragment) => {
    clinicalFileSoftDelete(file.id, props.id)
      .then(() => {
        Notification.success({
          duration: 0,
          message: "Clinical File Deleted",
          description: `${
            file.name ?? "Clinical file"
          } has been successfully deleted.`
        });
      })
      .catch(error => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Failed to delete clinical file"
        });
      });
  };

  const downloadFile = async (file: ClinicalFileFragment) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_THREADAPI_URL!.replace(
        "Api/GraphQL",
        "ClinicalApi/DownloadClinicalFile"
      )}?studentId=${props.id}&fileId=${file.id}`;

      const headers = AuthenticationService.getHeaders();
      if (XamarinHelper.insideXamarin()) {
        const result = await XamarinHelper.viewDocument(
          url,
          file.name || "Clinical File",
          headers.authorization,
          headers.organization
        );
        if (!result) {
          NotificationsHelper.BasicErrorNotification(
            "Failed to download clinical file",
            "There was an issue downloadig file."
          );
        }
      } else {
        const response = await fetch(url, { headers });
        const blob = await response.blob();
        FileSaver.saveAs(blob, file.name ?? "download");
      }
      setLoading(false);
    } catch (e) {
      const error = e as Error;
      setLoading(false);
      NotificationsHelper.ErrorNotification({
        error,
        title: "Failed to download clinical file"
      });
    }
  };

  const actions = (file: ClinicalFileFragment) => (
    <Menu
      onClick={() => confirmDeletion(file)}
      selectable={false}
      mode="vertical"
      key={file.id}
    >
      <MenuItem key="deleteFile">
        <Icon type="fa-trash fas" width="28px" />
        Delete File
      </MenuItem>
    </Menu>
  );
  const nameColumn: IColumnProps<ClinicalFileFragment> = {
    dataIndex: "name",
    title: "File Name",
    render: (_text: any, record: ClinicalFileFragment) =>
      XamarinHelper.insideXamarin() &&
      !FileHelper.isMobileSupported(record.contentType) ? (
        <>{record.name}</>
      ) : (
        <Link onClick={() => downloadFile(record)}>{record.name}</Link>
      )
  };
  const uploadedOnColumn: IColumnProps<ClinicalFileFragment> = {
    dataIndex: "createdAt",
    title: "Uploaded On",
    render: (_text, record) =>
      moment(record.createdAt).format("MMM. D, YYYY h:mm a"),
    xs: { visible: false }
  };
  const sizeColumn: IColumnProps<ClinicalFileFragment> = {
    dataIndex: "fileSize",
    title: "File Size",
    render: (_text, record) => formatFileSize(record.fileSize),
    xs: { visible: false }
  };
  const uploadedByColumn: IColumnProps<ClinicalFileFragment> = {
    dataIndex: "createdBy",
    title: "Uploaded By",
    render: (_text, record) => record.createdByName,
    xs: { visible: false }
  };
  const overflowColumn: IColumnProps<ClinicalFileFragment> = {
    key: "overflow",
    align: "right",
    render: (_text, record) => (
      <Dropdown trigger={["click"]} overlay={actions(record)}>
        <Button ghost={true} borderless={true}>
          <Icon color="default" variation={10} type="fa-ellipsis-v fas" />
        </Button>
      </Dropdown>
    )
  };
  const renderExpandedRow = (record: ClinicalFileFragment) => (
    <Row padding="0px 0px 0px 16px" direction="column" type="flex">
      <Col>
        Uploaded On: {moment(record.createdAt).format("MMM. D, YYYY h:mm a")}
      </Col>
      <Col>File Size: {formatFileSize(record.fileSize)}</Col>
      <Col>Uploaded By: {record.createdByName}</Col>
    </Row>
  );
  const columns: Array<IColumnProps<ClinicalFileFragment>> = [
    nameColumn,
    uploadedOnColumn,
    sizeColumn,
    uploadedByColumn
  ];
  if (canDelete) {
    columns.push(overflowColumn);
  }
  const files = props.files.filter(f => f.fileType === fileFilter);
  return (
    <>
      {showUpload && (
        <StudentFileUpload
          studentId={props.id}
          files={props.files}
          onComplete={() => {
            setShowUpload(false);
            props.onFileUploaded();
          }}
        />
      )}
      <Card
        margin={"16px 0px"}
        variation={1}
        width="100%"
        title={
          <>
            <Row type="flex" justify="space-between">
              <Col>Clinical Files</Col>
            </Row>
            <Row margin="4px 0 0 0" type="flex">
              <Col type="flex" grow={1}>
                {props.files.length > 0 && (
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    defaultValue={fileFilter}
                    getPopupContainer={(trigger: HTMLElement) => {
                      return trigger.parentElement?.parentElement
                        ?.parentElement as HTMLElement;
                    }}
                    onChange={value =>
                      setFileFilter(value as ClinicalFileTypeEnum)
                    }
                  >
                    <SelectOption
                      value={ClinicalFileTypeEnum.BEHAVIOR_INTERVENTION_PLAN}
                    >
                      {LabelHelper.clinicalFileAreaLabel(
                        ClinicalFileTypeEnum.BEHAVIOR_INTERVENTION_PLAN
                      )}
                    </SelectOption>
                    <SelectOption
                      value={ClinicalFileTypeEnum.INDIVIDUALIZED_EDUCATION_PLAN}
                    >
                      {LabelHelper.clinicalFileAreaLabel(
                        ClinicalFileTypeEnum.INDIVIDUALIZED_EDUCATION_PLAN
                      )}
                    </SelectOption>
                    <SelectOption value={ClinicalFileTypeEnum.THREAD_REPORTS}>
                      {LabelHelper.clinicalFileAreaLabel(
                        ClinicalFileTypeEnum.THREAD_REPORTS
                      )}
                    </SelectOption>
                    <SelectOption value={ClinicalFileTypeEnum.OTHER}>
                      {LabelHelper.clinicalFileAreaLabel(
                        ClinicalFileTypeEnum.OTHER
                      )}
                    </SelectOption>
                  </Select>
                )}
              </Col>
              {canUpload && (
                <Col type="flex" grow={0} margin="4px 0 0 16px">
                  <Button type="primary" onClick={() => setShowUpload(true)}>
                    <Row type="flex">
                      <Col>
                        <Icon type="fa-plus fas" />
                      </Col>
                      <Col margin="0 0 0 12px" xs={0} sm={1}>
                        Upload File
                      </Col>
                    </Row>
                  </Button>
                </Col>
              )}
            </Row>
          </>
        }
      >
        {files.length > 0 ? (
          <Media.Xs>
            {(isXs: boolean) => (
              <Table
                columns={columns}
                pagination={false}
                dataSource={files}
                rowKey={file => file.id}
                expandedRowKeys={files.map(file => file.id)}
                rowExpandable={() => isXs}
                expandIconColumnIndex={-1}
                expandedRowRender={renderExpandedRow}
              />
            )}
          </Media.Xs>
        ) : (
          <Heading level={5}>N/A</Heading>
        )}
        {loading && <LoadingScreen tip="Downloading..." />}
      </Card>
    </>
  );
};
