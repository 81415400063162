import {
  Col,
  Grid,
  Heading,
  Row,
  Drawer,
  Card,
  Icon,
  Image,
  Divider,
  Tag
} from "@raudabaugh/thread-ui";
import React, { useState } from "react";
import { IProgramItem } from "../../StudentPrograms/Types";
import { FocusBar } from "./FocusBar";
import { ApolloError } from "@apollo/client";
import {
  useDataPointRecordTaskAnalysisMutation,
  useDataPointUndoTaskAnalysisMutation
} from "../../DataAccess/TaskAnalysisData";
import { LabelHelper } from "../../Shared/LabelHelper";
import { TaskAnalysisTargetRow } from "./TaskAnalysisTargetRow";
import {
  ReinforcementScheduleEnum,
  StepStateEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import summaryImage from "Shared/Images/summary.svg";
import sdImage from "Shared/Images/sd.svg";
import promptImage from "Shared/Images/prompt.svg";
import { TaskAnalysisHelper } from "Shared/TaskAnalysisHelper";

interface ITaskAnalysisFocusBaseProps {
  item: IProgramItem;
  index: number;
  currentStudentId: string;
  completed: boolean;
  open: boolean;
  onClose: (dataCollected: boolean) => void;
  programType: string;
}

export const TaskAnalysisFocus = (props: ITaskAnalysisFocusBaseProps) => {
  const [dataCollected, setDataCollected] = useState(false);
  const [selectedStep, setSelectedStep] = useState<number>(-1);

  const { dataPointRecordTaskAnalysis } =
    useDataPointRecordTaskAnalysisMutation();
  const { dataPointUndoTaskAnalysis } = useDataPointUndoTaskAnalysisMutation();
  const handleLeftButtonClick = (taskId = "") => {
    const step = props.item.steps.find(s => s.id === taskId);
    const skip = step?.state !== StepStateEnum.ACTIVE;
    // handle negative result
    dataPointRecordTaskAnalysis(
      props.item.runId!,
      props.currentStudentId,
      taskId,
      skip ? TrialResultEnum.NONE : TrialResultEnum.MINUS,
      false
    )
      .then(() => {
        setDataCollected(true);
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    setSelectedStep(-1);
  };
  const handleCenterButtonClick = (taskId = "") => {
    // handle n/a result
    dataPointRecordTaskAnalysis(
      props.item.runId!,
      props.currentStudentId,
      taskId,
      TrialResultEnum.NOT_APPLICABLE,
      false
    )
      .then(() => {
        setDataCollected(true);
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    setSelectedStep(-1);
  };
  const handleRightButtonClick = (taskId = "") => {
    // handle positive result
    dataPointRecordTaskAnalysis(
      props.item.runId!,
      props.currentStudentId,
      taskId,
      TrialResultEnum.PLUS,
      false
    )
      .then(() => {
        setDataCollected(true);
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    setSelectedStep(-1);
  };
  const handleUndoClick = () => {
    dataPointUndoTaskAnalysis(props.item.runId!, props.currentStudentId, false)
      .then(() => {
        setDataCollected(true);
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
    setSelectedStep(-1);
  };
  const handleEditButtonClick = (taskId = "") => {
    // find the task, and set it as the selected index if it exists and is active
    setSelectedStep(props.item.steps.findIndex(step => step.id === taskId));
  };

  const handleDrawerClose = () => {
    props.onClose(dataCollected);
    setDataCollected(false);
    setSelectedStep(-1);
  };

  const currentStep = TaskAnalysisHelper.getNextStep(
    props.item.steps,
    props.item.trials,
    false
  );

  return (
    <>
      <Drawer
        key={"Drawer_" + props.index}
        mask={false}
        margin="0"
        padding="0"
        height="100%"
        placement="bottom"
        visible={props.open}
        closable={false}
      >
        <Grid
          type="flex"
          height="100vh"
          width="100%"
          color="primary"
          variation={9}
        >
          <Row type="flex" scroll="vertical">
            <Col grow={1}>
              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  onClick={handleDrawerClose}
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <FocusBar
                    id="closeProgramDrawer"
                    heading={props.item.name}
                    icon="fa-times fas"
                    isOpen={true}
                    size="25px"
                  />
                </Card>
              </Card>

              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <Row type="flex">
                    <Col xs={19} sm={20} md={20} lg={22} xl={23} xxl={23}>
                      <Heading color="default" variation={1} level={6}>
                        {props.item.steps.length} steps •{" "}
                        {props.item.correctCount}/{props.item.applicableCount}{" "}
                        correct
                      </Heading>
                    </Col>
                    <Col>
                      {!props.completed && props.item.applicableCount > 0 && (
                        <Icon
                          onClick={handleUndoClick}
                          type="fa-undo fas"
                          size="25px"
                          width={"50px"}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Card>
              {props.item.programDescription && (
                <Row padding="10px 0 0 0" margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={summaryImage} alt="Summary" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.programDescription}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.discriminativeStimulus && (
                <Row margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={sdImage} alt="SD" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.discriminativeStimulus}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.prompt !== "" && (
                <Row margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={promptImage} alt="Prompt" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.prompt}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.reinforcementSchedule !==
                ReinforcementScheduleEnum.NONE && (
                <Row margin="15px 15px" type="flex">
                  <Tag color="primary" maxWidth="10em">
                    {LabelHelper.reinforcementScheduleShortLabel(
                      props.item.reinforcementSchedule
                    )}
                    {props.item.reinforcement}
                  </Tag>
                  {props.item.reinforcementType}
                </Row>
              )}
              <Divider />
              {props.item.steps.map((step, index) => (
                <TaskAnalysisTargetRow
                  key={`task_${index}`}
                  completed={props.completed}
                  currentStep={currentStep}
                  selectedStep={selectedStep}
                  index={index}
                  item={props.item}
                  onLeftButtonClick={handleLeftButtonClick}
                  onCenterButtonClick={handleCenterButtonClick}
                  onRightButtonClick={handleRightButtonClick}
                  onEditButtonClick={handleEditButtonClick}
                />
              ))}
            </Col>
          </Row>
        </Grid>
      </Drawer>
    </>
  );
};
