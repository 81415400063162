import {
  Card,
  Button,
  Dropdown,
  Icon,
  Menu,
  MenuItem,
  Col,
  ButtonGroup,
  Row,
  Heading,
  Text,
  IClickParam
} from "@raudabaugh/thread-ui";
import moment from "moment";
import React from "react";

export interface ISimpleMaintenanceCardProps {
  targetId: string;
  targetTitle: string;
  masteryDate: string | undefined;
  lastMaintenanceDateTime: string | null;
  programTitle: string;
  programId: string;
  minusCount: number;
  plusCount: number;
  index: number;
  onPlusClick: (targetId: string, programId: string) => Promise<boolean>;
  onMinusClick: (
    targetId: string,
    programId: string
  ) => Promise<boolean> | boolean;
  onReset: (targetId: string, programId: string) => Promise<boolean>;
  onCompleted: (targetId: string, programId: string) => Promise<void>;
}

export const SimpleMaintenanceCard = (props: ISimpleMaintenanceCardProps) => {
  const stopEventPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const onPlusClick = async () => {
    await props.onPlusClick(props.targetId, props.programId);
  };

  const onMinusClick = async () => {
    await props.onMinusClick(props.targetId, props.programId);
  };

  const onReset = async () => {
    await props.onReset(props.targetId, props.programId);
  };

  const onCompleted = () => {
    props.onCompleted(props.targetId, props.programId);
  };

  const handleMenuClick = async (p: IClickParam) => {
    p.domEvent.stopPropagation();
    switch (p.key) {
      case "complete":
        onCompleted();
        break;
      case "resetScore":
        await onReset();
        break;
    }
  };

  const renderOverflowMenu = () => {
    const actions = (
      <Menu onClick={handleMenuClick} selectable={false} mode="vertical">
        <MenuItem key="complete">Mark as Complete</MenuItem>
        <MenuItem key="resetScore">Reset the score</MenuItem>
      </Menu>
    );
    return (
      <Dropdown trigger={["click"]} overlay={actions}>
        <Button onClick={stopEventPropagation} ghost={true} borderless={true}>
          <Icon color="default" variation={10} type="fa-ellipsis-v fas" />
        </Button>
      </Dropdown>
    );
  };
  return (
    <Card variation={1} width="100%">
      <Row
        type="flex"
        justify="space-between"
        padding="2px 0 0 0"
        align="middle"
      >
        <Col xs={22} sm={22} md={8} lg={8} xl={8} xxl={6}>
          <Heading level={4} weight="medium" noTooltip={true}>
            {props.targetTitle}
          </Heading>
          {props.programTitle}
        </Col>
        <Col xs={2} sm={2} md={0} lg={0} xl={0} xxl={0}>
          {renderOverflowMenu()}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6}>
          <Row>
            <Text strong={true}>Mastered On </Text>
            {props.masteryDate
              ? moment(props.masteryDate).format("MMMM D, YYYY")
              : "N/A"}
          </Row>
          <Row>
            <Text strong={true}>Last Maintenance On </Text>
            {props.lastMaintenanceDateTime
              ? moment(props.lastMaintenanceDateTime).format("MMMM D, YYYY")
              : "N/A"}
          </Row>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6}>
          <Row
            type="flex"
            justify="space-between"
            alignItems="center"
            align="middle"
            grow={1}
          >
            <Col xs={8} sm={8} md={7} lg={7} xl={7} xxl={7}>
              <Heading
                align="center"
                color="default"
                variation={10}
                weight="medium"
                level={4}
                noTooltip={true}
              >{`${props.plusCount}/${
                props.plusCount + props.minusCount
              }`}</Heading>
            </Col>
            <Col xs={16} sm={16} md={15} lg={15} xl={15} xxl={15}>
              <ButtonGroup block={true}>
                <Button
                  id={`${props.index}_minusButton`}
                  block={true}
                  size="x-large"
                  type="primary"
                  onClick={onMinusClick}
                >
                  <Icon
                    size="1.1em"
                    color="default"
                    variation={1}
                    type="fa-minus fas"
                  />
                </Button>
                <Button
                  id={`${props.index}_plusButton`}
                  block={true}
                  size="x-large"
                  type="primary"
                  onClick={onPlusClick}
                >
                  <Icon
                    size="1.1em"
                    color="default"
                    variation={1}
                    type="fa-plus fas"
                  />
                </Button>
              </ButtonGroup>
            </Col>
            <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2}>
              {renderOverflowMenu()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
