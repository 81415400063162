import {
  Card,
  Col,
  Row,
  Button,
  ButtonGroup,
  Grid,
  Heading,
  Icon
} from "@raudabaugh/thread-ui";
import React, { useCallback, MouseEvent } from "react";
import { StepStateEnum, TrialResultEnum } from "Shared/Api/globalTypes";
import { IProgramItem } from "StudentPrograms/Types";

interface ITaskAnalsysiTargetRow {
  completed: boolean;
  currentStep: number;
  selectedStep?: number;
  index: number;
  item: IProgramItem;

  onLeftButtonClick: (taskId: string) => void;
  onCenterButtonClick: (taskId: string) => void;
  onRightButtonClick: (taskId: string) => void;
  onEditButtonClick: (taskId: string) => void;
}

export function TaskAnalysisTargetRow(props: ITaskAnalsysiTargetRow) {
  const {
    completed,
    currentStep,
    index,
    item,
    selectedStep,
    onLeftButtonClick,
    onCenterButtonClick,
    onRightButtonClick,
    onEditButtonClick
  } = props;

  const step = item.steps[index];

  const handleLeftButtonClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onLeftButtonClick(step.id);
    },
    [onLeftButtonClick, step]
  );
  const handleCenterButtonClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onCenterButtonClick(step.id);
    },
    [onCenterButtonClick, step]
  );
  const handleRightButtonClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onRightButtonClick(step.id);
    },
    [onRightButtonClick, step]
  );
  const handleEditButtonClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onEditButtonClick(step.id);
    },
    [onEditButtonClick, step]
  );

  const result = item.trials[index]?.result;
  const hasValue = result && item.trials[index]?.occurredAt;
  const disabled = completed;
  const skip = step.state !== StepStateEnum.ACTIVE;
  const editable = currentStep === index || selectedStep === index;
  const darkenedCard = !editable && (hasValue || disabled);
  const variation = completed ? 6 : 1;
  const color = completed ? "primary" : "default";

  const renderButtons = () => {
    if (editable) {
      if (skip) {
        return (
          <ButtonGroup block={true}>
            <Button
              id={index + "_SkipButton"}
              disabled={disabled}
              type="primary"
              size="x-large"
              onClick={handleLeftButtonClick}
            >
              Skip
            </Button>
          </ButtonGroup>
        );
      }
      return (
        <ButtonGroup block={true}>
          <Button
            id={index + "_LeftButton"}
            disabled={disabled}
            type="primary"
            size="x-large"
            onClick={handleLeftButtonClick}
          >
            <Icon
              size="1.1em"
              color={color}
              variation={variation}
              type="fa-minus fas"
            />
          </Button>
          <Button
            id={`${props.index}_CenterButton`}
            disabled={disabled}
            type="primary"
            size="x-large"
            onClick={handleCenterButtonClick}
          >
            <Grid margin="0 -20px">
              <Heading
                weight="bold"
                level={5}
                color={color}
                variation={variation}
              >
                N/A
              </Heading>
            </Grid>
          </Button>
          <Button
            id={props.index + "_RightButton"}
            disabled={disabled}
            type="primary"
            size="x-large"
            onClick={handleRightButtonClick}
          >
            <Icon
              size="1.1em"
              color={color}
              variation={variation}
              type="fa-plus fas"
            />
          </Button>
        </ButtonGroup>
      );
    } else {
      if (!hasValue) {
        return <></>;
      }
      if (skip) {
        return (
          <ButtonGroup block={true}>
            <Button disabled={disabled} type="primary" size="x-large">
              <Heading
                weight="bold"
                level={5}
                color={color}
                variation={variation}
              >
                Skipped
              </Heading>
            </Button>
          </ButtonGroup>
        );
      }
      switch (result) {
        case TrialResultEnum.MINUS:
          return (
            <ButtonGroup block>
              <Button
                disabled={disabled}
                type="primary"
                size="x-large"
                onClick={handleEditButtonClick}
              >
                <Icon
                  size="1.1em"
                  color={color}
                  variation={variation}
                  type="fa-minus fas"
                />
              </Button>
            </ButtonGroup>
          );

        case TrialResultEnum.PLUS:
          return (
            <ButtonGroup block>
              <Button
                disabled={disabled}
                type="primary"
                size="x-large"
                onClick={handleEditButtonClick}
              >
                <Icon
                  size="1.1em"
                  color={color}
                  variation={variation}
                  type="fa-plus fas"
                />
              </Button>
            </ButtonGroup>
          );

        case TrialResultEnum.NOT_APPLICABLE:
          return (
            <ButtonGroup block>
              <Button
                disabled={disabled}
                type="primary"
                size="x-large"
                onClick={handleEditButtonClick}
              >
                <Heading
                  weight="bold"
                  level={5}
                  color={color}
                  variation={variation}
                >
                  N/A
                </Heading>
              </Button>
            </ButtonGroup>
          );

        default:
          return <></>;
      }
    }
  };

  return (
    <Card
      color="primary"
      variation={darkenedCard ? 9 : 8}
      margin="16px 16px 12px 16px"
    >
      <Grid>
        <Row type="flex" align="middle">
          <Col
            xs={13}
            sm={16}
            md={18}
            lg={20}
            xl={20}
            xxl={20}
            onClick={disabled ? undefined : handleEditButtonClick}
          >
            <Heading level={5} color="default">
              {index + 1}. {step.description}
            </Heading>
          </Col>
          <Col
            xs={11}
            sm={8}
            md={6}
            lg={4}
            xl={4}
            xxl={4}
            onClick={
              !result || result === TrialResultEnum.NONE
                ? handleEditButtonClick
                : undefined
            }
          >
            <Grid height="64px">{renderButtons()}</Grid>
          </Col>
        </Row>
      </Grid>
    </Card>
  );
}
