import { Button, Heading, InputNumber, Row, Spin } from "@raudabaugh/thread-ui";
import { storage } from "./ApolloHelper";
import { useState } from "react";
import moment from "moment";

export const Benchmarking = () => {
  const [running, setRunning] = useState(false);
  const [startTime, setStartTime] = useState<number>();
  const [storeFinishedTime, setStoreFinishedTime] = useState<number>();
  const [fetchFinishedTime, setFetchFinishedTime] = useState<number>();
  const [responseLength, setResponseLength] = useState<number>();
  const [size, setSize] = useState(0.5 * 1024 * 1024); // localStorage limit is 5,200,000 characters
  const runBenchmark = async () => {
    const payload = "x".repeat(size);
    setStartTime(moment.now());
    await storage.setItem("benchmark-test", payload);
    setStoreFinishedTime(moment.now());
    const response = await storage.getItem("benchmark-test");
    setResponseLength(response?.length ?? 0);
    setFetchFinishedTime(moment.now());
  };
  const handleRunClick = () => {
    setRunning(true);
    runBenchmark().then(() => setRunning(false));
  };
  const renderBenchmarks = () => {
    if (!fetchFinishedTime || !storeFinishedTime || !startTime) {
      return null;
    }
    var storeDuration = (storeFinishedTime - startTime) / 1000;
    var fetchDuration = (fetchFinishedTime - storeFinishedTime) / 1000;
    return (
      <>
        <Heading level={5}>Seconds to store: {storeDuration}</Heading>
        <Heading level={5}>Seconds to fetch: {fetchDuration}</Heading>
        {responseLength !== size && (
          <Heading color="error" level={5}>
            Response size mismatch: {responseLength}{" "}
          </Heading>
        )}
      </>
    );
  };
  if (running) {
    return <Spin tip="Running benchmark ..." />;
  }
  return (
    <>
      <Heading level={3}>Cache Persistence Benchmarking</Heading>
      {renderBenchmarks()}
      <Row>
        Payload size:
        <InputNumber
          placeholder=""
          value={size}
          onChange={value => setSize(value ? (value as number) : 0)}
        />
      </Row>
      <Button type="primary" onClick={handleRunClick}>
        Run Benchmark
      </Button>
    </>
  );
};
