import {
  Col,
  Grid,
  Heading,
  Input,
  Row,
  DatePicker
} from "@raudabaugh/thread-ui";
import moment from "moment";
import React, { useEffect } from "react";

interface IEditStudentBasicInfoProps {
  firstName: string;
  lastName: string;
  eINumber: string;
  birthday?: moment.Moment;
  create?: boolean;
  validDate: boolean;
  onChangeText: (
    field: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChangeDate: (field: string, value: moment.Moment) => void;
  onValidateCheck?: (isValid: boolean) => void;
}

export const EditStudentBasicInfo = (props: IEditStudentBasicInfoProps) => {
  useEffect(() => {
    if (props.firstName !== "" && props.lastName !== "") {
      props.onValidateCheck!(true);
    }
  }, [props.firstName, props.lastName, props.onValidateCheck]);

  return (
    <Grid>
      <Row margin="0px 0px 32px 0px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              First Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id="studentFirstNameInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("firstName", value)
            }
            placeholder=""
            value={props.firstName ? props.firstName : ""}
          />
        </Col>
      </Row>
      <Row margin="32px 0px">
        <Col>
          <Row type="flex" margin="0">
            <Heading level={5} margin="0px" color="error">
              * &nbsp;
            </Heading>
            <Heading level={5} margin="0px" color="default" variation={7}>
              Last Name
            </Heading>
          </Row>
        </Col>
        <Col>
          <Input
            id="studentLastNameInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("lastName", value)
            }
            placeholder=""
            value={props.lastName ? props.lastName : ""}
          />
        </Col>
      </Row>
      <Row margin="28px 0px">
        <Col>
          <Heading level={5} margin="0px" color="default" variation={7}>
            Birthday
          </Heading>
          <DatePicker
            id="dateInput"
            onChange={(value: moment.Moment) =>
              props.onChangeDate("birthday", value)
            }
            placeholder="Select date"
            value={
              moment(props.birthday).isValid()
                ? moment(props.birthday)
                : undefined
            }
          />
          {!props.validDate && (
            <Heading level={5} margin="0px" color="error">
              Birthday outside of valid range
            </Heading>
          )}
        </Col>
      </Row>
      <Row margin="32px 0px">
        <Col>
          <Heading
            id="eINumber"
            level={5}
            margin="0px"
            color="default"
            variation={7}
          >
            EI Number
          </Heading>
          <Input
            id="eiNumberInput"
            size="large"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
              props.onChangeText("eINumber", value)
            }
            placeholder=""
            value={props.eINumber ? props.eINumber : ""}
          />
        </Col>
      </Row>
    </Grid>
  );
};
