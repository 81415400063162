import gql from "graphql-tag";
import moment from "moment";
import { v4 as uuid, v4 } from "uuid";
import { ApolloClient, useMutation } from "@apollo/client";
import { PromptHelper } from "Shared/PromptHelper";
import {
  FRAGMENT_PHASE,
  FRAGMENT_DATA_POINT,
  FRAGMENT_SESSION
} from "./Fragments";
import _ from "lodash";
import {
  CollectionMethodEnum,
  DataPointStateEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import {
  dataPointUndoDiscreteTrial,
  dataPointUndoDiscreteTrialVariables
} from "Shared/Api/dataPointUndoDiscreteTrial";
import {
  dataPointRecordDiscreteTrial,
  dataPointRecordDiscreteTrialVariables
} from "Shared/Api/dataPointRecordDiscreteTrial";
import {
  dataPointScoreDiscreteTrial,
  dataPointScoreDiscreteTrialVariables
} from "Shared/Api/dataPointScoreDiscreteTrial";
import {
  dataPointRestartDiscreteTrial,
  dataPointRestartDiscreteTrialVariables
} from "Shared/Api/dataPointRestartDiscreteTrial";
import {
  dataPointEndDiscreteTrial,
  dataPointEndDiscreteTrialVariables
} from "Shared/Api/dataPointEndDiscreteTrial";
import {
  dataPointSetPromptLevel,
  dataPointSetPromptLevelVariables,
  dataPointSetPromptLevel_dataPointSetPromptLevel_phase
} from "Shared/Api/dataPointSetPromptLevel";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { dataPointRestartTaskAnalysis } from "Shared/Api/dataPointRestartTaskAnalysis";
import { ProgramDataHelper } from "./ProgramDataHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { useCallback } from "react";

const MUTATION_RECORD_DISCRETE_TRIAL = gql`
  mutation dataPointRecordDiscreteTrial(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $result: TrialResultEnum!
    $occurredAt: DateTime!
    $prompt: String!
    $targetId: GUID
  ) {
    dataPointRecordTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
      result: $result
      occurredAt: $occurredAt
      prompt: $prompt
      targetId: $targetId
    ) {
      dataPoint {
        ...DataPointFragment
      }
      autoRecordDataPoints {
        ...DataPointFragment
      }
      autoRecordSessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_SESSION}
`;

const MUTATION_UNDO_DISCRETE_TRIAL = gql`
  mutation dataPointUndoDiscreteTrial($pointId: GUID!, $studentId: GUID!) {
    dataPointUndoTrial(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_END_DISCRETE_TRIAL = gql`
  mutation dataPointEndDiscreteTrial(
    $pointId: GUID!
    $studentId: GUID!
    $occurredAt: DateTime!
  ) {
    dataPointComplete(
      pointId: $pointId
      studentId: $studentId
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SCORE_DISCRETE_TRIAL = gql`
  mutation dataPointScoreDiscreteTrial(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $correct: Int!
    $attempted: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: $correct
      attempted: $attempted
      duration: 0
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_RESTART_DISCRETE_TRIAL = gql`
  mutation dataPointRestartDiscreteTrial(
    $oldPointId: GUID!
    $newPointId: GUID!
    $studentId: GUID!
  ) {
    dataPointRestart(
      oldPointId: $oldPointId
      newPointId: $newPointId
      studentId: $studentId
    ) {
      session {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_SESSION}
`;

const MUTATION_SET_PROMPT_LEVEL = gql`
  mutation dataPointSetPromptLevel(
    $pointId: GUID!
    $studentId: GUID!
    $prompt: String!
  ) {
    dataPointSetPromptLevel(
      pointId: $pointId
      studentId: $studentId
      prompt: $prompt
    ) {
      phase {
        ...PhaseFragment
      }
    }
  }
  ${FRAGMENT_PHASE}
`;

const readDataPointAndPhase = (
  client: ApolloClient<object>,
  dataPointId: string,
  optimistic = true
) => {
  const dataPoint: DataPointFragment | null = client.readFragment(
    {
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${dataPointId}`
    },
    optimistic
  );

  if (!dataPoint) {
    throw Error("Could not find data point");
  }

  const phase: PhaseFragment | null = client.readFragment(
    {
      fragment: FRAGMENT_PHASE,
      fragmentName: "PhaseFragment",
      id: `PhaseType:${dataPoint.phaseId}`
    },
    optimistic
  );

  return { dataPoint, phase };
};

const addDiscreteTrial = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null,
  trialId: string,
  result: TrialResultEnum,
  occurredAt: string,
  createdById: string,
  createdByName: string,
  targetId?: string,
  targetDescription?: string
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  modifiedDataPoint.trials.push({
    __typename: "TrialType",
    id: trialId,
    index: dataPoint.trials.length,
    result,
    prompt: PromptHelper.PROMPT_INDEPENDENT,
    stepId: null,
    targetId: targetId ?? null,
    occurredAt: occurredAt,
    duration: null,
    createdById,
    createdByName
  });

  if (modifiedDataPoint.state === DataPointStateEnum.NOT_STARTED) {
    modifiedDataPoint.state = DataPointStateEnum.IN_PROGRESS;
  }

  if (modifiedDataPoint.trials.length === phase?.numberOfTrials) {
    modifiedDataPoint.state = DataPointStateEnum.COMPLETED;
    modifiedDataPoint.completedAt = occurredAt;
  }

  return modifiedDataPoint;
};

const scoreDiscreteTrial = (
  dataPoint: DataPointFragment,
  correct: number,
  attempted: number,
  occurredAt: DateTime
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  modifiedDataPoint.trials = [];
  modifiedDataPoint.correctOverride = correct;
  modifiedDataPoint.attemptedOverride = attempted;
  modifiedDataPoint.durationOverride = 0;
  modifiedDataPoint.active = true;
  modifiedDataPoint.method = CollectionMethodEnum.PAPER;

  modifiedDataPoint.completedAt = occurredAt;
  modifiedDataPoint.state = DataPointStateEnum.COMPLETED;

  return modifiedDataPoint;
};

const undoDiscreteTrial = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  modifiedDataPoint.trials.pop();

  if (
    modifiedDataPoint.state === DataPointStateEnum.COMPLETED ||
    modifiedDataPoint.state === DataPointStateEnum.ABANDONED
  ) {
    modifiedDataPoint.state = DataPointStateEnum.IN_PROGRESS;
  }

  return modifiedDataPoint;
};

const completeDiscreteTrial = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null,
  occurredAt: DateTime
) => {
  const modifiedDataPoint = _.cloneDeep(dataPoint);

  const trials = modifiedDataPoint.trials.filter(
    trial =>
      trial.result !== TrialResultEnum.NOT_APPLICABLE &&
      trial.result !== TrialResultEnum.NONE
  );

  if (
    trials.length === 0 &&
    typeof modifiedDataPoint.attemptedOverride !== "number"
  ) {
    modifiedDataPoint.state = DataPointStateEnum.ABANDONED;
  } else {
    modifiedDataPoint.state = DataPointStateEnum.COMPLETED;
    modifiedDataPoint.completedAt = occurredAt;
  }

  return modifiedDataPoint;
};

export const buildEndDiscreteTrialDataPointFragment = (
  dataPoint: DataPointFragment,
  phase: PhaseFragment | null,
  occurredAt: DateTime
) => {
  return completeDiscreteTrial(dataPoint, phase, occurredAt);
};

const setPhasePrompt = (phase: PhaseFragment | null, prompt: string) => {
  const modifiedPhase = _.cloneDeep(phase);
  if (modifiedPhase) {
    modifiedPhase.prompt = prompt;
  }
  return modifiedPhase;
};

export const useDataPointUndoDiscreteTrialMutation = () => {
  const [mutate, { client, data, error }] = useMutation<
    dataPointUndoDiscreteTrial,
    dataPointUndoDiscreteTrialVariables
  >(MUTATION_UNDO_DISCRETE_TRIAL);

  const handleUndoDiscreteTrial = useCallback(
    (pointId: string, studentId: string) => {
      const { dataPoint, phase } = readDataPointAndPhase(client, pointId);
      const optimisticResponse: dataPointUndoDiscreteTrial = {
        dataPointUndoTrial: {
          __typename: "DataPointOutput",
          dataPoint: undoDiscreteTrial(dataPoint, phase)
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId },
        update: (cache, { data }) => {
          const point = data?.dataPointUndoTrial?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return { dataPointUndoDiscreteTrial: handleUndoDiscreteTrial, error, data };
};

export const useDataPointRecordDiscreteTrialMutation = () => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const [mutate, { client, error, data }] = useMutation<
    dataPointRecordDiscreteTrial,
    dataPointRecordDiscreteTrialVariables
  >(MUTATION_RECORD_DISCRETE_TRIAL);

  const handleDataPointRecordDiscreteTrial = useCallback(
    (
      pointId: string,
      studentId: string,
      result: TrialResultEnum,
      prompt: string,
      targetId?: string,
      targetDescription?: string
    ) => {
      const occurredAt = moment().utc().toISOString();
      const trialId = uuid();
      const { dataPoint, phase } = readDataPointAndPhase(client, pointId);
      const point = addDiscreteTrial(
        dataPoint,
        phase,
        trialId,
        result,
        occurredAt,
        threadUserContext.userId,
        threadUserContext.userName,
        targetId,
        targetDescription
      );
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointRecordDiscreteTrial | undefined =
        undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          id: `DataPointType:${pointId}`,
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointRecordTrial: {
            __typename: "DataPointRecordOutput",
            dataPoint: point,
            autoRecordDataPoints: null,
            autoRecordSessions: null
          }
        };
        DataPointDataHelper.autoRecordOptimisticResponse(
          client.cache,
          studentId,
          threadUserContext.userId,
          threadUserContext.userName,
          optimisticResponse.dataPointRecordTrial!
        );
      }
      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          studentId,
          trialId,
          result,
          occurredAt,
          prompt,
          targetId
        },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointRecordTrial?.dataPoint;
          if (point && point.state === DataPointStateEnum.COMPLETED) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
          const output = data?.dataPointRecordTrial;
          if (output) {
            DataPointDataHelper.updateChartDataPointsWithAutoRecordOutput(
              cache,
              output
            );
          }
        }
      });
    },
    [
      mutate,
      client,
      online,
      threadUserContext.userId,
      threadUserContext.userName
    ]
  );
  return {
    dataPointRecordDiscreteTrial: handleDataPointRecordDiscreteTrial,
    error,
    data
  };
};

export const useDataPointScoreDiscreteTrialMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointScoreDiscreteTrial,
    dataPointScoreDiscreteTrialVariables
  >(MUTATION_SCORE_DISCRETE_TRIAL);

  const handleDataPointScoreDiscreteTrial = useCallback(
    (
      pointId: string,
      phaseId: string,
      studentId: string,
      correct: number,
      attempted: number,
      occurredAt: DateTime
    ) => {
      const { dataPoint } = readDataPointAndPhase(client, pointId);
      const optimisticResponse: dataPointScoreDiscreteTrial = {
        dataPointSaveScore: {
          __typename: "DataPointOutput",
          dataPoint: scoreDiscreteTrial(
            dataPoint,
            correct,
            attempted,
            occurredAt
          )
        }
      };

      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          phaseId,
          studentId,
          correct,
          attempted,
          occurredAt
        },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return {
    dataPointScoreDiscreteTrial: handleDataPointScoreDiscreteTrial,
    error,
    data
  };
};

export const useDataPointRestartDiscreteTrialMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    dataPointRestartDiscreteTrial,
    dataPointRestartDiscreteTrialVariables
  >(MUTATION_RESTART_DISCRETE_TRIAL);

  const handleDataPointRestartDiscreteTrial = useCallback(
    (pointId: string, studentId: string, programId: string) => {
      const session = ProgramDataHelper.readProgramSession(
        client.cache,
        programId
      );
      if (!session?.session || !session?.currentPhase) {
        throw new Error("Unable to locate program session to restart");
      }
      const newPointId = v4();
      const now = moment();
      const point = DataPointDataHelper.readCreateDataPoint(
        client.cache,
        studentId,
        programId,
        ProgramTypeEnum.DTT,
        session.currentPhase.id,
        newPointId,
        now,
        threadUserContext.userId,
        threadUserContext.userName,
        DataPointStateEnum.NOT_STARTED
      );
      const optimisticResponse: dataPointRestartTaskAnalysis = {
        dataPointRestart: {
          __typename: "DataPointRestartOutput",
          session: {
            __typename: "SessionType",
            id: programId,
            drawerPosition: session.session?.drawerPosition,
            dataPoint: point,
            phase: session.currentPhase
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { oldPointId: pointId, newPointId, studentId }
      });
    },
    [mutate, client.cache, threadUserContext.userId, threadUserContext.userName]
  );
  return {
    dataPointRestartDiscreteTrial: handleDataPointRestartDiscreteTrial,
    error,
    data
  };
};

export const useDataPointEndDiscreteTrialMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointEndDiscreteTrial,
    dataPointEndDiscreteTrialVariables
  >(MUTATION_END_DISCRETE_TRIAL);

  const handleDataPointEndDiscreteTrial = useCallback(
    (pointId: string, studentId: string) => {
      const { dataPoint, phase } = readDataPointAndPhase(client, pointId);
      const occurredAt = moment().utc().toISOString();
      const optimisticResponse: dataPointEndDiscreteTrial = {
        dataPointComplete: {
          __typename: "DataPointOutput",
          dataPoint: buildEndDiscreteTrialDataPointFragment(
            dataPoint,
            phase,
            occurredAt
          )
        }
      };

      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, occurredAt },
        update: (cache, { data }) => {
          const point = data?.dataPointComplete?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate, client]
  );
  return {
    dataPointEndDiscreteTrial: handleDataPointEndDiscreteTrial,
    error,
    data
  };
};

export const useDataPointSetPromptLevelMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointSetPromptLevel,
    dataPointSetPromptLevelVariables
  >(MUTATION_SET_PROMPT_LEVEL);

  const handleDataPointSetPromptLevel = useCallback(
    (pointId: string, studentId: string, prompt: string) => {
      const { phase } = readDataPointAndPhase(client, pointId);
      const optimisticResponse: dataPointSetPromptLevel = {
        dataPointSetPromptLevel: {
          __typename: "PhaseOutput",
          phase: setPhasePrompt(
            phase,
            prompt
          ) as dataPointSetPromptLevel_dataPointSetPromptLevel_phase
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, prompt }
      });
    },
    [mutate, client]
  );
  return {
    dataPointSetPromptLevel: handleDataPointSetPromptLevel,
    error,
    data
  };
};
