import React from "react";
import { Col, Row, Heading, Avatar } from "@raudabaugh/thread-ui";
import { getInitials } from "../Shared/Initials";

interface IChartHeaderProps {
  studentName: string;
  schoolYear: string;
  title: string;
}

export const ChartHeader = (props: IChartHeaderProps) => {
  return (
    <Row type="flex" grow={1}>
      <Col span={14} margin="2px 0 0 0">
        <Row type="flex" justify="start" grow={1}>
          <Col margin="0 12px 0 0">
            <Avatar size="large">
              <Heading level={3} margin="12px 0 0 0">
                {getInitials(props.studentName)}
              </Heading>
            </Avatar>
          </Col>
          <Col>
            <Heading level={4} weight="bold">
              {props.studentName}
            </Heading>
            <Heading level={6}>{props.title}</Heading>
          </Col>
        </Row>
      </Col>
      <Col span={10} margin="2px 0 0 0">
        <Row type="flex" justify="end" grow={1}>
          <Heading margin="0 8px 0 0" level={6} weight="bold">
            School Year:
          </Heading>
          <Heading level={6}>{props.schoolYear}</Heading>
        </Row>
      </Col>
    </Row>
  );
};
