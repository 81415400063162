import React from "react";
import { Col, Row, ListItem, Paragraph, Icon } from "@raudabaugh/thread-ui";

interface ITargetDetailRowProps {
  description: string;
  masteredDate: string;
}

export const TargetDetailRow = (props: ITargetDetailRowProps) => {
  return (
    <ListItem>
      <Row type="flex" grow={1} wrap gutter={2}>
        <Col>
          <Icon type="fa-bullseye fas" />
        </Col>
        <Col grow={1}>
          <Paragraph margin="0" wrap>
            {props.description}
          </Paragraph>
        </Col>
        <Col>{props.masteredDate}</Col>
      </Row>
    </ListItem>
  );
};
