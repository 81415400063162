import React from "react";
import {
  Col,
  Row,
  Heading,
  PrintPage,
  List,
  ListItem,
  Divider,
  Paragraph
} from "@raudabaugh/thread-ui";
import { TargetDetailRow } from "./TargetDetailRow";
import { ChartHeader } from "../ChartHeader";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { TargetStateEnum } from "Shared/Api/globalTypes";

interface ITargetListProps {
  studentName: string;
  title: string;
  schoolYear: string;
  currentPhase: PhaseFragment | null;
  program: ProgramFragment;
}

export const TargetListPage = (props: ITargetListProps) => {
  var masteredTargets = props.program.targets.filter(
    t => t.state === TargetStateEnum.MASTERED
  );
  return (
    <PrintPage key="targets">
      <ChartHeader {...props} />
      <Row grow={1} gutter={44} padding="26px 0">
        <Col span={6}>
          <Heading level={6} weight="bold">
            Mastery Criterion
          </Heading>
          {props.currentPhase?.criterionForMastery.map((criterion, index) => (
            <Heading key={index} level={6}>
              • {Math.round(criterion.minPercentage * 100)}% x{" "}
              {criterion.pointsAnalyzed}
            </Heading>
          ))}
          {(props.currentPhase?.unitGoal ?? "").length > 0 && (
            <>
              <Divider />
              <Heading level={6} weight="bold">
                Short Term Objective
              </Heading>
              <Paragraph>{props.currentPhase?.unitGoal}</Paragraph>
            </>
          )}
          {(props.program.programGoal ?? "").length > 0 && (
            <>
              <Divider />
              <Heading level={6} weight="bold">
                Long Term Objective
              </Heading>
              <Paragraph>{props.program.programGoal}</Paragraph>
            </>
          )}
        </Col>
        <Col span={18}>
          <List size="small">
            <ListItem>
              <Row type="flex" justify="end" grow={1}>
                <Heading level={6} weight="bold">
                  Mastered Date
                </Heading>
              </Row>
            </ListItem>
            {masteredTargets.map((target, index) => (
              <TargetDetailRow
                key={index}
                description={target.targetDescription ?? ""}
                masteredDate={target.mastered ?? ""}
              />
            ))}
            <ListItem />
          </List>
        </Col>
      </Row>
    </PrintPage>
  );
};
