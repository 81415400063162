import {
  Button,
  Card,
  Col,
  Grid,
  Heading,
  Row,
  Steps,
  Step,
  Progress,
  Illustration,
  Modal
} from "@raudabaugh/thread-ui";
import React, { useEffect, useState } from "react";
import DiscardChangesModal from "Shared/DiscardChangesModal";
import { NotificationsHelper } from "../Shared/NotificationsHelper";
import _ from "lodash";
import { StaffInvitation } from "./StaffInvitation";
import { StaffPassword } from "./StaffPassword";
import { StaffProfile } from "./StaffProfile";
import {
  IStaffOnboardingModel,
  IStaffOnboardingStep,
  StaffOnboardingStepEnum
} from "./Types";
import connectingImage from "Shared/Images/undraw-connecting-teams-3-1-pgn.svg";
import {
  useOnboardingStaffQuery,
  useStaffConfirmMutation,
  useStaffProfileMutation,
  useStaffSetupMutation
} from "OnboardingAccess/OnboardingStaffData";
import { LoadingScreen } from "Shared/LoadingScreen";
import { ApolloError } from "@apollo/client";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import {
  OnboardingStaffConfirmInput,
  OnboardingStaffProfileInput,
  OnboardingStaffSetupInput,
  StaffOnboardingStatusEnum
} from "Shared/OnboardingApi/globalTypes";
import { useHistory } from "react-router-dom";

export interface IOnboardingStaffProps {
  staffId: string;
  orgId: string;
  code: string;
}

export const OnboardingStaff = (props: IOnboardingStaffProps) => {
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [model, setModel] = useState<IStaffOnboardingModel>({});
  const [valid, setValid] = useState({
    INVITATION: false,
    PROFILE: false,
    PASSWORD: false
  });
  const {
    loading: staffLoading,
    data: staffData,
    error: staffError
  } = useOnboardingStaffQuery(props.staffId, props.orgId, props.code);
  const { confirm: staffConfirm } = useStaffConfirmMutation();
  const { profile: staffProfile } = useStaffProfileMutation();
  const { setup: staffSetup } = useStaffSetupMutation();
  const history = useHistory();

  useEffect(() => {
    const staff = staffData?.onboardingStaff;
    if (staff) {
      setModel({
        firstName: staff.staffFirstName,
        lastName: staff.staffLastName,
        phoneNumber: staff.staffPhoneNumber ?? "",
        role: staff.staffRole
      });
    }
  }, [staffData]);
  useEffect(() => {
    const staff = staffData?.onboardingStaff;
    if (
      staff &&
      staff.status === StaffOnboardingStatusEnum.SETUP &&
      !valid.PASSWORD
    ) {
      setValid(v => ({ ...v, PASSWORD: true }));
    }
  }, [staffData, setValid, valid]);
  useEffect(() => {
    if (staffError) {
      if (!staffError.networkError) {
        history.replace(RoutePathEnum.ONBOARDING_STAFF_INVALID);
      } else {
        NotificationsHelper.ApolloErrorNotification({
          error: staffError,
          title: "Unable to access your invitation"
        });
      }
    }
  }, [staffError, history]);

  const onboarding = staffData?.onboardingStaff;
  if (staffLoading) {
    return <LoadingScreen />;
  }

  const handleStepClick = (step: IStaffOnboardingStep) => {
    const clickedStep = steps.indexOf(step);
    if (clickedStep < currentStep) {
      setCurrentStep(clickedStep);
    }
  };

  const handleValidation = (tab: StaffOnboardingStepEnum, value: boolean) => {
    const clone = _.cloneDeep(valid);
    if (clone[tab] !== value) {
      clone[tab] = value;
      setValid(clone);
    }
  };

  const handleCancel = () => {
    if (currentStep === 0) {
      Modal.confirm({
        title: "Are you sure you wish to decline this invitation?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => handleInvitation(false)
      });
      return;
    }
    setCancelAlertOpen(true);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleContinue = () => {
    const key = steps[currentStep].key;
    if (!valid[key]) {
      setShowValidation(true);
      NotificationsHelper.BasicErrorNotification(
        "Missing Required Info",
        "Please review sections marked in red for incomplete information before submitting."
      );
      return;
    }
    setLoading(true);
    switch (steps[currentStep].key) {
      case StaffOnboardingStepEnum.INVITATION:
        handleInvitation(true);
        break;

      case StaffOnboardingStepEnum.PROFILE:
        handleProfile();
        break;

      case StaffOnboardingStepEnum.PASSWORD:
        handlePassword();
        break;
    }
  };

  const handleProfile = () => {
    const input: OnboardingStaffProfileInput = {
      staffId: props.staffId,
      orgId: props.orgId,
      code: props.code,
      firstName: model.firstName ?? "",
      lastName: model.lastName ?? "",
      phoneNumber: model.phoneNumber ?? ""
    };
    staffProfile(input)
      .then(() => {
        setCurrentStep(currentStep + 1);
        setShowValidation(false);
      })
      .catch((error: ApolloError) => {
        if (!error.networkError) {
          history.replace(RoutePathEnum.ONBOARDING_STAFF_INVALID);
        } else {
          NotificationsHelper.ApolloErrorNotification({
            error,
            title: "Failed to complete your account setup."
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handlePassword = () => {
    const input: OnboardingStaffSetupInput = {
      staffId: props.staffId,
      orgId: props.orgId,
      code: props.code,
      password: model.password ?? ""
    };
    staffSetup(input)
      .then(() => {
        history.replace(RoutePathEnum.ONBOARDING_STAFF_COMPLETE);
      })
      .catch((error: ApolloError) => {
        if (!error.networkError) {
          history.replace(RoutePathEnum.ONBOARDING_STAFF_INVALID);
        } else {
          NotificationsHelper.ApolloErrorNotification({
            error,
            title: "Failed to complete your account setup."
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const closeCancelAlert = () => {
    setCancelAlertOpen(false);
  };

  const closeCancelAlertAndDialog = () => {
    history.replace(RoutePathEnum.ONBOARDING_STAFF_CANCELED);
  };

  const steps: IStaffOnboardingStep[] = [
    {
      key: StaffOnboardingStepEnum.INVITATION,
      component: StaffInvitation,
      label: "Confirm your invitation"
    },
    {
      key: StaffOnboardingStepEnum.PROFILE,
      component: StaffProfile,
      label: "Update your profile"
    },
    {
      key: StaffOnboardingStepEnum.PASSWORD,
      component: StaffPassword,
      label: "Choose a password"
    }
  ];

  const renderCurrentStep = () => {
    var step = steps[currentStep];
    if (!step.component) {
      return (
        <Heading level={5} color="error">
          Step is not yet implemented.
        </Heading>
      );
    }
    var Component = step.component; // as any;
    return (
      <Component
        staffId={props.staffId}
        orgId={props.orgId}
        code={props.code}
        model={model}
        status={
          staffData?.onboardingStaff?.status ?? StaffOnboardingStatusEnum.NONE
        }
        showValidation={showValidation}
        onValidation={handleValidation}
        onChange={handleChange}
      />
    );
  };

  const handleChange = (model: IStaffOnboardingModel) => {
    setModel(model);
  };

  const handleInvitation = (accept: boolean) => {
    const input: OnboardingStaffConfirmInput = {
      staffId: props.staffId,
      orgId: props.orgId,
      code: props.code,
      accept
    };
    staffConfirm(input)
      .then(() => {
        if (accept) {
          setCurrentStep(currentStep + 1);
          setShowValidation(false);
        } else {
          history.replace(RoutePathEnum.ONBOARDING_STAFF_DECLINED);
        }
      })
      .catch((error: ApolloError) => {
        if (!error.networkError) {
          history.replace(RoutePathEnum.ONBOARDING_STAFF_INVALID);
        } else {
          NotificationsHelper.ApolloErrorNotification({
            error,
            title: "Failed to complete your confirmation."
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid type="flex" width="100%" color="default" variation={1}>
      <LoadingScreen tip="Processing..." loading={loading} />
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} topOffset={20} />
      </Row>
      <Row type="flex" padding="16px">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col>
          <Heading level={3}>Welcome to {onboarding?.organizationName}</Heading>
          <Heading level={5}>
            Set up your Thread Learning account in 3 easy steps
          </Heading>
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
      </Row>
      <Row type="flex" justify="space-between" margin="0 16px 16px 16px">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={0} md={20} lg={16} xl={12}>
          <Steps current={currentStep}>
            {steps.map(step => (
              <Step onClick={() => handleStepClick(step)} title={step.label} />
            ))}
          </Steps>
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={6} md={0}>
          <Progress
            type="circle"
            width={56}
            percent={((currentStep + 1) * 100) / steps.length}
            format={() => `${currentStep + 1} of ${steps.length}`}
          />
        </Col>
        <Col xs={18} md={0}>
          <Row type="flex" justify="end">
            <Heading level={3}>{steps[currentStep].label}</Heading>
          </Row>
          {currentStep < steps.length - 1 && (
            <Row type="flex" justify="end">
              <Heading level={5}>Next: {steps[currentStep + 1].label}</Heading>
            </Row>
          )}
        </Col>
      </Row>
      <Row type="flex" color="default" variation={2} padding="16px">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={24} md={20} lg={16} xl={12}>
          {renderCurrentStep()}
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
      </Row>
      <Row>
        <Card
          left="0"
          bottom="0"
          position="fixed"
          width="100%"
          margin="0"
          bordered={true}
          color="default"
          variation={4}
          padding="1px 0 0 0"
          zIndex={1}
        >
          <Row type="flex" margin="0 -5px 0 0">
            <Col xs={24} color="default" variation={1}>
              <Row type="flex" margin="16px">
                <Col grow={1} />
                <Col margin="0px 12px 0px 0px">
                  <Button size="default" onClick={handleCancel}>
                    {currentStep === 0 ? "Decline" : "Cancel"}
                  </Button>
                </Col>
                {currentStep > 0 && (
                  <Col margin="0px 12px 0px 0px">
                    <Button onClick={handleBack} size="default">
                      Back
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    onClick={handleContinue}
                    size="default"
                    type="primary"
                  >
                    {currentStep === 0 ? "Accept" : "Continue"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Row>
      <DiscardChangesModal
        intercept={false}
        visible={cancelAlertOpen}
        setVisible={setCancelAlertOpen}
        onCancel={closeCancelAlert}
        onOk={closeCancelAlertAndDialog}
      />
    </Grid>
  );
};
