import { memo, useCallback, useMemo } from "react";
import {
  Button,
  Dropdown,
  IClickParam,
  Icon,
  Media,
  Menu,
  MenuItem
} from "@raudabaugh/thread-ui";
import { IProgramItem } from "./Types";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";

interface IProgramTableMenuProps {
  item: IProgramItem;
  onAbc: (id: string) => void;
  onChart: (id: string, type: ProgramTypeEnum) => void;
  onArchive: (id: string) => void;
  onCurriculumAdd?: (id: string) => void;
  onEnterScore: (id: string) => void;
  onEdit?: (id: string, type: ProgramTypeEnum) => void;
  onInfo: (id: string) => void;
}

const ProgramTableMenu = (props: IProgramTableMenuProps) => {
  const {
    item,
    onAbc,
    onChart,
    onArchive,
    onCurriculumAdd,
    onEdit,
    onEnterScore,
    onInfo
  } = props;

  const stopPropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  const handleInfoClick = useCallback(
    e => {
      e.stopPropagation();
      onInfo(item.programId);
    },
    [item.programId, onInfo]
  );

  const handleChartClick = useCallback(
    e => {
      e.stopPropagation();
      onChart(item.programId, item.type);
    },
    [item.programId, item.type, onChart]
  );

  const handleClick = useCallback(
    (p: IClickParam) => {
      console.log("menu handle click");
      p.domEvent.stopPropagation();
      switch (p.key) {
        case "abc":
          onAbc(item.programId);
          break;
        case "chart":
          onChart(item.programId, item.type);
          break;
        case "archive":
          onArchive(item.programId);
          break;
        case "curriculumadd":
          if (onCurriculumAdd) {
            onCurriculumAdd(item.programId);
          }
          break;
        case "score":
          onEnterScore(item.programId);
          break;
        case "edit":
          if (onEdit) {
            onEdit(item.programId, item.type);
          }
          break;
      }
    },
    [item, onAbc, onChart, onArchive, onEdit, onEnterScore, onCurriculumAdd]
  );

  const overlay = useMemo(
    () => (
      <Menu onClick={handleClick} selectable={false} mode="vertical">
        <MenuItem key="abc">
          <Icon type="fa-edit fas" width="24px" />
          Add ABC Data
        </MenuItem>
        {!item.locked && (
          <MenuItem key="score">
            <Icon type="fa-star fas" width="28px" />
            Enter Score
          </MenuItem>
        )}
        <MenuItem key="chart">
          <Icon type="fa-chart-line fas" width="28px" />
          Chart
        </MenuItem>
        {onEdit && (
          <MenuItem key="edit">
            <Icon type="fa-edit fas" width="28px" />
            Edit Program
          </MenuItem>
        )}
        <MenuItem key="archive">
          <Icon type="fa-archive fas" width="28px" />
          Archive
        </MenuItem>
        {onCurriculumAdd && (
          <MenuItem key="curriculumadd">
            <Icon type="fa-plus fas" width="28px" />
            Add to My Curriculum
          </MenuItem>
        )}
      </Menu>
    ),
    [handleClick, item, onEdit, onCurriculumAdd]
  );

  return (
    <>
      <Media.Md orLarger>
        <Button type="primary" borderless ghost onClick={handleInfoClick}>
          <Icon type="fa-info-circle far" size="20px" />
        </Button>
        <Button type="primary" borderless ghost onClick={handleChartClick}>
          <Icon type="fa-chart-line fas" size="20px" />
        </Button>
      </Media.Md>
      <Dropdown trigger={["click"]} overlay={overlay}>
        <Button type="primary" borderless ghost onClick={stopPropagation}>
          <Icon type="fa-ellipsis-v fas" size="20px" />
        </Button>
      </Dropdown>
    </>
  );
};

export default memo(ProgramTableMenu);
