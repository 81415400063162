import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Heading,
  IClickParam,
  IColumnProps,
  Icon,
  Menu,
  MenuItem,
  Table,
  Row,
  Paragraph
} from "@raudabaugh/thread-ui";
import moment from "moment";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { IABCDataByStudent_aBCDataByStudent } from "Shared/Api/IABCDataByStudent";

interface IAbcDataTableProps {
  abcData: IABCDataByStudent_aBCDataByStudent[];
  hasEditAbcDataPermission: boolean;
  hasSoftDeleteAbcDataPermission: boolean;
  onEditRecord: (record: IABCDataByStudent_aBCDataByStudent) => void;
  onDeleteRecord: (record: IABCDataByStudent_aBCDataByStudent) => void;
}

export const AbcDataTable = (props: IAbcDataTableProps) => {
  const { threadUserContext } = useThreadContext();
  const [expandedRowsKeys, setExpandedRowKeys] = useState<string[]>([]);
  const defineRecord = (record: IABCDataByStudent_aBCDataByStudent) => {
    return record!.id!;
  };

  const handleMenuClick = (
    p: IClickParam,
    record: IABCDataByStudent_aBCDataByStudent
  ) => {
    p.domEvent.stopPropagation();

    switch (p.key) {
      case "delete":
        props.onDeleteRecord(record);
        break;
      case "edit":
        props.onEditRecord(record);
        break;
    }
  };

  const getExpandableParagraph = (
    recordId: string,
    paragraphText?: string | null
  ) => {
    const isExpanded = expandedRowsKeys.findIndex(e => e === recordId) > -1;
    return (
      <Paragraph ellipsis={!isExpanded ? { rows: 3 } : undefined}>
        {paragraphText}
      </Paragraph>
    );
  };

  const sortText = (a: string | null, b: string | null) => {
    if (a == null) {
      a = "";
    }
    if (b == null) {
      b = "";
    }
    return a.localeCompare(b);
  };

  const expandIconOnClick = (record: IABCDataByStudent_aBCDataByStudent) => {
    const clone = [...expandedRowsKeys];
    const index = expandedRowsKeys.findIndex(e => e === record.id);

    if (index > -1) {
      delete clone[index];
      setExpandedRowKeys(clone);
    } else {
      clone.push(record.id);
      setExpandedRowKeys(clone);
    }
  };

  const columns: Array<IColumnProps<IABCDataByStudent_aBCDataByStudent>> = [
    {
      dataIndex: "",
      key: "",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        const isExpanded =
          expandedRowsKeys.findIndex(e => e === record.id) > -1;

        return (
          <Icon
            type={isExpanded ? "fa-chevron-down fas" : "fa-chevron-right fas"}
            onClick={() => expandIconOnClick(record)}
          />
        );
      },
      title: ""
    },
    {
      dataIndex: "date",
      defaultSortOrder: "descend",
      key: "date",
      width: "125px",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        let date = moment(record!.date).format("YYYY-MM-DD h:mm A");
        return (
          <Heading id={record!.date!.replace(/\s/g, "")} level={6}>
            {date}
          </Heading>
        );
      },
      sorter: (a, b) => moment(a.date).diff(moment(b.date)),
      title: "Date"
    },
    {
      dataIndex: "createdBy",
      key: "createdBy",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return <Heading level={6}>{record.createdByName}</Heading>;
      },
      sorter: (a, b) => sortText(a.createdByName, b.createdByName),
      title: "User"
    },
    {
      dataIndex: "setting",
      key: "setting",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return getExpandableParagraph(record.id, record.setting);
      },
      sorter: (a, b) => sortText(a.setting, b.setting),
      title: "Setting"
    },
    {
      dataIndex: "antecedent",
      key: "antecedent",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return getExpandableParagraph(record.id, record.antecedent);
      },
      sorter: (a, b) => sortText(a.antecedent, b.antecedent),
      title: "Antecedent"
    },
    {
      dataIndex: "behavior",
      key: "behavior",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return getExpandableParagraph(record.id, record.behavior);
      },
      sorter: (a, b) => sortText(a.behavior, b.behavior),
      title: "Behavior"
    },
    {
      dataIndex: "consequence",
      key: "consequence",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return getExpandableParagraph(record.id, record.consequence);
      },
      sorter: (a, b) => sortText(a.consequence, b.consequence),
      title: "Consequence"
    },
    {
      dataIndex: "note",
      key: "note",
      render: (
        text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        index: number
      ) => {
        return getExpandableParagraph(record.id, record.note);
      },
      sorter: (a, b) => sortText(a.note, b.note),
      title: "Notes"
    },
    {
      dataIndex: "admin",
      key: "admin",
      title: "",
      sorter: false,
      render: (
        _text: any,
        record: IABCDataByStudent_aBCDataByStudent,
        _: number
      ) => {
        const canEditAbcData =
          props.hasEditAbcDataPermission ||
          threadUserContext.userId === record.createdById;
        const canDeleteAbcData =
          props.hasSoftDeleteAbcDataPermission ||
          threadUserContext.userId === record.createdById;
        const actions = (
          <Menu
            onClick={e => {
              handleMenuClick(e, record);
            }}
            mode="vertical"
          >
            {canEditAbcData && (
              <MenuItem key="edit">
                <Icon type="fa-edit fas" width="28px"></Icon>Edit
              </MenuItem>
            )}
            {canDeleteAbcData && (
              <MenuItem key="delete">
                <Icon type="fa-trash fas" width="28px"></Icon>Delete
              </MenuItem>
            )}
          </Menu>
        );

        if (canEditAbcData || canDeleteAbcData) {
          return (
            <Dropdown trigger={["click"]} overlay={actions}>
              <Row>
                <Button ghost={true} borderless={true}>
                  <Icon
                    color="default"
                    variation={10}
                    type="fa-ellipsis-v fas"
                  />
                </Button>
              </Row>
            </Dropdown>
          );
        } else {
          return <></>;
        }
      }
    }
  ];
  return (
    <Table
      columns={columns}
      dataSource={props.abcData}
      rowKey={defineRecord}
      pagination={false}
    />
  );
};
