import gql from "graphql-tag";
import { ApolloCache, useMutation } from "@apollo/client";
import { FRAGMENT_DATA_POINT, FRAGMENT_SESSION } from "./Fragments";
import { v4 as uuid, v5 as deterministicUuid } from "uuid";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import {
  dataPointUndoFrequency,
  dataPointUndoFrequencyVariables
} from "Shared/Api/dataPointUndoFrequency";
import {
  dataPointMarkFrequency,
  dataPointMarkFrequencyVariables
} from "Shared/Api/dataPointMarkFrequency";
import {
  dataPointScoreFrequency,
  dataPointScoreFrequencyVariables
} from "Shared/Api/dataPointScoreFrequency";
import { PromptHelper } from "Shared/PromptHelper";
import {
  DataPointStateEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import moment from "moment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { refetchProgramSessionsQuery } from "./ProgramData";
import { useCallback } from "react";

const MUTATION_MARK_FREQUENCY = gql`
  mutation dataPointMarkFrequency(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $occurredAt: DateTime!
  ) {
    dataPointMarkTrial(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      trialId: $trialId
      result: PLUS
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
      autoRecordDataPoints {
        ...DataPointFragment
      }
      autoRecordSessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_SESSION}
`;

const MUTATION_UNDO_FREQUENCY = gql`
  mutation dataPointUndoFrequency($pointId: GUID!, $studentId: GUID!) {
    dataPointUndoTrial(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SCORE_FREQUENCY = gql`
  mutation dataPointScoreFrequency(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $score: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: $score
      attempted: 0
      duration: 0
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const readDataPointAndAddTrial = (
  cache: ApolloCache<object>,
  studentId: string,
  pointId: string,
  phaseId: string,
  programId: string,
  trialId: string,
  occurredAt: moment.Moment,
  createdById: string,
  createdByName: string
) => {
  const point: DataPointFragment = DataPointDataHelper.readCreateDataPoint(
    cache,
    studentId,
    programId,
    ProgramTypeEnum.FREQUENCY,
    phaseId,
    pointId,
    occurredAt,
    createdById,
    createdByName,
    DataPointStateEnum.COMPLETED
  );

  point.trials.push({
    __typename: "TrialType",
    id: trialId,
    index: point.trials.length,
    result: TrialResultEnum.PLUS,
    prompt: PromptHelper.PROMPT_INDEPENDENT,
    stepId: null,
    targetId: null,
    occurredAt: occurredAt.utc().toISOString(),
    duration: null,
    createdById,
    createdByName
  });
  return point;
};

export const useDataPointUndoFrequencyMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointUndoFrequency,
    dataPointUndoFrequencyVariables
  >(MUTATION_UNDO_FREQUENCY);

  const handleDataPointUndoFrequency = useCallback(
    (pointId: string, studentId: string) => {
      const optimisticResponse = buildUndoFrequencyOptimisticResponse(
        client.cache,
        pointId
      );
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId }
      });
    },
    [client.cache, mutate]
  );
  return {
    dataPointUndoFrequency: handleDataPointUndoFrequency,
    error,
    data
  };
};

const buildUndoFrequencyOptimisticResponse = (
  cache: ApolloCache<object>,
  pointId: string
) => {
  const point = DataPointDataHelper.readDataPoint(cache, pointId, true);
  if (point == null) {
    throw new Error("Unable to locate data point to undo");
  }
  point.trials.pop();
  const optimisticResponse: dataPointUndoFrequency = {
    dataPointUndoTrial: {
      __typename: "DataPointOutput",
      dataPoint: point
    }
  };
  return optimisticResponse;
};

export const useDataPointMarkFrequencyMutation = () => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const [mutate, { client, error, data }] = useMutation<
    dataPointMarkFrequency,
    dataPointMarkFrequencyVariables
  >(MUTATION_MARK_FREQUENCY);

  const handleDataPointMarkFrequency = useCallback(
    (
      programId: string,
      phaseId: string,
      studentId: string,
      createdById: string,
      createdByName: string
    ) => {
      const trialId = uuid();
      const now = moment();
      const today = now.clone().startOf("day");
      const pointId = deterministicUuid(today.format("YYYY-MM-DD"), phaseId);
      const point = readDataPointAndAddTrial(
        client.cache,
        studentId,
        pointId,
        phaseId,
        programId,
        trialId,
        now,
        createdById,
        createdByName
      );
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointMarkFrequency | undefined = undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.cache.writeFragment({
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          id: `DataPointType:${pointId}`,
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointMarkTrial: {
            __typename: "DataPointRecordOutput",
            dataPoint: point,
            autoRecordDataPoints: null,
            autoRecordSessions: null
          }
        };
        DataPointDataHelper.autoRecordOptimisticResponse(
          client.cache,
          studentId,
          threadUserContext.userId,
          threadUserContext.userName,
          optimisticResponse.dataPointMarkTrial!
        );
      }
      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          phaseId,
          studentId,
          trialId,
          occurredAt: now.utc().toISOString()
        },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointMarkTrial?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateSessionDataPoint(cache, point);
          }
          const output = data?.dataPointMarkTrial;
          if (output) {
            DataPointDataHelper.updateChartDataPointsWithAutoRecordOutput(
              cache,
              output
            );
          }
        }
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [
      mutate,
      client,
      online,
      threadUserContext.userId,
      threadUserContext.userName
    ]
  );
  return {
    dataPointMarkFrequency: handleDataPointMarkFrequency,
    error,
    data
  };
};

export const useDataPointScoreFrequencyMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointScoreFrequency,
    dataPointScoreFrequencyVariables
  >(MUTATION_SCORE_FREQUENCY);

  const handleDataPointScoreFrequency = useCallback(
    (
      programId: string,
      phaseId: string,
      studentId: string,
      score: number,
      occurredAt: DateTime
    ) => {
      const day = moment(occurredAt).startOf("day");
      const pointId = deterministicUuid(day.format("YYYY-MM-DD"), phaseId);
      return mutate!({
        variables: { pointId, phaseId, studentId, score, occurredAt },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateSessionDataPoint(cache, point);
          }
        }
      });
    },
    [mutate]
  );
  return {
    dataPointScoreFrequency: handleDataPointScoreFrequency,
    error,
    data
  };
};
