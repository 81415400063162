import {
  Card,
  Col,
  Heading,
  List,
  ListItem,
  Row,
  Link
} from "@raudabaugh/thread-ui";
import { getInitials } from "../../Shared/Initials";
import { ProtectedAvatar } from "../../Shared/ProtectedAvatar";
import React, { useCallback, useEffect, useState } from "react";
import { StaffFragment } from "Shared/Api/StaffFragment";
import {
  IStaffSearchVariables,
  IStaffSearch_staffSearch_edges
} from "Shared/Api/IStaffSearch";
import { StaffRoleEnum } from "Shared/Api/globalTypes";
import { useStaffSearchQuery } from "DataAccess/StaffData";
import { EditStaffAssignments } from "../EditStaffAssignments";
import { v4 as uuid } from "uuid";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface IInstructorsAssignedProps {
  instructors: StaffFragment[];
  onInstructorSelect?: (staffId: string) => void;
  onSave: (
    instructorsToSave: StaffFragment[],
    instructorsToRemove: StaffFragment[]
  ) => void;
  onSelect: (staffId: string) => void;
  canEdit: boolean;
}

interface IQueryState extends IStaffSearchVariables {
  editInstructors: boolean;
  contextId: string;
}

const DefaultItems = 40;
const DefaultMoreItems = 150;

export const InstructorsAssigned = (props: IInstructorsAssignedProps) => {
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [instructorsToSave, setInstructorsToSave] = useState<StaffFragment[]>(
    []
  );
  const [instructorsToRemove, setInstructorsToRemove] = useState<
    StaffFragment[]
  >([]);
  const [availableInstructors, setAvailableInstructors] = useState<
    IStaffSearch_staffSearch_edges[]
  >([]);
  const [totalAvailableInstructors, setTotalAvailableInstructors] = useState(0);

  const notEmpty = <T extends {}>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined;
  };

  const [queryState, setQueryState] = useState<IQueryState>({
    forString: "",
    first: DefaultItems,
    after: null,
    sortFirst: props.instructors.map(s => s.id),
    roleFilters: [
      StaffRoleEnum.ADMIN,
      StaffRoleEnum.INSTRUCTOR,
      StaffRoleEnum.SUPERVISOR
    ],
    activeOnly: true,
    contextId: uuid(),
    editInstructors: false
  });

  const handleSave = useCallback(() => {
    props.onSave([...instructorsToSave], [...instructorsToRemove]);
    setInstructorsToSave([]);
    setInstructorsToRemove([]);
    setAvailableInstructors([]);
    setQueryState({
      ...queryState,
      first: DefaultItems,
      after: null,
      sortFirst: props.instructors.map(s => s.id),
      contextId: uuid(),
      editInstructors: false
    });
  }, [instructorsToRemove, instructorsToSave, props, queryState]);

  const handleEditInstructorsClick = useCallback(() => {
    setAvailableInstructors([]);
    setQueryState({
      ...queryState,
      first: DefaultItems,
      after: null,
      sortFirst: props.instructors.map(s => s.id),
      contextId: uuid(),
      editInstructors: true
    });
  }, [props.instructors, queryState]);

  const handleCancelEdit = useCallback(() => {
    setInstructorsToSave([]);
    setInstructorsToRemove([]);
    setAvailableInstructors([]);
    setQueryState({
      ...queryState,
      contextId: uuid(),
      editInstructors: false
    });
  }, [queryState]);

  const { error: instructorsError, data: instructorsData } =
    useStaffSearchQuery(queryState, {
      fetchPolicy: "no-cache"
    });

  useEffect(() => {
    let cancelled = false;
    if (!cancelled && instructorsData && instructorsData.staffSearch) {
      const totalCount = +instructorsData.staffSearch!.totalCount! || 0;
      if (totalCount > 0) {
        const tempData = instructorsData.staffSearch.edges!.filter(notEmpty);
        const tempInstructors = Array.from(
          new Set(availableInstructors.concat(tempData))
        );

        const tempHasMore = totalCount > tempInstructors.length;
        setAvailableInstructors(tempInstructors);
        setTotalAvailableInstructors(totalCount);
        setHasMore(tempHasMore);
      } else {
        setAvailableInstructors([]);
        setTotalAvailableInstructors(0);
        setHasMore(false);
      }
    }

    if (instructorsError) {
      NotificationsHelper.ErrorNotification({
        error: instructorsError,
        title: "Failed to Load Data"
      });
    }

    return () => {
      cancelled = true;
    };
    // NOTE: added due to availableInstructors causing 2x calls
    // eslint-disable-next-line
  }, [instructorsError, instructorsData]);

  const handleInstructorSearch = useCallback(
    (search: string) => {
      setQueryState({
        ...queryState,
        forString: search,
        after: null,
        first: DefaultItems
      });
      setAvailableInstructors([]);
      setHasMore(true);
    },
    [queryState]
  );

  const handleLoadMore = useCallback(() => {
    hasMore &&
      setQueryState({
        ...queryState,
        after:
          availableInstructors && availableInstructors.length
            ? availableInstructors[availableInstructors.length - 1]?.cursor!
            : null,
        first: DefaultMoreItems
      });
  }, [hasMore, queryState, availableInstructors]);

  const handleAssignmentChanged = (
    instructor: StaffFragment,
    checked: boolean
  ) => {
    const newInstructorsToSave = instructorsToSave;
    const newInstructorsToRemove = instructorsToRemove;
    if (checked) {
      setInstructorsToRemove(
        newInstructorsToRemove.filter(
          (i: StaffFragment) => i.id !== instructor.id
        )
      );
      setInstructorsToSave(newInstructorsToSave.concat(instructor));
    } else {
      setInstructorsToRemove(newInstructorsToRemove.concat(instructor));
      setInstructorsToSave(
        newInstructorsToSave.filter(
          (i: StaffFragment) => i.id !== instructor.id
        )
      );
    }
  };

  return (
    <>
      <Card
        margin={"16px 0px"}
        variation={1}
        width="100%"
        title={
          <Row type="flex" justify="space-between">
            <Col>Instructors</Col>
            {props.canEdit && (
              <Heading
                id="Edit_Staff_Assignment_Button"
                level={6}
                color="primary"
              >
                <Link
                  icon="fa-edit fas"
                  iconPosition="right"
                  onClick={() => handleEditInstructorsClick()}
                >
                  Edit
                </Link>
              </Heading>
            )}
          </Row>
        }
      >
        <List size="small">
          {props.instructors && props.instructors.length > 0
            ? props.instructors.map(
                (instructor: StaffFragment, index: number) => {
                  return (
                    <ListItem key={index}>
                      <Row
                        margin="0 8px"
                        type="flex"
                        align="middle"
                        onClick={() => props.onSelect(instructor.id)}
                      >
                        <Col>
                          <ProtectedAvatar
                            color="primary"
                            alt={instructor.fullName}
                            size="default"
                            src={instructor.avatarUrl}
                          >
                            {getInitials(instructor.fullName!)}
                          </ProtectedAvatar>
                        </Col>
                        <Col>
                          <Heading level={5} margin="0px 0px 0px 16px">
                            {instructor.fullName}
                          </Heading>
                        </Col>
                      </Row>
                    </ListItem>
                  );
                }
              )
            : "No current instructors"}
          <EditStaffAssignments
            onSave={handleSave}
            onAssignmentChanged={handleAssignmentChanged}
            visible={queryState.editInstructors}
            onClose={handleCancelEdit}
            assignments={props.instructors!}
            peopleToSave={instructorsToSave}
            peopleToRemove={instructorsToRemove}
            totalPeople={totalAvailableInstructors}
            shownPeople={availableInstructors.map(i => i.node)}
            hasMore={hasMore}
            onSearch={handleInstructorSearch}
            onLoadMore={handleLoadMore}
          />
        </List>
      </Card>
    </>
  );
};
