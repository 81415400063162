import gql from "graphql-tag";
import moment from "moment";
import { PromptHelper } from "Shared/PromptHelper";
import { IntervalHelper } from "../Shared/IntervalHelper";
import {
  FRAGMENT_DATA_POINT,
  FRAGMENT_PHASE,
  FRAGMENT_SESSION
} from "./Fragments";
import { QUERY_PROGRAM_SESSIONS } from "./Queries";
import {
  dataPointRestartInterval,
  dataPointRestartIntervalVariables
} from "../Shared/Api/dataPointRestartInterval";
import {
  ApolloCache,
  ApolloClient,
  useApolloClient,
  useMutation
} from "@apollo/client";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  dataPointUndoInterval,
  dataPointUndoIntervalVariables
} from "Shared/Api/dataPointUndoInterval";
import {
  dataPointRecordInterval,
  dataPointRecordIntervalVariables
} from "Shared/Api/dataPointRecordInterval";
import {
  dataPointEndInterval,
  dataPointEndIntervalVariables
} from "Shared/Api/dataPointEndInterval";
import {
  dataPointScoreInterval,
  dataPointScoreIntervalVariables
} from "Shared/Api/dataPointScoreInterval";
import {
  DataPointStateEnum,
  ProgramTypeEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { DataPointFragment } from "Shared/Api/DataPointFragment";
import { DataPointDataHelper } from "./DataPointDataHelper";
import { dataPointRestartTaskAnalysis } from "Shared/Api/dataPointRestartTaskAnalysis";
import { ProgramDataHelper } from "./ProgramDataHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { refetchProgramSessionsQuery } from "./ProgramData";
import { useCallback } from "react";

const MUTATION_RECORD_INTERVAL = gql`
  mutation dataPointRecordInterval(
    $pointId: GUID!
    $studentId: GUID!
    $trialId: GUID!
    $result: TrialResultEnum!
    $occurredAt: DateTime!
    $index: Int!
    $targetId: GUID
  ) {
    dataPointRecordTrial(
      pointId: $pointId
      studentId: $studentId
      trialId: $trialId
      result: $result
      occurredAt: $occurredAt
      index: $index
      targetId: $targetId
    ) {
      dataPoint {
        ...DataPointFragment
      }
      autoRecordDataPoints {
        ...DataPointFragment
      }
      autoRecordSessions {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
  ${FRAGMENT_SESSION}
`;

const MUTATION_UNDO_INTERVAL = gql`
  mutation dataPointUndoInterval($pointId: GUID!, $studentId: GUID!) {
    dataPointUndoTrial(pointId: $pointId, studentId: $studentId) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_END_INTERVAL = gql`
  mutation dataPointEndInterval(
    $pointId: GUID!
    $studentId: GUID!
    $occurredAt: DateTime!
  ) {
    dataPointEnd(
      pointId: $pointId
      studentId: $studentId
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_SCORE_INTERVAL = gql`
  mutation dataPointScoreInterval(
    $pointId: GUID!
    $phaseId: GUID!
    $studentId: GUID!
    $correct: Int!
    $attempted: Int!
    $occurredAt: DateTime!
  ) {
    dataPointSaveScore(
      pointId: $pointId
      phaseId: $phaseId
      studentId: $studentId
      correct: $correct
      attempted: $attempted
      duration: 0
      occurredAt: $occurredAt
    ) {
      dataPoint {
        ...DataPointFragment
      }
    }
  }
  ${FRAGMENT_DATA_POINT}
`;

const MUTATION_RESTART_INTERVAL = gql`
  mutation dataPointRestartInterval(
    $oldPointId: GUID!
    $newPointId: GUID!
    $studentId: GUID!
  ) {
    dataPointRestart(
      oldPointId: $oldPointId
      newPointId: $newPointId
      studentId: $studentId
    ) {
      session {
        ...SessionFragment
      }
    }
  }
  ${FRAGMENT_SESSION}
`;

export const useDataPointUndoIntervalMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointUndoInterval,
    dataPointUndoIntervalVariables
  >(MUTATION_UNDO_INTERVAL);

  const handleDataPointUndoInterval = useCallback(
    (pointId: string, studentId: string) => {
      const optimisticResponse = buildUndoIntervalOptimisticResponse(
        client.cache,
        pointId
      );
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId },
        update: (cache, { data }) => {
          const point = data?.dataPointUndoTrial?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { dataPointUndoInterval: handleDataPointUndoInterval, error, data };
};

const buildUndoIntervalOptimisticResponse = (
  cache: ApolloCache<object>,
  pointId: string
) => {
  const point = DataPointDataHelper.readDataPoint(cache, pointId, true);
  if (point == null) {
    throw new Error("Unable to locate data point to undo");
  }
  point.trials.pop();
  if (
    point.state === DataPointStateEnum.COMPLETED ||
    point.state === DataPointStateEnum.ABANDONED
  ) {
    point.state = DataPointStateEnum.IN_PROGRESS;
  }
  const optimisticResponse: dataPointUndoInterval = {
    dataPointUndoTrial: {
      __typename: "DataPointOutput",
      dataPoint: point
    }
  };
  return optimisticResponse;
};

export const useDataPointRecordIntervalMutation = () => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const client = useApolloClient();
  const [mutate, { error, data }] = useMutation<
    dataPointRecordInterval,
    dataPointRecordIntervalVariables
  >(MUTATION_RECORD_INTERVAL);

  const handleDataPointRecordInterval = useCallback(
    (
      pointId: string,
      studentId: string,
      result: TrialResultEnum,
      index: number
    ) => {
      const occurredAt = moment().utc().toISOString();
      const trialId = uuid();
      const point = readDataPointAndAddTrial(
        client,
        pointId,
        trialId,
        result,
        occurredAt,
        index,
        threadUserContext.userId,
        threadUserContext.userName
      );
      point.state = DataPointStateEnum.COMPLETED;
      point.completedAt = occurredAt;
      let fetchPolicy: MutationFetchPolicy | undefined = undefined;
      let optimisticResponse: dataPointRecordInterval | undefined = undefined;
      if (online) {
        // While online, don't use optimistic responses to avoid ui clobbering
        fetchPolicy = "no-cache";
        client.writeFragment({
          id: `DataPointType:${pointId}`,
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          data: point
        });
      } else {
        // While offline, optimistic responses must be used to allow mutations to be queued
        optimisticResponse = {
          dataPointRecordTrial: {
            __typename: "DataPointRecordOutput",
            dataPoint: point,
            autoRecordDataPoints: null,
            autoRecordSessions: null
          }
        };
        client.writeFragment({
          id: `DataPointType:${pointId}`,
          fragment: FRAGMENT_DATA_POINT,
          fragmentName: "DataPointFragment",
          data: optimisticResponse.dataPointRecordTrial!.dataPoint
        });
      }
      return mutate!({
        optimisticResponse,
        variables: {
          pointId,
          studentId,
          trialId,
          result,
          occurredAt,
          index
        },
        fetchPolicy,
        update: (cache, { data }) => {
          const point = data?.dataPointRecordTrial?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
          }
          const output = data?.dataPointRecordTrial;
          if (output) {
            DataPointDataHelper.updateChartDataPointsWithAutoRecordOutput(
              cache,
              output
            );
          }
        }
      }).catch(error => {
        refetchProgramSessionsQuery(client, studentId);
        throw error;
      });
    },
    [mutate, client, online, threadUserContext.userId, threadUserContext.userName]
  );
  return {
    dataPointRecordInterval: handleDataPointRecordInterval,
    error,
    data
  };
};

const readDataPointAndAddTrial = (
  client: ApolloClient<object>,
  pointId: string,
  trialId: string,
  result: TrialResultEnum,
  occurredAt: string,
  index: number,
  createdById: string,
  createdByName: string
) => {
  const run: DataPointFragment | null = _.cloneDeep(
    client.readFragment({
      fragment: FRAGMENT_DATA_POINT,
      fragmentName: "DataPointFragment",
      id: `DataPointType:${pointId}`
    })
  );
  if (!run) {
    throw Error("Could not find data point");
  }
  const phase: PhaseFragment | null = client.readFragment({
    fragment: FRAGMENT_PHASE,
    fragmentName: "PhaseFragment",
    id: `PhaseType:${run.phaseId}`
  });
  const priorResult = IntervalHelper.getIntervalResult(
    index,
    run.attemptedOverride,
    run.correctOverride,
    phase?.defaultTrialResult ?? TrialResultEnum.NOT_APPLICABLE,
    run.trials
  );
  if (run.attemptedOverride != null) {
    if (
      result === TrialResultEnum.NOT_APPLICABLE &&
      result !== priorResult &&
      run.attemptedOverride > 0
    ) {
      run.attemptedOverride--;
    } else if (
      priorResult === TrialResultEnum.NOT_APPLICABLE &&
      result !== priorResult &&
      run.attemptedOverride < (phase?.numberOfTrials ?? 0)
    ) {
      run.attemptedOverride++;
    }
  }
  const trial = run.trials.find(trial => trial.index === index);
  if (trial) {
    trial.result = result;
    return run;
  }
  run.trials.push({
    __typename: "TrialType",
    id: trialId,
    index,
    result,
    prompt: PromptHelper.PROMPT_INDEPENDENT,
    stepId: null,
    targetId: null,
    duration: null,
    occurredAt: occurredAt,
    createdById,
    createdByName
  });
  if (!run.startedAt) {
    run.startedAt = moment().utc().toISOString();
  }
  if (run.state === DataPointStateEnum.NOT_STARTED) {
    run.state = DataPointStateEnum.IN_PROGRESS;
  } else if (run.state === DataPointStateEnum.IN_PROGRESS) {
    run.state = DataPointStateEnum.COMPLETED;
  }
  return run;
};

export const useDataPointScoreIntervalMutation = () => {
  const [mutate, { error, data }] = useMutation<
    dataPointScoreInterval,
    dataPointScoreIntervalVariables
  >(MUTATION_SCORE_INTERVAL);

  const handleDataPointScoreInterval = useCallback(
    (
      pointId: string,
      phaseId: string,
      studentId: string,
      correct: number,
      attempted: number,
      date: DateTime
    ) => {
      date = moment().utc().toISOString();
      return mutate!({
        refetchQueries: [
          {
            query: QUERY_PROGRAM_SESSIONS,
            variables: { studentId }
          }
        ],
        variables: {
          pointId,
          phaseId,
          studentId,
          correct,
          attempted,
          occurredAt: date
        },
        update: (cache, { data }) => {
          const point = data?.dataPointSaveScore?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate]
  );

  return {
    dataPointScoreInterval: handleDataPointScoreInterval,
    error,
    data
  };
};

export const useDataPointRestartIntervalMutation = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { client, error, data }] = useMutation<
    dataPointRestartInterval,
    dataPointRestartIntervalVariables
  >(MUTATION_RESTART_INTERVAL);

  const handleDataPointRestartInterval = useCallback(
    (pointId: string, studentId: string, programId: string) => {
      const session = ProgramDataHelper.readProgramSession(
        client.cache,
        programId
      );
      if (!session?.session || !session?.currentPhase) {
        throw new Error("Unable to locate program session to restart");
      }
      const newPointId = uuid();
      const now = moment();
      const point = DataPointDataHelper.readCreateDataPoint(
        client.cache,
        studentId,
        programId,
        ProgramTypeEnum.INTERVAL,
        session.currentPhase.id,
        newPointId,
        now,
        threadUserContext.userId,
        threadUserContext.userName,
        DataPointStateEnum.NOT_STARTED
      );
      const optimisticResponse: dataPointRestartTaskAnalysis = {
        dataPointRestart: {
          __typename: "DataPointRestartOutput",
          session: {
            __typename: "SessionType",
            id: programId,
            drawerPosition: session.session?.drawerPosition,
            dataPoint: point,
            phase: session.currentPhase
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { newPointId, oldPointId: pointId, studentId }
      });
    },
    [mutate, client.cache, threadUserContext.userId, threadUserContext.userName]
  );
  return {
    dataPointRestartInterval: handleDataPointRestartInterval,
    error,
    data
  };
};

export const useDataPointEndIntervalMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    dataPointEndInterval,
    dataPointEndIntervalVariables
  >(MUTATION_END_INTERVAL);

  const handleDataPointEndInterval = useCallback(
    (pointId: string, studentId: string) => {
      const occurredAt = moment().utc().toISOString();
      const optimisticResponse = buildEndIntervalOptimisticResponse(
        client.cache,
        pointId,
        occurredAt
      );
      return mutate!({
        optimisticResponse,
        variables: { pointId, studentId, occurredAt },
        update: (cache, { data }) => {
          const point = data?.dataPointEnd?.dataPoint;
          if (point) {
            DataPointDataHelper.updateChartDataPoints(cache, point);
            DataPointDataHelper.updateLastRun(cache, point);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { dataPointEndInterval: handleDataPointEndInterval, error, data };
};

export const buildEndIntervalDataPointFragment = (
  cache: ApolloCache<object>,
  pointId: string,
  occurredAt: string
) => {
  const point = DataPointDataHelper.readDataPoint(cache, pointId, true);
  if (!point) {
    throw new Error("Unable to locate data point to end");
  }
  const phase: PhaseFragment | null = cache.readFragment({
    fragment: FRAGMENT_PHASE,
    fragmentName: "PhaseFragment",
    id: `PhaseType:${point.phaseId}`
  });
  if (!phase) {
    throw new Error("Unable to locate data point to end");
  }

  const recorded = point.trials.filter(
    t =>
      t.result !== TrialResultEnum.NOT_APPLICABLE &&
      t.result !== TrialResultEnum.NONE
  ).length;
  let unrecorded = 0;
  if (
    phase.lengthOfEachInterval &&
    phase.defaultTrialResult !== TrialResultEnum.NOT_APPLICABLE &&
    point.trials.length > 0
  ) {
    const secondsSinceStart = moment(occurredAt).diff(
      point.trials[0].occurredAt,
      "seconds"
    );

    const numberOfTrials = phase?.numberOfTrials ?? 0;
    const lastTrialIndex =
      Math.min(
        secondsSinceStart,
        (numberOfTrials - 1) * phase.lengthOfEachInterval
      ) / phase.lengthOfEachInterval;
    if (lastTrialIndex < numberOfTrials)
      for (let i = 0; i <= lastTrialIndex; i++) {
        if (!point.trials.find(t => t.index === i)) {
          unrecorded++;
        }
      }
  }
  point.attemptedOverride = recorded + unrecorded;
  point.completedAt = occurredAt;
  point.state = DataPointStateEnum.COMPLETED;
  return point;
};

const buildEndIntervalOptimisticResponse = (
  cache: ApolloCache<object>,
  pointId: string,
  occurredAt: string
) => {
  const point = buildEndIntervalDataPointFragment(cache, pointId, occurredAt);
  const optimisticResponse: dataPointEndInterval = {
    dataPointEnd: {
      __typename: "DataPointOutput",
      dataPoint: point
    }
  };
  return optimisticResponse;
};
