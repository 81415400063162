import {
  Card,
  Grid,
  Icon,
  IClickParam,
  Menu,
  MenuItem,
  Row,
  SubMenu,
  Badge,
  Affix
} from "@raudabaugh/thread-ui";
import { useCallback, useMemo } from "react";
import { SideMenuHeader } from "./SideMenuHeader";
import { findPermission } from "./RolesMap";
import { Permissions } from "../Shared/Api/globalTypes";
import { SettingsHelper } from "./SettingsHelper";
import { OrganizationSettingsEnum } from "../App";
import { OfflineSyncStatus } from "./OfflineSyncStatus";
import { XamarinHelper } from "./XamarinHelper";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { RoutePathEnum } from "../Routes/RoutePathEnum";
import { LabelHelper } from "./LabelHelper";
import { useHistory } from "react-router";
import { IRouteState } from "Routes/IRouteState";

interface ISideMenuProps {
  path: string;
  onCloseMenu: () => void;
  onSelect: (option: RoutePathEnum) => void;
  messageCount: number;
}

export const SideMenu = (props: ISideMenuProps) => {
  const { threadUserContext } = useThreadContext();
  const history = useHistory();
  const { onCloseMenu, onSelect } = props;

  const selectedKeys: RoutePathEnum[] = useMemo(() => {
    const state = history.location.state as IRouteState;
    if (state?.sideMenu === RoutePathEnum.NONE) {
      return [];
    }
    if (state?.sideMenu) {
      return [state.sideMenu];
    }
    return [];
  }, [history.location.state]);

  const handleMenuSelect = useCallback(
    (param: IClickParam) => {
      onCloseMenu();
      onSelect(param.key as RoutePathEnum);
    },
    [onCloseMenu, onSelect]
  );

  const showDataCollection = SettingsHelper.boolean(
    threadUserContext,
    OrganizationSettingsEnum.MOBILE_DATA_COLLECTION,
    true
  );

  const showNotifications = SettingsHelper.boolean(
    threadUserContext,
    OrganizationSettingsEnum.MOBILE_ALERTS,
    true
  );

  const showCurriculum = true;

  const canViewNotifications = findPermission(
    threadUserContext.role,
    Permissions.VIEW_NOTIFICATIONS
  );

  return (
    <Grid color="navbar" variation={9} type="flex" height="100vh">
      <Row>
        <Card width="100%" padding="0" margin="0" bordered={false}>
          <SideMenuHeader />
        </Card>
      </Row>
      <Row grow={1}>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectedKeys}
          onClick={handleMenuSelect}
          defaultOpenKeys={["sub1"]}
        >
          {showDataCollection && (
            <MenuItem key={RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS}>
              <Icon type="fa-bullseye fas" margin="0 8px 0 0" width="16px" />
              {LabelHelper.routePathLabel(
                RoutePathEnum.DATA_COLLECTION_LIST_PROGRAMS
              )}
            </MenuItem>
          )}
          {showDataCollection && (
            <MenuItem key={RoutePathEnum.MY_STUDENT_LIST}>
              <Icon
                type="fa-address-book far"
                margin="0 8px 0 0"
                width="16px"
              />
              {LabelHelper.routePathLabel(RoutePathEnum.MY_STUDENT_LIST)}
            </MenuItem>
          )}
          {(findPermission(
            threadUserContext.role,
            Permissions.VIEW_ALL_STAFF
          ) ||
            findPermission(
              threadUserContext.role,
              Permissions.VIEW_ASSIGNED_STUDENTS
            ) ||
            findPermission(
              threadUserContext.role,
              Permissions.VIEW_ALL_STUDENTS
            )) && (
            <MenuItem key={RoutePathEnum.PEOPLE_MANAGEMENT}>
              <Icon
                type="fa-address-book far"
                margin="0 8px 0 0"
                width="16px"
              />
              {LabelHelper.routePathLabel(RoutePathEnum.PEOPLE_MANAGEMENT)}
            </MenuItem>
          )}
          {findPermission(
            threadUserContext.role,
            Permissions.DOWNLOAD_REPORTS
          ) && (
            <SubMenu
              key={RoutePathEnum.REPORT}
              title={
                <span>
                  <Icon
                    type="fa-download fas"
                    margin="0 8px 0 0"
                    width="16px"
                  />
                  <span>
                    {LabelHelper.routePathLabel(RoutePathEnum.REPORT)}
                  </span>
                </span>
              }
            >
              <MenuItem key={RoutePathEnum.REPORT_PROGRESS}>
                <Icon type="fa-file-word fas" margin="0 8px 0 0" width="16px" />
                {LabelHelper.routePathLabel(RoutePathEnum.REPORT_PROGRESS)}
              </MenuItem>
              {!XamarinHelper.insideXamarin() && (
                <MenuItem key={RoutePathEnum.REPORT_RAW_DATA}>
                  <Icon
                    type="fa-file-excel fas"
                    margin="0 8px 0 0"
                    width="16px"
                  />
                  {LabelHelper.routePathLabel(RoutePathEnum.REPORT_RAW_DATA)}
                </MenuItem>
              )}
              <MenuItem key={RoutePathEnum.REPORT_SESSION_NOTE}>
                <Icon type="fa-file-word fas" margin="0 8px 0 0" width="16px" />
                {LabelHelper.routePathLabel(RoutePathEnum.REPORT_SESSION_NOTE)}
              </MenuItem>
              {!XamarinHelper.insideXamarin() &&
                findPermission(
                  threadUserContext.role,
                  Permissions.DOWNLOAD_ORG_REPORTS
                ) && (
                  <MenuItem key={RoutePathEnum.REPORT_BILLING}>
                    <Icon
                      type="fa-dollar-sign fas"
                      margin="0 8px 0 0"
                      width="16px"
                    />
                    {LabelHelper.routePathLabel(RoutePathEnum.REPORT_BILLING)}
                  </MenuItem>
                )}
              {!XamarinHelper.insideXamarin() && (
                <MenuItem key={RoutePathEnum.REPORT_ABC_DATA}>
                  <Icon
                    type="fa-file-excel fas"
                    margin="0 8px 0 0"
                    width="16px"
                  />
                  {LabelHelper.routePathLabel(RoutePathEnum.REPORT_ABC_DATA)}
                </MenuItem>
              )}
            </SubMenu>
          )}
          {showNotifications && canViewNotifications && (
            <MenuItem key={RoutePathEnum.NOTIFICATIONS}>
              <Icon type="fa-bell far" margin="0 8px 0 0" width="16px" />
              <Badge count={props.messageCount} offset={[25, 7]}>
                {LabelHelper.routePathLabel(RoutePathEnum.NOTIFICATIONS)}
              </Badge>
            </MenuItem>
          )}
          {showCurriculum && (
            <MenuItem key={RoutePathEnum.CURRICULUM}>
              <Icon type="fa-book fas" margin="0 8px 0 0" width="16px" />
              {LabelHelper.routePathLabel(RoutePathEnum.CURRICULUM)}
            </MenuItem>
          )}
          {threadUserContext.isSysop && (
            <SubMenu
              key={RoutePathEnum.SYSOP}
              title={
                <span>
                  <Icon
                    type="fa-hat-wizard fas"
                    margin="0 8px 0 0"
                    width="16px"
                  />
                  <span>{LabelHelper.routePathLabel(RoutePathEnum.SYSOP)}</span>
                </span>
              }
            >
              <MenuItem key={RoutePathEnum.ORGANIZATION_CREATE}>
                {LabelHelper.routePathLabel(RoutePathEnum.ORGANIZATION_CREATE)}
              </MenuItem>
              <MenuItem key={RoutePathEnum.REPORT_FINANCE}>
                {LabelHelper.routePathLabel(RoutePathEnum.REPORT_FINANCE)}
              </MenuItem>
              <MenuItem key={RoutePathEnum.REPORT_FINANCE_DETAILED}>
                {LabelHelper.routePathLabel(
                  RoutePathEnum.REPORT_FINANCE_DETAILED
                )}
              </MenuItem>
              <MenuItem key={RoutePathEnum.BENCHMARKING}>Benchmarking</MenuItem>
            </SubMenu>
          )}
        </Menu>
      </Row>
      <Affix offsetBottom={0}>
        <Row color="navbar" variation={10} padding="12px">
          <OfflineSyncStatus />
        </Row>
      </Affix>
    </Grid>
  );
};
