import React from "react";
import moment from "moment";
import { Col, Heading, Icon, Row, Tooltip } from "@raudabaugh/thread-ui";
import { useOfflineDetail } from "./ApolloHelper";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { LabelHelper } from "./LabelHelper";

export const OfflineSyncStatus: React.FC = () => {
  const { lastPoll, online } = useOfflineDetail();
  const { threadUserContext } = useThreadContext();

  return (
    <>
      {!online && (
        <Row type="flex">
          <Col grow={0}>
            <Tooltip title="Offline">
              <Icon
                color="default"
                variation={1}
                size="1em"
                type="fa-wifi-slash fas"
              />
            </Tooltip>
          </Col>
          <Col grow={0} padding="0 0 0 12px">
            <Heading color="default" variation={1} level={6}>
              Offline
            </Heading>
          </Col>
        </Row>
      )}
      {lastPoll > 0 && (
        <Row type="flex">
          <Col>
            <Tooltip title="The sync time is based on the last time all offline student data synced to this device.">
              <Heading color="default" variation={1} level={6}>
                Last Sync{" "}
                {LabelHelper.dateTimeLabel(moment(lastPoll), threadUserContext)}
              </Heading>
            </Tooltip>
          </Col>
        </Row>
      )}
    </>
  );
};
