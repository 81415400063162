import { Chart, IChartPhase, IChartData } from "@raudabaugh/thread-ui";
import { ChartHelper } from "./ChartHelper";
import { ProgramTypeEnum } from "Shared/Api/globalTypes";

interface IChartViewProps {
  programType: ProgramTypeEnum;
  phases: IChartPhase[];
  data: IChartData;
  studentId: string;
}

export const ChartView = (props: IChartViewProps) => {
  const handleFetchData = async (id: string) => {};

  return (
    <Chart
      mode="screen"
      type="Line"
      data={props.data}
      phases={props.phases}
      yAxisTitle={ChartHelper.getYAxisTitle(
        props.programType,
        props.data.stepBarName
      )}
      yAxisLabels={ChartHelper.getYAxisLabels(
        props.data.stepBarName,
        props.data.maxValue
      )}
      fetchData={handleFetchData}
      scrollToRight={true}
    />
  );
};
