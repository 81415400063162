import React from "react";
import { Grid, Row, Illustration, Heading, Col } from "@raudabaugh/thread-ui";
import connectingImage from "Shared/Images/undraw-connecting-teams-3-1-pgn.svg";

export const StaffOnboardingAborted = () => {
  return (
    <Grid type="flex" height="100vh" width="100%" color="default" variation={1}>
      <Row padding="0 0 32px 0">
        <Illustration src={connectingImage} />
      </Row>
      <Row type="flex">
        <Col xs={0} md={2} lg={4} xl={6} />
        <Col xs={24} md={20} lg={16} xl={12}>
          <Row padding="0 16px">
            <Heading level={3}>Account Setup Canceled</Heading>
          </Row>
          <Row padding="8px 16px">
            <Heading level={5}>
              The setup of your Thread Learning account has been canceled. You
              can resume the setup process at any time by clicking on the View
              Invitation link in your Welcome email.
            </Heading>
          </Row>
        </Col>
        <Col xs={0} md={2} lg={4} xl={6} />
      </Row>
    </Grid>
  );
};
