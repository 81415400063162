import { Heading, Icon, Modal, Tag, Row } from "@raudabaugh/thread-ui";
import { EnterScoreDialog } from "StudentPrograms/EnterScoreDialog";
import { ApolloError } from "@apollo/client";
import { memo, useMemo, useState } from "react";
import { IProgramItem } from "StudentPrograms/Types";
import {
  useDataPointRecordTaskAnalysisMutation,
  useDataPointRestartTaskAnalysisMutation,
  useDataPointEndTaskAnalysisMutation,
  useDataPointUndoTaskAnalysisMutation,
  useDataPointScoreTaskAnalysisMutation
} from "../DataAccess/TaskAnalysisData";
import AdvancedTrialCard from "./AdvancedTrialCard";
import { TaskAnalysisFocus } from "./Focus/TaskAnalysisFocus";
import {
  DrawerEnum,
  ProgramTypeEnum,
  StepStateEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { PhaseFragment_steps } from "Shared/Api/PhaseFragment";
import {
  useProgramDeactivateMutation,
  useProgramMoveMutation
} from "DataAccess/ProgramData";
import { NotificationsHelper } from "Shared/NotificationsHelper";

interface ITaskAnalysisCardBaseProps {
  size: "sm" | "lg";
  currentStudentId: string;
  programType: string;
  title: string;
  runId: string;
  programId: string;
  totalAttempted: number;
  totalApplicable: number;
  totalCorrect: number;
  currentStep: number;
  index: number;
  steps: PhaseFragment_steps[];
  completed: boolean;
  locked: boolean;
  setLoading: (loading: boolean) => void;
  showFocusDrawer?: () => void;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onPopup: (programId: string) => void;
  item: IProgramItem;
}

const TaskAnalysisCard = (props: ITaskAnalysisCardBaseProps) => {
  const { dataPointEndTaskAnalysis } = useDataPointEndTaskAnalysisMutation();
  const { programDeactivate } = useProgramDeactivateMutation();
  const { programMove } = useProgramMoveMutation();
  const { dataPointRecordTaskAnalysis } =
    useDataPointRecordTaskAnalysisMutation();
  const { dataPointRestartTaskAnalysis } =
    useDataPointRestartTaskAnalysisMutation();
  const { dataPointScoreTaskAnalysis } =
    useDataPointScoreTaskAnalysisMutation();
  const { dataPointUndoTaskAnalysis } = useDataPointUndoTaskAnalysisMutation();

  const [enterScoreOpen, setEnterScoreOpen] = useState(false);
  const [scoreWarningOpen, setScoreWarningOpen] = useState(false);
  const [closeWarningOpen, setCloseWarningOpen] = useState(false);
  const [endWarningOpen, setEndWarningOpen] = useState(false);
  const [focusModeOpen, setFocusModeOpen] = useState(false);

  const onClose = () => {
    props.setLoading(false);
  };
  const handleScoreWarningContinue = () => {
    setEnterScoreOpen(true);
    setScoreWarningOpen(false);
  };
  const handleScoreWarningCancel = () => {
    setScoreWarningOpen(false);
  };
  const handleEndWarningContinue = () => {
    setEndWarningOpen(false);
    setFocusModeOpen(false);
    dataPointEndTaskAnalysis(props.runId, props.currentStudentId).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };
  const handleEndWarningCancel = () => {
    setEndWarningOpen(false);
    setFocusModeOpen(false);
  };
  const handleCloseWarningContinue = () => {
    setCloseWarningOpen(false);
    closeProgram(true);
  };
  const handleCloseWarningCancel = () => {
    setCloseWarningOpen(false);
  };
  const handleLeftButtonClick = async () => {
    // handle negative result
    if (props.currentStep >= 0) {
      try {
        await dataPointRecordTaskAnalysis(
          props.runId,
          props.currentStudentId,
          props.steps[props.currentStep].id,
          TrialResultEnum.MINUS
        );
      } catch (e) {
        const error = e as Error;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    }
  };
  const handleCenterButtonClick = async () => {
    // handle n/a result
    if (props.currentStep >= 0) {
      try {
        await dataPointRecordTaskAnalysis(
          props.runId,
          props.currentStudentId,
          props.steps[props.currentStep].id,
          TrialResultEnum.NOT_APPLICABLE
        );
      } catch (e) {
        const error = e as Error;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    }
  };
  const handleRightButtonClick = async () => {
    // handle positive result
    if (props.currentStep >= 0) {
      try {
        await dataPointRecordTaskAnalysis(
          props.runId,
          props.currentStudentId,
          props.steps[props.currentStep].id,
          TrialResultEnum.PLUS
        );
      } catch (e) {
        const error = e as Error;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    }
  };
  const handleSkipClick = async () => {
    // handle skip
    if (
      props.currentStep >= 0 &&
      props.steps[props.currentStep].state === StepStateEnum.INACTIVE
    ) {
      try {
        await dataPointRecordTaskAnalysis(
          props.runId,
          props.currentStudentId,
          props.steps[props.currentStep].id,
          TrialResultEnum.NONE
        );
      } catch (e) {
        const error = e as Error;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    }
  };
  const handleEnterScoreClose = () => {
    setEnterScoreOpen(false);
  };
  const handleEnterScoreClick = () => {
    if (props.totalAttempted === 0) {
      setEnterScoreOpen(true);
    } else {
      setScoreWarningOpen(true);
    }
  };
  const handleRunAgainClick = () => {
    dataPointRestartTaskAnalysis(
      props.runId,
      props.currentStudentId,
      props.programId
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed",
        onClose
      });
    });
  };
  const handleAbcClick = () => {
    props.onAbc(props.programId);
  };
  const handleChartClick = () => {
    props.onChart(props.programId, ProgramTypeEnum.TASK_ANALYSIS);
  };
  const handleUndoClick = () => {
    dataPointUndoTaskAnalysis(props.runId, props.currentStudentId, false).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };
  const handleMoveToTopClick = () => {
    programMove(props.programId, props.currentStudentId, 0).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };
  const handleCloseClick = () => {
    if(props.item.drawer === DrawerEnum.PRIVATE_DRAWER) {
      const hasNoTrials = props.totalAttempted === 0;
      const isCompleted = props.completed;

      if(hasNoTrials || isCompleted) {
        closeProgram(false);
      } else {
        setCloseWarningOpen(true);
      }
    } else{
      closeProgram(false);
    }
  };
  const handleEndClick = () => {
    setEndWarningOpen(true);
  };
  const handleSaveScore = (
    score: number,
    date: moment.Moment,
    attempts?: number,
    phaseId?: string
  ) => {
    dataPointScoreTaskAnalysis(
      props.runId,
      phaseId ?? props.item.phaseId,
      props.currentStudentId,
      score,
      attempts!,
      date.utc().toISOString()
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  };
  const closeProgram = (confirmed: boolean) => {
    programDeactivate(props.programId, props.currentStudentId, confirmed).catch(
      (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed",
          onClose
        });
      }
    );
  };
  const handleFocusModeClose = () => {
    setFocusModeOpen(false);
  };
  const handleFocusModeOpen = () => {
    setFocusModeOpen(true);
  };

  let step: JSX.Element = (
    <Tag color="primary">
      {props.currentStep + 1}. {props.steps[props.currentStep]?.description}
    </Tag>
  );

  if (props.completed) {
    step = <Tag color="primary">Completed</Tag>;
  }
  const variation = props.completed ? 6 : 1;
  const color = props.completed ? "primary" : "default";

  const caption = useMemo(
    () => (
      <>
        {props.size === "lg" ? (
          <Row type="flex" direction="column" alignItems="center">
            <Heading color="default" level={4}>
              {props.totalCorrect}/{props.totalApplicable}
            </Heading>
            <Heading color="default" level={5}>
              {props.steps.length} steps
            </Heading>
          </Row>
        ) : (
          <Row type="flex" direction="column" alignItems="center">
            {props.completed ? (
              <Heading color="default" level={7}>Completed</Heading>
            ) : (
              <Heading color="default" level={7}>
                step {Math.max(0, props.currentStep) + 1} of {props.steps.length}
              </Heading>
            )}
          </Row>
        )}
      </>
    ),
    [
      props.completed,
      props.totalCorrect,
      props.totalApplicable,
      props.steps.length,
      props.currentStep,
      props.size
    ]
  );

  return (
    <>
      <AdvancedTrialCard
        size={props.size}
        locked={props.locked}
        skip={
          props.currentStep >= 0 &&
          props.currentStep < props.steps.length &&
          props.steps[props.currentStep].state === StepStateEnum.INACTIVE &&
          !props.completed
        }
        programType={props.programType}
        index={props.index}
        targets={step}
        leftLabel={
          <Icon
            size="1.1em"
            color={color}
            variation={variation}
            type="fa-minus fas"
          />
        }
        centerLabel={
          <Heading weight="bold" level={5} color={color} variation={variation}>
            N/A
          </Heading>
        }
        rightLabel={
          <Icon
            size="1.1em"
            color={color}
            variation={variation}
            type="fa-plus fas"
          />
        }
        title={props.title}
        caption={caption}
        disabled={props.completed}
        studentId={props.currentStudentId}
        onEnterScoreClick={!props.completed ? handleEnterScoreClick : undefined}
        onLeftButtonClick={handleLeftButtonClick}
        onCenterButtonClick={handleCenterButtonClick}
        onRightButtonClick={handleRightButtonClick}
        onAbcClick={handleAbcClick}
        onChartClick={handleChartClick}
        onUndoClick={props.currentStep > 0 ? handleUndoClick : undefined}
        onCloseClick={handleCloseClick}
        onEndClick={
          !props.completed && props.totalAttempted !== 0
            ? handleEndClick
            : undefined
        }
        onMoveToTopClick={handleMoveToTopClick}
        onRunAgainClick={props.completed ? handleRunAgainClick : undefined}
        onShowFocusDrawer={props.item.locked ? undefined : handleFocusModeOpen}
        onPopupClick={() => props.onPopup(props.programId)}
        onSkipClick={handleSkipClick}
      />
      <TaskAnalysisFocus
        completed={props.completed}
        currentStudentId={props.currentStudentId}
        index={props.index}
        item={props.item}
        onClose={handleFocusModeClose}
        open={focusModeOpen}
        programType={props.programType}
      />
      <EnterScoreDialog
        scoreLabel="Correct"
        attemptsLabel="Attempted"
        defaultAttempts={props.steps.length}
        onClose={handleEnterScoreClose}
        open={enterScoreOpen}
        onSaveScore={handleSaveScore}
        studentId={props.currentStudentId}
        programId={props.programId}
      />
      <Modal
        title="Are you sure you want to enter a score?"
        afterClose={handleScoreWarningCancel}
        visible={scoreWarningOpen}
        onOk={handleScoreWarningContinue}
        onCancel={handleScoreWarningCancel}
        okText="Enter Score"
      >
        You have unsaved trials that will be overridden immediately. You can't
        undo this action.
      </Modal>
      <Modal
        title="Are you sure you want to end this program?"
        afterClose={handleEndWarningCancel}
        visible={endWarningOpen}
        onOk={handleEndWarningContinue}
        onCancel={handleEndWarningCancel}
        cancelText="No"
        okText="Yes"
      >
        You've only recorded data for {props.totalAttempted} steps out of{" "}
        {props.steps.length} steps.
      </Modal>
      <Modal
        title="Are you sure you want to close this program?"
        afterClose={handleCloseWarningCancel}
        visible={closeWarningOpen}
        onOk={handleCloseWarningContinue}
        onCancel={handleCloseWarningCancel}
        okText="Close Program"
      >
        Your data will be deleted immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export default memo(TaskAnalysisCard);
