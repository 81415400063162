import { ChartPrint } from "Charts/ChartPrint";
import { Route, Switch, useParams } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";

interface IPrintParams {
  studentId: string;
  programId: string;
}

const PrintChart = () => {
  const { studentId, programId } = useParams<IPrintParams>();
  return <ChartPrint studentId={studentId} programId={programId} />;
};

interface PrinterRouterProps {}

export const PrintRouter = (props: PrinterRouterProps) => {
  return (
    <Switch>
      <Route path={RoutePathEnum.PRINT_CHART} exact={true}>
        <PrintChart />
      </Route>
    </Switch>
  );
};
