import React from "react";
import { Route, Switch } from "react-router-dom";
import { SessionNotesRouter } from "./SessionNotesRouter";
import { CurriculumRouter } from "./CurriculumRouter";
import { ReportsRouter } from "./ReportsRouter";
import { DataCollectionRouter } from "./DataCollectionRouter";
import { ChartsRouter } from "./ChartsRouter";
import { ArchiveRouter } from "./ArchiveRouter";
import { OrganizationRouter } from "./OrganizationRouter";
import { NotificationsRouter } from "./NotificationsRouter";
import { PeopleManagementRouter } from "./PeopleManagementRouter";
import { MyStudentRouter } from "./MyStudentRouter";
import { AbcRouter } from "./AbcRouter";
import { DefaultRouter } from "./DefaultRouter";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { RoutePathEnum } from "./RoutePathEnum";
import { MaintenanceRouter } from "./MaintenanceRouter";
import { Benchmarking } from "Shared/Benchmarking";

interface ThreadRouterProps {}

export const ThreadRouter = (props: ThreadRouterProps) => {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path={RoutePathEnum.DATA_COLLECTION}>
          <DataCollectionRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.MY_STUDENT_LIST}>
          <MyStudentRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.CHARTS}>
          <ChartsRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.SESSION_NOTES}>
          <SessionNotesRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.CURRICULUM}>
          <CurriculumRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.REPORT}>
          <ReportsRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.NOTIFICATIONS}>
          <NotificationsRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.PEOPLE_MANAGEMENT}>
          <PeopleManagementRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.ORGANIZATION}>
          <OrganizationRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.ARCHIVE}>
          <ArchiveRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.MAINTENANCE}>
          <MaintenanceRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.ABC_DATA}>
          <AbcRouter {...props} />
        </Route>
        <Route path={RoutePathEnum.BENCHMARKING}>
          <Benchmarking {...props} />
        </Route>
        <Route path={RoutePathEnum.DEFAULT}>
          <DefaultRouter {...props} />
        </Route>
      </Switch>
    </React.Suspense>
  );
};
