import { Grid, Heading, Row } from "@raudabaugh/thread-ui";
import React, { useEffect, useState } from "react";
import { StudentTable } from "./Shared/StudentTable";
import { NotificationsHelper } from "../Shared/NotificationsHelper";
import { useUserQuery } from "../DataAccess/UserData";
import { StudentProfileFragment } from "../Shared/Api/StudentProfileFragment";

export const MyStudents = () => {
  // student specific state
  const [studentData, setStudentData] = useState<StudentProfileFragment[]>([]);

  const {
    loading: userLoading,
    error: userError,
    data: userData
  } = useUserQuery(null);

  useEffect(() => {
    if (userError) {
      NotificationsHelper.ErrorNotification({
        error: userError,
        title: "Action Failed"
      });
    }
  }, [userError, userLoading]);

  useEffect(() => {
    let cancelled = false;
    if (!cancelled && userData?.user) {
      const totalCount = userData.user.assignedStudents.length || 0;
      if (totalCount > 0) {
        const tmpStudentData = userData.user.assignedStudents.map(
          assignment => assignment.student
        );
        setStudentData(tmpStudentData);
      } else {
        setStudentData([]);
      }
    }
  }, [userData]);

  return (
    <>
      <Row>
        <Grid padding="16px" width="100%">
          <Heading level={3}>My Student Data</Heading>
        </Grid>
      </Row>

      <Row color="default" variation={1} margin="16px 0 0 0">
        <Grid
          margin="-16px 0 0 0"
          color="default"
          variation={1}
          type="flex"
          height="auto"
          width="100%"
        >
          <Row>
            <StudentTable
              data={studentData}
              hasMore={false}
              myStudents={true}
            />
          </Row>
        </Grid>
      </Row>
    </>
  );
};
