import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { RoutePathEnum } from "./RoutePathEnum";
import { useStudentContext } from "./StudentContextHook";
import { useUserQuery } from "DataAccess/UserData";
import { IRouteState } from "./IRouteState";

interface DefaultStudentProps {
  path: RoutePathEnum;
}
export const DefaultStudent: React.FC<DefaultStudentProps> = ({ path }) => {
  const { data } = useUserQuery(null);
  const history = useHistory();
  let { studentId } = useStudentContext();

  if (studentId || data) {
    if (!studentId && data?.user && data.user.assignedStudents.length > 0) {
      var students = [...data.user.assignedStudents].sort((a, b) =>
        (a.student.fullName ?? "").localeCompare(b.student.fullName ?? "")
      );
      studentId = students[0].studentId;
    }
    if (!studentId) {
      return <Redirect to={{ pathname: RoutePathEnum.ERROR_NO_STUDENTS }} />;
    }
    const state = history.location.state as IRouteState;
    return (
      <Redirect
        to={{
          pathname: path.replace(":studentId", studentId ?? ""),
          state: {
            ...state,
            sideMenu: path,
            current: path,
            backLabel: undefined
          }
        }}
      />
    );
  }

  return null;
};
