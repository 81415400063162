import {
  Button,
  Card,
  Col,
  Grid,
  Heading,
  Row,
  Modal,
  Tabs,
  TabPane,
  Icon,
  Paragraph
} from "@raudabaugh/thread-ui";
import React, { useCallback, useEffect, useState } from "react";
import {
  useProgramSessionsQuery,
  useProgramCreateMutation,
  useProgramUpdateMutation
} from "../DataAccess/ProgramData";
import { LoadingScreen } from "../Shared/LoadingScreen";
import { ApolloError } from "@apollo/client";
import { LabelHelper } from "../Shared/LabelHelper";
import {
  CriterionInput,
  CurriculumTypeEnum,
  DrawerEnum,
  ProgramInput,
  ProgramTypeEnum,
  StepInput,
  TargetInput,
  TrialResultEnum
} from "../Shared/Api/globalTypes";
import DiscardChangesModal from "../Shared/DiscardChangesModal";
import { NotificationsHelper } from "../Shared/NotificationsHelper";
import { StudentFragment } from "../Shared/Api/StudentFragment";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import { ProgramTabEnum } from "./ProgramTabEnum";
import { IProgramFormStep } from "./Types";
import { ProgramDetails } from "./ProgramDetails";
import { PhaseDetails } from "./PhaseDetails";
import { Targets } from "./Targets";
import { UnitDetails } from "./UnitDetails";
import ProgramInfoModal from "./ProgramInfoModal";
import _ from "lodash";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import {
  useCurriculumQuery,
  useCurriculumTemplateQuery
} from "DataAccess/CurriculumData";
import { RouterHelper } from "Routes/RouterHelper";
import { RoutePathEnum } from "Routes/RoutePathEnum";
import { useHistory } from "react-router-dom";

export interface IProgramEditProps {
  programId: string;
  studentId: string;
  onClose: () => void;
}

export const ProgramEdit = (props: IProgramEditProps) => {
  const { programUpdate } = useProgramUpdateMutation();
  const { programCreate } = useProgramCreateMutation();
  const [phaseId, setPhaseId] = useState("");
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showProgramInfo, setShowProgramInfo] = useState(false);
  const [originalPhaseName, setOriginalPhaseName] = useState<string | null>(
    null
  );
  const [program, setProgram] = useState<ProgramInput>({});
  const [currentPhase, setCurrentPhase] = useState<PhaseFragment>();
  const [currentProgram, setCurrentProgram] = useState<ProgramFragment>();
  const { threadUserContext } = useThreadContext();
  const student = threadUserContext.assignedStudents?.find(
    s => s.studentId === props.studentId
  )?.student as StudentFragment;
  const students = [student];
  const studentName = student?.firstName ?? "Student";
  const [currentTab, setCurrentTab] = useState(ProgramTabEnum.PHASE_DETAILS);
  const [valid, setValid] = useState({
    PROGRAM_DETAILS: true,
    PHASE_DETAILS: true,
    TARGETS: true,
    UNIT_DETAILS: true
  });
  const history = useHistory();

  const { data: sessionData } = useProgramSessionsQuery(props.studentId);

  useEffect(() => {
    if (sessionData) {
      const session = sessionData.programSessions.find(
        s => s.program.id === props.programId
      );
      const program = session?.program;
      let dto = session?.currentPhase;
      setCurrentPhase(session?.currentPhase ?? undefined);
      setCurrentProgram(program ?? undefined);
      if (program && dto) {
        setPhaseId(dto.id);
        setOriginalPhaseName(dto.phaseNameOverride);
        const phase: ProgramInput = {
          drawer: program.drawer,
          pinned: program.pinned ?? program.drawer === DrawerEnum.PUBLIC_DRAWER,
          phaseSummary: dto.phaseSummary,
          phaseNameOverride: dto.phaseNameOverride,
          baselineText: dto.baselineText,
          frequencyOfDataCollectionText: dto.frequencyOfDataCollectionText,
          criterionForMasteryText: dto.criterionForMasteryText,
          defaultTrialResult: dto.defaultTrialResult,
          definitionOfBehavior: dto.definitionOfBehavior,
          instructionalCue: dto.instructionalCue,
          errorless: dto.errorless,
          programGoal: program.programGoal,
          minimumRequiredTrials: dto.minimumRequiredTrials,
          procedureDetails: dto.procedureDetails,
          tips: dto.tips,
          promptingProcedure: dto.promptingProcedure,
          materials: dto.materials,
          prerequisiteSkillsNeeded: program.prerequisiteSkillsNeeded,
          prompt: dto.prompt,
          reinforcementRatio: dto.reinforcementRatio,
          reinforcementSchedule: dto.reinforcementSchedule,
          typeOfReinforcement: dto.typeOfReinforcement,
          lengthOfEachInterval: dto.lengthOfEachInterval,
          intervalType: program.intervalType,
          unitGoal: dto.unitGoal,
          programName: program.name,
          programType: program.type,
          numberOfTrials: dto.numberOfTrials,
          templateId: program.templateId,
          curriculumId: program.curriculumId,
          unlimitedTrials:
            program.type !== ProgramTypeEnum.INTERVAL && dto.unlimitedTrials
        };
        if (
          program.type === ProgramTypeEnum.INTERVAL &&
          phase.defaultTrialResult === TrialResultEnum.NONE
        ) {
          phase.defaultTrialResult = TrialResultEnum.NOT_APPLICABLE;
        }
        const stepList: StepInput[] = [];
        dto.steps.forEach(step => {
          const stepInput: StepInput = {
            id: step.id,
            state: step.state,
            description: step.description
          };
          stepList.push(stepInput);
        });
        let targetList: TargetInput[] = [];
        program.targets.forEach(target => {
          const targetInput: TargetInput = {
            id: target.id,
            targetDescription: target.targetDescription,
            mastered: target.mastered,
            state: target.state,
            completed: target.completed
          };
          targetList.push(targetInput);
        });
        const criterionList: CriterionInput[] = [];
        dto.criterionForMastery.forEach(criterion => {
          const criterionInput: CriterionInput = {
            id: criterion.id,
            minPercentage: criterion.minPercentage,
            pointsAnalyzed: criterion.pointsAnalyzed
          };
          criterionList.push(criterionInput);
        });
        phase.steps = stepList;
        phase.targets = targetList;
        phase.criterionForMastery = criterionList;
        setProgram(phase);
        setLoading(false);
      }
    }
  }, [props.programId, props.studentId, studentName, sessionData]);

  const { data: templateData } = useCurriculumTemplateQuery(
    program.curriculumId || "",
    program.templateId || "",
    {
      skip: !(program.curriculumId && program.templateId)
    }
  );

  const { data: curriculumData } = useCurriculumQuery(
    program.curriculumId || "",
    {
      skip: !program.curriculumId
    }
  );

  const closeSaveAlert = () => {
    setSaveAlertOpen(false);
  };

  const handleCancel = () => {
    if (saveNeeded) {
      setCancelAlertOpen(true);
    } else {
      props.onClose();
    }
  };

  const closeCancelAlert = () => {
    setCancelAlertOpen(false);
  };

  const closeCancelAlertAndDialog = () => {
    props.onClose();
  };

  const handleProgramChange = (program: ProgramInput) => {
    setProgram(program);
    setSaveNeeded(true);
  };

  const handleStudentChange = (students: StudentFragment[]) => {};

  const handleValidation = (tab: ProgramTabEnum, value: boolean) => {
    const clone = _.cloneDeep(valid);
    if (clone[tab] !== value) {
      clone[tab] = value;
      setValid(clone);
    }
  };

  const handleSave = () => {
    for (const key in valid) {
      if (!valid[key]) {
        NotificationsHelper.BasicErrorNotification(
          "Missing Required Info",
          "Please review sections marked in red for incomplete information before submitting."
        );
        return;
      }
    }
    if (!props.programId) {
      save(true);
    } else {
      setSaveAlertOpen(true);
    }
  };

  const handleTemplateClick = useCallback(() => {
    if (templateData && curriculumData) {
      if (
        curriculumData?.curriculum?.type === CurriculumTypeEnum.USER &&
        curriculumData?.curriculum?.id !== threadUserContext.userId
      ) {
        Modal.info({
          okCancel: true,
          title: `${curriculumData?.curriculum?.name} - ${templateData?.curriculumTemplate?.programName}`,
          content: "This is a private curriculum. Please contact the user.",
          okText: "OK"
        });
      } else {
        RouterHelper.redirectToCurriculumViewTemplate(
          program.curriculumId!,
          program.templateId!,
          LabelHelper.routePathLabel(
            RoutePathEnum.DATA_COLLECTION_EDIT_PROGRAM
          )!,
          history,
          false
        );
      }
    }
  }, [templateData, program, history, curriculumData, threadUserContext]);

  const save = (shouldCreateNewPhase: boolean) => {
    const input: ProgramInput = _.cloneDeep(program);

    // Filter new-deleted steps and targets before saving the program
    if (input.steps && currentPhase) {
      for (const step of input.steps) {
        if (
          step.softDeleted &&
          !currentPhase.steps.find(s => s.id === step.id)
        ) {
          input.steps = input.steps.filter(s => s.id !== step.id);
        }
      }
    }
    if (input.targets && currentProgram) {
      for (const target of input.targets) {
        if (
          target.softDeleted &&
          !currentProgram.targets.find(t => t.id === target.id)
        ) {
          input.targets = input.targets.filter(t => t.id !== target.id);
        }
        delete target["isNew"];
      }
    }

    setSaving(true);
    if (!shouldCreateNewPhase) {
      programUpdate(props.programId, phaseId, props.studentId, input)
        .then(() => {
          setSaveNeeded(false);
          setSaving(false);
          props.onClose();
        })
        .catch((error: ApolloError) => {
          setSaving(false);
          setSaveAlertOpen(false);
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Save Program"
          });
        });
    } else {
      let phaseNameOverride = input.phaseNameOverride;
      if (
        originalPhaseName !== null &&
        phaseNameOverride === originalPhaseName
      ) {
        phaseNameOverride = null;
      }
      programCreate(student.id, props.programId, {
        ...input,
        phaseNameOverride
      })
        .then(() => {
          setSaveNeeded(false);
          setSaving(false);
          props.onClose();
        })
        .catch((error: ApolloError) => {
          setSaving(false);
          setSaveAlertOpen(false);
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Save Program"
          });
        });
    }
  };

  if (loading || saving) {
    return (
      <LoadingScreen
        loading={true}
        tip={saving ? "Saving changes ..." : "Loading ..."}
      />
    );
  }

  const steps: IProgramFormStep[] = [
    { key: ProgramTabEnum.PROGRAM_DETAILS, component: ProgramDetails },
    { key: ProgramTabEnum.UNIT_DETAILS, component: UnitDetails },
    { key: ProgramTabEnum.TARGETS, component: Targets },
    { key: ProgramTabEnum.PHASE_DETAILS, component: PhaseDetails }
  ];

  const renderStep = (step: IProgramFormStep) => {
    if (!step?.component) {
      return (
        <Heading level={5} color="error">
          Step is not yet implemented.
        </Heading>
      );
    }
    var Component = step.component; // as any;
    return (
      <Component
        editing={true}
        program={program}
        students={students}
        showValidation={true}
        onProgramChange={handleProgramChange}
        onStudentChange={handleStudentChange}
        onValidation={handleValidation}
      />
    );
  };

  return (
    <Grid>
      <Row type="flex" direction="column" justify="space-between" height="100%">
        <Grid margin="0 16px">
          <Row type="flex" margin="0 0 0 -16px">
            <Col xs={24}>
              <Tabs
                activeKey={currentTab}
                size="large"
                onChange={activeKey =>
                  setCurrentTab(activeKey as ProgramTabEnum)
                }
              >
                {steps.map(step => {
                  let tab = <>{LabelHelper.programTabLabel(step.key)}</>;
                  if (!valid[step.key]) {
                    tab = (
                      <>
                        <Icon color="error" type="fa-info-circle far" /> {tab}
                      </>
                    );
                  }
                  return (
                    <TabPane key={step.key} tab={tab}>
                      <Row margin="0 16px">{renderStep(step)}</Row>
                    </TabPane>
                  );
                })}
              </Tabs>
            </Col>
          </Row>
          <Card
            left="0"
            bottom="0"
            position="fixed"
            width="100%"
            margin="0"
            bordered={true}
            color="default"
            variation={4}
            padding="1px 0 0 0"
            zIndex={1}
          >
            <Row type="flex" margin="0 -5px 0 0">
              <Col
                color="navbar"
                margin="-16px 11px -12px -16px"
                grow={1}
                variation={9}
                xs={0}
                sm={0}
                md={0}
                lg={5}
                xl={4}
                xxl={4}
              />
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={19}
                xl={20}
                color="default"
                variation={1}
              >
                <Row type="flex" margin="16px" align="middle" wrap={true}>
                  {curriculumData && templateData && (
                    <>
                      <Paragraph level={6} margin="0px">
                        Modified&nbsp;from:&nbsp;
                      </Paragraph>
                      <Paragraph
                        level={6}
                        strong={true}
                        margin="0px"
                        color="primary"
                        ellipsis={true}
                        onClick={handleTemplateClick}
                      >
                        {curriculumData?.curriculum?.name} -{" "}
                        {templateData?.curriculumTemplate?.programName}
                      </Paragraph>
                    </>
                  )}
                  <Col grow={1} />
                  <Col margin="0px 12px">
                    <Button size="default" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handleSave} size="default" type="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Grid>
        <Modal
          visible={saveAlertOpen}
          onCancel={closeSaveAlert}
          title="You've chosen to save changes to this program"
          footer={
            <>
              <Button type="primary" onClick={event => save(true)}>
                Create New Phase
              </Button>
              <Button type="primary" onClick={event => save(false)}>
                Correct Old Phase
              </Button>
              <Button type="ghost" onClick={closeSaveAlert}>
                Cancel
              </Button>
            </>
          }
        >
          Do you intend to create a phase change line and a new phase or were
          you correcting a mistake in the old phase?
        </Modal>
        <DiscardChangesModal
          intercept={saveNeeded}
          visible={cancelAlertOpen}
          setVisible={setCancelAlertOpen}
          onCancel={closeCancelAlert}
          onOk={closeCancelAlertAndDialog}
        />
        {showProgramInfo && (
          <ProgramInfoModal
            studentId={props.studentId}
            programId={props.programId}
            onClose={() => setShowProgramInfo(false)}
          />
        )}
      </Row>
    </Grid>
  );
};
