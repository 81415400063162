import {
  Icon,
  Menu,
  MenuItem,
  IClickParam,
  Dropdown,
  Button,
  Notification
} from "@raudabaugh/thread-ui";
import React from "react";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";

interface IActionsMenuProps {
  id: string;
  index: number;
  errorless?: boolean;
  locked?: boolean;
  studentId: string;

  onAbcClick: () => void;
  onChartClick: () => void;
  onUndoClick?: () => void;
  onCloseClick: () => void;
  onMoveToTopClick: () => void;
  onEndClick?: () => void;
  onEnterScoreClick?: () => void;
  onRunAgainClick?: () => void;
  onErrorlessClick?: () => void;
}

export const ActionsMenu = (props: IActionsMenuProps) => {
  const online = useOnlineStatus();
  const { threadUserContext } = useThreadContext();

  const availableOffline =
    threadUserContext.assignedStudents.filter(
      s => s.studentId === props.studentId && s.availableOffline
    ).length > 0;

  const showOfflineNotification = (itemName: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${itemName} function is not available while in offline mode.`
    });
  };

  const handleClick = (p: IClickParam) => {
    switch (p.key) {
      case "abc":
        !(online || availableOffline)
          ? showOfflineNotification("Add ABC Data")
          : props.onAbcClick();
        break;
      case "chart":
        !(online || availableOffline)
          ? showOfflineNotification("Chart")
          : props.onChartClick();
        break;
      case "undo":
        props.onUndoClick?.();
        break;
      case "close":
        props.onCloseClick();
        break;
      case "end":
        props.onEndClick?.();
        break;
      case "top":
        props.onMoveToTopClick();
        break;
      case "score":
        !online
          ? showOfflineNotification("Enter Score")
          : props.onEnterScoreClick?.();
        break;
      case "run":
        props.onRunAgainClick?.();
        break;
      case "assist":
        props.onErrorlessClick?.();
        break;
    }
  };

  const overlay = (
    <Menu onClick={handleClick}>
      <MenuItem key="abc">
        <Icon type="fa-edit fas" width="24px" />
        Add ABC Data
      </MenuItem>
      <MenuItem key="chart">
        <Icon type="fa-chart-line fas" width="24px" />
        Chart
      </MenuItem>
      {props.onUndoClick && (
        <MenuItem key="undo">
          <Icon width="24px" type="fa-undo fas" />
          Undo
        </MenuItem>
      )}
      <MenuItem key="close">
        <Icon width="24px" type="fa-times-circle fas" />
        Close
      </MenuItem>
      <MenuItem id={"Index" + props.index + "Move"} key="top">
        <Icon width="24px" type="fa-arrow-up fas" />
        Move to Top
      </MenuItem>
      {props.onEndClick && (
        <MenuItem key="end">
          <Icon width="24px" type="fa-stop-circle fas" />
          End Program
        </MenuItem>
      )}
      {!props.locked && props.onEnterScoreClick && (
        <MenuItem key="score">
          <Icon width="24px" type="fa-star fas" />
          Enter Score
        </MenuItem>
      )}
      {props.onRunAgainClick && (
        <MenuItem key="run">
          <Icon width="24px" type="fa-play-circle fas" />
          Run Again
        </MenuItem>
      )}
      {props.errorless && (
        <MenuItem key="assist">
          <Icon width="24px" type="fa-hand-pointer fas" />
          Edit Prompt Level
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Dropdown trigger={["click"]} overlay={overlay}>
      <Button id={props.id} ghost={true} borderless={true}>
        <Icon type="fa-ellipsis-v fas" />
      </Button>
    </Dropdown>
  );
};
