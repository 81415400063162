import { Col, Grid, Image, Row } from "@raudabaugh/thread-ui";
import threadImage from "Shared/Images/thread_logo.svg";

export const SideMenuHeader = () => {
  return (
    <Grid color="navbar" height="56px">
      <Row type="flex">
        <Col grow={1} margin="12px 0 0 12px">
          <Image src={threadImage} alt="Thread Logo" height="44px"/>
        </Col>
      </Row>
    </Grid>
  );
};
