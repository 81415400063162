import {
  Button,
  Card,
  Col,
  Grid,
  Heading,
  Icon,
  Link,
  Notification,
  Row,
  Switch,
  Tooltip
} from "@raudabaugh/thread-ui";
import { EditStudentBasicInfo } from "./EditStudentBasicInfo";
import moment from "moment";
import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import { StudentInput } from "Shared/Api/globalTypes";
import { useStudentUpdateMutation } from "DataAccess/StudentData";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { useStaffAssignedStudentOfflineAvailabilityMutation } from "DataAccess/StaffData";
import { IStaff_staff_assignedStudents } from "Shared/Api/IStaff";
import { StaffAssignedStudentInput } from "Shared/Api/globalTypes";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import { LoadingScreen } from "../../Shared/LoadingScreen";
import { useOnlineStatus } from "Shared/ApolloHelper";
import { OrganizationSettingsEnum } from "App";
interface IStudentBasicInfoBaseProps {
  id: string;
  firstName: string;
  lastName: string;
  birthday: string;
  eINumber: string | null;
  currentStudents?: IStaff_staff_assignedStudents[];
  canEdit?: boolean;
  setLoadingScreen: (value: boolean) => void;
}

export const StudentBasicInfo = (props: IStudentBasicInfoBaseProps) => {
  const [validDate, setValidDate] = useState<boolean>(
    props.birthday
      ? moment(props.birthday!).isAfter(moment().subtract(80, "years")) &&
          moment(props.birthday!).isBefore(moment().subtract(3, "months"))
      : true
  );
  const online = useOnlineStatus();
  const [studentInfo, setStudentInfo] = useState<StudentInput>({
    birthday: props.birthday,
    eINumber: props.eINumber,
    firstName: props.firstName,
    lastName: props.lastName
  });

  const [basicStudentInfoValidCheck, setBasicStudentInfoValidCheck] =
    useState<boolean>(true);
  const [editInfo, setEditInfo] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { threadUserContext } = useThreadContext();
  const { updateStudentInfo } = useStudentUpdateMutation();
  const { toggleStudentOfflineAvailability } =
    useStaffAssignedStudentOfflineAvailabilityMutation();

  const maxStudents =
    threadUserContext.settings[OrganizationSettingsEnum.MAX_OFFLINE_STUDENTS_PER_USER]?.value ?? 15;
  const offlineStudentsTooltip = `By turning this on you make this student available offline for just your user account. There is a limit of ${maxStudents} students that can be made available offline to your account. Additionally, to continue working offline you must connect Thread to the internet every 48 hours so that your data can upload.`;

  const submitCheck = () => {
    if (
      studentInfo.firstName !== "" &&
      basicStudentInfoValidCheck &&
      (studentInfo.birthday ? validDate : true)
    ) {
      return false;
    }
    return true;
  };

  const validateStudentInfo = (isValid: boolean) => {
    setBasicStudentInfoValidCheck(isValid);
  };

  const editDate = (field: string, date: moment.Moment) => {
    var endDate = moment().subtract(3, "months");
    var startDate = moment().subtract(80, "years");
    var validDate =
      date == null ||
      (date.isValid()
        ? date.isAfter(startDate) && date.isBefore(endDate)
        : true);
    const newValue = { ...studentInfo };
    newValue[field] = date;
    setStudentInfo(newValue);
    setValidDate(validDate);
  };

  const editBasicInfoValue = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newInfo = { ...studentInfo };
    newInfo[field] = event.target.value;
    setStudentInfo(newInfo);
  };

  const handleEditInfoClick = () => {
    setEditInfo(!editInfo);
  };

  const cancelEditInfo = () => {
    const oldValues: StudentInput = {
      eINumber: props.eINumber,
      firstName: props.firstName,
      lastName: props.lastName,
      birthday: props.birthday
    };

    setEditInfo(false);
    setStudentInfo(oldValues);
  };

  const saveChanges = () => {
    saveEditedInfo({ ...studentInfo });
    setEditInfo(!editInfo);
  };

  const saveEditedInfo = (values: StudentInput) => {
    props.setLoadingScreen(true);
    values.birthday = values.birthday || undefined;
    updateStudentInfo(props.id, values!)
      .then(() => props.setLoadingScreen(false))
      .catch((error: ApolloError) =>
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        })
      );
  };
  const handleSuccess = (title: string, description: string) => {
    Notification.success({
      duration: 0,
      message: title,
      description
    });
  };

  const handleError = (error: Error) => {
    NotificationsHelper.ErrorNotification({
      error,
      title: error.name,
      description: error.message
    });
  };

  const handleOfflineAvailabilityToggle = (availableOffline: boolean) => {
    setShowSpinner(true);
    if (availableOffline) {
      const offlineStudents = props.currentStudents?.filter(
        s => s.availableOffline
      );

      if (offlineStudents && offlineStudents.length >= maxStudents) {
        setShowSpinner(false);
        NotificationsHelper.ErrorNotification({
          title: "Unable to make student available online",
          description: `You have reached the maximum number of students (${maxStudents}). In order to make this student available offline, you must first untoggle a student.`
        });
        return;
      }
    }
    const input: StaffAssignedStudentInput = { availableOffline };
    toggleStudentOfflineAvailability(threadUserContext.userId, props.id, input)
      .then(student => {
        setShowSpinner(false);
        handleSuccess(
          "Student Availability Updated",
          "Student availability successfully updated"
        );
      })
      .catch(error => {
        setShowSpinner(false);
        handleError(error);
      });
  };

  const availableOffline = props.currentStudents
    ? props.currentStudents?.filter(
        cs => cs.studentId === props.id && cs.availableOffline
      ).length > 0
    : false;

  const canToggleOffline = props.currentStudents
    ? props.currentStudents?.filter(cs => cs.studentId === props.id).length > 0
    : false;

  return (
    <>
      <LoadingScreen loading={showSpinner} />
      <Card
        margin={"16px 0px"}
        variation={1}
        width="100%"
        title={
          <Row type="flex" justify="space-between">
            <Col>Student Info</Col>
            {props.canEdit && online && (
              <Heading id="Edit_Student_Basic_Info" level={6} color="primary">
                <Link
                  icon="fa-edit fas"
                  iconPosition="right"
                  onClick={handleEditInfoClick}
                >
                  Edit
                </Link>
              </Heading>
            )}
          </Row>
        }
      >
        {!editInfo ? (
          <Grid>
            <Row margin="0px 0px 12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  First Name
                </Heading>
                <Heading level={5}>
                  {props.firstName ? props.firstName : "N/A"}
                </Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  Last Name
                </Heading>
                <Heading level={5}>
                  {props.lastName ? props.lastName : "N/A"}
                </Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading level={6} color="default" variation={7}>
                  Birthday
                </Heading>
                <Heading level={5}>
                  {props.birthday
                    ? moment(props.birthday).format("YYYY-MM-DD")
                    : "N/A"}
                </Heading>
              </Col>
            </Row>
            <Row margin="12px 0px">
              <Col>
                <Heading id="eINumber" level={6} color="default" variation={7}>
                  EI Number
                </Heading>
                <Heading level={5}>
                  {props.eINumber ? props.eINumber : "N/A"}
                </Heading>
              </Col>
            </Row>
            {canToggleOffline && (
              <Row margin="12px 0px">
                <Col>
                  <Heading level={5}>
                    Available Offline
                    <Tooltip title={offlineStudentsTooltip}>
                      <Icon
                        opacity={0.65}
                        color="default"
                        variation={10}
                        size="1em"
                        margin="0px 5px"
                        type="fa-info-circle far"
                      />
                    </Tooltip>
                  </Heading>
                  <Switch
                    disabled={!online}
                    checked={availableOffline}
                    onChange={value => {
                      handleOfflineAvailabilityToggle(value);
                    }}
                  />
                </Col>
              </Row>
            )}
          </Grid>
        ) : (
          <Row>
            <EditStudentBasicInfo
              firstName={studentInfo.firstName!}
              lastName={studentInfo.lastName!}
              eINumber={studentInfo.eINumber!}
              birthday={
                studentInfo.birthday ? moment(studentInfo.birthday) : undefined
              }
              onChangeText={editBasicInfoValue}
              onChangeDate={editDate}
              onValidateCheck={validateStudentInfo}
              validDate={validDate}
            />
            <Row type="flex">
              <Col margin="0px 12px 0px 0px">
                <Button
                  disabled={submitCheck()}
                  size="large"
                  type="primary"
                  onClick={saveChanges}
                >
                  Save
                </Button>
              </Col>
              <Col>
                <Button size="large" onClick={cancelEditInfo}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        )}
      </Card>
    </>
  );
};
