import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
  NetworkStatus,
  useQuery
} from "@apollo/client";
import OfflineLink from "./OfflineLink";
import {
  AsyncLocalStorage,
  IAsyncStorage,
  XamarinStorage
} from "./OfflineStorage";
import { AsyncStorageWrapper, CachePersistor } from "apollo3-cache-persist";
import gql from "graphql-tag";
import moment from "moment";
import { Notification } from "@raudabaugh/thread-ui";
import { LoadingScreen } from "./LoadingScreen";
import { SessionVariableEnum } from "../App";
import { XamarinHelper } from "./XamarinHelper";
import { version } from "./Version";
import { useUserQuery } from "DataAccess/UserData";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { refetchAbcDataByStudentQuery } from "DataAccess/AbcDataData";
import {
  refetchProgramSessionsQuery,
  refetchProgramChartQuery
} from "DataAccess/ProgramData";
import {
  refetchActiveSessionNotesQuery,
  refetchSessionNotesQuery
} from "DataAccess/SessionNoteData";
import { SessionNoteStatusFilter } from "./Api/globalTypes";
import { IUser } from "./Api/IUser";
import { AuthenticationService } from "Security/AuthenticationService";
import { NotificationsHelper } from "./NotificationsHelper";
import { getStudentContext } from "Routes/StudentContextHook";

const OFFLINE_ENABLE =
  process.env.REACT_APP_OFFLINE_ENABLE === "true" ||
  (XamarinHelper.insideXamarin() &&
    XamarinHelper.checkMinimumInjectedVersion(2, 1));

// convenient for dev to be able to toggle these separately
const OFFLINE_ENABLE_CACHE_PERSISTENCE =
  process.env.REACT_APP_OFFLINE_ENABLE_CACHE_PERSISTENCE === "true";
const OFFLINE_ENABLE_MUTATION_QUEUING =
  process.env.REACT_APP_OFFLINE_ENABLE_MUTATION_QUEUING === "true" ||
  OFFLINE_ENABLE;
const OFFLINE_ENABLE_POLLING =
  process.env.REACT_APP_OFFLINE_ENABLE_POLLING === "true" || OFFLINE_ENABLE;

const OFFLINE_WARNING_TIMEOUT =
  parseInt(process.env.REACT_APP_OFFLINE_WARNING_TIMEOUT ?? "", 10) ||
  24 * 60 * 60 * 1000; // default 24 hours
const OFFLINE_POLLING_INTERVAL =
  parseInt(process.env.REACT_APP_OFFLINE_POLLING_INTERVAL ?? "", 10) ||
  5 * 60 * 1000; // default 5 minutes

const appAndVersion = XamarinHelper.insideXamarin()
  ? `Mobile ${version}`
  : `Web ${version}`;

export const storage: IAsyncStorage =
  XamarinHelper.insideXamarin() && XamarinHelper.kvcacheSupported()
    ? new XamarinStorage()
    : new AsyncLocalStorage();

export const cache = new InMemoryCache({
  //dataIdFromObject: object => object.id as string
  //dataIdFromObject: `${object.__typename}_${object.id}`
  typePolicies: {
    StepType: {
      keyFields: false
    },
    TargetType: {
      keyFields: false
    },
    CustomSettingType: {
      keyFields: false
    },
    CriterionType: {
      keyFields: false
    }
  }
});

const cachePersistor = OFFLINE_ENABLE_CACHE_PERSISTENCE
  ? new CachePersistor({
      cache,
      // @ts-ignore seems like apollo3-cache-persist is typed wrong as it serializes PersistedData to string by default
      storage: new AsyncStorageWrapper(storage),
      debug: process.env.NODE_ENV === "development",
      maxSize: false
    })
  : null;

// Create link that contains the access token and organization
const tokenLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem(SessionVariableEnum.TOKEN);
  const organization = sessionStorage.getItem(SessionVariableEnum.ORGANIZATION);
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      organization,
      "x-thread-app": appAndVersion
    }
  });

  return forward!(operation);
});

const offlineLink = OFFLINE_ENABLE_MUTATION_QUEUING
  ? new OfflineLink({
      storage,
      onGraphqlError: (error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          title: "Error Syncing Changes",
          error
        });
      }
    })
  : null;

// Create link that defines the http endpoint Thread API
const threadApiUrl: string = process.env.REACT_APP_THREADAPI_URL!;
const threadLink = new HttpLink({
  uri: threadApiUrl,
  credentials: "include"
});

// Create link that defines the http endpoint for the Thread Onboarding API
const onboardingApiUrl: string = threadApiUrl.replace("GraphQL", "Onboarding");
const onboardingLink = new HttpLink({
  uri: onboardingApiUrl
});

// Combine APIs together
const httpLink = ApolloLink.split(
  operation => operation.operationName.startsWith("onboarding"),
  onboardingLink,
  threadLink
);

// Create link that defines the ws endpoint for Thread API subscriptions
const wsLink = new WebSocketLink({
  uri: threadApiUrl.replace("http", "ws"),
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      authToken: sessionStorage.getItem(SessionVariableEnum.TOKEN)
    })
  }
});

// Combined http/ws link
const onlineLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  cache,
  link: offlineLink
    ? ApolloLink.from([tokenLink, offlineLink, onlineLink])
    : ApolloLink.from([tokenLink, onlineLink])
});

const waitForPersistence = async (
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>
) => {
  try {
    // will restore cache from anything persisted in storage and install a listener to persist changes back
    await cachePersistor?.restore();

    // work around what seems to be an apollo bug - if a cache only query can't be resolved and we watch it, it never
    // seems to receive broadcasts on update, prepopulate our syncstatus fields so its immediately resolvable
    if (client.readQuery<ISyncStatus>({ query: syncStatusQuery }) === null) {
      client.writeQuery<ISyncStatus>({
        query: syncStatusQuery,
        data: DEFAULT_SYNC_STATUS
      });
    }

    // reads any persisted mutations in the queue and attempts to start replay if possible.
    await offlineLink?.setup(client);

    setReady(true);
  } catch (e) {
    const error = e as Error;
    setError(error);
  }
};

export const purgePersistence = async () => {
  await offlineLink?.clear();

  await cachePersistor?.pause();
  await cachePersistor?.purge();
};

const syncStatusQuery = gql`
  query syncStatus {
    attempts
    mutations
    inflight
    syncing
    lastPoll
    firstSync
  }
`;

interface ISyncStatus {
  __typename: "SyncStatus";
  attempts?: number;
  mutations?: number;
  inflight?: boolean;
  syncing?: boolean;
  lastPoll?: number;
  firstSync?: boolean;
}

const DEFAULT_SYNC_STATUS: ISyncStatus = {
  __typename: "SyncStatus",

  attempts: 0,
  inflight: false,
  lastPoll: 0,
  mutations: 0,
  syncing: false,
  firstSync: true
};

const getSyncStatus = (): ISyncStatus => {
  const syncStatus = client.readQuery<ISyncStatus>({ query: syncStatusQuery });
  return syncStatus ?? DEFAULT_SYNC_STATUS;
};

const updateSyncStatus = (update: Partial<ISyncStatus>) => {
  const current = getSyncStatus();
  client.writeQuery<ISyncStatus>({
    query: syncStatusQuery,
    data: {
      ...current,
      ...update,

      __typename: "SyncStatus"
    }
  });
};

const offlineStudentSyncTime: Map<string, number> = new Map();

const handlePollCompleted = async (data: IUser) => {
  const { inflight, syncing, firstSync } = getSyncStatus();

  if (data?.user && !inflight && !syncing) {
    const offlineStudents =
      data?.user?.assignedStudents
        .filter(as => as.availableOffline)
        .map(as => as.student) ?? [];

    const unsyncedStudentIds = offlineStudents
      .filter(s => {
        const modifiedAt = moment(s.modifiedAt);
        // giving moment a null will create an invalid date
        const syncedAt = moment(offlineStudentSyncTime.get(s.id) ?? null);

        return !syncedAt.isValid() || modifiedAt.isAfter(syncedAt);
      })
      .map(s => s.id);

    const pollStarted = Date.now();
    try {
      global.console.log("Starting cache sync");
      updateSyncStatus({
        syncing: true
      });
      for (const studentId of unsyncedStudentIds) {
        const studentContext = getStudentContext();
        if (
          !firstSync &&
          studentContext.studentId &&
          studentContext.studentId === studentId
        ) {
          global.console.log("Skipping current student ", studentId);
          continue;
        }
        global.console.log("Syncing student ", studentId);
        const sessionData = await refetchProgramSessionsQuery(
          client,
          studentId
        );
        for (const programSession of sessionData?.programSessions) {
          await refetchProgramChartQuery(
            client,
            studentId,
            programSession.program.id
          );
        }
        await refetchAbcDataByStudentQuery(client, studentId);
        await refetchActiveSessionNotesQuery(client, studentId);
        await refetchSessionNotesQuery(
          client,
          studentId,
          SessionNoteStatusFilter.SUBMITTED_ONLY,
          10000
        );
        global.console.log("Finished Syncing student ", studentId);
        offlineStudentSyncTime.set(studentId, pollStarted);
      }
      updateSyncStatus({
        lastPoll: pollStarted,
        syncing: false,
        firstSync: false
      });
      global.console.log("Finished cache sync");
    } catch (error) {
      updateSyncStatus({
        syncing: false
      });
      global.console.error("Cache Sync failed", error);
    }
  }
};

const OnlineStatusContext = React.createContext<boolean>(true);

interface IOfflineDetail {
  attempts?: number;
  mutations?: number;
  inflight?: boolean;
  syncing?: boolean;
  lastPoll: number;
  online: boolean;
}

const OfflineDetailContext = React.createContext<IOfflineDetail>({
  attempts: 0,
  mutations: 0,
  inflight: false,
  syncing: false,
  lastPoll: 0,
  online: true
});

/**
 * Wrapper around ApolloProvider but waits for initialization of persisted cache and offline link before displaying children
 *
 * @param param0
 * @returns
 */
export const ApolloHelper: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>();
  const [navigatorOnline, setNavigatorOnline] = useState(true);
  const [xamarinOnline, setXamarinOnline] = useState(true);
  const [ready, setReady] = useState(false);
  const [lastWarning, setLastWarning] = useState(0);

  const { data: syncStatusData } = useQuery<ISyncStatus>(syncStatusQuery, {
    client, // outside of ApolloProvider
    fetchPolicy: "cache-only",
    returnPartialData: true,
    skip: !ready // don't query until persistence is ready
  });

  useEffect(() => {
    if (XamarinHelper.insideXamarin()) {
      const xamarinOnlineEventHandler = (event: CustomEvent) => {
        const message = JSON.parse(event.detail);
        setXamarinOnline(message.online);
      };
      const xamarinOnlineEventListenerCast =
        xamarinOnlineEventHandler as EventListener;
      window.addEventListener("xamarin-online", xamarinOnlineEventListenerCast);

      return () => {
        window.removeEventListener(
          "xamarin-online",
          xamarinOnlineEventListenerCast
        );
      };
    }
  }, []);

  useEffect(() => {
    const loginUser = async () => {
      const authService = AuthenticationService.getInstance();
      let user = await authService.getUser();
      if (!user || user.expired) {
        await authService.login();
        user = await authService.getUser();
        if (!user || user.expired) {
          // User redirected to login page
          return;
        }
      }
      await waitForPersistence(setReady, setError);
    };
    loginUser();
  }, []);

  const handleOnlineEvent = useCallback(async () => {
    const current = window.navigator.onLine;
    setNavigatorOnline(current);

    if (current) {
      // assumes callback only called after ready
      await offlineLink?.sync();
    }
  }, [setNavigatorOnline]);

  useEffect(() => {
    if (ready) {
      setNavigatorOnline(window.navigator.onLine);

      window.addEventListener("online", handleOnlineEvent);
      window.addEventListener("offline", handleOnlineEvent);

      return () => {
        window.removeEventListener("online", handleOnlineEvent);
        window.removeEventListener("offline", handleOnlineEvent);
      };
    }
  }, [handleOnlineEvent, ready, setNavigatorOnline]);

  useEffect(() => {
    if (error) {
      console.error(
        "unexpected errors waiting for persistence to come up",
        error
      );
    }
  }, [error]);

  const attempts = syncStatusData?.attempts || 0;
  const inflight = syncStatusData?.inflight || false;
  const syncing = syncStatusData?.syncing || false;
  const lastPoll = syncStatusData?.lastPoll || 0;
  const mutations = syncStatusData?.mutations || 0;
  // computed online - navigatorOnline can lie to us about being online, treat ourselves as offline until mutations have started clearing
  const online = attempts === 0 && navigatorOnline && xamarinOnline;

  // only create a new context value object when pieces have changes, otherwise it'd be new object every render
  const offlineDetail = useMemo<IOfflineDetail>(
    () => ({
      attempts,
      inflight,
      syncing,
      mutations,
      online,
      lastPoll
    }),
    [attempts, inflight, syncing, mutations, online, lastPoll]
  );

  useEffect(() => {
    if (!ready || !OFFLINE_ENABLE_POLLING) {
      return;
    }

    const lastEvent = Math.max(lastPoll, lastWarning);

    if (lastEvent > 0 && !online) {
      // // always wait at least a few seconds - primarily for first load when polled query might be inflight
      const timeUntilNextWarning = Math.max(
        2000,
        lastEvent + OFFLINE_WARNING_TIMEOUT - Date.now()
      );

      const timeout = setTimeout(() => {
        const offlineDuration = moment.duration(Date.now() - lastPoll);

        const hoursOffline = Math.floor(offlineDuration.asHours());
        const minutesOffline = offlineDuration.minutes();

        Notification.warn({
          duration: 0,
          message: "Offline Warning",
          description: `You have been offline for ${hoursOffline} hour${
            hoursOffline !== 1 ? "s" : ""
          } and ${minutesOffline} minute${
            minutesOffline !== 1 ? "s" : ""
          }. Please connect to the internet as soon as possible to ensure the most accurate up to date data is shown`
        });

        setLastWarning(Date.now());
      }, timeUntilNextWarning);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [lastPoll, lastWarning, online, ready]);

  if (!ready) {
    return <LoadingScreen loading />;
  }

  return (
    <ApolloProvider client={client}>
      <OfflineDetailContext.Provider value={offlineDetail}>
        <OnlineStatusContext.Provider value={online}>
          {children}
        </OnlineStatusContext.Provider>
      </OfflineDetailContext.Provider>
      {OFFLINE_ENABLE_POLLING && (
        <PollingOfflineQuery
          ready={ready}
          online={online}
          onCompleted={handlePollCompleted}
        />
      )}
    </ApolloProvider>
  );
};

interface PollingOfflineQueryProps {
  ready: boolean;
  online: boolean;
  onCompleted: (data: IUser) => void;
}

/**
 * In order get onCompleted to fire reliably we have to use notifyOnNetworkStatusChange which will cause the component to render
 * every poll - to avoid a cascade of child re-rendering, put the polling query in its own component so it can be a leaf node.
 */
const PollingOfflineQuery: React.FC<PollingOfflineQueryProps> = ({
  ready,
  online,
  onCompleted
}) => {
  const {
    refetch,
    data,
    networkStatus,
    previousData,
    startPolling,
    stopPolling
  } = useUserQuery(null, {
    // when queries have a policy that suggest a network request, they'll trigger such a network request when they get broadcasted
    // cache updates - we don't want that. By setting cache-only for fetch policy we'll only trigger actual network request attempts
    // when we poll or refetch (since that automagically changes the fetch policy for those calls)
    fetchPolicy: "cache-only",
    skip: !ready,
    notifyOnNetworkStatusChange: true
  });

  // control polling based on online status - also should give us a more consistent next sync when we transition from offline
  useEffect(() => {
    if (ready) {
      if (online) {
        console.log("PollingOfflineQuery: started polling");
        startPolling(OFFLINE_POLLING_INTERVAL);
      } else {
        console.log("PollingOffflineQuery: stopped polling");
        stopPolling();
      }
    }
  }, [online, ready, startPolling, stopPolling]);

  useEffect(() => {
    // this replaces us directly refetching on navigator online event, instead using the aggregated online state in coordination with OfflineLink
    if (ready && online) {
      console.log("PollingOfflineQuery: forced a fetch");
      refetch();
    }
  }, [online, ready, refetch]);

  const previousNetworkStatusRef = useRef<number>(0);

  // we previously used the onCompleted option for the apollo query which triggered any time there was a network transition - similar but not exactly
  // the same as we're doing below - however, that didn't give us the opportunity to easily compare new data with previous data.
  useEffect(() => {
    const previousNetworkStatus = previousNetworkStatusRef.current;
    previousNetworkStatusRef.current = networkStatus;

    const networkTransitioned =
      previousNetworkStatus > 0 &&
      previousNetworkStatus < NetworkStatus.ready &&
      networkStatus >= NetworkStatus.ready;
    const networkReadyTransition =
      networkTransitioned && networkStatus === NetworkStatus.ready;

    if (process.env.NODE_ENV === "development" && networkTransitioned) {
      console.log(
        "PollingOfflineQuery: network status transition %d -> %d",
        previousNetworkStatus,
        networkStatus
      );
    }

    const previousOfflineStudents: Set<string> = new Set(
      previousData?.user?.assignedStudents
        .filter(as => as.availableOffline)
        .map(as => as.studentId) ?? []
    );
    const nextOfflineStudents: string[] =
      data?.user?.assignedStudents
        .filter(as => as.availableOffline)
        .map(as => as.studentId) ?? [];

    const newOfflineStudents = nextOfflineStudents.filter(
      sid => !previousOfflineStudents.has(sid)
    );

    if (networkReadyTransition || newOfflineStudents.length > 0) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "PollingOfflineQuery: triggering sync - ready transition: %s, new students: %o",
          networkReadyTransition,
          newOfflineStudents
        );
      }

      onCompleted(data!);
    }
  }, [
    data,
    networkStatus,
    onCompleted,
    previousData,
    previousNetworkStatusRef
  ]);

  // non-visual component
  return null;
};

export const useOnlineStatus = () => {
  return useContext(OnlineStatusContext);
};

export const useOfflineDetail = () => {
  return useContext(OfflineDetailContext);
};
