import {
  gql,
  QueryFunctionOptions,
  useMutation,
  useQuery
} from "@apollo/client";
import {
  QUERY_STAFFS_ASSIGNED,
  QUERY_STUDENT,
  QUERY_STUDENT_SEARCH
} from "./Queries";
import { IStudent, IStudentVariables } from "../Shared/Api/IStudent";
import {
  studentCreate,
  studentCreateVariables
} from "../Shared/Api/studentCreate";
import {
  IStudentSearch,
  IStudentSearchVariables
} from "../Shared/Api/IStudentSearch";
import {
  studentUpdate,
  studentUpdateVariables
} from "../Shared/Api/studentUpdate";
import {
  studentAssignStaffs,
  studentAssignStaffsVariables
} from "../Shared/Api/studentAssignStaffs";
import {
  studentRemoveStaffs,
  studentRemoveStaffsVariables
} from "../Shared/Api/studentRemoveStaffs";
import {
  studentRemove,
  studentRemoveVariables
} from "../Shared/Api/studentRemove";
import {
  studentDeactivate,
  studentDeactivateVariables
} from "../Shared/Api/studentDeactivate";
import {
  studentReactivate,
  studentReactivateVariables
} from "../Shared/Api/studentReactivate";
import { UseQueryOptions } from "types";
import {
  FRAGMENT_STUDENT,
  FRAGMENT_STUDENT_PROFILE
} from "DataAccess/Fragments";
import { StudentInput } from "Shared/Api/globalTypes";
import { useCallback } from "react";

export const useStudentSearchQuery = (
  variables: IStudentSearchVariables,
  options?: UseQueryOptions
) => {
  return useQuery<IStudentSearch, IStudentSearchVariables>(
    QUERY_STUDENT_SEARCH,
    Object.assign({}, { variables, fetchPolicy: "no-cache" }, { ...options })
  );
};

const MUTATION_INVITE_STUDENT = gql`
  mutation studentCreate($input: StudentInput!) {
    studentCreate(input: $input) {
      student {
        ...StudentProfileFragment
      }
    }
  }
  ${FRAGMENT_STUDENT_PROFILE}
`;

const MUTATION_UPDATE_STUDENT = gql`
  mutation studentUpdate($id: GUID!, $input: StudentInput!) {
    studentUpdate(id: $id, input: $input) {
      student {
        ...StudentProfileFragment
      }
    }
  }
  ${FRAGMENT_STUDENT_PROFILE}
`;

const MUTATION_REMOVE_STUDENT = gql`
  mutation studentRemove($id: GUID!) {
    studentRemove(id: $id) {
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

const MUTATION_DEACTIVATE_STUDENT = gql`
  mutation studentDeactivate($id: GUID!) {
    studentDeactivate(id: $id) {
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

const MUTATION_REACTIVATE_STUDENT = gql`
  mutation studentReactivate($id: GUID!) {
    studentReactivate(id: $id) {
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

const MUTATION_ASSIGN_STAFFS = gql`
  mutation studentAssignStaffs($studentId: GUID!, $staffIds: [GUID!]) {
    studentAssignStaffs(studentId: $studentId, staffIds: $staffIds) {
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

const MUTATION_REMOVE_STAFFS = gql`
  mutation studentRemoveStaffs($studentId: GUID!, $staffIds: [GUID!]) {
    studentRemoveStaffs(studentId: $studentId, staffIds: $staffIds) {
      student {
        ...StudentFragment
      }
    }
  }
  ${FRAGMENT_STUDENT}
`;

export const useStudentInviteMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentCreate,
    studentCreateVariables
  >(MUTATION_INVITE_STUDENT);

  const handleInviteStudent = useCallback(
    (input: StudentInput) => {
      return mutate!({
        variables: { input }
      });
    },
    [mutate]
  );
  return { inviteStudent: handleInviteStudent, error, data };
};

export const useStudentUpdateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentUpdate,
    studentUpdateVariables
  >(MUTATION_UPDATE_STUDENT);

  const handleUserUpdateStudentInfo = useCallback(
    (id: string, input: StudentInput) => {
      return mutate!({
        variables: { id, input }
      });
    },
    [mutate]
  );
  return { updateStudentInfo: handleUserUpdateStudentInfo, error, data };
};

export const useStudentRemoveMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentRemove,
    studentRemoveVariables
  >(MUTATION_REMOVE_STUDENT);

  const handleStudentRemove = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { removeStudent: handleStudentRemove, error, data };
};

export const useStudentDeactivateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentDeactivate,
    studentDeactivateVariables
  >(MUTATION_DEACTIVATE_STUDENT);

  const handleStudentDeactivate = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { deactivateStudent: handleStudentDeactivate, error, data };
};

export const useStudentReactivateMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentReactivate,
    studentReactivateVariables
  >(MUTATION_REACTIVATE_STUDENT);

  const handleStudentReactivate = useCallback(
    (id: string) => {
      return mutate!({
        variables: { id }
      });
    },
    [mutate]
  );
  return { reactivateStudent: handleStudentReactivate, error, data };
};

export const useStudentUpdatePrimaryGuardianMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentUpdate,
    studentUpdateVariables
  >(MUTATION_UPDATE_STUDENT);

  const handleUpdateStudentPrimaryGuardian = useCallback(
    (
      id: string,
      email: string | null,
      firstName: string | null,
      lastName: string | null,
      phoneNumber: string | null
    ) => {
      const input: StudentInput = {
        primaryEmail: email,
        primaryFirstName: firstName,
        primaryLastName: lastName,
        primaryPhoneNumber: phoneNumber
      };
      return mutate!({
        variables: { id, input }
      });
    },
    [mutate]
  );
  return {
    updateStudentPrimaryGuardian: handleUpdateStudentPrimaryGuardian,
    error,
    data
  };
};

export const useStudentUpdateSecondaryGuardianMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentUpdate,
    studentUpdateVariables
  >(MUTATION_UPDATE_STUDENT);

  const handleUpdateStudentSecondaryGuardian = useCallback(
    (
      id: string,
      email: string | null,
      firstName: string | null,
      lastName: string | null,
      phoneNumber: string | null
    ) => {
      const input: StudentInput = {
        secondaryEmail: email,
        secondaryFirstName: firstName,
        secondaryLastName: lastName,
        secondaryPhoneNumber: phoneNumber
      };
      return mutate!({
        variables: { id, input }
      });
    },
    [mutate]
  );
  return {
    updateStudentSecondaryGuardian: handleUpdateStudentSecondaryGuardian,
    error,
    data
  };
};

export const useStudentQuery = (id: string, options?: UseQueryOptions) => {
  return useQuery<IStudent, IStudentVariables>(
    QUERY_STUDENT,
    Object.assign(
      {},
      {
        variables: { id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
      },
      { ...options }
    ) as QueryFunctionOptions<IStudent, IStudentVariables>
  );
};

export const useStudentAssignStaffsMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentAssignStaffs,
    studentAssignStaffsVariables
  >(MUTATION_ASSIGN_STAFFS);

  const handleAssignStaffToStudent = useCallback(
    (studentId: string, staffIds: string[]) => {
      return mutate!({
        variables: { studentId, staffIds },
        refetchQueries: [
          {
            query: QUERY_STAFFS_ASSIGNED,
            variables: { studentId }
          }
        ]
      });
    },
    [mutate]
  );
  return {
    assignStaffToStudent: handleAssignStaffToStudent,
    error,
    data
  };
};

export const useStudentRemoveStaffsMutation = () => {
  const [mutate, { error, data }] = useMutation<
    studentRemoveStaffs,
    studentRemoveStaffsVariables
  >(MUTATION_REMOVE_STAFFS);

  const handleRemoveStaff = useCallback(
    (studentId: string, staffIds: string[]) => {
      return mutate!({
        variables: { studentId, staffIds },
        refetchQueries: [
          {
            query: QUERY_STAFFS_ASSIGNED,
            variables: { studentId }
          }
        ]
      });
    },
    [mutate]
  );
  return { removeStaffFromStudent: handleRemoveStaff, error, data };
};
