import {
  ApolloCache,
  ApolloClient,
  QueryFunctionOptions,
  useMutation,
  useQuery
} from "@apollo/client";
import gql from "graphql-tag";
import { v4 } from "uuid";
import { QUERY_ABC_DATA_BY_STUDENT } from "./Queries";
import { FRAGMENT_ABC_DATA } from "./Fragments";
import {
  IABCDataByStudent,
  IABCDataByStudentVariables,
  IABCDataByStudent_aBCDataByStudent
} from "Shared/Api/IABCDataByStudent";
import { ABCDataInput } from "Shared/Api/globalTypes";
import {
  aBCDataCreate,
  aBCDataCreateVariables
} from "Shared/Api/aBCDataCreate";
import {
  aBCDataUpdate,
  aBCDataUpdateVariables,
  aBCDataUpdate_aBCDataUpdate_aBCData
} from "Shared/Api/aBCDataUpdate";
import {
  aBCDataSoftDelete,
  aBCDataSoftDeleteVariables,
  aBCDataSoftDelete_aBCDataSoftDelete_aBCData
} from "Shared/Api/aBCDataSoftDelete";
import { UseQueryOptions } from "types";
import { AbcDataFragment } from "Shared/Api/AbcDataFragment";
import _ from "lodash";
import { useThreadContext } from "ContextHooks/ThreadContextHook";
import { useCallback } from "react";

const MUTATION_CREATE = gql`
  mutation aBCDataCreate($id: GUID!, $studentId: GUID!, $input: ABCDataInput!) {
    aBCDataCreate(id: $id, studentId: $studentId, input: $input) {
      aBCData {
        ...AbcDataFragment
      }
    }
  }
  ${FRAGMENT_ABC_DATA}
`;

const MUTATION_UPDATE = gql`
  mutation aBCDataUpdate($id: GUID!, $studentId: GUID!, $input: ABCDataInput!) {
    aBCDataUpdate(id: $id, studentId: $studentId, input: $input) {
      aBCData {
        ...AbcDataFragment
      }
    }
  }
  ${FRAGMENT_ABC_DATA}
`;

const MUTATION_SOFT_DELETE = gql`
  mutation aBCDataSoftDelete($id: GUID!, $studentId: GUID!) {
    aBCDataSoftDelete(id: $id, studentId: $studentId) {
      aBCData {
        ...AbcDataFragment
      }
    }
  }
  ${FRAGMENT_ABC_DATA}
`;

export const useAbcDataByStudentQuery = (
  studentId: GUID,
  options?: UseQueryOptions
) => {
  const { loading, error, data, refetch } = useQuery<
    IABCDataByStudent,
    IABCDataByStudentVariables
  >(
    QUERY_ABC_DATA_BY_STUDENT,
    Object.assign(
      {},
      { variables: { studentId } },
      { ...options }
    ) as QueryFunctionOptions<IABCDataByStudent, IABCDataByStudentVariables>
  );
  return { loading, error, data, refetch };
};

export const refetchAbcDataByStudentQuery = async (
  client: ApolloClient<object>,
  studentId: string
) => {
  const abcData = await client.query<
    IABCDataByStudent,
    IABCDataByStudentVariables
  >({
    query: QUERY_ABC_DATA_BY_STUDENT,
    variables: { studentId },
    fetchPolicy: "network-only"
  });
  return abcData.data;
};

export const useAbcDataCreate = () => {
  const { threadUserContext } = useThreadContext();
  const [mutate, { error, data }] = useMutation<
    aBCDataCreate,
    aBCDataCreateVariables
  >(MUTATION_CREATE);

  const handleAbcCreate = useCallback(
    (studentId: string, input: ABCDataInput) => {
      const id = v4();
      const optimisticResponse: aBCDataCreate = {
        aBCDataCreate: {
          __typename: "ABCDataOutput",
          aBCData: {
            __typename: "ABCDataType",
            id,
            // it'd be nice to just use spread on input into session note output, but we
            // unfortunately have to get rid of all the undefineds and provide expected
            // defaults for some enums.
            antecedent: input.antecedent || null,
            behavior: input.behavior || null,
            consequence: input.consequence || null,
            date: input.date || null,
            note: input.note || null,
            programId: input.programId || null,
            setting: input.setting || null,
            createdById: threadUserContext.userId,
            createdByName: threadUserContext.userName
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId, input },
        update: (cache, { data }) => {
          if (studentId) {
            updateAbcDataByStudentQuery(
              cache,
              studentId,
              id,
              data?.aBCDataCreate?.aBCData
            );
          }
        }
      });
    },
    [mutate, threadUserContext.userId, threadUserContext.userName]
  );
  return { abcDataCreate: handleAbcCreate, error, data };
};

export const useAbcDataUpdateMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    aBCDataUpdate,
    aBCDataUpdateVariables
  >(MUTATION_UPDATE);

  const handleAbcDataUpdate = useCallback(
    (id: string, studentId: string, input: ABCDataInput) => {
      const existingAbcData = readAbcData(
        client.cache,
        id
      ) as aBCDataUpdate_aBCDataUpdate_aBCData;
      const optimisticResponse: aBCDataUpdate = {
        aBCDataUpdate: {
          __typename: "ABCDataOutput",
          aBCData: {
            ...existingAbcData,

            // it'd be nice to just use spread on input into session note output, but we
            // unfortunately have to get rid of all the undefineds and provide expected
            // defaults for some enums.
            antecedent: input.antecedent || null,
            behavior: input.behavior || null,
            consequence: input.consequence || null,
            date: input.date || null,
            note: input.note || null,
            programId: input.programId || null,
            setting: input.setting || null
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId, input },
        update: (cache, { data }) => {
          if (studentId) {
            updateAbcDataByStudentQuery(
              cache,
              studentId,
              id,
              data?.aBCDataUpdate?.aBCData
            );
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { abcDataUpdate: handleAbcDataUpdate, error, data };
};

export const useAbcDataSoftDeleteMutation = () => {
  const [mutate, { client, error, data }] = useMutation<
    aBCDataSoftDelete,
    aBCDataSoftDeleteVariables
  >(MUTATION_SOFT_DELETE);

  const handleAbcDataSoftDelete = useCallback(
    (id: string, studentId: string) => {
      const existingAbcData = readAbcData(
        client.cache,
        id
      ) as aBCDataSoftDelete_aBCDataSoftDelete_aBCData;
      const optimisticResponse: aBCDataSoftDelete = {
        aBCDataSoftDelete: {
          __typename: "ABCDataOutput",
          aBCData: {
            ...existingAbcData
          }
        }
      };
      return mutate!({
        optimisticResponse,
        variables: { id, studentId },
        update: (cache, { data }) => {
          if (studentId) {
            updateAbcDataByStudentQuery(cache, studentId, id, undefined);
          }
        }
      });
    },
    [mutate, client.cache]
  );
  return { abcDataSoftDelete: handleAbcDataSoftDelete, error, data };
};

const readAbcData = (
  cache: ApolloCache<object>,
  id: string,
  optimistic = true
) => {
  const fragment = cache.readFragment<AbcDataFragment>(
    {
      fragment: FRAGMENT_ABC_DATA,
      fragmentName: "AbcDataFragment",
      id: `ABCDataType:${id}`
    },
    optimistic
  );
  if (fragment === null) return fragment;
  return _.cloneDeep(fragment);
};

const readAbcDataByStudent = (
  cache: ApolloCache<object>,
  studentId: string,
  optimistic = true
): IABCDataByStudent_aBCDataByStudent[] => {
  const abcDataQuery = cache.readQuery<
    IABCDataByStudent,
    IABCDataByStudentVariables
  >(
    {
      query: QUERY_ABC_DATA_BY_STUDENT,
      variables: { studentId }
    },
    optimistic
  );
  return abcDataQuery?.aBCDataByStudent ?? [];
};

const updateAbcDataByStudentQuery = (
  cache: ApolloCache<object>,
  studentId: string,
  id: string,
  data: AbcDataFragment | null | undefined,
  optimistic = true
) => {
  const existingData = readAbcDataByStudent(cache, studentId, optimistic);

  // with an optimistic response we'll be called to update twice
  let abcData = _.cloneDeep(existingData);

  // if there's no data this id is removed
  if (data) {
    const newAbcData: IABCDataByStudent_aBCDataByStudent = {
      ...data,

      __typename: "ABCDataType",
      id
    };

    const dataIndex = abcData.findIndex(data => data.id === id);

    if (dataIndex >= 0) {
      abcData[dataIndex] = newAbcData;
    } else {
      abcData.push(newAbcData);
    }

    // API ORDER BY createdAt DESC
    abcData.sort((a, b) => {
      const aStart = a.date ?? "";
      const bStart = b.date ?? "";

      // ISO date strings in same offset (utc) can be lexographically sorted
      if (aStart < bStart) {
        return 1;
      } else if (aStart > bStart) {
        return -1;
      }

      return 0;
    });
  } else {
    abcData = abcData.filter(data => data.id !== id);
  }

  cache.writeQuery<IABCDataByStudent, IABCDataByStudentVariables>({
    query: QUERY_ABC_DATA_BY_STUDENT,
    variables: { studentId },
    data: {
      aBCDataByStudent: abcData
    }
  });
};
