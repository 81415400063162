import {
  Col,
  FormItem,
  Heading,
  InputTextArea,
  Row
} from "@raudabaugh/thread-ui";
import { useCallback, useEffect, useState } from "react";

interface ISmartNoteProps {
  name: string;
  initialValue?: string;
}

export const SmartNote = ({ name, initialValue }: ISmartNoteProps) => {
  const getWordCount = useCallback((text?: string | null) => {
    const words = text?.match(/\b(\w+)\b/g);
    return words?.length ?? 0;
  }, []);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(getWordCount(initialValue));
  }, [initialValue, getWordCount]);

  return (
    <>
      <FormItem
        name={name}
        rules={[
          {
            validator: async (_rule: any, value: any) => {
              if (!value || value.length === 0 || count < 14) {
                throw new Error(
                  "Please write or insert a description 14 words or longer"
                );
              }
            }
          }
        ]}
      >
        <InputTextArea
          id={`${name}TextField`}
          autoSize={true}
          onChange={event => {
            const newCount = getWordCount(event.target.value);
            if (newCount !== count) {
              setCount(newCount);
            }
          }}
        />
      </FormItem>
      <Row margin="12px 0 0 0">
        <Col span={12}>
          <Row type="flex">
            <Col grow={0} margin="0 8px 0 0">
              <Heading level={6}>Minimum length</Heading>
            </Col>
            <Col grow={0}>
              <Heading level={6} color="default" variation={10}>
                14 words
              </Heading>
            </Col>
          </Row>
        </Col>
        <Col span={11}>
          <Row type="flex">
            <Col grow={0} margin="0 8px 0 0">
              <Heading level={6}>Current length</Heading>
            </Col>
            <Col grow={0}>
              <Heading level={6} color={count < 14 ? "error" : "success"}>
                {count} words
              </Heading>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
