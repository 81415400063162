import { LoadingScreen } from "../../Shared/LoadingScreen";
import {
  Grid,
  Row,
  Heading,
  Col,
  Illustration,
  Tooltip,
  Tag,
  Checkbox,
  TabPane,
  Tabs,
  Icon,
  Select,
  SelectOption,
  Notification,
  Radio,
  Button,
  Card,
  InputNumber,
  DatePicker,
  Paragraph,
  Modal
} from "@raudabaugh/thread-ui";
import { useState, useEffect, useCallback, useMemo } from "react";
import { IPrompt, PromptHelper } from "../../Shared/PromptHelper";
import { useCurriculumsAvailableQuery } from "DataAccess/CurriculumData";
import { client } from "../../App";
import { useThreadContext } from "../../ContextHooks/ThreadContextHook";
import {
  CustomSettingControlEnum,
  OrganizationSettingInput,
  OrganizationSettingsInput,
  Permissions
} from "Shared/Api/globalTypes";
import {
  useOrganizationCustomSettingsQuery,
  useOrganizationQuery,
  useOrganizationUpdateSettingsMutation
} from "DataAccess/OrganizationData";
import { CustomSettingFragment } from "Shared/Api/CustomSettingFragment";
import { CustomSettingCategoryFragment } from "Shared/Api/CustomSettingCategoryFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import settingsImage from "./undraw_settings_ii2j.svg";
import _ from "lodash";
import { ICustomSettings_customSettings } from "Shared/Api/ICustomSettings";
import { CurriculumHelper } from "Shared/CurriculumHelper";
import { ValueList } from "./ValueList";
import DiscardChangesModal from "Shared/DiscardChangesModal";
import { ApolloError } from "@apollo/client";
import moment from "moment";
import { MultiValueList } from "./MultiValueList";
import { v4 } from "uuid";
import { usePeopleCountsQuery } from "DataAccess/PeopleData";
import { IPeopleCounts_peopleCounts } from "Shared/Api/IPeopleCounts";
import { findPermission } from "Shared/RolesMap";

export interface IOrgSettingsBaseProps {}

export const OrgSettings = (props: IOrgSettingsBaseProps) => {
  const { threadUserContext } = useThreadContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState(false);
  const [currentSettings, setCurrentSettings] = useState<{
    [key: string]: OrganizationSettingInput;
  }>({});
  const [customSettings, setCustomSettings] = useState<
    CustomSettingCategoryFragment[]
  >([]);
  const { data: curriculumData, error: curriculumError } =
    useCurriculumsAvailableQuery({ client });
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [currentTab, setCurrentTab] = useState("tab0");
  const [counts, setCounts] = useState<IPeopleCounts_peopleCounts>();
  const { organizationUpdateSettings } =
    useOrganizationUpdateSettingsMutation();
  const { error: countsError, data: countsData } = usePeopleCountsQuery();
  const customSettingMap: { [id: string]: CustomSettingFragment } =
    useMemo(() => {
      return {};
    }, []);

  useEffect(() => {
    countsError &&
      NotificationsHelper.ErrorNotification({
        error: countsError,
        title: "Connection Error",
        refreshPageOnClose: true
      });
  }, [countsError]);

  useEffect(() => {
    if (!countsData?.peopleCounts) {
      return;
    }
    setCounts(countsData.peopleCounts);
  }, [countsData, threadUserContext, threadUserContext.orgType]);

  const {
    loading: orgLoading,
    error: orgError,
    data: orgData
  } = useOrganizationQuery(threadUserContext.orgId);

  const {
    loading: orgCustomSettingsLoading,
    error: orgCustomSettingsError,
    data: orgCustomSettingsData
  } = useOrganizationCustomSettingsQuery();

  const buildSettingMap = useCallback(
    (
      category: CustomSettingCategoryFragment,
      map: { [id: string]: CustomSettingFragment }
    ) => {
      category.settings.forEach(setting => {
        if (!map[setting.id]) {
          map[setting.id] = setting;
        }
      });
      (category as ICustomSettings_customSettings)?.subcategories?.forEach(
        category => buildSettingMap(category, map)
      );
    },
    []
  );

  useEffect(() => {
    if (orgData && orgCustomSettingsData && orgData?.organization?.settings) {
      orgCustomSettingsData?.customSettings.forEach(c =>
        buildSettingMap(c, customSettingMap)
      );
      const settings = threadUserContext.isSysop
        ? orgCustomSettingsData?.customSettings
        : orgCustomSettingsData?.customSettings.filter(i => i.title?.toLowerCase() !== "sysops");
      setCustomSettings(settings || []);
      const current: { [id: string]: OrganizationSettingInput } = {};
      orgData?.organization.settings.forEach(s => {
        const currentSetting: OrganizationSettingInput = {
          id: s.setting.id,
          value: s.value ?? ""
        };
        if (
          customSettingMap[s.setting.id].control ===
            CustomSettingControlEnum.CHECKBOX_LIST ||
          customSettingMap[s.setting.id].control ===
            CustomSettingControlEnum.CUSTOM_LIST ||
          customSettingMap[s.setting.id].control ===
            CustomSettingControlEnum.MULTI_VALUE_LIST
        ) {
          current[`${s.setting.id}.${s.value}`] = currentSetting;
        } else {
          current[s.setting.id] = currentSetting;
        }
      });
      setCurrentSettings(current);
    }
  }, [
    orgData,
    orgCustomSettingsData,
    buildSettingMap,
    threadUserContext,
    customSettingMap
  ]);

  useEffect(() => {
    setLoading(false);
    if (orgError || orgCustomSettingsError || curriculumError) {
      NotificationsHelper.ErrorNotification({
        error: orgError,
        title: "Failed to Load Organization Settings!",
        refreshPageOnClose: true
      });
    }
  }, [orgError, orgCustomSettingsError, curriculumError]);

  useEffect(() => {
    setLoading(orgLoading || orgCustomSettingsLoading);
  }, [orgLoading, orgCustomSettingsLoading]);

  const headingLevelMap: (5 | 6 | 7 | 8)[] = [5, 6, 7, 8];

  const addSettingValue = useCallback(
    (settingId: string, value: string) => {
      const key = `${settingId}.${value}`;

      if (!currentSettings[key]) {
        const settings = _.clone(currentSettings);
        const setting: OrganizationSettingInput = {
          id: settingId,
          value: value
        };
        settings[key] = setting;
        setCurrentSettings(settings);
        setSaveNeeded(true);
        return true;
      }
      return false;
    },
    [currentSettings]
  );

  const removeSettingValue = useCallback(
    (settingId: string, value?: string) => {
      let key = settingId;
      if (value) key = key + `.${value}`;
      const clone = _.clone(currentSettings);
      delete clone[key];
      setCurrentSettings(clone);
      setSaveNeeded(true);
    },
    [currentSettings]
  );

  const updateSettingValue = (
    setting: CustomSettingFragment,
    checked: boolean,
    value: string
  ) => {
    //If parent setting exists, check to make sure it allows for this setting to be modified
    if (setting.parentSettingId) {
      if (
        !currentSettings[setting.parentSettingId] ||
        (setting.parentSettingValues &&
          setting.parentSettingValues.length > 0 &&
          !setting.parentSettingValues.includes(
            currentSettings[setting.parentSettingId].value
          ))
      ) {
        return;
      }
    }

    const settingsClone = { ...currentSettings };
    //If child settings exist, ensure the alteration of this parent setting affects its children if needed
    if (setting.childSettingIds) {
      setting.childSettingIds.forEach(id => {
        if (
          customSettingMap[id!].parentSettingValues &&
          !customSettingMap[id!].parentSettingValues?.includes(value)
        ) {
          delete settingsClone[id!];
        }
      });
    }

    const multiple = setting.control === CustomSettingControlEnum.CHECKBOX_LIST;
    const key = multiple ? `${setting.id}.${value}` : setting.id;
    const inverted =
      setting.id.startsWith("hide") || setting.id.startsWith("disable");
    //Delete setting
    if ((checked && inverted) || (!checked && !inverted)) {
      if (setting.childSettingIds && setting.childSettingIds.length > 0) {
        setting.childSettingIds.forEach(id => delete settingsClone[id!]);
      }
      delete settingsClone[key];
      setCurrentSettings({
        ...settingsClone
      });
      //Update or Add settings
    } else {
      if (
        setting.negateSettingIds?.length &&
        setting.negateSettingIds.length > 0
      ) {
        setting.negateSettingIds!.forEach(id => delete settingsClone[id!]);
      }

      if (
        setting.autoActivateChildSettings &&
        setting.childSettingIds?.length &&
        setting.childSettingIds?.length > 0
      ) {
        setting.childSettingIds!.forEach(
          id => (settingsClone[id!] = { id: id! } as OrganizationSettingInput)
        );
      }

      if (!currentSettings[setting.id] && setting.defaultValueWhenActivated) {
        value = setting.defaultValueWhenActivated;
      }

      settingsClone[key] = {
        value: value,
        id: setting.id
      };
      setCurrentSettings(settingsClone);
    }
    setSaveNeeded(true);
  };

  const getSettingValues = (setting: CustomSettingFragment) => {
    if (!setting.dataSource) {
      return setting.values;
    }
    switch (setting.dataSource) {
      case "StandardPrompts":
        return Object.keys(PromptHelper.standardPrompts(threadUserContext));
      case "PayCurriculum":
        const curriculums = curriculumData?.curriculumsAvailable;
        if (curriculums) {
          return curriculums
            .filter(c => c.id !== CurriculumHelper.defaultCurriculumId)
            .map(c => c.id);
        }
        break;
      case "CustomPrompts":
      case "MasteryCriterion":
        const listValues = Object.keys(currentSettings).filter(
          k => currentSettings[k].id === setting.id
        );
        return listValues.map(k => currentSettings[k].value);
    }
    return [];
  };

  const getSettingLabels = (setting: CustomSettingFragment) => {
    if (!setting.dataSource) {
      return setting.labels ?? undefined;
    }
    switch (setting.dataSource) {
      case "StandardPrompts":
        const prompts = PromptHelper.standardPrompts(threadUserContext);
        return Object.keys(prompts).map(key => (
          <Row type="flex">
            <Col>
              <Grid width="60px">
                <Tag color="primary">{prompts[key].label}</Tag>
              </Grid>
            </Col>
            <Col>{prompts[key].name}</Col>
          </Row>
        ));
      case "PayCurriculum":
        const curriculums = curriculumData?.curriculumsAvailable;
        if (curriculums) {
          return curriculums
            .filter(c => c.id !== CurriculumHelper.defaultCurriculumId)
            .map(c => c.name ?? "");
        }
        break;
      case "CustomPrompts":
        const listValues = Object.keys(currentSettings).filter(
          k => currentSettings[k].id === setting.id
        );
        const promptMap: { [key: string]: IPrompt } =
          PromptHelper.standardPrompts(threadUserContext);
        listValues.forEach(key => {
          const s = currentSettings[key];
          promptMap[s.value] = {
            name: s.value,
            label: PromptHelper.generateLabel(s.value)
          };
        });
        return listValues.map(k => promptMap[currentSettings[k].value].label);
    }
    return [];
  };

  const confirmSave = () => {
    Modal.warning({
      okCancel: true,
      title: "Are you sure you want to make changes?",
      content:
        "Changing organization settings will make changes for all users in your organization effective immediately. This can be especially disruptive if they are in the middle of using prompt levels that they will no longer have access too.",
      onOk: () => handleSave(),
      okText: "Save"
    });
  };

  const handleSave = () => {
    setSaving(true);

    // Map state to mutation payload
    const input: OrganizationSettingsInput = { settings: [] };
    for (const key in currentSettings) {
      input.settings.push(currentSettings[key]);
    }

    // Call update mutation
    organizationUpdateSettings(threadUserContext?.orgId ?? "", input)
      .then(() => {
        Notification.success({
          duration: 4,
          message: "Org Setting Changes Saved",
          description:
            "Changes to your organization settings have been successfully saved."
        });
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error: error,
          title: "Failed to Save Organization Setting Changes"
        });
      })
      .finally(() => {
        setSaving(false);
        setSaveNeeded(false);
      });
  };

  const handleEditMultiValue = (settingIdAndValue: string, value: string) => {
    const key = settingIdAndValue.split(".")[0];
    const newKey = `${key}.${value}`;

    if (!currentSettings[newKey]) {
      const cloneSettings = _.clone(currentSettings);
      delete cloneSettings[settingIdAndValue];

      const setting: OrganizationSettingInput = {
        id: key,
        value: value
      };
      cloneSettings[newKey] = setting;
      setCurrentSettings(cloneSettings);
      setSaveNeeded(true);
    }
  };

  const renderTab = (
    category: ICustomSettings_customSettings | CustomSettingCategoryFragment,
    index: number
  ): JSX.Element => {
    return (
      <TabPane key={"tab" + index} tab={category.title}>
        {category.tooltip && (
          <Heading level={7} margin="0 0 0 16px">
            {category.tooltip}
          </Heading>
        )}
        {index === 0 && renderOverview()}
        {(category as ICustomSettings_customSettings)?.subcategories?.map(
          value => renderCategory(value, 0)
        )}
        {category.settings.map(value => renderSetting(value, 0))}
      </TabPane>
    );
  };

  const renderCategory = (
    category: ICustomSettings_customSettings | CustomSettingCategoryFragment,
    level: number
  ): JSX.Element => {
    return (
      <Row margin={24 - level * 2 + "px 16px 0 16px"}>
        <Heading level={headingLevelMap[level]} weight="bold">
          {category.title}
          {category.tooltip && (
            <Tooltip title={category.tooltip}>
              <Icon margin="0 0 0 8px" type="fa-info-circle far" />
            </Tooltip>
          )}
        </Heading>
        {(category as ICustomSettings_customSettings)?.subcategories?.map(
          value => renderCategory(value, level + 1)
        )}
        {category.settings.map(value => renderSetting(value, level + 1))}
      </Row>
    );
  };

  const renderCheckboxSetting = (setting: CustomSettingFragment) => {
    const inverted =
      setting.id.startsWith("hide") || setting.id.startsWith("disable");
    const currentValue =
      currentSettings[setting.id]?.value ??
      currentSettings[`${setting.id}.${_.first(setting.values)}`]?.value ??
      setting.defaultValue;
    const isReadOnly =
      setting.readOnly ||
      (setting.editPermission !== Permissions.NONE &&
        !threadUserContext.isSysop &&
        !findPermission(threadUserContext.role, setting.editPermission));
    return (
      <Row margin="16px 0">
        {isReadOnly ? (
          <Heading level={6}>
            <Icon type="fa-check fas" color="primary" margin="0 8px 0 0" />
            {_.first(setting.labels) ?? _.first(setting.values) ?? ""}
            {setting.tooltip && !setting.title && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        ) : (
          <Checkbox
            checked={
              inverted
                ? currentValue !== _.first(setting.values)
                : currentValue === _.first(setting.values)
            }
            onChange={e =>
              updateSettingValue(
                setting,
                (e.target as HTMLInputElement).checked,
                "true"
              )
            }
          >
            {_.first(setting.labels) ?? _.first(setting.values) ?? ""}
            {setting.tooltip && !setting.title && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Checkbox>
        )}
      </Row>
    );
  };

  const renderChecklistSetting = (
    setting: CustomSettingFragment,
    level: number
  ) => {
    const inverted =
      setting.id.startsWith("hide") || setting.id.startsWith("disable");
    const values = getSettingValues(setting);
    const labels = getSettingLabels(setting);
    const isReadOnly =
      setting.readOnly ||
      (setting.editPermission !== Permissions.NONE &&
        !threadUserContext.isSysop &&
        !findPermission(threadUserContext.role, setting.editPermission));
    return (
      <>
        {setting.title && (
          <Heading
            margin="24px 16px 8px 16px"
            level={headingLevelMap[level]}
            weight="bold"
          >
            {setting.title}
            {setting.tooltip && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        )}
        {values.map((value, index) => {
          const optionId = `${setting.id}.${value}`;
          const margin = setting.title ? "16px" : "16px 0";
          return (
            <Row margin={margin}>
              {isReadOnly ? (
                <Heading level={6}>
                  {labels && inverted === !currentSettings[optionId] && (
                    <Icon
                    type="fa-check fas"
                    color="primary"
                    margin="0 8px 0 0"
                    /> )}
                  {labels && inverted === !currentSettings[optionId] && labels[index]}
                </Heading>
              ) : (
                <Checkbox
                  checked={
                    inverted
                      ? !currentSettings[optionId]
                      : !!currentSettings[optionId]
                  }
                  onChange={e =>
                    updateSettingValue(
                      setting,
                      (e.target as HTMLInputElement).checked,
                      value
                    )
                  }
                >
                  {labels ? labels[index] : value}
                </Checkbox>
              )}
            </Row>
          );
        })}
      </>
    );
  };

  const renderRadiolistSetting = (
    setting: CustomSettingFragment,
    level: number
  ) => {
    const currentValue =
      currentSettings[setting.id]?.value ?? setting.defaultValue;
    const values = getSettingValues(setting);
    const labels = getSettingLabels(setting);
    const inverted =
      setting.id.startsWith("hide") || setting.id.startsWith("disable");
    return (
      <>
        {setting.title && (
          <Heading
            margin="24px 16px 8px 16px"
            level={headingLevelMap[level]}
            weight="bold"
          >
            {setting.title}
            {setting.tooltip && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        )}
        {values.map((value, index) => {
          return (
            <Row margin="16px">
              <Radio
                checked={value === currentValue}
                onChange={e => updateSettingValue(setting, !inverted, value)}
              >
                {labels ? labels[index] : value}
              </Radio>
            </Row>
          );
        })}
      </>
    );
  };

  const renderOptionalDropdownSetting = (setting: CustomSettingFragment) => {
    const currentValue =
      currentSettings[setting.id]?.value ?? setting.defaultValue;

    const checked = !!currentSettings[setting.id];

    return (
      <Row margin="8px 0">
        <Checkbox
          checked={checked}
          onChange={e =>
            updateSettingValue(
              setting,
              (e.target as HTMLInputElement).checked,
              (e.target as HTMLInputElement).checked
                ? setting.defaultValue ?? ""
                : ""
            )
          }
        >
          {setting.title}
        </Checkbox>
        <Select
          value={currentValue}
          disabled={!checked}
          onSelect={value =>
            updateSettingValue(setting, true, value.toString())
          }
        >
          {setting.values.map((value, index) => (
            <SelectOption key={`${setting.id}${index}`} value={value}>
              {setting.labels ? setting.labels[index] : value}
            </SelectOption>
          ))}
        </Select>
        {setting.controlLabel && " " + setting.controlLabel}
      </Row>
    );
  };

  const renderOptionalNumericSetting = (setting: CustomSettingFragment) => {
    const currentValue =
      currentSettings[setting.id]?.value ?? setting.defaultValue;
    return (
      <Row margin="8px 0">
        <Checkbox
          checked={!!currentSettings[setting.id]}
          onChange={e =>
            updateSettingValue(
              setting,
              (e.target as HTMLInputElement).checked,
              (e.target as HTMLInputElement).checked
                ? setting.defaultValue ?? ""
                : ""
            )
          }
        >
          {setting.title}
        </Checkbox>
        <InputNumber
          min={setting.minValue ?? undefined}
          max={setting.maxValue ?? undefined}
          placeholder="0"
          value={currentValue ? Number(currentValue) : 0}
          disabled={!currentSettings[setting.id]}
          onChange={value =>
            updateSettingValue(setting, true, value!.toString())
          }
        />
        {customSettingMap[setting.id]?.parentSettingId &&
          currentSettings[customSettingMap[setting.id].parentSettingId!] &&
          setting.controlLabel && (
            <>
              {" "}
              {setting.controlLabel}{" "}
              {
                currentSettings[customSettingMap[setting.id].parentSettingId!]
                  .value
              }
            </>
          )}
      </Row>
    );
  };

  const renderNumericSetting = (
    setting: CustomSettingFragment,
    level: number
  ) => {
    const currentValue = currentSettings[setting.id]?.value
      ? Number(currentSettings[setting.id]?.value)
      : Number(setting.defaultValue);
    return (
      <Row margin="8px 0" type="flex" direction="column">
        {setting.title && (
          <Heading
            margin="24px 16px 8px 16px"
            level={headingLevelMap[level]}
            weight="bold"
          >
            {setting.title}
            {setting.tooltip && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        )}
        <Row margin="0 16px">
          <InputNumber
            min={setting.minValue ?? undefined}
            max={setting.maxValue ?? undefined}
            placeholder="0"
            value={currentValue!}
            onChange={value =>
              updateSettingValue(setting, true, value!.toString())
            }
          />
        </Row>
      </Row>
    );
  };

  const renderDatePickerSetting = (setting: CustomSettingFragment) => {
    const currentValue =
      currentSettings[setting.id]?.value ?? setting.defaultValue;
    return (
      <Row margin="8px 0">
        {setting.title && <Paragraph>{setting.title}</Paragraph>}
        <DatePicker
          value={
            !!currentSettings[setting.id] ? moment(currentValue) : undefined
          }
          placeholder="Pick a date"
          onChange={value =>
            updateSettingValue(setting, true, value!.toString())
          }
        ></DatePicker>
      </Row>
    );
  };

  const renderCustomListSetting = (
    setting: CustomSettingFragment,
    level: number
  ) => {
    const values = getSettingValues(setting);
    const tags = getSettingLabels(setting);
    return (
      <>
        {setting.title && (
          <Heading
            margin="24px 16px 8px 16px"
            level={headingLevelMap[level]}
            weight="bold"
          >
            {setting.title}
            {setting.tooltip && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        )}
        <Col md={12}>
          <ValueList
            id={setting.id}
            values={values}
            tags={tags}
            addLabel={setting.controlLabel ?? "Add"}
            generateTag={PromptHelper.generateLabel}
            onAdd={addSettingValue}
            onRemove={removeSettingValue}
          />
        </Col>
      </>
    );
  };

  const renderMultiValueListSetting = (
    setting: CustomSettingFragment,
    level: number
  ) => {
    const values = getSettingValues(setting);

    const parsedValues = values.map(c => {
      const values = c.split("-");
      return {
        id: v4(),
        numberofPoints: values[1],
        percentage: values[0]
      };
    }) as { id: string; numberofPoints: string; percentage: string }[];

    return (
      <>
        {setting.title && (
          <Heading
            margin="24px 16px 8px 16px"
            level={headingLevelMap[level]}
            weight="bold"
          >
            {setting.title}
            {setting.tooltip && (
              <Tooltip title={setting.tooltip}>
                <Icon margin="0 0 0 8px" type="fa-info-circle far" />
              </Tooltip>
            )}
          </Heading>
        )}
        <Row margin="0 16px">
          <Col md={12}>
            <MultiValueList
              settingId={setting.id}
              values={parsedValues}
              addSettingValue={addSettingValue}
              editSettingValue={handleEditMultiValue}
              removeSettingValue={removeSettingValue}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderOverview = () => {
    if (!counts) return <></>;
    const licenseExpires = threadUserContext.settings["license_expires"]?.value;
    const maxStudents =
      threadUserContext.settings["max_students"]?.value ?? "unlimited";
    const maxStaff =
      threadUserContext.settings["max_staff"]?.value ?? "unlimited";
    const maxCurriculumTemplates =
      threadUserContext.settings["max_curriculum_templates"]?.value ?? 2000;
    const maxMySavedTemplates =
      threadUserContext.settings["max_my_saved_templates"]?.value ?? 300;
    const maxOfflineStudents =
      threadUserContext.settings["max_offline_students_per_user"]?.value ?? 15;
    const staffCount = counts.staffCount + counts.inactiveStaffCount;
    const studentCount = counts.studentCount + counts.inactiveStudentCount;
    return (
      <>
        <Heading
          margin="24px 16px 8px 16px"
          level={headingLevelMap[1]}
          weight="bold"
        >
          Overview
        </Heading>
        <Col margin="0 16px">
          <Paragraph>
            {threadUserContext.orgName}'s subscription is set to expire on{" "}
            {licenseExpires} and will automatically renew unless you have
            indicated otherwise to CentralReach.
          </Paragraph>
          <Paragraph>
            {threadUserContext.orgName} has {studentCount} students (
            {counts.studentCount} active, {counts.inactiveStudentCount}{" "}
            inactive) and {staffCount} staff ({counts.staffCount} active,{" "}
            {counts.inactiveStaffCount} inactive) including {counts.adminCount}{" "}
            administrators, {counts.supervisorCount} supervisors and{" "}
            {counts.instructorCount} instructors.
          </Paragraph>
          <Paragraph>
            Your account has been set up to allow a maximum of {maxStudents}{" "}
            students and {maxStaff} staff.
          </Paragraph>
          <Paragraph>
            Your custom curriculum is set up to have a maximum of{" "}
            {maxCurriculumTemplates} program templates in it.
          </Paragraph>
          <Paragraph>
            Each staff-saved curriculum is set up to have a maximum of{" "}
            {maxMySavedTemplates} program templates in it.
          </Paragraph>
          <Paragraph>
            Each staff is set up to have a maximum of {maxOfflineStudents}{" "}
            assigned students each.
          </Paragraph>
        </Col>
      </>
    );
  };

  const renderSetting = (setting: CustomSettingFragment, level: number) => {
    switch (setting.control) {
      case CustomSettingControlEnum.CHECKBOX:
        return renderCheckboxSetting(setting);
      case CustomSettingControlEnum.CHECKBOX_LIST:
        return renderChecklistSetting(setting, level);
      case CustomSettingControlEnum.RADIO_LIST:
        return renderRadiolistSetting(setting, level);
      case CustomSettingControlEnum.CUSTOM_LIST:
        return renderCustomListSetting(setting, level);
      case CustomSettingControlEnum.OPTIONAL_DROPDOWN:
        return renderOptionalDropdownSetting(setting);
      case CustomSettingControlEnum.OPTIONAL_NUMERIC:
        return renderOptionalNumericSetting(setting);
      case CustomSettingControlEnum.NUMERIC:
        return renderNumericSetting(setting, level);
      case CustomSettingControlEnum.DATE_PICKER:
        return renderDatePickerSetting(setting);
      case CustomSettingControlEnum.MULTI_VALUE_LIST:
        return renderMultiValueListSetting(setting, level);
      default:
        return <></>;
    }
  };

  if (loading) {
    return <LoadingScreen loading={true} tip="Loading..." />;
  }
  if (saving) {
    return <LoadingScreen loading={true} tip="Saving changes..." />;
  }
  return (
    <Grid>
      <Row padding="16px">
        <Heading level={3}>
          {threadUserContext.orgName}'s Organization Settings
        </Heading>
      </Row>
      <Row padding="0 0 32px 0">
        <Illustration src={settingsImage} />
      </Row>
      <Row type="flex" justify="center" margin="0 0 0 8px">
        <Col xs={24}>
          <Tabs activeKey={currentTab} onChange={setCurrentTab}>
            {customSettings.map((value, index) =>
              renderTab(value ?? "", index)
            )}
          </Tabs>
        </Col>
      </Row>
      <Row height="96px">&nbsp;</Row>
      <Card
        left="0"
        bottom="0"
        position="fixed"
        width="100%"
        margin="0"
        bordered={true}
        color="default"
        variation={4}
        padding="1px 0 0 0"
        zIndex={1}
      >
        <Row type="flex" margin="0 -5px 0 0">
          <Col
            color="navbar"
            margin="-16px 11px -12px -16px"
            grow={1}
            variation={9}
            xs={0}
            sm={0}
            md={0}
            lg={5}
            xl={4}
            xxl={4}
          />
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={19}
            xl={20}
            color="default"
            variation={1}
          >
            <Row type="flex" margin="16px" align="middle">
              <Col grow={1} />
              <Col>
                <Button onClick={confirmSave} size="default" type="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <DiscardChangesModal
        intercept={saveNeeded}
        visible={cancelAlertOpen}
        setVisible={setCancelAlertOpen}
        onCancel={() => setCancelAlertOpen(false)}
      />
    </Grid>
  );
};
