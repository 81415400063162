import { QUERY_PEOPLE_COUNTS } from "./Queries";

import { useQuery } from "@apollo/client";
import { IPeopleCounts } from "../Shared/Api/IPeopleCounts";
import { UseQueryOptions } from "types";

// Query Hooks
export const usePeopleCountsQuery = (options?: UseQueryOptions) => {
  const { loading, error, data } = useQuery<IPeopleCounts>(
    QUERY_PEOPLE_COUNTS,
    Object.assign({}, { fetchPolicy: "no-cache" }, { ...options })
  );

  return { loading, error, data };
};
