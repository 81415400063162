import React, { useState } from "react";
import {
  Col,
  Grid,
  Heading,
  IColumnProps,
  Icon,
  Link,
  Notification,
  Row,
  Select,
  SelectOption,
  Table,
  Tag
} from "@raudabaugh/thread-ui";
import { LabelHelper } from "../Shared/LabelHelper";
import { PromptHelper } from "../Shared/PromptHelper";
import { findPermission } from "../Shared/RolesMap";
import { TrialDataFormItem } from "./TrialDataFormItem";
import { TrialDataFormActions } from "./TrialDataFormActions";
import {
  useDataPointAddTrialMutation,
  useDataPointDeleteTrialMutation,
  useDataPointUpdateTrialMutation
} from "DataAccess/DataPointData";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  DataPointFragment,
  DataPointFragment_trials
} from "Shared/Api/DataPointFragment";
import {
  ProgramTypeEnum,
  TrialInput,
  TrialResultEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { ProgramFragment } from "Shared/Api/ProgramFragment";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { ApolloError } from "@apollo/client";
import { PhaseFragment } from "Shared/Api/PhaseFragment";
import { useOnlineStatus } from "Shared/ApolloHelper";

interface IDiscreteTrialDetailProps {
  studentId: string;
  data: DataPointFragment;
  phases: PhaseFragment[];
  program: ProgramFragment;
  heading: JSX.Element;
  onClose: () => void;
}

export const DiscreteTrialDetail = (props: IDiscreteTrialDetailProps) => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();
  const { dataPointDeleteTrial } = useDataPointDeleteTrialMutation();
  const { dataPointUpdateTrial } = useDataPointUpdateTrialMutation();
  const { dataPointAddTrial } = useDataPointAddTrialMutation();
  const [editTrial, setEditTrial] = useState<{
    id?: string;
    index?: number;
    input: TrialInput;
    promptResult: boolean;
  }>();
  const [validationFailed, setValidationFailed] = useState(false);
  const dataPoint = props.data as DataPointFragment;
  const hasAddPermission = findPermission(
    threadUserContext.role,
    Permissions.ADD_TRIALS
  );

  const showOfflineNotification = (label: string) => {
    Notification.warn({
      duration: 0,
      message: "You are offline.",
      description: `The ${label} function is not available while in offline mode.`
    });
  };

  const handleAddTrialClick = () => {
    if (!online) {
      showOfflineNotification("Add Trial");
      return;
    }
    setEditTrial({ input: {}, promptResult: false });
  };
  const hasEditPermission = findPermission(
    threadUserContext.role,
    Permissions.ADD_TRIALS
  );

  const findTarget = (targetId: string | null) => {
    if (!targetId) {
      return null;
    }
    return props.program.targets.find(t => t.id === targetId);
  };

  const findPhase = (phaseId: string | null) => {
    if (!phaseId) {
      return null;
    }
    return props.phases.find(t => t.id === phaseId);
  };

  const columns: Array<IColumnProps<DataPointFragment_trials>> = [
    {
      dataIndex: "trial",
      key: "trial",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return <>{index + 1}</>;
      },
      title: "Trial",
      width: 60
    },
    {
      dataIndex: "score",
      key: "score",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return (
          <>
            {record.prompt !== PromptHelper.PROMPT_NONE &&
            record.prompt !== PromptHelper.PROMPT_INDEPENDENT
              ? "P"
              : LabelHelper.resultLabel(record.result)}
          </>
        );
      },
      title: "Score"
    }
  ];
  if (dataPoint.trials.filter(trial => trial.targetId != null).length > 0)
    columns.push({
      dataIndex: "target",
      key: "target",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return <>{findTarget(record.targetId)?.targetDescription}</>;
      },
      title: "Target"
    });
  const errorless = findPhase(dataPoint.phaseId)?.errorless;
  if (errorless) {
    columns.push({
      dataIndex: "prompt",
      key: "prompt",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return <>{record.prompt}</>;
      },
      title: "Prompt"
    });
  }
  if (hasEditPermission) {
    columns.push({
      align: "right",
      width: "60px",
      dataIndex: "edit",
      key: "edit",
      render: (text: any, record: DataPointFragment_trials, index: number) => {
        return (
          <Link
            onClick={() => {
              if (!online) {
                showOfflineNotification("Edit Details");
                return;
              }
              setEditTrial({
                id: record.id,
                index: index + 1,
                input: {
                  result: record.result,
                  targetId: record.targetId,
                  prompt: record.prompt
                },
                promptResult:
                  !!record.prompt &&
                  record.prompt !== PromptHelper.PROMPT_INDEPENDENT
              });
            }}
          >
            Edit
          </Link>
        );
      }
    });
  }

  const handleSubmit = () => {
    if (editTrial) {
      const isValid =
        !validateResult() && !validateTarget() && !validatePrompt();
      if (!isValid) {
        setValidationFailed(true);
        return;
      }
      if (editTrial.id) {
        dataPointUpdateTrial(
          dataPoint.id,
          props.studentId,
          editTrial.id,
          editTrial.input,
          ProgramTypeEnum.DTT
        )
          .then(() => setEditTrial(undefined))
          .catch((error: ApolloError) =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Update the Trial",
              description: error.message
            })
          );
      } else {
        dataPointAddTrial(
          dataPoint.id,
          props.studentId,
          uuid(),
          editTrial.input
        )
          .then(() => setEditTrial(undefined))
          .catch((error: ApolloError) =>
            NotificationsHelper.ErrorNotification({
              error,
              title: "Failed to Update the Trial",
              description: error.message
            })
          );
      }
    }
  };

  const handleDelete = () => {
    if (editTrial?.id) {
      dataPointDeleteTrial(
        dataPoint.id,
        props.studentId,
        editTrial.id,
        ProgramTypeEnum.DTT
      )
        .then(() => setEditTrial(undefined))
        .catch((error: ApolloError) =>
          NotificationsHelper.ErrorNotification({
            error,
            title: "Failed to Delete the Trial",
            description: error.message
          })
        );
    }
  };
  const validateResult = () => {
    if (
      !editTrial?.input.result ||
      editTrial.input.result === TrialResultEnum.NONE
    ) {
      return "A result must be selected.";
    }
  };

  // No validation currently needed for target
  const validateTarget = () => undefined;

  const validatePrompt = () => {
    if (!editTrial?.input.prompt) {
      return "A prompt must be selected";
    }
  };

  const handleResultChange = (value: string | number) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      clone.input.result =
        value === "P" ? TrialResultEnum.MINUS : (value as TrialResultEnum);
      clone.promptResult = value === "P";
      clone.input.prompt = clone.promptResult
        ? PromptHelper.PROMPT_NONE
        : PromptHelper.PROMPT_INDEPENDENT;
      setEditTrial(clone);
    }
  };

  const handlePromptChange = (value: string | number) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      clone.input.prompt = value as string;
      setEditTrial(clone);
    }
  };

  const handleTargetChange = (value: string | number) => {
    if (editTrial) {
      const clone = _.cloneDeep(editTrial);
      clone.input.targetId = value as string;
      setEditTrial(clone);
    }
  };

  if (editTrial !== undefined) {
    const promptMap = PromptHelper.prompts(threadUserContext);
    const errorless = findPhase(dataPoint.phaseId)?.errorless;
    return (
      <>
        <Row type="flex" margin="0 0 8px 0">
          <Col>
            <Link onClick={() => setEditTrial(undefined)}>
              <Icon type="fa-arrow-left fas" />
              {" View trials"}
            </Link>
          </Col>
        </Row>
        <Heading level={3}>
          {editTrial.index ? `Trial ${editTrial.index}` : "Add Trial"}
        </Heading>
        <TrialDataFormItem
          required={true}
          label="Score"
          validationMessage={validationFailed ? validateResult() : undefined}
        >
          <Select
            value={
              editTrial.promptResult ? "P" : editTrial.input.result ?? undefined
            }
            onChange={handleResultChange}
          >
            {Object.keys(TrialResultEnum)
              .filter(
                result =>
                  result !== TrialResultEnum.NONE &&
                  result !== TrialResultEnum.NOT_APPLICABLE
              )
              .map(result => (
                <SelectOption value={result} key={result}>
                  {LabelHelper.resultLabel(result as TrialResultEnum)}
                </SelectOption>
              ))}
            {errorless && (
              <SelectOption value="P" key="P">
                P
              </SelectOption>
            )}
          </Select>
        </TrialDataFormItem>
        <TrialDataFormItem
          required={false}
          label="Target"
          validationMessage={validationFailed ? validateTarget() : undefined}
        >
          <Select
            value={editTrial.input.targetId ?? undefined}
            onChange={handleTargetChange}
          >
            {props.program.targets
              .map((target, i) => {
                return (
                  <SelectOption value={target.id} key={target.id}>
                    {target.targetDescription}
                  </SelectOption>
                );
              })
              .filter(x => x !== undefined)}
            <SelectOption value="" key=""></SelectOption>
          </Select>
        </TrialDataFormItem>
        {errorless && (
          <TrialDataFormItem
            required={false}
            label="Prompt"
            validationMessage={validationFailed ? validatePrompt() : undefined}
          >
            <Select
              value={editTrial!.input.prompt ?? undefined}
              onChange={handlePromptChange}
            >
              {editTrial!.promptResult ? (
                Object.keys(promptMap).map(key => (
                  <SelectOption value={key} key={key}>
                    <Row type="flex">
                      <Col>
                        <Grid width="56px">
                          <Tag color="primary">{promptMap[key].label}</Tag>
                        </Grid>
                      </Col>
                      <Col grow={1}>{key}</Col>
                    </Row>
                  </SelectOption>
                ))
              ) : (
                <SelectOption
                  value={PromptHelper.PROMPT_INDEPENDENT}
                  key={PromptHelper.PROMPT_INDEPENDENT}
                >
                  <Row type="flex">
                    <Col>
                      <Grid width="56px">
                        <Tag color="primary">I</Tag>
                      </Grid>
                    </Col>
                    <Col grow={1}>{PromptHelper.PROMPT_INDEPENDENT}</Col>
                  </Row>
                </SelectOption>
              )}
            </Select>
          </TrialDataFormItem>
        )}
        <TrialDataFormActions
          onSubmit={() => handleSubmit()}
          onCancel={() => setEditTrial(undefined)}
          onDelete={handleDelete}
        />
      </>
    );
  }
  return (
    <>
      <Row type="flex" margin="0 0 8px 0">
        <Col>
          <Link onClick={() => props.onClose()}>
            <Icon type="fa-arrow-left fas" /> Datapoint details
          </Link>
        </Col>
      </Row>
      {props.heading}
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataPoint.trials}
        rowKey="id"
        scroll={{ y: "250px" }}
      />
      {hasAddPermission && (
        <Row type="flex" margin="16px">
          <Col grow={1} />
          <Col grow={0}>
            <Link onClick={() => handleAddTrialClick()}>
              <Icon type="fa-plus fas" /> Add Trial
            </Link>
          </Col>
        </Row>
      )}
    </>
  );
};
