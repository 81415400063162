import {
  Col,
  Grid,
  Heading,
  Row,
  Button,
  Icon,
  IClickParam,
  ISelectParam,
  Modal,
  Media,
  Menu,
  MenuItem,
  Notification
} from "@raudabaugh/thread-ui";
import { ApolloError } from "@apollo/client";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useDataPointScoreMutation } from "../DataAccess/DataPointData";
import { useDataPointScoreDurationMutation } from "../DataAccess/DurationData";
import { useDataPointScoreFrequencyMutation } from "../DataAccess/FrequencyData";
import {
  useProgramActivateMutation,
  useProgramDeactivateMutation,
  useProgramSetArchivedMutation
} from "../DataAccess/ProgramData";
import { IProgramItem } from "./Types";
import StudentBar from "./StudentBar";
import { useOnlineStatus } from "../Shared/ApolloHelper";
import { LabelHelper } from "../Shared/LabelHelper";
import { findPermission } from "../Shared/RolesMap";
import { useThreadContext } from "../ContextHooks/ThreadContextHook";
import {
  ArchiveTypeEnum,
  DataPointStateEnum,
  DrawerEnum,
  ProgramTypeEnum,
  TrialResultEnum,
  Permissions
} from "Shared/Api/globalTypes";
import { ModalConfirmation } from "../Shared/ModalConfirmation";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import { EnterScoreDialog } from "./EnterScoreDialog";
import ProgramTable from "./ProgramTable";
import ProgramInfoModal from "./ProgramInfoModal";

export enum NonProgramTypeEnum {
  ARCHIVE = "archive",
  CREATE = "create,",
  CURRICULUM = "curriculum"
}

const warningMsg = [
  "This program is currently being run and trials have not yet been graphed. ",
  "If you archive this program the data will be deleted and you can't undo that action."
].join("");

interface IStudentProgramsProps {
  items: IProgramItem[];
  studentId: string;
  onProgramToggle: () => void;
  onSelectStudent: (id: string) => void;
  onProgramEdit?: (programId: string, type: ProgramTypeEnum) => void;
  onProgramMenuItemClick?: (type: ProgramTypeEnum, drawer: DrawerEnum) => void;
  onAbc: (programId: string) => void;
  onChart: (programId: string, type: ProgramTypeEnum) => void;
  onCurriculumAdd: (programId: string) => void;
}

const StudentPrograms = ({
  items,
  studentId,
  onProgramToggle,
  onSelectStudent,
  onProgramEdit,
  onProgramMenuItemClick,
  onAbc,
  onChart,
  onCurriculumAdd
}: IStudentProgramsProps) => {
  const { threadUserContext } = useThreadContext();
  const online = useOnlineStatus();

  const { programActivate } = useProgramActivateMutation();
  const { programDeactivate } = useProgramDeactivateMutation();
  const { programSetArchived } = useProgramSetArchivedMutation();

  // archive related state
  const [archiveProgramId, setArchiveProgramId] = useState("");
  const [archiveConfirmationModalOpen, setArchiveConfirmationModalOpen] =
    useState(false);
  const [archiveSelectOpen, setArchiveSelectOpen] = useState(false);
  const [closeWarningOpen, setCloseWarningOpen] = useState<string>();

  const { dataPointScore } = useDataPointScoreMutation();
  const { dataPointScoreDuration } = useDataPointScoreDurationMutation();
  const { dataPointScoreFrequency } = useDataPointScoreFrequencyMutation();

  // enter score related state
  const [showEnterScore, setShowEnterScore] = useState(false);
  const [scoreWarningOpen, setScoreWarningOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IProgramItem>();
  const [defaultAttempts, setDefaultAttempts] = useState<number>();

  const [showNewProgram, setShowNewProgram] = useState(false);
  const [showProgramInfo, setShowProgramInfo] = useState<string>();

  // slight hack to workaround handlers that do items.find
  const itemsRef = useRef<IProgramItem[]>(items);
  itemsRef.current = items;

  const handleNewProgramOpen = useCallback(() => {
    setShowNewProgram(true);
  }, []);

  const handleNewProgramClose = useCallback(() => {
    setShowNewProgram(false);
  }, []);

  const handleNewProgramSelect = useCallback(
    (param: ISelectParam) => {
      if (onProgramMenuItemClick) {
        // to do deal with interval skill and behaviors we delimited the key
        const [programType, drawer] = param.key.split(";", 2);

        onProgramMenuItemClick(
          programType as ProgramTypeEnum,
          drawer as DrawerEnum
        );
      }
    },
    [onProgramMenuItemClick]
  );

  const closeProgram = useCallback(
    (id: string, confirmed: boolean) => {
      programDeactivate(id, studentId, confirmed).catch(
        (error: ApolloError) => {
          NotificationsHelper.ErrorNotification({
            error,
            title: "Action Failed"
          });
        }
      );
    },
    [programDeactivate, studentId]
  );

  const handleCloseWarningContinue = useCallback(() => {
    if (closeWarningOpen) {
      const id = closeWarningOpen;
      setCloseWarningOpen(undefined);
      closeProgram(id, true);
    }
  }, [closeWarningOpen, closeProgram]);

  const handleCloseWarningCancel = useCallback(() => {
    setCloseWarningOpen(undefined);
  }, []);

  const handleConfirmationModalOk = useCallback(() => {
    setArchiveSelectOpen(true);
    setArchiveConfirmationModalOpen(false);
  }, []);

  const handleConfirmationModalClose = useCallback(() => {
    setArchiveConfirmationModalOpen(false);
  }, []);

  const handleToggleProgram = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        const item = itemsRef.current.find(item => item.programId === id);
        if (item) {
          if (shouldCloseWithoutWarning(item)) {
            closeProgram(id, false);
          } else {
            setCloseWarningOpen(id);
          }
        }
      } else {
        programActivate(id, studentId).catch((error: ApolloError) => {
          NotificationsHelper.ErrorNotification({
            error,
            title: "Action Failed"
          });
        });
      }
      onProgramToggle();
    },
    [closeProgram, onProgramToggle, programActivate, studentId]
  );

  const handleArchive = useCallback((archiveProgramId: string) => {
    const programItem = itemsRef.current.find(
      item => item.programId === archiveProgramId
    );
    let sendToArchiveConfirmationModal = false;

    if (programItem) {
      switch (programItem.type) {
        case ProgramTypeEnum.INTERVAL:
        case ProgramTypeEnum.TASK_ANALYSIS:
        case ProgramTypeEnum.DTT:
          if (programItem.state === DataPointStateEnum.IN_PROGRESS) {
            sendToArchiveConfirmationModal = true;
          }
          break;
      }
    }

    // update state
    setArchiveProgramId(archiveProgramId);
    if (sendToArchiveConfirmationModal) {
      setArchiveConfirmationModalOpen(true);
    } else {
      setArchiveSelectOpen(true);
    }
  }, []);

  const sendToArchive = useCallback(
    async (archiveType: ArchiveTypeEnum) => {
      try {
        await programSetArchived(
          archiveProgramId,
          studentId,
          true,
          archiveType
        );
      } catch (err) {
        const error = err as ApolloError;
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      } finally {
        setArchiveProgramId("");
      }
    },
    [archiveProgramId, programSetArchived, studentId]
  );

  const handleArchiveMenuClick = useCallback(
    async (param: IClickParam) => {
      const archiveType = param.key as ArchiveTypeEnum;
      setArchiveSelectOpen(false);
      await sendToArchive(archiveType);
    },
    [sendToArchive]
  );

  const handleArchiveMenuCancel = useCallback(() => {
    setArchiveSelectOpen(false);
  }, []);

  const handleSaveScore = useCallback(
    async (
      score: number,
      date: moment.Moment,
      attempts?: number,
      phaseId?: string
    ) => {
      if (selectedItem) {
        try {
          if (selectedItem.type === ProgramTypeEnum.DURATION) {
            await dataPointScoreDuration(
              selectedItem.programId,
              phaseId ?? selectedItem.phaseId,
              studentId,
              score * 60,
              date.toISOString()
            );
          } else if (selectedItem.type === ProgramTypeEnum.FREQUENCY) {
            await dataPointScoreFrequency(
              selectedItem.programId,
              phaseId ?? selectedItem.phaseId,
              studentId,
              score,
              date.toISOString()
            );
          } else {
            await dataPointScore(
              selectedItem.runId,
              phaseId ?? selectedItem.phaseId,
              selectedItem.programId,
              selectedItem.type,
              studentId,
              score,
              date.toISOString(),
              attempts!
            );
          }
        } catch (e) {
          const error = e as Error;
          NotificationsHelper.ErrorNotification({
            error,
            title: "Unable to save score"
          });
        }
      }
    },
    [
      studentId,
      selectedItem,
      dataPointScoreDuration,
      dataPointScoreFrequency,
      dataPointScore
    ]
  );

  const handleEnterScoreContinue = useCallback((programItem: IProgramItem) => {
    setScoreWarningOpen(false);
    const behavior =
      programItem.type === ProgramTypeEnum.DURATION ||
      programItem?.type === ProgramTypeEnum.FREQUENCY;
    let attempts = 0;

    if (!behavior) {
      attempts =
        programItem?.type === ProgramTypeEnum.TASK_ANALYSIS
          ? programItem?.targets?.length
          : programItem?.totalCount;
    }
    setDefaultAttempts(attempts);

    setShowEnterScore(true);
  }, []);

  const handleEnterScore = useCallback(
    (id: string) => {
      if (!online) {
        Notification.warn({
          duration: 0,
          message: "You are offline.",
          description: `The Enter Score function is not available while in offline mode.`
        });
        return;
      }
      const programItem = itemsRef.current.find(item => item.programId === id);
      if (programItem) {
        setSelectedItem(programItem);
        if (shouldShowEnterScoreWarning(programItem)) {
          setScoreWarningOpen(true);
        } else {
          handleEnterScoreContinue(programItem);
        }
      }
    },
    [online, handleEnterScoreContinue]
  );

  const handleScoreWarningCancel = useCallback(() => {
    setScoreWarningOpen(false);
  }, []);

  const handleEnterScoreClose = useCallback(() => {
    setShowEnterScore(false);
    setSelectedItem(undefined);
  }, []);

  const handleInfo = useCallback((id: string) => {
    setShowProgramInfo(id);
  }, []);

  const handleInfoClose = useCallback(() => {
    setShowProgramInfo(undefined);
  }, []);

  // used by new program modal
  const showArchive = useMemo(
    () => findPermission(threadUserContext.role, Permissions.VIEW_ARCHIVE),
    [threadUserContext.role]
  );

  // used by enter score dialog
  const scoreBehavior = useMemo(
    () =>
      selectedItem?.type === ProgramTypeEnum.DURATION ||
      selectedItem?.type === ProgramTypeEnum.FREQUENCY,
    [selectedItem]
  );
  const scoreLabel = useMemo(() => {
    switch (selectedItem?.type) {
      case ProgramTypeEnum.FREQUENCY:
        return "Number of Occurrences";
      case ProgramTypeEnum.DURATION:
        return "Duration of Occurrence (minutes)";
      default:
        return "Correct";
    }
  }, [selectedItem]);

  return (
    <>
      {showNewProgram && (
        <Modal
          key="newProgram"
          visible={showNewProgram}
          onCancel={handleNewProgramClose}
          footer={null}
          title={"Select Type of New Program"}
        >
          <Menu onSelect={handleNewProgramSelect}>
            <MenuItem key={NonProgramTypeEnum.CREATE}>Create new</MenuItem>
            <MenuItem key={NonProgramTypeEnum.CURRICULUM}>
              Create from curriculum template
            </MenuItem>
            {showArchive && (
              <MenuItem key={NonProgramTypeEnum.ARCHIVE}>
                Activate a program from Archive
              </MenuItem>
            )}
          </Menu>
        </Modal>
      )}
      {showProgramInfo && (
        <ProgramInfoModal
          studentId={studentId}
          programId={showProgramInfo}
          onEdit={onProgramEdit}
          onClose={handleInfoClose}
        />
      )}
      {archiveConfirmationModalOpen && (
        <ModalConfirmation
          title="Are you sure you want to archive this program?"
          message={warningMsg}
          type="warning"
          visible={archiveConfirmationModalOpen}
          onCancel={handleConfirmationModalClose}
          onOk={handleConfirmationModalOk}
          okButtonTitle="Delete & Archive"
        />
      )}
      {closeWarningOpen && (
        <Modal
          title="Are you sure you want to close this program?"
          afterClose={handleCloseWarningCancel}
          visible={!!closeWarningOpen}
          onOk={handleCloseWarningContinue}
          onCancel={handleCloseWarningCancel}
          okText="Close Program"
        >
          Your data will be deleted immediately. You can’t undo this action.
        </Modal>
      )}
      {archiveSelectOpen && (
        <Modal
          visible={archiveSelectOpen}
          onCancel={handleArchiveMenuCancel}
          footer={null}
          title={"Archive the program as:"}
        >
          <Menu selectable={false} onClick={handleArchiveMenuClick}>
            <MenuItem key={ArchiveTypeEnum.MASTERED}>
              {LabelHelper.archiveTypeLabel(ArchiveTypeEnum.MASTERED)}
            </MenuItem>
            <MenuItem key={ArchiveTypeEnum.ON_HOLD}>
              {LabelHelper.archiveTypeLabel(ArchiveTypeEnum.ON_HOLD)}
            </MenuItem>
            <MenuItem key={ArchiveTypeEnum.DISCONTINUED}>
              {LabelHelper.archiveTypeLabel(ArchiveTypeEnum.DISCONTINUED)}
            </MenuItem>
          </Menu>
        </Modal>
      )}
      {scoreWarningOpen && (
        <Modal
          title="Are you sure you want to enter a score?"
          afterClose={handleScoreWarningCancel}
          visible={scoreWarningOpen}
          onOk={handleEnterScoreContinue}
          onCancel={handleScoreWarningCancel}
          okText="Enter Score"
        >
          You have unsaved trials that will be overridden immediately. You can't
          undo this action.
        </Modal>
      )}
      {showEnterScore && (
        <EnterScoreDialog
          scoreLabel={scoreLabel}
          dateLabel={!scoreBehavior ? "Date" : "Date of Behavior"}
          attemptsLabel={!scoreBehavior ? "Attempted" : undefined}
          defaultAttempts={defaultAttempts}
          onClose={handleEnterScoreClose}
          open={showEnterScore}
          onSaveScore={handleSaveScore}
          studentId={studentId}
          programId={selectedItem?.programId ?? ""}
        />
      )}
      <Grid>
        <Row>
          <Col padding="16px">
            <Heading level={3}>Plan a Session</Heading>
          </Col>
        </Row>
        <Row type="flex" wrap={false} padding="8px">
          <Col grow={1}>
            <StudentBar
              currentStudentId={studentId}
              onSelectStudent={onSelectStudent}
            />
          </Col>
          {onProgramMenuItemClick && (
            <Col>
              <Media.Sm orLarger>
                {(smOrLarger: boolean) => (
                  <Button
                    type="primary"
                    ghost
                    shape={smOrLarger ? undefined : "circle"}
                    onClick={handleNewProgramOpen}
                  >
                    <Row type="flex" align="middle" justify="center">
                      <Icon type="fa-plus fas" />
                      {smOrLarger && <Col margin="0 0 0 12px">New Program</Col>}
                    </Row>
                  </Button>
                )}
              </Media.Sm>
            </Col>
          )}
        </Row>
        <Row padding="8px">
          <ProgramTable
            items={items}
            onAbc={onAbc}
            onArchive={handleArchive}
            onChart={onChart}
            onCurriculumAdd={onCurriculumAdd}
            onEdit={onProgramEdit}
            onEnterScore={handleEnterScore}
            onInfo={handleInfo}
            onToggleProgram={handleToggleProgram}
          />
        </Row>
      </Grid>
    </>
  );
};

const shouldCloseWithoutWarning = (item: IProgramItem) => {
  if (item.drawer === DrawerEnum.PRIVATE_DRAWER) {
    const hasNoTrials = item.trials.length === 0;
    const allTrialsNoneResult =
      item.trials.length > 0 &&
      item.trials.filter(t => t.result === TrialResultEnum.NONE).length ===
        item.trials.length;
    const isCompleted = item.state === DataPointStateEnum.COMPLETED;

    return hasNoTrials || allTrialsNoneResult || isCompleted;
  } else {
    return true;
  }
};

const shouldShowEnterScoreWarning = (programItem: IProgramItem) => {
  return (
    programItem.type !== ProgramTypeEnum.DURATION &&
    programItem.type !== ProgramTypeEnum.FREQUENCY &&
    programItem.trials?.filter(
      t =>
        t.result !== TrialResultEnum.NONE &&
        t.result !== TrialResultEnum.NOT_APPLICABLE
    )?.length > 0
  );
};

export default memo(StudentPrograms);
