import {
  Col,
  Grid,
  Heading,
  Row,
  Drawer,
  Card,
  Icon,
  Image,
  Divider,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Tag
} from "@raudabaugh/thread-ui";
import * as React from "react";
import { IProgramItem } from "../../StudentPrograms/Types";
import { FocusBar } from "./FocusBar";
import { ApolloError } from "@apollo/client";
import {
  useDataPointRecordDiscreteTrialMutation,
  useDataPointSetPromptLevelMutation,
  useDataPointUndoDiscreteTrialMutation
} from "../../DataAccess/DiscreteTrialData";
import { DiscreteTargetRow } from "./DiscreteTargetRow";
import { LabelHelper } from "../../Shared/LabelHelper";
import { IPrompt, PromptHelper } from "Shared/PromptHelper";
import { useState } from "react";
import {
  ReinforcementScheduleEnum,
  TrialResultEnum
} from "Shared/Api/globalTypes";
import { NotificationsHelper } from "Shared/NotificationsHelper";
import summaryImage from "Shared/Images/summary.svg";
import sdImage from "Shared/Images/sd.svg";
import promptImage from "Shared/Images/prompt.svg";

interface IDiscreteTrialFocusBaseProps {
  item: IProgramItem;
  index: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  currentStudentId: string;
  completed: boolean;
  open: boolean;
  promptLevels: IPrompt[];
  onClose: (dataCollected: boolean) => void;
}

export const DiscreteTrialFocus = (props: IDiscreteTrialFocusBaseProps) => {
  const { dataPointSetPromptLevel } = useDataPointSetPromptLevelMutation();
  const { dataPointRecordDiscreteTrial } =
    useDataPointRecordDiscreteTrialMutation();
  const { dataPointUndoDiscreteTrial } =
    useDataPointUndoDiscreteTrialMutation();
  const [dataCollected, setDataCollected] = useState(false);

  const changePromptType = (prompt: string) => {
    dataPointSetPromptLevel(
      props.item.runId!,
      props.currentStudentId,
      prompt
    ).catch((error: ApolloError) => {
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    });
  };

  const handleLeftButtonClick = async (
    targetId: string,
    targetDescription: string
  ) => {
    try {
      // handle negative result
      await dataPointRecordDiscreteTrial(
        props.item.runId!,
        props.currentStudentId,
        TrialResultEnum.MINUS,
        PromptHelper.PROMPT_INDEPENDENT,
        targetId,
        targetDescription
      );
      setDataCollected(true);
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
  };

  const handleCenterButtonClick = async (
    targetId: string,
    targetDescription: string
  ) => {
    if (props.item.prompt === PromptHelper.PROMPT_INDEPENDENT) {
      return;
    }
    try {
      await dataPointRecordDiscreteTrial(
        props.item.runId!,
        props.currentStudentId,
        TrialResultEnum.MINUS,
        props.item.prompt,
        targetId,
        targetDescription
      );
      setDataCollected(true);
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
  };

  const handleRightButtonClick = async (
    targetId: string,
    targetDescription: string
  ) => {
    try {
      // handle positive result
      await dataPointRecordDiscreteTrial(
        props.item.runId!,
        props.currentStudentId,
        TrialResultEnum.PLUS,
        PromptHelper.PROMPT_INDEPENDENT,
        targetId,
        targetDescription
      );
      setDataCollected(true);
    } catch (e) {
      const error = e as Error;
      NotificationsHelper.ErrorNotification({
        error,
        title: "Action Failed"
      });
    }
    if (props.item.errorless) {
      changePromptType(PromptHelper.PROMPT_INDEPENDENT);
    }
  };

  const handleUndoClick = () => {
    dataPointUndoDiscreteTrial(props.item.runId!, props.currentStudentId)
      .then(() => {
        setDataCollected(true);
      })
      .catch((error: ApolloError) => {
        NotificationsHelper.ErrorNotification({
          error,
          title: "Action Failed"
        });
      });
  };

  const handleDrawerClose = () => {
    props.onClose(dataCollected);
    setDataCollected(false);
  };

  const variation = props.completed ? 6 : 1;
  const color = props.completed ? "primary" : "default";
  const prompts: JSX.Element[] = props.promptLevels.map(promptLevel => (
    <MenuItem key={promptLevel.name}>{promptLevel.name}</MenuItem>
  ));
  const actions = (
    <Menu onClick={param => changePromptType(param.key)}>{prompts}</Menu>
  );

  return (
    <>
      <Drawer
        key={"Drawer_" + props.index}
        mask={false}
        margin="0"
        padding="0"
        height="100%"
        placement="bottom"
        visible={props.open}
        closable={false}
      >
        <Grid
          type="flex"
          height="100vh"
          width="100%"
          color="primary"
          variation={9}
        >
          <Row type="flex" scroll="vertical">
            <Col grow={1}>
              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  onClick={handleDrawerClose}
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <FocusBar
                    id="closeProgramDrawer"
                    heading={props.item.name}
                    icon="fa-times fas"
                    isOpen={true}
                    size="25px"
                  />
                </Card>
              </Card>

              <Card
                margin="0"
                bordered={false}
                padding="0"
                boxShadow="0 2px 8px 0"
              >
                <Card
                  width="100%"
                  margin="0"
                  padding="16px 16px 12px 16px"
                  color="primary"
                  variation={9}
                  bordered={false}
                >
                  <Row type="flex">
                    <Col xs={19} sm={20} md={20} lg={22} xl={23} xxl={23}>
                      <Heading color="default" variation={1} level={6}>
                        {props.item.totalCount ? props.item.totalCount : "∞"}{" "}
                        trials • {props.item.correctCount}/
                        {props.item.applicableCount} correct
                      </Heading>
                    </Col>
                    <Col>
                      {props.item.applicableCount > 0 && (
                        <Icon
                          onClick={handleUndoClick}
                          type="fa-undo fas"
                          size="25px"
                          width={"50px"}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Card>

              {props.item.programDescription && (
                <Row padding="10px 0 0 0" margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={summaryImage} alt="Summary" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.programDescription}
                    </Heading>
                  </Col>
                </Row>
              )}
              {props.item.discriminativeStimulus && (
                <Row margin="15px 15px" type="flex">
                  <Col margin="0 5px">
                    <Image src={sdImage} alt="SD" />
                  </Col>
                  <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                    <Heading level={6} color="default">
                      {props.item.discriminativeStimulus}
                    </Heading>
                  </Col>
                </Row>
              )}
              <Row margin="15px 15px" type="flex">
                <Col margin="0 5px">
                  <Image src={promptImage} alt="Prompt" />
                </Col>
                <Col xs={18} sm={20} md={20} lg={22} xl={23} xxl={23}>
                  {props.item.errorless ? (
                    <Dropdown trigger={["click"]} overlay={actions}>
                      <Button type="primary">
                        <Row type="flex">
                          <Col>{props.item.prompt}</Col>
                          <Col margin="0 0 0 7px">
                            <Icon type="fa-edit fas" />
                          </Col>
                        </Row>
                      </Button>
                    </Dropdown>
                  ) : (
                    <Heading level={6} color="default">
                      {props.item.prompt}
                    </Heading>
                  )}
                </Col>
              </Row>
              {props.item.reinforcementSchedule !==
                ReinforcementScheduleEnum.NONE && (
                <Row margin="15px 15px" type="flex">
                  <Tag color="primary" maxWidth="10em">
                    {LabelHelper.reinforcementScheduleShortLabel(
                      props.item.reinforcementSchedule
                    )}
                    {props.item.reinforcement}
                  </Tag>
                  {props.item.reinforcementType}
                </Row>
              )}
              <Divider />
              {props.item.discreteTargets.map((target, index) => (
                <DiscreteTargetRow
                  key={index}
                  target={target}
                  errorless={props.item.errorless}
                  index={index}
                  disabled={props.completed}
                  onLeftClick={handleLeftButtonClick}
                  onRightClick={handleRightButtonClick}
                  onCenterClick={handleCenterButtonClick}
                  onChangePromptType={changePromptType}
                  promptLevels={props.promptLevels}
                  prompt={props.item.prompt ?? PromptHelper.PROMPT_NONE}
                  leftLabel={
                    <Icon
                      size="1.1em"
                      color={color}
                      variation={variation}
                      type="fa-minus fas"
                    />
                  }
                  centerLabel={
                    props.item.errorless ? (
                      <Heading level={7} color={color} variation={variation}>
                        {PromptHelper.generateLabel(props.item.prompt)}
                      </Heading>
                    ) : undefined
                  }
                  rightLabel={
                    <Icon
                      size="1.1em"
                      color={color}
                      variation={variation}
                      type="fa-plus fas"
                    />
                  }
                />
              ))}
            </Col>
          </Row>
        </Grid>
      </Drawer>
    </>
  );
};
